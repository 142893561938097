import { makeStyles } from '@material-ui/core/styles';
import colors from '../../utils/colors';

const useStyles = makeStyles(theme => ({
  notifCard: {
    alignItems: 'center',
    borderRadius : 15,
    cursor: 'pointer',
    display: 'flex',
    padding: 5,
    position: 'relative',
    '&:hover': {
      backgroundColor: colors.HOVER_DEFAULT
    },
  },

  unseenCard: {
    borderColor : colors.PRIMARY,
    borderStyle : 'solid',
  },

  unseenText: {
    color : colors.PRIMARY
  },

  image: {
    margin: theme.spacing(2),
    [theme.breakpoints.up('xs')]: {
      height: 50,
      width: 50,
      borderRadius: 50,
    },
    [theme.breakpoints.up('sm')]: {
      height: 70,
      width: 70,
      borderRadius: 70,
    },
    [theme.breakpoints.up('md')]: {
      height: 70,
      width: 70,
      borderRadius: 70,
    },
  },

  blurImage: {
    filter: 'blur(8px)'
  },

  contentView: {
    flex: '1',
    alignSelf: 'center',
  },

  info: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },

  column: {
    flexDirection: 'column'
  },

  thirdRow: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent : 'space-around'
  },

  bold: {
    fontWeight: 'bold'
  },

}));

export default useStyles;