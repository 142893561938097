import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Avatar, ButtonBase } from '@material-ui/core';

import useStyles from './style';

import calendarAction from '../../../redux/calendar/action';

const { setSelectedEventsList } = calendarAction;

const ParticipantList = (props) => {
  const classes = useStyles();
  const { eventList, setSelectedEventsList } = props;
  const [selectedParticipant, setSelectedParticipant] = useState(eventList.length > 0 ? eventList[0].user.userId : 0);

  useEffect(() => {
    console.log('ParticipantList useEffect()');
  }, [])

  useEffect(() => {
    if (eventList.length > 0) {
      if (selectedParticipant === 0) {
        setSelectedParticipant(eventList[0].user.userId);
      }
    }
  }, [eventList])

  const selectParticipant = (userId) => {
    const events = eventList.filter((event) => event.user.userId === userId)[0];

    setSelectedParticipant(userId);
    setSelectedEventsList(events);
  }

  return (
    <div className={classes.gridListContainer}>
        {
          eventList.map(({ user }) => {
            return (
              <ButtonBase
                key={user.userId} 
                focusRipple
                className={`${classes.gridListItem} ${selectedParticipant === user.userId && classes.activeGridListItem}`}
                onClick={() => selectParticipant(user.userId)}
              >
                <Avatar 
                  variant="rounded"
                  className={classes.gridListImage}
                  src={user.mediaProfile}
                />
              </ButtonBase>
            )
          })
        }
      </div>
  );
};

/////////////////////////////
// mapStateToProps()
/////////////////////////////
const mapStateToProps = (state) => {
  console.log('participantlist/index.js:mapStateToProps(state)');
  console.log(state);
  return {
    eventList     : state.Calendar.eventList
  };
}

export default connect(mapStateToProps, { setSelectedEventsList })(
  ParticipantList
);
