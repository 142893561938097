import React, { useEffect } from 'react';
import { Router } from 'react-router-dom';
import store      from './store';
import { 
  MuiThemeProvider, 
  createTheme 
} from '@material-ui/core/styles';
import { createBrowserHistory } from 'history';
import Routes from '../src/routers/index';
import colors from './utils/colors';
import '../src/assets/App.css';
import { disableLogs } from './utils/helper';
import { SnackbarProvider } from 'notistack'
import { Provider } from 'react-redux';

const browserHistory = createBrowserHistory();
const theme = createTheme({
  palette: {
    primary: {
      main: colors.PRIMARY,
      contrastText: colors.WHITE,
      border: 'rgba(0,0,0,0.1)'
    },
    secondary: {
      main: colors.WHITE,
    },
    text: {
      primary: colors.PRIMARY_TEXT,
      // secondary: colors.WHITE,
    },
    chat: {
      border: 'rgba(0,0,0,0.5)'
    }
  },
  typography: {
    fontFamily: 'sans-serif'
  },
});

const App = () => {

  useEffect(()=>{
    store.dispatch({ type: 'ADD_USER_SETTINGS_OBJECT' });
  },[])

  disableLogs();
  
  return (
    <Provider store={store}>
      <MuiThemeProvider theme={theme}>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <Router history={browserHistory}>
            <Routes />
          </Router>
        </SnackbarProvider>
      </MuiThemeProvider>
    </Provider>
  );
}

export default App;