import React, { useState, useEffect } from 'react';
import { Modal, Box, Button, Typography, TextField, Link, CircularProgress, Grid, List, ListItem, ListItemIcon, ListItemText, OutlinedInput } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from './style';
import check from '../../../assets/images/confirmation-check.png';
import {
  UPD_MODE_USER_USERNAME_PASSWORD,
  ALERT_SEVERITY_ERROR,
  HTTP_STATUS
} from '../../../utils/constants';
import { userRequest, thirdPartyRequest } from '../../../service/requests';
import colors from '../../../utils/colors';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import { handleHttpError } from '../../../utils/helper';

const AccountInfo = (props) => {
  
  const classes = useStyles();
  const { updateUser, user, next, showAlert } = props;

  const [isSaving, setIsSaving]               = useState(false);
  const [isModalOpen, setIsModalOpen]         = useState(false);
  const [isPasswordShown, setIsPasswordShown] = useState(false);
  const [typingTimeout, setTypingTimeout]     = useState(0);

  const [state, setState] = useState({
    userName                 : '',
    isUserNameError          : false,
    userNameErrorDesc        : '',
    password                 : '',
    isPasswordError          : false,
    passwordErrorDesc        : '',
    confirmPassword          : '',
    isConfirmPasswordError   : false,
    confirmPasswordErrorDesc : '',
    isPasswordPassed         : false, 
    passwordCriteria         : {
      has8Characters         : false,
      has1Lowercase          : false,
      has1Uppercase          : false,
      has1Number             : false,
      has1SpecialCharacter   : false,
      isPasswordVisible      : false,
    isConfirmPasswordVisible : false,
    }
  });

  const {
    userName, isUserNameError, userNameErrorDesc, 
    password, isPasswordError, passwordErrorDesc, 
    confirmPassword, isConfirmPasswordError, confirmPasswordErrorDesc,
    isPasswordPassed, passwordCriteria 
  } = state;

  useEffect(() => {

    setState({
      ...state,
      isPasswordError : password !== confirmPassword ? true : false,
      passwordErrorDesc : password !== confirmPassword ? '' : '',
      isConfirmPasswordError : password !== confirmPassword ? true : false,
      confirmPasswordErrorDesc : password !== confirmPassword ? 'Password does not match' : ''
    });

  }, [confirmPassword]);

  const handleInputChange = (event) => {

    const inputValue = event.target.value;
    
    let newState = {
      ...state,
      [event.target.name]: inputValue,
    }

    if (event.target.name === 'password') {
      const has8Characters       = inputValue.length >= 8;
      const has1Lowercase        = /[a-z]/.test(inputValue);
      const has1Uppercase        = /[A-Z]/.test(inputValue);
      const has1Number           = /[0-9]/.test(inputValue);
      const has1SpecialCharacter = /[~`!@#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/.test(inputValue);

      newState = {
        ...newState,
        isPasswordPassed : has8Characters && has1Lowercase && has1Uppercase && has1Number && has1SpecialCharacter,
        passwordCriteria : {
          has8Characters,
          has1Lowercase,
          has1Uppercase,
          has1Number,
          has1SpecialCharacter
        }
      }
    }

    setState({
      ...newState
    });
  };

  const handlePasswordVisibility = name => event => {
    
  }

  const checkUsernameAvailability = async () => {
    console.log('AccountInfo.checkUsernameAvailability()');

    if (userName != '') {
      const response = await userRequest.checkUsername({
        user_name: userName
      }).catch(error => {
        handleHttpError({
          error,
          request: 'userRequest.checkUsername::src/views/registration/account-info'
        })
      });
      console.log('response : ', response);
  
      setIsSaving(false);
      if (response?.data?.code == HTTP_STATUS._462) {

        setState({
          ...state,
          isUserNameError   : true,
          userNameErrorDesc : response.data.description,
        });

        return false;
      } else {
        setState({
          ...state,
          isUserNameError   : false,
          userNameErrorDesc : '',
        });
        

        return true;
      }
    } else {
      setState({
        ...state,
        isUserNameError   : false,
        userNameErrorDesc : 'Required.',
      });

      return false;
    }
  }

  const updateUserService = () => {
    console.log('AccountInfo.updateUserService()');

    setIsSaving(prevIsSaving => !prevIsSaving);
    userRequest.updateUser({
      user_id     : user.userId,
      upd_mode    : UPD_MODE_USER_USERNAME_PASSWORD,
      who_updated : user.userId,
      user_name   : userName,
      password    : password
    }).then(response => {
      console.log('response : ', response);

      if (response?.data?.status?.code == HTTP_STATUS._200) {
        const responseUser = response.data.userDetail.user
        
        updateUser(responseUser);
        setIsSaving(prevIsSaving => !prevIsSaving);
        toggleModal();
      } else {
        setIsSaving(prevIsSaving => !prevIsSaving);
      }
    }).catch(error => {
     

      setIsSaving(prevIsSaving => !prevIsSaving);
      handleHttpError({
        error,
        request: 'userRequest.updateUser::src/views/registration/account-info'
      })
    });
  }

  const handleNext = async () => {
    
    if (userName === '' || password === '' || confirmPassword === '' || password !== confirmPassword) {

      setState({
        ...state,
        isUserNameError           : userName === '' ? true : false,
        userNameErrorDesc         : userName === '' ? 'Required' : '',
        isPasswordError           : (password === '' || password !== confirmPassword) ? true : false,
        passwordErrorDesc         : password === '' ? 'Required' : password !== confirmPassword ? 'Password does not match.' : '',
        isConfirmPasswordError    : (confirmPassword === '' || password !== confirmPassword) ? true : false,
        confirmPasswordErrorDesc  : confirmPassword === '' ? 'Required' : password !== confirmPassword ? 'Password does not match.' : '',
      })
      return;
    } else if (!isPasswordPassed) {
      showAlert(ALERT_SEVERITY_ERROR, 'Password is weak. Create a stronger password.');
      return;
    }

    const isAvailable = await checkUsernameAvailability();
    setState({
      ...state,
      isUserNameError : !isAvailable,
      userNameErrorDesc : isAvailable ? '' : 'Username is already used'
    })
    isAvailable && updateUserService();
  }

  const toggleModal = () => {
    setIsModalOpen(prevIsModalOpen => !prevIsModalOpen);
  };

  const handleClick = () => {
    toggleModal();
    next();
  }
 
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => {
    setState({ ...state, isConfirmPasswordVisible: !state.isConfirmPasswordVisible });
  }

  
  const handleClickShowPassword = () => {
    setState({ ...state, isPasswordVisible: !state.isPasswordVisible });
  };

  const inCompleteForm =  password !== confirmPassword || password == '' || userName == '';

  return (
    <Grid item md={7} className={classes.gridItem}>
      <Box className={classes.accountInfo}>
        <Box className={classes.content}>
          <Typography variant="h6" color="textPrimary" className={`text-bold text-center ${classes.header}`}>
            Create a Username and Password
          </Typography>
          <Grid container justify="center" className={classes.middleSection} >
            <Grid item sm={12} className={classes.inputContainer}>
              <TextField
                label={'Username'}
                name={'userName'}
                value={userName}
                onChange={handleInputChange}
                onBlur={checkUsernameAvailability}
                className={classes.textField}
                required
                error={isUserNameError}
                helperText={userNameErrorDesc}
                variant='outlined'
                size='small'
              />

              <TextField
                label={'Password'}
                name={'password'}
                type={state.isPasswordVisible ? 'text' : 'password'}
                value={password}
                onChange={handleInputChange}
                className={classes.textField}
                required
                error={isPasswordError}
                helperText={passwordErrorDesc}
                variant='outlined'
                size='small'

                InputProps={{              
                  endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {state.isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                  )
                }}
              />

              <TextField
                label={'Confirm Password'}
                name={'confirmPassword'}
                type={state.isConfirmPasswordVisible ? 'text' : 'password'}
                value={confirmPassword}
                onChange={handleInputChange}
                className={classes.textField}
                required
                error={isConfirmPasswordError}
                helperText={confirmPasswordErrorDesc}
                variant='outlined'
                size='small'
                
                InputProps={{              
                  endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge='end'
                    >
                      {state.isConfirmPasswordVisible ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                  )
                }}
              />
            </Grid>
            <Grid item sm={12} className={classes.passwordStrengthContainer}>
              <Box className={classes.passwordStrength}>
                <Typography>Password must contain: </Typography>
                <List>
                  <ListItem dense>
                    <ListItemIcon>
                      {
                          passwordCriteria.has8Characters ?
                          <CheckIcon fontSize="small" className={classes.checkIconGreen} />
                        : 
                          <CloseIcon fontSize="small" color="error"/>
                      }
                    </ListItemIcon>
                    <ListItemText primary="8 characters" />
                  </ListItem>
                  <ListItem dense>
                      <ListItemIcon>
                      {
                          passwordCriteria.has1Lowercase ?
                          <CheckIcon fontSize="small" className={classes.checkIconGreen} />
                        : 
                          <CloseIcon fontSize="small" color="error"/>
                      }
                    </ListItemIcon>
                    <ListItemText primary="1 lowercase" />
                  </ListItem>
                  <ListItem dense>
                    <ListItemIcon>
                      {
                          passwordCriteria.has1Uppercase ?
                          <CheckIcon fontSize="small" className={classes.checkIconGreen} />
                        : 
                          <CloseIcon fontSize="small" color="error"/>
                      }
                    </ListItemIcon>
                    <ListItemText primary="1 uppercase" />
                  </ListItem>
                  <ListItem dense>
                      <ListItemIcon>
                      {
                          passwordCriteria.has1Number ?
                          <CheckIcon fontSize="small" className={classes.checkIconGreen} />
                        : 
                          <CloseIcon fontSize="small" color="error"/>
                      }
                      </ListItemIcon>
                    <ListItemText primary="1 number" />
                  </ListItem>
                  <ListItem dense>
                    <ListItemIcon>
                      {
                          passwordCriteria.has1SpecialCharacter ?
                          <CheckIcon fontSize="small" className={classes.checkIconGreen} />
                        : 
                          <CloseIcon fontSize="small" color="error"/>
                      }
                    </ListItemIcon>
                    <ListItemText primary="1 special character" />
                  </ListItem>
                </List>
              </Box>
            </Grid>
          </Grid>
          <Typography variant="subtitle2" className={`text-center ${classes.policy}`} color="textPrimary">
            We take privacy & security<br/>very seriously. Other users will only see your<br/>username and never your real name.
          </Typography>
        </Box>
        
        <Box>
          <Button color="primary" variant="contained" onClick={handleNext} className={`btn-min-width`} disabled={isSaving || inCompleteForm}>
            {
              isSaving ? 
                <CircularProgress color="white" size={24}/>
              :
                `Create`
            }
          </Button>
        </Box>

        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={isModalOpen}
          onClose={toggleModal}
        >
          <div className={classes.modal}>
            <div className={classes.paper}>
              
              <img src={check} className={classes.image} alt="check"/>

              <Typography variant="h5" color="textPrimary" className={`text-bold text-center`}>
                Thank You!
              </Typography>

              <Typography variant="subtitle2" className={`text-center`} color="textPrimary">
                Now tell us more about yourself so we can help find someone who will like you for who you really are.
              </Typography>

              <Typography variant="subtitle2" className={`text-center`} color="textPrimary">
                You can always update this information later.
              </Typography>

              <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit} onClick={handleClick}>
                {
                  isSaving ? 
                    <CircularProgress color="white" size={24}/>
                  :
                    `Continue`
                }
              </Button>
            </div>
          </div>
        </Modal>
      </Box>
    </Grid>
  )
}

export default AccountInfo;