import React from "react";
import { Box, Typography, Card, Divider, Button } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import useStyles from "../style";
import { STATUS_ACTIVE, USER_SUBSCRIPTION_REGULAR_ID } from "../../../../../utils/constants";
import { mdiCheckCircle } from "@mdi/js";
import Icon from "@mdi/react";
import colors from "../../../../../utils/colors";

const CardPlan = (props) => {
  const {
    membershipDetail,
    userSubscription,
    isLoading = false,
    handleSubscriptionClick,
    freeTrial = false,
  } = props;
  const descriptionArr = membershipDetail.description.trim().split(".");
  const classes = useStyles();

  //Temporarily removed the 2 months free trial
  // if (freeTrial) {
  //   return (
  //     <Card className={classes.trialCardPlan}>
  //       <Box>
  //         <Box className={classes.paymentDetails}>
  //           <Typography className={"textNumerator"}>
  //             {userSubscription.status === STATUS_ACTIVE &&
  //             userSubscription.subscriptionPlanId ==
  //               membershipDetail.subscriptionPlanId ? (
  //               <Typography className={"textNumerator"}>
  //                 Your 2 months Free Subscription is Active
  //               </Typography>
  //             ) : (
  //               <Typography className={"textNumerator"}>
  //                 You have a Free 2 months Access as a Regular User
  //               </Typography>
  //             )}
  //           </Typography>
  //           <Typography className={"endDate"}>It's FREE duting beta</Typography>
  //         </Box>
  //         {!(
  //           userSubscription.status === STATUS_ACTIVE &&
  //           userSubscription.subscriptionPlanId ==
  //             membershipDetail.subscriptionPlanId
  //         ) && (
  //           <Button
  //             className={classes.buttonSelect}
  //             onClick={handleSubscriptionClick.bind(
  //               this,
  //               membershipDetail.stripeProductId,
  //               membershipDetail.subscriptionPlanId
  //             )}
  //           >
  //             {isLoading && membershipDetail.subscriptionPlanId == 0 ? (
  //               <CircularProgress color="white" size={24} />
  //             ) : (
  //               "Select"
  //             )}
  //           </Button>
  //         )}
  //       </Box>
  //     </Card>
  //   );
  // }

  return (
    membershipDetail.subscriptionPlanId !== USER_SUBSCRIPTION_REGULAR_ID
      ? <Card className={classes.cardPlan}>
          {(isLoading || userSubscription?.subscriptionPlanId == membershipDetail?.subscriptionPlanId || !membershipDetail?.stripeProductId) && 
            <Box className={classes.activeSubscription}>
              <Icon path={mdiCheckCircle} size={3} color={colors.GRADIENT_ORANGE} />
              <Button className={classes.buttonActiveSubscription}>Active</Button>
            </Box>
          }
          <Box>
            <Box className={classes.paymentDetails}>
              <Typography className={"textNumerator"}>
                {`$${membershipDetail.rate}`}
                <span className={"textSlash"}></span>
                <span className={"textMonths"}>
                  {membershipDetail.validityInMonths === 12 ? ' per year' : ` per ${membershipDetail.validityInMonths} months` } 
                </span>
              </Typography>
              <Divider />
              <Typography> Only ${membershipDetail.ratePerMonth} per month </Typography>
            </Box>
            <div className={classes.cardDiv}>
              {descriptionArr.map((description, index) => {
                if (description)
                  return (
                    <div key={index} className={classes.cardDescription}>
                      <span>&#8226;</span>
                      {description}
                    </div>
                  );
              })}
            </div>
            {!(isLoading || userSubscription?.subscriptionPlanId == membershipDetail.subscriptionPlanId || !membershipDetail.stripeProductId) && 
              <Button
                className={classes.buttonSelect}
                onClick={handleSubscriptionClick.bind(
                  this,
                  membershipDetail.stripeProductId,
                  membershipDetail.subscriptionPlanId,
                  membershipDetail
                )}
              >
                Select
              </Button>
            }
          </Box>
        </Card>
      : <Card className={classes.trialCardPlan}>
          <Box>
              <Box className={classes.paymentDetails}>
                  <Typography className={"textNumerator"}>
                      { userSubscription?.status === STATUS_ACTIVE && userSubscription?.subscriptionPlanId == membershipDetail.subscriptionPlanId 
                          ? <Typography className={"textNumerator"}> Your 12 months Free Regular Subscription is Active </Typography>
                          : <Typography className={"textNumerator"}> Sign up for 12 months while it’s FREE in Beta ! </Typography>
                      }
                  </Typography>
                  <Typography className={"endDate"}> </Typography>
              </Box>
              {!(userSubscription.status === STATUS_ACTIVE && userSubscription.subscriptionPlanId == membershipDetail.subscriptionPlanId) && (
                  <Button
                  className={classes.buttonSelect}
                    onClick={handleSubscriptionClick.bind(
                      this,
                      membershipDetail.stripeProductId,
                      membershipDetail.subscriptionPlanId,
                      membershipDetail
                    )}
                  >
                  {isLoading && membershipDetail?.subscriptionPlanId == 0 ? (
                      <CircularProgress color="white" size={24} />
                  ) : (
                      "Select"
                  )}
                  </Button>
              )}
          </Box>
        </Card>
  );
};

export default CardPlan;
