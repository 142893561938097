import { all, put, takeEvery, select, takeLatest } from 'redux-saga/effects';

import action         from './action';
import actionCalendar from '../calendar/action';
import actionMessage  from '../message/action';

import { calendarRequest, notificationRequest } from '../../service/requests';
import { 
  UPD_MODE_CALENDAR_IS_SEEN,
  CALENDAR_INVITE_ACCEPTED,
  CALENDAR_INVITE_SENT,
  CALL_ACTION_COMPLETED,
  CALL_ACTION_DECLINE,
  CALL_ACTION_MISSED,
  CALL_CARD,
  CALL_STATUS_INBOUND_AUDIO,
  CALL_STATUS_INBOUND_VIDEO,
  CALL_STATUS_MISSED_AUDIO,
  CALL_STATUS_MISSED_VIDEO,
  CALL_STATUS_OUTBOUND_AUDIO,
  CALL_STATUS_OUTBOUND_VIDEO,
  CALL_TYPE_AUDIO,
  CALL_TYPE_VIDEO,
  COMMUNICATION_TYPE_VIDEO,
  EMPTY_CHAT,
  NO_NOTIFICATION,
  NO_NOTIFICATION_DESC,
  NOTIFICATION_CARD,
  PRELOADER_CALL_CARD,
  PRELOADER_MESSAGE_CARD,
  PRELOADER_NOTIFICATION_CARD,
  NO_NOTIFICATION_HISTORY_TITLE,
  NO_CALL_HISTORY_TITLE,
  NO_CALL_HISTORY_DESC,
  NO_CHAT_HISTORY_TITLE,
  NO_CHAT_HISTORY_DESC,
  COMMUNICATION_TYPE_AUDIO, 
  HTTP_STATUS} from '../../utils/constants';

import { callRequest, multipleRequest } from '../../service/requests'
import { handleHttpError } from '../../utils/helper';
/////////////////////////
// reqNotificationGet()
/////////////////////////
function* reqNotificationGet({payload}) {

  try {
    console.log('reqNotificationGet()');

    const getUser = state => state.Session.user;

    const User = yield select(getUser);
    const res = yield notificationRequest
      .get(User.user.userId, payload.notificationListPage)
      .catch((error) => {
        handleHttpError({
          error,
          request: 'notificationRequest.get::src/redux/notification'
        })
      });

    console.log('res : ', res);

    if (res) {
      if (res?.data?.status?.code == HTTP_STATUS._200) {
        yield put({
          type    : action.NOTIFICATION_GET_SUCCESS,
          payload : { notificationList:res.data.notification,
                      notificationListPage: payload.notificationListPage }
        });
      } else {
        yield put({
          type    : action.NOTIFICATION_GET_ERROR,
          payload : []
        });
      }
    } else {
      yield put({
        type    : action.NOTIFICATION_GET_ERROR,
        payload : []
      });
    }

  } catch (error) {
    console.log('error');
    console.log(error);

    yield put({
      type    : action.NOTIFICATION_GET_ERROR,
      payload : []
    });
  }
}

/////////////////////////
// reqNotificationSeen()
/////////////////////////
function* reqNotificationSeen({ payload }) {
  const notifId = payload;

  try {
    console.log('reqNotificationSeen()');
    console.log('notifId : ', notifId);

    const getUser = state => state.Session.user;
    const User = yield select(getUser);

    const res = yield notificationRequest.seenNotification({
      user_id  : User.user.userId,
      notif_id : notifId
    }).catch(error => {
      handleHttpError({
        error,
        request: 'notificationRequest.seenNotification::src/redux/notification'
      })
    });

    if (res) {
      yield put({
        type    : action.NOTIFICATION_SEEN_SUCCESS,
        payload : res.data.notification
      });
    }

  } catch (error) {
    console.log('error');
    console.log(error);
  }
}

/////////////////////////
// reqCalendarSeenEvent()
/////////////////////////
function* reqCalendarSeenEvent({ payload }) {
  console.log('reqCalendarSeenEvent()');
  
  const { calendarId, userId } = payload;

  try {
    console.log('calendarId : ', calendarId);
    console.log('userId     : ', userId);

    const res = yield calendarRequest.updateCalendarEvent({
      calendar_id   : calendarId,
      user_id 			: userId,
      upd_mode			: UPD_MODE_CALENDAR_IS_SEEN,
      update_value	: '1',
    }).catch(error=>{
      handleHttpError({
        error,
        request: 'calendarRequest.updateCalendarEvent::src/redux/notification'
      })
    });

    if (res?.data?.status?.code === HTTP_STATUS._200) {
      yield put({
        type    : actionCalendar.CALENDAR_UPDATE_RECEIVER,
        payload : res.data.calendar
      });  
      yield put({
        type    : action.NOTIFICATION_CALENDAR_SEEN_SUCCESS
      });  
    }
  } catch (error) {
    console.log('error: ', error);
  }
}

/////////////////////////
// reqChatSeen()
/////////////////////////
function* reqChatSeen({ payload }) {
  console.log('reqChatSeen()');
  
  const { connectionUserId } = payload;

  try {
    console.log('connectionUserId : ', connectionUserId);
    
    yield put({
      type    : actionMessage.MESSAGE_UPDATE,
      payload : connectionUserId
    });  
    yield put({
      type    : action.NOTIFICATION_CHAT_SEEN_SUCCESS
    });  
  } catch (error) {
    console.log('error: ', error);
  }
}

/////////////////////////
// reqMarkAllRead()
/////////////////////////
function* reqMarkAllRead({ payload }) {
  console.log('reqMarkAllRead()');
  
  const { userId } = payload;

  try {
    console.log('userId : ', userId);

    const res = yield notificationRequest.markAllNotificationRead({
      user_id : userId
    }).catch(error => {
      handleHttpError({
        error,
        request: 'notificationRequest.markAllNotificationRead::src/redux/notification'
      })
    });
    
    if (res) {
      if (res?.data?.status?.code == HTTP_STATUS._200) {
        yield put({
          type    : action.NOTIFICATION_GET_SUCCESS,
          payload : res.data.notification
        });
        yield put({
          type    : action.NOTIFICATION_MARK_ALL_SUCCESS
        });
      } else {
        yield put({
          type    : action.NOTIFICATION_GET_ERROR,
          payload : []
        });
      }
    } else {
      yield put({
        type    : action.NOTIFICATION_GET_ERROR,
        payload : []
      });
    }
  } catch (error) {
    console.log('error: ', error);
  }
}

function* getPhoneCallLog({payload}){
  try {
    const { userId, pageCount, user } = payload
    const callLogResponse = yield multipleRequest([
      callRequest.getCall({
        user_id: userId,
        page: pageCount
      }, CALL_TYPE_AUDIO),
      callRequest.getCall({
        user_id: userId,
        page: pageCount
      }, CALL_TYPE_VIDEO)
    ]).catch(error =>{
      handleHttpError({
        error,
        request: 'callRequest.getCall'
      })
    })
    

    //isNone?
    if (callLogResponse[0]?.data?.status?.code == HTTP_STATUS._471 && callLogResponse[1]?.data?.status?.code == HTTP_STATUS._471) {
      yield put({
        type    : action.NOTIFICATION_SET_CALL_LOG_STATUS,
        payload : true
      });
    }

    let phoneCallLogs = [];

    if( callLogResponse[0].data.call_thread.length > 1) {

      callLogResponse[0].data.call_thread.forEach(call => {

      let phoneCall = {
        notificationSender: {}
      };

      phoneCall.notificationSender['user'] = call.userConnection.user;
      phoneCall.notificationSender['userSubscription'] = call.userConnection.userSubscription;
      phoneCall.notificationSender['userField'] = call.userConnection.userField;
      phoneCall.notificationSender['message'] = call.userConnection.message;
      phoneCall['notifType'] = COMMUNICATION_TYPE_AUDIO;

      if ((call.whoAdded == user.userId) && (call.status == CALL_ACTION_COMPLETED)) {
        phoneCall['details'] = CALL_STATUS_OUTBOUND_AUDIO;
      } else if ((call.whoAdded != user.userId) && (call.status == CALL_ACTION_COMPLETED)) {
      phoneCall['details'] = CALL_STATUS_INBOUND_AUDIO;
      } else if ((call.status == CALL_ACTION_MISSED) || (call.status == CALL_ACTION_DECLINE)) {
      phoneCall['details'] = CALL_STATUS_MISSED_AUDIO;
      } else {
      }

      phoneCall['whenAdded'] = call.whenAdded;

      phoneCallLogs.push(phoneCall);
      });
    }

    if (callLogResponse[1].data.call_thread.length > 1) {

      callLogResponse[1].data.call_thread.forEach(call => {
        let videoCall = {
        notificationSender: {}
        };

        videoCall.notificationSender['user'] = call.userConnection.user;
        videoCall.notificationSender['userSubscription'] = call.userConnection.userSubscription;
        videoCall.notificationSender['userField'] = call.userConnection.userField;
        videoCall.notificationSender['message'] = call.userConnection.message;
        videoCall['notifType'] = COMMUNICATION_TYPE_VIDEO;

        if ((call.whoAdded == user.userId) && (call.status == CALL_ACTION_COMPLETED)) {
        videoCall['details'] = CALL_STATUS_OUTBOUND_VIDEO;
        } else if ((call.whoAdded != user.userId) && (call.status == CALL_ACTION_COMPLETED)) {
        videoCall['details'] = CALL_STATUS_INBOUND_VIDEO;
        } else if ((call.status == CALL_ACTION_MISSED) || (call.status == CALL_ACTION_DECLINE)) {
        videoCall['details'] = CALL_STATUS_MISSED_VIDEO;
        } else {
        }

        videoCall['whenAdded'] = call.whenAdded;

        phoneCallLogs.push(videoCall);
      });
    }
    
    phoneCallLogs.sort((a, b) => {
     return new Date(b.eventTime) - new Date(a.eventTime);
    });
    //Merge Pagination
    yield put({
      type    : action.NOTIFICATION_GET_CALL_LOG_SUCCESS,
      payload : phoneCallLogs
    });


  } catch (err){
    console.log('error', err)
  }
}

export default function* rootSaga() {
  yield all([takeLatest(action.NOTIFICATION_GET,            reqNotificationGet)]);
  yield all([takeEvery(action.NOTIFICATION_SEEN,            reqNotificationSeen)]);
  yield all([takeEvery(action.NOTIFICATION_CALENDAR_SEEN,   reqCalendarSeenEvent)]);
  yield all([takeEvery(action.NOTIFICATION_CHAT_SEEN,       reqChatSeen)]);
  yield all([takeEvery(action.NOTIFICATION_MARK_ALL_READ,   reqMarkAllRead)]);
  yield all([takeLatest(action.NOTIFICATION_GET_CALL_LOG,   getPhoneCallLog)]);
}
