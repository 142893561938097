import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../utils/colors';

const useStyles = makeStyles(theme => ({

  box: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddintTop: theme.spacing(2),
    paddintBottom: theme.spacing(2),
  },

  height: {
    // height: 700,

    '& > .MuiSlider-root': {
      height: 400,
      margin: 10,
    },

    '& > .MuiBox-root': {
      display: 'flex',
      flexDirection: 'row',
      height: 400,
      margin: 10,

      '& > .MuiBox-root': {
        marginLeft: theme.spacing(5),
      },

      '& > .MuiBox-root:first-of-type': {
        display: 'flex',
        flexDirection: 'column',
        alignSelf: 'center',
        width: theme.spacing(20),
        marginRight: theme.spacing(5),
      }
    }
  },

}));

export default useStyles;