import { makeStyles } from '@material-ui/core/styles';
import colors from '../../utils/colors';

const useStyles = makeStyles(theme => ({

  container: {

  },

  overlay: {
    position: 'fixed',
    width: '41.6666666667%',
    height: '100%',
    backgroundImage: `linear-gradient(180deg, ${colors.GRADIENT_YELLOW} 30%, ${colors.GRADIENT_ORANGE} 90%)`,
    opacity: 0.5,
    zIndex: 2,
  },

  image: {
    width: '41.6666666667%',
    position: 'fixed',
  },

  sos: {
    position: 'absolute',
    height: 64,
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(4),
    zIndex: 3,
  },
}));

export default useStyles;