import React from 'react';
import { sessionService } from 'redux-react-session';
import ReactDOM from 'react-dom';
import { applyMiddleware, compose, createStore } from 'redux'
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga'
import App from './App';
import rootReducer from './redux/reducers.js'
import User from './redux/user/reducer';
import rootSaga from './redux/sagas';
import '../src/assets/index.css';
import * as serviceWorker from './serviceWorker';
import Firebase, { FirebaseContext } from './classes/firebase';
import  store, { persistor } from './store.js';
import { PersistGate } from 'redux-persist/integration/react'

const firebase = new Firebase();

sessionService.initSessionService(store);

ReactDOM.render(
  <Provider store={store}>
    <FirebaseContext.Provider value={firebase}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </FirebaseContext.Provider>
  </Provider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
