import React, { useState, useEffect, useRef } from 'react';
import { Container, Grid, Card, CardContent, Link, Modal, Box, CircularProgress, Button, Fade } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { useLocation, useHistory, } from 'react-router-dom';
import SnackBar from '../../components/snackbar';
import useMediaQuery from '@material-ui/core/useMediaQuery';

// components
import DashboardUnansweredMessages from '../../components/dashboard-unanswered-messages';
import DashboardShortlist from '../../components/dashboard-shortlist';
import DashboardSearchlist from '../../components/dashboard-searchlist';
import DashboardStatusCard from '../../components/dashboard-status-card';
import DashboardUpcomingEvents from '../../components/dashboard-upcoming-events';
import Preloader from '../../components/preloader';
import moment from 'moment'
// styles
import useStyles from './style';

// Utils
import {
  PRELOADER_UNANSWERED_MESSAGE_CARD, CALENDAR_RESPONSE_PENDING,
  STATUS_VIEWED_ME, STATUS_CALENDAR_INVITES, STATUS_HEARTED_ME,
  STATUS_PROFILE_COMPLETION, STATUS_PROFILE_IDEAL, ALERT_SEVERITY_SUCCESS,
  TOAST_SUBSCRIPTION_PLAN_CHANGED, TOAST_SUBSCRIPTION_PLAN_ERROR, ALERT_SEVERITY_ERROR, UPD_MODE_USER_MEMBERSHIP,
  SOS_EMAIL,
  STATUS_INACTIVE,
  STATUS_ACTIVE
} from '../../utils/constants';
import { getUrlParams, isDayBeforeOrAfterFromNow } from '../../utils/helper';
import { getUserChannels } from '../../utils/twilio';

// others
import connectionAction from '../../redux/connection/action';
import calendarAction from '../../redux/calendar/action';
import searchAction from '../../redux/search/action';
import messageAction from '../../redux/message/action';
import { userRequest } from '../../service/requests';
import userAction from '../../redux/user/action';

import payment_logo from '../../assets/images/payment.png'
import confetti from '../../assets/images/confetti.svg'
const { getShortlist, getRecentViews, setUserConnection } = connectionAction;
const { getSearches } = searchAction;
const { getCalendarEvents } = calendarAction;
const { saveMessages, updateMessages, updateTwilio, updateTwilioClient, toggleMessageLoaded, getAllMessages } = messageAction;
const { updateUserSubscription, startSubscription, updateBillingFields } = userAction;
const Home = (props) => {
  const {
    User, userId,
    isShortlistLoading, shortlist, getShortlist,
    isSearchLoading, searches, getSearches,
    isRecentViewsLoading, likedMeList, iViewedList, viewedMeList, getRecentViews,
    eventList, isEventLoading, getCalendarEvents,
    messages, twilio, saveMessages, updateTwilio, updateTwilioClient,
    setUserConnection, isShortListLoaded, isRecentViewsLoaded, isSearchLoaded, isEventLoaded,
    updateUserSubscription, currentSubscription,
    isMessageLoaded, toggleMessageLoaded, getAllMessages, applyingSubscriptionLoading, applyingSubscriptionStatus,
    startSubscription, updateBillingFields
  } = props;
  const history = useHistory();
  const classes = useStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const spacing = isMobile ? 1 : 4;

  const [completeIdealPercent, setCompleteIdealPercent] = useState(0);
  const [completeProfilePercent, setCompleteProfilePercent] = useState(0);
  const [newPendingEventsCount, setNewPendingEventsCount] = useState(0);

  const stringParams = props.location.search.substring(1)
  const params = stringParams ? getUrlParams(stringParams) : {}


  useEffect(() => {
    if (isSearchLoaded) setIdealProgress();
    if (isEventLoaded) setCalendarInvite();
    if (User) setUserProfileProgress();
  }, [searches, User, eventList]);

  useEffect(() => {
    if (twilio.client !== null) {
      setTimeout(() => { setMessageList(); }, 100)
    }
  }, [likedMeList, viewedMeList, iViewedList, twilio.client]);


  useEffect(() => {
    if (!isRecentViewsLoaded) getRecentViews();
    if (!isShortListLoaded) getShortlist();
    if (!isSearchLoaded) getSearches();
    if (!isEventLoaded)
      getCalendarEvents({
        connection_user_id: 0,
        date_start: 0,
        date_end: 0,
      });
  }, []);

  const gotoPage = (url) => {
    history.push(url);
  }

  const goToMyIdeal = (pathname, search) => {
    history.push({
      pathname: pathname,
      search: search,
      state: { detail: true }
    })
  }

  const messageReplyClickHandler = (userConnection) => {
    if (!userConnection) return;
    setUserConnection(userConnection);
    history.push(`/profile/${userConnection.user.userName}?tab=communicate`);
  };

  const profileClickHandler = (userConnection) => {
    if (!userConnection) return;
    setUserConnection(userConnection);
    history.push(`/profile/${userConnection.user.userName}?tab=profile`);
  };

  const setIdealProgress = () => {
    const idealSearchList = searches.filter(
      (item) => item.searchName === 'My Ideal'
    );
    if (!idealSearchList.length) return;

    const searchlistResult = idealSearchList[0].users;
    if (!searchlistResult) return;
    const preferenceKeysArr = Object.keys(searchlistResult);
    let hasValueCount = 0;
    preferenceKeysArr.map((key) => {
      if (searchlistResult[key]) {
        hasValueCount++;
      }
    });
    const percentage = (hasValueCount / preferenceKeysArr.length) * 100;
    setCompleteIdealPercent(Math.floor(percentage));
  };

  const setUserProfileProgress = () => {
    const userPreferenceObject = { ...User.userField.userPreference };
    if (!userPreferenceObject.religBeliefDesc) {
      delete userPreferenceObject.religBeliefDesc;
    }
    const preferenceKeysArr = Object.keys(userPreferenceObject);

    let hasValueCount = 0;
    preferenceKeysArr.map((key) => {
      if (userPreferenceObject[key]) {
        hasValueCount++;
      }
    });

    const percentage = (hasValueCount / preferenceKeysArr.length) * 100;
    setCompleteProfilePercent(Math.floor(percentage));
  };

  const setCalendarInvite = () => {
    if (!eventList) return;
    if (!eventList.length) return;

    const newEvents = eventList.filter(events => {
      if (!events.eventList.length) return;
      events.eventList.filter(eventItem => {
        const dateStartTimestamp = eventItem.meetup.dateStart;
        const isPending = eventItem.eventReceiver.calendar.status === CALENDAR_RESPONSE_PENDING;
        const isUpcomingEvent = isDayBeforeOrAfterFromNow(dateStartTimestamp, 'unix').isBefore;
        if (isPending && isUpcomingEvent) {
          return eventItem;
        };
      })
    })

    if (!newEvents.length) return;

    const unansweredInvites = [];

    newEvents.forEach((events) => {
      events.eventList.forEach(eventItem => {
        const isInvited = eventItem.eventReceiver.user.userId === User.user.userId;
        if (isInvited) unansweredInvites.push(eventItem);
      })
    });

    // set unanswered invites count from other users
    setNewPendingEventsCount(unansweredInvites.length);
  };


  const setMessageList = () => {
    getAllMessages(twilio.client);
  };



  return (
    <Container className={classes.homeContainer}>
      <Grid container spacing={spacing}>
        <Grid item xs={12} lg={5}>
          <Grid container spacing={spacing}>
            <Grid item xs={12}>
              <Card className={classes.messageContainer} variant='outlined'>
                <CardContent className={classes.cardContentMessage}>
                  <Link component='button' onClick={gotoPage.bind(this, '/message-center/chat')}>
                    <h3 className={classes.cardMessageHeader}>Unanswered Messages</h3>
                  </Link>
                  <div className={classes.cardContentMessageWrapper}>
                    {
                      !isMessageLoaded || isRecentViewsLoading ?
                        [1, 2, 3, 4].map(value => <Preloader type={PRELOADER_UNANSWERED_MESSAGE_CARD} key={value} />)
                        :
                        <DashboardUnansweredMessages
                          messages={messages}
                          replyCallback={messageReplyClickHandler}
                        />
                    }
                  </div>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12}>
              <DashboardSearchlist
                title='View All'
                searches={searches}
                isLoading={isSearchLoading}
                gotoPage={goToMyIdeal.bind(this, '/search', '/advanced-search')}
                clickCallback={profileClickHandler}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} lg={7}>
          <Grid container spacing={spacing}>
            <Grid item xs={12}>
              <DashboardShortlist
                title='Shortlist'
                shortlist={shortlist}
                isLoading={isShortlistLoading}
                gotoPage={gotoPage.bind(this, '/shortlist')}
                clickCallback={profileClickHandler}
              />
            </Grid>

            <Grid item xs={12} sm={4} lg={4}>
              <DashboardStatusCard
                title='Viewed Me'
                count={viewedMeList.length}
                isLoading={isRecentViewsLoading}
                type={STATUS_VIEWED_ME}
                description='Profile Views'
                gotoPage={gotoPage.bind(this, '/recent-views/viewed-me')}
              />
            </Grid>

            <Grid item xs={12} sm={4} lg={4}>
              <DashboardStatusCard
                title='Calendar Invites'
                count={eventList.length}
                isLoading={isEventLoading}
                type={STATUS_CALENDAR_INVITES}
                gotoPage={gotoPage.bind(this, '/calendar/invitation-view')}
                description={`${newPendingEventsCount ?
                  `${newPendingEventsCount} new`
                  :
                  'No'
                  } unanswered calendar invites`
                }
              />
            </Grid>

            <Grid item xs={12} sm={4} lg={4}>
              <DashboardStatusCard
                title='Hearted Me'
                count={likedMeList.length}
                isLoading={isRecentViewsLoading}
                type={STATUS_HEARTED_ME}
                gotoPage={gotoPage.bind(this, '/recent-views/liked-me')}
                description='Profiles that meet the Near Me criteria'
              />
            </Grid>
            {
              (completeProfilePercent !== 100) &&
              <Grid item xs={12} sm={6} lg={6}>
                <DashboardStatusCard
                  title='Complete Your Profile'
                  count={completeProfilePercent}
                  isLoading={false}
                  type={STATUS_PROFILE_COMPLETION}
                  description='We will suggest your match based on your preferences'
                />
              </Grid>
            }
            {
              (completeIdealPercent !== 100) &&
              <Grid item xs={12} sm={6} lg={6}>
                <DashboardStatusCard
                  title='Complete My Ideal S.O.Search'
                  count={completeIdealPercent}
                  isLoading={isSearchLoading}
                  type={STATUS_PROFILE_IDEAL}
                  description='We will suggest your match based on your preferences'
                />
              </Grid>
            }
            <Grid item xs={12}>
              <DashboardUpcomingEvents
                eventList={eventList}
                isLoading={isEventLoading}
                gotoPage={gotoPage.bind(this, '/calendar/invitation-view')}
                profileClickCallback={profileClickHandler}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Modal
        open={params?.success}
        onClose={() => { }}
      >
        <Fade in={params?.success}>
          <div class={classes.paymentModalContainer}>
            {params?.subscribedToMembership === 'trial' ?
              <div class={classes.confettiContainer}>
                <img src={confetti} />
              </div>
              :
              <div class={classes.imageContainer}>
                <img src={payment_logo} />
              </div>
            }
            {
              params?.subscribedToMembership === 'trial' ?
                <div class={classes.paymentDescriptionContainer}>
                  <h2>Congratulations!</h2>
                  Your free trial subscription has been activated.
                </div>
                :
                <div class={classes.paymentDescriptionContainer}>
                  <h2>Payment Successful!</h2>
                  Congratulations, your payment has been processed.
                </div>
            }


            <Button color='primary' variant='contained' style={{ textTransform: 'none' }} onClick={() => { history.push({ pathname: '/home' }) }}>Continue</Button>
          </div>
        </Fade>
      </Modal>

      {/* <SnackBar 
        severity={toastInfo.success === 'true' ? ALERT_SEVERITY_SUCCESS : ALERT_SEVERITY_ERROR}
        message={toastInfo.success  === 'true' ? TOAST_SUBSCRIPTION_PLAN_CHANGED : TOAST_SUBSCRIPTION_PLAN_ERROR}
        open={openToast}
        setOpen={setOpenToast}
      /> */}
    </Container>
  );
};

const mapStateToProps = (state) => {
  console.log('shortlist/index.js:mapStateToProps(state)');
  console.log(state);
  return {
    User: state.Session.user,
    user: state.Session.user.user,
    userId: state.Session.user.user.userId,
    shortlist: state.Connection.shortlist,
    isShortlistLoading: state.Connection.isShortlistLoading,
    isSearchLoading: state.Search.isSearchLoading,
    isRecentViewsLoading: state.Connection.isRecentViewsLoading,
    searches: state.Search.searches,
    likedMeList: state.Connection.recentViewsList.likedMe,
    iViewedList: state.Connection.recentViewsList.iViewed,
    viewedMeList: state.Connection.recentViewsList.viewedMe,
    eventList: state.Calendar.eventList,
    isEventLoading: state.Calendar.isEventLoading,
    messageList: state.Message.messageList,
    messages: state.Message.messages,
    channels: state.Message.twilio.channels,
    isMessageLoaded: state.Message.isMessageLoaded,
    twilio: state.Message.twilio,
    isShortListLoaded: state.Connection.isShortListLoaded,
    isRecentViewsLoaded: state.Connection.isRecentViewsLoaded,
    isSearchLoaded: state.Search.isSearchLoaded,
    isEventLoaded: state.Calendar.isEventLoaded,
    currentSubscription: state.User.userSubscription,
    applyingSubscriptionLoading: state.User.applyingSubscriptionLoading,
    applyingSubscriptionStatus: state.User.applyingSubscriptionStatus,

  };
};

export default connect(mapStateToProps, {
  getShortlist,
  getSearches,
  getRecentViews,
  getCalendarEvents,
  saveMessages,
  updateMessages,
  updateTwilio,
  updateTwilioClient,
  setUserConnection,
  updateUserSubscription,
  toggleMessageLoaded,
  getAllMessages,
  startSubscription,
  updateBillingFields
})(Home);
