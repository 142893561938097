import React, { useState } from 'react';
import { Box, Button, Checkbox, Typography, Link } from '@material-ui/core';

import useStyles from './style';
import stop from '../../../assets/images/register-stop.png'

const PrivacyPolicy = (props) => {
  const classes = useStyles();
  const { next } = props;

  const [isChecked, setChecked] = useState(false);
  const handleChange = event => {
    setChecked(event.target.checked);
  };

  const handleNext = () => {
    next();
  }

  return (
    <Box className={classes.privacyPolicy}>
      <Box className={classes.content}>
        <img src={stop} className={classes.image} alt="stop"/>
        
        <Typography variant="h5" color="textPrimary">
          But First ...
        </Typography>

        <Typography variant="subtitle2" className={`text-center`} color="textPrimary">
          Your privacy and security are important to us.<br/>Please open and read our:
        </Typography>
        
        <Link href="/privacypolicy">
          Privacy Policy
        </Link>
        
        <Link href="/aboutus">
          Terms and Condition
        </Link>
        
        <Box className={`d-flex`}>
          <Box className={classes.checkBox}>
            <Checkbox
              checked={isChecked}
              onChange={handleChange}
              color="primary"
            />
          </Box>
          <Typography variant="subtitle2" align="justify" color="textPrimary">
            I am at least 18 years old, and I have read and agree to the Terms & Condition and Privacy Policy for S.O. Search
          </Typography>
        </Box>
      </Box>

      <Box>
        <Button color="primary" disabled={!isChecked} variant="contained" onClick={handleNext} className={`btn-min-width`}>
          Next
        </Button>
      </Box>
    </Box>
  )
}

export default PrivacyPolicy;