import { makeStyles } from '@material-ui/core/styles';
import zIndex from '@material-ui/core/styles/zIndex';
import colors from '../../utils/colors';

const useStyles = makeStyles(theme => ({
    couponCard: {
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        width: 600,
        minHeight: 100,
        borderColor: colors.PRIMARY,
        backgroundColor: colors.WHITE,
        borderWidth: 2,
        borderStyle: 'solid',
        position: 'relative',
    },
    left: {
        width: 15,
        height: 30,
        borderTopRightRadius: 30,
        borderBottomRightRadius: 30,
        borderColor: colors.PRIMARY,
        backgroundColor: colors.BACKGROUND_COLOR,
        borderWidth: 2,
        borderStyle: 'solid',
        position: 'absolute',
        borderWidth: 2,
        borderLeftWidth: 0,
        left: -2,
    },

    right: {
        width: 15,
        height: 30,
        borderTopLeftRadius: 30,
        borderBottomLeftRadius: 30,
        borderColor: colors.PRIMARY,
        backgroundColor: colors.BACKGROUND_COLOR,
        borderWidth: 2,
        borderStyle: 'solid',
        position: 'absolute',
        borderWidth: 2,
        borderRightWidth: 0,
        right: -2
    },

    row: {
        flexDirection: 'row',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        // alignItems: 'center',
    },

    dash:{
      borderWidth: 0,
      borderLeftWidth: 2,
      borderStyle: 'dashed',
      borderColor: colors.PRIMARY,
    },
  
    couponContent: {
        flex: 6,
        overflow: 'auto',
        flexWrap: 'wrap', 
        flexDirection: 'column',
        wordBreak: 'break-word',
        flexShrink: 1,
        alignSelf: 'center',
        margin: '15px 20px',
    },

    subscriptionContainer: {
        display: 'flex',
        flex: 4, 
        flexDirection: 'column',
        overflow: 'auto',
        flexWrap: 'wrap', 
        wordBreak: 'break-word',
        flexShrink: 1,
        alignSelf: 'center',
        margin: '15px 20px',
    },

    couponText: {
        fontSize: 16,
        color: colors.PRIMARY_TEXT,
        paddingBottom: 5,

    },

    subscriptionText: {
        fontSize: 16,
        color: colors.PRIMARY,
        fontWeight: 'bold',
    },
    monthsFree: {
        fontSize: 16,
        color: colors.PRIMARY,
    },

    buttonStyle: {
        backgroundColor: colors.PRIMARY,
        width: 100, 
        height: 30,  
        alignSelf: 'center',
    }
}));

export default useStyles;