import React, { useEffect, useRef, useState } from 'react';
import { 
  AppBar, Box, Button, Tab, Tabs, Toolbar, Typography, Popper, Grow, Paper, MenuList, MenuItem, 
  ClickAwayListener, Avatar, IconButton, useMediaQuery, useTheme, InputAdornment, TextField, 
  Divider, ListItemIcon, ListItemText, Collapse, ListItemSecondaryAction, Switch, Checkbox
} from '@material-ui/core';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import AddCircleIcon from '@material-ui/icons/AddCircle';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import RadioButtonUncheckedOutlined from '@material-ui/icons/RadioButtonUncheckedOutlined';
import RadioButtonCheckedOutlined from '@material-ui/icons/RadioButtonCheckedOutlined';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import KeyboardArrowDownOutlinedIcon from '@material-ui/icons/KeyboardArrowDownOutlined';
import SearchIcon from '@material-ui/icons/Search';
import Heart from '../heart'

// import Rewards from '../rewards';
import useStyles from './style';
import logo from '../../assets/images/logo-text-white.png'
import calendarAction from '../../redux/calendar/action';
import lookupAction from '../../redux/lookup/action';
import rootAction from '../../redux/action';
import userAction from '../../redux/user/action';
import messageAction from '../../redux/message/action';

import { getImageCache, handleHttpError, urlParamToJSON } from '../../utils/helper';
import { userRequest } from '../../service/requests';

import { 
  ADD_EVENT, 
  LOOK_UP_SUBSCRIPTION_PLAN,
  UPD_MODE_USER_VIEWABILITY_STATUS,
  USER_SUBSCRIPTION_ELITE, 
  USER_SUBSCRIPTION_PRIVACY, 
  USER_SUBSCRIPTION_REGULAR,
  UPD_MODE_USER_SETTING,
  STATUS_RECEIVED,
  IMAGE_INVALID_LINK,
  HTTP_STATUS
} from '../../utils/constants';
import colors from '../../utils/colors';
import NoImageLink from '../../assets/images/user-profile.png'
const { setEventDetailsModalOpen, setEventOperation } = calendarAction;
const { signOutUser } = rootAction;
const { updateUserSubscription, updateUser, updateUserSettings } = userAction;
const { getLookUpFields } = lookupAction;
const { updateTwilio } = messageAction;

const a11yProps = (index) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const urlTabsViews = [
  'viewed-me',
  'liked-me',
  'ive-viewed'
]

const urlTabsContact = [
  'notification',
  'chat',
  'call-video'
]

const urlTabsSearch = [
  'simple-search',
  'advanced-search'
]

const urlTabsCalendar = [
  'invitation-view',
  'calendar-view'
]

const TopBar = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const anchorRef = useRef(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

  const tabname = props.computedMatch.params.tabname;
  const { authenticated, user, signOutUser, client, lookUpSubscriptionPlan, getLookUpFields, setEventDetailsModalOpen, setEventOperation, userSubscription, topBarSibling : TopBarSibling,  userId, updateTwilio  } = props;
 
  const [profileImage, setProfileImage] = useState('');

  const [value, setValue] = useState(0);
  const [subTabValue, setSubTabValue] = useState(0);

  const [open, setOpen] = useState(false);
  const [hasSubTab, setHasSubTab] = useState(false);

  const [activeStatus, setActiveStatus] = useState(true);
  const [showSubSetting, setShowSubSetting] = useState(false);
  const [subSettingActiveIndex, setSubSettingActiveIndex] = useState(null);

  const [searchName, setSearchName] = useState("");
  
  useEffect(()=>{
    if (client == null) updateTwilio(userId);
  },[])

  useEffect(() => {
    
    setSelectedTab();
    setSubTab();

    lookUpSubscriptionPlan.length === 0 && getLookUpFields(LOOK_UP_SUBSCRIPTION_PLAN);

    const hasUserSubscription = Object.keys(userSubscription).length ? true : false;
    if (!hasUserSubscription) {
      getUserDetail();
    }
  }, [history.location.pathname]);

  useEffect(()=>{
    getUserDetail();
  },[open])

  const subTabs = {
    'recent-views': {
      viewedme  : 'Viewed Me',
      likedme   :  'Liked me',
      iveviewed : 'I\'ve Viewed'
    },
    'message-center': {
      notification: 'Notification',
      chat        : 'Chat',
      callvideo   : 'Call/Video'
    }, 
    search: {
      simplesearch  : 'Simple Search',
      advancedsearch: 'Advanced Search'
    },
    calendar: {
      invitationview: 'Invitation View',
      calendarview  : 'Calendar View'
    }
  };

  const viewableStatusArr = [
    'Regular Member',
    'Privacy Member',
    'Elite Member'
  ];

  // const headerTitleArr = [
  //   '',
  //   'Views',
  //   'Contact',
  //   'Shortlist',
  //   'Search',
  //   'Calendar'
  // ];

  const setSubTab = () => {
    const pathname = history.location.pathname.split('/');
    const tabKeys = Object.keys(subTabs);
    setHasSubTab(tabKeys.includes(pathname[1]));
    
    const subTabIndex = subTabKeys().indexOf(tabname);
    
    if (subTabIndex === -1) return;

    if (pathname[1] === 'search') {
      history.push({
        pathname: `/${pathname[1]}/${tabname}`,
        state   : {
          subTabIndex: subTabIndex
        }
      });
      setSubTabValue(subTabIndex);
      return;

    }else if((pathname[1] === 'message-center')){
      
      history.push({
        pathname: `/${pathname[1]}/${tabname}`,
        state   : {
          subTabIndex: subTabIndex
        }
      });
      setSubTabValue(subTabIndex);
      return;
    } else if (!tabname) {
      return;
    };

    history.push({
      pathname  : `/${pathname[1]/tabname}`,
      state     : {
        subTabIndex
      }
    });

    setSubTabValue(subTabIndex);
  }

  const subTabKeys = () => {
    const pathname = history.location.pathname.split('/');
    if (!subTabs[pathname[1]]) return [];
    return Object.keys(subTabs[pathname[1]])
  }

  const onSubTabChanged = (event, value) => {
    const pathname = history.location.pathname.split('/');
    setSubTabValue(value);

    if(pathname[1] == 'recent-views'){
      history.push({
        pathname  : `/recent-views/${urlTabsViews[value]}`,
        state     : {
          subTabIndex: value
        }
      });
    }
    else if (pathname[1] == 'message-center'){
      history.push({
        pathname  : `/message-center/${urlTabsContact[value]}`,
        state     : {
          subTabIndex: value
        }
      });
    }
    else if (pathname[1] == 'search'){
      if(urlTabsSearch[value] == 'simple-search' && searchName){
        history.push({
          pathname  : `/search/${urlTabsSearch[value]}?q=${searchName}`,
          state     : {
            subTabIndex: value
          }
        });
      }else{
        history.push({
          pathname  : `/search/${urlTabsSearch[value]}`,
          state     : {
            subTabIndex: value
          }
        });
      }
    }
    else if (pathname[1] == 'calendar'){
      history.push({
        pathname  : `/calendar/${urlTabsCalendar[value]}`,
        state     : {
          subTabIndex: value
        }
      });
    } 
  }

  const setSelectedTab = () => {
    const pathname = history.location.pathname;
    if (pathname === '/home') {
      setValue(0);
    } else if (pathname === `/recent-views/${tabname}`) {
      setValue(1);
    } else if (pathname === `/message-center/${tabname}`) {
      setValue(2);
    } else if (pathname === '/shortlist') {
      setValue(3);
    } else if (pathname === `/search/${tabname}`) {
      setValue(4);
    } else if (pathname === `/calendar/${tabname}`) {
      setValue(5);
    // } else if (pathname === '/near-me') {
    //   setValue(6);
    } else {
      setValue(false);
    }
  }

  const goToSignIn = () => {
    history.push('/?signin=true');
  }

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);

    setTimeout(() => {
      setShowSubSetting(false);
      setSubSettingActiveIndex(null);      
    }, 300);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);

    setTimeout(() => {
      setShowSubSetting(false);
      setSubSettingActiveIndex(null);   
    }, 300);
  };

  const signOut = () => {
    signOutUser();
  }

  const handleListKeyDown = (event) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);  

  
  const handleChange = (event, value) => {
    if (value == 0) {
      history.push('/home');
    } else if(value == 1) {
      history.push('/recent-views/viewed-me');
    } else if(value == 2) {
      history.push('/message-center/notification');
    } else if(value == 3) {
      history.push('/shortlist');
    } else if(value == 4) {
      history.push('/search/advanced-search');
      setSubTabValue(1);
    } else if(value == 5) {
      history.push('/calendar/invitation-view');
    } else if(value == 6) {
      history.push('/near-me');
    }
    setValue(value);

    if (!hasSubTab) return;
    setSubTabValue(value == 4 ? 1 : 0);
  };

  const handleMenuItemChange = (url) => {
    console.log('handleMenuItemChange');

    history.push(url);
    setOpen(false);
  }

  const onSearchbarKeyEnter = (event) => {
    if (!event.target.value.trim()) return;
    if (event.key == 'Enter') {
      console.log('searchValue', event.target.value);
      history.push({
        pathname: `/search/simple-search?q=${event.target.value}`,
        state   : {
          subTabIndex: 0,
          searchValue: event.target.value
        }
      });
      setSubTabValue(0);
    }
  }

 
  const onfilterSearchbar = evt => {
    const SearchInput = evt.target.value.replace(/[`~0-9!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi, '');
    setSearchName(SearchInput);
  };

  if(user){
    !user.mediaProfile.includes(IMAGE_INVALID_LINK) && getImageCache(user.mediaProfile).then((cachedImage) => {
      setProfileImage(cachedImage);
    }).catch((e)=>{
      setProfileImage(NoImageLink)
    });
  } 

  user && getImageCache(user.mediaProfile).then((cachedImage) => {
    if(!cachedImage.includes(IMAGE_INVALID_LINK)){
      setProfileImage(cachedImage)
    } else{
      setProfileImage(NoImageLink)
    }
  }).catch(e=>{
    setProfileImage(NoImageLink)
  });
  
  const getUserDetail = () => {
    console.log('getUserDetail()');
    const { userId, updateUserSubscription } = props;

    userRequest.getDetail({
      user_id : userId
    }).then(response => {
      console.log('userRequest.getDetail::src/components/topbar response : ', response);

      updateUserSubscription(response?.data?.userDetail?.userSubscription);
      updateUserSettings(response?.data?.userDetail?.userSetting)
      setActiveStatus(response?.data?.userDetail?.userSetting?.isReachabilityStatusOn === 1)
    }).catch(error => {
      handleHttpError({
        error,
        request: "userRequest.getDetail::src/components/topbar"
      })
    });
  }

  const updateViewabilityStatus = (viewabilityStatus) => {
    console.log('updateViewabilityStatus()');

    const { updateUserSubscription, userId } = props;
    userRequest.updateUser({
      user_id            : userId,
      upd_mode           : UPD_MODE_USER_VIEWABILITY_STATUS,
      who_updated        : userId,
      viewability_status : viewabilityStatus
    }).then(response => {
      if (response?.data?.status?.code != HTTP_STATUS._200) {
        let { status } = response?.data;
        throw new Error(`${status?.code} ${status?.description}`);
      }
      updateUserSubscription(response?.data?.userDetail?.userSubscription);
    }).catch(error => {
      handleHttpError({
        error,
        request: "userRequest.updateUser::src/components/topbar"
      })
    });
  }

  const toggleUpdateStatus = async () => {

    const { userSettings, userId } = props;

    client && client.services.users.myself.updateAttributes({
      'is_reachable' : activeStatus
    });

    if (!activeStatus) {
      // Update status to active
      const updateUserSettingsResponse = await userRequest.updateUser({
        user_id                   : userId,
        upd_mode                  : UPD_MODE_USER_SETTING,
        who_updated               : userId,
        is_general_sound_on       : 1,
        is_general_alert_on       : 1,
        is_reachability_status_on : 1,
        is_chat_alert_on          : userSettings?.isChatAlertOn,
        is_chat_sound_on          : userSettings?.isChatSoundOn,
        is_voicecall_alert_on     : userSettings?.isVoiceCallAlertOn,
        is_voicecall_sound_on     : userSettings?.isVoiceCallSoundOn,
        is_videocall_alert_on     : userSettings?.isVideoCallAlertOn,
        is_videocall_sound_on     : userSettings?.isVideoCallSoundOn,
        is_notif_alert_on         : userSettings?.isNotifAlertOn,
        is_notif_sound_on         : userSettings?.isNotifSoundOn,
        chat_soundname            : userSettings?.chatSoundName,
        voicecall_soundname       : userSettings?.voiceCallSoundName,
        videocall_soundname       : userSettings?.videoCallSoundName,
        notif_soundname           : userSettings?.notifSoundName
      }).catch(error => { handleHttpError(error, 'appMenu userRequest.updateUser settings')});
      console.log("updateUserSettingsResponse : ", updateUserSettingsResponse)

      if (updateUserSettingsResponse?.data?.status?.code != HTTP_STATUS._200) {
        this.setState({ reachabilityStatus : false })
        let { status } = updateUserSettingsResponse?.data;
        throw new Error(`${status?.code} ${status?.description}`);
      }
      updateUserSettings(updateUserSettingsResponse?.data?.userDetail?.userSetting);
      setActiveStatus(updateUserSettingsResponse?.data?.userDetail?.userSetting?.isReachabilityStatusOn);
    } else {
      // Update status to inactive
      const updateUserSettingsResponse = await userRequest.updateUser({
        user_id                   : userId,
        upd_mode                  : UPD_MODE_USER_SETTING,
        who_updated               : userId,
        is_general_sound_on       : 0,
        is_general_alert_on       : 0,
        is_reachability_status_on : 0,
        is_chat_alert_on          : userSettings?.isChatAlertOn,
        is_chat_sound_on          : userSettings?.isChatSoundOn,
        is_voicecall_alert_on     : userSettings?.isVoiceCallAlertOn,
        is_voicecall_sound_on     : userSettings?.isVoiceCallSoundOn,
        is_videocall_alert_on     : userSettings?.isVideoCallAlertOn,
        is_videocall_sound_on     : userSettings?.isVideoCallSoundOn,
        is_notif_alert_on         : userSettings?.isNotifAlertOn,
        is_notif_sound_on         : userSettings?.isNotifSoundOn,
        chat_soundname            : userSettings?.chatSoundName,
        voicecall_soundname       : userSettings?.voiceCallSoundName,
        videocall_soundname       : userSettings?.videoCallSoundName,
        notif_soundname           : userSettings?.notifSoundName
      }).catch(error => { handleHttpError(error, 'appMenu userRequest.updateUser settings')});
      console.log("updateUserSettingsResponse : ", updateUserSettingsResponse)

      if (updateUserSettingsResponse?.data?.status?.code != HTTP_STATUS._200) {
        let { status } = updateUserSettingsResponse?.data;
        throw new Error(`${status?.code} ${status?.description}`);
      }
      setActiveStatus(updateUserSettingsResponse?.data?.userDetail?.userSetting?.isReachabilityStatusOn);
      updateUserSettings(updateUserSettingsResponse?.data?.userDetail?.userSetting);
    }
  }

  const settingsCollapsibleMenu = () => {
    const { userSubscription } = props;
    const subscriptionPlan = lookUpSubscriptionPlan.find(lookUpSubscriptionPlanItem => lookUpSubscriptionPlanItem.subscriptionPlanId === userSubscription.subscriptionPlanId);
    
    // useEffect(()=>{
    //   console.log("useEffects is here")
    // },[])

    let userSubscriptionDesc = '';
    if (subscriptionPlan) {
      switch (subscriptionPlan.membershipLevelCode) {
        case USER_SUBSCRIPTION_REGULAR:
          userSubscriptionDesc = 'Regular'
          break;
        case USER_SUBSCRIPTION_PRIVACY:
          userSubscriptionDesc = 'Privacy'
          break;
        case USER_SUBSCRIPTION_ELITE:
          userSubscriptionDesc = 'Elite'
          break;
      }
    }

    let viewabilityStatusMenus = [
      {
        membership        : 'Regular Member',
        viewabilityStatus : USER_SUBSCRIPTION_REGULAR,
        isActive          : userSubscription.viewabilityStatus === USER_SUBSCRIPTION_REGULAR,
        isActive          : userSubscription.viewabilityStatus === USER_SUBSCRIPTION_REGULAR,
      },
      {
        membership        : 'Privacy Member',
        viewabilityStatus : USER_SUBSCRIPTION_PRIVACY,
        isActive          : userSubscription.viewabilityStatus === USER_SUBSCRIPTION_PRIVACY,
        isDisable         : userSubscription.membershipLevelCode !== USER_SUBSCRIPTION_PRIVACY && userSubscription.membershipLevelCode !== USER_SUBSCRIPTION_ELITE
      },
      {
        membership        : 'Elite Member',
        viewabilityStatus : USER_SUBSCRIPTION_ELITE,
        isActive          : userSubscription.viewabilityStatus === USER_SUBSCRIPTION_ELITE,
        isDisable         : userSubscription.membershipLevelCode !== USER_SUBSCRIPTION_ELITE
      }
    ];

    const showSubsettingFunction = () => {
      setShowSubSetting(prevIsShow => !prevIsShow);
    }
    
    return (
      <MenuList autoFocusItem={open} id='menu-list-grow' onKeyDown={handleListKeyDown} disablePadding>
        <Box>
          <Box className={classes.gradientCard}>
            <MenuItem className={classes.noHover}>
              <ListItemText primary={`Your profile is: ${activeStatus ? 'Active' : 'Offline'}`}/>
                <ListItemSecondaryAction>
                  <Switch
                    color='primary'
                    edge='end'
                    checked={activeStatus}
                    onClick={toggleUpdateStatus}
                />
              </ListItemSecondaryAction>
            </MenuItem>
            <MenuItem className={`${classes.noHover} ${classes.customMenuItem} ${activeStatus && 'hidden'}`}>
              <ListItemText secondary={'You will still receive messages but you will appear unavailble to respond.'}/>
            </MenuItem>
            <Divider className={classes.divider}/>
            <MenuItem className={classes.menuItemMemberType} onClick={showSubsettingFunction}>
              <ListItemText primary={`${userSubscriptionDesc} Member`} />
              <ListItemIcon className='beforeEnter'>
                <ChevronRightIcon fontSize='small' />
              </ListItemIcon>
            </MenuItem>
            {
              viewabilityStatusMenus.map((item, index) => {
                const { viewabilityStatus, membership, isActive, isDisable } = item;

                return (
                  <MenuItem 
                    value={viewabilityStatus}
                    className={`${classes.menuItemMemberType} ${classes.customMenuItem} ${showSubSetting || 'hidden'}`} key={index} button 
                    onClick={() => updateViewabilityStatus(viewabilityStatus)}
                    disabled={isDisable}
                  >
                    <ListItemText secondary={membership} />
                    <ListItemIcon className='beforeEnter'>
                      {
                        isActive ?
                          <RadioButtonCheckedOutlined fontSize='small' color={'secondary'}/>
                        :
                          <RadioButtonUncheckedOutlined fontSize='small' color={'secondary'}/>
                      }
                    </ListItemIcon>
                  </MenuItem>
                )
              })
            }
          </Box>
          <MenuItem onClick={() => handleMenuItemChange('/settings/rewards')}>Rewards</MenuItem>
          <MenuItem onClick={() => handleMenuItemChange('/settings/notification')}>Settings</MenuItem>
          <MenuItem onClick={() => handleMenuItemChange('/settings/my-profile')}>My Profile</MenuItem>
          <MenuItem onClick={() => handleMenuItemChange('/settings/my-pictures')}>My Pictures</MenuItem>
          <MenuItem onClick={() => handleMenuItemChange('/settings/my-account')}>My Account</MenuItem>
          <MenuItem onClick={() => handleMenuItemChange('/settings/blocked')}>Blocked Profiles</MenuItem>
          <MenuItem onClick={() => handleMenuItemChange('/settings/report-issue')}>Report Issues</MenuItem>
          <MenuItem onClick={() => handleMenuItemChange('/settings/about-us')}>About Us</MenuItem>
          <MenuItem onClick={signOut}>Sign Out</MenuItem>
        </Box>
      </MenuList>
    )
  }

  return (
    <AppBar component={Paper} position='static' elevation={0} className={classes.appBar}>
      <Toolbar className={classes.toolbar}>
        <div className={classes.toolbarTitle}>
          <img src={logo} className={classes.logo} alt='S.O.Search'/>
        </div>
        <TextField
          className={classes.searchbar}
          id='input-with-icon-textfield'
          placeholder='Search in S.O.Search'
          onKeyUp={onSearchbarKeyEnter}
          value={searchName}
          onChange={onfilterSearchbar}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchIcon />
              </InputAdornment>
            ),
            disableUnderline: true
          }}
        />
        {
          authenticated ? 
            <Box className={classes.menuButton}>
              <Typography variant='h6' color='secondary'>
                {`Hi, ${user.userName}`}
              </Typography>
              <Avatar src={profileImage} />
              <IconButton
                color='secondary'
                aria-controls={open ? 'menu-list-grow' : undefined}
                aria-haspopup='true'
                onClick={handleToggle}
                ref={anchorRef}
              >
                <KeyboardArrowDownOutlinedIcon/>
              </IconButton>
              <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{zIndex: 1}}>
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                  >
                    <Paper className={classes.settingsContainer}>
                      <ClickAwayListener onClickAway={handleClose}>
                        {settingsCollapsibleMenu()}
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </Box>
          :
            <Button href='#' color='primary' variant='contained' className={classes.button} onClick={goToSignIn}>
              Sign In
            </Button>
        }
      </Toolbar>
      {
        authenticated &&
        <>
          <div className={classes.tabsContainer}>
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor='secondary'
              textColor='secondary'
              scrollButtons='on'
              aria-label='main tabs'
              className={classes.container}
              TabIndicatorProps={{
                style: { top: 40 }
              }}
            >
              <Tab label='Home' {...a11yProps(0)} className={classes.tab} />
              <Tab label='Views' {...a11yProps(1)} className={classes.tab} />
              <Tab label='Contacts' {...a11yProps(2)} className={classes.tab} />
              <Tab label='Shortlist' {...a11yProps(3)} className={classes.tab} />
              <Tab label='Search' {...a11yProps(4)} className={classes.tab} />
              <Tab label='Calendar' {...a11yProps(5)} className={classes.tab} />
            </Tabs>
            <Box display='flex' flexDirection='row' alignItems='center' className={classes.subTabContainer}>
              { 
                hasSubTab &&
                  <Tabs
                    value={subTabValue}
                    onChange={onSubTabChanged}
                    indicatorColor='secondary'
                    textColor='secondary'
                    scrollButtons='on'
                    aria-label='scrollable auto tabs example'
                    className={`${classes.container} subTabs ${props.topBarSibling && classes.subTabWithSibling}`}
                    TabIndicatorProps={{
                      style: { top: 40 }
                    }}
                  >
                    {
                      subTabKeys().map((key, index) => {
                        const pathname = history.location.pathname.split('/');
                        return <Tab label={subTabs[pathname[1]][key]} {...a11yProps(index)} key={index} className={classes.tab} />
                      })
                    }
                  </Tabs>
              }
              {
                (value === 5) && <Button color='secondary' variant='contained' onClick={() => { 
                  setEventDetailsModalOpen(true);
                  setEventOperation(ADD_EVENT)
                }} startIcon={<AddCircleIcon color='primary' />}>Add Event</Button>
              }
            </Box>
          </div>
        </>
      }
    </AppBar>
  );
};

const mapStateToProps = (state) => {
  console.log('topbar/index.js:mapStateToProps(state)');
  console.log(state);
  return {
    authenticated          : state?.Session?.authenticated,
    user                   : state?.Session?.user?.user,
    userId                 : state?.Session?.user?.user.userId,
    client                 : state?.Message?.twilio?.client,
    userSubscription       : state?.User?.userSubscription,
    userSettings           : state?.User?.userSettings,
    lookUpSubscriptionPlan : state?.Lookup?.lookUpSubscriptionPlan,
  };
}

export default connect(mapStateToProps, { 
  getLookUpFields, 
  setEventDetailsModalOpen,
  setEventOperation,
  signOutUser, 
  updateUser,
  updateUserSubscription,
  updateUserSettings,
  updateTwilio
})(TopBar);
