import { combineReducers } from 'redux';
import { sessionReducer } from 'redux-react-session';
import action from './action';
import Calendar from './calendar/reducer';
import Connection from './connection/reducer';
import Lookup from './lookup/reducer';
import Message from './message/reducer';
import Notification from './notification/reducer';
import Search from './search/reducer';
import Tutorial from './tutorial/reducer';
import User from './user/reducer';

const appReducers = combineReducers({
  Calendar,
  Connection,
  Lookup,
  Message,
  Notification,
  Search,
  Tutorial,
  User,
  Session: sessionReducer
});

const rootReducer = (state, act) => {

  switch (act.type) {

    case action.SIGN_OUT:{
      console.log("redux/reducers.js:RESET_APP");
      const { Lookup } = state;
      const initialState = Lookup;
      
      return appReducers(initialState, act);
    }
    case action.CLEAR_USER_DATA:{
      console.log("redux/reducers.js:RESET_APP");
      const  lookupState  = state.Lookup;
      const  sessionState = state.Session;
      const  userState    = sessionState.user

      return {
        ...appReducers({lookupState,sessionState,userState}, act),
        Session: { ...sessionState, authenticated : 'false',
                  user: { ...userState, isSignedIn: 'false'},
                 },
        Lookup: lookupState
      }
    }
    default:
      return appReducers(state, act);
  }
}

export default rootReducer;