import { makeStyles } from '@material-ui/core/styles';
import colors from '../../utils/colors';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.paper,
  },

  container: {
    // margin: '0',
    display: 'flex',
    position: 'absolute',
    top: 110,
    zIndex: 0,
    padding: 0
    // marginLeft: 10
  },

  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
  },

  drawerPaper: {
    width: drawerWidth,
    height: 1000,
    top: 'unset',
    backgroundColor: 'transparent',
    left: 'unset',
    border: 'none',
    borderRight: '1px solid rgba(0, 0, 0, 0.12)',
    position: 'absolute',
    '& .MuiList-padding': {
      paddingTop: theme.spacing(5)
    }
  },

  toolbar: theme.mixins.toolbar,

  content: {
    flex: 1,
    padding: theme.spacing(3),
    '& > .MuiTypography-root:first-child': {
      color: colors.PRIMARY
    }
  },

  listItem: {
    '&:hover': {
      // backgroundColor: colors.LIGHT_ORANGE,
    },
  },

  selectedListItem: {
    // backgroundColor: colors.PRIMARY,
    color: colors.PRIMARY,
    '& > .text-bold > span': {
      fontWeight: 'bold'
    },
  },

  collapsibleItemList: {
    cursor: 'pointer',
    fontSize: 14,
    lineHeight: 2,
    color: colors.TEXT_GRAY,
    paddingLeft: 20,
    // '&:hover': {
    //   textDecoration: 'none',
    //   // backgroundColor: colors.LIGHT_ORANGE,
    // },
  },

  collapsibleItemListSelected: {
    cursor: 'pointer',
    fontSize: 14,
    lineHeight: 2,
    color: colors.PRIMARY,
    paddingLeft: 20
  },

  // NOTIFICATION SETTINGS
  formControlLabel: {
    marginLeft: theme.spacing(5),
    '& .MuiFormControlLabel-label': {
      color: colors.TEXT_GRAY,
    }
  },

  notificationContainer: {
    '& .MuiBox-root': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingLeft: 30,
      paddingTop: 30
    },

    '& > p, hr': {
      fontWeight: 'bold',
      marginLeft: 30
    },

    '& p': {
      color: colors.PRIMARY_TEXT
    }
  },

  // MY ACCOUNT
  accountButton: {
    marginBottom : theme.spacing(2),
    marginTop : theme.spacing(2),
  },

  box: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  link: {
    cursor: 'pointer',
    color: colors.PRIMARY,
    marginBottom: 10,
  },

  // Change Password
  changePassword: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),

    '& > .MuiFormControl-root': {
      width: 250,
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },

    '& > .MuiButtonBase-root': {
      width: 250,
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
  },

  // ABOUT US
  contentButton: {
    marginTop : theme.spacing(2),

    '&:hover': {
      backgroundColor: colors.HOVER_DEFAULT,
      // cursor: 'pointer'
    },
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  aboutUsBoxHeader: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing(4, 0)
  },

  aboutUsBoxContent: {
    '& .MuiBox-root' : {
      marginLeft: theme.spacing(3)
    },

    '& > ul': {
      marginLeft: theme.spacing(5)
    },

    '& > ul > li': {
      listStyleType: 'disc'
    }
  },

  aboutUsContainer: {
    paddingLeft: 30,
  },  

  btnPrimary: {
    background: colors.PRIMARY,
    color: colors.WHITE,
    borderRadius: 10
  },

  menuList: {
    paddingTop: 10,
    paddingLeft: 25
  },

  menuListHeader: {
    paddingTop: 48, 
    paddingBottom: 15, 
    paddingLeft: 10, 
    fontSize: 20,
    fontWeight: 'bold',
    color: colors.PRIMARY,
    '& > .text-bold > span': {
      fontWeight: 'bold'
    }
  },

}));

export default useStyles;