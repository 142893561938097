import { enqueueSnackbar } from "notistack";

const toast = ({ variant = "default", message, style = {} }) => {
  enqueueSnackbar(message, {
    variant,
    style,
  });
};

export { toast };
