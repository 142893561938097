import React from 'react';
import moment from 'moment';
import { Typography, Divider, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import useStyles from './style';
import { 
  CALL_STATUS_INBOUND_AUDIO,
  CALL_STATUS_INBOUND_VIDEO,
  CALL_STATUS_MISSED_AUDIO,
  CALL_STATUS_MISSED_VIDEO,
  CALL_STATUS_OUTBOUND_AUDIO,
  CALL_STATUS_OUTBOUND_VIDEO,
  HISTORY_FILTER_AUDIO, 
  HISTORY_FILTER_CHAT,
  HISTORY_FILTER_EVENT, 
  HISTORY_FILTER_NOTIFICATION,
  HISTORY_FILTER_VIDEO,
} from '../../utils/constants';

import { notificationContent } from '../../utils/notificationcontent';

import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import EventIcon from '@material-ui/icons/Event';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PhoneIcon from '@material-ui/icons/Phone';
import VideocamIcon from '@material-ui/icons/Videocam';

const HistoryCard = (props) => {
  const { key, historyType, detail, time, userName } = props;
  const classes = useStyles();
  let icon;
  let historyDetail = detail;

  if (historyType === HISTORY_FILTER_CHAT) {
    icon = <ChatBubbleIcon color='primary' />
  } else if (historyType === HISTORY_FILTER_AUDIO && detail === 'INC') {
    historyDetail = CALL_STATUS_INBOUND_AUDIO
    icon = <PhoneIcon color='primary' />
  } else if (historyType === HISTORY_FILTER_AUDIO && detail === 'OUT') {
    historyDetail = CALL_STATUS_OUTBOUND_AUDIO
    icon = <PhoneIcon color='primary' />
  } else if (historyType === HISTORY_FILTER_AUDIO && detail === 'MIS') {
    historyDetail = CALL_STATUS_MISSED_AUDIO
    icon = <PhoneIcon color='primary' />
  } else if (historyType === HISTORY_FILTER_VIDEO && detail === 'INC') {
    historyDetail = CALL_STATUS_INBOUND_VIDEO
    icon = <VideocamIcon color='primary' />
  } else if (historyType === HISTORY_FILTER_VIDEO && detail === 'OUT') {
    historyDetail = CALL_STATUS_OUTBOUND_VIDEO
    icon = <VideocamIcon color='primary' />
  } else if (historyType === HISTORY_FILTER_VIDEO && detail === 'MIS') {
    historyDetail = CALL_STATUS_MISSED_VIDEO
    icon = <VideocamIcon color='primary' />
  } else if (historyType === HISTORY_FILTER_EVENT) {
    icon = <EventIcon color='primary' />
  } else if (historyType === HISTORY_FILTER_NOTIFICATION) {
    historyDetail = notificationContent(userName)[detail]?.notifHistoryDetail;
    icon = <NotificationsIcon color='primary' />
  }

  return (
    <React.Fragment key={key}>
      <ListItem>
        <ListItemIcon>
          {icon}
        </ListItemIcon>
        <ListItemText>
          <Typography
            variant="h6"
            color="textPrimary"
            display='flex'
            className={classes.historyDetail}
          >
            {historyDetail}
            <Typography
              display='inline'
              variant="subtitle1"
              color="textPrimary"
              style={{ marginLeft : 5 }}
            >
              {moment.unix(time).fromNow()}
            </Typography>
          </Typography>
        </ListItemText>
      </ListItem>
      <Divider />
    </React.Fragment>
  )
}

export default HistoryCard;