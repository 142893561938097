import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../utils/colors';

const useStyles = makeStyles(theme => ({

  backdrop: {
    backgroundColor : 'rgba(0,0,0,0)'
  },

  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius : 10,
    boxShadow: theme.shadows[5],
    outline : 0,
    padding: theme.spacing(1),
    position: 'absolute'
  },

  filterLabel: {
    fontWeight: 'bold'
  },

  filterOption: {
    marginLeft: 5
  },

  infoBar:{
    display: 'flex',
    flexWrap: 'wrap',
    paddingBottom: 10,
    justifyContent: 'space-between',
    '& > .MuiTypography-root:first-child': {
      fontWeight: 'bold',
      fontSize: 18,
      padding: 12
    },
  },

}));

export default useStyles;