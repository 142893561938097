import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../../utils/colors';

const useStyles = makeStyles(theme => ({
  formControl: {
    height : 40,
    minWidth: 120,

    '& > .MuiInputBase-root': {
      height : 40,
    }
  },

  modalWrapper: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
  },
  
  modalContent: {
    width: '100%',
    height: '100%',
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    textAlign: 'center',
    padding: 20,

    '& > .MuiBox-root > .MuiFormControl-root:last-of-type': {
      width : 175,
    },
  },

  closeButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    minWidth: 'min-content'
  }
}));

export default useStyles;