import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Button, Box, Checkbox,FormControl, FormControlLabel, FormGroup, Radio, RadioGroup, TextField, CircularProgress } from '@material-ui/core';

import useStyles from './style';
import { 
  LOOK_UP_BODY_TYPE, LOOK_UP_ETHNICITY, LOOK_UP_EDUC_LEVEL, LOOK_UP_EYE_COLOR, LOOK_UP_HEIGHT, LOOK_UP_HAIR_COLOR, LOOK_UP_HOBBY, LOOK_UP_INCOME_LEVEL, LOOK_UP_KID_SITUATION, LOOK_UP_LANGUAGE, LOOK_UP_PET_POLICY, LOOK_UP_POS_POLITICS, LOOK_UP_PREF_SMOKING, LOOK_UP_ALCOHOL, LOOK_UP_PROFESSION, LOOK_UP_RELATIONSHIP_TYPE, LOOK_UP_RELIG_BELIEF, 
  UPD_MODE_USER_BODY_TYPE, UPD_MODE_USER_EDUC_LEVEL, UPD_MODE_USER_ETHNICITY, UPD_MODE_USER_EYE_COLOR, UPD_MODE_USER_HAIR_COLOR, UPD_MODE_USER_INCOME_LEVEL, UPD_MODE_USER_KID_SITUATION, UPD_MODE_USER_LANGUAGE, UPD_MODE_USER_POS_POLITICS, UPD_MODE_USER_PREF_ALCOHOL, UPD_MODE_USER_PREF_SMOKING, UPD_MODE_USER_RELATIONSHIP_STATUS, UPD_MODE_USER_RELATIONSHIP_TYPE, UPD_MODE_USER_RELIGION, UPD_MODE_USER_HEIGHT,
  ALERT_SEVERITY_SUCCESS, ALERT_SEVERITY_ERROR, TOAST_AXIOS_REQUEST_ERROR,UPD_MODE_USER_PET_POLICY, UPD_MODE_USER_PROFESSION, LOOK_UP_CODE_NOP, UPD_MODE_USER_HOBBY, LOOK_UP_GENDER, UPD_MODE_USER_LOOKING_FOR_GENDER, LOOK_UP_EXER_REGIMEN, UPD_MODE_USER_EXER_REGIMEN, HTTP_STATUS, LOOK_UP_RELATIONSHIP_STATUS } from '../../../../utils/constants';
import { userRequest } from '../../../../service/requests';
import { handleHttpError } from '../../../../utils/helper';
const Preference = forwardRef((props, ref) => {
    const classes = useStyles();

    const arrLookUp = [
        {
          lookUp          : LOOK_UP_RELATIONSHIP_STATUS,
          lookUpPreference: 'relationship_status',
          updMode         : UPD_MODE_USER_RELATIONSHIP_STATUS,
          lookUpState     : 'lookUpRelStatus',
          attribute       : 'relationshipStatus'
        },
        {
          lookUp          : LOOK_UP_RELATIONSHIP_TYPE,
          lookUpPreference: 'relationship_type',
          updMode         : UPD_MODE_USER_RELATIONSHIP_TYPE,
          lookUpState     : 'lookUpRelType',
          attribute       : 'relationshipType'
        },
        {
          lookUp          : LOOK_UP_GENDER,
          lookUpPreference: 'looking_for_gender',
          updMode         : UPD_MODE_USER_LOOKING_FOR_GENDER,
          lookUpState     : 'lookUpGender',
          attribute       : 'lookingForGender'
        },
        {
          lookUp          : LOOK_UP_LANGUAGE,
          lookUpPreference: 'language_code',
          updMode         : UPD_MODE_USER_LANGUAGE,
          lookUpState     : 'lookUpLanguage',
          attribute       : 'languageCode'
        },
        {
          lookUp          : LOOK_UP_BODY_TYPE,
          lookUpPreference: 'body_type',
          updMode         : UPD_MODE_USER_BODY_TYPE,
          lookUpState     : 'lookUpBodyType',
          attribute       : 'bodyType'
        },
        {
          lookUp          : LOOK_UP_HAIR_COLOR,
          lookUpPreference: 'hair_color',
          updMode         : UPD_MODE_USER_HAIR_COLOR,
          lookUpState     : 'lookUpHairColor',
          attribute       : 'hairColor'
        },
        {
          lookUp          : LOOK_UP_EYE_COLOR,
          lookUpPreference: 'eye_color',
          updMode         : UPD_MODE_USER_EYE_COLOR,
          lookUpState     : 'lookUpEyeColor',
          attribute       : 'eyeColor'
        },
        {
          lookUp          : LOOK_UP_KID_SITUATION,
          lookUpPreference: 'kid_situation',
          updMode         : UPD_MODE_USER_KID_SITUATION,
          lookUpState     : 'lookUpKidSituation',
          attribute       : 'kidSituation'
        },
        {
          lookUp          : LOOK_UP_EDUC_LEVEL,
          lookUpPreference: 'educ_level',
          updMode         : UPD_MODE_USER_EDUC_LEVEL,
          lookUpState     : 'lookUpEducLevel',
          attribute       : 'educLevel'
        },
        {
          lookUp          : LOOK_UP_ALCOHOL,
          lookUpPreference: 'pref_alcohol',
          updMode         : UPD_MODE_USER_PREF_ALCOHOL,
          lookUpState     : 'lookUpPrefAlcohol',
          attribute       : 'prefAlcohol'
        },
        {
          lookUp          : LOOK_UP_PREF_SMOKING,
          lookUpPreference: 'pref_smoking',
          updMode         : UPD_MODE_USER_PREF_SMOKING,
          lookUpState     : 'lookUpPrefSmoking',
          attribute       : 'prefSmoking'
        },
        {
          lookUp          : LOOK_UP_EXER_REGIMEN,
          lookUpPreference: 'exer_regimen',
          updMode         : UPD_MODE_USER_EXER_REGIMEN,
          lookUpState     : 'lookUpExerRegimen',
          attribute       : 'exerRegimen'
        },
        {
          lookUp          : LOOK_UP_ETHNICITY,
          lookUpPreference: 'ethnicity',
          updMode         : UPD_MODE_USER_ETHNICITY,
          lookUpState     : 'lookUpEthnicity',
          attribute       : 'ethnicity'
        },
        {
          lookUp          : LOOK_UP_RELIG_BELIEF,
          lookUpPreference: 'relig_belief',
          updMode         : UPD_MODE_USER_RELIGION,
          lookUpState     : 'lookUpReligBelief',
          attribute       : 'religBelief'
        },
        {
          lookUp          : LOOK_UP_PROFESSION,
          lookUpPreference: 'profession',
          updMode         : UPD_MODE_USER_PROFESSION,
          lookUpState     : 'lookUpProfession',
          attribute       : 'profession'
        },
        {
          lookUp          : LOOK_UP_PET_POLICY,
          lookUpPreference: 'pet_policy',
          updMode         : UPD_MODE_USER_PET_POLICY,
          lookUpState     : 'lookUpPetPolicy',
          attribute       : 'petPolicy'
        },
        // {
        //   lookUp          : LOOK_UP_INCOME_LEVEL,
        //   lookUpPreference: 'income_level',
        //   updMode         : UPD_MODE_USER_INCOME_LEVEL,
        //   lookUpState     : 'lookUpIncomeLevel',
        //   attribute       : 'incomeLevel'
        // },
        {
          lookUp          : LOOK_UP_POS_POLITICS,
          lookUpPreference: 'pos_politics',
          updMode         : UPD_MODE_USER_POS_POLITICS,
          lookUpState     : 'lookUpPosPolitics',
          attribute       : 'posPolitics'
        },
        {
          lookUp          : LOOK_UP_HOBBY,
          lookUpPreference: 'hobby_code',
          updMode         : UPD_MODE_USER_HOBBY,
          lookUpState     : 'lookUpHobbyCode',
          attribute       : 'hobbyCode'
        },
        {
          lookUp          : LOOK_UP_HEIGHT,
          lookUpPreference: 'height',
          updMode         : UPD_MODE_USER_HEIGHT,
          lookUpState     : 'lookUpHeight',
          attribute       : 'height'
        },
      ]
      
    const { isSaving, setIsSaving, setOpenedIndex, index, Lookup, multiple, required, showAlert, updateSession, updateUserField, user, userField, label } = props;
    const [preference, setPreference] = useState(multiple ? userField.userPreference[arrLookUp[index].attribute] : userField.userPreference[arrLookUp[index].attribute] == null ? '' : userField.userPreference[arrLookUp[index].attribute]);
    const [religion, setReligion] = useState(userField.userPreference.religBeliefDesc);
    const [relationshipStatus, setRelationshipStatus] = useState(userField.userPreference.relationshipStatusDesc);
  
    const selectPreference = event => {
      console.log('Preference.selectPreference()');
      console.log('event : ', event.target.value, event.target.name);
      
      if (multiple) {
        if(preference?.includes(event?.target?.value)) {
          setPreference(preference.filter(value => value !== event?.target?.value));
        } else {
          setPreference([...preference, event?.target?.value]);
        }
      } else {
        setPreference(event?.target?.value);
      }
  
    };
  
    const savePreference = async () => {
      console.log('preference : ', preference);
  
      if (!preference?.length && required) {
        const message = multiple ? 'Required. Select at least one.' : 'Required. Select one.';
        showAlert(ALERT_SEVERITY_ERROR, message);
        return
      }
  
      const updMode                    = arrLookUp[index]?.updMode
      const lookUpPreference           = arrLookUp[index]?.lookUpPreference
  
      setIsSaving(prevIsSaving => !prevIsSaving);
  
      let payload = {
        user_id             : user.userId,
        upd_mode            : updMode,
        who_updated         : user.userId,
        [lookUpPreference]  : preference.toString()
      }

      if (religion?.length && preference === 'OTH' && lookUpPreference === 'relig_belief') {
        payload.relig_belief_desc = religion;
      } else if (userField?.userPreference?.religBeliefDesc?.length && lookUpPreference === 'relig_belief' && preference != 'OTH') {
        payload.relig_belief_desc = '';
        setReligion('');
      }
      
      if (relationshipStatus?.length && preference === 'OTH' && lookUpPreference === 'relationship_status') {
        payload.relationship_status_desc = relationshipStatus;
      } else if (userField?.userPreference?.relationshipStatusDesc?.length && lookUpPreference === 'relationship_status' && preference != 'OTH') {
        payload.relationship_status = '';
        setRelationshipStatus('');
      }
  
      await userRequest.updateUser(payload)
      .then(response => {
        console.log('response : ', response);
  
        if (response?.data?.status?.code != HTTP_STATUS._200) {
          let { status } = response?.data;
          throw new Error(`${status?.code} ${status?.description}`);
        }
        updateUserField(response?.data?.userDetail?.userField)
        updateSession(response?.data?.userDetail);
        showAlert(ALERT_SEVERITY_SUCCESS, `${label} has successfully updated.`);
      }).catch(error => {
        handleHttpError({
          error,
          request: 'userRequest.updateUser::src/views/settings/my-profile/preference'
        })
      }).finally(() => {
        setIsSaving(prevIsSaving => !prevIsSaving); 
        setOpenedIndex(prevOpenedIndex => !prevOpenedIndex); 
      })
    }
  
    useImperativeHandle(ref, () => ({
      async triggerSave() {
        await savePreference();
      }
    }));
  
    const handleReligBeliefInputChange = (event) => {
      let value = event.target.value;
      setReligion(value);
    }
   
    const handleRelationshipStatusInputChange = (event) => {
      let value = event.target.value;
      setRelationshipStatus(value);
    }
    const lookUpPreference           = arrLookUp[index]?.lookUpPreference
    return (
        <Box>
            <Box className={classes.preferenceContainer}>
            <RadioGroup aria-label="position" className={classes.radioGroup} name="preference" value={preference} onChange={selectPreference} >
            {
                multiple ?
                <FormControl component="fieldset">
                <FormGroup className={classes.formControl}>
                    <Box>
                    {
                        Lookup[arrLookUp[index]?.lookUpState].map((value, index) => 
                        {
                            if (index >= 0 && index <= 10) {
                            return (
                                <FormControlLabel
                                key={index}
                                value={value?.code}
                                control={<Checkbox checked={preference?.includes(value?.code)} color="primary"/>}
                                onChange={selectPreference}
                                label={value?.description}
                                labelPlacement="end"
                                />
                            )
                            }
                        }
                        )
                    }
                    </Box>
                    <Box>
                    {
                        Lookup[arrLookUp[index]?.lookUpState].map((value, index) => 
                        {
                            if (index >= 11 && index <= 21) {
                            return (
                                <FormControlLabel
                                key={index}
                                value={value?.code}
                                control={<Checkbox checked={preference?.includes(value.code)} color="primary"/>}
                                onChange={selectPreference}
                                label={value?.description}
                                labelPlacement="end"
                                />
                            )
                            }
                        }
                        )
                    }
                    </Box>
                    <Box>
                    {
                        Lookup[arrLookUp[index]?.lookUpState].map((value, index) => 
                        {
                            if (index >= 22 && index <= 32) {
                            return (
                                <FormControlLabel
                                key={index}
                                value={value?.code}
                                control={<Checkbox checked={preference?.includes(value?.code)} color="primary"/>}
                                onChange={selectPreference}
                                label={value?.description}
                                labelPlacement="end"
                                />
                            )
                            }
                        }
                        )
                    }
                    </Box>
                    <Box>
                    {
                        Lookup[arrLookUp[index]?.lookUpState].map((value, index) => 
                        {
                            if (index >= 33 && index <= 43) {
                            return (
                                <FormControlLabel
                                key={index}
                                value={value?.code}
                                control={<Checkbox checked={preference?.includes(value?.code)} color="primary"/>}
                                onChange={selectPreference}
                                label={value?.description}
                                labelPlacement="end"
                                />
                            )
                            }
                        }
                        )
                    }
                    </Box>
                </FormGroup>
                </FormControl>
                : 
                Lookup[arrLookUp[index]?.lookUpState].filter((value) => value?.code != LOOK_UP_CODE_NOP).map((value, index) => 
                    {
                    return (
                        <>
                        <FormControlLabel
                            key={index}
                            value={value?.code}
                            control={<Radio color="primary" size="small" className={classes.radio}/>}
                            label={value?.description}
                            labelPlacement="end"
                        />
                        {
                            (value?.code === 'OTH' && preference === 'OTH' && lookUpPreference === 'relig_belief') && 
                            <TextField name="religion" className={classes.textField} 
                            size="small" variant="outlined" placeholder="Please specify" 
                            value={religion} onChange={handleReligBeliefInputChange} required
                            />
                        }
                         {
                            (value?.code === 'OTH' && preference === 'OTH' && lookUpPreference === 'relationship_status') && 
                            <TextField name="relationshipStatus" className={classes.textField} 
                            size="small" variant="outlined" placeholder="Please specify" 
                            value={relationshipStatus} onChange={handleRelationshipStatusInputChange} required
                            />
                        }
                        </>
                    )
                    }
                )
            }
            </RadioGroup>
            </Box>
            <Box className={classes.collapseBoxWrapper}>
                <Button disabled={isSaving} className={classes.buttonSave} onClick={savePreference} color="primary" variant="contained">
                {
                    isSaving ?
                        <CircularProgress size={24}/>
                    : 
                        'Save'
                }
                </Button>
            </Box>
        </Box>

    )
  })

export default Preference;