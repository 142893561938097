import React, { useState } from 'react';

import { Box, Button, MenuItem, FormHelperText, FormControl, RadioGroup, FormControlLabel, LinearProgress, Radio, Select, Typography, TextField, Link, CircularProgress, Grid, Hidden } from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';

import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';

import useStyles from './style';
import colors from '../../../utils/colors';
import countryByDialInfo from '../../../assets/json/country-by-dial-info.json';
import SOS_GRADIENT from '../../../assets/images/logo-text-gradient.png';

import { getAge, formatPhoneNumber, getFormattedDateTime, handleHttpError } from '../../../utils/helper';
import { userRequest } from '../../../service/requests';
import { HTTP_STATUS } from '../../../utils/constants';

const BasicInfo = (props) => {
  const classes = useStyles();

  const { next, updateUser, updateToken } = props;
  const [isSaving, setIsSaving] = useState(false);

  const [state, setState] = useState({
    firstName           : '',
    isFirstNameError    : false,
    firstNameErrorDesc  : '',
    lastName            : '',
    isLastNameError     : false,
    lastNameErrorDesc   : '',
    gender              : '',
    isGenderError       : false,
    genderErrorDesc     : '',
    birthday            : '',
    isBirthdayError     : false,
    birthdayErrorDesc   : '',
    email               : '',
    countryCode         : '+1',
    mobileNo            : '',
    isMobileNoError     : false,
    mobileNoErrorDesc   : ''
  });
  const { 
      firstName, isFirstNameError, firstNameErrorDesc,
      lastName, isLastNameError, lastNameErrorDesc,
      gender, isGenderError, genderErrorDesc,
      birthday, isBirthdayError, birthdayErrorDesc,
      email,
      countryCode, mobileNo, isMobileNoError, mobileNoErrorDesc } = state;

  const [isEmailError, setIsEmailError]     = useState(false);
  const [emailErrorDesc, setEmailErrorDesc] = useState('');

  const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const handleInputChange = (event) => {
    let value = event.target.value;

    if (event.target.name == 'email') {
      const isEmailInvalid = !emailPattern.test(value);

      setIsEmailError(isEmailInvalid ? true : false)
      setEmailErrorDesc(isEmailInvalid ? 'Invalid email format.' : '');
    } else if (event.target.name == 'mobileNo') {
      // value = value.replace(/[^0-9]/g, '');

      // if (value.length > 10)
      //   return

      value = formatPhoneNumber(value);
    }

    setState({
      ...state,
      [event.target.name] : value
    });
  };

  const handleCodeChange = (event) => {
    console.log('event : ', event.target.value);
    setState({
      ...state,
      countryCode : event.target.value
    })
  }

  const validateEmail = async () => {
    if (email == '') {
      setIsEmailError(true)
      setEmailErrorDesc('Required');
    } else {
      if (!isEmailError) {
        userRequest.checkEmail({
          email
        }).then(response => {
          console.log('response : ', response);

          if (response?.data?.code == HTTP_STATUS._469) {
            setIsEmailError(true)
            setEmailErrorDesc('This email is already used.');
          } else {
            setIsEmailError(false)
            setEmailErrorDesc('');
          }
        }).catch(error => {
          handleHttpError({
            error,
            request: 'userRequest.checkEmail::src/views/registration/basic-info'
          })
          
        });
      }
    }
  }

  const alphabeticallySort = countryByDialInfo.filter((countryItem) => countryItem.dial_code != null).sort(function(firstCountryItem, secondCountryItem) {
    var dial_codeA = firstCountryItem.code.toLowerCase(); 
    var dial_codeB = secondCountryItem.code.toLowerCase();
    if (dial_codeA < dial_codeB) {
      return -1; 
    }
    if (dial_codeA > dial_codeB) {
      return 1; 
    }
    return 0; 
  })

  const addUserService = () => {
    userRequest.addUser({
      email       : email,
      phone_no    : `${countryCode}${mobileNo.replace(/[^0-9]+/g, '')}`,
      first_name  : firstName,
      last_name   : lastName,
      gender      : gender,
      birthday    : birthday,
      who_added   : 1
    }).then(response => {
      console.log('response : ', response);

      if (response?.data?.status?.code == HTTP_STATUS._200) {
        const user  = response.data.user;
        const token = response.data.token;

        setIsSaving(prevIsSaving => !prevIsSaving);
        updateUser(user);
        updateToken(token);

        next();
      }
    }).catch(error => {
      setIsSaving(prevIsSaving => !prevIsSaving);
      handleHttpError({
        error,
        request: 'userRequest.addUser::src/views/registration/basic-info'
      })
    }); 
  }

  const areInputValid = () => {
    let isValid = true;
    if (
      firstName === '' || 
      lastName === '' || 
      gender === '' || 
      email === '' || 
      mobileNo === '' ||
      getAge(birthday) < 18
    ) {
      isValid = false
    }


    setIsEmailError(email === '' ? true : false);
    setEmailErrorDesc(email === '' ? 'Required.' : '');
    setState({
      ...state,
      isFirstNameError    : firstName === '' ? true : false,
      firstNameErrorDesc  : firstName === '' ? 'Required.' : '',
      isLastNameError     : lastName === '' ? true : false,
      lastNameErrorDesc   : lastName === '' ? 'Required.' : '',
      isGenderError       : gender === '' ? true : false,
      genderErrorDesc     : gender === '' ? 'Required.' : '',
      isBirthdayError     : getAge(birthday) < 18 ? true : false,
      birthdayErrorDesc   : getAge(birthday) < 18 ? 'You must be at least 18 years old to use this app.' : '',
      isMobileNoError     : mobileNo === '' ? true : false,
      mobileNoErrorDesc   : mobileNo === '' ? 'Required.' : ''  
    });

    return isValid;
  }

  const handleNext = async () => {
    console.log('mobileNo : ', mobileNo);
    
    if (areInputValid()) {
      setIsSaving(prevIsSaving => !prevIsSaving);
      userRequest.checkEmail({
        email
      }).then(response => {
        console.log('response : ', response);

        if (response?.data?.code == HTTP_STATUS._469) {
          setIsEmailError(true);
          setEmailErrorDesc('This email is already used.');
          setIsSaving(prevIsSaving => !prevIsSaving);
        } else {
          addUserService();
        }
      }).catch(error => {
        handleHttpError({
          error,
          request: 'userRequest.checkEmail::src/views/registration/basic-info'
        })
      });
    }
  }

  return (
    <>
      <Grid item xs={12} className={classes.gridHeader}>
        <img src={SOS_GRADIENT} className={classes.sos} alt='logo'/>
        <Box display='flex' flexDirection='column'>
          <Typography variant='caption' color='textPrimary' className={classes.description}>
            {`Have an account? `}
            <Link href='/?signin=true' variant='caption' color='primary'>
              Sign In
            </Link>
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box className={classes.content}>
          <Typography variant='h6' color='primary' className={`text-bold`} align='center'>
            Join Today!
          </Typography>
          <Typography variant='subtitle2' color='textPrimary' className={`text-bold`} align='center' >
            Setup an account and start your journey
          </Typography>
        </Box>
      </Grid>
      <Grid item md={6} className={classes.gridItem}>
        <Box className={classes.content}>
          <TextField
            label={'First Name'}
            name='firstName'
            value={firstName}
            onChange={handleInputChange}
            className={classes.textField}
            required
            error={isFirstNameError}
            helperText={firstNameErrorDesc}
            variant='outlined'
            size='small'
          />
          <TextField
            label={'Last Name'}
            name='lastName'
            value={lastName}
            onChange={handleInputChange}
            className={classes.textField}
            required
            error={isLastNameError}
            helperText={lastNameErrorDesc}
            variant='outlined'
            size='small'
          />
          <TextField
            label={'Email'}
            name='email'
            value={email}
            onChange={handleInputChange}
            onBlur={validateEmail}
            className={classes.textField}
            required
            error={isEmailError}
            helperText={emailErrorDesc}
            variant='outlined'
            size='small'
          />
          <Box className={classes.mobileNumber}>
            <Typography variant='subtitle2' color='textPrimary'>
              Mobile Number
            </Typography>
            <Box>
              <FormControl className={classes.formControl} error={isMobileNoError}>
                <Select
                  value={countryCode}
                  onChange={handleCodeChange}
                  variant='outlined'
                >
                  {
                    alphabeticallySort.map((countryItem, index) => 
                      <MenuItem key={index} value={countryItem.dial_code}>
                        {`${countryItem.flag} ${countryItem.code} ${countryItem.dial_code}`}
                      </MenuItem>    
                    )
                  }
                </Select>
              </FormControl>
              <TextField
                placeholder='(XXX) XXX-XXXX'
                name='mobileNo'
                value={mobileNo}
                onChange={handleInputChange}
                required
                error={isMobileNoError}
                variant='outlined'
                // size='small'
              />
            </Box>
            {
              isMobileNoError && <FormHelperText>{mobileNoErrorDesc}</FormHelperText>
            }
          </Box>
          <Hidden smUp>
            <Box className={classes.gender}>
              <Typography variant='subtitle2' color='textPrimary' className={isGenderError && classes.genderFieldError}>
                Gender *
              </Typography>
              <RadioGroup aria-label='position' className={classes.radioGroup} name='gender' value={gender} onChange={handleInputChange} row>
                <FormControlLabel
                  value='MAL'
                  control={<Radio color='primary' />}
                  label='Male'
                  labelPlacement='end'
                  color={colors.PRIMARY_TEXT}
                />
                <FormControlLabel
                  value='FEM'
                  control={<Radio color='primary' />}
                  label='Female'
                  labelPlacement='end'
                />
              </RadioGroup>
              {
                isGenderError && <FormHelperText>{genderErrorDesc}</FormHelperText>
              }
            </Box>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                variant='inline'
                inputVariant='outlined'
                label='Birthday'
                InputLabelProps={{ shrink: birthday ? true : undefined }}
                name='birthday'
                format='MM/dd/yyyy'
                maxDate={moment().subtract(18, 'years').format('YYYY-MM-DD')}
                value={birthday}
                onChange={ 
                  (date) => handleInputChange({
                    target: {
                      name: 'birthday',
                      value: getFormattedDateTime(date, null, 'YYYY-MM-DD')
                    }
                  })
                }
                className={classes.textField}
                required
                error={isBirthdayError}
                helperText={birthdayErrorDesc}
                disableFuture
                autoOk
              /> 
            </MuiPickersUtilsProvider>
          </Hidden>
        </Box>
      </Grid>
      <Hidden smDown>
        <Grid item md={6} className={classes.gridItem}>
          <Box className={classes.content}>
            {/* GENDER */}
            <Box className={classes.gender}>
              <Typography variant='subtitle2' color='textPrimary' className={isGenderError && classes.genderFieldError}>
                Gender *
              </Typography>
              <RadioGroup aria-label='position' className={classes.radioGroup} name='gender' value={gender} onChange={handleInputChange} row>
                <FormControlLabel
                  value='MAL'
                  control={<Radio color='primary' />}
                  label='Male'
                  labelPlacement='end'
                  color={colors.PRIMARY_TEXT}
                />
                <FormControlLabel
                  value='FEM'
                  control={<Radio color='primary' />}
                  label='Female'
                  labelPlacement='end'
                />
              </RadioGroup>
              {
                isGenderError && <FormHelperText>{genderErrorDesc}</FormHelperText>
              }
            </Box>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                variant='inline'
                inputVariant='outlined'
                label='Birthday'
                InputLabelProps={{ shrink: birthday ? true : undefined }}
                name='birthday'
                format='MM/dd/yyyy'
                maxDate={moment().subtract(18, 'years').format('YYYY-MM-DD')}
                value={birthday}
                onChange={ 
                  (date) => handleInputChange({
                    target: {
                      name: 'birthday',
                      value: getFormattedDateTime(date, null, 'YYYY-MM-DD')
                    }
                  })
                }
                className={classes.textField}
                required
                error={isBirthdayError}
                helperText={birthdayErrorDesc}
                disableFuture
                autoOk
              /> 
            </MuiPickersUtilsProvider>
          </Box>
        </Grid>
      </Hidden>
      <Grid item xs={12}>
        <Box className={classes.content} textAlign='center'>
          <Button color='primary' variant='contained' onClick={handleNext} className={`btn-min-width`} disabled={isSaving}>
            {
              isSaving 
              ? <CircularProgress color='white' size={24} />
              : `Next`
            }
          </Button>
        </Box>
      </Grid>
    </>
  )
}

export default BasicInfo;