import React, { useState } from 'react';
import { Box, Button, Grid, TextField, CircularProgress, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditOutlined';
import DoneIcon from '@material-ui/icons/Done';
import useStyles from './style';
import {
  TOAST_AXIOS_REQUEST_ERROR,
  TOAST_SHORTLIST_ADDED,
  TOAST_SHORTLIST_ADDING,
  TOAST_SHORTLIST_REMOVED,
  TOAST_SHORTLIST_REMOVING,
  TOAST_USER_BLOCKED,
  TOAST_USER_LIKED,
  TOAST_USER_NOTE_ADDED,
  TOAST_USER_UNLIKED,
  HEART_ACCEPTED,
  HEART_SENT,

  ALERT_SEVERITY_ERROR,
  ALERT_SEVERITY_INFO,
  ALERT_SEVERITY_SUCCESS,
  ALERT_SEVERITY_WARINIG,
  STATUS_INACTIVE,
  MAX_NOTE_CHARACTERS,
  HTTP_STATUS
} from '../../../utils/constants'
import { connectionRequest } from '../../../service/requests';
import { handleHttpError } from '../../../utils/helper';

const Notes = React.memo((props) => {
  console.log('Notes()');

  const classes = useStyles();
  const { note, connectionUserId, setUserConnection, showAlert, userName, userId } = props;

  const [value, setValue] = useState(note ? note : '');
  const [isSaving, setIsSaving] = useState(false);

  const saveNoteRequest = () => {
    console.log('Notes.saveNoteRequest()');

    if (note == value) return
    setIsSaving(true);
    connectionRequest.addNote(
      {
        user_id: userId,
        who_added: userId,
        note: value
      },
      connectionUserId
    ).then(response => {
      console.log('response : ', response);

      if (response?.data?.status?.code == HTTP_STATUS._200) {
        setUserConnection(response.data.connection);
        showAlert(ALERT_SEVERITY_SUCCESS, TOAST_USER_NOTE_ADDED(userName));
      }
    }).catch(error => {
      handleHttpError({
        error,
        request: 'connectionRequest.addNote::src/component/userprofile/notes'
      })
    }).finally(() => {
      setIsSaving(false);
    });
  }

  const handleChange = event => {
    setValue(event.target.value);
  };

  const handleEdit = event => {
    saveNoteRequest();
  };

  return (
    <Box p={3} boxShadow={2} className={classes.rootContainer}>
      <h3 style={{ marginTop: -10}}>Notes</h3>
      <Grid container direction='column' spacing={2} className={classes.noteGrid}>
        <Grid item xs={12}>
          <TextField
             id='notes'
            multiline
            rows='8'
            placeholder={`Write notes about ${userName}`}
            value={value}
            variant='outlined'
            onChange={handleChange}
            className={classes.notesField}
            margin="normal"
            disabled={isSaving}
            inputProps={{ maxLength: MAX_NOTE_CHARACTERS }}
          />
          {/* <Typography variant="caption">
            { `${value.length} / ${MAX_NOTE_CHARACTERS}` }
          </Typography> */}
        </Grid>
        <Grid item xs={12}>
          <Grid container alignItems='center' justify='flex-end'>
            <Grid item className={classes.saveButtonGridItem}>
              <Button disabled={isSaving || (!value.trim().length && !note)} color='primary' variant='contained' onClick={handleEdit}>
                {
                  isSaving 
                    ? <CircularProgress size={24}/> 
                    : 'Save'
                }
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {/* <Button color='primary' className={classes.editNoteButton} onClick={handleEdit}>
          {
            disable ? 
              <EditIcon style={{fill: 'Green'}}/>
            : 
              <DoneIcon style={{fill: 'Green'}}/> 
          }
        </Button> */}
      </Grid>
    </Box>
  )
})

export default Notes;