import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../utils/colors';

const useStyles = makeStyles(theme => ({

  box: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',

    '& > .MuiBox-root': {
      display: 'flex',
      flexDirection: 'column',

      '& .MuiFormControlLabel-label': {
        color: colors.TEXT_GRAY
      },

    },
  },
  
  formControl: {
    display: 'flex',
    flexDirection: 'row',

    '& > .MuiBox-root': {
      display: 'flex',
      flexDirection: 'column',

      '& .MuiFormControlLabel-label': {
        color: colors.TEXT_GRAY
      },
    },
  },

}));

export default useStyles;