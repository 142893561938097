import React, { memo }                    from 'react';
import { Box, GridList, GridListTile,
	     Typography, Modal, IconButton, 
		 Link }                           from '@material-ui/core';
import LockIcon                           from '@material-ui/icons/Lock';
import CloseIcon                          from '@material-ui/icons/Close';
import Icon                               from '@mdi/react'
import { mdiSunglasses }                  from '@mdi/js';
import DiamondIcon                        from '../../../assets/images/diamond.png';
import useStyles                          from './style';
import Empty                              from '../../empty';
import Preloader                          from '../../../components/preloader';
import NO_PHOTOS                          from '../../../assets/images/no_photos.png';
import { MSG_NO_PHOTOS, PRELOADER_PHOTO } from '../../../utils/constants';

//////////////////
///User Photos///
////////////////

const Photos = (props) => {

console.log('UserProfile(): Photos');

  const classes = useStyles();
	const { arePhotosPrivate } = props;
	const { mediaGallery, user, userSubscription } = props.userConnection;

	const defaultBoxHeight = 220;
	let cellHeight = 100
	let gridListCol = 2;
	const visibilityStatus = userSubscription?.viewabilityStatus;

	if (mediaGallery && mediaGallery.length > 0) {
		gridListCol = ((mediaGallery.length < 2) || arePhotosPrivate) ? mediaGallery.length : 4;
		cellHeight	= ((mediaGallery.length < 2) || arePhotosPrivate) ? defaultBoxHeight : 193;
	}

	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

  return (
	  <>
		<Box className={classes.containerBox}  p={3} boxShadow={2} height={(arePhotosPrivate || (mediaGallery && mediaGallery.length <= 2)) ? defaultBoxHeight : 190}>
			<div className={classes.seeAllBar}>
				<Typography className={classes.textBar}>Photos</Typography>
			    { !arePhotosPrivate && <Link onClick={handleOpen}>See All</Link>}
			</div>
			{
				(typeof mediaGallery !== 'undefined') ?
					<div className={classes.root}>
							{
								(arePhotosPrivate && (mediaGallery && mediaGallery.length > 0)) &&
								<>
								<Box position='absolute' zIndex={1} display='flex' alignItems='center' flexDirection='column' p={5}>
									<LockIcon color='black' className={classes.lockIcon} />
									<Typography color='black'>{`${user.userName}`}  
										{ visibilityStatus == 'PRV' ? <Icon path={mdiSunglasses} size={1} color="black" style={{marginLeft : 2}}/> 
											: visibilityStatus == 'ELT' ? <img src={DiamondIcon} className={`${classes.planIcon} ${classes.quickFade}`} /> : '' } 
										prefers Privacy.</Typography>
									<Typography color='black'>{`\nPhotos will be available if you and ${user.userName} are connected via chat.`}</Typography>
								</Box>
								</>
							}
							{
								(mediaGallery && mediaGallery.length > 0) ?
									<GridList cellHeight={cellHeight} cols={mediaGallery.length < 4 ? mediaGallery.length: gridListCol} className={`${arePhotosPrivate && classes.blurGallery}`}>
										{
											mediaGallery.slice(0,4).map((photo) => (
												<GridListTile key={photo.media_id}>
													<img className={classes.image} onContextMenu={(e)=> e.preventDefault()} src={photo.s3_path} />
												</GridListTile>
											)) 
										}
									</GridList>
								:	<Box>
										<Empty image={NO_PHOTOS} title={MSG_NO_PHOTOS} />
									</Box>
							}
					</div>
				:
				<GridList cellHeight={150} cols={4}>
					<GridListTile>
						<Preloader type={PRELOADER_PHOTO} />
					</GridListTile>
					<GridListTile>
						<Preloader type={PRELOADER_PHOTO} />
					</GridListTile>
					<GridListTile>
						<Preloader type={PRELOADER_PHOTO} />
					</GridListTile>
					<GridListTile>
						<Preloader type={PRELOADER_PHOTO} />
					</GridListTile>
				</GridList>
			}
    </Box>
	<Modal
        open={open}
        onClose={handleClose}
      >
        <Box className={classes.modalBox}>
			<div className={classes.infoBar}>
				<Typography className={classes.textBar}>All Photos</Typography>
				<IconButton onClick={handleClose} style={{position: 'absolute', right: 0, top: 0, padding: 15}}>
					<CloseIcon />
				</IconButton>	
			</div>
			{
				(typeof mediaGallery !== 'undefined') ?
					<Box style={{ margin: 15, width: 'auto', height: 'auto', display:'flex', justifyContent: 'center', alignItems: 'center' }}>
						{
							(arePhotosPrivate && (mediaGallery && mediaGallery.length > 0)) &&
							<>
							<Box position='absolute' height={defaultBoxHeight} style= {{ width: '100%', zIndex:1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}  flexDirection='column'>
								<LockIcon color='black' className={classes.lockIcon} />
								<Typography variant='subtitle1' color='black'>{`Photos will be available if you and ${user.userName} are connected via chat.`}</Typography>
							</Box>
							</>
						}
						{
							(mediaGallery && mediaGallery.length > 0) ?
								<GridList cellHeight={ 200 } className={`${arePhotosPrivate && classes.gridList }`} spacing={1} cols={3}>
									{
									
										mediaGallery.map((photo) => (
											<GridListTile key={photo.media_id} cols = { 1 }>
												
											 	<img className={classes.imageAll} src={photo.s3_path} />
											</GridListTile>
										))
									}
								</GridList>
							: 
								<Box display='flex' justifyContent='center' alignItems='center' height={300}>
									<Empty image={NO_PHOTOS} title={MSG_NO_PHOTOS} />
								</Box>
						}
					</Box>
				:
				<Box px={5} pb={5}>
					<GridList cellHeight={190} cols={4}>
						<GridListTile>
							<Preloader className={classes.imageAll} type={PRELOADER_PHOTO} />
						</GridListTile>
						<GridListTile>
							<Preloader className={classes.imageAll} type={PRELOADER_PHOTO} />
						</GridListTile>
						<GridListTile>
							<Preloader className={classes.imageAll} type={PRELOADER_PHOTO} />
						</GridListTile>
						<GridListTile>
							<Preloader className={classes.imageAll} type={PRELOADER_PHOTO} />
						</GridListTile>
					</GridList>
				</Box>
			}
        </Box>
    </Modal>
	</>
  )
}

export default memo(Photos);