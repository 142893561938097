import { makeStyles } from "@material-ui/core/styles";
import colors from "../../utils/colors";

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 10,
    border: "none",
    boxShadow: "none",
  },

  cardHeader: {
    marginLeft: 16,
    marginRight: 16,
    color: colors.PRIMARY,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& p > a": {
      borderBottom: 'solid 1px',
      cursor: 'pointer',
      color: colors.PRIMARY,
      fontSize: 12
    },
  },

  cardContent: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: "0 !important",
    justifyContent: 'center',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down("md")]: {
      marginLeft: -10,
    },

    [theme.breakpoints.down("sm")]: {
      padding: 0,
    },
  },

  multipleRows: {
    flexFlow: "wrap",
    alignItems: "center",
    marginLeft: theme.spacing(1.5),
    justifyContent: 'flex-start',
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(-1),
    },
  },

  itemSearch: {
    display: "flex",
    flexDirection: "column",
    marginLeft: theme.spacing(1.5),
    maxWidth: 180,
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(0.5),
      maxWidth: 130,
    },

    "& div.MuiCard-root": {
      display: "flex",
      flexDirection: "column",
      cursor: "pointer",
      marginBottom: theme.spacing(1.5),
      [theme.breakpoints.down("sm")]: {
        marginBottom: theme.spacing(0.5),
      },
    },

    "& p:nth-child(1)": {
      fontWeight: "bold",
      fontSize: 16,
    },
    "& p:nth-child(n+2)": {
      fontSize: 12,
    },
  },

  itemSearchImg: {
    width: 180,
    height: 180,
    [theme.breakpoints.down("sm")]: {
      width: 130,
      height: 130,
    },
  },

  profileDetails: {
    margin: "0 1em",
    "& p": {
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      overflow: "hidden",
    },
  },

  preloaderHome: {
    width: "fit-content",
    maxWidth: "0 !important",
  },

  planIcon: {
    width: '20px',
    height: '20px'
  },

  quickFade: {
    animation: '$fadeInAnimation 0.5s forwards',
  }
}));

export default useStyles;
