const action = {
    
	LOOK_UP_GET_FIELDS        		: "LOOK_UP_GET_FIELDS",
	LOOK_UP_GET_FIELDS_ERROR  		: "LOOK_UP_GET_FIELDS_ERROR",
	LOOK_UP_SET_BODY_TYPE     		: "LOOK_UP_SET_BODY_TYPE",
	LOOK_UP_SET_EDUC_LEVEL    		: "LOOK_UP_SET_EDUC_LEVEL",
	LOOK_UP_SET_ETHNICITY     		: "LOOK_UP_SET_ETHNICITY",
	LOOK_UP_SET_EXER_REGIMEN  		: "LOOK_UP_SET_EXER_REGIMEN",
	LOOK_UP_SET_EYE_COLOR     		: "LOOK_UP_SET_EYE_COLOR",
	LOOK_UP_SET_GENDER        		: "LOOK_UP_SET_GENDER",
	LOOK_UP_SET_HAIR_COLOR    		: "LOOK_UP_SET_HAIR_COLOR",
	LOOK_UP_SET_HEART         		: "LOOK_UP_SET_HEART",
	LOOK_UP_SET_HEIGHT        		: "LOOK_UP_SET_HEIGHT",
	LOOK_UP_SET_INCOME_lEVEL  		: "LOOK_UP_SET_INCOME_lEVEL",
	LOOK_UP_SET_ISSUE_CATEG   		: "LOOK_UP_SET_ISSUE_CATEG",
	LOOK_UP_SET_HOBBY_CODE    		: "LOOK_UP_SET_HOBBY_CODE",
	LOOK_UP_SET_KID_POLICY    		: "LOOK_UP_SET_KID_POLICY",
	LOOK_UP_SET_KID_SITUATION 		: "LOOK_UP_SET_KID_SITUATION",
	LOOK_UP_SET_LANGUAGE 	  		: "LOOK_UP_SET_LANGUAGE",
    LOOK_UP_SET_MEMBERSHIP_LEVEL    : "LOOK_UP_SET_MEMBERSHIP_LEVEL",
	LOOK_UP_SET_PET_POLICY    		: "LOOK_UP_SET_PET_POLICY",
	LOOK_UP_SET_POS_POLITICS  		: "LOOK_UP_SET_POS_POLITICS",
	LOOK_UP_SET_PREF_ALCOHOL  		: "LOOK_UP_SET_PREF_ALCOHOL",
	LOOK_UP_SET_PREF_SMOKING  		: "LOOK_UP_SET_PREF_SMOKING",
	LOOK_UP_SET_PROFESSION			: "LOOK_UP_SET_PROFESSION",
	LOOK_UP_SET_RATING        		: "LOOK_UP_SET_RATING",
	LOOK_UP_SET_RELIG_BELIEF  		: "LOOK_UP_SET_RELIG_BELIEF",
	LOOK_UP_SET_REL_TYPE      		: "LOOK_UP_SET_REL_TYPE",
	LOOK_UP_SET_REL_STATUS			: "LOOK_UP_SET_REL_STATUS",
	LOOK_UP_SET_SUB_PLAN      		: "LOOK_UP_SET_SUB_PLAN",
	LOOK_UP_GET_CITY      			: "LOOK_UP_GET_CITY",
	LOOK_UP_GET_STATE      			: "LOOK_UP_GET_STATE",
	LOOK_UP_GET_COUNTRY     	 	: "LOOK_UP_GET_COUNTRY",
	LOOK_UP_SET_CITY      			: "LOOK_UP_SET_CITY",
	LOOK_UP_SET_STATE      			: "LOOK_UP_SET_STATE",
	LOOK_UP_SET_COUNTRY     	 	: "LOOK_UP_SET_COUNTRY",
	
	

	getLookUpFields: lookUp => ({
		type    : action.LOOK_UP_GET_FIELDS,
		payload : lookUp
	}),
	
	getLookUpFieldsError: lookUp => ({
		type    : action.LOOK_UP_GET_FIELDS_ERROR,
		payload : lookUp
	}),

	getLookUpCountry: (payload) => {
		return ({
			type    : action.LOOK_UP_GET_COUNTRY,
			payload : payload
		})
	},

	getLookUpState: (payload) =>({
		type    : action.LOOK_UP_GET_STATE,
		payload : payload
	}),

	getLookUpCity: (payload) =>({
			type    : action.LOOK_UP_GET_CITY,
			payload : payload
	}),
};

export default action;