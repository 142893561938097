import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { AppBar, Box, Button, CircularProgress, Link, Toolbar, Typography, Divider, Paper, Modal, TextField } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import welcome from '../../assets/images/welcome-background.png';
import APP_STORE from '../../assets/images/appstore.png';
import GOOGLE_PLAY from '../../assets/images/googleplay.png';
import SOS_WHITE from '../../assets/images/logo-text-white.png';
import SOS_GRADIENT from '../../assets/images/logo-text-gradient.png';
import { userRequest } from '../../service/requests';
import userAction from '../../redux/user/action';
import useStyles from './style';
import { ALERT_SEVERITY_ERROR, TOAST_AXIOS_REQUEST_ERROR, ALERT_SEVERITY_SUCCESS, HTTP_STATUS } from '../../utils/constants';
import { sessionService } from 'redux-react-session';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import { handleHttpError } from '../../utils/helper';

const SignIn = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const { showAlert, signInUser, isVisible, toggleSignInModal, toggleForgotPasswordModal } = props;
  const [isModalOpen, setIsModalOpen] = useState(history.location.search.includes('true') ? true : false);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [state, setState] = useState({
    email: '',
    isEmailError: false,
    emailErrorDesc: '',
    password: '',
    isPasswordError: false,
    passwordErrorDesc: '',
    isPasswordVisible: false
  });
  const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const {
    email, isEmailError, emailErrorDesc,
    password, isPasswordError, passwordErrorDesc
  } = state;

  const handleInputChange = (event) => {
    let value = event.target.value;
    let emailError = {}

    if (event.target.name == 'email') {
      const isEmailInvalid = !emailPattern.test(value);
      emailError = {
        isEmailError: isEmailInvalid ? true : false,
        emailErrorDesc: isEmailInvalid ? 'Invalid email format' : '',
      }
    }

    setState({
      ...state,
      ...emailError,
      [event.target.name]: value
    });
  };

  const signIn = () => {
    setState({
      ...state,
      isEmailError: email === '' || !emailPattern.test(email) ? true : false,
      emailErrorDesc: email === '' ? 'Required' : !emailPattern.test(email) ? 'Invalid email format' : '',
      isPasswordError: password === '' ? true : false,
      passwordErrorDesc: password === '' ? 'Required' : '',
    });

    if (email === '' || password === '' || !emailPattern.test(email))
      return

    setIsLoggingIn(prevIsLogginIn => !prevIsLogginIn);
    userRequest.signIn({
      email: email,
      password: password
    }).then(response => {
      console.log('response : ', response);

      setIsLoggingIn(prevIsLogginIn => !prevIsLogginIn);
      if (response?.data?.status?.code == HTTP_STATUS._200) {
        signInUser(response.data);

        // intervalId && BackgroundTimer.clearTimeout(intervalId);
        // login();
        // getRecentViews(); 
        // getSearchItems(); 
        // getShortlist(); 
        // getNearMeHistory();
        // getCalendarEvents({
        //   connection_user_id  : 0,
        //   date_start          : 0,
        //   date_end            : 0
        // });
      } else {

        setState({
          ...state,
          isEmailError: true,
          emailErrorDesc: '',
          isPasswordError: true,
          passwordErrorDesc: 'Invalid email or incorrect password'
        });

        // failedAttempt     : prevState.failedAttempt + 1
      }
    }).catch(error => {
      setIsLoggingIn(prevIsLogginIn => !prevIsLogginIn);
      handleHttpError({
        error,
        request: 'userRequest.signIn::src/views/welcome'
      })
    });
  }



  const handleClickShowPassword = () => {
    setState({ ...state, isPasswordVisible: !state.isPasswordVisible });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Modal
      open={isVisible}
      onClose={toggleSignInModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Paper className={classes.paper}>
        <Box className={classes.box}>
          <img src={SOS_GRADIENT} className={classes.gradientLogo} alt="logo" />
          <Box className={classes.form}>
            <TextField
              label={'Email Address'}
              name='email'
              value={email}
              onChange={handleInputChange}
              className={classes.textField}
              required
              error={isEmailError}
              helperText={emailErrorDesc}
              variant='outlined'
              fullWidth
              size='small'
            />
            <TextField
              label={'Password'}
              name='password'
              type={state.isPasswordVisible ? 'text' : 'password'}
              value={password}
              onChange={handleInputChange}
              className={classes.textField}
              required
              error={isPasswordError}
              helperText={passwordErrorDesc}
              variant='outlined'
              fullWidth
              size='small'

              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {state.isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <Typography variant="caption" color="textPrimary" className={classes.forgotpassword}>
              <Link variant="caption" color='textPrimary' className={classes.link} onClick={toggleForgotPasswordModal}>
                Forgot password?
              </Link>
            </Typography>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={signIn}
            >
              {
                isLoggingIn ?
                  <CircularProgress color="white" size={24} />
                  :
                  'Sign In'
              }
            </Button>
            <Typography variant="caption" color="textPrimary" className={classes.description}>
              {`Not yet a member? `}
              <Link href="/registration" variant="caption" color='textPrimary' className={classes.link}>
                Register
              </Link>
            </Typography>
          </Box>
          <Box className={classes.footer}>
            <Typography variant="h5" >
              GET THE APP NOW!
            </Typography>
            <Box className={classes.stores}>
              <img 
                src={APP_STORE}
                className={classes.store}
                alt="app store"
                onClick={() => window.open("https://play.google.com/store/apps/details?id=com.facebook.katana")}
                />
              <img 
                src={GOOGLE_PLAY}
                className={classes.store}
                alt="google play"
                onClick={() => window.open("https://play.google.com/store/apps/details?id=com.facebook.katana")}
                />
            </Box>
          </Box>
        </Box>
      </Paper>
    </Modal>
  )
}

const ForgotPassword = (props) => {
  const classes = useStyles();
  const history = useHistory();

  const { showAlert, signInUser, isVisible, toggleForgotPasswordModal, toggleSignInModal, updateUser } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [state, setState] = useState({
    email: '',
    isEmailError: false,
    emailErrorDesc: '',
  });
  const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const {
    email, isEmailError, emailErrorDesc
  } = state;

  const handleInputChange = (event) => {
    let value = event.target.value;
    let emailError = {}

    if (event.target.name == 'email') {
      const isEmailInvalid = !emailPattern.test(value);
      emailError = {
        isEmailError: isEmailInvalid ? true : false,
        emailErrorDesc: isEmailInvalid ? 'Invalid email format' : '',
      }
    }

    setState({
      ...state,
      ...emailError,
      [event.target.name]: value
    });
  };

  const forgotPassword = () => {
    setState({
      ...state,
      isEmailError: email === '' || !emailPattern.test(email) ? true : false,
      emailErrorDesc: email === '' ? 'Required' : !emailPattern.test(email) ? 'Invalid email format' : '',
    });

    if (email === '' || !emailPattern.test(email))
      return

    setIsLoading(prevIsLoading => !prevIsLoading);
    userRequest.verifyResetPasswordEmail({
      email: email,
    }).then(response => {
      console.log('response : ', response);

      if (response?.data?.status?.code == HTTP_STATUS._200) {
        setState({
          ...state,
          isEmailError: false,
          emailErrorDesc: ''
        });
        setIsLoading(prevIsLoading => !prevIsLoading);
        // next();
        showAlert(ALERT_SEVERITY_SUCCESS, 'We sent a code to your phone number to verify your account.');
        updateUser({
          email
        });
        history.push('/forgotpassword');
      } else {
        setState({
          ...state,
          isEmailError: true,
          emailErrorDesc: 'Email does not exist'
        });
      }
      setIsLoading(prevIsLoading => !prevIsLoading);
    }).catch(error => {
      setIsLoading(prevIsLoading => !prevIsLoading);
      handleHttpError({
        error,
        request: 'userRequest.verifyResetPasswordEmail::src/views/welcome'
      })
    });
  }

  return (
    <Modal
      open={isVisible}
      onClose={toggleForgotPasswordModal}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Paper className={classes.paper}>
        <Box className={classes.box}>
          <img src={SOS_GRADIENT} className={classes.gradientLogo} alt="logo" />
          <Typography variant="h5" color="textPrimary">
            Forgot password
          </Typography>
          <Typography variant="subtitle2" color="textPrimary">
            Please provide the email address associated with our account.
          </Typography>
          <Box className={classes.form}>
            <TextField
              label={'Email Address'}
              name='email'
              value={email}
              onChange={handleInputChange}
              className={classes.textField}
              required
              error={isEmailError}
              helperText={emailErrorDesc}
              variant='outlined'
              fullWidth
              size='small'
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={forgotPassword}
            >
              {
                isLoading ?
                  <CircularProgress color="white" size={24} />
                  :
                  'Forgot Password'
              }
            </Button>
            <Typography variant="caption" color="textPrimary" className={classes.description}>
              {`Remember your account? `}
              <Link variant="caption" color='textPrimary' className={classes.link} onClick={toggleSignInModal}>
                Sign In
              </Link>
            </Typography>
          </Box>
        </Box>
      </Paper>
    </Modal>
  )
}

const Welcome = (props) => {
  console.log(window.REACT_APP_ENVIRONMENT);
  const classes = useStyles();
  const history = useHistory();

  const [isSignInModalOpen, setIsSignInModalOpen] = useState(history.location.search.includes('?signin=true') ? true : false);
  const [isForgotPasswordModalOpen, setIsForgotPasswordModalOpen] = useState(history.location.search.includes('?forgotpassword=true') ? true : false);

  useEffect(() => {
    document.getElementById('root').style.height = '100%';
  }, []);


  const goToRegistration = () => {
    history.push('/registration');
  }

  const toggleSignInModal = () => {
    setIsForgotPasswordModalOpen(false);
    setIsSignInModalOpen(!isSignInModalOpen);
  }

  const toggleForgotPasswordModal = () => {
    setIsSignInModalOpen(false);
    setIsForgotPasswordModalOpen(!isForgotPasswordModalOpen);
  }


  return (
    <Box component="main" className={classes.container}>
      <Box className={classes.welcome}>
        <img src={SOS_WHITE} className={classes.sos} alt="logo" />
        <Button href="#" color="primary" variant="contained" className={classes.signInButton} onClick={toggleSignInModal}>
          SIGN IN
        </Button>
        <Box>
          <Typography variant="h2" className={classes.text}>
            Life is a journey.<br />S.O.Search is the way.
          </Typography>
          <Typography variant="subtitle2" className={classes.buttonContainer}>
            <Button href="#" color="primary" variant="contained" className={classes.button} onClick={goToRegistration}>
              JOIN NOW
            </Button>
          </Typography>
          <Typography variant="subtitle2" color='secondary' className={classes.buttonContainer}>
            By clicking Join Now, you agree to our <Link href="/aboutus" target={'_blank'} color='secondary' className={classes.link}>Terms</Link>.
            <br />
            Learn how we process data in our <Link href="/privacypolicy" target={'_blank'}  color='secondary' className={classes.link}>Privacy Policy</Link>.
          </Typography>
        </Box>
      </Box>
      <Box className={classes.overlay}></Box>
      <img src={welcome} className={classes.image} alt="welcome" />
      <SignIn {...props} isVisible={isSignInModalOpen} toggleSignInModal={toggleSignInModal} toggleForgotPasswordModal={toggleForgotPasswordModal} />
      <ForgotPassword {...props} isVisible={isForgotPasswordModalOpen} toggleForgotPasswordModal={toggleForgotPasswordModal} toggleSignInModal={toggleSignInModal} />
    </Box>
  );
}

/////////////////////////////
// mapStateToProps()
/////////////////////////////
const mapActionToProps = {
  signInUser: userAction.signInUser,
  updateUser: userAction.updateUser
}

/////////////////////////////
// mapStateToProps()
/////////////////////////////
const mapStateToProps = (state) => {
  console.log('shortlist/index.js:mapStateToProps(state)');
  console.log(state);
  return {

  };
}

export default connect(mapStateToProps, mapActionToProps)(Welcome);