import React, { useEffect, useState } from 'react';
import { Avatar, Box, Grid, IconButton, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import Sound from 'react-sound';
import TwilioVideo from 'twilio-video';

import PhoneOutlinedIcon from '@material-ui/icons/CallOutlined';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import CallEndOutlinedIcon from '@material-ui/icons/CallEndOutlined';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import VideocamIcon from '@material-ui/icons/Videocam';
import VideocamOffIcon from '@material-ui/icons/VideocamOff';
import MicOffIcon from '@material-ui/icons/MicOff';
import MicIcon from '@material-ui/icons/Mic';

import useStyles from './style';
import ring  from '../../assets/audios/ring_ring.mp3'
import colors from '../../utils/colors';

import userAction         from '../../redux/user/action';
import connectionAction   from '../../redux/connection/action';

import { ALERT_SEVERITY_ERROR, TOAST_AXIOS_REQUEST_ERROR, ALERT_SEVERITY_SUCCESS, CALL_STATE_CALLING, VIDEO_CALL_REJECTED, VIDEO_CALL_ENDED, ALERT_SEVERITY_INFO, CALL_STATE_DECLINE, VIDEO_CALL_MISSED, VIDEO_CALL_RECEIVER, VIDEO_CALL_CALLER, VIDEO_CALL_DIALLED, CALL_STATE_CONNECTING, CALL_STATE_DISCONNECTED, CALL_STATE_MISSED, CALL_ACTION_MISSED, ROOM, VIDEO_CALL_ANSWERED, CALL_ACTION_ACCEPT, CALL_ACTION_DECLINE, CALL_STATE_CONNECTED, CALL_ACTION_COMPLETED, CALL_TYPE_VIDEO } from '../../utils/constants';
import { callRequest, multipleRequest, userRequest, notificationRequest } from '../../service/requests';
import { notificationContent } from '../../utils/notificationcontent';
import { handleHttpError, isEmpty } from '../../utils/helper';

const { updateTwilioVideoToken } = userAction;
const { setUserConnection } = connectionAction;

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const VideoCall = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const query = useQuery().toString();
  const operator = query.split('&')[0].split('=')[1];

  const { setUserConnection, showAlert, user, twilioVideoToken, updateTwilioVideoToken, userConnection, iViewed, likedMe, viewedMe } = props;

  const [threadId, setThreadId] = useState(query.split('&')[1].split('=')[1]);
  const [senderId] = useState(operator === VIDEO_CALL_RECEIVER ? query.split('&')[2].split('=')[1] : null);

  const [videoCall, setVideoCall] = useState({});
  const [status, setStatus] = useState(CALL_STATE_CALLING);
  const [localVideoTrack, setLocalVideoTrack] = useState({});
  const [isVideoEnabled, toggleIsVideoEnabled] = useState(false);
  const [isAudioEnabled, toggleIsAudioEnabled] = useState(false);
  const [isRemoteVideoEnabled, setIsRemoteVideoEnabled] = useState(true);

  const [playStatus, setPlayStatus] = useState(Sound.status.STOPPED);
  const [interval, setInterval] = useState(null);

  useEffect(()=>{
    return ()=>{
      updateTwilioVideoToken(null)
    }
  },[])

  useEffect(() => {
    document.getElementById('root').style.height = '100%';

    if (isEmpty(localVideoTrack)) 
      TwilioVideo.createLocalVideoTrack().then(track => {
        setLocalVideoTrack(track);
        toggleIsVideoEnabled(prevPreview => !prevPreview);
        const localMediaContainer = document.getElementById('local-media');
        localMediaContainer.appendChild(track.attach());
      });

    if (operator === VIDEO_CALL_CALLER) {
      const videoCallNotifContent = notificationContent(user.userName)[VIDEO_CALL_DIALLED];
  
      notificationRequest.sendNotification({
        item_id                 : threadId,
        sender_id               : user.userId,
        receiver_id             : userConnection.user.userId,
        notif_type              : videoCallNotifContent.notifType,
        is_notification_showed  : videoCallNotifContent.isNotificationShowed,
        is_voip_notification    : true,
        title                   : videoCallNotifContent.notifTitle,
        body                    : videoCallNotifContent.notifBody
      }).then(response => {
        console.log('response : ', response);
        
        startVideoCall();
      }).catch(error => {
        handleHttpError({
          error,
          request: 'notificationRequest.sendNotification::src/views/video-call'
        })
      });
    } else {
      const caller = [...iViewed, ...likedMe.map(user => user.userConnection), ...viewedMe].find(connection => connection.user.userId == senderId);

      setPlayStatus(Sound.status.PLAYING);
      setUserConnection(caller);
    }

    return () => {
      console.log('will unmount');
      setPlayStatus(Sound.status.STOPPED);
      clearTimeout(interval);
    }
  }, [threadId]);

  useEffect(() => {
    if (twilioVideoToken != null && twilioVideoToken !== VIDEO_CALL_REJECTED && twilioVideoToken !== VIDEO_CALL_ENDED && twilioVideoToken !== VIDEO_CALL_MISSED) {
      setPlayStatus(Sound.status.STOPPED);
      setStatus(CALL_STATE_CONNECTING);
      clearTimeout(interval);
      initiateVideoCall();
    } else if (twilioVideoToken === VIDEO_CALL_REJECTED && operator === VIDEO_CALL_CALLER) {
      setPlayStatus(Sound.status.STOPPED);
      clearTimeout(interval);
      updateTwilioVideoToken(null);
      setStatus(CALL_STATE_DECLINE);
      showAlert(ALERT_SEVERITY_ERROR, 'Video call rejected.');
    } else if ((twilioVideoToken === VIDEO_CALL_ENDED) && operator === VIDEO_CALL_RECEIVER) {
      setPlayStatus(Sound.status.STOPPED);
      clearTimeout(interval);
      showAlert(ALERT_SEVERITY_ERROR, 'Video call ended.');
      updateTwilioVideoToken(null);
      endVideoCall();
    } else if ((twilioVideoToken === VIDEO_CALL_MISSED) && operator === VIDEO_CALL_RECEIVER) {
      setPlayStatus(Sound.status.STOPPED);
      clearTimeout(interval);
      showAlert(ALERT_SEVERITY_ERROR, 'Video call missed.');
      goBack();
    } else if ((twilioVideoToken === VIDEO_CALL_REJECTED) && operator === VIDEO_CALL_RECEIVER) {
      setPlayStatus(Sound.status.STOPPED);
      clearTimeout(interval);
      showAlert(ALERT_SEVERITY_ERROR, 'Video call rejected.');
      goBack();
    }

  }, [twilioVideoToken, twilioVideoToken && twilioVideoToken.tokenTwilio]);

  

  const initiateVideoCall = () => {
    console.log('initiateVideoCall');

    TwilioVideo.connect(twilioVideoToken.token).then(room => {
      console.log('TwilioVideo connect');
      console.log(`Successfully joined a Room: ${room}`);
      
      setStatus(CALL_STATE_CONNECTED);
      setVideoCall({ room });
      toggleIsAudioEnabled(true);

      // CALLER
      // Log Participant Connected
      room.on('participantConnected', participant => {
        console.log(`A remote Participant connected: ${participant}`);
        console.log('participant : ', participant);

        participant.tracks.forEach(publication => {
          console.log('publication : ', publication);
          if (publication.isSubscribed) {
            const track = publication.track;
            console.log('publication.track : ', track);
            document.getElementById('remote-media').appendChild(track.attach());
          }
        });        

        participant.on('trackSubscribed', track => {
          console.log('trackSubscribed : ', track);
          if(track.kind === 'data') return
          document.getElementById('remote-media').appendChild(track.attach());
        });
        
      });

      // RECEIVER
      // Log any Participants already connected to the Room
      room.participants.forEach(participant => {
        console.log(`Participant "${participant.identity}" is connected to the Room`);
        console.log('participant : ', participant);

        participant.tracks.forEach(publication => {
          if (publication.isSubscribed) {
            const track = publication.track;
            console.log('track : ', track);
            document.getElementById('remote-media').appendChild(track.attach());
          }
        });        

        participant.on('trackSubscribed', track => {
          console.log('trackSubscribed : ', track);
          if(track.kind === 'data') return
          document.getElementById('remote-media').appendChild(track.attach());
        });

      });

      // Log Participants as they disconnect from the Room
      room.once('participantDisconnected', participant => {
        console.log(`Participant "${participant.identity}" has disconnected from the Room`);
        room.disconnect();
      });

      // Participant Disconnected
      room.on('participantDisconnected', participant => {
        console.log(`Participant disconnected: ${participant.identity}`);
        room.disconnect();
      });

      // Disconnected
      room.on('disconnected', room => {
        console.log('Disconnected');
        updateTwilioVideoToken(null);
        setStatus(CALL_STATE_DISCONNECTED);
        room.localParticipant.tracks.forEach(publication => {
          publication.track.stop();
          const attachedElements = publication.track.detach();
          attachedElements.forEach(element => element.remove());
        });

        document.getElementById('remote-media').innerHTML = '';
      });

      room.on('trackDisabled', track => {
        console.log('trackDisabled : ', track);

        setIsRemoteVideoEnabled(false);
      });

      room.on('trackEnabled', track => {
        console.log('trackDisabled : ', track);

        setIsRemoteVideoEnabled(true);
      });
      
    }, error => {
      console.error(`Unable to connect to Room: ${error.message}`);
      showAlert(ALERT_SEVERITY_ERROR, error.message);
    });
  }

  const getTurnedOffCameraMsg = (username) => {

    if (username[username.length - 1] === 's' || username[username.length - 1] === 'z' || username[username.length - 1] === 'S' || username[username.length - 1] === 'Z') {
      return `${username}' camera is turned off.`;
    } else {
      return `${username}'s camera is turned off.`;
    }
  }

  const startVideoCall = () => {
    console.log('startVideoCall');

    setPlayStatus(Sound.status.PLAYING);

    if (operator === VIDEO_CALL_CALLER) {
      // 30 seconds time out
      const newInterval = setTimeout(() => {
        setStatus(CALL_STATE_MISSED);
        
        const missVideoCallNotification = notificationContent(user.userName)[VIDEO_CALL_MISSED];

        multipleRequest([
          notificationRequest.sendNotification({
            item_id                 : threadId,
            sender_id               : user.userId,
            receiver_id             : userConnection.user.userId,
            notif_type              : missVideoCallNotification.notifType,
            is_notification_showed  : missVideoCallNotification.isNotificationShowed,
            is_voip_notification    : true,
            title                   : missVideoCallNotification.notifTitle,
            body                    : missVideoCallNotification.notifBody
          }),
          callRequest.respondToCall({
            user_id       : user.userId
          }, CALL_ACTION_MISSED, threadId)
        ]).then(response => {
          console.log('response : ', response);
        }).catch(error => {
          handleHttpError({
            error,
            request: 'notificationRequest.sendNotification&callRequest.respondToCall::src/views/video-call'
          })
        });

        setPlayStatus(Sound.status.STOPPED);
      }, 30000);

      setInterval(newInterval);
    }
  }

  const acceptVideoCall = () => {
    console.log('acceptVideoCall');

    const roomName = (user.userId > senderId) ? `${ROOM+ senderId}_${user.userId}` : `${ROOM+ user.userId}_${senderId}`;
    const videoCallNotifContent = notificationContent(null)[VIDEO_CALL_ANSWERED];
    
    setPlayStatus(Sound.status.STOPPED);
    clearTimeout(interval);
    setStatus(CALL_STATE_CONNECTING);

    multipleRequest([
      notificationRequest.sendNotification({
        item_id                 : threadId,
        sender_id               : user.userId,
        receiver_id             : senderId,
        notif_type              : videoCallNotifContent.notifType,
        is_notification_showed  : videoCallNotifContent.isNotificationShowed,
        title                   : videoCallNotifContent.notifTitle,
        body                    : videoCallNotifContent.notifBody
        
      }),
      userRequest.getTwilioVideoToken({
        room_name : roomName,
        user_name : user.userName
      }),
      callRequest.respondToCall({
        user_id   : user.userId
      }, CALL_ACTION_ACCEPT, threadId)
    ]).then(([notifResponse, twilioVideoResponse, callRequestResponse]) => {
      console.log('notifResponse        : ', notifResponse);
      console.log('twilioVideoResponse  : ', twilioVideoResponse);
      console.log('callRequestResponse  : ', callRequestResponse);

      updateTwilioVideoToken(twilioVideoResponse.data.tokenTwilio);

      // if (!isVideoOn) {
      //   this.toggleVideo();
      // }
    }).catch(error => {
      handleHttpError({
        error,
        request: 'notificationRequest.sendNotification&userRequest.getTwilioVideoToken&callRequest.respondToCall::src/views/video-call'
      })
    });

  }

  const declineVideoCall = () => {
    console.log('declineVideoCall');

    const declineVideoCallNotification = notificationContent(null)[VIDEO_CALL_REJECTED];

    setPlayStatus(Sound.status.STOPPED);
    clearTimeout(interval);

    multipleRequest([
      notificationRequest.sendNotification({
        item_id                 : threadId,
        sender_id               : user.userId,
        receiver_id             : senderId,
        notif_type              : declineVideoCallNotification.notifType,
        is_notification_showed  : declineVideoCallNotification.isNotificationShowed,
        title                   : declineVideoCallNotification.notifTitle,
        body                    : declineVideoCallNotification.notifBody
      }),
      callRequest.respondToCall({
        user_id      : user.userId
      }, CALL_ACTION_DECLINE, threadId)
    ]).then(response => {
      console.log('response : ', response);
      if (operator === VIDEO_CALL_RECEIVER){
        goBack();
      }
       
    }).catch(error => {
      handleHttpError({
        error,
        request: 'notificationRequest.sendNotification&callRequest.respondToCall::src/views/video-call'
      })
    });
  }

  const endVideoCall = () => {
    console.log('endVideoCall', videoCall, localVideoTrack);

    setPlayStatus(Sound.status.STOPPED);
    clearTimeout(interval);
    !isEmpty(videoCall) && videoCall.room.disconnect();
    
    if (operator === VIDEO_CALL_CALLER && status !== CALL_STATE_CONNECTED) {

      const videoCallNotification = notificationContent(null)[VIDEO_CALL_REJECTED];

      multipleRequest([
        notificationRequest.sendNotification({
          item_id                 : threadId,
          sender_id               : user.userId,
          receiver_id             : (operator === VIDEO_CALL_RECEIVER) ? senderId : userConnection.user.userId,
          notif_type              : videoCallNotification.notifType,
          is_notification_showed  : videoCallNotification.isNotificationShowed,
          is_voip_notification    : true,
          title                   : videoCallNotification.notifTitle,
          body                    : videoCallNotification.notifBody
        }),
        callRequest.respondToCall({
          user_id   : user.userId
        }, CALL_ACTION_COMPLETED, threadId)
      ]).then(response => {
        console.log('response : ', response);

        setStatus(CALL_STATE_DISCONNECTED);
      }).catch(error => {
        handleHttpError({
          error,
          request: 'notificationRequest.sendNotification& callRequest.respondToCall::src/views/video-call'
        })      
      });
    }
  }

  const goBack = () => {
    console.log('goBack');
    localVideoTrack.stop();
    localVideoTrack.disable();
    history.goBack();
  }

  const redial = () => {
    console.log('redial');

    callRequest.startCall({
      from_user_id : user.userId,
      to_user_id   : (operator === VIDEO_CALL_RECEIVER) ? senderId : userConnection.user.userId,
    }, CALL_TYPE_VIDEO).then(response => {
      console.log('response : ', response);

      const { callThreadId } = response.data.call;
      setThreadId(callThreadId);
      setStatus(CALL_STATE_CALLING);
      window.location.replace(`/videocall?operator=${VIDEO_CALL_CALLER}&threadId=${callThreadId}`);
      // window.open(`${window.location.host}/videocall`, 'Video Call');
    }).catch(error => {
      handleHttpError({
        error,
        request: 'callRequest.startCall::src/views/video-call'
      })
    });
  }

  const toggleVideo = () => {
    console.log('toggleVideo');

    if(isEmpty(videoCall)) return
    if(!videoCall.room.localParticipant) return

    if (localVideoTrack.isEnabled) {
      videoCall && videoCall.room.localParticipant.videoTracks.forEach((publication) => {
        publication.track.disable();
      });

      localVideoTrack.disable();
    } else {
      videoCall && videoCall.room.localParticipant.videoTracks.forEach((publication) => {
        publication.track.enable();
      });

      localVideoTrack.enable();
    }

    toggleIsVideoEnabled(prevPreview => !prevPreview);
  }

  const toggleAudio = () => {
    console.log('toggleAudio');

    if (isAudioEnabled) {
      videoCall.room.localParticipant.audioTracks.forEach(publication => {
        console.log('IM HERE publication : ', publication);
        publication.track.disable();
      });
    } else {
      videoCall.room.localParticipant.audioTracks.forEach(publication => {
        console.log('IM HERE publication : ', publication);
        publication.track.enable();
      });
    }

    toggleIsAudioEnabled(prevSound => !prevSound);
  }

  return (

    <Grid container className={classes.container}>
      <Grid item xs={12} className={classes.grid}>
        <Sound
          url={ring}
          playStatus={playStatus}
          playFromPosition={0 /* in milliseconds */}
          loop={true}
        />
        {
          (status !== CALL_STATE_CONNECTED) &&
          <Box className={classes.content}>
            <Avatar
              alt={'profile'}
              className={classes.image}
              src={userConnection && userConnection.user && userConnection.user.mediaProfile}
            />
            <Typography variant="h6" color="TextPrimary" align="center">{userConnection && userConnection.user && userConnection.user.userName}</Typography>
            <Typography variant="subtitle2" color="TextPrimary" align="center">
              {
                status === CALL_STATE_CALLING && operator === VIDEO_CALL_RECEIVER ?
                  'Calling'
                  : status === CALL_STATE_CALLING ?
                    'Contacting'
                    : status === CALL_STATE_CONNECTING ?
                      'Connecting'
                      : status === CALL_STATE_DISCONNECTED ?
                        'Call Ended'
                        : status === CALL_STATE_DECLINE ?
                          'Call Rejected'
                          : status === CALL_STATE_MISSED ?
                            'No Answer'
                            :
                            ''
              }
            </Typography>
          </Box>
        }
        <Box className={classes.controls}>
          {
            (status === CALL_STATE_CONNECTED || status === CALL_STATE_CONNECTING) ?
              <Box>
                <IconButton className={`${isVideoEnabled ? classes.iconButtonOutlined : classes.iconButtonContained}`} onClick={toggleVideo}>
                  {
                    isVideoEnabled ?
                      <VideocamIcon style={{fill : colors.WHITE}} />
                      :
                      <VideocamOffIcon style={{fill : colors.WHITE}} />
                  }
                </IconButton>
                <IconButton className={`${isAudioEnabled ? classes.iconButtonOutlined : classes.iconButtonContained}`} onClick={toggleAudio}>
                  {
                    isAudioEnabled ?
                      <MicIcon style={{fill : colors.WHITE}} />
                      :
                      <MicOffIcon style={{fill : colors.WHITE}} />
                  }
                </IconButton>
                <IconButton className={classes.iconButtonRed} onClick={endVideoCall}>
                  <CallEndOutlinedIcon className={classes.icon} />
                </IconButton>
              </Box>
              : ((operator === VIDEO_CALL_CALLER && status === CALL_STATE_DECLINE) || status === CALL_STATE_DISCONNECTED || status === CALL_STATE_MISSED) ?
                <Box>
                  <IconButton className={classes.iconButtonGray} onClick={goBack}>
                    <ArrowBackOutlinedIcon className={classes.icon} />
                  </IconButton>
                  <IconButton className={classes.iconButtonGreen} onClick={redial}>
                    <PhoneOutlinedIcon className={classes.icon} />
                  </IconButton>
                </Box>
                :
                (operator === VIDEO_CALL_CALLER) ?
                  <Box>
                    <IconButton className={classes.iconButtonRed} onClick={endVideoCall}>
                      <CallEndOutlinedIcon className={classes.icon} />
                    </IconButton>
                  </Box>
                  :
                  <Box>
                    <IconButton className={classes.iconButtonGreen} onClick={acceptVideoCall}>
                      <CheckOutlinedIcon className={classes.icon} />
                    </IconButton>
                    <IconButton className={classes.iconButtonRed} onClick={declineVideoCall}>
                      <CloseOutlinedIcon className={classes.icon} />
                    </IconButton>
                  </Box>
          }
        </Box>
        <Box id="local-media" className={classes.localVideo} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
          {
            !isVideoEnabled &&
            <Avatar
              className={classes.localViewProfile}
              src={user.mediaProfile}
            />
          }
        </Box>
        <Box id="remote-media" className={`${isRemoteVideoEnabled ? classes.remoteVideo : classes.turnedOffRemoteVideo}`} display='flex' flexDirection='column' alignItems='center' justifyContent='center'>
          {
            !isRemoteVideoEnabled &&
            <>
              <Avatar
                className={classes.image}
                src={userConnection && userConnection.user && userConnection.user.mediaProfile}
              />
              <Typography variant="subtitle2" align="center" className={classes.username}>
                {(userConnection && userConnection.user && userConnection.user.userName) && getTurnedOffCameraMsg(userConnection.user.userName)}
              </Typography>
            </>
            }
        </Box>
      </Grid>
    </Grid>
  );
}

/////////////////////////////
// mapStateToProps()
/////////////////////////////
const mapStateToProps = (state) => {
  console.log('shortlist/index.js:mapStateToProps(state)');
  console.log(state);
  return {
    user              : state.Session.user.user,
    twilioVideoToken  : state.User.twilioVideoToken,
    userConnection    : state.Connection.userConnection,
    likedMe       : state.Connection.recentViewsList.likedMe,
    iViewed       : state.Connection.recentViewsList.iViewed,
    viewedMe      : state.Connection.recentViewsList.viewedMe 
  };
}

export default connect(mapStateToProps, { setUserConnection, updateTwilioVideoToken })(
  VideoCall
);