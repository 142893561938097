import { makeStyles } from "@material-ui/core/styles";
import { AspectRatio } from "@material-ui/icons";
import colors from "../../../utils/colors";

const useStyles = makeStyles((theme) => ({
  containerBox: {
    borderRadius: 10,
    backgroundColor: '#ffffff'
  },

  root: {
    display: 'flex',
    flexWrap: 'wrap',
    height: '85%',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
  },

  lockIcon: {
    marginBottom : 15
  },

  blurGallery: {
    filter: 'blur(50px)',
  },

  imageAll:{
    width: '200px',
    height: '200px',
    backgroundPosition: 'center',
    aspectRatio: 1,
  },

  image: {
    width: '150px',
    height: '150px',
    backgroundPosition: 'center',
    aspectRatio: 1,
    backgroundSize: 'center',
  },

  infoBar:{
    padding: 15,
    position: 'relative',
    justifyContent: 'space-between',
  },


  textBar: {
    fontWeight: 'bold',
    fontSize: 18,
  },
    
  seeAllBar:{
    display: 'flex',
    flexWrap: 'wrap',
    paddingBottom: 10,
    justifyContent: 'space-between',
  },

  // See All Photo Modal
  modalBox:{
    overflow: 'auto',
    position: 'relative',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		borderRadius: 10,
		backgroundColor: 'white',
    minWidth: '30%',
    maxWidth: 500,
    minHeight: 300,
    maxHeight: 500,
  },

  gridList: {
    filter: 'blur(50px)',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height:'100%',
    transform: 'translateZ(0)',
  },

  planIcon: {
    width: '20px',
    height: '20px'
  },

  quickFade: {
    animation: '$fadeInAnimation 0.5s forwards',
  },
 
}));

export default useStyles;
