import action from "./action";
import { STATUS_RECEIVED, STATUS_ACTIVE, STATUS_INACTIVE, PHONE_CALL_REQUEST_SENT, PHONE_CALL_REQUEST_RECEIVED, PHONE_CALL_REQUEST_ACCEPTED, VIDEO_CALL_REQUEST_SENT, VIDEO_CALL_REQUEST_RECEIVED, VIDEO_CALL_REQUEST_ACCEPTED, MESSAGE_REQUEST_SENT, MESSAGE_REQUEST_RECEIVED, MESSAGE_REQUEST_ACCEPTED, VIDEO_CALL_DISABLED, PHONE_CALL_DISABLED, MESSAGE_DISABLED } from "../../utils/constants";

const initState = {
  userConnectionList   : [],
  userConnection       : {},
  shortlist            : [],
  nearMeList           : [],
  recentViewsList      : {
    likedMe  : [],
    iViewed  : [],
    viewedMe : [],
    page: { 
            likedMePage    : 1,
            iViewedPage    : 1,
            viewedMePage   : 1
          },
    isLikedMe             : false,
    isIViewed             : false,
    isViewedMe            : false,
  },
  isLoading               : false,
  isHistoryLoading        : false,
  isShortlistLoading      : false,
  isShortlistNetworkError : false,
  isNearMeLoading         : false,
  isRecentViewsLoading    : false,
  isShortlistLoaded       : false,
  isRecentViewsLoaded     : false,
};   

export default function connectionReducer(state = initState, act) {

  switch (act.type) {

    case action.CONNECTION_SET_USER_CONNECTION:
      console.log("redux/connection/reducer.js:CONNECTION_SET_USER_CONNECTION");
      return {
        ...state,
        userConnection     : (Object.keys(act.payload).length > 0 && state.userConnection.user) ? (state.userConnection.user.userId == act.payload.user.userId) ?
                             {
                                ...state.userConnection,
                                ...act.payload
                             } : act.payload 
                             : act.payload,
        userConnectionList : Object.keys(act.payload).length > 0 ? state.userConnectionList.map(
                              (userConnection) => {
                                return (userConnection.user.userId != act.payload.user.userId) ? userConnection : act.payload
                             }) : state.userConnectionList
      }

    case action.CONNECTION_GET_USER_CONNECTION_LIST:
      console.log("redux/connection/reducer.js:CONNECTION_GET_USER_CONNECTION_LIST");
      return {
        ...state,
        userConnectionList : act.payload,
        isLoading          : true
      }

    case action.CONNECTION_SET_USER_CONNECTION_LIST:
      console.log("redux/connection/reducer.js:CONNECTION_SET_USER_CONNECTION_LIST");
      return {
        ...state,
        userConnectionList : act.payload,
        isLoading          : false
      }

    case action.CONNECTION_VIEW_PROFILE:
      console.log("redux/connection/reducer.js:CONNECTION_VIEW_PROFILE");
      return {
        ...state,
        isHistoryLoading   : true
      }

    case action.CONNECTION_GET_SHORTLIST:
      console.log("redux/connection/reducer.js:CONNECTION_GET_SHORTLIST");
      return {
        ...state,
        isShortlistLoading : true
      }

    case action.CONNECTION_GET_SHORTLIST_SUCCESS:
      console.log("redux/connection/reducer.js:CONNECTION_GET_SHORTLIST_SUCCESS");
      return {
        ...state,
        shortlist               : act.payload,
        isShortlistLoading      : false,
        isShortlistLoaded       : true,
        isShortlistNetworkError : false,
      }

    case action.CONNECTION_GET_SHORTLIST_ERROR:
      console.log("redux/connection/reducer.js:CONNECTION_GET_SHORTLIST_ERROR");
      return {
        ...state,
        shortlist               : act.payload,
        isShortlistLoading      : false,
        isShortlistNetworkError : true
      }

    case action.CONNECTION_GET_NEAR_ME:
      console.log("redux/connection/reducer.js:CONNECTION_GET_NEAR_ME");
      return {
        ...state,
        isNearMeLoading     : true,
        isNearMeRefreshing  : true
      }

    case action.CONNECTION_GET_NEAR_ME_SUCCESS:
      console.log("redux/connection/reducer.js:CONNECTION_GET_NEAR_ME_SUCCESS");
      return {
        ...state,
        nearMeList          : act.payload,
        isNearMeLoading     : false,
        isNearMeRefreshing  : false
      }

    case action.CONNECTION_GET_NEAR_ME_ERROR:
      console.log("redux/connection/reducer.js:CONNECTION_GET_NEAR_ME_ERROR");
      return {
        ...state,
        nearMeList          : [],
        isNearMeLoading     : false,
        isNearMeRefreshing  : false
      }
      
    case action.CONNECTION_REMOVE_NEAR_ME:
      console.log("redux/connection/reducer.js:CONNECTION_REMOVE_NEAR_ME");
      return {
        ...state,
        nearMeList          : state.nearMeList.filter(userNearMe => userNearMe.nearMe.nearMeId != act.payload),
      }
    
    case action.CONNECTION_GET_RECENT_VIEWS:
      console.log("redux/connection/reducer.js:CONNECTION_GET_RECENT_VIEWS");
      return {
        ...state,
        isRecentViewsLoading: true
      }

    case action.CONNECTION_GET_RECENT_VIEWS_SUCCESS:
      console.log("redux/connection/reducer.js:CONNECTION_GET_RECENT_VIEWS_SUCCESS");
      console.log('CONNECTION_GET_RECENT_VIEWS_SUCCESS: ', act.payload)
      return {
        ...state,
        recentViewsList: {
          ...state.recentViewsList,
          ...act.payload
          // likedMe  : [ ...state.recentViewsList.likedMe,  ...act.payload.recentViewsList.likedMe  ],
          // iViewed  : [ ...state.recentViewsList.iViewed,  ...act.payload.recentViewsList.iViewed  ],
          // viewedMe : [ ...state.recentViewsList.viewedMe, ...act.payload.recentViewsList.viewedMe ],
          // page     : { ...act.payload.page },
          // isLikedMe             : act.payload.isLikedMe,
          // isIViewed             : act.payload.isIViewed,
          // isViewedMe            : act.payload.isViewedMe,
        },
        isRecentViewsLoading : false,
        isRecentViewsLoaded  : true,
      }

    case action.CONNECTION_GET_RECENT_VIEWS_ERROR:
      console.log("redux/connection/reducer.js:CONNECTION_GET_RECENT_VIEWS_ERROR");
      return {
        ...state,
        isRecentViewsLoading: false
      }

    case action.CONNECTION_SET_MEDIA_GALLERY:
      console.log("redux/connection/reducer.js:CONNECTION_SET_MEDIA_GALLERY");
      return {
        ...state,
        userConnection     : {
          ...state.userConnection,
          mediaGallery : act.payload
        },
        isLoading          : false
      }  

    case action.CONNECTION_REMOVE_USER_CONNECTION:
      console.log("redux/connection/reducer.js:CONNECTION_REMOVE_USER_CONNECTION");
      return {
        ...state,
        recentViewsList     : {
          ...state.recentViewsList,
          iViewed   : state.recentViewsList.iViewed.filter(userConnection => userConnection.connectionUserId != act.payload),
          likedMe   : state.recentViewsList.likedMe.filter(user => user.userConnection.connectionUserId != act.payload),
          viewedMe  : state.recentViewsList.viewedMe.filter(userConnection => userConnection.connectionUserId != act.payload),
        },
      }  

    case action.CONNECTION_GET_HISTORY:
        console.log("redux/connection/reducer.js:CONNECTION_GET_HISTORY");
        return {
          ...state,
          isHistoryLoading   : true
        }  

    case action.CONNECTION_GET_HISTORY_SUCCESS:
      console.log("redux/connection/reducer.js:CONNECTION_GET_HISTORY_SUCCESS");
      return {
        ...state,
        userConnection     : {
          ...state.userConnection,
          connectionHistory : act.payload
        },
        isHistoryLoading   : false
      }
    
    case action.CONNECTION_GET_HISTORY_ERROR:
      console.log("redux/connection/reducer.js:CONNECTION_GET_HISTORY_ERROR");
      return {
        ...state,
        isHistoryLoading   : false
      }

    case action.CONNECTION_CHAT_REQUEST_UPDATE: {
      console.log("redux/message/reducer.js:CONNECTION_CHAT_REQUEST_UPDATE");
      console.log("act.payload : ", act.payload);
      const { notifType, senderId } = act.payload;
      if (Object.keys(state.userConnection).length === 0) return;
      if (state.userConnection.connectionUserId != senderId) return;
      return {
        ...state,
        userConnection : {
          ...state.userConnection,
          message : notifType === MESSAGE_REQUEST_SENT ? STATUS_RECEIVED : STATUS_ACTIVE
        }
      }
    }

    case action.CONNECTION_PHONE_REQUEST_UPDATE: {
      console.log("redux/message/reducer.js:CONNECTION_PHONE_REQUEST_UPDATE");
      console.log("act.payload : ", act.payload);
      const { notifType, senderId } = act.payload;
      if (Object.keys(state.userConnection).length === 0) return;
      if (state.userConnection.connectionUserId != senderId) return;
      return {
        ...state,
        userConnection : {
          ...state.userConnection,
          phoneCall : notifType === PHONE_CALL_REQUEST_SENT ? STATUS_RECEIVED : STATUS_ACTIVE
        }
      }
    }

    case action.CONNECTION_VIDEO_REQUEST_UPDATE: {
      console.log("redux/message/reducer.js:CONNECTION_VIDEO_REQUEST_UPDATE");
      console.log("act.payload : ", act.payload);
      const { notifType, senderId } = act.payload;
      if (Object.keys(state.userConnection).length === 0) return;
      if (state.userConnection.connectionUserId != senderId) return;
      return {
        ...state,
        userConnection : {
          ...state.userConnection,
          videoCall : notifType === VIDEO_CALL_REQUEST_SENT ? STATUS_RECEIVED : STATUS_ACTIVE
        }
      }
    }

    case action.CONNECTION_DISABLED: {
      console.log("redux/message/reducer.js:CONNECTION_DISABLED");
      console.log("act.payload : ", act.payload);
      const { notifType, senderId } = act.payload;
      if (Object.keys(state.userConnection).length === 0) return;
      if (state.userConnection.connectionUserId != senderId) return;
      let newUserConnection = {
        ...state.userConnection
      }

      switch (notifType) {
        case VIDEO_CALL_DISABLED:
        newUserConnection.videoCall = STATUS_INACTIVE
          break;
        
        case PHONE_CALL_DISABLED:
        newUserConnection.videoCall = STATUS_INACTIVE
        newUserConnection.phoneCall = STATUS_INACTIVE
          break;

        case MESSAGE_DISABLED:
        newUserConnection.videoCall = STATUS_INACTIVE
        newUserConnection.phoneCall = STATUS_INACTIVE
        newUserConnection.message   = STATUS_INACTIVE
          break;
      
        default:
          break;
      }

      return {
        ...state,
        userConnection : {
          ...newUserConnection,
        }
      }
    }

    default:
      return state
  }
}
