import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';

import useStyles from './style';
import ChatLayout from '../../components/chat-layout';
import Empty from '../../components/empty';
import MessageCenterCard from '../../components/message-center-card';
import Preloader from '../../components/preloader';

import connectionAction from '../../redux/connection/action';
import messageAction from '../../redux/message/action';
import notificationAction from '../../redux/notification/action';
import NO_NOTIFICATION_IMG from '../../assets/images/no_notifications.png';
import NO_CALL_HISTORY_IMG from '../../assets/images/no_calls.png'
import NO_CHAT_IMG from '../../assets/images/no_chats.png'
import NotificationCard from '../../components/notification-card'
import { useBottomScrollListener } from 'react-bottom-scroll-listener';

import {
  CALENDAR_INVITE_ACCEPTED,
  CALENDAR_INVITE_SENT,
  CALL_ACTION_COMPLETED,
  CALL_ACTION_DECLINE,
  CALL_ACTION_MISSED,
  CALL_CARD,
  CALL_STATUS_INBOUND_AUDIO,
  CALL_STATUS_INBOUND_VIDEO,
  CALL_STATUS_MISSED_AUDIO,
  CALL_STATUS_MISSED_VIDEO,
  CALL_STATUS_OUTBOUND_AUDIO,
  CALL_STATUS_OUTBOUND_VIDEO,
  CALL_TYPE_AUDIO,
  CALL_TYPE_VIDEO,
  COMMUNICATION_TYPE_VIDEO,
  EMPTY_CHAT,
  NO_NOTIFICATION,
  NO_NOTIFICATION_DESC,
  NOTIFICATION_CARD,
  PRELOADER_CALL_CARD,
  PRELOADER_MESSAGE_CARD,
  PRELOADER_NOTIFICATION_CARD,
  NO_NOTIFICATION_HISTORY_TITLE,
  NO_CALL_HISTORY_TITLE,
  NO_CALL_HISTORY_DESC,
  NO_CHAT_HISTORY_TITLE,
  NO_CHAT_HISTORY_DESC,
  COMMUNICATION_TYPE_AUDIO
} from '../../utils/constants'
import { callRequest, multipleRequest } from '../../service/requests';
import { BottomScrollListener } from 'react-bottom-scroll-listener';

import TabPanel from './tab-panel';
import Notification from './notification';
import Chat from './chat'
import CallLogs from './call-logs'
import CenteredTabs from './centered-tabs';
const { getRecentViews, setUserConnection } = connectionAction;
const { saveMessages, seenMessage, updateMessages, updateMessageParticipant, updateTwilio, updateTwilioClient, getAllMessages } = messageAction;
const { getNotifications, markAllRead, seenNotification, resetNotification } = notificationAction;




const MessageCenter = (props) => {

  console.log('MessageCenter()');

  const {
    getNotifications,
    isLoading,
    isMessageLoaded,
    messages,
    seenNotification,
    setUserConnection,
    twilio,
    getAllMessages,
    resetNotification,
    isAllNotificationFetched
  } = props;
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const [selectedMessageThread, setSelectedMessageThread] = useState(null);
  const [isCallLoading, setCallLoading] = useState(true);
  const [callLogs, setCallLog] = useState([]);
  const [status, setStatus] = useState();
  const [isOnBottom, setIsOnBottom] = useState(false);
  const [value, setValue] = useState(0);
  const loadingRef = useRef()



  useEffect(() => {
    const pathname = location.pathname.split('/');
  
    if(pathname[2] == 'notification'){
      setValue(0);
    }else if(pathname[2] == 'chat'){
      setValue(1);
    }else if (pathname[2] == 'call-video'){
      setValue(2);
    }else{
      history.push('/message-center/notification');
      setValue(0);
    }
  }, [location.pathname])

  useEffect(() => {
    return () => {
      resetNotification()
    }
  }, [])

  //Get bottom listener
  const handleContainerOnBottom = useBottomScrollListener(() => {
    console.log('handleContainerOnBottom');
      setIsOnBottom(true);
  }, {
    debounce: 1000,
    triggerOnNoScroll: false,
  });

  const handleClickNotification = (notification) => {
    const { isSeen, notifId, notifType, notificationSender } = notification;
    const isUnseen = isSeen === 0;

    isUnseen && seenNotification(notifId);

    if (notifType === CALENDAR_INVITE_ACCEPTED || notifType === CALENDAR_INVITE_SENT) {
      history.push('/calendar');

    } else {
      setUserConnection(notificationSender);
      history.push(`/profile/${notificationSender.user.userName}?tab=profile`);
    }
  };
  
  return (
    <Container
      className={`${classes.root}`}
    >
      <CenteredTabs
        ref={handleContainerOnBottom}
        location={location}
        {...props}
        tabs={[
          {
            id: 0,
            label: 'Notification',
            component: <Notification
              handleClickNotification={handleClickNotification}
              getNotifications={getNotifications}
              value={value}
              isOnBottom={isOnBottom}
              setIsOnBottom={setIsOnBottom}
              isAllNotificationFetched={isAllNotificationFetched}
            />
          },
          {
            id: 1,
            label: 'Chat',
            component: <Chat
              {...props}
              isLoading={isLoading}
              messages={messages}
              isMessageLoaded={isMessageLoaded}
              selectedMessageThread={selectedMessageThread}
              setSelectedMessageThread={setSelectedMessageThread}
              twilio={twilio}
              getAllMessages={getAllMessages}
              value={value}
            />
          },
          {
            id: 2,
            label: 'Call/Video',
            component: <CallLogs
              ref={loadingRef}
              isCallLoading={isCallLoading}
              callLogs={callLogs}
              handleClickNotification={handleClickNotification}
              setCallLoading={setCallLoading}
              setCallLog={setCallLog}
              user={props.user}
              userId={props.userId}
              isOnBottom={isOnBottom}
              setIsOnBottom={setIsOnBottom}
              setStatus={setStatus}
              location={location}
              status={status}
              value={value}
            />
          },
        ]}
        value={value}
      />
    </Container>


  )
}

/////////////////////////////
// mapStateToProps()
/////////////////////////////
const mapStateToProps = (state) => {
  console.log('message-center/index.js:mapStateToProps(state)');
  console.log(state);
  return {
    user: state.Session.user.user,
    userId: state.Session.user.user.userId,
    isRecentViewsLoaded: state.Connection.isRecentViewsLoaded,
    likedMeList: state.Connection.recentViewsList.likedMe,
    iViewedList: state.Connection.recentViewsList.iViewed,
    viewedMeList: state.Connection.recentViewsList.viewedMe,
    channels: state.Message.twilio.channels,
    twilio: state.Message.twilio,
    messages: state.Message.messages,
    isLoading: state.Message.isLoading,
    isMessageLoaded: state.Message.isMessageLoaded,
    notificationList: state.Notification.notificationList,
    isNotificationLoading: state.Notification.isNotificationLoading,
    isNotificationLoaded: state.Notification.isNotificationLoaded,
    unseenNotificationCount: state.Notification.badgeCount.notification,
    callLogStatus: state.Notification.callLogStatus,
    isNotificationLogsLoading: state.Notification.isNotificationLogsLoading,
    isNotificationLogsLoaded: state.Notification.isNotificationLogsLoaded,
    notificationListPage: state.Notification.notificationListPage,
    isAllNotificationFetched: state.Notification.isAllNotificationFetched
  };
}

export default connect(
  mapStateToProps,
  {
    getAllMessages,
    getRecentViews,
    getNotifications,
    markAllRead,
    seenNotification,
    setUserConnection,
    saveMessages,
    seenMessage,
    updateMessages,
    updateMessageParticipant,
    updateTwilio,
    updateTwilioClient,
    getAllMessages,
    resetNotification
  })(
    MessageCenter
  );