import { makeStyles } from '@material-ui/core/styles';
import colors from '../../utils/colors';

const useStyles = makeStyles(theme => ({

	avatar: {
		marginRight: 15,
	},

	baseBubble: {
		borderRadius: 20,
		padding: theme.spacing(1, 1.5),
		width: 'fit-content'
	},

	sent: {
		backgroundColor: colors.PRIMARY,
		color: colors.WHITE
	},

	received: {
		backgroundColor: colors.LIGHT_GRAY,
		color: colors.PRIMARY_TEXT,
	}
}));

export default useStyles;