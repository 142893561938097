import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Slider, Box, CircularProgress, Typography } from '@material-ui/core';
import useStyles from './style';
import {
  UPD_MODE_USER_HEIGHT,
  LOOK_UP_HEIGHT,
  USER_GENDER_MALE,
  HTTP_STATUS
} from '../../../utils/constants'
import { userRequest } from '../../../service/requests';
import { handleHttpError } from '../../../utils/helper';

const marks = [
  {
    value: 360,
    label: `7'11`,
  },
  {
    value: 349,
    label: `7'0`,
  },
  {
    value: 337,
    label: `6'0`,
  },
  {
    value: 325,
    label: `5'0`,
  },
  {
    value: 313,
    label: `4'0`,
  },
  {
    value: 300,
    label: `3'0`,
  },
];

const Height = forwardRef((props, ref) => {
  const classes = useStyles();
  const { lookUpHeight, getLookUpFields, callback, user, updateUserField, next } = props;
  
  const [isSaving, setIsSaving] = useState(false);
  const [state, setState] = useState({
    heightCode: 506,
    heightDescription : `5\'6"`,
    height: 325
  });

  const { height, heightCode, heightDescription } = state;

  useEffect(() => {

    setState({
      ...state,
      height            : (user.gender === USER_GENDER_MALE) ? 330 : 325,
      heightCode        : (user.gender === USER_GENDER_MALE) ? 506 : 501,
      heightDescription : (user.gender === USER_GENDER_MALE) ? `5\'6"` : `5\'1"`,
    });

    getLookUpFields(LOOK_UP_HEIGHT);
    
  }, [])

  useEffect(() => {
    callback(isSaving);
  }, [isSaving]);

  const handleChange = (event, value) => {

    const { lookUpHeight }    = props;
    const maximumSliderValue  = 360;
    const minSliderValue      = 300;
    let heightDescription     = '7\'11"';
    let newHeightValue;

    if (value > minSliderValue) {
      newHeightValue = value - 1;
    } else if (value === minSliderValue) {
      newHeightValue = 300
    }
  
    console.log('newHeightValue : ', newHeightValue);

    if (newHeightValue > 299 && newHeightValue < 312) {
      newHeightValue = newHeightValue - 300

      if (newHeightValue === 0) {
        newHeightValue = 300;
      } else {
        newHeightValue += 300;
      }
    } else if (newHeightValue > 311 && newHeightValue < 324) {
      newHeightValue = newHeightValue - 312

      if (newHeightValue === 0) {
        newHeightValue = 400;
      } else {
        newHeightValue += 400;
      }
    } else if (newHeightValue > 323 && newHeightValue < 336) {
      newHeightValue = newHeightValue - 324

      if (newHeightValue === 0) {
        newHeightValue = 500;
      } else {
        newHeightValue += 500;
      }
    } else if (newHeightValue > 335 && newHeightValue < 348) {
      newHeightValue = newHeightValue - 336
      
      if (newHeightValue === 0) {
        newHeightValue = 600;
      } else {
        newHeightValue += 600;
      }
    } else if (newHeightValue > 347 && newHeightValue < 360) {
      newHeightValue = newHeightValue - 348

      if (newHeightValue === 0) {
        newHeightValue = 700;
      } else {
        newHeightValue += 700;
      }
    }

    const heightObj   = lookUpHeight.find(height => height.code == newHeightValue);
    
    heightDescription = heightObj && heightObj.description;
    const heightCode  = heightObj && heightObj.code;

    // this.setState({ 
    //   heightCode,
    //   heightDescription,
    //   value             : roundedValue
    // });

    setState({
      ...state,
      heightCode,
      heightDescription,
      height  : value
    })

  }

  function valuetext(value) {
    return `${value}`;
  }

  const handleNext = () => {
    console.log('Height.handleNext()');

    setIsSaving(prevIsSaving => !prevIsSaving);
    userRequest.updateUser({
      user_id     : user.userId,
      upd_mode    : UPD_MODE_USER_HEIGHT,
      who_updated : user.userId,
      height      : heightCode
    }).then(response => {
      if (response?.data?.status?.code != HTTP_STATUS._200) {
        let { status } = response?.data;
        throw new Error(`${status?.code} ${status?.description}`);
      } 
      updateUserField(response?.data?.userDetail?.userField);
      next();
      setIsSaving(prevIsSaving => !prevIsSaving);
    }).catch(error => {
      handleHttpError({
        error,
        request: 'userRequest.updateUser::src/views/registration/height'
      })
    });
  }

  useImperativeHandle(ref, () => ({
    triggerNext() {
      handleNext()
    }
  }));

  return (
    <Box className={`${classes.box} ${classes.height}`}>

        <Typography variant="h5" color="primary" className={`text-bold text-center mb-20`}>
          About You
        </Typography>

        <Typography variant="h6" className={`text-bold text-center mb-20`} color="textPrimary">
          What is your height?
        </Typography>

          {
            lookUpHeight.length > 0 ?
              <Box>
                <Box>
                  <Typography variant="h3" className={`text-center`} color="textPrimary">
                    I
                  </Typography>
                  <Typography variant="h3" className={`text-center`} color="textPrimary">
                    am
                  </Typography>
                  <Typography variant="h3" className={`text-center`} color="textPrimary">
                    {heightDescription}
                    {/* {height} */}
                  </Typography>
                </Box>
                <Box>
                  <Slider
                    orientation="vertical"
                    getAriaValueText={valuetext}
                    defaultValue={height}
                    // value={height}
                    aria-labelledby="vertical-slider"
                    marks={marks}
                    min={300}
                    max={360}
                    onChange={handleChange}
                    // step={10}
                    // onChangeCommitted={handleChange}
                  /> 
                </Box>
              </Box>
            :
              <CircularProgress/>
          }
    </Box>
  )
});

export default Height;