import { makeStyles } from '@material-ui/core/styles';
import colors from '../../utils/colors';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    marginTop: '120px',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    textAlign: 'center',
    [theme.breakpoints.down("sm")]: {
      marginTop: '165px'
    },
  },
  loadingBar:{
    width:"100%",
   
  },
  hidden:{
    display:"none"
  },
  paper: {
    backgroundColor: colors.BACKGROUND_COLOR,
    marginTop: theme.spacing(4),
  },

}));

export default useStyles;