import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../utils/colors';

const useStyles = makeStyles(theme => ({

  reportIssue: {
    '& > .MuiTextField-root': {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
      width: '100%',
    },

    '& > .MuiBox-root > .MuiButtonBase-root': {
      marginBottom: theme.spacing(1),
      marginLeft: theme.spacing(2),
      float: 'right',
    },

    '& > .MuiFormControl-root .MuiInputBase-root': {
      background: colors.WHITE,
      '& .MuiSelect-selectMenu.MuiOutlinedInput-input': {
        padding: 10,
        boxShadow: '0px 5px 6px -6px #000029',
      },
      '& .MuiSvgIcon-root': {
        color: colors.PRIMARY
      }
    } 

  },

  reportIssueSelectItem: {
    '&.Mui-selected, .Mui-selected:hover': {
      color: colors.PRIMARY,
      backgroundColor: 'rgba(0, 0, 0, 0)'
    },
  },

  reportIssueButton: {
    marginTop: 5,
    borderRadius: 40,
    width: 200
  },

}));

export default useStyles;
