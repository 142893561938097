import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, List, ListItem, ListItemIcon, ListItemText, Modal, Button, Paper, TextField, CircularProgress } from '@material-ui/core';

import LinkIcon from '@material-ui/icons/Link';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';

import useStyles from './style';
import Preloader from '../../../components/preloader';
import SnackBar from '../../../components/snackbar';
import Title from '../../../components/title';
import CouponCard from '../../../components/coupon-card';
import { PRELOADER_REFERRAL_CODE, PRELOADER_COUPON, ALERT_SEVERITY_SUCCESS, TOAST_REFERRAL_LINK_COPIED_SUCCESS, TOAST_COUPON_USE_SUCCESS, REFER_MESSAGE, STATUS_SUCCESS, STATUS_FAIL } from '../../../utils/constants';

import { userRequest } from '../../../service/requests';
import { Link } from 'react-router-dom';

const Rewards = (props) => {
  const {
    referralCode,
    isLoading,
    isFetchingCoupons,
    isClaiming,
    isFetched,
    refreshReferralCode,
    isUserReferred,
    submitReferralCode,
    isSubmitting,
    // coupons,
    // claimCoupon,
    creditCount,
    creditCountStatus,
    getCreditCount
  } = props;
  const [openToast, setOpenToast] = useState(false);
  const [toast, setToast] = useState();
  const [coupon, setCoupon] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [referredCode, setReferredCode] = useState('');

  useEffect(() => {
    if(toast != null){
      setOpenToast(true);
    }
  }, [toast]);

  const referralLink = `${window.location.origin}/refer?code=${referralCode}`;
  const subscriptionType = 'Privacy User';
  const monthsFree = '12 months of free service.';
  const referredUser = 'Referred 12 users.';
  const classes = useStyles();

  const copyReferralLink = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(referralLink);
    } else {
      const referralLinkElement = document.createElement('textarea')
      referralLinkElement.value = referralLink;
      referralLinkElement.contentEditable = true;
      referralLinkElement.readOnly = false;
      referralLinkElement.setAttribute('readonly', false);
      referralLinkElement.setAttribute('contenteditable', true);
      referralLinkElement.style.position = 'absolute';
      referralLinkElement.style.left = '-9999px'; 
      document.body.appendChild(referralLinkElement);
      referralLinkElement.select();
      referralLinkElement.setSelectionRange(0, 999999);
      document.execCommand('copy');
	    document.body.removeChild(referralLinkElement);
    }
    setToast({
      severity: ALERT_SEVERITY_SUCCESS,
      message: TOAST_REFERRAL_LINK_COPIED_SUCCESS
    });
    setOpenToast(true);
  }

  const renderCreditCount = () => {
    if (creditCountStatus === STATUS_SUCCESS) {
      return (
        <Typography variant="h4" color="primary" style={{display: 'contents'}}>
          {creditCount}
        </Typography>
      );
    }
    if (creditCountStatus === STATUS_FAIL) {
      return (
        <Box padding={1}>
          <Typography>
            Failed to Load Credit. Click{" "}
            <Link
              color="primary"
              onClick={(e) => {
                e.preventDefault()
                getCreditCount();
              }}
            >
              here
            </Link>{" "}
            to refresh.
          </Typography>
        </Box>
      );
    }
    return <Preloader type={PRELOADER_REFERRAL_CODE} />;
  };

  return (
    <Box className={classes.wrapper}>
      <Title title="Rewards" />
      <Box className={classes.container}>

        <Typography variant="h5" color="primary">Get one month of FREE service for each new friend who signs up with this referral code</Typography>
        <Typography  display="block">Click share below and refer now!</Typography>
        <Typography  display="block">Your referral code:</Typography>
        {
          isLoading ?
            <Preloader type={PRELOADER_REFERRAL_CODE}/>
          :
            !isLoading && !referralCode && isFetched ?
            <Box padding={1}>
              <Typography>Failed to Load Referral Code. Click <Link color="primary" onClick={refreshReferralCode}>here</Link> to refresh.</Typography>
            </Box>
          :
            <Typography variant="h4" color="primary" style={{display: 'contents'}}><span>{referralCode}</span></Typography>
        }
        <Typography  display="block">Months of remaining FREE credit service:</Typography>
        {
          renderCreditCount()
        }
        {
          !isUserReferred &&
          <Box className={classes.rewardForm}>
            <Typography display="block">Enter a referral code:</Typography>
            <TextField
              variant="outlined"
              inputProps={{maxLength: 6, style:{ textAlign: 'center'}}}
              value={referredCode}
              onChange={(e)=>setReferredCode(e.target.value.toUpperCase())}
            />
            <Box>
              <Button 
                color='primary' 
                variant='contained' 
                onClick={() => submitReferralCode(referredCode, setToast)}
                disabled={referredCode.length != 6}
                className={classes.submitBtn}
              >
                {
                  isSubmitting ? 
                  <CircularProgress color="white" size={24} />
                  :
                  'Submit'
                }
              </Button>
            </Box>
          </Box>
        }
        <Grid item className={classes.buttonGrid}>
          <List aria-label='main referral buttons'>
            <ListItem disabled={isLoading} Button component='a' className={classes.copyLinkButton} onClick={copyReferralLink}>
              <ListItemIcon>
                <LinkIcon className={classes.shareIcon} />
              </ListItemIcon>
              <ListItemText primary='Copy Referral Link' className={classes.buttonText} />
            </ListItem>
            <ListItem disabled={isLoading} Button component='a' className={classes.facebookButton} href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(referralLink)}`} target='_blank'>
              <ListItemIcon>
                <FacebookIcon className={classes.shareIcon} />
              </ListItemIcon>
              <ListItemText primary='Share Referral Link to Facebook' className={classes.buttonText} />
            </ListItem>					
            <ListItem disabled={isLoading} Button component='a' className={classes.linkedInButton} href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(referralLink)}&title=SOSearch+Referral+Link`} target='_blank'>
              <ListItemIcon>
                <LinkedInIcon className={classes.shareIcon} />
              </ListItemIcon>
              <ListItemText primary='Share Referral Link to LinkedIn' className={classes.buttonText} />
            </ListItem>
            <ListItem disabled={isLoading} Button component='a' className={classes.twitterButton} href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(REFER_MESSAGE(`${window.location.origin}/refer?code=${referralCode}`))}`} target='_blank'>
              <ListItemIcon>
                <TwitterIcon className={classes.shareIcon} />
              </ListItemIcon>
              <ListItemText primary='Tweet Referral Link to Twitter' className={classes.buttonText} />
            </ListItem>				
          </List>
        </Grid>
        {/* <Box padding={1}>
          { coupons.length > 0 && <Typography variant="h5" color="primary">Available Coupons</Typography> }
          {
            coupons.map((coupon, index) => {
              return(
                <Box mb={2}>
                  {        
                      isFetchingCoupons ? 
                        <Preloader type={PRELOADER_COUPON}/>
                      :
                      <CouponCard
                        subscriptionType={!coupon.coupon_type && subscriptionType}
                        monthsFree={!coupon.coupon_description && monthsFree}
                        referredUser={!coupon.coupon_description && referredUser}
                        claimCoupon={() => {
                          setCoupon(coupon);
                          setOpenModal(true);
                      }} />
                  }        
                </Box>
              )
            })
          }
        </Box> */}
      </Box>
      <SnackBar 
        {...toast}
        open={openToast}
        setOpen={setOpenToast}
      />
      {/* <Modal
        open={openModal}
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
      >
        <Paper className={classes.paper}>
          <Box className={classes.box}>
            <Typography variant="h5" color="textPrimary">
              Coupon
            </Typography>
            <Typography variant="subtitle2" color="textPrimary">
              Are you sure you want to use this coupon to be a Privacy User?
            </Typography>
            <Box className={classes.btnBox} mt={2}>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  className={classes.modalButton}
                  disabled={isClaiming}
                  onClick={ async () => {
                      await claimCoupon(coupon.coupon_id);  
                        setToast({
                          severity: ALERT_SEVERITY_SUCCESS,
                          message: TOAST_COUPON_USE_SUCCESS
                        });
                      setOpenModal(isClaiming);
                  }}>
                  {
                    isClaiming ? 
                      <CircularProgress size={24}/>
                    :
                      'Yes'
                  }
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  color="secondary"
                  disabled={isClaiming}
                  className={classes.modalButton}
                  onClick={() => setOpenModal(false)}
                >
                  No
                </Button>
            </Box>
          </Box>
        </Paper>
      </Modal> */}
    </Box>
  )
}

export default Rewards
