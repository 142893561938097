import { all, put, takeEvery, select } from 'redux-saga/effects';

import axios              from 'axios';
import moment             from 'moment';

import action             from './action';
import searchAction       from '../search/action';
// import notificationAction from '../notification/action';

import { connectionRequest, multipleRequest, nearMeRequest, userRequest } from '../../service/requests';
import { 
  CALL_STATUS_INBOUND_AUDIO,
  CALL_STATUS_MISSED_AUDIO,
  CALL_STATUS_OUTBOUND_AUDIO,
  CHANNEL, 
  HISTORY_FILTER_AUDIO,
  HISTORY_FILTER_CHAT, 
  HTTP_STATUS, 
  TOAST_AXIOS_REQUEST_ERROR, 
  TWILIO_BASE_URL
} from '../../utils/constants';
import { createChatClient } from '../../utils/twilio';
import { handleHttpError } from '../../utils/helper';

/////////////////////////
// reqAudioCallGetCallLog()
/////////////////////////
function* reqAudioCallGetCallLog(apiPath, params) {

	try {
		console.log('reqAudioCallGetCallLog()');

		const res = yield axios.get(
			TWILIO_BASE_URL + apiPath,
			{
				params,
				auth: {
					username : process.env.REACT_APP_TWILIO_ACCOUNT_SID,
					password : process.env.REACT_APP_TWILIO_AUTH_TOKEN
				}
			}
		)

		return res;
	} catch (error) {
		console.log('error : ', error);
	}
}

/////////////////////////
// reqAudioCallGetCombinedCallLog()
/////////////////////////
function* reqAudioCallGetCombinedCallLog(apiPath, inboundCallParam, outboundCallParam) {

  try {
		console.log('reqAudioCallGetCombinedCallLog()');
		console.log('apiPath 					 : ', apiPath);
		console.log('inboundCallParam  : ', inboundCallParam);
		console.log('outboundCallParam : ', outboundCallParam);

		let inboundCallLog			= [];
    let outboundCallLog			= [];
    let combinedCallLog			= [];
		let inboundCallApiPath	= apiPath;
		let outboundCallApiPath	= apiPath;

		while (inboundCallApiPath != null) {
			const resInboundCall 		 = yield reqAudioCallGetCallLog(inboundCallApiPath, inboundCallParam);

			inboundCallLog 		 	= [...inboundCallLog, ...resInboundCall.data.calls];
			inboundCallApiPath 	= resInboundCall.data.next_page_uri
		}

		while (outboundCallApiPath != null) {
			const resOutboundCallLog = yield reqAudioCallGetCallLog(outboundCallApiPath, outboundCallParam);

			outboundCallLog 		= [...outboundCallLog, ...resOutboundCallLog.data.calls];
			outboundCallApiPath = resOutboundCallLog.data.next_page_uri
    }
    
    [...inboundCallLog, ...outboundCallLog].map(callLog => {
      let detail = '';
      if (callLog.status == 'completed' && callLog.direction == 'outbound-dial') {
        
        detail   = CALL_STATUS_OUTBOUND_AUDIO;
      } else if (callLog.status == 'completed' && callLog.direction == 'inbound') {
        
        detail   = CALL_STATUS_INBOUND_AUDIO;
      } else if (callLog.status == 'no-answer' && callLog.direction == 'inbound') {
        detail   = CALL_STATUS_MISSED_AUDIO;
      } else if (callLog.status == 'no-answer' && callLog.direction == 'outbound-dial') {
        detail   = CALL_STATUS_MISSED_AUDIO;
      } else {
        detail   = CALL_STATUS_MISSED_AUDIO;
      }

      combinedCallLog.push({
        detail,
        historyType : HISTORY_FILTER_AUDIO,
        time        : moment(moment(callLog.end_time).format('YYYY-MM-DD HH:mm')).unix()
      });
    });
    
    return combinedCallLog

  } catch (error) {
    console.log('error');
    console.log(error);
  }
}

/////////////////////////
// reqConnectionHistory()
/////////////////////////
function* reqConnectionHistory({ payload }) {
  
  try {
    console.log('reqConnectionHistory()');
    
    const { userId, connectionUserId, page } = payload;
    let connectionHistory   = [];
    const getTwilioChannels = state => state.Message.twilio.channels;
    const channels          = yield select(getTwilioChannels);
    const uniqueName        = (userId > connectionUserId) ? `${CHANNEL + connectionUserId}_${userId}` : `${CHANNEL + userId}_${connectionUserId}`;    
    const chatRecord        = channels.find(channel => channel.state.uniqueName == uniqueName);

    console.log("userId", userId)
    console.log("connectionUserId", connectionUserId)
    console.log("page", page)

    if (chatRecord) {
        const userMessages = yield chatRecord.getMessages();
        let messageList    = [];

        if (userMessages) {
          userMessages.items.forEach(message => {
            console.log('message : ', message);
            const messageObj = {
              _id         : message.state.index,
              text        : message.state.body,
              createdAt   : message.state.timestamp,
              user        : {
                              _id  : parseInt(message.state.author),
                              // name : connectionUserName,
                            }
            };

            (messageObj.user._id != userId) &&
            connectionHistory.push({
              detail      : messageObj.text,
              historyType : HISTORY_FILTER_CHAT,
              time        : moment(moment(messageObj.createdAt).format('YYYY-MM-DD HH:mm')).unix()
            });
            messageList.push(messageObj)
          });
      }
    }

    const resConnectionHistory = yield connectionRequest.getHistory({
      connection_user_id : connectionUserId,
      page
    }, userId).catch(error =>{
      handleHttpError({
        error,
        request: 'connectionRequest.getHistory::src/redux/connection'
      })
    })

    if (resConnectionHistory) {
      console.log('resConnectionHistory');
      console.log(resConnectionHistory);

      connectionHistory = [...connectionHistory, ...resConnectionHistory.data.history]
    }

    const sortedConnectionHistory = connectionHistory.sort((a, b) => {
      return b.time - a.time;
    });

    yield put({
      type    : action.CONNECTION_GET_HISTORY_SUCCESS,
      payload : sortedConnectionHistory
    });
  } catch (error) {
    console.log('error');
    console.log(error);

    yield put({
      type    : action.CONNECTION_GET_HISTORY_ERROR,
      payload : []
    });
  }
}

/////////////////////////
// reqConnectionViewProfile()
/////////////////////////
function* reqConnectionViewProfile({ payload }) {
  const { userId, connectionUserId } = payload;
  
  try {
    console.log('reqConnectionViewProfile()');

    // TODO
    // const getSearchList   = state => state.Search.searches;
    // const searches      = yield select(getSearchList);
    
    // console.log('searches : ', searches);

    // searches.map(searchListItem => {
    //   let searchedUser = searchListItem.searchResult.find(searchResultItem => searchResultItem.user.user.userId == connectionUserId)
      
    //   if (searchedUser) {
    //     if (!searchedUser.isViewed) {
    //       searchListItem.searchResult[searchListItem.searchResult.indexOf(searchedUser)]['isViewed'] = true;
    //       searchListItem['unviewedSearchResultCount'] = searchListItem.unviewedSearchResultCount - 1;
    //       return searchListItem;
    //     }
    //   } else {
    //     return searchListItem;
    //   }
    // });

    // yield put({
    //   type    : searchAction.SEARCH_UPDATE_SEARCH_LIST,
    //   payload : searches
    // });

    const resGetMediaGallery = yield userRequest.getMediaGallery({
      user_id : connectionUserId
    }).catch(error =>{
      handleHttpError({
        error,
        request: 'userRequest.getMediaGallery::src/redux/connection'
      })
    });

    console.log(resGetMediaGallery);

    if (resGetMediaGallery) {
      if (resGetMediaGallery?.data?.status?.code == HTTP_STATUS._200) {
        yield put({
          type    : action.CONNECTION_SET_MEDIA_GALLERY,
          payload : resGetMediaGallery.data.media
        });
      }
    }

    const res = yield connectionRequest.viewUser({
      viewer_user_id : userId
    }, 
    connectionUserId
    ).catch(error => {
      handleHttpError({
        error,
        request: 'connectionRequest.viewUser::src/redux/connection'
      })
    });

    console.log(res);

  } catch (error) {
    console.log('error');
    console.log(error);
  }
}

/////////////////////////
// reqConnectionGetShortlist()
/////////////////////////
function* reqConnectionGetShortlist() {
  
  try {
    console.log('reqConnectionGetShortlist()');

    const getUser = state => state.Session.user;
    const User = yield select(getUser);

    const res = yield connectionRequest.getShortlist({
      user_id : User.user.userId
    }).catch(error =>{ 
      handleHttpError({
        error,
        request: 'connectionRequest.getShortlist::src/redux/connection'
      })
    });

    console.log('res : ', res);

    if (res) {
      yield put({
        type    : action.CONNECTION_GET_SHORTLIST_SUCCESS,
        payload : res.data.userConnection
      });

    } else {
      yield put({
        type    : action.CONNECTION_GET_SHORTLIST_ERROR,
        payload : []
      });
    }

  } catch (error) {
    console.log('error');
    console.log(error);

    yield put({
      type    : action.CONNECTION_GET_SHORTLIST_ERROR,
      payload : []
    });
  }
}

/////////////////////////
// reqConnectionGetRecentViewsList()
/////////////////////////
function* reqConnectionGetRecentViewsList({payload}) {

  try {
    console.log('reqConnectionGetRecentViewsList()');

    const getUser = state => state.Session.user;
    const User = yield select(getUser);

    const res = yield multipleRequest([
      yield connectionRequest.getWhoLikedMe({
        user_id : User.user.userId,
        page: 1
      }),
      yield connectionRequest.getWhoIViewed({
        user_id : User.user.userId,
        page: 1
      }),
      yield connectionRequest.getWhoViewedMe({
        user_id : User.user.userId,
        page: 1
      })
    ]).catch(error=>{
      handleHttpError({
        error,
        request: 'connectionRequest.getWhoLikedMe&&getWhoIViewed&&getWhoViewedMe::src/redux/connection'
      })
    });

    console.log('reszzz : ', res);

    if (res) {
      let unviewedViewedMeUsers = 0;
      const recentViewsList = {
        likedMe  : res[0].data.status.code == HTTP_STATUS._200 ? res[0].data.users : [],
        iViewed  : res[1].data.status.code == HTTP_STATUS._200 ? res[1].data.userConnection : [],
        viewedMe : res[2].data.status.code == HTTP_STATUS._200 ? res[2].data.userConnection : []
      };

      yield put({
        type    : action.CONNECTION_GET_RECENT_VIEWS_SUCCESS,
        payload : recentViewsList
      });

      recentViewsList.viewedMe.forEach(user => {
        unviewedViewedMeUsers = !user.profileView.isSeen ? (unviewedViewedMeUsers + 1) : unviewedViewedMeUsers
      });

    } else {
      yield put({
        type    : action.CONNECTION_GET_RECENT_VIEWS_ERROR,
        payload : []
      });
    }

  } catch (error) {
    handleHttpError({
      error: {
        message: TOAST_AXIOS_REQUEST_ERROR
      },
      request: 'src/redux/connection'
    })
    
    yield put({
      type    : action.CONNECTION_GET_RECENT_VIEWS_ERROR,
      payload : []
    });
  }
}


export default function* rootSaga() {
  yield all([takeEvery(action.CONNECTION_VIEW_PROFILE,     reqConnectionViewProfile)]);
  yield all([takeEvery(action.CONNECTION_GET_HISTORY,      reqConnectionHistory)]);
  yield all([takeEvery(action.CONNECTION_GET_SHORTLIST,    reqConnectionGetShortlist)]);
  // yield all([takeEvery(action.CONNECTION_GET_NEAR_ME,      reqConnectionGetNearMeList)]);
  yield all([takeEvery(action.CONNECTION_GET_RECENT_VIEWS, reqConnectionGetRecentViewsList)]);
}


/*


/////////////////////////
// reqConnectionGetNearMeList()
/////////////////////////
function* reqConnectionGetNearMeList() {
  
  try {
    console.log('reqConnectionGetNearMeList()');

    const getUser = state => state.Session.user;
    const User = yield select(getUser);

    const res = yield nearMeRequest.getNearMeHistory({
      user_id : User.user.userId
    });

    console.log('res : ', res);

    if (res) {
      yield put({
        type    : action.CONNECTION_GET_NEAR_ME_SUCCESS,
        payload : res.data.userNearMe
      });

    } else {
      yield put({
        type    : action.CONNECTION_GET_NEAR_ME_ERROR,
        payload : []
      });
    }

  } catch (error) {
    console.log('error');
    console.log(error);
    
    yield put({
      type    : action.CONNECTION_GET_NEAR_ME_ERROR,
      payload : []
    });
  }
}
*/