import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../utils/colors';

const useStyles = makeStyles(theme => ({
  
  box: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddintTop: theme.spacing(2),
    paddintBottom: theme.spacing(2),
    width: '100%',
  },

  genderContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: theme.spacing(8),
    width: '100%',
  },

  gender: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },

  image: {
    height: 120,
    marginBottom: theme.spacing(2),
  },

  selectedImage: {
    height: 170,
    marginBottom: theme.spacing(2),
  },

  vertical: {
    height: 170,
    borderLeft: `2px solid ${colors.TEXT_GRAY}`,
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
  },
  
}));

export default useStyles;