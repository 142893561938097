import { Prompt } from 'react-router';
import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Typography, 
         CircularProgress, Link }             from '@material-ui/core';

import SadIcon                                from '../../../../assets/images/sad.png';
import SmartPhoneWithSadIcon                  from '../../../../assets/images/smartphone_sad.png';
import useStyles                              from './style';
import PinInput                               from 'react-pin-input';
import { connect } from 'react-redux';
import rootAction from '../../../../redux/action';
import { userRequest, billingRequest } from '../../../../service/requests';
import SnackBar from '../../../../components/snackbar';
import { ALERT_SEVERITY_SUCCESS,ALERT_SEVERITY_ERROR,TOAST_AXIOS_REQUEST_ERROR, TOAST_CODE_SEND, TOAST_CODE_INVALID } from '../../../../utils/constants';

const { clearUserData,signOutUser } = rootAction;
const { billingUnsubscribeWipe } = billingRequest;

///////////////////////
// Unsubscribe Panel//
/////////////////////
console.log('Unsubscribe Panel');

const UnsubscribePlan = (props) => {

    const classes = useStyles();
    const { userSubscription, updateUserSubscription, lookUpSubscriptionPlan, userId, setOpenUnsubscribePanel, signOutUser, clearUserData } = props;

    const [isUnsubscribeLoading, setIsUnsubscribeLoading] = useState(false);
    const [ confirmationPage,    setConfirmationPage ]    = useState(1);
    const [ isResending,         setResending ]           = useState(false);
    const [ timer,               setTimer ]               = useState('60'); //Initial Timer by Second
    const [snackbarSeverity,     setSnackbarSeverity ]    = useState('');
    const [snackbarMessage,      setSnackbarMessage ]     = useState('');
    const [isSnackbarOpen,       setIsSnackbarOpen ]      = useState(false);
    const userSubscriptionPLan = userSubscription.membershipLevelCode === 'PRV'? 'Privacy': userSubscription.membershipLevelCode === 'ELITE' ? 'ELT' : 'Regular';
    
    console.log(userSubscription.membershipLevelCode,'Unsubscribe Panel userSubscription');

    const [ state, setState ] = useState({
      code          : '',
      isCodeError   : false,
      codeErrorDesc : '',
    });

    

    const { code, isCodeError, codeErrorDesc } = state;
    const useRefTimer = useRef(null);
    
    const handleInputChange = event => {
      let value = event.target.value.replace(/[^0-9]/g, '');
      setState({
        ...state,
        code : value,
      });
    };
  
    const getTimerTimeRemaining = (event) => {
        const total   = Date.parse(event) - Date.parse(new Date());
        const seconds = Math.floor((total / 1000) % 60);
        return { total, seconds };
    }
  
    const startTimer = (event) => {
        let { total, seconds } = getTimerTimeRemaining(event);
        total >= 0 && setTimer(( seconds > 9 ? seconds : seconds ))
    }
  
    const preStartTimer = (event) => { 
        useRefTimer.current && clearInterval(useRefTimer.current);
        const id = setInterval(() => {
            startTimer(event);
        }, 1000)
        useRefTimer.current = id;
    }
  
    const getTimer = (params) => {
        let timeComputed = new Date();
        timeComputed.setSeconds(timeComputed.getSeconds() + params);
        return timeComputed;
    }

    const userClearData = (params) =>{
      clearUserData(params);
    }

    const endSubscription = async () => {
      const { userId } = props;
      try {
        setIsUnsubscribeLoading(true);
          const billingResponse = await billingUnsubscribeWipe(userId);
          console.log(billingResponse)
          if (billingResponse.status == '200') {

            setSnackbarSeverity(ALERT_SEVERITY_SUCCESS);
            setSnackbarMessage('You have successfully ended your subscription.');
            setIsSnackbarOpen(true);

            setConfirmationPage(prevPage => prevPage + 1);
            userClearData(confirmationPage);
          }
      } catch (err) {

        setSnackbarSeverity(ALERT_SEVERITY_ERROR);
        setSnackbarMessage(TOAST_AXIOS_REQUEST_ERROR);
        setIsSnackbarOpen(true);
    
        console.log('error :', err);
      } finally {
        setIsUnsubscribeLoading(false);
       
      }
    }

    const verificationOTPCode = async  (params) => {
      const { userId, phoneNo } = props.user;
      console.log('Veririfcation Code()');
  
      const response = await userRequest.verifyPhone({
        user_id  : userId,
        phone_no : phoneNo,
        conf_code: params
      }).catch(error => {
        console.log('error : ', error);
      });

      if(response.data.code !== "301" && response.status === 200){
        endSubscription();
      }else{
        setSnackbarSeverity(ALERT_SEVERITY_ERROR);
        setSnackbarMessage(TOAST_CODE_INVALID);
        setIsSnackbarOpen(true);
      }
      console.log(response, ' Veririfcation Code()');
    }

    const resendOTP = async () => {
      const { userId, phoneNo } = props.user;
      console.log('sentOTP()');
      const response = await userRequest.resendCode({
        user_id : userId,
        phone_no: phoneNo
      }).catch(error => {
        console.log('error : ', error);
      });
    
      if(response.status === 200){
        setResending(true); 
        preStartTimer(getTimer(60)); // Set Timer by Second
      }
    }

    const sentOTP = async () =>{
      const { userId, phoneNo } = props.user;
      console.log('sentOTP()');
           
      const response = await userRequest.sentOTP({
        user_id : userId,
        phone_no: phoneNo
      }).catch(error => {
        console.log('error : ', error);
      });

      if(response.status === 200){
        setConfirmationPage(prevPage => prevPage + 1);
        setSnackbarSeverity(ALERT_SEVERITY_SUCCESS);
        setSnackbarMessage(TOAST_CODE_SEND);
        setIsSnackbarOpen(true);
      }
    }

    const onProceed = () =>{
      confirmationPage === 2 &&  sentOTP();
      confirmationPage === 4 &&  userClearData(confirmationPage);
      if(confirmationPage === 3){code.length === 6 && verificationOTPCode(code)};
    }
 
    useEffect(() => {
        timer === 0 && setResending(false); 
    }, [timer]);

    useEffect(() => {
      console.log(code,'code');
    }, [code]);

  return (
    <Box className={classes.box}>
    <Prompt when = { confirmationPage === 4} message = { () => {
      userClearData(confirmationPage);
      return `Sorry you can't proceed to this Page, you will be automatically Logout after this message`
  }}/>

        { 
        confirmationPage != 3 ?
              <Box className = { classes.container }> 
                <Box className = { classes.textContentBox }>
                { confirmationPage === 1 ? <img src = { SmartPhoneWithSadIcon } className = {`${classes.planIcon} ${classes.quickFade}`} /> : <img src = { SadIcon } className = {`${classes.sadIcon} ${classes.fade}`} />}
                  
                    {
                    confirmationPage === 1 ? <Typography variant = "subtitle2"  align = "center" color = "textPrimary">
                      {`We are sorry to see you go, but we hope it is for all the right reasons!`}{<br/>}{<br/>}
          
                      {` By confirming below, your account will be closed, and all personally identifiable information will be deleted including chats,`} 
                      { `pictures and payment information. You will be starting over if you re-join S.O Search in the future.`}{<br/>}{<br/>}
          
                      {`If you would still like to proceed, please confirm below.`}
                      </Typography>
                      :
                      
                      confirmationPage === 2 ? 
                      <Typography variant = "subtitle2"  align = "center" color = "textPrimary">
                      {` Are you sure you want to End your subscription to Privacy?`}{<br/>}
                      {`This will cancel your ${userSubscriptionPLan} subscription.`}
                      </Typography>
                      : 
                      <Typography variant = "subtitle2"  align = "center" color = "textPrimary">
                      {`You have ended your ${userSubscriptionPLan} subscription plan.`}
                      </Typography>
                    }
                </Box>
                <Box className={classes.bottomBoxButton}>
                    <Button variant="contained" className={`${classes.button} ${classes.confirmButton}`} onClick={() => { confirmationPage === 1 && setConfirmationPage(prevPage => prevPage + 1); onProceed(); }}>{ confirmationPage !== 4 ? 'Confirm' : 'Proceed'}</Button>
                   {   confirmationPage !== 4 && <Button variant="contained" className={`${classes.button} ${classes.cancelButton}`}  onClick={() => setOpenUnsubscribePanel(false)} >Cancel</Button>}
                </Box>
              </Box>
              :
              <Box className={classes.form}>
                      <Typography variant = "h4"  align = "center" color = "textPrimary" style = {{ fontWeight: 'bold', marginBottom: 50 }}>
                       {`OTP Verification`}
                       </Typography>
                     <Typography variant = "subtitle1"  align = "center" color = "textPrimary">
                       {`Enter the code sent to your`}{<br/>}
                       {`Mobile number.`}
                       </Typography>

                       <Box  style = {{ display: 'flex', flexDirection: 'row' }}>
                          {
                            isResending ?
                              <CircularProgress style = {{ marginLeft: 'auto', marginRight: 10, marginTop: 50 }} color = 'primary' size = { 24 }/>
                            : 
                            <Link 
                            onClick   = {() => { resendOTP() }}
                            component = "button" 
                            variant   = "body2" 
                            color     = 'primary' 
                            style     = {{ fontSize: '16px', fontWeight:'bold',marginTop: 50, marginLeft: 'auto' }}>
                            Resend Code
                           </Link>
                          } 
                          {
                            isResending && <Typography variant = "subtitle2"  align = "center" color = "primary" style = {{ fontSize: '16px', fontWeight:'bold', marginTop: 50 }}> {`${timer} Sec`} </Typography>
                          }
                       </Box>
                    
                    <PinInput 
                      length       = { 6 }
                      initialValue = { code }
                      onChange     = { (value) => handleInputChange( { target: { value, name: 'code' } } ) }
                      type         = "numeric" 
                      inputMode    = "number"
                      autoSelect   = { true }
                    /> 
                    { isCodeError && <Typography color = { 'error' }>{ codeErrorDesc }</Typography>} 
                    <Button variant = "contained" style = {{ marginTop: 60 }} disabled = { code.length === 6 ? false : true}className={`${classes.button} ${classes.confirmButton}`} onClick={ () => {  onProceed();} }>Proceed</Button>
                </Box>
                }
      <SnackBar
        severity = { snackbarSeverity }
        message  = { snackbarMessage }
        open     = { isSnackbarOpen }
        setOpen  = { setIsSnackbarOpen }
      />
    </Box>
    
  )
}


/////////////////////////////
// mapStateToProps()
/////////////////////////////
const mapStateToProps = (state) => {
  console.log('unsubscribe/index.js:mapStateToProps(state)');
  console.log(state);
  return {};
}

// connect(mapStateToProps, mapActionToProps)(Welcome);
export default connect (mapStateToProps, {clearUserData, signOutUser}) (UnsubscribePlan);
