import React, { useEffect, useState } from 'react';
import { Box, Button, Divider, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Modal, Typography,Paper } from '@material-ui/core';
import useStyles from './style';
import Preloader from '../../preloader';
import Empty from '../../empty';
import HistoryCard from '../../../components/historycard';
import { connectionRequest } from '../../../service/requests';

import CheckIcon from '@material-ui/icons/Check';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';

import {  
  HISTORY_FILTER_ALL, 
  HISTORY_FILTER_AUDIO,
  HISTORY_FILTER_CHAT,
  HISTORY_FILTER_EVENT,
  HISTORY_FILTER_NOTIFICATION,
  HISTORY_FILTER_VIDEO,
  HTTP_STATUS,
  MSG_NO_PROFILE_HISTORY,
  PRELOADER_USER_HISTORY 
} from '../../../utils/constants';

import NO_HISTORY from '../../../assets/images/no_history.png';
import { handleHttpError } from '../../../utils/helper';

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    width: 400
  };
}

const History = (props) => {
 
  console.log('History()');
  const classes = useStyles();

  const { userId, userConnection, /*connectionHistory, isHistoryLoading,*/ getHistory } = props;

  const [state, setState] = useState({
    history         : [],
    filterType      : HISTORY_FILTER_ALL,
    selectedFilter  : [HISTORY_FILTER_ALL, HISTORY_FILTER_AUDIO, HISTORY_FILTER_CHAT, HISTORY_FILTER_EVENT, HISTORY_FILTER_VIDEO, HISTORY_FILTER_NOTIFICATION]
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [historyPage, setHistoryPage] = useState(1);
  const [isHistoryLoading, setIsHistoryLoading] = useState(false);
  const [connectionHistory, setConnectionHistory] = useState([]);
  const [modalStyle] = useState(getModalStyle);

  const { filterType, selectedFilter } = state;
  const [history, setHistory] = useState([]);

  useEffect(() => {
    //getHistory({ userId, connectionUserId : userConnection.user.userId, page: historyPage})
    setIsHistoryLoading(true);
    connectionRequest.getHistory({
      connection_user_id: userConnection.user.userId,
      page: historyPage
    }, userId)
    .then(response=>{
      console.log("response", response);
      if(response?.data?.status?.code === HTTP_STATUS._200){
        setConnectionHistory([...connectionHistory, ...response.data.history]);
      }
    })
    .catch(error=>{
      handleHttpError({
        error,
        request: 'connectionRequest.getHistory::src/components/userprofile/history'
      })
    })
    .finally(()=>{
      setIsHistoryLoading(false)
    })
  }, []);

  useEffect(() => {
    // getHistory({userId, connectionUserId : userConnection.user.userId, page: historyPage})
    setIsHistoryLoading(true);
    connectionRequest.getHistory({
      connection_user_id: userConnection.user.userId,
      page: historyPage
    }, userId)
    .then(response=>{
      console.log("response", response);
      if(response?.data?.status?.code === HTTP_STATUS._200){
        if(response.data.history.length != 0){
          setConnectionHistory([...connectionHistory, ...response.data.history]);
        }
      }
    })
    .catch(error=>{
      handleHttpError({
        error,
        request: 'connectionRequest.getHistory::src/components/userprofile/history'
      })
    })
    .finally(()=>{
      setIsHistoryLoading(false)
    })
  }, [historyPage]);

  useEffect(() => {
    if(connectionHistory != undefined){
      setHistory(connectionHistory);
    }
  }, [connectionHistory]);

  const onBottomScrollRef = useBottomScrollListener(()=>{
    console.log("onBottomScrollRef");
    setHistoryPage(historyPage + 1);
  }, {
    debounce: 200,
    triggerOnNoScroll: false,
  });

  const setFilter = (filterType) => {
    console.log('History.setFilter()');

    setIsModalOpen(false);

    if (!connectionHistory)
      return

    let updSelectedFilter = selectedFilter;
    let updHistory = connectionHistory;

    if (updSelectedFilter.includes(filterType)) {
      updSelectedFilter.splice(updSelectedFilter.indexOf(filterType), 1);
    } else {
      updSelectedFilter.push(filterType);
    }
  
    setState({
      ...state,
      history         : updHistory.filter(connectionHistoryItem => updSelectedFilter.includes(connectionHistoryItem.historyType)),
      selectedFilter  : updSelectedFilter
    });
  }

  return (
    <Box py={5} boxShadow={2} style={{borderRadius: 10, backgroundColor: '#ffffff', padding: '12px'}}>   
      <Box className={classes.infoBar}>
        <Typography>History</Typography>
        <IconButton onClick={() => setIsModalOpen(true)}>
          <FilterListIcon color='primary'/>
          <Typography paddingTop='12px' color='primary'>Filter</Typography>
        </IconButton>
      </Box>
      {
        isHistoryLoading ?
          Array.from({ length: 8 }, () => 1).map((value, index) => {
            return (
              <Preloader type={PRELOADER_USER_HISTORY} key={index} />
            )
          })
        : 
        <Paper ref={onBottomScrollRef}  style={{maxHeight: 500, overflow: (history && history.length > 5 ) ? 'auto': null}} elevation = {0}>
         
          <List aria-label="user profile history" style={{ marginLeft: 40, marginRight: 40}}>
                  {
                    (history && history.length > 0)
                    ? history.map((item, index) => {
                        return (
                          <HistoryCard key={index} historyType={item.historyType} detail={item.detail} time={item.time} userName={userConnection.user.userName} />
                        )
                      })
                    : 
                    <Box display='flex' justifyContent='center' alignItems='center' height={500}>
                      <Empty image={NO_HISTORY} title={MSG_NO_PROFILE_HISTORY} />
                    </Box>
                  }
                </List>
      </Paper>
        
      }
      <Modal
        aria-labelledby='filter-modal'
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        BackdropProps={{ className: classes.backdrop }}
      >
        <div style={modalStyle} className={classes.modalPaper}>
          <Box display='flex' flexDirection='row' alignItems='center'>
            <Box width='90%' display='flex' alignItems='center' justifyContent='center'>
              <Typography
                align='center'
                variant="h6"
                color="textPrimary"
                className={classes.filterLabel}
              >
                Filter
              </Typography>
            </Box>
            <Box width='10%' display='flex' alignItems='center' justifyContent='flex-end'>
              <IconButton aria-label='close' onClick={() => setIsModalOpen(false)}>
                <CloseOutlinedIcon />
              </IconButton>
            </Box>
          </Box>
          <List aria-label="user profile filter options">
            <ListItem button onClick={setFilter.bind(this, HISTORY_FILTER_CHAT)}>
              <ListItemText>
                <Typography
                  display='inline'
                  variant="subtitle1"
                  color="textPrimary"
                  className={classes.filterOption}
                >
                  Chat
              </Typography>
              </ListItemText>
              {
                selectedFilter.includes(HISTORY_FILTER_CHAT) &&
                <ListItemSecondaryAction>
                  <CheckIcon color='primary' />
                </ListItemSecondaryAction>
              }
            </ListItem>
            <ListItem button onClick={setFilter.bind(this, HISTORY_FILTER_AUDIO)}>
              <ListItemText>
                <Typography
                  display='inline'
                  variant="subtitle1"
                  color="textPrimary"
                  className={classes.filterOption}
                >
                  Voice Call
              </Typography>
              </ListItemText>
              {
                selectedFilter.includes(HISTORY_FILTER_AUDIO) &&
                <ListItemSecondaryAction>
                  <CheckIcon color='primary' />
                </ListItemSecondaryAction>
              }
            </ListItem>
            <ListItem button onClick={setFilter.bind(this, HISTORY_FILTER_VIDEO)}>
              <ListItemText>
                <Typography
                  display='inline'
                  variant="subtitle1"
                  color="textPrimary"
                  className={classes.filterOption}
                >
                  Video Call
              </Typography>
              </ListItemText>
              {
                selectedFilter.includes(HISTORY_FILTER_VIDEO) &&
                <ListItemSecondaryAction>
                  <CheckIcon color='primary' />
                </ListItemSecondaryAction>
              }
            </ListItem>
            <ListItem button onClick={setFilter.bind(this, HISTORY_FILTER_NOTIFICATION)}>
              <ListItemText>
                <Typography
                  display='inline'
                  variant="subtitle1"
                  color="textPrimary"
                  className={classes.filterOption}
                >
                  Notifications
              </Typography>
              </ListItemText>
              {
                selectedFilter.includes(HISTORY_FILTER_NOTIFICATION) &&
                <ListItemSecondaryAction>
                  <CheckIcon color='primary' />
                </ListItemSecondaryAction>
              }
            </ListItem>
            <ListItem button onClick={setFilter.bind(this, HISTORY_FILTER_EVENT)}>
              <ListItemText>
                <Typography
                  display='inline'
                  variant="subtitle1"
                  color="textPrimary"
                  className={classes.filterOption}
                >
                  Calendar Event
              </Typography>
              </ListItemText>
              {
                selectedFilter.includes(HISTORY_FILTER_EVENT) &&
                <ListItemSecondaryAction>
                  <CheckIcon color='primary' />
                </ListItemSecondaryAction>
              }
            </ListItem>
          </List>
        </div>
		  </Modal>
    </Box>
  )
}

export default History;