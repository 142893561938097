import { makeStyles } from '@material-ui/core/styles';
import colors from '../../utils/colors';

const useStyles = makeStyles(theme => ({
    card: {
        borderRadius: 10,
        border: 'none',
        boxShadow: 'none'
    },

    cardHeader: {
        marginLeft: 16,
        marginRight: 16,
        color: colors.PRIMARY,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '& p > a': {
            borderBottom: 'solid 1px',
            cursor: 'pointer',
            color: colors.PRIMARY,
            fontSize: 12
        }
    },

    cardContent: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        marginBottom: theme.spacing(1),
        paddingBottom: '0 !important',
        [theme.breakpoints.down('md')]: {
            marginLeft: -10
        },

        [theme.breakpoints.down('xs')]: {
            padding: 0,
            overflowX: 'scroll',
            justifyContent: 'left'
        }
    },

    multipleRows: {
        flexFlow: 'wrap',
        justifyContent: 'center',
        marginLeft: theme.spacing(1.5),
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(-1)
        },
    },

    item: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: 10,
        alignItems: 'center',
        '& div': {
            display: 'flex',
            cursor: 'pointer'
        },
        [theme.breakpoints.down('md')]: {
            marginLeft: 10
        }
    },

    itemImg: {
        height: 120,
		width: 120
    },

    itemSearch: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: theme.spacing(1.5),
        [theme.breakpoints.down('sm')]: {
            marginLeft: theme.spacing(0.5)
        },

        '& div.MuiCard-root': {
            display: 'flex',
            flexDirection: 'column',
            cursor: 'pointer',
            marginBottom: theme.spacing(1.5),
            [theme.breakpoints.down('sm')]: {
                marginBottom: theme.spacing(0.5),
            }
        },

        '& img': {
            minWidth: 180,
            maxHeight: 180,
            [theme.breakpoints.down('md')]: {
                maxWidth: 100
            }
        },

        "& p:nth-child(1)" : {
            fontWeight: 'bold',
            fontSize: 16
        },
        '& p:nth-child(n+2)': {
            fontSize: 10
        }
    },

    itemSearchImg: {
        width: 180,
        height: 180,
        [theme.breakpoints.down('md')]: {
            width: 100,
            height: 100
        }
    },

    profileDetails: {
        margin: '0 1em',
    },

    preloaderHome: {
        width: 'fit-content',
        maxWidth: '0 !important'
    },
    
    planIcon: {
        width: '20px',
        height: '20px'
      },
    
      quickFade: {
        animation: '$fadeInAnimation 0.5s forwards',
      },
}));

export default useStyles;