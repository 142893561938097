import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';

import useStyles from '../style';
import ChatLayout from '../../../components/chat-layout';
import Empty from '../../../components/empty';
import MessageCenterCard from '../../../components/message-center-card';
import Preloader from '../../../components/preloader';

import connectionAction from '../../../redux/connection/action';
import messageAction from '../../../redux/message/action';
import notificationAction from '../../../redux/notification/action';
import NO_NOTIFICATION_IMG from '../../../assets/images/no_notifications.png';
import NO_CALL_HISTORY_IMG from '../../../assets/images/no_calls.png'
import NO_CHAT_IMG from '../../../assets/images/no_chats.png'
import NotificationCard from '../../../components/notification-card'
import { useBottomScrollListener } from 'react-bottom-scroll-listener';

import {
  CALENDAR_INVITE_ACCEPTED,
  CALENDAR_INVITE_SENT,
  CALL_ACTION_COMPLETED,
  CALL_ACTION_DECLINE,
  CALL_ACTION_MISSED,
  CALL_CARD,
  CALL_STATUS_INBOUND_AUDIO,
  CALL_STATUS_INBOUND_VIDEO,
  CALL_STATUS_MISSED_AUDIO,
  CALL_STATUS_MISSED_VIDEO,
  CALL_STATUS_OUTBOUND_AUDIO,
  CALL_STATUS_OUTBOUND_VIDEO,
  CALL_TYPE_AUDIO,
  CALL_TYPE_VIDEO,
  COMMUNICATION_TYPE_VIDEO,
  EMPTY_CHAT,
  NO_NOTIFICATION,
  NO_NOTIFICATION_DESC,
  NOTIFICATION_CARD,
  PRELOADER_CALL_CARD,
  PRELOADER_MESSAGE_CARD,
  PRELOADER_NOTIFICATION_CARD,
  NO_NOTIFICATION_HISTORY_TITLE,
  NO_CALL_HISTORY_TITLE,
  NO_CALL_HISTORY_DESC,
  NO_CHAT_HISTORY_TITLE,
  NO_CHAT_HISTORY_DESC,
  COMMUNICATION_TYPE_AUDIO
} from '../../../utils/constants'
import { callRequest, multipleRequest } from '../../../service/requests';

const { getRecentViews, setUserConnection } = connectionAction;
const { saveMessages, seenMessage, updateMessageParticipant, updateTwilio, updateTwilioClient, getAllMessages } = messageAction;
const { getNotifications, markAllRead, seenNotification } = notificationAction;





const Chat = (props) => {

  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const {
    isLoading,
    messages,
    isMessageLoaded,
    selectedMessageThread,
    setSelectedMessageThread,
    twilio,
    getAllMessages,
    value
  } = props


  useEffect(() => {
    if (twilio.client !== null && value === 1) {
      getAllMessages(twilio.client)
    }
  }, [twilio.client])

  useEffect(() => {
    if (messages) {
      setSelectedMessageThread(messages[0])
    }
  }, [messages])

  const handleScroll = (e) => {

    if (e.target.scrollTop == 0) { 
      setIsLoadingMore(true);
          if (selectedMessageThread.hasPrevPage ) {
            selectedMessageThread.prevPage().then(messagesPage => {            
              setSelectedMessageThread({
                      ...selectedMessageThread, 
                        hasNextPage   : messagesPage.hasNextPage, 
                        hasPrevPage   : messagesPage.hasPrevPage,
                        messages      : [...selectedMessageThread.messages.reverse(), ...messagesPage.items].reverse(),
                        nextPage      : messagesPage.nextPage,
                        prevPage      : messagesPage.prevPage,
                      });
              setIsLoadingMore(false);
            });
            e.target.scrollTo({
              top      : 1000,
              behavior : 'smooth'});
          }else{
            setIsLoadingMore(false);
          }
    }
 }

  return (
    <Box
      alignItems={((isLoading && messages.length) || isMessageLoaded) ? 'flex-start' : 'center'}
      justifyContent={((isLoading && messages.length) || isMessageLoaded) ? 'flex-start' : 'center'}
      display='flex'
    >
           

      {
        isLoading || !isMessageLoaded || !selectedMessageThread ?
          <Grid
            container
            justify='flex-start'
            alignItems='center'
          // spacing={3}
          >
            {
              Array.from({ length: 5 }, () => 1).map((value, index) => {
                return (
                  <Preloader type={PRELOADER_MESSAGE_CARD} key={index} />
                )
              })
            }
          </Grid>

          : (messages.length > 0)
            ? <ChatLayout {...props} hasChatList handleScroll={handleScroll} selectedMessageThread={selectedMessageThread} isLoadingMore = { isLoadingMore } setSelectedMessageThread={setSelectedMessageThread} client={twilio.client} />
            : <Empty type={EMPTY_CHAT} title={NO_CHAT_HISTORY_TITLE} description={NO_CHAT_HISTORY_DESC} image={NO_CHAT_IMG} />
      }

    </Box>
  )
}
export default Chat