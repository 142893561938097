import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../utils/colors';

const useStyles = makeStyles(theme => ({
  // COMMUNICATE
   
  communicateContainer: {
    backgroundColor: '#ffffff'
  },

  actionGrid: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '80%',
  },

  actionButton: {
    fill: `white`,
    fontSize: 50, 
    zIndex: 1
  },

  secondRowActionGrid: {
    justifyContent : 'center'
  },

  actionCard: {
     display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      height: 130,
      width: 150,
    },
    [theme.breakpoints.up('sm')]: {
      height: 150,
      width: 150,
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative'
  },

  communicateSmallActionButton: {
    borderRadius: 32/2,
    boxShadow: '0px 5px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    height: 32,
    minHeight: 32,
    width: 32,
    minWidth: 32,
    padding: 0,
    zIndex: 2,
    top: 5,
    right: 5,
    position: 'absolute',
    backgroundColor: colors.LIGHT_GRAY,
  },

  communicateSmallActionButtonEnable: {
    backgroundColor: colors.ERROR,
    '&:hover': {
      backgroundColor: '#FF5C5C',
    }
  },

  communicateActionButton: {
    [theme.breakpoints.down('sm')]: {
      height: 80,
      width: 80,
      borderRadius: 80/2
    },
    [theme.breakpoints.up('sm')]: {
      height: 70,
      width: 70,
      borderRadius: 70/2
    },
    border: `0px solid darkgray`,
    backgroundColor: 'transparent',
    marginBottom: theme.spacing(3),
    boxShadow: '0px 5px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  },

  defaultShade: {
    boxShadow: 'none',
    border: 'none',
    borderRadius: 0,
    overflow: 'hidden',
    position: 'absolute',
    top: 0.1,
    left: '-50%',
  },

  leftUnshaded: {
    backgroundColor: 'lightgray',
    left: '-50%',
  },

  rightUnshaded: {
    backgroundColor: 'lightgray',
    left: '50%',
  },

  leftShaded: {
    backgroundImage: `linear-gradient(180deg, ${colors.GRADIENT_YELLOW} 30%, ${colors.GRADIENT_ORANGE} 90%)`,
    left: '-50%',
  },

  rightShaded: {
    backgroundImage: `linear-gradient(180deg, ${colors.GRADIENT_YELLOW} 30%, ${colors.GRADIENT_ORANGE} 90%)`,
    left: '50%',
  },

  // Message Bubble
  message: {
    borderRadius: 5,
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    margin: theme.spacing(0.25),
  },

  received: {
    backgroundColor: colors.LIGHT_GRAY,
    marginRight: 'auto'
  },

  fontReceived: {
    color: colors.TEXT_GRAY
  },

  sent: {
    backgroundColor: colors.PRIMARY,
    marginLeft: 'auto'
  },

  fontSent: {
    color: colors.WHITE
  },

  // Conversation
  conversation: {
    border: `1px solid ${colors.LIGHT_GRAY}`,
    borderRadius: 4,
  },

  title: {
    '& > .MuiTypography-root': {
      borderBottom: `1px solid ${colors.LIGHT_GRAY}`,
      padding: theme.spacing(2),
      paddingLeft : theme.spacing(4),
    }
  },

  messages: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: 200,
    maxHeight: 200,
    overflow: 'scroll',
    marginTop: theme.spacing(1.5),
    padding: theme.spacing(1.5),
  },

  messageFIeld: {
    '& > .MuiFormControl-root': {
      border: 'none',
      margin: 0,  

      '& > .MuiInputBase-root': {
        borderRadius: 0,
      }
    }
  },

  sendIcon: {
    color: colors.PRIMARY
  },
  
  button: {
    marginBottom  : 7,
    marginRight   : 5,
  },

  modal:{
    borderRadius  : 10
  }

}));

export default useStyles;