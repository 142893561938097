import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useHistory } from "react-router-dom";
import { Box, Link, Drawer, List, ListItem, ListItemText, Typography, Container, Divider, Switch } from '@material-ui/core';
import Collapsible from 'react-collapsible';
import ReferAFriend from './refer-a-friend';
import MyPictures from './my-pictures';
import MyAccount from './my-account';
import MyProfile from './my-profile';
import BlockedProfilesContent from './blocked-profiles';
import ReportIssuesContent from './report-issues';
import useStyles from './style';
import Title from '../../components/title';
import TermsAndConditionsContent from './terms-and-conditions';
import PrivacyPolicyContent from './privacy-policy';
import RewardsContent from './rewards';

import lookupAction from '../../redux/lookup/action';
import userAction from '../../redux/user/action';
import rootAction from '../../redux/action';

import { userRequest, billingRequest } from '../../service/requests';
import { USER_SUBSCRIPTION_REGULAR, USER_SUBSCRIPTION_ELITE, USER_SUBSCRIPTION_PRIVACY, BLOCKED_NO_PROFILES, UPD_MODE_REFERRED_BY, TOAST_REFERRAL_SUCCESS, TOAST_REFERRAL_ERROR, ALERT_SEVERITY_SUCCESS, ALERT_SEVERITY_ERROR, HTTP_STATUS, STATUS_LOADING, STATUS_SUCCESS, STATUS_FAIL } from '../../utils/constants';
import { handleHttpError } from '../../utils/helper';

const { getLookUpFields } = lookupAction;
const { setNotifSetting, updateUser, updateSession, updateUserField, updateUserSubscription, updateUserSettings, updateProfilePicture } = userAction;

const { signOutUser } = rootAction;

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const list = [
  {
    title : 'Rewards',
    url   : '/settings/rewards',
    isCollapsible: false
  },
  {
    title : 'Settings',
    url   : '/settings/notification',
    isCollapsible: false
  },
  {
    title : 'My Profile',
    url   : '/settings/my-profile',
    isCollapsible: false
  },
  {
    title : 'My Pictures',
    url   : '/settings/my-pictures',
    isCollapsible: false
  },
  {
    title : 'My Account',
    url   : '/settings/my-account',
    isCollapsible: false
  },
  {
    title : 'Blocked Profiles',
    url   : '/settings/blocked',
    isCollapsible: false
  },
  {
    title : 'Report Issues',
    url   : '/settings/report-issue',
    isCollapsible: false
  },
  {
    title : 'About Us',
    url   : '/settings/about-us',
    isCollapsible: true
  },
  // {
  //   title : 'Sign Out',
  //   url   : 'go home',
  // }
];

const Rewards = (props) => {
  const { userId, updateUserSubscription, updateUserField } = props;

  const [referralCode, setReferralCode] = useState('');
  const [coupons, setCoupons] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isClaiming, setIsClaiming] = useState(false);
  const [isFetchingCoupons, setIsFetchingCoupons] = useState(false);
  const [isFetched, setIsFetched] = useState(false);
  const [isUserReferred, setIsUserReferred] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [creditCount, setCreditCount] = useState(0)
  const [creditCountStatus, setCreditCountStatus] = useState(STATUS_LOADING)


  useEffect(() => {
    userRequest.getDetail({
      user_id: userId
    })
    .then(response => {
      console.log('response', response);
      setIsUserReferred(response?.data?.userDetail?.userField?.referredBy != "0");
    }).catch(error =>{
      handleHttpError({
        error,
        request: 'userRequest.getDetail::src/views/settings'
      })
    })
    getCreditCount()
  }, []);

  const getReferralCode = () => {
    console.log('getReferralCode()');
    setIsLoading(true);
    
    userRequest.getReferralCode({
      user_id : userId
    }).then(response => {
      console.log('response : ', response);
      setReferralCode(response.data.referral.confCode);
    }).catch(error => {
      handleHttpError({
        error,
        request: 'userRequest.getReferralCode::src/views/settings'
      })
    }).finally(() => {
      setIsFetched(prevIsFetched => !prevIsFetched);
      setTimeout(() => {
        setIsLoading(false); 
      }, 500);
    })
  }

  // const getCoupons = () => {
  //   console.log('getCoupons()');
  //   setIsFetchingCoupons(true);

  //   userRequest.getCoupons(userId)
  //     .then(response => {
  //       console.log('response : ', response);
  //       setCoupons(response.data.coupons);
  //     })
  //     .catch(error => {
  //       console.log('error : ', error);
  //     })
  //     .finally(() => {
  //       setIsFetched(prevIsFetched => !prevIsFetched);
  //       setTimeout(() => {
  //         setIsFetchingCoupons(false);
  //       }, 500);
  //     });
  // }

  if (!referralCode && !isLoading && !isFetched && !isFetchingCoupons && !isClaiming) {
    getReferralCode();
    // getCoupons();
  }

  const refreshReferralCode = () => {
    getReferralCode();
    // getCoupons();
    setIsFetched(prevIsFetched => !prevIsFetched);
  }

  const getDetailForSubscription = () => {
    userRequest.getDetail({
      user_id : userId
    })
    .then(response => {
      console.log('response', response);
      updateUserSubscription(response?.data?.userDetail?.userSubscription);
    }).catch(error =>{
      handleHttpError({
        error,
        request: 'userRequest.getDetail::src/views/settings'
      })
    });
  }

  // const claimCoupon = async (couponId) => {
  //   console.log('claimCoupon');
  //   setIsClaiming(true);
  //   const payload = {
  //     userId,
  //     couponId
  //   }
  //     await billingRequest.claimCoupon(payload)
  //     .then(response => {
  //       console.log('response', response);

  //       if(response?.data?.status?.code == 200){ 
  //         getDetailForSubscription();
  //       }
  //     })
  //     .catch(error => {
  //       console.log('error', error);
  //     })
  //     .finally(() => {
  //       setIsFetched(prevIsFetched => !prevIsFetched);
  //       refreshReferralCode();
  //       setIsClaiming(false);
  //     });
  // }

  const submitReferralCode = (code, setToast) => {
    setIsSubmitting(prevState=>!prevState);
    userRequest.isReferralCodeAvailable({
      user_id: userId,
      referral_code: code
    }).then(response => {
      console.log('response', response);
      if(response?.data?.code == HTTP_STATUS._200 && code != referralCode){
        userRequest.updateUser({
          user_id: userId, 
          who_updated: userId,
          upd_mode: UPD_MODE_REFERRED_BY,
          conf_code: code,
        }).then(response => {
          console.log('response', response);
          if (response?.data?.status?.code != HTTP_STATUS._200) {
            let { status } = response?.data;
            throw new Error(`${status?.code} ${status?.description}`);
          }
          updateUserField(response?.data?.userDetail?.userField);
          setIsUserReferred(response?.data?.userDetail?.userField?.referredBy != "0");
          setToast({
            severity: ALERT_SEVERITY_SUCCESS,
            message: TOAST_REFERRAL_SUCCESS
          });
        }).catch(error => {
          handleHttpError({
            error,
            request: 'userRequest.updateUser::src/views/settings'
          })
        })
      }else{
        setToast({
          severity: ALERT_SEVERITY_ERROR,
          message: TOAST_REFERRAL_ERROR
        });
      }
    }).catch(error => {
      console.log('error', error);
      handleHttpError({
        error,
        request: 'userRequest.isReferralCodeAvailable::src/views/settings'
      })
    }).finally(() => {
      setIsSubmitting(prevState => !prevState);
    });
  }

  const getCreditCount = () => {
    setCreditCountStatus(STATUS_LOADING)
    userRequest
      .getCreditCount(userId)
      .then((response) => {
        setCreditCount(response?.data?.referral_credits?.credits)
        setCreditCountStatus(STATUS_SUCCESS)
      })
      .catch((error) => {
        handleHttpError({
          error,
          request: 'userRequest.getCreditCount::src/views/settings'
        })
        setCreditCountStatus(STATUS_FAIL)
      })
  };

  const componentProps = {
    isLoading,
    referralCode,
    isFetched,
    // coupons,
    isClaiming,
    isFetchingCoupons,
    // claimCoupon,
    refreshReferralCode,
    isUserReferred,
    submitReferralCode,
    isSubmitting,
    creditCount,
    creditCountStatus,
    getCreditCount
  }

  return <RewardsContent {...componentProps} />
}


const NotificationSettings = (props) => {
  const classes = useStyles();
  const [isNotifOn, setIsNotifOn ] = useState(false);
  const { settings, setNotifSetting, userSettings, userId } = props;
  const isNotificationEnabled = settings.notification.isEnabled;

  useEffect(() => {
    userRequest.getDetail({
      user_id : userId
    }).then(response => {
      console.log('response : ', response);
      updateUserSettings(response?.data?.userDetail?.userSetting)
      setIsNotifOn(response?.data?.userDetail?.userSetting?.isGeneralAlertOn === 1);
    }).catch(error => {
      handleHttpError({
        error,
        request: "userRequest.getDetail::src/components/topbar"
      })
    });
  }, [userSettings]);

  const handleChange = async (event) => {
    let payload = {
      notificationSetting: 'desktop',
      value: event.target.checked
    }
    if (event.target.checked) {
      if (Notification.requestPermission()) {
        Notification.requestPermission().then(result => {
          if (result === 'granted') {
            setNotifSetting(payload);
          }
          return;
        }).catch(error => {
          throw error;
        });
      } else {
        Notification.requestPermission(result => {                                                                                                                                                             
          if (result === 'granted') {
            setNotifSetting(payload);
          }
          return;
        });
      }
      
    }
    setNotifSetting(payload);
  };

  return (
    <Box className={`${classes.content} ${classes.notificationContainer}`}>
      <Title title="Settings" />
      <Typography>S.O.Search will send you notifications about your account.</Typography>
      <Box>
        <Typography>Turn {isNotifOn ? 'off' : 'on'} notifications</Typography>
        <Switch
          checked={isNotifOn}
          onChange={handleChange}
          name="notifToggle"
          color="primary"
          />
      </Box>  
      <Divider />
    </Box>
  )
}

const BlockedProfiles = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.content}>
      <Title title="Blocked Profiles" />
      <BlockedProfilesContent {...props}/>
    </Box>
  )
}

const ReportIssues = (props) => {
  const classes = useStyles();

  return (
    <Box className={classes.content}>
      <Title title="Report Issues" />
      <ReportIssuesContent {...props}/>
    </Box>
  )
}

const TermsAndConditions = (props) => {
  const classes = useStyles();
  return (
    <Box className={classes.content}>
      <Box className={classes.aboutUsBoxHeader}>
        <Typography className={'text-bold'} variant="h5" color={'primary'}>
          Terms and Conditions
        </Typography>
        </Box>
      <TermsAndConditionsContent/>
    </Box>
  )
}

const PrivacyPolicy = (props) => {
  const classes = useStyles();
  return (
    <Box className={classes.content}>
      <Box className={classes.aboutUsBoxHeader}>
        <Typography className={'text-bold'} variant="h5" color={'primary'}>
          Privacy Policy
        </Typography>
      </Box>
      <PrivacyPolicyContent/>    
    </Box>
  )
}

const Settings = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const query = useQuery().toString();
  const { userSubscription, lookUpSubscriptionPlan, match } = props;
  const tabname = match.params.tabname;
  const [isClicked, setIsClicked] = useState(true);
  const [isAboutUsOpen, setIsAboutUsOpen] = useState(false);
  const initialValue = list.findIndex(item => item.url == `/settings/${tabname}`);
  const [tab, setTab] = useState(initialValue);
  useEffect(() => {
    if (tabname === '' || !list.find(item => item.url == `/settings/${tabname}`)) {
      history.push(list[0].url);
      setTab(0);
    } else if (list.find(item => item.url == `/settings/${tabname}`)) {
      setTab(list.findIndex(item => item.url == `/settings/${tabname}`));
    }
  }, [tabname]);

  const handleListItemChange = (value, index) => { 
    setTab(index);
    index == 7 ? setIsAboutUsOpen(true) : setIsAboutUsOpen(false)
    history.push(value.url);
  }
  const toggleIsClicked = (value, index, isTermsandCondition) => {
    setTab(index);
    history.push(value.url);

    isTermsandCondition ? setIsClicked(true) : setIsClicked(false)
  }

  const subscriptionPlan = lookUpSubscriptionPlan.find(lookUpSubscriptionPlanItem => lookUpSubscriptionPlanItem.subscriptionPlanId === userSubscription.subscriptionPlanId);
  
  let userSubscriptionDesc = '';
  if (subscriptionPlan) {
    switch (subscriptionPlan.membershipLevelCode) {
      case USER_SUBSCRIPTION_REGULAR:
        userSubscriptionDesc = 'Regular'
        break;
      case USER_SUBSCRIPTION_PRIVACY:
        userSubscriptionDesc = 'Privacy'
        break;
      case USER_SUBSCRIPTION_ELITE:
        userSubscriptionDesc = 'Elite'
        break;
    }
  }
  const logOut = () => {
    props.signOutUser()
  }

  const components = [
    <Rewards {...props} key={1}/>, 
    <NotificationSettings {...props} key={2}/>, 
    <MyProfile {...props} key={3}/>, 
    <MyPictures {...props} key={4}/>, 
    <MyAccount {...props} key={5}/>, 
    <BlockedProfiles {...props} key={6}/>,
    <ReportIssues {...props} key={7}/>, 
    isClicked ? <TermsAndConditions {...props} key={8}/> : <PrivacyPolicy {...props} key={8}/>
  ];
  

  return (
    <Container maxWidth="lg" className={classes.container}>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Typography className={classes.menuListHeader}>{`${userSubscriptionDesc} Member`}</Typography>
        <Divider />
        <List disablePadding={true} className={classes.menuList}>
          {
            list.map((value, index, isTermsandCondition) => (
              value.isCollapsible ?
              <ListItem button className={ isAboutUsOpen ? classes.selectedListItem : classes.listItem} onClick={handleListItemChange.bind(this, value, index)}>        
              <Collapsible open={isAboutUsOpen} trigger={<ListItemText primary={value.title}/>}>  
                <Link className={isClicked ? classes.collapsibleItemListSelected : classes.collapsibleItemList} onClick={toggleIsClicked.bind(this, value, index, isTermsandCondition=true)}>
                  Terms & Conditions
                </Link>
                <Divider/>
                <Link className={!isClicked ? classes.collapsibleItemListSelected : classes.collapsibleItemList}  onClick={toggleIsClicked.bind(this, value, index, isTermsandCondition=false)}>
                  Privacy Policy
                </Link>
              </Collapsible>
            </ListItem>
              :
              <ListItem button key={index} className={`${index === tab && classes.selectedListItem} ${classes.listItem}`} onClick={handleListItemChange.bind(this, value, index)}>
                <ListItemText primary={value.title} />
              </ListItem>
            ))
          }
          <ListItem button className={classes.listItem} onClick={logOut}>
            <ListItemText primary="Sign Out" />
          </ListItem>
        </List>
      </Drawer>

      {
        components.filter((value, index) => index === tab)
      }
      
    </Container>
  );
}

/////////////////////////////
// mapStateToProps()
/////////////////////////////
function mapStateToProps(state) {
  console.log('settings/index.js:mapStateToProps(state)');
  console.log(state);
  return {
    userId                 : state.Session.user.user.userId,
    user                   : state.Session.user.user,
    userField              : state.Session.user.userField,
    userName               : state.Session.user.user.userName,
    email                  : state.Session.user.user.email,
    mediaProfile           : state.Session.user.user.mediaProfile,
    settings               : state.Session.user.settings,
    lookUpIssueCateg       : state.Lookup.lookUpIssueCateg,
    isLoading              : state.Lookup.isLoading,
    Lookup                 : state.Lookup, 
    lookUpRelStatus        : state.Lookup.lookUpRelStatus,
    lookUpRelType          : state.Lookup.lookUpRelType, 
    lookUpLanguage         : state.Lookup.lookUpLanguage,
    lookUpBodyType         : state.Lookup.lookUpBodyType,
    lookUpHairColor        : state.Lookup.lookUpHairColor, 
    lookUpEyeColor         : state.Lookup.lookUpEyeColor,
    lookUpKidSituation     : state.Lookup.lookUpKidSituation, 
    lookUpEducLevel        : state.Lookup.lookUpEducLevel, 
    lookUpPrefAlcohol      : state.Lookup.lookUpPrefAlcohol, 
    lookUpPrefSmoking      : state.Lookup.lookUpPrefSmoking,
    lookUpExerRegimen      : state.Lookup.lookUpExerRegimen,
    lookUpEthnicity        : state.Lookup.lookUpEthnicity, 
    lookUpReligBelief      : state.Lookup.lookUpReligBelief,
    lookUpProfession       : state.Lookup.lookUpProfession, 
    lookUpPetPolicy        : state.Lookup.lookUpPetPolicy, 
    lookUpIncomeLevel      : state.Lookup.lookUpIncomeLevel, 
    lookUpPosPolitics      : state.Lookup.lookUpPosPolitics, 
    lookUpHobbyCode        : state.Lookup.lookUpHobbyCode,
    lookUpHeight           : state.Lookup.lookUpHeight,
    userSubscription       : state.User.userSubscription,
    userSettings           : state?.User?.userSettings,
    lookUpSubscriptionPlan : state.Lookup.lookUpSubscriptionPlan,
  };
}

export default connect(mapStateToProps, { getLookUpFields, setNotifSetting, updateUser, updateSession, updateUserField, updateUserSettings, updateUserSubscription, signOutUser, updateProfilePicture })(
  Settings
);