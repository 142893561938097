import React, { useState, useEffect, useRef } from 'react';
import {
  Container,
  Grid,
  Card,
  CardContent,
  Link,
  Modal,
  Box,
  CircularProgress,
  Button,
  Fade,
  Backdrop,
  Typography,
  TextField,
  IconButton,
  Tooltip
} from '@material-ui/core';
import { connect } from 'react-redux';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import ErrorIcon from '@material-ui/icons/Error';
import { userRequest, billingRequest, stripeRequest } from '../../../service/requests';
import { useStyles } from './style'
import logo from '../../../assets/images/icon.png'
import { HTTP_STATUS, UPD_MODE_USER_MEMBERSHIP, USER_SUBSCRIPTION_REGULAR_ID } from '../../../utils/constants';
import userAction from '../../../redux/user/action';
import { useHistory } from 'react-router-dom';
import { handleHttpError } from '../../../utils/helper';

const { updateUserSubscription } = userAction;

const membershipDetails = [
  {
    code: 'REG',
    details: 'Regular'
  },
  {
    code: 'PRV',
    details: 'Privacy'
  },
  {
    code: 'ELT',
    details: 'Elite'
  }
]


const PaymentModal = (props) => {

  const { setPaymentModalDetails, isPaymentModalOpen, paymentModalDetails, user, updateUserSubscription, confirmUrl, setConfirmUrl, status, setStatus, newUser, isFromRegistration } = props
  const classes = useStyles()
  const [cardNumber, setCardNumber] = useState('')
  const [exprDate, setExprDate] = useState('')
  const [cvc, setCvc] = useState('')
  const [membership, setMembership] = useState('Regular')
  const [validitySubtitle, setValiditySubtitle] = useState('every 6 months')
  const [paymentIntentId, setPaymentIntentId] = useState('')
  const [statusDetails, setStatusDetails] = useState('')
  const [requiredActionData, setRequiredActionData] = useState({})

  const [submitDisabled, setSubmitDisabled] = useState(true)
  const [dateError, setDateError] = useState(false)
  const history = useHistory();
  useEffect(() => {
    if (cardNumber.length >= 19 && cvc.length >= 3 && exprDate.length >= 5) {
      setSubmitDisabled(false)
    } else {
      setSubmitDisabled(true)
    }
    const currentDate = new Date()
    const inputDate = new Date(`20${exprDate.substring(3, 5)}`, parseInt(exprDate.substring(0, 2)), 0)
    if (inputDate >= currentDate) {
      setDateError(false)
    } else {
      if (exprDate.length === 5) {
        setDateError(true)
      }

    }
  }, [cardNumber, exprDate, cvc])

  const validatePayment = async () => {
    if(paymentModalDetails.subscriptionPlanId === USER_SUBSCRIPTION_REGULAR_ID) {

      const userId = isFromRegistration ? newUser.userId : user.userId
      const userRequestResponse = await userRequest.updateUser({
        user_id: userId,
        upd_mode: UPD_MODE_USER_MEMBERSHIP,
        who_updated: userId,
        membership_plan_id: paymentModalDetails.subscriptionPlanId
      }).catch(error => handleHttpError({ error, request: 'userRequest.updateUser::src/views/subscription/paymentModal'}))

      if(userRequestResponse.status !== 200){
        setStatus('fail')
        setStatusDetails('Validation fail')
      } else {
        await updateUserSubscription(userRequestResponse?.data?.userDetail?.userSubscription)
        setStatus('done')
        setStatusDetails('Successfully subscribed')
      }
    } else {
      try {
        const paymentDetailsResponse = await stripeRequest.retrievePaymentIntent(paymentIntentId).catch(error => {
          handleHttpError({
            error,
            request: 'stripeRequest.retrievePaymentIntent::src/views/subscription/paymentmodal'
          })
        })
        const { status, data: paymentIntent } = paymentDetailsResponse
        if (status !== 200) {
          setStatus('fail')
        } else {
          if (paymentIntent.status === 'succeeded') {
            const userSubscription = await updateUserRequest()
            const userSubscriptionPayload = {
              ...userSubscription,
              ...requiredActionData
            }

            if (userSubscription) {
              updateUserSubscription(userSubscriptionPayload)
              setStatus('done')
              setStatusDetails('Successfully subscribed')
            }

          } else if (paymentIntent.status === 'requires_payment_method') {
            setStatus('fail')
            setStatusDetails('Validation fail')
          }
        }
      } catch (e) {
        console.log('error: ', e)
      }
    }
  }


  const handleSubmit = async () => {
    setStatus('loading')
    const paymentMethod = await addPaymentMethod()
    if (paymentMethod) {
      const subscription = await subscribeToMembership(paymentMethod)
      const stripeSubscriptionPlanId = subscription.id;
      const stripeCustomerId = subscription.stripeCustomerId;
      if (subscription.subscriptionStatus == 'succeeded') {

        const userSubscription = await updateUserRequest()
        const userSubscriptionPayload = {
          ...userSubscription,
          stripeSubscriptionPlanId,
          stripeCustomerId
        }

        if (userSubscription) {
          updateUserSubscription(userSubscriptionPayload)
          setStatus('done')
          setStatusDetails('Successfully subscribed')
        }

      } else if (subscription.subscriptionStatus == 'requires_action') {
        setRequiredActionData({
          stripeSubscriptionPlanId,
          stripeCustomerId
        })
        const confirmUrl = await getConfirmUrl(subscription)
        setConfirmUrl(confirmUrl)

      } else {
        setStatus('fail')
        setStatusDetails('Insufficient funds')
      }


    } else {
      setStatus('fail')
      setStatusDetails('Invalid card')
    }

  }

  const getConfirmUrl = async (subscription) => {
    console.log('Requires authentication')
    const clientSecret = subscription.clientSecret.split('secret');
    const paymentIntentId = clientSecret[0].substring(0, clientSecret[0].length - 1)
    setPaymentIntentId(paymentIntentId)
    try {
      const paymentIntentResponse = await billingRequest.paymentIntent(paymentIntentId).catch(error =>{
        handleHttpError({
          error,
          request: 'billingRequest.paymentIntent::src/views/subscription/paymentmodal'
        })
      });
      const { data: subscription, status } = paymentIntentResponse.data
      if (status?.code === HTTP_STATUS._422) {
        return false
      }
      const confirmUrl = subscription.next_action.redirect_to_url.url;
      return confirmUrl
    } catch (e) {
      console.log('error: ', e)
      return false
    }

  }

  const updateUserRequest = async () => {

    const userId = isFromRegistration ? newUser.userId : user.userId

    try {
      const userRequestResponse = await userRequest.updateUser({
        user_id: userId,
        upd_mode: UPD_MODE_USER_MEMBERSHIP,
        who_updated: userId,
        membership_plan_id: paymentModalDetails.subscriptionPlanId
      }).catch(error => {
        handleHttpError({
          error,
          request: 'userRequest.updateUser::src/views/subscription/paymentmodal'
        })
      })

      return userRequestResponse?.data?.userDetail?.userSubscription

    } catch (e) {
      console.log('error', e)
      return false
    }


  }

  const addPaymentMethod = async () => {

    const card = cardNumber.replaceAll(" ", "")
    const exprMonth = exprDate.split('/')[0]
    const exprYear = exprDate.split('/')[1]

    const PaymentParams = new URLSearchParams();
    PaymentParams.append('type', 'card')
    PaymentParams.append('card[exp_month]', exprMonth)
    PaymentParams.append('card[exp_year]', exprYear)
    PaymentParams.append('card[number]', card)
    PaymentParams.append('card[cvc]', cvc)


    try {
      const paymentMethod = await stripeRequest.paymentMethod(PaymentParams).catch(error =>{
        handleHttpError({
          error,
          request: 'stripeRequest.paymentMethod::src/views/subscription/paymentmodal'
        })
      });
      return paymentMethod.data.id
    } catch (e) {
      console.log('error: ', e)
      return false
    }

  }

  const subscribeToMembership = async (paymentMethod) => {

    const productId = paymentModalDetails.priceId
    const userId = isFromRegistration ? newUser.userId : user.userId
    const email = isFromRegistration ? newUser.email : user.email

    const billingParams = new URLSearchParams();
    billingParams.append('userId', userId)
    billingParams.append('email', email)
    billingParams.append('productId', productId)
    billingParams.append('paymentMethod', paymentMethod)

    try {

      const billingSubscription = await billingRequest.billingSubscribe(billingParams).catch(error => {
        handleHttpError({
          error,
          request: 'billingRequest.billingSubscribe::src/views/subscription/paymentmodal'
        })
      });
      return billingSubscription.data.subscription

    } catch (e) {
      console.log('error: ', e)
      return false
    }

  }



  useEffect(() => {
    membershipDetails.forEach(membership => {
      if (paymentModalDetails.membershipDetail.membershipLevelCode === membership.code) {
        setMembership(membership.details)
        return
      }
    });
    const validity = paymentModalDetails.membershipDetail.validityInMonths

    if (validity === 12) {
      setValiditySubtitle('every one year')
    }
    else {
      setValiditySubtitle(`every ${validity} months`)
    }
    return () => {
      setStatusDetails('')
    }
  }, [])



  useEffect(() => {
    if (status === 'validating' || paymentModalDetails.subscriptionPlanId === USER_SUBSCRIPTION_REGULAR_ID) {
      validatePayment()
    }
  }, [status])

  return (
    <React.Fragment>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={isPaymentModalOpen && confirmUrl == ''}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isPaymentModalOpen && confirmUrl == ''}>
          <Box className={classes.paymentCard}>
            {
              (status === 'none' || status === 'validating' || status === 'loading') && <Box className={classes.mainContainer}>
                <IconButton aria-label='close' disabled={status !== 'none'} onClick={() => { setPaymentModalDetails({}) }} className={classes.closeButton}>
                  <CloseOutlinedIcon />
                </IconButton>
                <div className={classes.imageContainer}>
                  <img src={logo} />
                </div>
                <Box component={'div'} className={classes.headerInformation}>
                  <Typography className={classes.title}>
                    Subscribe to {membership} Plan
                  </Typography>
                  <div className={classes.subTitle}>
                    <span>
                      ${paymentModalDetails.membershipDetail.rate}
                    </span>
                    {validitySubtitle}
                  </div>
                </Box>
                <Box component={'div'} className={classes.cardInformation}>
                  Card Information
                  <input placeholder='1234 1234 1234 1234'
                    pattern="\d*"
                    type="tel"
                    autoComplete='off'
                    value={cardNumber}
                    onChange={(e) => {
                      if (e.target.value.length < 20) {
                        const value = e.target.value.replaceAll(' ', '').match(/.{1,4}/g)
                        setCardNumber(value ? value.join(' ') : '')
                      }
                    }}
                  />
                  <div className={classes.cardAdditional}>
                    <span>
                      <input placeholder='MM/YY'
                        pattern="\d*"
                        autoComplete='off'
                        value={exprDate}
                        onChange={(e) => {
                          let value = e.target.value.replaceAll('/', '')
                          if (value.length > 2) {
                            value = value.slice(0, 2) + "/" + value.slice(2, 4)
                          }
                          if (e.target.value.length < 6) {
                            setExprDate(value)
                          }
                        }}
                      />
                      {
                        dateError && <Tooltip title="Invalid date">
                          <ErrorIcon className={classes.dateError} />
                        </Tooltip>
                      }
                    </span>
                    <span>
                      <input placeholder='CVC'
                        pattern="\d*"
                        autoComplete='off'
                        value={cvc}
                        onChange={(e) => {
                          if (e.target.value.length < 4) {
                            setCvc(e.target.value)
                          }
                        }}
                      />
                    </span>
                  </div>
                </Box>
                <Button
                  className={classes.submitButton}
                  variant="contained"
                  onClick={() => {
                    handleSubmit()
                  }}
                  color="primary"
                  disabled={status === 'validating' || status === 'loading' || submitDisabled}
                >
                  {status === 'validating' || status === 'loading' ? <CircularProgress size={17} /> : `Submit Payment`}
                </Button>

              </Box>


            }
            {
              status === 'done' && <Box className={classes.mainContainer}>
                <IconButton aria-label='close' onClick={() => { setPaymentModalDetails({}) }} className={classes.closeButton}>
                  <CloseOutlinedIcon />
                </IconButton>
                <div className={classes.imageContainer}>
                  <img src={logo} />
                </div>
                <Box component={'div'} className={classes.headerInformation}>
                  <Typography className={classes.title}>
                    Successfully Subscribed to {membership} Plan
                  </Typography>
                  <div className={classes.subTitle}>
                    <span>
                      ${paymentModalDetails.membershipDetail.rate}
                    </span>
                    {validitySubtitle}
                  </div>
                </Box>
                <Box component={'div'} className={classes.cardInformation}>
                  <svg className={classes.checkmark} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                    <circle className={classes.checkmark__circle} cx="26" cy="26" r="25" fill="none" />
                    <path className={classes.checkmark__check} fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                  </svg>
                  {/* <svg class={classes.checkmark} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                          <circle class={classes.checkmark__circle} cx="26" cy="26" r="25" fill="none" />
                          <path class={classes.checkmark__check} fill="none" d="M14.1 14.1l23.8 23.8 m0,-23.8 l-23.8,23.8" />
                        </svg> */}
                </Box>
                <Button
                  className={classes.submitButton}
                  variant="contained"
                  onClick={() => {
                    setPaymentModalDetails({})
                    {
                      !isFromRegistration && 
                        history.push({
                          pathname: '/home',
                          search: '?success=true&subscribedToMembership=true'
                        })
                    }
                  }}
                >
                  { isFromRegistration ? 'Continue' : 'Go to home'}
                </Button>

              </Box>
            }
            {
              status === 'fail' && <Box className={classes.mainContainer}>
                <IconButton aria-label='close' onClick={() => { setPaymentModalDetails({}) }} className={classes.closeButton}>
                  <CloseOutlinedIcon />
                </IconButton>
                <div className={classes.imageContainer}>
                  <img src={logo} />
                </div>
                <Box component={'div'} className={classes.headerInformation}>
                  <Typography className={classes.title}>
                    Subscription to {membership} Plan Failed
                  </Typography>
                  <div className={classes.subTitle}>
                    <span>
                      ${paymentModalDetails.membershipDetail.rate}
                    </span>
                    {validitySubtitle}
                  </div>
                </Box>
                <Box component={'div'} className={classes.cardInformation}>
                  {/* <svg className={classes.checkmark} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                    <circle className={classes.checkmark__circle} cx="26" cy="26" r="25" fill="none" />
                    <path className={classes.checkmark__check} fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                  </svg> */}
                  <svg className={classes.checkmark} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                    <circle className={classes.checkmark__circle} cx="26" cy="26" r="25" fill="none" />
                    <path className={classes.checkmark__check} fill="none" d="M14.1 14.1l23.8 23.8 m0,-23.8 l-23.8,23.8" />
                  </svg>
                  <span className={classes.subInformation}>{statusDetails}</span>
                </Box>

                <Button
                  className={classes.submitButton}
                  variant="contained"
                  onClick={() => {
                    setPaymentModalDetails({})
                  }}
                >
                  Close
                </Button>
              </Box>
            }
          </Box>
        </Fade>
      </Modal>
    </React.Fragment>
  )


}

/////////////////////////////
// mapStateToProps()
/////////////////////////////
function mapStateToProps(state) {
  return {
    user: state.Session.user.user
  };
}

export default connect(mapStateToProps, { updateUserSubscription })(
  PaymentModal
);