import { makeStyles } from '@material-ui/core/styles';
import colors from '../../utils/colors';

const useStyles = makeStyles(theme => ({
  
  
  container: {
    height: '100%',
    display: 'flex',

  },

  grid: {
    display: 'flex',
    justifyContent: 'center',
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },

  image: {
    height: 96,
    marginBottom: theme.spacing(1),
    width: 96,
  },

  remoteAudio: {
    position: 'absolute',
    left: 0
    // display: 'flex',
    // height: '100%',
    // justifyContent: 'center',

    // '& > video': {
    //   height: '100%',
    //   width: 'auto',
    // },
  },

  controls: {
    bottom: 0,
    marginBottom: theme.spacing(5),
    position: 'absolute',

    '& > .MuiBox-root': {

      '& > .MuiIconButton-root': { 
        margin: theme.spacing(2),
      }
    },
  },

  iconButton: {
    backgroundColor: colors.PRIMARY,
  },

  iconButtonOutlined: {
    backgroundColor: colors.DARK_GRAY,
    border: `1px solid ${colors.DARK_GRAY}`
  },

  iconButtonContained: {
    backgroundColor: colors.PRIMARY,
    border: `1px solid ${colors.PRIMARY}`
  },

  iconButtonGreen: {
    backgroundColor: colors.GREEN,
    border: `1px solid ${colors.GREEN}`
  },

  iconButtonRed: {
    backgroundColor: colors.ERROR,
    border: `1px solid ${colors.ERROR}`
  },

  iconButtonGray: {
    backgroundColor: colors.TEXT_GRAY,
    border: `1px solid ${colors.TEXT_GRAY}`
  }


}));

export default useStyles;