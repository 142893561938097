import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../utils/colors';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({

  content: {
    flex: 1,
    paddingTop: theme.spacing(5),
    padding: theme.spacing(4),
    '& > .MuiTypography-root:first-child': {
      color: colors.PRIMARY
    },

    '& > .MuiBox-root:first-of-type': {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      '& a': {
        cursor: 'pointer'
      }
    }
  },

  imgsContainer: {
    padding: 20
  },

  tile: {
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    height: 150,
    width: 150,
    cursor: 'pointer',
    overflow: 'hidden',
    position: 'relative',

    '&:hover': {
      '& > a': {
        visibility: 'visible'
      },

      '& > svg': {
        visibility: 'visible'
      }
    }
  },

  cardMedia: {
    height: 150,
    width: 150,
    ['-webkit-transition']: '-webkit-transform 500ms ease',
    transition: '-webkit-transform 500ms ease',
    transition: 'transform 500ms ease',
    transition: 'transform 500ms ease, -webkit-transform 500ms ease',

    '&:hover': {
      '& > svg': {
        visibility: 'visible'
      },
      '& > .overlay': {
        width: '100%',
        height: '100%',
        position: 'absolute',
        backgroundColor: colors.BLACK,
        opacity: 0.5,
      }
    }
  },

  link: {
    bottom: 0.5,
    color: colors.WHITE,
    fontWeight: 'bold',
    fontSize: 12,
    textAlign: 'center',
    position: 'absolute',
    width: '100%',
    background: colors.PRIMARY,
    padding: theme.spacing(0.5, 0),
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    visibility: 'hidden',
  },

  deleteIcon: {
    borderRadius: 2,
    color: colors.WHITE,
    fontSize: 20,
    position: 'absolute',
    right: 3,
    top: 3,
    visibility: 'hidden',
  },

  addPhotoLabel: {
    color: colors.PRIMARY,
    cursor: 'pointer',
    fontSize: 14,
    fontWeight: 'bold'
  },  

  addPhoto: {
    alignItems: 'center',
    border: `2px dashed ${colors.TEXT_GRAY}`,
    display: 'flex',
    justifyContent: 'center',
    height: 146,
    width: 146,

    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colors.LIGHT_GRAY
    },
  },

  transparent: {
    color: colors.TRANSPARENT,
  },

  imageBox: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-start',
    flexFlow: 'wrap'
  },

  inputFile: {
    width: 0.1,
    height: 0.1,
    opacity: 0,
    overflow: 'hidden',
    position: 'absolute',
    zIndex: -1
  },

  // MODAL
  modal: {
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },

  viewImageBox: {
    alignItems: 'center',
    display: 'flex',
    height: '50%',
    justifyContent: 'center',
    position: 'relative',
    width: '50%',

    '& > .MuiLink-root': {
      bottom: 0,
      width: '100%',
      color: colors.WHITE,
      background: 'rgba(0, 0, 0, 0.5)',
      padding: theme.spacing(1 , 0),
      fontWeight: 'bold',
      cursor: 'pointer',
      fontSize: 12,
      textAlign: 'center',
      position: 'absolute',
    }
  },

  closeIcon: {
    color: colors.GRAY,
    position: 'absolute',
    right: 10,
    top: 10, 
    cursor : 'pointer',

    '&:hover': {
      color: colors.WHITE,
    }
  },

  uploadImageBox: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    height: 'auto',
    width: 250,

    [theme.breakpoints.up('md')]: {
      height: 'auto',
      width: 400,
    },
  },

  image: {
    backgroundSize: 'contain',
    height: '100%',
    width: '100%',
  },

  controls: {
    display: 'flex',
    flexDirection: 'row',

    '& .Mui-disabled': {
      borderColor: colors.TEXT_GRAY,
      color: colors.TEXT_GRAY,
    },
  },

  button: {
    borderColor: colors.WHITE,
    color: colors.WHITE,
    margin: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)'
    },
  },
  buttonUpload: {
    backgroundColor: colors.GRADIENT_ORANGE,
    color: colors.WHITE,
    margin: theme.spacing(1),
    '&:hover': {
      backgroundColor: colors.PRIMARY,
      color: colors.WHITE,
      borderColor: colors.WHITE,
    },
  },

}));

export default useStyles;