import { makeStyles } from '@material-ui/core/styles';
import colors from '../../utils/colors';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    marginTop: 120,
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    textAlign: 'center',
    [theme.breakpoints.down("sm")]: {
      marginTop: '165px'
    },
  },
  
  paper: {
    backgroundColor: colors.WHITE, 
    marginTop: theme.spacing(4),
  },

  markAllReadContainer: {
    alignItems: 'flex-end',
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(2, 0, 2, 0),
  },
  
  // ChatLayout
  chatLayoutContainer: {
    backgroundColor: colors.WHITE,
    height: 600
  },

  chatBox: {
    overflow : 'scroll'
  },

  chatsLabel: {
    fontWeight: 'bold', 
    margin: 10
  },

  chatItemUsername: {
    fontWeight: 'bold',
    color: colors.PRIMARY_TEXT
  },

  unseenChatItemRow: {
    color: colors.PRIMARY,
    fontWeight: 'bold'
  },

  fullHeight: {
    height: '100%'
  },

  verticallyCenter: {
    display: 'flex',
    justifyContent: 'center'
  },

  verticallyRight: {
    display: 'flex',
    justifyContent: 'flex-end'
  },

  scrollContainer: {
    overflow: 'scroll'
  },

  chatThread: {
    height: '100%', 
    overflow: 'scroll',
    paddingLeft: 10, 
    paddingRight: 10,
    padding: theme.spacing(0, 1),
  },

  messageInputContainer: {
    padding: theme.spacing(1),
  },

  messageInput: {
    alignSelf: 'center',
    backgroundColor: '#F1F3F4',
    borderRadius: 100,
    display: 'flex',
    maxHeight: 40,
    paddingBottom: 3,
    paddingLeft: 20,
    paddingTop: 3,
  },

  loadingBar:{
    width:"100%",
   
  },

  hidden:{
    display:"none"
  }

}));

export default useStyles;