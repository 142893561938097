import axios from 'axios';
import { axiosInstance, stripeInstance } from './axios'
import api from './api';
import {
  CONNECTION_ACTION_ADD,
  CONNECTION_ACTION_BLOCK,
  CONNECTION_ACTION_REMOVE,
  CONNECTION_ACTION_UNBLOCK,
  CONNECTION_TYPE_BLOCK,
  CONNECTION_TYPE_HEART,
  CONNECTION_TYPE_NOTE,
  CONNECTION_TYPE_RATING,
  CONNECTION_TYPE_SHORTLIST,
} from '../utils/constants';

let cancelToken;
let source;

export const multipleRequest = (request) => axios.all(request);

export const geoCodeRequest = {

  getGeoCodeAddress: (params) => axios.get(
    api.GEOCODE_ADDRESS,
    {
      params
    }
  ),
}
export const calendarRequest = {

  addCalendarEvent: (data) => axiosInstance.post(
    api.CALENDAR_ADD_EVENT,
    data
  ),

  getCalendarEvents: (params, userId) => axiosInstance.get(
    api.CALENDAR_GET_ALL_EVENTS + '/' + userId,
    {
      params
    }
  ),

  updateCalendarEvent: (data) => axiosInstance.post(
    api.CALENDAR_UPDATE_EVENT,
    data
  ),

  updateMeetup: (data) => axiosInstance.post(
    api.CALENDAR_UPDATE_MEETUP,
    data
  )
}

export const callRequest = {

  getCall: (params, callType) => axiosInstance.get(
    api.CALL_GET_LOGS + '/' + callType,
    {
      params
    }
  ),

  startCall: (data, callType) => axiosInstance.post(
    api.CALL_START + '/' + callType,
    data
  ),

  respondToCall: (data, action, callThreadId) => axiosInstance.post(
    api.CALL_RESPOND + '/' + action + '/' + callThreadId,
    data
  )
}

export const connectionRequest = {

  addToShortlist: (data, connectionUserId) => axiosInstance.post(
    api.CONNECTION_SET_CONNECTION + CONNECTION_TYPE_SHORTLIST + '/' + CONNECTION_ACTION_ADD + '/' + connectionUserId,
    data
  ),

  addNote: (data, connectionUserId) => axiosInstance.post(
    api.CONNECTION_SET_CONNECTION + CONNECTION_TYPE_NOTE + '/' + CONNECTION_ACTION_ADD + '/' + connectionUserId,
    data
  ),

  blockUser: (data, connectionUserId) => axiosInstance.post(
    api.CONNECTION_SET_CONNECTION + CONNECTION_TYPE_BLOCK + '/' + CONNECTION_ACTION_BLOCK + '/' + connectionUserId,
    data
  ),

  connectUser: (data, path) => axiosInstance.post(
    api.CONNECTION_SET_CONNECTION + path,
    data
  ),

  getBlockedUser: (params) => axiosInstance.get(
    api.CONNECTION_GET_BLOCKED,
    {
      params
    }
  ),

  getConnection: (params, connectionUserId) => axiosInstance.get(
    api.CONNECTION_GET_CONNECTION + connectionUserId,
    {
      params
    }
  ),

  getHistory: (params, userId) => axiosInstance.get(
    api.HISTORY_GET_ITEMS + '/' + userId,
    {
      params
    }
  ),

  getShortlist: (params) => axiosInstance.get(
    api.CONNECTION_GET_SHORTLIST,
    {
      params
    }
  ),

  getUsersNearMe: (params) => axiosInstance.get(
    api.CONNECTION_GET_NEAR_ME,
    {
      params
    }
  ),

  getWhoIViewed: (params) => axiosInstance.get(
    api.CONNECTION_GET_WHO_I_VIEW,
    {
      params
    }
  ),

  getWhoLikedMe: (params) => axiosInstance.get(
    api.CONNECTION_GET_WHO_LIKE_ME,
    {
      params
    }
  ),

  getWhoViewedMe: (params) => axiosInstance.get(
    api.CONNECTION_GET_WHO_VIEW_ME,
    {
      params
    }
  ),

  likeUser: (data, path) => axiosInstance.post(
    api.CONNECTION_SET_CONNECTION + CONNECTION_TYPE_HEART + '/' + path,
    data
  ),

  markAsSeen: (data) => axiosInstance.post(
    api.CONNECTION_MARK_AS_SEEN,
    data
  ),

  rateUser: (data, connectionUserId) => axiosInstance.post(
    api.CONNECTION_SET_CONNECTION + CONNECTION_TYPE_RATING + '/' + CONNECTION_ACTION_ADD + '/' + connectionUserId,
    data
  ),

  removeShortlist: (data, connectionUserId) => axiosInstance.post(
    api.CONNECTION_SET_CONNECTION + CONNECTION_TYPE_SHORTLIST + '/' + CONNECTION_ACTION_REMOVE + '/' + connectionUserId,
    data
  ),

  unblockUser: (data, connectionUserId) => axiosInstance.post(
    api.CONNECTION_SET_CONNECTION + CONNECTION_TYPE_BLOCK + '/' + CONNECTION_ACTION_UNBLOCK + '/' + connectionUserId,
    data
  ),

  viewUser: (data, connectionUserId) => axiosInstance.post(
    api.CONNECTION_ADD_WHO_VIEW_ME + connectionUserId,
    data
  ),

  usersProfile: (data) => axiosInstance.post(
    api.CONNECTION_USERS_PROFILE,
    data
  )

}

export const lookupRequest = {

  getProfileLookup: (lookup) => axiosInstance.get(
    api.PROFILE_LU + lookup
  ),

  getSearchLookup: (lookup) => axiosInstance.get(
    api.USER_SEARCH_LU + lookup
  ),

  getCredentialLookup: (lookup) => axiosInstance.get(
    api.CREDENTIAL_LU + lookup
  ),

  getCountries: () => axiosInstance.get(
    api.COUNTRIES_LU
  ),
  getCountries: (lookup) => axiosInstance.get(
    api.COUNTRIES_CODE_LU + lookup
  ),
  getStates: (lookup) => axiosInstance.get(
    api.STATES_LU + lookup
  ),
  getCities: (lookup) => axiosInstance.get(
    api.CITIES_LU + lookup
  ),

}

export const notificationRequest = {

  bindUserNotification: (data) => axiosInstance.post(
    api.NOTIFICATION_BIND,
    data
  ),

  get: (userId, page) => axiosInstance.get(
    api.NOTIFICATION_GET + userId + '?page=' + page),

  seenNotification: (data) => axiosInstance.post(
    api.NOTIFICATION_SEEN,
    data
  ),

  sendNotification: (data) => axiosInstance.post(
    api.NOTIFICATION_SEND,
    data
  ),

  markAllNotificationRead: (data) => axiosInstance.post(
    api.NOTIFICATION_MARK_ALL_READ,
    data
  )

}

export const nearMeRequest = {

  addNearMe: (data) => axiosInstance.post(
    api.NEAR_ME_ADD,
    data
  ),

  getNearMeHistory: (params) => axiosInstance.get(
    api.NEAR_ME_GET_HISTORY,
    {
      params
    }
  ),

  checkUsersNearMe: (params) => axiosInstance.get(
    api.NEAR_ME_CHECK_USER,
    {
      params
    }
  ),

  updateNearMe: (data) => axiosInstance.put(
    api.NEAR_ME_UPDATE,
    data
  ),

}

export const searchRequest = {
  getSearch: (params) => axiosInstance.get(
    api.SEARCH_GET,
    {
      params
    }
  ),

  addSearch: (data) => axiosInstance.post(
    api.SEARCH_ADD,
    data
  ),

  searchByUsername: (params) => axiosInstance.get(
    api.SEARCH_USER_BY_USERNAME,
    {
      params
    }
  ),

  updateSearch: (data, searchId) => axiosInstance.put(
    api.SEARCH_UPDATE + searchId,
    data
  ),

  updateSearchCriteria: (data, searchId, criteriaType) => axiosInstance.post(
    api.SEARCH_UPDATE + searchId + '/' + criteriaType,
    data
  ),

  deleteSearch: (data, searchId) => axiosInstance.delete(
    api.SEARCH_DELETE + searchId,
    {
      data
    }
  ),

}

export const twilioRequest = {
  addConversationParticipants: (data) => axiosInstance.post(
    api.USER_ADD_CHAT_PARTICIPANT,
    data
  )

  // getPhoneCallsTo: (lookup) => axiosInstance.get(
  //   TWILIO_BASE_URL+ api.+ lookup
  // ),

  // getPhoneCallsFrom: (lookup) => axiosInstance.get(
  //   TWILIO_BASE_URL+ api.+ lookup
  // ), 
}

export const userRequest = {

  claimCoupon: (userId, couponId) => axiosInstance.post(
    api.USER_CLAIM_COUPON(userId, couponId)
  ),

  // getCoupons: (userId) => axiosInstance.get(
  //   api.USER_GET_COUPONS(userId)
  // ),

  isReferralCodeAvailable: (data) => axiosInstance.post(
    api.USER_REFERRAL_AVAILABILITY,
    data
  ),

  addUser: (data) => axiosInstance.post(
    api.USER_ADD,
    data
  ),

  cancelRequest: () => {
    source.cancel();
  },

  changePassword: (data) => axiosInstance.post(
    api.USER_CHANGE_PASS,
    data
  ),

  checkEmail: (data) => axiosInstance.post(
    api.USER_CHECK_EMAIL,
    data
  ),

  checkUsername: (data) => axiosInstance.post(
    api.USER_CHECK_USERNAME,
    data
  ),

  deleteUserMedia: (data) => axiosInstance.delete(
    api.USER_DELETE_MEDIA,
    {
      data
    }
  ),

  get: (params) => axiosInstance.get(
    api.USER_GET,
    {
      params
    }
  ),

  getDetail: (params) => axiosInstance.get(
    api.USER_GET_DETAIL,
    {
      params
    }
  ),

  getMediaGallery: (params) => axiosInstance.get(
    api.USER_GET_GALLERY,
    {
      params
    }
  ),

  getSubscriptionPlan: (params) => axiosInstance.get(
    api.SUBSCRIPTION_PLAN_LU,
    {
      params
    }
  ),

  getPresignedUrl: (data) => axiosInstance.post(
    api.USER_GET_PRESIGNED_URL,
    data
  ),

  getReferralCode: (params) => axiosInstance.get(
    api.USER_GET_REFERRAL_CODE,
    {
      params
    }
  ),

  getTwilioChatToken: (params) => axiosInstance.get(
    api.USER_GET_TWILIO_CHAT_TOKEN,
    {
      params
    }
  ),

  getTwilioVideoToken: (params) => axiosInstance.get(
    api.USER_GET_TWILIO_VIDEO_TOKEN,
    {
      params,
    }
  ),

  getTwilioVoiceToken: (params) => axiosInstance.get(
    api.USER_GET_TWILIO_VOICE_TOKEN,
    {
      params,
    }
  ),

  reportIssue: (data) => axiosInstance.post(
    api.USER_REPORT_ISSUE,
    data
  ),

  resendCode: (data) => axiosInstance.post(
    api.USER_RESEND_CODE,
    data
  ),

  resetPassword: (data) => axiosInstance.post(
    api.USER_PASS_RESET,
    data
  ),

  setUserField: (data) => axiosInstance.post(
    api.USER_UPDATE,
    data
  ),

  signIn: (data) => axiosInstance.post(
    api.USER_SIGNIN,
    data
  ),

  updateUser: (data) => axiosInstance.post(
    api.USER_UPDATE,
    data
  ),

  updateSubscription: (data, userId) => axiosInstance.patch(
    api.USER_UPDATE + userId + api.USER_SUBSCRIPTION,
    data
  ),

  uploadPhoto: (data) => axiosInstance.post(
    api.USER_ADD_PHOTO,
    data,
  ),

  verifyCode: (data) => axiosInstance.post(
    api.USER_VERIFY_CODE,
    data
  ),

  verifyPhone: (data) => axiosInstance.post(
    api.USER_VERIFY_PHONE,
    data
  ),

  verifyResetPasswordEmail: (data) => axiosInstance.post(
    api.USER_VERIFY_EMAIL,
    data
  ),

  verifyReferredByCode: (data) => axiosInstance.post(
    api.USER_CHECK_REFERRAL_CODE,
    data
  ),
  sentOTP: (data) => axiosInstance.post(
    api.USER_SENT_OTP,
    data
  ),

  getCreditCount: (userId) => axiosInstance.get(api.REFERRAL_COUNTER(userId)),
  
  claimFreeTrial: (userId) => axiosInstance.post(api.USER_FREE_TRIAL_CLAIM(userId)),

  checkFreeTrial: (userId) => axiosInstance.get(api.USER_FREE_TRIAL_CHECK(userId)),



}

export const stripeRequest = {

  paymentMethod: (data) => stripeInstance.post(
    api.PAYMENT_METHOD,
    data
  ),

  paymentIntent: (data) => stripeInstance.post(
    api.PAYMENT_INTENT + data + api.CONFIRM
  ),

  retrievePaymentIntent: (data) => stripeInstance.get(
    api.PAYMENT_INTENT + data 
  )

}

export const billingRequest = {

  getPaymentLink: (data) => axiosInstance.post(
    api.BILLING_GET_LINK,
    data,
    {
      headers: {
        ['user-id']: data.userId
      }
    }
  ),

  billingSubscribe: (data) => axiosInstance.post(
    api.BILLING_SUBSCRIBE,
    data,
    {
      header: {
        ['user-id']: data.userId
      }
    }
  ),

  billingUnsubscribe: (data) => axiosInstance.delete(
    `${api.BILLING_UNSUBSCRIBE}/${data.id}`,
    {
      header: {
        ['user-id']: data.userId
      }
    }
  ),
  // billingUnsubscribeWipe: (data) => axiosInstance.delete(
  //   `${api.BILLING_UNSUBSCRIBE_WIPE}/${data}`,
  //   {
  //     header: {
  //       ['user-id']: data
  //     }
  //   }
  // ),

  // claimCoupon: (data) => axiosInstance.post(
  //   api.BILLING_CLAIM_COUPON,
  //   data,
  //   {
  //     header: {
  //       ['user-id']: data.userId
  //     }
  //   }
  // ),

  paymentIntent: (data) => axiosInstance.get(
    `${api.BILLING_PAYMENT_INTENT}${data}` ,
    data,
    {
      header: {
        ['user-id']: data.userId
      }
    }
  )

}

export const thirdPartyRequest = {
  getCityState: (params) => axios.get(
    api.ZIPCODE,
    {
      params: {
        ...params,
        API: 'CityStateLookup',
      },
      headers: {
        'Content-Type': 'text/xml',
      },
    }
  ),
}
/* * * * * * * * * * * * * * * * 
 *  Sample Object 
 * * * * * * * * * * * * * * * */
/*
export const connection = {
  * * * * * * * * * * * * * * * * 
  *  Sample POST API
  * * * * * * * * * * * * * * * * 
  get: (params) => axiosInstance.get(
    api.USER_GET, 
    {
      params
    }),

  * * * * * * * * * * * * * * * * 
  *  Sample POST API
  * * * * * * * * * * * * * * * * 
  post: (data) => axiosInstance.post(
    api.USER_GET, 
    data
  ),
}
*/