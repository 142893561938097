import { makeStyles } from '@material-ui/core/styles';
import colors from '../../utils/colors';

const useStyles = makeStyles(theme => ({
	card: {
		border: 'none',
		boxShadow: 'none',
		borderRadius: '10px',
		backgroundColor: '#E0DCDC'
	},

	cardItem: {
		display: 'flex',
		border: 'none',
		boxShadow: 'none',
		borderRadius: '10px',
		margin: '1em'
	},

	cardHeader: {
		color: colors.PRIMARY,
		marginLeft: '1em'
	},

	dateWrapper: {
		background: 'linear-gradient(#FDC830, #F37335)',
		display: 'inline-flex',
		wordSpacing: '100vw',
		width: 'min-content',
		minWidth: '106px',
		textAlign: 'center',
		lineHeight: 1.5,
		padding: '0 10px',
		color: colors.WHITE,
		fontWeight: 'bold',
		borderRadius: '10px'
	},

	cardDetails: {
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'center',
		marginLeft: '1em',
		marginRight: '1em',
		minWidth: 0,
		'& p': {
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap'
		},
		'& a': {
			color: colors.PRIMARY,
			textDecoration: 'none',
			cursor: 'pointer'
		}
	},

	cardContent: {
		position: 'relative',
		[theme.breakpoints.down('xs')]: {
			padding: 0
		}
	},

	link: {
		textAlign: 'end',
		fontSize: '12px',
		position: 'absolute',
		bottom: 0,
		right: 0,
		marginRight: '1em',
		'& a': {
				borderBottom: 'solid 1px',
				cursor: 'pointer',
				color: colors.PRIMARY,
				fontSize: 12,
		}
	},

	emptyEventLabel: {
		marginLeft: '15px'
	}
}));

export default useStyles;
