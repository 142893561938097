import React from 'react';
import { Card, CardMedia } from '@material-ui/core';

import Icon              from '@mdi/react';
import { mdiSunglasses } from '@mdi/js';
import DiamondIcon       from '../../assets/images/diamond.png';
import Empty from '../empty';
import useStyles from './style';

import NO_CHATS_IMAGE from "../../assets/images/no_chats.png";
import { MSG_NO_UNANSWERED_MESSAGES } from '../../utils/constants';

const DashboardUnansweredMessages = (props) => {
	const classes = useStyles();
	const { messages, replyCallback } = props;

	// only show unanswered messages
	const unansweredMessages = messages.filter((messageItem) => {
		if (!messageItem.messages.length) return;
		if (messageItem.isUnseen) return messageItem;
	});

	const callbackFunction = (message) => {
		replyCallback && replyCallback(message.userConnection)
	}

	if (!unansweredMessages.length) return <Empty image={NO_CHATS_IMAGE} description={MSG_NO_UNANSWERED_MESSAGES} /> 

	
	return unansweredMessages.map(message => {
		const latestMessage = message.messages[message.messages.length - 1];
		const userName =  message.userConnection.user.userName;

		return <Card className={classes.item} variant="outlined" key={message.userId}>
			<div className={classes.cardWrapper}>
				<CardMedia
					className={classes.profileImg}
					image={message.userConnection.user.mediaProfile}
					title={userName}
				/>
				<div className={classes.textWrapper}>
					<p className={classes.username}>{`${userName}`}	
					{( message.userConnection?.userSubscription?.viewabilityStatus == 'PRV' ) ? <Icon path={mdiSunglasses} size={1} color="black" style={{marginLeft : 2}} /> 
        	:( message.userConnection?.userSubscription?.viewabilityStatus == 'ELT' ) ? <img src={DiamondIcon} className={`${classes.planIcon} ${classes.quickFade}`} /> :''}
 					{`, ${message.userConnection.user.age}`}</p>
					<p className={classes.message}>{latestMessage.state.body}</p>
				</div>
				<div className={classes.buttonWrapper}>
					<button className={classes.replyButton} onClick={callbackFunction.bind(this, message)}>Reply</button>
				</div>
			</div>
		</Card>
	})
}

export default DashboardUnansweredMessages;
