import { makeStyles } from '@material-ui/core/styles';
import colors from '../../utils/colors';

const useStyles = makeStyles(theme => ({


  formControl: {
    marginBottom: theme.spacing(2),
    // minWidth: 120,
  },
  
  selectEmpty: {
    marginTop: theme.spacing(2),
  },

  item: {
    display: 'flex'
  }

}));

export default useStyles;