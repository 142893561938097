import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../utils/colors';

const useStyles = makeStyles(theme => ({

  content: {
    '& .bold': {
      fontWeight: 600
    },

    '& .sectionTitle': {
      color: colors.ABOUT_US_SECTION_TITLE,
    },

    '& .MuiBox-root' : {
      marginLeft: theme.spacing(3)
    },

    '& > ul': {
      marginLeft: theme.spacing(5)
    },

    '& > ul > li': {
      listStyleType: 'disc'
    }
  }
}));

export default useStyles;