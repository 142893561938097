import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../utils/colors';

const useStyles = makeStyles(theme => ({
  rootContainer: {
    backgroundColor: '#ffffff',
    borderRadius: 10,
    height: 285,
  },
  noteGrid: {
    position: 'relative',
    '& > .MuiGrid-root:first-of-type': {
      textAlign: 'end',
      color: colors.PRIMARY
    },
 
  },

  notesField: {
    width: '100%',
    backgroundColor: '#ffffff',
 
   },

  editNoteButton: {
    backgroundColor: colors.WHITE,
    borderRadius: 32/2,
    boxShadow: '0px 5px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
    height: 32,
    minHeight: 32,
    minWidth: 32,
    padding: 0,
    position: 'absolute',
    right: 7,
    top : 7,
    width: 32,
    zIndex: 2,
  },
}));

export default useStyles;