import React, { useState } from 'react';
import { Avatar, Box, Button, Checkbox, Container, FormControlLabel, Grid, Hidden, Link, TextField, Typography } from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import registration from '../../assets/images/registration.png'
import logo from '../../assets/images/logo.png'
import SOS_WHITE from '../../assets/images/logo-text-white.png';
import useStyles from './style';

const LeftLayout = () => {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.overlay}></Box>
      <img src={registration} className={classes.image} alt="registration"/>
      <img src={SOS_WHITE} className={classes.sos} alt="logo"/>
    </Box>
  );
}

export default LeftLayout;