import React, { useState, useRef, useEffect } from 'react';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

import { useStyles, selectStyle } from './style';
import colors from '../../utils/colors'
import { UNITED_STATES } from '../../utils/constants';
const animatedComponents = makeAnimated();



const Selection = (props) => {
    const { options = [], className, defaultValue, placeholder='', inputProps= {}, isLoading } = props
    
    const onMenuOpen = () => {
        setTimeout(() => {
            const selectedEl = document.getElementsByClassName("MyDropdown__option--is-selected")[0];
            if (selectedEl) {
                selectedEl.scrollIntoView({ block: 'nearest', inline: 'start' });
            }
        }, 15);
    };

    return <Select
        {...inputProps}
        onMenuOpen={onMenuOpen}
        className={className}
        classNamePrefix="MyDropdown"
        menuPortalTarget={document.body}
        menuPosition="fixed"
        isDisabled={false}
        isLoading={isLoading}
        isClearable={false}
        isRtl={false}
        isSearchable={true}
        name="color"
        options={options}
 
        styles={{
            menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
            menu: (provided) => ({ ...provided, zIndex: 9999 }),
            option: (base, state) => {

                if (state.isSelected) {
                    return {
                        ...base,
                        zindex: 999,
                        color: colors.WHITE,
                        backgroundColor: colors.PRIMARY
                    }
                }
                return {
                    ...base,
                    color: colors.GRAY,
                    backgroundColor: colors.WHITE,
                    zindex: 999,
                    "&:hover": {
                        backgroundColor: colors.LIGHT_GRAY,
                        color: colors.PRIMARY_TEXT
                    },
                }
            },
            valueContainer: (e) => ({
                ...e,
                color: colors.YELLOW
            }),

            dropdownIndicator: (e) => ({
                ...e,
                color: colors.PRIMARY_TEXT
            }),
            control: (base, state) => ({
                ...base,
                border: state.isFocused ? `1px solid ${colors.PRIMARY}` : `1px solid ${colors.LIGHT_GRAY}`,
                borderRadius: '5px',
                paddingBottom: '3px',
                color: colors.YELLOW,
                backgroundColor: colors.TRANSPARENT,
                boxShadow: state.isFocused ? 0 : 0,
                "&:hover": {
                    border: state.isFocused ? `1px solid ${colors.PRIMARY}` : `1px solid ${colors.GRAY}`,
                    boxShadow: ''
                },
            }),
            singleValue: (base) => {
                return {
                    ...base,
                    color: colors.TEXT_GRAY
                }
            }
        }
        }

    />
}

export default Selection;