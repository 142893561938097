import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../utils/colors';

const useStyles = makeStyles(theme => ({

  // COLLAPSIBLE CONTAINER
  collapsibleContainer: {
    marginBottom : theme.spacing(2),
    marginTop : theme.spacing(2),
  },

  collapsibleContent: {
    display: 'flex',
    padding: theme.spacing(1, 0),
    '& > svg': {
      padding: 5,
      marginRight: 15,
      color: colors.PRIMARY,
      width: 48,
      height: 48
    },

    '& > svg path': {
      fill: colors.PRIMARY
    },

    '& > .MuiBox-root': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      
      '& > .MuiTypography-root': {
        fontSize: 16
      },

      '& > .MuiTypography-root:first-of-type': {
        fontWeight: 'bold',
        // color: colors.PRIMARY,
        textTransform: 'lowercase',
      },

      '& > .MuiTypography-root:first-line': {
        textTransform: 'capitalize'
      },

      '& > .MuiTypography-root:nth-child(2)': {
        color: colors.PRIMARY,
      }
    },
  },

  collapseBoxWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },

  valueText: {
    color: colors.TEXT_GRAY,
  },

  hidden: {
    visibility: 'hidden',
  },

  visible: {
    visibility: 'visible',
  },

  link: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: 14,
    marginLeft: 'auto',
    '& > svg': {
      width: 16,
      height: 16,
      marginLeft: 5,
      '& > path': {
        fill: colors.PRIMARY
      }
    }
  },

  linkSave: {
    cursor: 'pointer',
    fontSize: 14,
    float: 'right',
    marginBottom: theme.spacing(2),
  },

  buttonSave: {
    width: 120,
    borderRadius: 40,
    alignSelf: 'flex-end',
    marginBottom: theme.spacing(2),
  },

  // ADDRESS
  addressContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(4),
  },

  textField: {
    width: 240,
    marginBottom: theme.spacing(2),

    '& > .MuiOutlinedInput-root': {
      borderRadius: 10
    }
  },

  // ABOUT US
  noteField: {
    width: '100%',
    marginBottom: theme.spacing(2),
    '& > .MuiOutlinedInput-root': {
      borderRadius: 10
    }
  },

  // PREFERENCE
  preferenceContainer: {
    marginLeft: theme.spacing(4),
    marginBottom: theme.spacing(1),

    '& .MuiTypography-root': {
      color: colors.TEXT_GRAY,
      fontSize: 14,
    }
  },

  formControl: {
    display: 'flex',
    flexDirection: 'row',

    '& > .MuiBox-root': {
      display: 'flex',
      flexDirection: 'column'
    }
  },

  radio: {
    padding: theme.spacing(0.5),
  },

  // HEIGHT
  heightContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: 400,
    justifyContent: 'center',
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(1),

    '& > .MuiBox-root': {
      marginLeft: theme.spacing(3),
    },

    '& > .MuiBox-root:first-of-type': {
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'center',
      width: theme.spacing(20),
      marginRight: theme.spacing(3),
    }
  },

  // MY PROFILE
  content: {
    flex: 1,
    paddingTop: theme.spacing(5),
    padding: theme.spacing(4),
    '& > .MuiTypography-root:first-child': {
      color: colors.PRIMARY
    }
  },

  basicInfoContainer: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(5),
    '& > .MuiIconButton-root': {
      width: 128,
      height: 128,
    },
    '& > .MuiBox-root': {
      alignSelf: 'center',
      marginLeft: theme.spacing(2),
      '& > .MuiTypography-root': {
        color: colors.TEXT_GRAY,
      },
      '& > .MuiTypography-h6': {
        fontWeight: 'bold'
      }
    }
  },

  photoIcon: {
    padding: 4,
    left: 50,
    top:40,
    position: 'relative',
    backgroundColor: colors.GRAY,
    color: colors.WHITE,
    borderRadius: 15,
    height: 18,
    width: 18,
  },

  
  inputFile: {
    width: 0.1,
    height: 0.1,
    opacity: 0,
    overflow: 'hidden',
    position: 'absolute',
    zIndex: -1
  },

  images: {
    position: 'absolute',
    width: 128,
    height: 128,
  },

  linkStatus: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  
  //Update Profile Picture
  closeIcon: {
    color: colors.GRAY,
    position: 'absolute',
    right: 10,
    top: 10, 
    cursor : 'pointer',
    '&:hover': {
      color: colors.WHITE,
    }
  },

  uploadImageBox: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    height: 'auto',
    width: 250,
    [theme.breakpoints.up('md')]: {
      height: 'auto',
      width: 400,
    },
  },

  button: {
    border: 'none',
    color: colors.PRIMARY,
    margin: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)',
      borderColor: colors.PRIMARY,
    },
  },

  controls: {
    display: 'flex',
    flexDirection: 'row',
    '& .Mui-disabled': {
      borderColor: colors.TEXT_GRAY,
      color: colors.TEXT_GRAY,
    },
  },

  imageBoxModal: {
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },
  
  // MODAL

  // MODAL
  modal: {
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent'
  },

  paper: {
    position: 'absolute',
    minWidth: 350,
    maxWidth: 350,
    top: '30%',
    left: 'calc(50% - 215px)',
    backgroundColor: colors.WHITE,
    borderRadius: 15,
    padding: theme.spacing(5, 5, 5),

    '& > .MuiTypography-root': {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },

    '& > .MuiButtonBase-root': {
      marginTop: theme.spacing(3),
    }

  },

  modalBtnContainer: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'row'
  },
  
  submit: {
    marginRight :theme.spacing(.75)
  },

  image:{
    fontSize:50
  },
  buttonUpload: {
    backgroundColor: colors.GRADIENT_ORANGE,
    color: colors.WHITE,
    margin: theme.spacing(1),
    '&:hover': {
      backgroundColor: colors.PRIMARY,
      color: colors.WHITE,
      borderColor: colors.WHITE,
    },
  },
  
  planIcon: {
    width: '20px',
    height: '20px'
  },

  quickFade: {
    animation: '$fadeInAnimation 0.5s forwards',
  },

  ringIcon: {
    width: '50px',
    height: '50px',
    padding: '5px',
    marginRight: '15px'
  }

}));

export default useStyles;
