import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    // flexGrow: 1,
    // width: '100%',
    // backgroundColor: theme.palette.background.paper,
    position: 'absolute',
    marginTop: 120,
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0
  },

  invitationView: {
    marginTop: 180,
    [theme.breakpoints.down("sm")]: {
      marginTop: 165
    },
  },

  createEventContainer: {
    alignItems: 'flex-end',
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(2, 0, 2, 0),
  }

}));

export default useStyles;