import React from 'react';
import { Box, Container } from '@material-ui/core';
import Content from '../settings/terms-and-conditions';
import useStyles from './style';

function Welcome() {
  const classes = useStyles();

  return (
    <Container component={Box} maxWidth="lg" className={classes.container}>
      <Content showTitle={true}/>
    </Container>
  );
}

export default Welcome;