import { sessionService } from 'redux-react-session';
import { 
  all, 
  // call, 
  put, 
  select, 
  takeEvery } from 'redux-saga/effects';
import action from './action';
import { userRequest } from '../../service/requests';
import { UPD_MODE_USER_MEMBERSHIP } from '../../utils/constants';
import moment from 'moment'
import { handleHttpError } from '../../utils/helper';
/////////////////////////
// reqAuthenticate()
/////////////////////////
function* reqAuthenticate({ payload }) {
  const { user, token } = payload;

  try {
    console.log('reqAuthenticate()');

    yield sessionService.saveSession({ 
      token
    });

    yield sessionService.saveUser({
      user      : user,
      isSignedIn: false
    });
  } catch (error) {
    console.log('error : ', error);
  }
}

/////////////////////////
// reqUserSignIn()
/////////////////////////
function* reqUserSignIn({ payload }) {
  const { user, token } = payload;

  try {
    console.log('reqUserSignIn()');

    const userDetail = {
      user : user,
      settings : {
        notification : {
          isEnabled : true,
          custom : {
            alert : true,
            chat  : true,
            voice : true,
            video : true
          }
        }
      }
    }
    const session = yield sessionService.saveSession({ 
      token
    });

    const response = yield userRequest.getDetail({
      user_id : user.userId
    }).catch(error =>{
      handleHttpError({
        error,
        request: 'userRequest.getDetail::src/redux/user'
      })
    });

    if (response) {
      userDetail.userField = response.data.userDetail.userField

      yield sessionService.saveUser({
        ...userDetail,
        isSignedIn: true
      });
      yield put({
        type    : action.USER_FIELD_UPDATE,
        payload : response.data.userDetail.userField
      });
      yield put({
        type    : action.USER_SUBSCRIPTION_UPDATE,
        payload : response.data.userDetail.userSubscription
      });
    }
  } catch (error) {
    console.log('error : ', error);
  }
}

/////////////////////////
// reqUserUpdateSession()
/////////////////////////
function* reqUserUpdateSession({ payload }) {
  const { user, userField } = payload;

  try {
    console.log('reqUserUpdateSession()');

    const getSession = state => state.Session;
    const Session    = yield select(getSession);
    const userDetail = {
      ...Session.user,
      user : user,
      userField : userField
    }

    sessionService.saveUser(userDetail).then(() => {
      console.log('SAVE USER SESSION SUCCESS');
    }).catch(error => {
      console.log('error : ', error);
    });

  } catch (error) {
    console.log('error : ', error);
  }
}

/////////////////////////
// reqUserUpdateSessionProfile()
/////////////////////////
function* reqUserUpdateSessionProfile({ payload }) {
  const { media } = payload;

  try {
    console.log('reqUserUpdateSessionProfile()');

    const getSession = state => state.Session;
    const Session    = yield select(getSession);
    const userDetail = {
      ...Session.user,
      user: {
        ...Session.user.user,
        mediaProfile: media,
      },
    }

    sessionService.saveUser(userDetail).then(() => {
      console.log('SAVE USER SESSION SUCCESS');
    }).catch(error => {
      console.log('error : ', error);
    });

  } catch (error) {
    console.log('error : ', error);
  }
}

/////////////////////////
// reqUserUpdateNotificationSettings()
/////////////////////////
function* reqUserUpdateNotificationSettings({ payload }) {
  const { notificationSetting, value } = payload;

  try {
    console.log('reqUserUpdateNotificationSettings()');

    const getSession = state => state.Session;
    const Session    = yield select(getSession);
    let notificationSettings = Session.user.settings.notification;
    

    if (notificationSetting === 'desktop') {
      notificationSettings['isEnabled'] = value;
    } else {
      notificationSettings.custom[notificationSetting] = value;
    }

    const updatedSession = {
      ...Session.user,
      settings : {
        ...Session.user.settings,
        notification : notificationSettings
      }
    }

    sessionService.saveUser(updatedSession).then(() => {
      console.log('SAVE USER SESSION SUCCESS');
    }).catch(error => {
      console.log('error : ', error);
    });
  } catch (error) {
    console.log('error : ', error);
  }
}

const delay = (ms) => new Promise(res => setTimeout(res, ms))

function* reqBillingUpdate({payload}) {
  try{
    const { subscriptionPlanId } = payload
    const getSession = state => state.Session;
    const Session    = yield select(getSession);
    const userId = Session.user.user.userId;
  
      for( let ctr=0 ; ctr < 10 ; ctr++ ){
        const response = yield userRequest.getDetail({
          user_id : userId
        }).catch(error =>{
          handleHttpError({
            error,
            request: 'userRequest.getDetail::src/redux/user'
          })
        });

        const userSubscription = response.data.userDetail.userSubscription

        const currentDate = moment().unix()- ( 3 * 60 )
        const minDate = moment.unix(currentDate).format("MM/DD/YY H:mm ")
        const subscriptionUpdated = moment.unix(userSubscription.whenUpdated).format("MM/DD/YY H:mm ")
        if(subscriptionPlanId === 'trial' && new Date(subscriptionUpdated) >= new Date(minDate)) {
          const payload = {
            userSubscription: userSubscription,
            applyingSubscriptionLoading: false,
            applyingSubscriptionStatus: 'success'
          }
          yield put({
            type    : action.USER_BILLING_UPDATE,
            payload 
          });
          return
        }
        console.log(new Date(subscriptionUpdated) >= new Date(minDate) && subscriptionPlanId, userSubscription.subscriptionPlanId)
        if(new Date(subscriptionUpdated) >= new Date(minDate) && `${subscriptionPlanId}` === `${userSubscription.subscriptionPlanId}`){
          const payload = {
            userSubscription: userSubscription,
            applyingSubscriptionLoading: false,
            applyingSubscriptionStatus: 'success'
          }
          yield put({
            type    : action.USER_BILLING_UPDATE,
            payload 
          });
          return
        }
        yield delay(500)
        if(ctr === 9){
          const payload = {
            userSubscription: userSubscription,
            applyingSubscriptionLoading: false,
            applyingSubscriptionStatus: 'fail'
          }
          yield put({
            type    : action.USER_BILLING_UPDATE,
            payload 
          });
        }
      } 
      
  }
  catch(e){
    console.log('error: ', e)
  }
}

export default function* rootSaga() {
  yield all([takeEvery(action.AUTHENTICATE, reqAuthenticate)]);
  yield all([takeEvery(action.USER_SIGNIN, reqUserSignIn)]);
  yield all([takeEvery(action.UPDATE_USER_SESSION, reqUserUpdateSession)]);
  yield all([takeEvery(action.USER_PROFILE_UPDATE, reqUserUpdateSessionProfile)]);
  yield all([takeEvery(action.USER_NOTIF_SETTING_SET, reqUserUpdateNotificationSettings)]);
  yield all([takeEvery(action.USER_SUBSCRIPTION_START, reqBillingUpdate)]);
}
