import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Modal, Popover, CardMedia, Card, IconButton, CircularProgress } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import Empty from '../../../components/empty';
import { PRELOADER_BLOCKED_PROFILES, BLOCKED_NO_PROFILES, HTTP_STATUS } from '../../../utils/constants';
import image from '../../../assets/images/user-profile.png';
import NO_BLOCKED_PROFILES from "../../../assets/images/no_blocked_users.png";
import Preloader from '../../../components/preloader';
import useStyles from './style';
import { connectionRequest } from '../../../service/requests';
import { formatDateTime, handleHttpError } from '../../../utils/helper';
import { select } from 'redux-saga/effects';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useHistory } from 'react-router-dom';






const BlockedProfiles = (props) => {
  const classes = useStyles();

  let history = useHistory();
  const { userId } = props;
  var uservalue;


  const [blockedUsers, setBlockedUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [isUnblockLoading, setIsUnblockLoading] = useState(false);
  const [show, setshow] = useState(true);
  const [selected, setSelected] = useState([])
  const { userConnection, setUserConnection } = useState([]);



  const getBlockedUser = () => {
    connectionRequest.getBlockedUser({
      user_id: userId
    }).then(response => {
      console.log('response : ', response);

      if (response?.data?.status?.code == HTTP_STATUS._200) {
        setBlockedUsers(response.data.users);

      }
      setIsLoading(false);
    }).catch(error => {
      handleHttpError({
        error,
        request: 'connectionRequest.getBlockedUser::src/views/settings/blocked-profiles'
      })
    });
  }

  useEffect(() => {
    getBlockedUser();
  }, []);

  const toggleModal = () => {
    setIsModalOpen(prevIsModalOpen => !prevIsModalOpen);
  };

  const togglePopover = (event, connectionUser) => {

    if (connectionUser) {
      const { userId, userName } = connectionUser;
      setSelectedUser({ userId, userName });
    }

    setPopoverAnchorEl(event.currentTarget);
    setIsPopoverOpen(!isPopoverOpen);

    if (isPopoverOpen) {
      setPopoverAnchorEl(null);
    }
  }

  const handleUnblock = () => {
    setIsPopoverOpen(false);

    toggleModal();
  }




  const unblockUser = async () => {
    const connectionUserId = selectedUser.userId;
    const connectionUserName = selectedUser.userName;

    try {
      setIsUnblockLoading(true);
      const response = await connectionRequest.unblockUser(
        {
          user_id: userId,
          who_added: userId
        },
        connectionUserId
      ).catch(error =>{
        handleHttpError({
          error,
          request: 'connectionRequest.unblockUser::src/views/settings/blocked-profiles'
        })
      })

      if (response?.data?.status?.code == HTTP_STATUS._200) {
        setBlockedUsers(blockedUsers.filter(auUserConnection => auUserConnection.user.userId !== connectionUserId));
        toggleModal();
        history.push(`/profile/${connectionUserName}?tab=profile`)

      }
    } catch (error) {
      console.log('error: ', error);
    } finally {
      setIsUnblockLoading(false);
    }


  }



  const Content = () => {

    return (
      <>
        <Box className={classes.profileBox}>
          {
              isLoading ?
                [1, 2, 3, 4].map(value => <Preloader type={PRELOADER_BLOCKED_PROFILES} key={value} />)
                : blockedUsers.length > 0 ?
                  blockedUsers.map((value, index) =>
                    <Box onClick={(event) => {
                      togglePopover(event, value.user)
                      const USERID = value.user.userId;
                      uservalue = USERID;
                      setSelected(uservalue);
                      setshow(false);
                    }}
                      component={Card} className={classes.blockedCard} key={index}>
                      <CardMedia className={classes.blockedImage} image={value.user.mediaProfile ? value.user.mediaProfile : image}></CardMedia>
                      <Box className={classes.blockedUsername}>
                        <Typography variant="subtitle2">
                          {value.user.userName}
                          
                        </Typography>
                        <Typography variant="subtitle2" className={classes.blockedDate}>
                          {formatDateTime(value.auConnection.whenChanged)}
                        </Typography>

                      </Box>
                      <Typography onClick={handleUnblock} className={classes.unblockbtn}>Unblock</Typography>
                    </Box>

                  )
                  :
                  <Box className={classes.emptyBox}>
                    <Empty image={NO_BLOCKED_PROFILES} title="Blocked Profiles" description={BLOCKED_NO_PROFILES} /> 
                  </Box>
          }
         </Box>

        <Modal
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
          open={isModalOpen}
          onClose={toggleModal}
        >
          <div className={classes.modal}>
            <div className={classes.paper}>

              <Typography variant="h5" color="textPrimary" className={`text-bold text-center`}>
                {`Unblock ${selectedUser && selectedUser.userName}`}
              </Typography>

              <Typography variant="subtitle2" className={`text-center`} color="textPrimary">
                {`Do you really want to unblock ${selectedUser && selectedUser.userName}?`}
              </Typography>

              <Box className={classes.modalBtnContainer}>
                <Button
                  type="submit"
                  fullWidth
                  variant="outlined"
                  color="primary"
                  className={classes.submit}
                  onClick={toggleModal}
                >
                  No
                </Button>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={unblockUser}
                  disabled={isUnblockLoading}
                >
                  {
                    isUnblockLoading ?
                      <CircularProgress color="white" size={24} />

                      :
                      'Yes'
                  }
                </Button>
              </Box>
            </div>
          </div>
        </Modal>
      </>
    )
  }





  return (
    <>
      <Content />
    </>
  )
}

export default BlockedProfiles;