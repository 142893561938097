import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../../utils/colors';

const useStyles = makeStyles(theme => ({
  paymentCard: {
    outline: 'none',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    boxShadow: 24,
    borderRadius: 10,
    width: '500px',
    height: '800px',
  },

  iframe:{
    width: '100%',
    height: '100%'
  },

  closeButton: {
    position: 'absolute',
    top: -40,
    right: -40
  },

  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    position: 'relative',
    width: '100%',
    height: '100%',
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-khtml-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    'user-select': 'none',
    fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif'"
  },

  imageContainer: {
    marginTop: 20,
    width: 100,
    height: 100,
    overflow: 'hidden',
    marginLeft: 'auto',
    marginRight: 'auto',
    '& > img': {
      width: '100%',
      height: '100%',
    },
  },

  cardInformation: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    color: colors.PRIMARY_TEXT,
    fontSize: 14,
    '& > input': {
      marginTop: 5,
      width: '100%',
      height: '35px',
      borderTopLeftRadius: 7,
      borderTopRightRadius: 7,
      boxSizing: 'border-box',
      paddingLeft: 10,
      border: '1px solid gray',
      outlineColor: colors.PRIMARY_TEXT
    }
  },

  cardAdditional: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',


    '& input': {
      width: '50%',
      height: '35px',
      boxSizing: 'border-box',
      border: '1px solid gray',
      borderTop: 0,
      outlineColor: colors.PRIMARY_TEXT
    },
    '& input:nth-of-type(1)': {
      borderBottomLeftRadius: 7,
      borderRight: 0,
      paddingLeft: 10,
    },
    '& input:nth-of-type(2)': {
      borderBottomRightRadius: 7,
      paddingLeft: 10,
    }
  },

  headerInformation: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    alignItems: 'center',
    width: '100%',
  },

  title: {
    color: colors.GRADIENT_ORANGE,
    fontWeight: 'bold',
    fontSize: 20,
  },

  subTitle: {
    display: 'flex',
    flexDirection: 'row',
    display: 'inline',
    color: colors.PRIMARY_TEXT,
    '& span:nth-of-type(1)': {
      fontWeight: 'bold',
      fontSize: 18,
      marginRight: 5,

    },
    '& span:nth-of-type(2)': {
      marginTop: 'auto',
    }

  },

  submitButton: {
    marginTop: 'auto',
    color: 'white',
    backgroundColor: colors.GRADIENT_ORANGE
  }

}
))



export { useStyles }