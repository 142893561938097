import { makeStyles } from '@material-ui/core/styles';
import colors from '../../utils/colors';

const useStyles = makeStyles(theme => ({

	item: {
		borderRadius: '10px',
		border: 'none',
		margin: '10px'
	},

	cardWrapper: {
		display: 'flex',
		flexDirection: 'row',
		margin: '1em',
		marginLeft: '20px',
		marginRight: '20px',
	},

	profileImg: {
		borderRadius: '50%',
		alignSelf: 'center',
		height: '60px',
		width: '60px'

	},

	username: {
		color: colors.PRIMARY,
		fontWeight: 'bold'
	},

	message: {
		fontSize: '12px',
		textOverflow: 'ellipsis'
	},

	textWrapper: {
		flexGrow: 1,
		whiteSpace: 'nowrap',
		overflow: 'hidden',
		minWidth: 0,
		marginLeft: 15,
		'& p:nth-child(1)': {
			margin: 0,
			marginBottom: '10px',
			marginTop: '5px'
		},
		'& p:nth-child(2)': {
			margin: 0,
			overflow: 'hidden',
			textOverflow: 'ellipsis',
			whiteSpace: 'nowrap'
		}
	},

	buttonWrapper: {
		alignSelf: 'center',
		display: 'flex',
		justifyContent: 'flex-end'
	},

	replyButton: {
		backgroundColor: colors.PRIMARY,
		padding: '5px',
		paddingLeft: '1.2em',
		paddingRight: '1.2em',
		color: colors.WHITE,
		border: 'none',
		borderRadius: '5px',
		cursor: 'pointer',
		'&:focus': {
			outline: 0
		}
	},

	planIcon: {
    width: '20px',
    height: '20px'
  },
  
  quickFade: {
    animation: '$fadeInAnimation 0.5s forwards',
  },

}));

export default useStyles;