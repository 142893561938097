import React, { useCallback, useState, useEffect, useRef } from 'react';
import ReactCrop                                           from 'react-image-crop';
import { Button, 
         Box, 
         CircularProgress, 
         Dialog, DialogActions, 
         DialogContent, 
         DialogContentText,
         DialogTitle, 
         Modal, 
         Typography, 
        Divider, 
         CardMedia, 
         Link }                                            from '@material-ui/core';

import DeleteOutlinedIcon                                  from '@material-ui/icons/DeleteOutlined';
import DeleteIcon                                          from '@material-ui/icons/Delete';
import CloseOutlinedIcon                                   from '@material-ui/icons/CloseOutlined';
import CloudUploadOutlinedIcon                             from '@material-ui/icons/CloudUploadOutlined';
import RotateRightOutlinedIcon                             from '@material-ui/icons/RotateRightOutlined';
import CancelPresentationIcon                              from '@material-ui/icons/CancelPresentation';
import useStyles                                           from './style';
import Title                                               from '../../../components/title';
import Empty                                               from '../../../components/empty';
import { createRandomCharacters, handleHttpError } from '../../../utils/helper';
import { ALERT_SEVERITY_INFO, 
         ALERT_SEVERITY_SUCCESS, 
         ALERT_SEVERITY_ERROR, 
         TOAST_AXIOS_REQUEST_ERROR, 
         TOAST_INTERNAL_SERVER_ERROR, 
         UPD_MODE_USER_PROFILE_PHOTO, 
         MSG_NO_PHOTOS, 
         HTTP_STATUS}                                    from '../../../utils/constants';
import { multipleRequest, userRequest }                     from '../../../service/requests';
import NO_PHOTOS                                            from '../../../assets/images/no_photos.png';
import 'react-image-crop/dist/ReactCrop.css';

const MyPictures = (props) => {
  const classes = useStyles();

  const { userId, showAlert, mediaProfile, updateSession, updateUser, user, updateProfilePicture } = props;
  const [medias, setMedia]                                    = useState([]);
  const [mediaId, setMediaId]                                 = useState(null);
  const [profile, setProfile]                                 = useState(null);
  const [isDialogOpen, setIsDialogOpen]                       = useState(false);
  const [isSaving, setIsSaving]                               = useState(false);
  const [isSavingProfilePicture, setIsSavingProfilePicture]   = useState(false);
  const [isDeleting, setIsDeleting]                           = useState(false);
  const [loadingProfile, setLoadingProfile]                   = useState(false);
  const [file, setFile]                                       = useState(null);

  const [isImageModalOpen, setIsImageModalOpen]               = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen]             = useState(false);
  const [isRotate, setIsRotate]                               = useState(false);
  const [angle, setAngle]                                     = useState(0);

  const [selectedImage, setSelectedImage]                     = useState({});
  const fileRef = React.useRef(null)
  const [crop, setCrop] = useState({ 
    unit:   '%', 
    x:      0,
    y:      0,
    width:  100,
    height: 100,
    aspect: null
  });
  const [imageRef, setImageRef] = useState(null);

  useEffect(() => {
    getPhotos();
  }, [])

  const getPhotos = () => {
    console.log('MyPictures.getPhotos');
    setLoadingProfile(true)
    userRequest.getMediaGallery({
      user_id : userId
    }).then(response => {
      console.log('response : ', response);
     
      if (response?.data?.status?.code == HTTP_STATUS._200) {
        let profilePath = ''
        setMedia(response.data.media.filter(media => {
          if(media.album_name == "Profile"){
            setProfile(media)
            profilePath = media.s3_path
            console.log(profilePath,'profpath')
          }
          return media.album_name != "Profile"
        }));
        updateProfilePicture({media: profilePath})
      } else {
        showAlert(ALERT_SEVERITY_ERROR, TOAST_INTERNAL_SERVER_ERROR);
      }
      setLoadingProfile(false)
    })
    .catch(error => {
      setLoadingProfile(false)
      handleHttpError({
        error,
        request: 'userRequest.getMediaGallery::src/views/settings/my-picture'
      })
    })
  }

  const setProfilePicture = (path, e) => {

    e.stopPropagation();
    setIsSavingProfilePicture(prevIsSaving => !prevIsSaving);
    showAlert(ALERT_SEVERITY_INFO, 'Changing your profile picture.');
    userRequest.updateUser({
      user_id        : userId,
      upd_mode       : UPD_MODE_USER_PROFILE_PHOTO,
      who_updated    : userId,
      profile_photos : path
    }).then(response => {
      console.log('responsezz : ', response);

      if (response?.data?.status?.code != HTTP_STATUS._200) {
        let { status } = response?.data;
        throw new Error(`${status?.code} ${status?.description}`);
      }
      updateSession(response?.data?.userDetail);
      updateUser(response?.data?.userDetail?.user);
      showAlert(ALERT_SEVERITY_SUCCESS, 'You have successfully changed your profile picture.');
      getPhotos();
      setIsSavingProfilePicture(prevIsSaving => !prevIsSaving);
    }).catch(error => {
      setIsSavingProfilePicture(prevIsSaving => !prevIsSaving);
      handleHttpError({
        error,
        request: 'userRequest.updateUser::src/views/settings/my-picture'
      }) 
     });
  }

  const toggleImageModal = () => {
    setIsImageModalOpen(prevIsModalOpen => !prevIsModalOpen);
  };

  const viewImage = (value) => {
    console.log('value : ', value);

    setSelectedImage(value);
    toggleImageModal();
  }

  const toggleUploadModal = () => {
    setIsUploadModalOpen(prevIsModalOpen => !prevIsModalOpen);
  };

  const onChange = (e) => {
    if(medias.length <= 9){
      if (e.target.files.length === 0) {
        return
      }
      const objectUrl = URL.createObjectURL(e.target.files[0]);
      toggleUploadModal();
      setIsRotate(false);
      setAngle(0);
      setCrop({
        unit:   '%', 
        x:      0,
        y:      0,
        width:  100,
        height: 100,
        aspect: 0
      });
      setSelectedImage({
        s3_path : objectUrl
      });
    }else{
      showAlert(ALERT_SEVERITY_ERROR, `You've reached the maximum number of photos.`)
    }
  }

  const updateCropRatio = (ratioWidth,ratioHeight) => {
    console.log(crop.aspect, 'crop aspect')
  
    const ratio              = ratioWidth / ratioHeight;
    const origWidth          = imageRef.width;
    const origHeight         = imageRef.height;
    const newWidth           = ratioWidth * 100;
    const computedRatio      = (origHeight / origWidth) * newWidth;
    const rationInPercentage = ((computedRatio / origWidth) * 100) > 100? 100: ((computedRatio / origWidth) * 100);
   
    setCrop({
      ...crop,
      unit:  '%', 
      x:      0,
      y:     0,
      width:    ratioWidth == 1 && ratioHeight == 1 ?((origHeight / origWidth) * 100) >= 100? 100 : ((origHeight / origWidth) * 100): rationInPercentage,
      height:   ratioWidth == 1 && ratioHeight == 1 ?((origWidth / origHeight) * 100) >= 100? 100 : ((origWidth / origHeight) * 100) : newWidth >= 100? 100: newWidth,
      aspect: ratio
    });
  }

  const uploadPhoto = () => {
    
    showAlert(ALERT_SEVERITY_INFO, 'Uploading please wait.');
    setIsSaving(prevIsSaving => !prevIsSaving);

    const data = new FormData();
    data.append('user_id', userId);
    data.append('image', file);
    data.append('is_primary', 'false')

    console.log('userId', userId);
    console.log('image', file);
    console.log('is_primary', false);
    
    userRequest.uploadPhoto(
      data
    ).then(response => {
      console.log('response : ', response);

      toggleUploadModal();
      showAlert(ALERT_SEVERITY_SUCCESS, 'Uploaded successfully.');
      setIsSaving(prevIsSaving => !prevIsSaving);
      
      setMedia(response.data.media.filter(media => {
        if(media.album_name == "Profile"){
          setProfile(media)
        }
        return media.album_name != "Profile"
      }));
    
     
      fileRef.current.value=''
    }).catch(error => {
      setIsSaving(prevIsSaving => !prevIsSaving);
      handleHttpError({
        error,
        request: 'userRequest.uploadPhoto::src/views/settings/my-picture'
      }) 
    });
  }

  useEffect(()=>{
    if(imageRef != null){
      createCroppedImage(imageRef, crop,angle,isRotate);
    }
  },[imageRef,angle,isRotate]);

  const onLoad = useCallback(img => {
    setImageRef(img);
  }, []);

  const cropImage = async (crop) => {
    console.log('crop : ', crop);

    if (imageRef && crop.width && crop.height) {
      createCroppedImage(imageRef, crop,angle,isRotate); 
    }
  };

  const createCroppedImage = async (image, crop,angle,isRotate) => {
    const canvas  = document.createElement('canvas');
    const scaleX  = image.naturalWidth / image.width;
    const scaleY  = image.naturalHeight / image.height;

    canvas.width  = crop.width;
    canvas.height = crop.height;

    const ctx     = canvas.getContext('2d');

    ctx.save();
    if(isRotate == true){
      ctx.translate(canvas.width/2,canvas.height/2);
      ctx.rotate(isRotate? angle * Math.PI/180 : 0);
    }
  
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      isRotate? -crop.width / 2 : 0, isRotate? -crop.height / 2 : 0,
      crop.width,
      crop.height
    );

    ctx.restore();

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          reject(new Error('Canvas is empty'));
          return;
        }
        
        blob.name = 'newFile.jpeg';
        setFile(blob);
      }, 'image/jpeg');
    });
  }

  const rotateImage = () => {

    if(angle != 270){
      setAngle(angle + 90);
      setIsRotate(true);
    }else{
      setAngle(0);
      setIsRotate(false);
    }

    console.log('rotateImage()', isRotate);
    console.log('angle: ', angle);
  }

  const toggleDialog = (mediaId, event) => {
    if (event) event.stopPropagation();
    setMediaId(mediaId);
    setIsDialogOpen(prevIsDialogOpen => !prevIsDialogOpen);
  }

  const deletePhoto = () => {
    
    

    setIsDeleting(prevIsDeleting => !prevIsDeleting);
    showAlert(ALERT_SEVERITY_INFO, 'Deleting a photo.');
    
    let is_primary = mediaId == profile?.media_id 

    userRequest.deleteUserMedia({
      media_id  : mediaId,
      who_added : userId,
      is_primary
    }).then(response => {
      console.log('response : ', response);
      if (response?.data?.status?.code == HTTP_STATUS._200) {
        let hasProfile = false
        setMedia(response.data.media.filter(media => {
          if(media.album_name == "Profile"){
            setProfile(media)
            hasProfile = true
          }
          return media.album_name != "Profile"
        }));
        if(!hasProfile) {
          setProfile(null)
          updateProfilePicture({media: ''})
        }
        toggleDialog();
        setIsDeleting(prevIsDeleting => !prevIsDeleting);
        showAlert(ALERT_SEVERITY_SUCCESS, 'You have successfully deleted a photo.');
      }
    }).catch(error => {
      setIsDeleting(prevIsDeleting => !prevIsDeleting);
      setMedia(medias);
      handleHttpError({
        error,
        request: 'userRequest.deleteUserMedia::src/views/settings/my-picture'
      }) 
    });
  }

  return (
    <Box className={classes.content}>
      <Title title="My Pictures" />
      <Box>
        <Typography variant="subtitle1" color="textPrimary">
          {`Upload Photo(s): (${medias.length})`}
        </Typography>
         <label className={classes.addPhotoLabel} htmlFor="file">Add Photo</label>
         <input ref={fileRef} name="image" id="file" type="file" accept="image/*" className={classes.inputFile} onChange={onChange}/>
      </Box>

      <Divider/>
      <Box className={classes.imgsContainer}>
        <Typography variant="subtitle2" color="textPrimary">
          My Profile Picture
        </Typography>
        <br/>
        {
          (profile || loadingProfile) ? <Box className={classes.tile} onClick={viewImage.bind(this, {s3_path : mediaProfile})}>
          <CardMedia className={classes.cardMedia} image={profile?.s3_path} >
            <div className={'overlay'}></div>
            <DeleteIcon className={classes.deleteIcon} onClick={(e)=> {
              if(profile) {
                toggleDialog(profile.media_id, e)
              }
            }}/>
          </CardMedia>
        </Box> :  <Box className={classes.imageBox}>
          <Box display='flex' justifyContent='center' alignItems='center' height={150}>
          <Empty image={NO_PHOTOS} title={"No profile"} />
          </Box>
        </Box>
        }
        <br/>

        <br/>
        <Typography variant="subtitle2" color="textPrimary">
          My Other Pictures
        </Typography>
        <br/>

        <Box className={classes.imageBox}>
          {
            medias.length > 0 ?
              medias.map((value, index) => (
                value.album_name != 'Profile' && 
                <Box key={index} className={classes.tile} onClick={viewImage.bind(this, value)}>
                  <CardMedia className={classes.cardMedia} image={value.s3_path} >
                    <div className={'overlay'}></div>
                    <DeleteIcon className={classes.deleteIcon} onClick={toggleDialog.bind(this, value.media_id)}/>
                  </CardMedia>
                </Box>
              ))
            : 
              <Box display='flex' justifyContent='center' alignItems='center' height={150}>
                <Empty image={NO_PHOTOS} title={MSG_NO_PHOTOS} />
              </Box>
          }
          <Dialog
            open={isDialogOpen}
            onClose={toggleDialog}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">Delete Photo</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Are you sure you want to delete this photo?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={toggleDialog} color="primary" variant="outlined">
                No
              </Button>
              <Button onClick={deletePhoto} color="primary" variant="contained" autoFocus>
                Yes
              </Button>
            </DialogActions>
          </Dialog>


          <Modal
            open={isImageModalOpen}
            onClose={toggleImageModal}
          >
            <Box className={classes.modal}>
              <CloseOutlinedIcon className={classes.closeIcon} onClick={toggleImageModal}/>
              <Box className={classes.viewImageBox}>
                <CardMedia className={classes.image} image={selectedImage && selectedImage.s3_path}></CardMedia>
                {
                  selectedImage.path && <Link onClick={(e) => {
                    setProfilePicture(selectedImage && selectedImage.path, e);
                    toggleImageModal();
                  }}>Make Profile Picture</Link> 
                }
              </Box>
            </Box>
          </Modal>

          <Modal
            open={isUploadModalOpen}
            onClose={toggleUploadModal}
          >
            <Box className={classes.modal}>
              <CloseOutlinedIcon className={classes.closeIcon} onClick={toggleUploadModal}/>
              <Box className={classes.uploadImageBox}>
                <ReactCrop
                  src={selectedImage && selectedImage.s3_path}
                  onImageLoaded={onLoad}
                  crop={crop}
                  style={{ transform: `rotate(${angle}deg)` }}
                  onChange={c => setCrop(c)}
                  onComplete={cropImage}
                />
                <Box className={classes.controls}>
                  <Button disabled={isSaving} variant="outlined" className={classes.button} onClick={updateCropRatio.bind(this, 0,0)}>
                    Freeform
                  </Button>
                  <Button disabled={isSaving} variant="outlined" className={classes.button}onClick={rotateImage} startIcon={<RotateRightOutlinedIcon className={classes.cropIcon}/>}>
                    Rotate
                  </Button>
                  <Button disabled={isSaving} variant="outlined" className={classes.button} onClick={updateCropRatio.bind(this, 1,1)}>
                    Square
                  </Button>
                  <Button disabled={isSaving} variant="outlined" className={classes.button} onClick={updateCropRatio.bind(this, 5,7)}>
                    5:7
                  </Button>
                  <Button disabled={isSaving} variant="outlined" className={classes.button} onClick={updateCropRatio.bind(this, 3,4)}>
                    3:4
                  </Button>
                  <Button disabled={isSaving} variant="outlined" className={classes.button} onClick={updateCropRatio.bind(this, 3,5)}>
                    3:5
                  </Button>
                  <Button disabled={isSaving} variant="outlined" className={classes.button} onClick={updateCropRatio.bind(this, 2,3)}>
                    2:3
                  </Button>
                </Box>
                <Box className={classes.controls}>
                  <Button disabled={isSaving} variant="outlined" className={classes.button} onClick={toggleUploadModal} startIcon={<CancelPresentationIcon className={classes.cropIcon}/>}>
                    Cancel
                  </Button>
                  <Button disabled={isSaving} variant="outlined" className={classes.buttonUpload} onClick={uploadPhoto} startIcon={!isSaving ? <CloudUploadOutlinedIcon className={classes.cropIcon}/> : <></>}>
                    {
                      isSaving ?
                        <CircularProgress color="white" size={24}/>
                      : 
                        'Upload'
                    }
                  </Button>
                </Box>
              </Box>
            </Box>
          </Modal>
        </Box>
      </Box>
    </Box>
  )
}

export default MyPictures;