import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../utils/colors';

const useStyles = makeStyles(theme => ({
  box: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddintTop: theme.spacing(2),
    paddintBottom: theme.spacing(2),
    width: '100%'
  },

  box2: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    width: '100%'
  },

  noteField: {
    width: '90%'
  },

  // MODAL
  modal: {
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent'
  },

  paper: {
    backgroundColor: colors.WHITE,
    borderRadius: 15,
    position: 'absolute',
    minWidth: 350,
    left: 'calc(50% - 215px)',
    maxWidth: 350,
    padding: theme.spacing(5, 5, 5),
    textAlign: 'center',
    top: '20%',

    '& > .MuiTypography-root': {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },

    '& > .MuiButtonBase-root': {
      marginTop: theme.spacing(3),
    }
  },

  image: {
    marginBottom: theme.spacing(2),
    height: 64,
    width: 64,
  },

  characterCount: {
    alignSelf: 'flex-end',
    marginRight: '5%',
  }

}));

export default useStyles;