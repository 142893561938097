import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import {
  Button, Box, CircularProgress, Collapse, Divider, FormControl, FormHelperText, Link,
  MenuItem, Select, TextField, Typography, Grid, List, ListItem, ListItemIcon, ListItemText,
  Modal, Card, OutlinedInput, Input, Fade, Backdrop, Paper 
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
// import countryByCallingCode from 'country-json/src/country-by-calling-code.json';
import countryByDialInfo from '../../../assets/json/country-by-dial-info.json';
import PinInput from 'react-pin-input';

import useStyles from './style';
import SOS_GRADIENT from '../../../assets/images/logo-text-gradient.png';
import Title from '../../../components/title';
import SnackBar from '../../../components/snackbar';
import SubscriptionPlan from './subscription-plan';
import UnsubscribePlan from './unsubscribe-plan';
import PaymentModal from './paymentModal';
import ConfirmModal from './confirmModal';

import { LOOK_UP_ISSUE_CATEG, UPD_MODE_USER_PHONE_NO, UPD_MODE_USER_EMAIL_ADDRESS, ALERT_SEVERITY_SUCCESS, ALERT_SEVERITY_ERROR, TOAST_AXIOS_REQUEST_ERROR, HTTP_STATUS } from '../../../utils/constants';
import lookupAction from '../../../redux/lookup/action';
import userAction from '../../../redux/user/action';
import { userRequest, billingRequest } from '../../../service/requests';
import { formatPhoneNumber, getFormattedDateTime, handleHttpError } from '../../../utils/helper';
import { USER_SUBSCRIPTION_REGULAR, USER_SUBSCRIPTION_ELITE, USER_SUBSCRIPTION_PRIVACY, UPD_MODE_USER_SUBSCRIPTION_STATUS, USER_SUBSCRIPTION_STATUS_END, USER_SUBSCRIPTION_TRIAL_ID } from '../../../utils/constants';

import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';

import rootAction  from '../../../redux/action';

import { connect } from 'react-redux';

const { signOutUser } = rootAction;

const CollapsibleContainer = (props) => {
  const classes = useStyles();
  const { component, label, index, openedIndex, setOpenedIndex } = props;

  const toggleContent = () => {
    setOpenedIndex(openedIndex != index ? index : null);
  }

  return (
    <Box className={classes.collapsibleContainer}>
      <Box className={classes.box}>
        <ListItem className={classes.itemList} button onClick={toggleContent}>
          <ListItemText variant="subtitle1" color="textPrimary">
            <Typography color="textPrimary">
              {label}
            </Typography>
          </ListItemText>
            {
              !(openedIndex === index) ? 
              <ExpandMoreIcon /> : <ExpandLessIcon />
            }
        </ListItem>
      </Box>
      <Collapse in={openedIndex === index}>
        <Box>
          {
            component
          }
        </Box>
      </Collapse>
      <Divider />
    </Box>
  )
}

const ChangePassword = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const { showAlert, userId, user, updateUser } = props;

  const [isSaving, setIsSaving] = useState(false);
  const [values, setState] = useState({
    currentPassword: '',
    newPassword: '',
    confirmNewPassword: '',
    isCurrentPasswordError: false,
    currentPasswordErrorDesc: '',
    isNewPasswordError: false,
    newPasswordErrorDesc: '',
    isConfirmNewPasswordError: false,
    confirmNewPasswordErrorDesc: '',
    isPasswordPassed: false,
    isPasswordVisible: false,
    isNewPasswordVisible: false,
    isConfirmPasswordVisible: false,
    passwordCriteria: {
      has8Characters: false,
      has1Lowercase: false,
      has1Uppercase: false,
      has1Number: false,
      has1SpecialCharacter: false
    }
  });

  const {
    currentPassword, newPassword, confirmNewPassword,
    isCurrentPasswordError, currentPasswordErrorDesc,
    isNewPasswordError, newPasswordErrorDesc,
    isConfirmNewPasswordError, confirmNewPasswordErrorDesc,
    passwordCriteria, isPasswordPassed
  } = values;

  useEffect(() => {
    if (newPassword !== confirmNewPassword && newPassword !== '' && confirmNewPassword !== '') {
      setState({
        ...values,
        isConfirmNewPasswordError: true,
        confirmNewPasswordErrorDesc: 'Password mismatched.'
      });
    }
     else if (newPassword === confirmNewPassword && newPassword === currentPassword  && confirmNewPassword !== '') {
      setState({
        ...values,
        isConfirmNewPasswordError: true,
        confirmNewPasswordErrorDesc: 'New password must be different from current password.'
      });
    } 
    else {
      setState({
        ...values,
        isConfirmNewPasswordError: false,
        confirmNewPasswordErrorDesc: ''
      });
    }
  }, [confirmNewPassword, newPassword])

  const handleInputChange = (event) => {

    let inputValue = event.target.value;
    if (!inputValue.trim('') && inputValue.length) {
      inputValue = '';
      return;
    }

    const has8Characters = inputValue.length >= 8;
    const has1Lowercase = /[a-z]/.test(inputValue);
    const has1Uppercase = /[A-Z]/.test(inputValue);
    const has1Number = /[0-9]/.test(inputValue);
    const has1SpecialCharacter = /[~`!@#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/.test(inputValue);

    let newState = {
      ...values,
      [event.target.name]: inputValue,
    }

    if (event.target.name === 'newPassword') {
      newState = {
        ...newState,
        isPasswordPassed: has8Characters && has1Lowercase && has1Uppercase && has1Number && has1SpecialCharacter,
        passwordCriteria: {
          has8Characters,
          has1Lowercase,
          has1Uppercase,
          has1Number,
          has1SpecialCharacter
        }
      }
    }

    setState({
      ...newState
    });
  };

  const changePassword = () => {
    console.log('ChangePassword.changePassword()');

    if (currentPassword === '' || newPassword === '' || confirmNewPassword === '') {
      setState({
        ...values,
        isCurrentPasswordError: currentPassword === '' ? true : false,
        currentPasswordErrorDesc: currentPassword === '' ? 'Required.' : '',
        isNewPasswordError: newPassword === '' ? true : false,
        newPasswordErrorDesc: newPassword === '' ? 'Required' : '',
        isConfirmNewPasswordError: confirmNewPassword === '' ? true : false,
        confirmNewPasswordErrorDesc: confirmNewPassword === '' ? 'Required' : '',
      });     
    } else if (newPassword !== confirmNewPassword) {
      showAlert(ALERT_SEVERITY_ERROR, 'Password mismatched.');
    } else if (!isPasswordPassed) {
      showAlert(ALERT_SEVERITY_ERROR, 'Password is weak. Create a stronger password.');
    } else {
      setIsSaving(prevIsSaving => !prevIsSaving);
      userRequest.changePassword({
        user_id: userId,
        current_password: currentPassword,
        new_password: newPassword
      }).then(response => {
        console.log('response : ', response);

        setIsSaving(prevIsSaving => !prevIsSaving);
        if (response?.data?.code == HTTP_STATUS._200) {
          setState({
            ...values,
            currentPassword: '',
            newPassword: '',
            confirmNewPassword: '',
            isCurrentPasswordError: false,
            currentPasswordErrorDesc: ''
          });
          showAlert(ALERT_SEVERITY_SUCCESS, 'Successfully changed password.');
        } else {
          setState({
            ...values,
            isCurrentPasswordError: true,
            currentPasswordErrorDesc: 'Incorrect password.'
          });
          showAlert(ALERT_SEVERITY_ERROR, 'Incorrect password.');
        }
      }).catch(error => {
        setIsSaving(prevIsSaving => !prevIsSaving);
        handleHttpError({
          error,
          request: 'userRequest.changePassword::src/views/settings/myaccount'
        })
      });
    }
  }

  const handleClickShowPassword = () => {
    setState({ ...values, isPasswordVisible: !values.isPasswordVisible });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => {
    setState({ ...values, isConfirmPasswordVisible: !values.isConfirmPasswordVisible });
  }

  const handleClickShowNewPassword = () => {
    setState({ ...values, isNewPasswordVisible: !values.isNewPasswordVisible });
  }

  const forgotPassword = () => {
  
    userRequest.verifyResetPasswordEmail({
      email: user.email,
    }).then(response => {
      console.log('response : ', response);

      if (response?.data?.status?.code == HTTP_STATUS._200) {
        showAlert(ALERT_SEVERITY_SUCCESS, 'We sent a code to your phone number to verify your account.');
        updateUser({
          email : user.email
        });
        history.push('/forgotpassword');
      } 
    }).catch(error => {
      handleHttpError({
        error,
        request: 'userRequest.verifyResetPasswordEmail::src/views/welcome'
      })
    });
  }
 
  const disabledButtonChangePassword = currentPassword === '' || newPassword === '' || confirmNewPassword === '' || isConfirmNewPasswordError;
  return (
    <Grid container spacing={4} >
      <Grid item lg={6}>
        <Box className={classes.changePassword}>
          <TextField
            required
            label={'Current Password'}
            name={'currentPassword'}
            autoComplete="new-password"
            type={values.isPasswordVisible ? 'text' : 'password'}
            value={currentPassword}
            onChange={handleInputChange}
            className={classes.textField}
            variant="outlined"
            size="small"
            error={isCurrentPasswordError}
            helperText={currentPasswordErrorDesc}

            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          <TextField
            required
            label={'New Password'}
            name={'newPassword'}
            type={values.isNewPasswordVisible ? 'text' : 'password'}
            value={newPassword}
            onChange={handleInputChange}
            className={classes.textField}
            variant="outlined"
            size="small"
            error={isNewPasswordError}
            helperText={newPasswordErrorDesc}

            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowNewPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.isNewPasswordVisible ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />

          <TextField
            required
            label={'Confirm New Password'}
            name={'confirmNewPassword'}
            type={values.isConfirmPasswordVisible ? 'text' : 'password'}
            value={confirmNewPassword}
            onChange={handleInputChange}
            onBlur={handleInputChange}
            className={classes.textField}
            variant="outlined"
            size="small"
            error={isConfirmNewPasswordError}
            helperText={confirmNewPasswordErrorDesc}

            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {values.isConfirmPasswordVisible ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </Box>
        <Typography variant="caption" color="textPrimary" className={classes.forgotpassword}>
          <Link variant="caption" color='textPrimary' className={classes.linkForgetPassword} onClick={forgotPassword}>
            Forgot password?
          </Link>
        </Typography>
      </Grid>
      <Grid item lg={6}>
        <Box className={classes.passwordStrength}>
          <Typography>Password must contain: </Typography>
          <List>
            <ListItem dense>
              <ListItemIcon>
                {
                  passwordCriteria?.has8Characters ?
                    <CheckIcon fontSize="small" className={classes.checkIconGreen} />
                    :
                    <CloseIcon fontSize="small" color="error" />
                }
              </ListItemIcon>
              <ListItemText primary="8 characters" />
            </ListItem>
            <ListItem dense>
              <ListItemIcon>
                {
                  passwordCriteria?.has1Lowercase ?
                    <CheckIcon fontSize="small" className={classes.checkIconGreen} />
                    :
                    <CloseIcon fontSize="small" color="error" />
                }
              </ListItemIcon>
              <ListItemText primary="1 lowercase" />
            </ListItem>
            <ListItem dense>
              <ListItemIcon>
                {
                  passwordCriteria?.has1Uppercase ?
                    <CheckIcon fontSize="small" className={classes.checkIconGreen} />
                    :
                    <CloseIcon fontSize="small" color="error" />
                }
              </ListItemIcon>
              <ListItemText primary="1 uppercase" />
            </ListItem>
            <ListItem dense>
              <ListItemIcon>
                {
                  passwordCriteria?.has1Number ?
                    <CheckIcon fontSize="small" className={classes.checkIconGreen} />
                    :
                    <CloseIcon fontSize="small" color="error" />
                }
              </ListItemIcon>
              <ListItemText primary="1 number" />
            </ListItem>
            <ListItem dense>
              <ListItemIcon>
                {
                  passwordCriteria?.has1SpecialCharacter ?
                    <CheckIcon fontSize="small" className={classes.checkIconGreen} />
                    :
                    <CloseIcon fontSize="small" color="error" />
                }
              </ListItemIcon>
              <ListItemText primary="1 special character" />
            </ListItem>
          </List>
          <Button disabled={isSaving || disabledButtonChangePassword} onClick={changePassword} color="primary" variant="contained">
            {
              isSaving ?
                <CircularProgress color="white" size={24} />
                :
                'Change'
            }
          </Button>
        </Box>
      </Grid>
    </Grid>
  )
}

const ChangeEmail = (props) => {
  const classes = useStyles();

  const { showAlert, userId, updateSession, updateUser, user} = props;

  const [isSaving, setIsSaving] = useState(false);

  const [values, setState] = useState({
    password: '',
    email: user.email,
    isPasswordError: false,
    passwordErrorDesc: '',
  });

  const [isEmailError, setIsEmailError] = useState(false);
  const [emailErrorDesc, setEmailErrorDesc] = useState('');

  const {
    password, email,
    isPasswordError, passwordErrorDesc
  } = values;

  const handleInputChange = event => {
    let value = event.target.value;
    if (!value.trim('') && value.length) {
      value = '';
      return;
    }

    if (event.target.name == 'email') {
      const emailPattern = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      const isEmailInvalid = !emailPattern.test(value);

      setIsEmailError(isEmailInvalid ? true : false)
      setEmailErrorDesc(isEmailInvalid ? 'Invalid email format.' : '');
    }

    setState({
      ...values,
      [event.target.name]: value
    });
  };

  const validateEmail = () => {
    if (email == '') {
      setIsEmailError(true)
      setEmailErrorDesc('Required');
    } else {
      if (!isEmailError) {
        userRequest.checkEmail({
          email
        }).then(response => {
          console.log('response : ', response);

          if (response?.data?.code == HTTP_STATUS._469) {
            setIsEmailError(true)
            setEmailErrorDesc('This email is already used.');
          } else {
            setIsEmailError(false)
            setEmailErrorDesc('');
          }

        }).catch(error => {
          showAlert(ALERT_SEVERITY_ERROR, TOAST_AXIOS_REQUEST_ERROR);
          handleHttpError({
            error,
            request: 'userRequest.checkEmail::src/views/settings/myaccount'
          })
        });
      }
    }
  }

  const changeEmail = () => {

    if (password === '' || email === '') {
      setIsEmailError(email === '' ? true : false)
      setEmailErrorDesc(email === '' ? 'Required' : '');
      setState({
        ...values,
        isPasswordError: password === '' ? true : false,
        passwordErrorDesc: password === '' ? 'Required.' : '',
      });

      return
    }

    setIsSaving(prevIsSaving => !prevIsSaving);
    userRequest.checkEmail({
      email
    }).then(response => {
      console.log('response : ', response);

      if (response?.data?.code == HTTP_STATUS._469) {
        setIsEmailError(true)
        setEmailErrorDesc('This email is already used.');
      } else {
        userRequest.signIn({
          password,
          email: props.email
        }).then(response => {
          console.log('response : ', response);

          if (response?.data?.status?.code == HTTP_STATUS._200) {
            userRequest.updateUser({
              email,
              user_id: userId,
              upd_mode: UPD_MODE_USER_EMAIL_ADDRESS,
              who_updated: userId,
            }).then(response => {
              if (response?.data?.status?.code != HTTP_STATUS._200) {
                let { status } = response?.data;
                throw new Error(`${status?.code} ${status?.description}`);
              }
              console.log('response : ', response);
              setIsEmailError(false);
              setEmailErrorDesc('');
              setState({
                ...values,
                password: '',
                isPasswordError: '',
                passwordErrorDesc: ''
              });
              updateSession(response?.data?.userDetail);
              updateUser(response?.data?.userDetail?.user);    
              setIsSaving(prevIsSaving => !prevIsSaving);
              showAlert(ALERT_SEVERITY_SUCCESS, 'Successfully changed email address.');
            }).catch(error => {
              setIsSaving(prevIsSaving => !prevIsSaving);
              handleHttpError({
                error,
                request: 'userRequest.updateUser::src/views/settings/myaccount'
              })
            });
          } else {
            setState({
              ...values,
              isPasswordError: true,
              passwordErrorDesc: 'Incorrect password.'
            });
            setIsSaving(prevIsSaving => !prevIsSaving);
            showAlert(ALERT_SEVERITY_ERROR, 'Incorrect password.');
          }
        }).catch(error => {
          setIsSaving(prevIsSaving => !prevIsSaving);
          handleHttpError({
            error,
            request: 'userRequest.signIn::src/views/settings/myaccount'
          })
        });
      }
    }).catch(error => {
      handleHttpError({
        error,
        request: 'userRequest.checkEmail::src/views/settings/myaccount'
      })
    });
  }

  const handleClickShowPassword = () => {
    setState({ ...values, isPasswordVisible: !values.isPasswordVisible });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const disabledButtonUpdateEmail = email === '' || password === '' || isEmailError || email === user.email;
  return (    
    <Box className={classes.changePassword}>
      <Typography variant='subtitle2' className={classes.currentInfo}>
        { `Current email: ${user.email}` }
      </Typography>
      <TextField
        required
        label={'New Email'}
        name={'email'}
        onChange={handleInputChange}
        onBlur={validateEmail}
        className={classes.textField}
        variant="outlined"
        size="small"
        error={isEmailError}
        helperText={emailErrorDesc}
      />

      <TextField
        required
        id="outlined-adornment-password"
        label={'Password'}
        name={'password'}
        type={values.isPasswordVisible ? 'text' : 'password'}
        value={password}
        onChange={handleInputChange}
        className={classes.margin}
        variant='outlined'
        size='small'
        error={isPasswordError}
        helperText={passwordErrorDesc}

        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge='end'
              >
                {values.isPasswordVisible ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />

      <Button disabled={isSaving || disabledButtonUpdateEmail} onClick={changeEmail} color="primary" variant="contained">
        {
          isSaving ?
            <CircularProgress color="white" size={24} />
            :
            'Change'
        }
      </Button>
    </Box>
  )
}

const ChangeMobileNumber = (props) => {
  const classes = useStyles();

  const { email, showAlert, updateSession, updateUser, userId, user } = props;

  const [isSaving, setIsSaving] = useState(false);
  const [isVerifyCodeOpen, setIsVerifyCodeOpen] = useState(false);
  const [isChangingNumber, setIsChangingNumber] = useState(false);
  const [isMobileNumberError, setIsMobileNumberError] = useState(false);
  const [mobileNumberErrorDesc, setMobileNumberErrorDesc] = useState('');

  var length = user?.phoneNo?.length - 1;
  var numLength = length - 9;
  var phoneNumBody = formatPhoneNumber(user?.phoneNo?.substring(numLength, (length + 1)));
  var dialCode = user?.phoneNo?.substring(0, numLength);

  const [state, setState] = useState({
    password: '',
    mobileNumber: phoneNumBody,
    countryCode: dialCode,
    isPasswordError: false,
    passwordErrorDesc: '', 

    code: '',
    isCodeError: false, 
    codeErrorDesc: ''
  });
  
  const {
    password, mobileNumber, countryCode,
    isPasswordError, passwordErrorDesc,

    code, isCodeError, codeErrorDesc
  } = state;

  useEffect(() => {

  }, []);

  const handleCodeChange = (event) => {
    let value = event.target.value;
    if (!value.trim('') && value.length) {
      value = '';
      return;
    }

    setState({
      ...state,
      countryCode: value
    });
  }

  const handleInputChange = event => {
    let value = event.target.value;
    if (!value.trim('') && value.length) {
      value = '';
      return;
    }

    if (event.target.name === 'mobileNumber') {

      value = formatPhoneNumber(value);

    } else if (event.target.name === 'code') {
      value = value.replace(/[^0-9]/g, '');

      if (value.length > 6)
        return;
    }

    setState({
      ...state,
      [event.target.name]: value
    });
  };

  const mobileNumberStripped = mobileNumber?.replace(/[^\d]/g, "");

  const validateMobileNumber = () => {
    if ((countryCode + mobileNumberStripped) === user.phoneNo) {
      setIsMobileNumberError(true)
      setMobileNumberErrorDesc('New mobile number must be different from current mobile number.')
    }
    else if (mobileNumber === '') {
      setIsMobileNumberError(true)
      setMobileNumberErrorDesc('Required')
    }
    else {
      setIsMobileNumberError(false)
      setMobileNumberErrorDesc('')      
    }
  }

  const changeMobileNumber = async () => {
    setState({
      ...state,
      isMobileNumberError: mobileNumberStripped === '' || mobileNumberStripped.replace(/[^\d]/g, "").length !== 10 || countryCode + mobileNumberStripped === user.phoneNo ? true : false,
      mobileNumberErrorDesc: mobileNumberStripped === '' ? 'Required' : mobileNumberStripped.replace(/[^\d]/g, "").length !== 10 ? 'Enter a valid mobile number' : countryCode + mobileNumberStripped === user.phoneNo ? 'New mobile number must be different from current mobile number.' : '',
      isPasswordError: password === '' ? true : false,
      passwordErrorDesc: password === '' ? 'Required.' : '',
    });


    if (password === '' || mobileNumberStripped === '' || mobileNumberStripped.length !== 10 || countryCode + mobileNumberStripped === user.phoneNo)
      return

    setIsSaving(prevIsSaving => !prevIsSaving);
    const signInResponse = await userRequest.signIn({
      email,
      password
    }).catch(error => {
      setIsSaving(prevIsSaving => !prevIsSaving);
      handleHttpError({
        error,
        request: 'userRequest.signIn::src/views/settings/myaccount'
      })
    });
      console.log('signInResponse:',signInResponse);
      if (signInResponse?.data?.status?.code == HTTP_STATUS._200) {
        const sentOTPResponse = await userRequest.sentOTP({
          user_id : userId,
          phone_no: countryCode + mobileNumberStripped
        })
        .catch(error => {
        setIsSaving(prevIsSaving => !prevIsSaving);
        handleHttpError({
          error,
          request: 'userRequest.sentOTP::src/views/settings/myaccount'
        })
      });
      console.log('sentOTPResponse:',sentOTPResponse);
      if (sentOTPResponse?.data?.code != HTTP_STATUS._200) {
        let { status } = sentOTPResponse?.data;
        throw new Error(`${status?.code} ${status?.description}`);
      }
      showAlert(ALERT_SEVERITY_SUCCESS, 'We sent a code to your mobile phone number.');
      setIsSaving(prevIsSaving => !prevIsSaving);

      setIsVerifyCodeOpen(true);
      setIsChangingNumber(true);
      } else {
        setIsSaving(prevIsSaving => !prevIsSaving);
        setState({
          ...state,
          isPasswordError: true,
          passwordErrorDesc: 'Incorrect password.'
        });
      }
  }

  const resendCode = () => {
    const mobileNumberStripped = mobileNumber?.replace(/[^\d]/g, "");
    setState({
      ...state,
      code: '',
      isCodeError: false,
      codeErrorDesc: ''
    });

    userRequest.resendCode({
      user_id: userId,
      phone_no: countryCode + mobileNumberStripped,
    }).then(response => {
      console.log('response : ', response);

      if (response?.data?.code == HTTP_STATUS._200) {
        showAlert(ALERT_SEVERITY_SUCCESS, 'We sent a new code to your mobile phone number.');
      }
    }).catch(error => {
      handleHttpError({
        error,
        request: 'userRequest.resendCode::src/views/settings/myaccount'
      })
    });
  }

  const verifyCode = async () => {
    const mobileNumberStripped = mobileNumber?.replace(/[^\d]/g, "");
    setIsSaving(prevIsSaving => !prevIsSaving);
    if (code != '') {
      const verifyPhoneResponse = await userRequest.verifyPhone({
        user_id: userId,
        phone_no: countryCode + mobileNumberStripped,
        conf_code: code,
      }).catch(error => {
        handleHttpError({
          error,
          request: 'userRequest.verifyPhone::src/views/settings/myaccount'
        })
      })
      setIsSaving(prevIsSaving => !prevIsSaving);
      if (verifyPhoneResponse?.data?.code == HTTP_STATUS._200) {
        if(isChangingNumber) {
          const updateUserResponse = await userRequest.updateUser({
            user_id     : userId,
            upd_mode    : UPD_MODE_USER_PHONE_NO,
            who_updated	: userId,
            phone_no    : countryCode + mobileNumberStripped
          }).catch(error => 
            handleHttpError({
            error,
            request: 'userRequest.updateUser::src/views/settings/myaccount'
          }));
          console.log('updateUserResponse:',updateUserResponse);
          if (updateUserResponse?.data?.status.code != HTTP_STATUS._200) {
            let { status } = updateUserResponse?.data;
            throw new Error(`${status?.code} ${status?.description}`);
          }
          updateSession(updateUserResponse?.data?.userDetail);
          updateUser(updateUserResponse?.data?.userDetail?.user);
        }
        setIsVerifyCodeOpen(false);
        setIsChangingNumber(false);
        setState({
          ...state,
          code: '',
          codeErrorDesc: '',
          isCodeError: false,
          password: '',
        });
        showAlert(ALERT_SEVERITY_SUCCESS, 'Successfully verified the code.');

      } else {
        setState({
          ...state,
          isCodeError: true,
          codeErrorDesc: verifyPhoneResponse.data.description
        });
      }
    }
  }

  const [values, setValues] = React.useState({
    amount: '',
    password: '',
    weight: '',
    weightRange: '',
    isPasswordVisible: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, isPasswordVisible: !values.isPasswordVisible });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const disabledButtonUpdateMobNum = countryCode === '' || mobileNumber === '' || password === '' || countryCode + mobileNumberStripped === user.phoneNo;

  const alphabeticallySort = countryByDialInfo.filter((countryItem) => countryItem.dial_code != null).sort(function(firstCountryItem, secondCountryItem) {
    var dial_codeA = firstCountryItem.code.toLowerCase(); 
    var dial_codeB = secondCountryItem.code.toLowerCase();
    if (dial_codeA < dial_codeB) {
      return -1; 
    }
    if (dial_codeA > dial_codeB) {
      return 1; 
    }
    return 0; 
  })


  return (
    <Box className={classes.changePassword}>
      {
        isVerifyCodeOpen ?
          <Box className={classes.codeVerification}>
            {/* <TextField
              required
              label={'Code'}
              name={'code'}
              value={code}
              onChange={handleInputChange}
              className={classes.textField}
              variant="outlined"
              size="small"
              error={isCodeError}
              helperText={codeErrorDesc}
            /> */}
            <Typography color="textPrimary">Enter Verification Code</Typography>
            <PinInput
              length={6}
              initialValue={code}
              onChange={(value) => handleInputChange({ target: { value, name: 'code' } })}
              type="numeric"
              inputMode="number"
              autoSelect={true}
            />
            {isCodeError && <Typography color={'error'}>{codeErrorDesc}</Typography>}
            <Typography color="textPrimary">
              Please enter the verification code sent into your mobile device. <Link onClick={resendCode}>Resend Code.</Link>
            </Typography>
            <Typography color="textPrimary">
              <Link onClick={() => setIsVerifyCodeOpen(false)}>Change Mobile Number</Link>
            </Typography>
            <Box className={'buttonGroup'}>

              <Button disabled={isSaving || code.length < 6} onClick={verifyCode} color="primary" variant="contained">
                {
                  isSaving ?
                    <CircularProgress color="white" size={24} />
                    :
                    'Confirm'
                }
              </Button>
            </Box>
          </Box>
          :
          <>
            <Box className={classes.mobileNumber}>
              <Typography variant='subtitle2' className={classes.currentInfo} >
                {`Current mobile number: ${user.phoneNo}` }
              </Typography>
              <Typography variant="subtitle2" color="textPrimary">
                New Mobile Number
              </Typography>
              <Box>
                <FormControl className={classes.formControl} error={isMobileNumberError}>
                  <Select
                    variant="outlined"
                    value={countryCode}
                    onChange={handleCodeChange}
                    MenuProps = {{ PaperProps: {style: {maxHeight: 200}} }}
                  // renderValue={selected => `${selected}`}
                  >
                    {
                      alphabeticallySort.map((countryItem, index) =>
                        <MenuItem key={index} value={countryItem.dial_code}>{`${countryItem.flag} ${countryItem.code} ${countryItem.dial_code}`}</MenuItem>
                      )
                    }
                  </Select>
                </FormControl>
                <TextField
                  name='mobileNumber'
                  onChange={handleInputChange}
                  required
                  onBlur={validateMobileNumber}
                  error={isMobileNumberError}
                  helperText={mobileNumberErrorDesc}
                  variant="outlined"
                  size="small"
                />
              </Box>
            </Box>

            <TextField
              required
              id="outlined-adornment-password"
              label={'Password'}
              className={classes.margin}
              name={'password'}
              type={values.isPasswordVisible ? 'text' : 'password'}
              value={password}
              onChange={handleInputChange}
              size="small"
              error={isPasswordError}
              helperText={passwordErrorDesc}
              variant="outlined"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />

            <Button disabled={isSaving || disabledButtonUpdateMobNum} onClick={changeMobileNumber} color="primary" variant="contained">
              {
                isSaving ?
                  <CircularProgress color="white" size={24} />
                  :
                  'Change'
              }
            </Button>
          </>
      }
    </Box>
  )
}

const ModifySubscription = (props) => {
  const classes = useStyles();

  const [isFirstConfirmationModalOpen,  setisFirstConfirmationModalOpen] = useState(false);
  const [isOneTimePasswordModalOpen,    setOneTimePasswordModalOpen] = useState(false);
  const [isConfimationMessageModalOpen, setConfimationMessageModalOpen] = useState(false);

  const [snackbarSeverity, setSnackbarSeverity] = useState('');
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

  const { setOpen, userId, updateUserSubscription, signOutUser, userUnsubscribe, setOpenUnsubscribePanel } = props;

  const { userSubscription, lookUpSubscriptionPlan } = props;
  const subscriptionPlan = lookUpSubscriptionPlan.find(lookUpSubscriptionPlanItem => lookUpSubscriptionPlanItem.subscriptionPlanId === userSubscription.subscriptionPlanId);
  const expiryDate = getFormattedDateTime(userSubscription.subscriptionPlanEnd, 'unix', 'MMMM D, YYYY');

  let userSubscriptionDesc = '';
  if (subscriptionPlan) {
    switch (subscriptionPlan.membershipLevelCode) {
      case USER_SUBSCRIPTION_REGULAR:
        userSubscriptionDesc = 'Regular'
        break;
      case USER_SUBSCRIPTION_PRIVACY:
        userSubscriptionDesc = 'Privacy'
        break;
      case USER_SUBSCRIPTION_ELITE:
        userSubscriptionDesc = 'Elite'
        break;
    }
  }

  const toggleModal = () => {
    if (userSubscription.status === USER_SUBSCRIPTION_STATUS_END) return;
    setisFirstConfirmationModalOpen(!isFirstConfirmationModalOpen);
  };

  const removeSubscription = async () => {
    const response = await userRequest.updateUser({
      user_id: userId,
      upd_mode: UPD_MODE_USER_SUBSCRIPTION_STATUS,
      who_updated: userId,
      status: USER_SUBSCRIPTION_STATUS_END
    }).catch(error =>{
      handleHttpError({
        error,
        request: 'userRequest.updateUser::src/views/settings/myaccount'
      })
    });
    if (response?.data?.status?.code != HTTP_STATUS._200) {
      let { status } = response?.data;
			throw new Error(`${status?.code} ${status?.description}`);
    }
    updateUserSubscription(response?.data?.userDetail?.userSubscription);
    setSnackbarSeverity(ALERT_SEVERITY_SUCCESS);
    setSnackbarMessage('You have successfully ended your subscription.');
    setIsSnackbarOpen(true);
  }

  const toggleSubscriptionPlanPanel = () => {
    setOpen(prevIsOpen => !prevIsOpen);
  }
  const toggleUnsubscribePlanPanel = () => {
    setOpenUnsubscribePanel(prevIsOpen => !prevIsOpen);
  }

  return (
    <Box className={classes.subscriptionContainer}>
      {
        userSubscription.status != USER_SUBSCRIPTION_STATUS_END ?
          <Typography variant="subtitle1">
            You {userSubscriptionDesc} Subscription will end on {expiryDate}
          </Typography>
          :
          <></>
      }
      <Box className={classes.modifySubscriptionBox}>
        <Box>
          <Typography> {userSubscriptionDesc} Member </Typography>
          <Link onClick={toggleSubscriptionPlanPanel}> Update My Subscription </Link>
        </Box>
        <Divider />
        <Link color="error" onClick={toggleUnsubscribePlanPanel} className={userSubscription.status === USER_SUBSCRIPTION_STATUS_END ? classes.disabledLink : ''}>
          End My Subscription
        </Link>
      </Box>

      <SnackBar
        severity={snackbarSeverity}
        message={snackbarMessage}
        open={isSnackbarOpen}
        setOpen={setIsSnackbarOpen}
      />
    </Box>
  )
}

const MyAccount = (props) => {


  const { location, user,signOutUser } = props;

  const [ openedIndex,              setOpenedIndex ]            = useState(null);
  const [ showSubPlan,              setShowSubPlan ]            = useState(false);
  const [ isShowUnsubscribePanel,   setShowUnsubscribePanel ]   = useState(false);
  const [ paymentModalDetails,      setPaymentModalDetails ]    = useState({});
  const [ confirmUrl,               setConfirmUrl ]             = useState('');
  const [ status,                   setStatus ]                 = useState('none');

  const classes = useStyles();
  const isPaymentModalOpen = Object.keys(paymentModalDetails).length > 0 && paymentModalDetails.constructor === Object;


  useEffect(() => {
    const browseSubscriptions = location.state ? location.state.browseSubscriptions : false
    if (browseSubscriptions) {
      setShowSubPlan(true)
    }
   Object.keys(user).length == 0 && signOutUser();
  }, [])

  useEffect(()=>{
    if(!isPaymentModalOpen){
      setStatus('none')
    }
  },[isPaymentModalOpen])


  return (
    <Box className={classes.content}>
      <Title title= { !isShowUnsubscribePanel? "My Account" : "End Subscription Plan"} />
      {
        !showSubPlan? !isShowUnsubscribePanel? 
          <>
            <div onClick={openedIndex == 0 ? null : () => { setOpenedIndex(openedIndex != 0 ? 0 : null) }}>
              <CollapsibleContainer label="Change My Password" index={0}
                component={
                  <ChangePassword {...props} />
                }
                setOpenedIndex={setOpenedIndex}
                openedIndex={openedIndex}
              />
            </div>

            <div onClick={openedIndex == 1 ? null : () => { setOpenedIndex(openedIndex != 1 ? 1 : null) }}>
              <CollapsibleContainer label="Update My Email Address" index={1}
                component={
                  <ChangeEmail {...props} />
                }
                setOpenedIndex={setOpenedIndex}
                openedIndex={openedIndex}
              />
            </div>

            <div onClick={openedIndex == 2 ? null : () => { setOpenedIndex(openedIndex != 2 ? 2 : null) }}>
              <CollapsibleContainer label="Update My Mobile Number" index={2}
                component={
                  <ChangeMobileNumber {...props} />
                }
                setOpenedIndex={setOpenedIndex}
                openedIndex={openedIndex}
              />
            </div>

            <div onClick={openedIndex == 3 ? null : () => { setOpenedIndex(openedIndex != 3 ? 3 : null) }}>
              <CollapsibleContainer label="Modify My Subscription" index={3}
                setOpenedIndex={setOpenedIndex}
                openedIndex={openedIndex}
                component={
                  <ModifySubscription {...props} setOpen = { setShowSubPlan } setOpenUnsubscribePanel = { setShowUnsubscribePanel } />
                }
              />
            </div>
          </>
          :  <UnsubscribePlan  { ...props } setOpenUnsubscribePanel = { setShowUnsubscribePanel } setPaymentModalDetails={ setPaymentModalDetails }/> // Unsubscribe Panel //
          :  <SubscriptionPlan { ...props } setOpen = { setShowSubPlan } setPaymentModalDetails = { setPaymentModalDetails } setStatus={setStatus}/>
      }
      {
        isPaymentModalOpen && (
          <PaymentModal
            showAlert={props.showAlert}
            isPaymentModalOpen={isPaymentModalOpen}
            paymentModalDetails={paymentModalDetails}
            setPaymentModalDetails={setPaymentModalDetails}
            confirmUrl={confirmUrl}
            setConfirmUrl={setConfirmUrl}
            status={status}
            setStatus={setStatus}
          />
        )
      }
      {
        confirmUrl !== '' && (
          <ConfirmModal
            confirmUrl={confirmUrl}
            setConfirmUrl={setConfirmUrl}
            status={status}
            setStatus={setStatus}
          />
        )
      }
    </Box>
  )
}

const mapStateToProps = (state) => {
  console.log(state, 'my-account-StateToProps');
  return{
    user: state.User.user,
  }
}

export default connect( mapStateToProps, { signOutUser })(
  MyAccount
  );