import React, { useState, useEffect } from 'react';
import { Checkbox, Radio, FormControl, Input, InputLabel, Select, MenuItem, ListItemText } from '@material-ui/core/'
import useStyles from './style';
import { connect } from 'react-redux';
import searchAction from '../../redux/search/action';
import lookupAction from '../../redux/lookup/action';
import Rating from '../../components/rating';
import Heart from '../../components/heart';
import { 
  LOOK_UP_ALCOHOL,
  LOOK_UP_BODY_TYPE,
  LOOK_UP_CODE_NOP,
  LOOK_UP_EDUC_LEVEL,
  LOOK_UP_ETHNICITY,
  LOOK_UP_EXER_REGIMEN,
  LOOK_UP_EYE_COLOR,
  LOOK_UP_HAIR_COLOR,
  LOOK_UP_HEART,
  LOOK_UP_INCOME_LEVEL,
  LOOK_UP_KID_POLICY,
  LOOK_UP_POS_POLITICS,
  LOOK_UP_PREF_SMOKING,
  LOOK_UP_RATING,
  LOOK_UP_RELIG_BELIEF,
  LOOK_UP_RELATIONSHIP_TYPE,
  SEARCH_NAME,
  LOCATION,
  RATING,
  LIKEABILITY,
  BUILD_PREF,
  HAIR_COLOR, 
  EYE_COLOR,  
  KIDS_POS,   
  EDUC_PREF,  
  ETH_PREF,   
  RELIG_PREF, 
  EXERCISE_REG, 
  INCOME_PREF,  
  ALCOHOL_PREF, 
  SMOKING_PREF, 
  POLITICAL_VIEW,
} from '../../utils/constants';

const { updateSearchCriteria } = searchAction;
const { getLookUpFields } = lookupAction;

const lookUps = [
  {
    name      : 'Star Rating',
    lookup    : LOOK_UP_RATING,
    property  : 'lookUpRating',
    type      :   RATING,
  },
  {
    name      : 'Likeability',
    lookup    : LOOK_UP_HEART,
    property  : 'lookUpHeart',
    type      : LIKEABILITY,
  },
  {
    name      : 'Build',
    lookup    : LOOK_UP_BODY_TYPE,
    property  : 'lookUpBodyType',
    type      : BUILD_PREF,
  },
  {
    name      : 'Hair Color',
    lookup    : LOOK_UP_HAIR_COLOR,
    property  : 'lookUpHairColor',
    type      : HAIR_COLOR,
  },
  {
    name      : 'Eye Color',
    lookup    : LOOK_UP_EYE_COLOR,
    property  : 'lookUpEyeColor',
    type      : EYE_COLOR,  
  },
  {
    name      : 'Ethnicity',
    lookup    : LOOK_UP_ETHNICITY,
    property  : 'lookUpEthnicity',
    type      : ETH_PREF,   
  },
  {
    name      : 'Smoking',
    lookup    : LOOK_UP_PREF_SMOKING,
    property  : 'lookUpPrefSmoking',
    type      : SMOKING_PREF, 
  },
  {
    name      : 'Alcohol',
    lookup    : LOOK_UP_ALCOHOL,
    property  : 'lookUpPrefAlcohol',
    type      : ALCOHOL_PREF, 
  },
  {
    name      : 'Kids Position',
    lookup    : LOOK_UP_KID_POLICY,
    property  : 'lookUpKidPolicy',
    type      : KIDS_POS,  
  },
  {
    name      : 'Education',
    lookup    : LOOK_UP_EDUC_LEVEL,
    property  : 'lookUpEducLevel',
    type      : EDUC_PREF,   
  },
  {
    name      : 'Income Level',
    lookup    : LOOK_UP_INCOME_LEVEL,
    property  : 'lookUpIncomeLevel',
    type      : INCOME_PREF,
  },
  {
    name      : 'Religion',
    lookup    : LOOK_UP_RELIG_BELIEF,
    property  : 'lookUpReligBelief',
    type      : RELIG_PREF, 
  },
  {
    name      : 'Politics',
    lookup    : LOOK_UP_POS_POLITICS,
    property  : 'lookUpPosPolitics',
    type      : POLITICAL_VIEW,
  },
  {
    name      : 'Exercise',
    lookup    : LOOK_UP_EXER_REGIMEN,
    property  : 'lookUpExerRegimen',
    type      : EXERCISE_REG,
  },
  {
    name      : 'Relationship Type',
    lookup    : LOOK_UP_RELATIONSHIP_TYPE,
    property  : 'lookUpRelType'
  },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  },
  getContentAnchorEl: null,
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const DropDown = (props) => {
  const classes = useStyles();

  const { 
    type, 
    getLookUpFields, 
    isSingle,
    value,
    searchId,
    updateSearchCriteria
  } = props;

  const [lookUpObject] = useState(lookUps.find(lu => lu.lookup === type));
  const [lookUpValue, setLookUpValue] = useState([]);


  useEffect(() => {
    if (props[`${lookUpObject.property}`].length === 0) {
      getLookUpFields(type);
      return
    }

    let newValue;

    if (type === LOOK_UP_INCOME_LEVEL) {      
      if (!value) return

      newValue = props[`${lookUpObject.property}`].find(lu => lu.code === value);
      setLookUpValue(newValue);
    } else {
      newValue = props[`${lookUpObject.property}`].filter(lu =>  value.find(val => val === lu.code));
      setLookUpValue(newValue);
    }
    
  }, [props[`${lookUpObject.property}`].map(val => val.code).join(', ')]);

  const handleChange = (event) => {
    console.log('handleChange');
    let selectedValue = event.target.value;

    let newValue;
    const isNOPSelected = selectedValue.length > 1 && selectedValue[selectedValue.length - 1].code === LOOK_UP_CODE_NOP;
    newValue = isNOPSelected ? selectedValue.filter(lu => lu.code === LOOK_UP_CODE_NOP) : selectedValue.filter(lu => lu.code !== LOOK_UP_CODE_NOP);
    setLookUpValue(newValue);

    if(newValue.length > 0) {
      setLookUpValue(newValue);
      return
    }

    try {
      const defaultValue = props[`${lookUpObject.property}`][props[`${lookUpObject.property}`].length - 1]
      setLookUpValue([defaultValue]);
    }catch (e) {
      console.log(e)
    }

  };

  const handleClose = () => {
    
    if (type === LOOK_UP_INCOME_LEVEL) return;

    const selectedCriteria = lookUpValue.map(element => element.code)
    const isEqual = value.length === selectedCriteria.length && value.sort().every(function(val, index) { return val === selectedCriteria.sort()[index]});

    if (!isEqual) {
      let code = selectedCriteria.toString() == [] ? null : selectedCriteria.toString();
      if(!code) {
        code = LOOK_UP_CODE_NOP
        const newValue = [{code : LOOK_UP_CODE_NOP, description: "No Preference"}]
        setLookUpValue(newValue);
      }
      const criteriaType = lookUpObject.type;
      const searchCriteria = {
        searchId,
        criteriaType,
        code
      }
      updateSearchCriteria(searchCriteria);
    }
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="demo-mutiple-checkbox-label">{lookUpObject.name}</InputLabel>
      <Select
        labelId="demo-mutiple-checkbox-label"
        id="demo-mutiple-checkbox"
        multiple={!isSingle}
        value={lookUpValue}
        onChange={handleChange}
        onClose={handleClose}
        input={<Input />}
        renderValue={
          isSingle ? 
            selected => selected.description 
          : (type === LOOK_UP_HEART && lookUpValue[0]?.code != LOOK_UP_CODE_NOP) ?
            selected => selected.map(val => <Heart key={val.code} value={val.code}/>)
          : 
            selected => selected.map(val => val.description).join(', ')
        }
        MenuProps={MenuProps}
      >
        {
          props[`${lookUpObject.property}`].map((lookUp, index) => (
            <MenuItem key={index} value={lookUp}>
              {
                isSingle ?
                  <Radio
                    checked={lookUpValue.code === lookUp.code}
                    onChange={handleChange}
                    value={lookUp.code}
                    color="primary"
                  />
                :
                  <Checkbox 
                    checked={lookUpValue.findIndex(val => val.code === lookUp.code) > -1} 
                    color="primary"
                  />
              }
              {
                type === LOOK_UP_RATING && lookUp.code !== 'NOP' ? 
                  lookUp.code != "0.0" ? <Rating value={lookUp.code}/> : "Unrated"
                : type === LOOK_UP_HEART && lookUp.code !== 'NOP' ? 
                  <Heart value={lookUp.code}/>
                :
                  <ListItemText primary={lookUp.description} />
              }
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  )
}


/////////////////////////////
// mapStateToProps()
/////////////////////////////
const mapStateToProps = (state) => {
  // console.log('edit/index.js:mapStateToProps(state)');
  // console.log(state);
  return {
    user                     : state.Session.user.user,
    userId                   : state.Session.user.user.userId,
    searchId                 : state.Search.search.searchId,
    searches                  : state.Search.searches,
    lookUpBodyType           : state.Lookup.lookUpBodyType,
    lookUpEducLevel          : state.Lookup.lookUpEducLevel,
    lookUpEthnicity          : state.Lookup.lookUpEthnicity,
    lookUpExerRegimen        : state.Lookup.lookUpExerRegimen,
    lookUpEyeColor           : state.Lookup.lookUpEyeColor,
    lookUpHairColor          : state.Lookup.lookUpHairColor,
    lookUpHeart              : state.Lookup.lookUpHeart,
    lookUpIncomeLevel        : state.Lookup.lookUpIncomeLevel,
    lookUpKidPolicy          : state.Lookup.lookUpKidPolicy,
    lookUpPosPolitics        : state.Lookup.lookUpPosPolitics,
    lookUpPrefAlcohol        : state.Lookup.lookUpPrefAlcohol,
    lookUpPrefSmoking        : state.Lookup.lookUpPrefSmoking,
    lookUpRating             : state.Lookup.lookUpRating,
    lookUpReligBelief        : state.Lookup.lookUpReligBelief,
    lookUpRelType            : state.Lookup.lookUpRelType
  };
}

export default connect(mapStateToProps, { getLookUpFields, updateSearchCriteria })(
  DropDown
);