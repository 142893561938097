import React from "react";
import { Button, Avatar, Card, Container, Grid, Typography, View } from '@material-ui/core';
import useStyles from './style';

const CouponCard = (props) => {
    const classes = useStyles();
    const { subscriptionType, monthsFree, referredUser, claimCoupon } = props;
  
      return (

        <Grid className={classes.couponCard}>
            <Grid className={classes.left}/>
            <Grid className={classes.right}/>
            <Grid container className={classes.row}>
                <Grid className={classes.subscriptionContainer} >
                    <Typography className={classes.subscriptionText}>{subscriptionType}</Typography>
                    <Typography className={classes.monthsFree}>{monthsFree}</Typography>
                </Grid>
                <Grid className={classes.dash}/>
                <Grid  className={classes.couponContent}>
                    <Typography className={classes.couponText}>{referredUser}</Typography>
                    <Button onClick={claimCoupon} variant='contained' color="primary" className={classes.buttonStyle}>Use</Button>
                </Grid>
            </Grid>
        </Grid>
      )
  }
  export default CouponCard;