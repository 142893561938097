
/* * * * * * * * * * * * * *
 * WEBAPP CONSTANTS
 * * * * * * * * * * * * * */

// CONFIG HEADERS
export const XML_HTTP_REQUEST                   = 'XMLHttpRequest';
export const CSRF_TOKEN                         = 'nocheck';

// Standard HTTP Status Codes
export const HTTP_STATUS = {
    _200: '200',
    _201: '201',
    _202: '202',
    _203: '203',
    _204: '204',
  
    _400: '400',
    _401: '401',
    _403: '403',
    _404: '404',
    _405: '405',
    _409: '409',
    _422: '422',
    _500: '500',
    _502: '502',

    _460: '460',
    _461: '461',
    _462: '462',
    _463: '463',
    _464: '464',
    _465: '465',
    _466: '466',
    _467: '467',
    _468: '468',
    _469: '469',
    _470: '470',
    _471: '471',
    _472: '472',
    _473: '473',
    _475: '475',
    _476: '476',
    _477: '477',
    _489: '489',
    _485: '485',
    _490: '490',
}

// USER CARD
export const CARD_NOTIFICATION                  = 'CARD_NOTIFICATION';
export const CARD_SHORTLIST                     = 'CARD_SHORTLIST';
export const CARD_SHORTLIST_DEFAULT             = 'CARD_SHORTLIST_DEFAULT';
export const CARD_USER                          = 'CARD_USER';
export const CARD_VIEW                          = 'CARD_VIEW';
export const CARD_CALENDAR_EVENTS               = 'CARD_CALENDAR_EVENTS';

export const PRELOADER_CARD_VIEW                = 'PRELOADER_CARD_VIEW';
export const PRELOADER_CARD_NEAR_ME             = 'PRELOADER_CARD_NEAR_ME';
export const PRELOADER_CARD_SHORTLIST           = 'PRELOADER_CARD_SHORTLIST';
export const PRELOADER_CARD_SHORTLIST_HOME      = 'PRELOADER_CARD_SHORTLIST_HOME';
export const PRELOADER_CARD_USER                = 'PRELOADER_CARD_USER';
export const PRELOADER_CARD_CALENDAR            = 'PRELOADER_CARD_CALENDAR';
export const PRELOADER_UPCOMING_EVENT_CARD      = 'PRELOADER_UPCOMING_EVENT_CARD';
export const PRELOADER_CALL_CARD                = 'PRELOADER_CALL_CARD';
export const PRELOADER_MESSAGE_CARD             = 'PRELOADER_MESSAGE_CARD';
export const PRELOADER_UNANSWERED_MESSAGE_CARD  = 'PRELOADER_UNANSWERED_MESSAGE_CARD';
export const PRELOADER_NOTIFICATION_CARD        = 'PRELOADER_NOTIFICATION_CARD';
export const PRELOADER_USER_PROFILE             = 'PRELOADER_USER_PROFILE';
export const PRELOADER_SAVED_SEARCH             = 'PRELOADER_SAVED_SEARCH';
export const PRELOADER_CARD_SEARCH_HOME         = 'PRELOADER_CARD_SEARCH_HOME';
export const PRELODAER_MESSAGE                  = 'PRELODAER_MESSAGE';
export const PRELOADER_USER_HISTORY             = 'PRELOADER_USER_HISTORY';
export const PRELOADER_REFERRAL_CODE            = 'PRELOADER_REFERRAL_CODE';
export const PRELOADER_BLOCKED_PROFILES         = 'PRELOADER_BLOCKED_PROFILES';
export const PRELOADER_PHOTO                    = 'PRELOADER_PHOTO';
export const PRELOADER_COUPON                   = 'PRELOADER_COUPON';

// MESSAGE CENTER CARD
export const NOTIFICATION_CARD                  = 'NOTIFICATION_CARD';
export const MESSAGE_CARD                       = 'MESSAGE_CARD';
export const CALL_CARD                          = 'CALL_CARD';

// STATUS 
export const STATUS_ACTIVE                      = 'ACT';
export const STATUS_INACTIVE                    = 'INA';
export const STATUS_BLOCKED                     = 'BLO';
export const STATUS_RECEIVED_BLOCK              = 'RBL';
export const STATUS_SENT                        = 'SEN';
export const STATUS_RECEIVED                    = 'REC';

// HOME STATUS
export const STATUS_VIEWED_ME                   = 'VIEWED_ME';
export const STATUS_CALENDAR_INVITES            = 'CALENDAR_INVITES';
export const STATUS_HEARTED_ME                  = 'HEARTED_ME';
export const STATUS_PROFILE_COMPLETION          = 'PROFILE_COMPLETION';
export const STATUS_PROFILE_IDEAL               = 'PROFILE_IDEAL';


export const MAX_SHORTLIST_COUNT                = 9;
export const MAX_NOTE_CHARACTERS                = 1000;

// LOOK UPs
export const LOOK_UP_ALCOHOL                    = 'ALCOHOL';
export const LOOK_UP_BODY_TYPE                  = 'BODYTYPE';
export const LOOK_UP_CODE_NOP                   = 'NOP';
export const LOOK_UP_EDUC_LEVEL                 = 'EDUCLEVEL';
export const LOOK_UP_ETHNICITY                  = 'ETHNICITY';
export const LOOK_UP_EXER_REGIMEN               = 'EXERREGIMEN';
export const LOOK_UP_EYE_COLOR                  = 'EYECOLOR';
export const LOOK_UP_GENDER                     = 'GENDER';
export const LOOK_UP_HAIR_COLOR                 = 'HAIRCOLOR';
export const LOOK_UP_HEART                      = 'HEART';
export const LOOK_UP_HEIGHT                     = 'HEIGHT';
export const LOOK_UP_HOBBY                      = 'HOBBY';
export const LOOK_UP_HOBBY_CATEG                = 'HOBBYCATEG';
export const LOOK_UP_INCOME_LEVEL               = 'INCOMELEVEL';
export const LOOK_UP_ISSUE_CATEG                = 'ISSUECATEG';
export const LOOK_UP_KID_POLICY                 = 'KIDPOLICY';
export const LOOK_UP_KID_SITUATION              = 'KIDSITUATION';
export const LOOK_UP_LANGUAGE                   = 'LANGUAGE';
export const LOOK_UP_PET_POLICY                 = 'PETPOLICY';
export const LOOK_UP_POS_POLITICS               = 'POSPOLITICS';
export const LOOK_UP_PREF_SMOKING               = 'SMOKING';
export const LOOK_UP_PROFESSION                 = 'PROFESSION';
export const LOOK_UP_RATING                     = 'RATING';
export const LOOK_UP_RELATIONSHIP_STATUS        = 'RELATIONSHIPSTATUS';
export const LOOK_UP_RELATIONSHIP_TYPE          = 'RELATIONSHIPTYPE';
export const LOOK_UP_RELIG_BELIEF               = 'RELIGBELIEF';
export const LOOK_UP_SUBSCRIPTION_PLAN          = 'SUBSCRIPTIONPLAN';
export const LOOK_UP_USPS_ID                    = 'USPS_ID';


// SEARCH UPDATE MODE
export const UPD_MODE_SEARCH_BASIC              = 'SEARCH_BASIC';
export const UPD_MODE_SEARCH_INCOME_LEVEL       = 'SEARCH_INCOME_LEVEL';

// CONNECTION TYPE
export const CONNECTION_TYPE_BLOCK              = 'BLOCK';
export const CONNECTION_TYPE_HEART              = 'HEART';
export const CONNECTION_TYPE_MESSAGE            = 'MESSAGE';
export const CONNECTION_TYPE_NOTE               = 'NOTE';
export const CONNECTION_TYPE_PHONE_CALL         = 'PHONECALL';
export const CONNECTION_TYPE_RATING             = 'RATING';
export const CONNECTION_TYPE_SHORTLIST          = 'SHORTLIST';
export const CONNECTION_TYPE_VIDEO_CALL         = 'VIDEOCALL';


// SEARCH CRITERIA TYPE
export const SEARCH_NAME                        = 'SEARCHNAME';
export const LOCATION                           = 'LOC';
export const RATING                             = 'RATING';
export const LIKEABILITY                        = 'HEART';
export const BUILD_PREF                         = 'BODYTYPE';
export const HAIR_COLOR                         = 'HAIRCOLOR';
export const EYE_COLOR                          = 'EYECOLOR';
export const KIDS_POS                           = 'KIDPOLICY';
export const EDUC_PREF                          = 'EDUCLEVEL';
export const ETH_PREF                           = 'ETHNICITY';
export const RELIG_PREF                         = 'RELIGBELIEF';
export const EXERCISE_REG                       = 'EXERREGIMEN';
export const INCOME_PREF                        = 'INCOMELEVEL';
export const ALCOHOL_PREF                       = 'ALCOHOL';
export const SMOKING_PREF                       = 'SMOKING';
export const POLITICAL_VIEW                     = 'POSPOLITICS';

export const UNITED_STATES                      = 'United States';


// USER UPDATE MODE
export const UPD_MODE_USER_BIRTHDAY             = 'USER_BIRTHDAY';
export const UPD_MODE_USER_GENDER               = 'USER_GENDER';
export const UPD_MODE_USER_USERNAME_PASSWORD    = 'USER_USERNAME_PASSWORD';
export const UPD_MODE_USER_EMAIL_ADDRESS        = 'USER_EMAIL_ADDRESS'
export const UPD_MODE_USER_PHONE_NO             = 'USER_PHONE_NO';
export const UPD_MODE_USER_ABOUT                = 'USER_ABOUT';
export const UPD_MODE_USER_ADDRESS              = 'USER_ADDRESS';
export const UPD_MODE_USER_POSITION             = 'USER_POSITION';
export const UPD_MODE_USER_LOCATION             = 'USER_LOCATION';
export const UPD_MODE_USER_BODY_TYPE            = 'USER_BODY_TYPE';
export const UPD_MODE_USER_CREDIT_CARD          = 'USER_CREDIT_CARD';
export const UPD_MODE_USER_EDUC_LEVEL           = 'USER_EDUC_LEVEL';
export const UPD_MODE_USER_ETHNICITY            = 'USER_ETHNICITY';
export const UPD_MODE_USER_EYE_COLOR            = 'USER_EYE_COLOR';
export const UPD_MODE_USER_EXER_REGIMEN         = 'USER_EXER_REGIMEN';
export const UPD_MODE_USER_HAIR_COLOR           = 'USER_HAIR_COLOR';
export const UPD_MODE_USER_HEIGHT               = 'USER_HEIGHT';
export const UPD_MODE_USER_HOBBY                = 'USER_HOBBY';
export const UPD_MODE_USER_INCOME_LEVEL         = 'USER_INCOME_LEVEL';
export const UPD_MODE_USER_KID_SITUATION        = 'USER_KID_SITUATION';
export const UPD_MODE_USER_LANGUAGE             = 'USER_LANGUAGE';
export const UPD_MODE_USER_LAST_POS_LAT         = 'USER_LAST_POS_LAST';
export const UPD_MODE_USER_LAST_POS_LNG         = 'USER_LAST_POS_LNG';
export const UPD_MODE_USER_LOOKING_FOR_GENDER   = 'USER_LOOKING_FOR_GENDER';
export const UPD_MODE_USER_PET_POLICY           = 'USER_PET_POLICY';
export const UPD_MODE_USER_POS_POLITICS         = 'USER_POS_POLITICS';
export const UPD_MODE_USER_PREF_ALCOHOL         = 'USER_PREF_ALCOHOL';
export const UPD_MODE_USER_PREF_SMOKING         = 'USER_PREF_SMOKING';
export const UPD_MODE_USER_PROFESSION           = 'USER_PROFESSION';
export const UPD_MODE_USER_PROFILE_PHOTO        = 'USER_PROFILE_PHOTO';
export const UPD_MODE_USER_RELATIONSHIP_STATUS  = 'USER_RELATIONSHIP_STATUS';
export const UPD_MODE_USER_RELATIONSHIP_TYPE    = 'USER_RELATIONSHIP_TYPE';
export const UPD_MODE_USER_RELIGION             = 'USER_RELIGION';
export const UPD_MODE_USER_SETTING              = 'USER_SETTING';
export const UPD_MODE_USER_STATUS               = 'USER_STATUS';
export const UPD_MODE_USER_ZIP_CODE             = 'USER_ZIP_CODE';
export const UPD_MODE_USER_VIEWABILITY_STATUS   = 'USER_VIEWABILITY_STATUS';
export const UPD_MODE_USER_MEMBERSHIP           = 'USER_MEMBERSHIP';
export const UPD_MODE_USER_SUBSCRIPTION_STATUS  = 'USER_SUBSCRIPTION_STATUS';
export const UPD_MODE_REFERRED_BY               = 'REFERRED_BY';
export const LOOK_UP_GEOCODING_API_KEY          = 'GEOCODING_API_KEY';
export const UPD_MODE_USER_REFERRED_BY          = 'REFERRED_BY';
export const ALERT_SEVERITY_ERROR               = 'error'
export const ALERT_SEVERITY_INFO                = 'info'
export const ALERT_SEVERITY_SUCCESS             = 'success'
export const ALERT_SEVERITY_WARINIG             = 'warning'

// TOAST MESSAGES
export const TOAST_AXIOS_REQUEST_ERROR          = 'Sorry, an error occurred.';
export const TOAST_INTERNAL_SERVER_ERROR        = 'Sorry, an error occurred. Please try again later.';
export const TOAST_CALENDAR_UPDATE_FAILED       = 'Failed to update event information.';
export const TOAST_CODE_SEND                    = 'Sending code...';
export const TOAST_CODE_INVALID                 = `The OTP you've entered is incorrect. Please try again.`;
export const TOAST_EVENT_ADDED                  = 'Event is successfully created.';
export const TOAST_EVENT_DELETED                = 'Event deleted.';
export const TOAST_EVENT_DELETING               = 'Deleting event...';
export const TOAST_EVENT_UPDATED                = 'Event is successfully updated.';
export const TOAST_EVENT_UPDATE_FAILED          = 'Event updating failed.';
export const TOAST_EVENT_ACCEPTED               = 'You successfully accepted the invitation.';
export const TOAST_EVENT_DECLINED               = 'You successfully declined the invitation.';
export const TOAST_PASSWORD_CHANGED             = 'Password is successfully changed.';
export const TOAST_INVALID_PASSWORD             = 'New password must be different from current password.';
export const TOAST_PHOTO_DELETING               = 'Deleting...';
export const TOAST_PHOTO_UPLOADED               = 'You uploaded your profile picture.';
export const TOAST_SELECTION_SAVING             = 'Saving...';
export const TOAST_SELECTION_SAVED              = 'Saved.';
export const TOAST_SEARCH_ADDED                 = (searchName) => `${searchName} is successfully created.`;
export const TOAST_SEARCH_UPDATED               = (searchName) => `${searchName} is successfully updated.`;
export const TOAST_SEARCH_DELETING              = 'Deleting search...';
export const TOAST_SEARCH_DELETED               = (searchName) => `${searchName} is successfully deleted.`;
export const TOAST_SHORTLIST_ADDING             = 'Adding to shortlist...';
export const TOAST_SHORTLIST_ALREADY_ADDED      = 'Already added to shortlist.';
export const TOAST_REFERRAL_LINK_COPIED_SUCCESS = 'Referral link is copied in your clipboard';
export const TOAST_COUPON_USE_SUCCESS           = 'The coupon has been used successfully. Enjoy your free 12 months subscription.';
export const TOAST_SHORTLIST_ADDED              = (username) => `You added ${username} to your shortlist.`;
export const TOAST_SHORTLIST_REMOVING           = 'Removing from shortlist...';
export const TOAST_SHORTLIST_REMOVED            = (username) => `You removed ${username} from your shortlist.`;
export const TOAST_USER_BLOCKED                 = (username) => `You blocked ${username}.`;
export const TOAST_USER_LIKED                   = (username) => `You hearted ${username}.`;
export const TOAST_USER_RATED                   = (username) => `You rated ${username}.`;
export const TOAST_USER_UNLIKED                 = (username) => `You unliked ${username}.`;
export const TOAST_USER_NOTE_ADDED              = (username) => `You added note about ${username}.`;
export const TOAST_VERIFICATION_CODE_SENT       = 'New verification code sent via text message.';
export const TOAST_CHAT_SENT                    = (username) => `You sent ${username} a chat request.`
export const TOAST_CHAT_ACCEPTED                = (username) => `You accepted ${username}'s chat request.`
export const TOAST_CHAT_REMOVED                 = (username) => `You removed your chat request to ${username}.`
export const TOAST_CHAT_DECLINED                = (username) => `You declined ${username}'s chat request.`
export const TOAST_CHAT_DISABLED                = (username) => `You disabled chat with ${username}.`
export const TOAST_PHONE_SENT                   = (username) => `You sent ${username} a phone call request.`
export const TOAST_PHONE_ACCEPTED               = (username) => `You accepted ${username}'s phone call request.`
export const TOAST_PHONE_REMOVED                = (username) => `You removed your phone call request to ${username}.`
export const TOAST_PHONE_DECLINED               = (username) => `You declined ${username}'s phone call request.`
export const TOAST_PHONE_DISABLED               = (username) => `You disabled phone call with ${username}.`
export const TOAST_VIDEO_SENT                   = (username) => `You sent ${username} a video call request.`
export const TOAST_VIDEO_ACCEPTED               = (username) => `You accepted ${username}'s video call request.`
export const TOAST_VIDEO_REMOVED                = (username) => `You removed your video call request to ${username}.`
export const TOAST_VIDEO_DECLINED               = (username) => `You declined ${username}'s video call request.`
export const TOAST_VIDEO_DISABLED               = (username) => `You disabled video call with ${username}.`
export const TOAST_SUBSCRIPTION_PLAN_CHANGED    = 'You have successfully updated your subscription.'
export const TOAST_SUBSCRIPTION_PLAN_ERROR      = 'Your transaction has been canceled.'
export const TOAST_INPUT_LIMIT_REACHED          = (limit) => `You already reached the maximum ${limit} characters`
export const TOAST_REFERRAL_SUCCESS             = `Referral submitted success. Thank you.`
export const TOAST_REFERRAL_ERROR               = `Referral code is invalid.`

// TWEET CONTENT
export const REFER_MESSAGE                      = (referralLink) => `Join S.O.Search — the hot new relationship app and get one FREE month of service for each friend you refer! Join here : ${referralLink}.`

// CALENDAR MODES
export const ADD_EVENT                          = 'ADD_EVENT';
export const UPDATE_EVENT                       = 'UPDATE_EVENT';
export const UPD_MODE_CALENDAR_IS_SEEN          = 'CALENDAR_IS_SEEN';

// CALENDAR 
export const CALENDAR_DISPLAY_ALL               = 'ALL';
export const CALENDAR_DISPLAY_USER              = 'USER';
export const CALENDAR_RESPONSE_ACCEPTED         = 'ACCEPTED'; 
export const CALENDAR_RESPONSE_ACCEPTING        = 'ACCEPTING'; 
export const CALENDAR_RESPONSE_DECLINED         = 'DECLINED';  
export const CALENDAR_RESPONSE_DECLINING        = 'DECLINING';
export const CALENDAR_RESPONSE_GOING            = 'GOI'; 
export const CALENDAR_RESPONSE_NOT_GOING        = 'NOT'; 
export const CALENDAR_RESPONSE_PENDING          = 'PEN'; 
export const CALENDAR_VIEW_DAY                  = 'DAY'; 
export const CALENDAR_VIEW_MONTH                = 'MONTH'; 
export const CALENDAR_VIEW_YEAR                 = 'YEAR'; 

// CALENDAR UPDATE MODE
export const UPD_MODE_CALENDAR_REMINDER         = 'CALENDAR_REMINDER';
export const UPD_MODE_CALENDAR_STATUS           = 'CALENDAR_STATUS';

// SEARCH UPDATE CASE
export const SEARCH_CASE_INCOME_LEVEL           = 'INCOMELEVEL';

// SHORT LIST STATUS
export const SHORT_LIST_YES                     = 'YES';
export const SHORT_LIST_NO                      = 'NO';

/* * * * * * * * * * * * * *
 * MOBAPP CONSTANTS
 * * * * * * * * * * * * * */
// CONNECTION ACTION
export const CONNECTION_ACTION_ACCEPT           = 'ACC';
export const CONNECTION_ACTION_ACTIVATE         = 'ACT';
export const CONNECTION_ACTION_ADD              = 'ADD';
export const CONNECTION_ACTION_BLOCK            = 'BLO';
export const CONNECTION_ACTION_DEACTIVATE       = 'DEA';
export const CONNECTION_ACTION_IGNORE           = 'IGN';
export const CONNECTION_ACTION_RECEIVE          = 'REC';
export const CONNECTION_ACTION_REMOVE           = 'REM';
export const CONNECTION_ACTION_SEND             = 'SEN';
export const CONNECTION_ACTION_UNBLOCK          = 'UNB';
export const CONNECTION_ACTION_UNLIKE           = 'UNL';

// CALL
export const CALL_ACTION_ACCEPT                 = 'ACC';
export const CALL_ACTION_COMPLETED              = 'COM'; 
export const CALL_ACTION_DECLINE                = 'DEC';
export const CALL_ACTION_END                    = 'END';
export const CALL_ACTION_MISSED                 = 'MIS';
export const CALL_STATUS_OUTBOUND_AUDIO         = 'Outbound Phone Call';
export const CALL_STATUS_INBOUND_AUDIO          = 'Inbound Phone Call';
export const CALL_STATUS_MISSED_AUDIO           = 'Missed Phone Call';
export const CALL_STATUS_OUTBOUND_VIDEO         = 'Outbound Video Call';
export const CALL_STATUS_INBOUND_VIDEO          = 'Inbound Video Call';
export const CALL_STATUS_MISSED_VIDEO           = 'Missed Video Call';
export const CALL_TYPE_AUDIO                    = 'AUD';
export const CALL_TYPE_VIDEO                    = 'VID';

// MESSAGE CENTER
export const COMMUNICATION_TYPE_AUDIO           = 'AUDIO';
export const COMMUNICATION_TYPE_CALL            = 'CALL';
export const COMMUNICATION_TYPE_CHAT            = 'CHAT';
export const COMMUNICATION_TYPE_NOTIFICATION    = 'NOTIFICATION';
export const COMMUNICATION_TYPE_VIDEO           = 'VIDEO';
export const MESSAGE_CENTER_CONTACT_UNKNOWN     = 'User';

// MEDIA
export const MEDIA_PICK_MODE_REGISTER           = 'REGISTER';
export const MEDIA_PICK_MODE_UPDATE             = 'UPDATE';

// USER FIELD
export const USER_FIELD_NOT_STATED              = 'Not stated';
export const USER_GENDER_ALL                    = 'ALL';
export const USER_GENDER_MALE                   = 'MAL';
export const USER_GENDER_FEMALE                 = 'FEM';
export const USER_GENDER_UNKNOWN                = 'UNK';
export const USER_MALE_PRONOUN                  = 'his';
export const USER_FEMALE_PRONOUN                = 'her';

// USER SUBSCRIPTION
export const USER_SUBSCRIPTION_REGULAR          = 'REG';
export const USER_SUBSCRIPTION_PRIVACY          = 'PRV';
export const USER_SUBSCRIPTION_ELITE            = 'ELT';
export const USER_SUBSCRIPTION_STATUS_END       = 'END';
export const USER_SUBSCRIPTION_STATUS_EXPIRED   = 'EXP';
export const USER_SUBSCRIPTION_REGULAR_ID       = 3;
export const USER_SUBSCRIPTION_TRIAL_ID         = 1;
export const USER_SUBSCRIPTION_TRIAL_PRICE      = 0;
export const USER_SUBSCRIPTION_TRIAL_END        = '1672502400'; //unix timestamp 01/01/2023

// HISTORY FILTER
export const HISTORY_FILTER_ALL                 = 'all';
export const HISTORY_FILTER_AUDIO               = 'audio';
export const HISTORY_FILTER_CHAT                = 'chat';
export const HISTORY_FILTER_EVENT               = 'event';
export const HISTORY_FILTER_NOTIFICATION        = 'notification';
export const HISTORY_FILTER_VIDEO               = 'video';

// TWILIO 
export const TWILIO_BASE_URL                    = 'https://api.twilio.com';
export const CHANNEL                            = 'CHANNEL_';
export const ROOM                               = 'ROOM_';
export const MESSAGE_RECEIVED                   = 'twilio.channel.new_message';

// Notification
export const NOTIF_SOUND                        = 'NOTIF_SOUND';
export const NOTIF_SILENT                       = 'NOTIF_SILENT';
export const NOTIF_SIGHT_SOUND                  = 'NOTIF_SIGHT_SOUND';
export const NOTIF_CUSTOMIZE                    = 'NOTIF_CUSTOMIZE';
export const NOTIF_MUTE_ALL                     = 'NOTIF_MUTE_ALL';
export const NOTIF_TYPE_SHORTLIST_ADDED         = 'SHO_ADD'
export const NOTIF_TYPE_CALENDAR_PREFIX         = 'CAL'
export const NOTIF_TYPE_PROF_VIEW_ADDED         = 'VIE_ADD'
export const NOTIF_TYPE_HEART_SENT              = 'HEA_SEN'

// NO ITEMS MESSAGES
export const BLOCKED_NO_PROFILES                = 'You have no Blocked Profiles';
export const EVENT_NO_UPCOMING_EVENTS           = 'You have no upcoming events';
export const MSG_NO_CALL_HISTORY                = 'No call/video history';
export const MSG_NO_INVITATIONS                 = 'No invitations';
export const MSG_NO_LIKED__PROFILES             = 'No one has liked your profile';
export const MSG_NO_MESSAGE_HISTORY             = 'No chat messages';
export const MSG_NO_NEAR_ME                     = 'No users with similar interests\nnear you at this time';
export const MSG_NO_NOTIFICATION_HISTORY        = 'No notifications';
export const MSG_NO_PHOTOS                      = 'No photos';
export const MSG_NO_PROFILE_HISTORY             = 'No history yet';
export const MSG_NO_UNANSWERED_MESSAGES         = 'You have no unanswered messages';
export const MSG_NO_CONVERSATION                = 'You have no conversation with the user yet';
export const MSG_NO_VIEWED_PROFILES             = 'You haven’t viewed anyone lately';
export const MSG_NO_VIEWED_YOUR_PROFILE         = 'No one has viewed your profile';
export const USER_NO_PROFILES                   = 'No Profiles to show';

// DEFAULT SEARCH
export const MY_IDEAL                           = 'MY IDEAL';

// TWILIO VIDEO & VOICE CALL STATE
export const CALL_STATE_ACCEPTED                = 'ACCEPTED';
export const CALL_STATE_CANCELED                = 'CANCELED';
export const CALL_STATE_CALLING                 = 'CALLING';
export const CALL_STATE_CONNECTED               = 'CONNECTED';
export const CALL_STATE_CONNECTING              = 'CONNECTING';
export const CALL_STATE_DECLINE                 = 'DECLINE';
export const CALL_STATE_DISCONNECTED            = 'DISCONNECTED';
export const CALL_STATE_PENDING                 = 'PENDING';
export const CALL_STATE_MISSED                  = 'MISSED';
export const AUDIO_CALL_DIALLED                 = 'AUD_DIA';
export const AUDIO_CALL_MISSED                  = 'AUD_MIS';

// TWILIO PHONE CALL OPERATOR
export const PHONE_CALL_CALLER                  = 'CALLER';
export const PHONE_CALL_RECEIVER                = 'RECEIVER';

// TWILIO NOTIFICATION TYPE
export const BLOCKLIST_ADDED                    = 'BLO_ADD';
export const BLOCKLIST_REMOVED                  = 'BLO_REM';
export const CALENDAR_INVITE_ACCEPTED           = 'CAL_ACC';
export const CALENDAR_INVITE_DECLINED           = 'CAL_DEC';
export const CALENDAR_INVITE_RECEIVED           = 'CAL_REC';
export const CALENDAR_INVITE_SENT               = 'CAL_SEN';
export const CALENDAR_INVITE_UPDATED            = 'CAL_UPD';
export const CHAT_REQUEST_UPDATE                = 'MES_UPD';
export const HEART_ACCEPTED                     = 'HEA_ACC';
export const HEART_RECEIVED                     = 'HEA_REC';
export const HEART_REMOVED                      = 'HEA_REM';
export const HEART_SENT                         = 'HEA_SEN';
export const HEART_UNLIKED                      = 'HEA_UNL';
export const MESSAGE_REQUEST_ACCEPTED           = 'MES_ACC';
export const MESSAGE_DISABLED                   = 'MES_DIS';
export const MESSAGE_REQUEST_RECEIVED           = 'MES_REC';
export const MESSAGE_REQUEST_SENT               = 'MES_SEN';
export const NEAR_ME_USER_UPDATE                = 'NEA_UPD';
export const PHONE_CALL_DISABLED                = 'PHO_DIS';
export const PHONE_CALL_REQUEST_ACCEPTED        = 'PHO_ACC';
export const PHONE_CALL_REQUEST_RECEIVED        = 'PHO_REC';
export const PHONE_CALL_REQUEST_SENT            = 'PHO_SEN';
export const PHONE_REQUEST_UPDATE               = 'PHO_UPD';
export const SHORTLIST_ADDED                    = 'SHO_ADD';
export const SHORTLIST_REMOVED                  = 'SHO_REM';
export const USER_NEAR_ME                       = 'USER_NEAR_ME';
export const VIDEO_CALL_ANSWERED                = 'VID_ANS';
export const VIDEO_CALL_DIALLED                 = 'VID_DIA';
export const VIDEO_CALL_DISABLED                = 'VID_DIS';
export const VIDEO_CALL_ENDED                   = 'VID_END';
export const VIDEO_CALL_MISSED                  = 'VID_MIS';
export const VIDEO_CALL_REJECTED                = 'VID_REJ';
export const VIDEO_CALL_REQUEST_ACCEPTED        = 'VID_ACC';
export const VIDEO_CALL_REQUEST_RECEIVED        = 'VID_REC';
export const VIDEO_CALL_REQUEST_SENT            = 'VID_SEN';
export const VIDEO_REQUEST_UPDATE               = 'VID_UPD';

// TWILIO VIDEO CALL ROLES
export const VIDEO_CALL_CALLER                  = 'CALLER';
export const VIDEO_CALL_RECEIVER                = 'RECEIVER';

// MY PROFILE DEFAULT
export const PROFILE_FEMALE_HEIGHT              = '501';
export const PROFILE_MALE_HEIGHT                = '506';

// TUTORIAL TYPE
export const TUTORIAL_USER_PROFILE              = 'userProfile';
export const TUTORIAL_SHORTLIST                 = 'shortlist';

// EMPTY CONTENT
export const NO_USERS_FOUND_TITLE               = 'No Users Found';
export const NO_USERS_FOUND_DESC                = `Patience grasshopper. \nYour Significant Other is out there.`;
export const NO_USERS_LIKED_ME_TITLE            = 'No One has Liked Your Profile';
export const NO_USERS_LIKED_ME_DESC             = 'Users who liked you will appear here.';
export const NO_USERS_VIEWED_ME_TITLE           = 'No One has Viewed Your Profile';
export const NO_USERS_VIEWED_ME_DESC            = 'Users who viewed you will appear here.';
export const NO_USERS_I_VIEWED_TITLE            = 'You Haven\’t Viewed Anyone Lately';
export const NO_USERS_I_VIEWED_DESC             = 'Users you viewed will appear here.';

export const EMPTY_CHAT                         = 'EMPTY_CHAT';
export const EMPTY_UNANSWERED_MESSAGES          = 'EMPTY_UNANSWERED_MESSAGES';
export const EMPTY_UPCOMING_EVENTS              = 'EMPTY_UPCOMING_EVENTS';
export const EMPTY_USERS_SEARCH_HOME            = 'EMPTY_USERS_SEARCH_HOME';
export const EMPTY_USERS_SHORTLIST_HOME         = 'EMPTY_USERS_SHORTLIST_HOME';

//const added 
export const NO_CALL_HISTORY_DESC               = 'The users who you called and who called you will appear here.'; 
export const NO_CALL_HISTORY_TITLE              = 'No Call/Video History'; 
export const NO_CHAT_HISTORY_DESC               = 'Users who you messaged and who messaged you will appear here.'; 
export const NO_CHAT_HISTORY_TITLE              = 'No Chat Messages'; 
export const NO_NOTIFICATION_DESC               = 'Notifications like communication requests, hearts, and calendar requests will appear here.'; 
export const NO_NOTIFICATION_HISTORY_TITLE      = 'No Notification Yet';
export const NO_INVITATION_DESC                 = 'You don\'t have event invitations. If you want to invite someone, click the Add Event and create an invitation.'

export const SOS_EMAIL                          = 'sample@gmail.com?subject=Concerns';
export const IMAGE_INVALID_LINK                 = 'https://s3-us-west-2.amazonaws.com/cdn.dev.sos.com/media/null';

export const STATUS_SUCCESS                     = 'success'
export const STATUS_LOADING                     = 'loading'
export const STATUS_FAIL                        = 'fail'
