import { makeStyles } from '@material-ui/core/styles';
import colors from '../../utils/colors';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    marginTop: '120px',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    textAlign: 'center',
    [theme.breakpoints.down("sm")]: {
      marginTop: '130px'
    },

    '& > .MuiGrid-root': {
      marginTop: theme.spacing(4),
    },
  },

  preloaderCard: {
    border: `1px dashed ${colors.PRIMARY}`,
    backgroundColor: colors.WHITE,
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colors.HOVER_DEFAULT
    },
    position: 'relative',
  },

  image: {
    // width: 'inherit',
    // [theme.breakpoints.up('xs')]: {
    //   height: 150,
    // },
    // [theme.breakpoints.up('sm')]: {
    //   height: 150,
    // },
    // [theme.breakpoints.up('md')]: {
    //   height: 200,
    // },
    // [theme.breakpoints.up('lg')]: {
    //   height: 200,
    // },
    backgroundColor: colors.PRELOADER_BACKGROUND,
    height: 0,
    borderRadius: 4,
    paddingTop: '150%',
  },

  preloaderContent: {
    bottom: 0,
    position: 'absolute',
    padding: 10, 
    height: 50,
    maxHeight: 50,
    alignItems: 'center',
    width: '90%',
    left: 2
    // padding: 10, 
    // height: 70,
    // maxHeight: 70,
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    // cursor: 'pointer',
    // '&:hover': {
    //   backgroundColor: colors.HOVER_DEFAULT
    // },
  },
  overlay: {
    bottom: 0,
    position: 'absolute',
    backgroundColor: 'white',
    opacity: 0.8,
    padding: 10, 
    height: 70,
    maxHeight: 70,
    width: '100%',
    // filter:'blur(4px)',
    backdropFilter: 'blur(1px)',
    borderRadius: 8
  },

  username: {
    fontWeight: 'bold',
  },

  shortlistGrid : {
    margin : 0
  }

}));

export default useStyles;