import React from 'react';
import { Card, CardContent, Link } from '@material-ui/core';
import { connect } from 'react-redux';
import Preloader from '../preloader';
import NO_UPCOMING_EVENTS from "../../assets/images/no_events.png";
import { PRELOADER_UPCOMING_EVENT_CARD, EVENT_NO_UPCOMING_EVENTS } from '../../utils/constants';
import { getFormattedDateTime, isDayBeforeOrAfterFromNow } from '../../utils/helper';
import Empty from '../empty';
import useStyles from './style';

const DashboardUpcomingEvents = (props) => {
	const classes = useStyles();
	const { eventList, minHeight, gotoPage, isLoading, profileClickCallback } = props;

	const callbackFunction = (userConnectionLength) => {
		profileClickCallback(userConnectionLength)
	}

	const populateUpcomingEvents = () => {
		if (!eventList) return <Empty image={NO_UPCOMING_EVENTS} title="Upcoming Events" description={EVENT_NO_UPCOMING_EVENTS}/>
		if (!eventList.length) return <Empty image={NO_UPCOMING_EVENTS} title="Upcoming Events" description={EVENT_NO_UPCOMING_EVENTS}/>

		const upcomingEvents = [];

		eventList.map((eventItem, parentIndex) => {
			
			const dateStartTimestamp = eventItem.meetup.dateStart;
			const isPending = eventItem.eventReceiver.status === "PEN";
			const isUpcomingEvent = isDayBeforeOrAfterFromNow(dateStartTimestamp, 'unix').isBefore;
			if (!(isPending && isUpcomingEvent)) return;

			const formattedDate = {
				date: getFormattedDateTime(dateStartTimestamp, "unix", "MMMM DD YYYY"),
				time: getFormattedDateTime(dateStartTimestamp, "unix", "hh:mm a"),
			};

			upcomingEvents.push(<Card className={classes.cardItem} key={parentIndex}>
				<div className={classes.dateWrapper}>
					<p>{formattedDate.date}</p>
				</div>
				<div className={classes.cardDetails}>
					<p>{eventItem.meetup.meetupName} with <a onClick={callbackFunction.bind(this, eventItem.userConnection)}> {eventItem.userConnection.user.userName} </a></p>
					<p>{`${formattedDate.time} | ${eventItem.meetup.eventPlace.length === 0 ? "No location" : eventItem.meetup.eventPlace}`}</p>
				</div>
			</Card>)
		})

		if (!upcomingEvents.length) return <Empty image={NO_UPCOMING_EVENTS} title="Upcoming Events" description={EVENT_NO_UPCOMING_EVENTS}/>;
		return upcomingEvents;
	}

	const preloader = () => {
		return [1,2,3].map(value => <Preloader type={PRELOADER_UPCOMING_EVENT_CARD} key={value} />)
	}

	return (
		<Card className={classes.card}>
			<CardContent style={{minHeight: `${minHeight || '100'}px`}} className={classes.cardContent}>
				<div className={classes.cardHeader}>
					<Link component='button' onClick={gotoPage}>
						<h3>Upcoming Events</h3>
					</Link>
				</div>
				<div>
					{
						isLoading
							? preloader()
							: populateUpcomingEvents()
					}
				</div>
			</CardContent>
		</Card>
	)
}

const mapStateToProps = (state) => {
  // console.log('usercard/index.js:mapStateToProps(state)');
  // console.log(state);
  return {
		viewedMeList : state?.Connection?.recentViewsList?.viewedMe,
		iViewedList  : state?.Connection?.recentViewsList?.iViewed,
  };
}

export default connect(mapStateToProps)(
  DashboardUpcomingEvents
);
