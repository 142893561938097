import { Box, Container, Grid, Typography } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
      <Typography
        component='div'
        role='tabpanel'
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
  );
}


export default TabPanel