import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../utils/colors';

const useStyles = makeStyles(theme => ({

  backdrop: {
    backgroundColor : 'rgba(0,0,0,0)'
  },

  cardContent: {
    maxHeight : 250, 
    overflow : 'scroll',
    padding : 0
  },

  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius : 10,
    boxShadow: theme.shadows[5],
    outline : 0,
    padding: theme.spacing(1, 1, 1),
    position: 'absolute',
  },

  modalPaperContents: {
    padding: theme.spacing(0, 3),
  },

  eventInfoRow: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    padding: 2
  },

  eventDetailsInput: {
    backgroundColor : 'rgba(0,0,0,0)',
    // paddingTop : 4
  },

  closeButtonContainer: {
    textAlign : 'right'
  },

  saveButtonContainer: {
    padding: theme.spacing(3, 0)
  },

  saveButtonGridItem: {
    '& > .MuiButton-root': {
      textTransform : 'none',
      borderRadius : 20,
    width : 200
    }
  },

  responseContainer: {
    alignItems : 'center',
    display : 'flex'
  },

  responseInput: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)'
    },
    '&:hover #edit-button': {
      opacity : 0
    },
  },

  date: {
    color : colors.WHITE,
    lineHeight : 1,
    fontWeight : 600
  },

  calendarInfoText: {
    marginLeft : 10
  },

  center: {
    alignItems : 'center'
  },

  errorMessage: {
    color : colors.RED,
    textAlign : 'center',
    padding : 10
  },

  connectBtn: {
    textTransform : 'none'
  },

  userConnectionIssue:{
    display : 'flex',
    flexDirection : 'column',
    alignItems: 'center'
  },

  responseOptions: {
    alignItems : 'center',
    display : 'flex', 
    marginLeft : 10
  },

  acceptedResponseIcon: {
    color : colors.LIGHT_GREEN
  },

  declinedResponseIcon: {
    color : colors.ERROR
  },

  responseText: {
    alignItems : 'center', 
    marginLeft : 5
  },

  noLocText: {
    display: 'flex',
    alignItems: 'center'
  }

}));

export default useStyles;