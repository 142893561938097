import React, { useEffect, useState } from 'react';
import { Box, Button, Slider, FormControlLabel, Grid, IconButton, Paper, Radio, RadioGroup, TextField, Typography, CircularProgress, Modal } from '@material-ui/core';
import { parseString } from 'xml2js';
import Geocode from "react-geocode";
import countryByAbbreviation from 'country-json/src/country-by-abbreviation.json';
import BackIcon from '@material-ui/icons/ArrowBack';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import useStyles from './style';
import DropDown from '../../../components/dropdown';
import Select from '../../../components/Select';
import {
  LOOK_UP_ALCOHOL,
  LOOK_UP_BODY_TYPE,
  LOOK_UP_EDUC_LEVEL,
  LOOK_UP_ETHNICITY,
  LOOK_UP_EXER_REGIMEN,
  LOOK_UP_EYE_COLOR,
  LOOK_UP_HAIR_COLOR,
  LOOK_UP_HEART,
  LOOK_UP_INCOME_LEVEL,
  LOOK_UP_KID_POLICY,
  LOOK_UP_POS_POLITICS,
  LOOK_UP_PREF_SMOKING,
  LOOK_UP_RATING,
  LOOK_UP_RELIG_BELIEF,
  MY_IDEAL,
  USER_GENDER_UNKNOWN,
  UPD_MODE_SEARCH_BASIC,
  STATUS_ACTIVE,
  ALERT_SEVERITY_SUCCESS,
  ALERT_SEVERITY_ERROR,
  TOAST_AXIOS_REQUEST_ERROR,
  UNITED_STATES,
  TOAST_SEARCH_UPDATED,
  HTTP_STATUS,
  LOOK_UP_USPS_ID
} from '../../../utils/constants'

import { lookupRequest, thirdPartyRequest } from '../../../service/requests';
import colors from '../../../utils/colors';
import { getAddress, handleHttpError } from '../../../utils/helper';
import lookupAction from '../../../redux/lookup/action';
import axios from 'axios';

const CancelToken   = axios.CancelToken;
let cancel;

const DEFAULT_ADDRESS_PAYLOAD = {
  label: '',
  value: '',
  code: ''
}

const NewSearch = (props) => {
  console.log('NewSearch()');

  const classes = useStyles();
  const { onBack, search, showAlert, searches, updateSearch, isSearchUpdated, isSearchUpdateError, userAddress, Lookup } = props;
  const { rating, heart, bodyType, hairColor, eyeColor, kidPolicy, educLevel, ethnicity, religBelief, exerRegimen, prefAlcohol, prefSmoking, posPolitics } = search.searchDetail;
  const { searchId, userId } = search;

  const [editSearch, toggleEditSearch] = useState(false);
  const [matchedCountries, setMatchedCountries] = useState([]);
  const [cityLookUpTrigger, setCityLookUpTrigger] = useState({});

  const countryNames = Lookup.lookUpCountry ? Lookup.lookUpCountry : [];
  const stateNames = Lookup.lookUpState ? Lookup.lookUpState : [];
  // const cityNames = Lookup.cityNames ? Lookup.cityNames : [];
  
  // const LookupState = props?.Lookup?.lookUpState ? props.Lookup.lookUpState : []; 
  // const cityNames = props?.Lookup?.cityNames ? props.Lookup.cityNames :  [];
  // const LookupCountry = props?.Lookup?.lookUpCountry ? props.Lookup.lookUpCountry : [];
  const [initializedLookupState, setInitializedLookupState] = useState(true);
  const [initializedLookupCity, setInitializedLookupCity] = useState(true);
  const [initializedLookupCountry, setInitializedLookupCountry] = useState(true);
  const [initialization, setInitialization] = useState(true);
  const [cityNames, setCityNames] = useState([])
  const [uspsID, setUspsID] = useState(null)
  const [cityStateLoading, setCityStateLoading] = useState(false)
  const [cityLoading, setCityLoading] = useState(false)
 

  const currentState = search.state !== '' ? search.state : userAddress.state;
  const currentCountry = search.country !== '' ? search.country : userAddress.country;
  const currentCity = search.city !== '' ? search.city : userAddress.city;
  const currentZipCode = search.zipCode !== '' ? search.zipCode : userAddress.zipCode


  const [state, setState] = useState({
    gender              : search.gender,
    ageFrom             : search.ageFrom,
    ageTo               : search.ageTo,
    travelTolerance     : search.travelTolerance,
    searchName          : search.searchName,
    isSearchNameError   : false,
    searchNameErrorDesc : '',
    zipCode             : search.zipCode !== '' ? search.zipCode : userAddress.zipCode,
    city                : search.city !== '' ? search.city : userAddress.city,
    stateAddress        : search.state !== '' ? search.state : userAddress.state,
    country             : search.country !== '' ? search.country : userAddress.country,
    isCountryError      : false,
    countryErrorDesc    : '',
    latitude            : search.latitude,
    longitude           : search.longitude,
    isModalOpen         : false,
    isLoading           : true,
  });

  const [selectedCountry, setSelectedCountry] = useState({
    label: '',
    value: '',
    code: ''
  })

  const [selectedState, setSelectedState] = useState(DEFAULT_ADDRESS_PAYLOAD)
  const [selectedCity, setSelectedCity] = useState(DEFAULT_ADDRESS_PAYLOAD)

  const {
    gender,
    ageFrom,
    ageTo,
    travelTolerance,
    searchName,
    isSearchNameError,
    searchNameErrorDesc,
    zipCode,
    isZipCodeError,
    zipCodeErrorDesc,
    city,
    isCityError,
    cityErrorDesc,
    stateAddress,
    isStateError,
    stateErrorDesc,
    country,
    isCountryError,
    countryErrorDesc,
    latitude,
    longitude,
    isModalOpen
  } = state;

  // useEffect(() => {
  //   if (isSearchUpdated)
  //     showAlert(ALERT_SEVERITY_SUCCESS, TOAST_SEARCH_UPDATED(searchName));

  //   if (isSearchUpdateError) 
  //     showAlert(ALERT_SEVERITY_ERROR, TOAST_AXIOS_REQUEST_ERROR); 

  // }, [isSearchUpdated, isSearchUpdateError]);

  useEffect(()=>{
    if(initialization){
      lookupRequest.getCredentialLookup(LOOK_UP_USPS_ID).then(response => {
        if (response?.data?.status?.code == HTTP_STATUS._200) {
          setUspsID(response?.data?.credential?.value)
        }
        
        
      }).catch(error => {
        handleHttpError({
          error,
          request: 'lookupRequest.getCredentialLookup::src/views/search/new-search'
        })
      });
      props.getLookUpCountry('/')
      props.getLookUpState('233')
      setInitialization(false);
    }
  },[initialization])

  useEffect(()=>{
    if(!initialization && isModalOpen) {
      `${currentCountry?.trim().toLowerCase()}` === `${UNITED_STATES.trim().toLowerCase()}` && setSelectedCountry({code : "US", label: UNITED_STATES, value : 233})
      if((currentZipCode !== null || currentZipCode !== '') && `${currentCountry?.label?.trim().toLowerCase()}` !== `${UNITED_STATES.trim().toLowerCase()}`) {
        getCityAndState({zipCode: currentZipCode, uspsID})
      }
    }
    if(!initialization && !isModalOpen) {
      setSelectedCountry(DEFAULT_ADDRESS_PAYLOAD)
      setSelectedState(DEFAULT_ADDRESS_PAYLOAD)
      setSelectedCity(DEFAULT_ADDRESS_PAYLOAD)
    }
  },[isModalOpen])



  useEffect(() => {
    Geocode.setApiKey(window.GEOCODING_API_KEY);
      Geocode.fromAddress(
        `${zipCode} ${city} ${stateAddress} ${country}` 
      ).then(response => {
        console.log('response : ', response);
        const latitude = response.results[0].geometry.location.lat;
        const longitude = response.results[0].geometry.location.lng;

        handleSearchUpdate({
          zip_code  : search.zipCode,
          city      : search.city,
          state     : search.state,
          country   : search.country,
          latitude  : latitude,
          longitude : longitude
        });

        setState({
          ...state,
          city,
          stateAddress,
          zipCode,
          country,
          latitude,
          longitude,
        });
      }).catch(error => {
        console.warn('error : ', error);
      });
  }, [userAddress, state.gender])

  useEffect(() => {
    console.log('zipcode.useEffect()');
    if (zipCode) {
      if (zipCode.length === 5 && selectedCountry?.label?.toLowerCase() == UNITED_STATES.toLowerCase()) { 
        getCityAndState({zipCode, uspsID});
      }
    }
  }, [zipCode]);

  useEffect(()=>{
    if(!initialization && selectedCountry?.label?.toLowerCase() == UNITED_STATES.toLowerCase()) {
      setCityLoading(true)
      lookupRequest.getCities(selectedState.value).then(responseCities => {
        const cities = responseCities?.data?.cities
        setCityNames(cities)
      }).catch(error => { 
        console.log(error)
        handleHttpError({
          error,
          request: 'thirdPartyRequest.getCityState::src/views/search/new-search'
        })
      }).finally(()=>{setCityLoading(false)})
    }
  },[selectedState])


  const handleSearchUpdate = (updatedSearch) => {
    console.log('NewSearch.handleSearchUpdate()');

    updateSearch({
      searchId,
      search: {
        search_name       : searchName,
        status            : STATUS_ACTIVE,
        gender            : gender,
        age_from          : ageFrom,
        age_to            : ageTo,
        travel_tolerance  : travelTolerance,
        user_id           : userId,
        upd_mode          : UPD_MODE_SEARCH_BASIC,
        zip_code          : zipCode,
        city              : city,
        state             : stateAddress,
        country           : country,
        latitude          : latitude,
        longitude         : longitude,
        ...updatedSearch
      }
    });
  }

  const getCityAndState = ({zipCode = currentZipCode, uspsID}) => {
    cancel && cancel();
    setCityStateLoading(true)
    const zipCodeXML = `<CityStateLookupRequest USERID="${uspsID}"><ZipCode ID="0"><Zip5>${zipCode}</Zip5></ZipCode></CityStateLookupRequest>`

    axios.get(
      'https://production.shippingapis.com/ShippingAPITest.dll',
      {
        params: {
          API : 'CityStateLookup', 
          XML : zipCodeXML
        },
        headers : {
          'Content-Type'  : 'text/xml'
        },
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        })
      }
    ).then(response => {
      parseString(response.data, (error, result) => {
        console.log('result : ', result);
        console.log('error  : ', error);
        if (result.CityStateLookupResponse.ZipCode[0].Error == undefined) { 

          const selectedState = stateNames.filter((state)=>state.code == result.CityStateLookupResponse.ZipCode[0].State[0])[0] || null
          if(!selectedState) {
            handleHttpError({
              error,
              request: 'thirdPartyRequest.getCityState::src/views/search/new-search'
            })
          }
          
          lookupRequest.getCities(selectedState.id).then(responseCities => {
            const cities = responseCities?.data?.cities
            const selectedCity = cities.filter(city=> city.name.toLowerCase() == result.CityStateLookupResponse.ZipCode[0].City[0].toLowerCase())[0] || null 
            setCityNames(cities)
            if(selectedState && selectedCity){
             setSelectedState({label: selectedState.name, value: selectedState.id })
             setSelectedCity({label: selectedCity.name, value: selectedCity.id})
            }


          }).catch(error => { 
            console.log(error)
            handleHttpError({
              error,
              request: 'thirdPartyRequest.getCityState::src/views/search/new-search'
            })
          })
        }
    })
    }).catch(error => { 
      console.log(error)
      handleHttpError({
        error,
        request: 'thirdPartyRequest.getCityState::src/views/search/new-search'
      })
    }).finally(()=>{ setCityStateLoading(false) })

  }

  const handleGenderChange = event => {
    setState({
      ...state,
      gender: event.target.value
    });

    handleSearchUpdate({
      gender : event.target.value,
    });
  };

  const handleAgeRangeChange = (event, newValue) => {
    setState({
      ...state,
      ageFrom: newValue[0],
      ageTo: newValue[1],
    });
  };

  const handleInputChange = event => {

    let value = event.target.value;
    if (event.target.name == 'zipCode') {
      value = value.replace(/[^0-9]/g, '');

      if (value.length > 5)
        return
        setState({
          ...state,
          [event.target.name]: value,
        });
        return
    }

    setState({
      ...state,
      [event.target.name]: event.target.value
    });
  };

  const searchNameChange = event => {

    let value = event.target.value;
    console.log('event.target.name : ', event.target.name);

    setState({
      ...state,
      searchName : event.target.value
    });
  };

  const handleTravelToleranceChange = (event, newValue) => {
    setState({
      ...state,
      travelTolerance: newValue,
    });
  };

  const toggleEditSearchName = () => {
    toggleEditSearch(prevEditSearch => !prevEditSearch);
    const isSearchNameExists = searches.filter(search => search.searchId !== searchId).some(search => search.searchName.toUpperCase() === searchName.toUpperCase());
    setState({
      ...state,
      isSearchNameError   : searchName === '' || isSearchNameExists ? true : false,
      searchNameErrorDesc : searchName === '' ? 'Required.' : isSearchNameExists ? 'Duplicate search name.' : ''
    });
    if (searchName === '' || isSearchNameExists) {
      return
    }
    handleSearchUpdate({
      search_name : searchName
    });
  }

  const toggleAddressModal = () => {
    setState({
      ...state,
      isModalOpen: !isModalOpen,
    })
  }

  const updateAge = () => {
    handleSearchUpdate({
      age_from  : ageFrom,
      age_to    : ageTo
    });
  }

  const updateAddress = () => {
    Geocode.setApiKey(window.GEOCODING_API_KEY);

    if ((city || stateAddress || zipCode || country) && country == UNITED_STATES) {
      Geocode.fromAddress(
        `${zipCode} ${city} ${state} ${country}` 
      ).then(response => {
        console.log('response : ', response);

        const latitude = response.results[0].geometry.location.lat;
        const longitude = response.results[0].geometry.location.lng;

        handleSearchUpdate({
          zip_code  : zipCode,
          city      : city,
          state     : stateAddress,
          country   : country,
          latitude  : latitude,
          longitude : longitude
        });

        setState({
          ...state,
          city,
          stateAddress,
          zipCode,
          country,
          latitude,
          longitude,
          isModalOpen: !isModalOpen
        });
      }).catch(error => {
        console.warn('error : ', error);
      });
    } else {
      handleSearchUpdate({
        zip_code  : zipCode,
        city      : city,
        state     : stateAddress,
        country   : country,
        latitude  : null,
        longitude : null
      });

      setState({
        ...state,
        city,
        stateAddress,
        zipCode,
        country,
        latitude : null,
        longitude : null,
        isModalOpen: !isModalOpen
      });
    }
  }

  const updateTravelTolerance = () => {
    handleSearchUpdate({
      travel_tolerance : travelTolerance
    });
  }

  const selectChange = (field, e)=>{
    if(field === 'country'){
      setSelectedCountry(e)
      setSelectedState(DEFAULT_ADDRESS_PAYLOAD)
      setSelectedCity(DEFAULT_ADDRESS_PAYLOAD)
      setState({
        ...state,
        city: '',
        stateAddress: '',
        zipCode: '',
      })
    }

    if(field === 'stateAddress') {
      setSelectedState(e)
      setSelectedCity(DEFAULT_ADDRESS_PAYLOAD)
      setCityNames([])
    }

    if(field === 'city') {
      setSelectedCity(e)
    }
  }


  const isAddressValid = () => {
    if(`${selectedCountry?.label?.trim().toLowerCase()}` === `${UNITED_STATES.trim().toLowerCase()}` && selectedState.label !== DEFAULT_ADDRESS_PAYLOAD.label && selectedCity.label !== DEFAULT_ADDRESS_PAYLOAD.label && state.zipCode !== '') {
      return true
    } else if(state.zipCode !== '' && state.stateAddress && state.city && selectedCountry.label !== ''){
      return true
    }
    return false
  }



  return (
    false ? 
      <div className='text-center'>
        <CircularProgress/>
      </div>
    :
      <Grid container justify="center" spacing={5}>
        <Grid item xs={12}> 
        <Box style={{display: 'flex', flexDirection: 'column'}}>
        <Box className={classes.container}>
            <Button color="primary" variant="contained" onClick={onBack}><BackIcon/>Back</Button>
            <div className={classes.searchNameHeader}>
              {
                editSearch ?
                  <TextField
                    value={searchName}
                    onChange={searchNameChange}
                    variant="outlined"
                    size="small"
                    required
                    error={isSearchNameError}
                    helperText={searchNameErrorDesc}
                  />
                :
                  <Box>
                    <Typography variant="h5" color="textPrimary" className={classes.searchName}>
                      {searchName}
                    </Typography>
                    <Typography variant="caption" style={{color : 'red'}}>{searchNameErrorDesc}</Typography>
                  </Box>
              }
              {
                search.searchName.toUpperCase() !== MY_IDEAL &&
                  <Box color={colors.GREEN} onClick={toggleEditSearchName} className={classes.editSearchName}>
                    {
                      editSearch ? 
                        <CheckRoundedIcon/>
                      :
                        <EditOutlinedIcon/>
                    }
                  </Box>
              }
            </div>
            <Button><BackIcon/>Back</Button>
          </Box>
          <Typography variant="h5" color="textPrimary" style={{ alignSelf: 'center', fontWeight: 'bold' }}>
                      {'I am Searching for:'}
          </Typography>
        </Box>
         
        </Grid>
        <Grid item xs={12} md={5}>
          <Box className={classes.form}>
            <RadioGroup aria-label="position" className={classes.radioGroup} name="position" value={gender} onChange={handleGenderChange} row>
              <Typography color="textPrimary" variant="subtitle2">
                Gender
              </Typography>
              <FormControlLabel
                value="MAL"
                control={<Radio color="primary" size="small" />}
                label="Male"
                labelPlacement="end"
              />
              <FormControlLabel
                value="FEM"
                control={<Radio color="primary" size="small" />}
                label="Female"
                labelPlacement="end"
              />
            </RadioGroup>

            <Box>
              <Typography color="textPrimary" variant="subtitle2">Age</Typography>
              <Typography color="textPrimary" variant="subtitle2">{`${ageFrom} - ${ageTo}`}</Typography>
            </Box>
            <Slider
              value={[ageFrom, ageTo]}
              onChange={handleAgeRangeChange} 
              onChangeCommitted={updateAge}
              aria-labelledby="range-slider"
              valueLabelDisplay="auto"
              marks={[
                {
                  value: 25,
                  label: '25',
                },
                {
                  value: 50,
                  label: '50',
                },
                {
                  value: 75,
                  label: '75',
                },
                {
                  value: 100,
                  label: '100',
                }
              ]}
              min={18}
              max={100}
            />

            <Typography color="textPrimary" variant="subtitle2">Location</Typography>
            <Box>
              {
                country ? 
                  <Typography color="primary" variant="subtitle1">{getAddress({ city: currentCity, state: currentState, country: currentCountry, zipCode: currentZipCode})}</Typography>
                :
                  <Typography color="primary" variant="subtitle1">Add</Typography>
              }
              <IconButton color="primary" onClick={toggleAddressModal}><EditOutlinedIcon/></IconButton>
            </Box>
            
            <Box>
              <Typography color="textPrimary" variant="subtitle2">Travel Tolerance</Typography>
              <Typography color="textPrimary" variant="subtitle2">{`${travelTolerance} ${travelTolerance > 1 ? 'miles' : 'mile'}`}</Typography>
            </Box>
            <Slider
              value={travelTolerance}
              onChange={handleTravelToleranceChange}
              onChangeCommitted={updateTravelTolerance}
              valueLabelDisplay="auto"
              aria-labelledby="continuous-slider"
              marks={[
                {
                  value: 50,
                  label: '50 miles',
                },
                {
                  value: 150,
                  label: '150 miles',
                },
                {
                  value: 250,
                  label: '250 miles',
                },
              ]}
              min={1}
              max={250}
            />

            <DropDown type={LOOK_UP_RATING} value={rating} />
            <DropDown type={LOOK_UP_HEART} value={heart} />
            <DropDown type={LOOK_UP_BODY_TYPE} value={bodyType} />
            <DropDown type={LOOK_UP_HAIR_COLOR} value={hairColor} />
            <DropDown type={LOOK_UP_EYE_COLOR} value={eyeColor} />
          </Box>
        </Grid>
        <Grid item md={1} ></Grid>
        <Grid item xs={12} md={5} className={classes.form}>
          <DropDown type={LOOK_UP_KID_POLICY} value={kidPolicy} />
          <DropDown type={LOOK_UP_EDUC_LEVEL} value={educLevel} />
          <DropDown type={LOOK_UP_ETHNICITY} value={ethnicity} />
          <DropDown type={LOOK_UP_RELIG_BELIEF} value={religBelief} />
          <DropDown type={LOOK_UP_EXER_REGIMEN} value={exerRegimen} />
          {/* <DropDown type={LOOK_UP_INCOME_LEVEL} value={search.incomeLevel} isSingle={true} /> */}
          <DropDown type={LOOK_UP_ALCOHOL} value={prefAlcohol} />
          <DropDown type={LOOK_UP_PREF_SMOKING} value={prefSmoking} />
          <DropDown type={LOOK_UP_POS_POLITICS} value={posPolitics} />
        </Grid>
        <Grid item md={1} ></Grid>
        <Modal
          open={isModalOpen}
          onClose={toggleAddressModal}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <Paper className={classes.paper}>
              <Box className={classes.box}>
                <Typography variant="subtitle1" className={`text-center`} color="textPrimary">
                  Location
                </Typography>
                  <Select
                    options={countryNames.map((country)=>{
                      return {
                        label: country.name,
                        value: country.id,
                        code: country.code,
                      }
                    })}
                    // isLoading={state.isLoading}
                    className={classes.selectBox}
                    inputProps={{
                      placeholder:"Select Country",
                      onChange:(e)=>{
                        selectChange('country', e)
                      },
                      value: selectedCountry.value !== '' ? selectedCountry : ''
                    }}
                  />
                  <TextField
                    label="Postal Code"
                    name="zipCode"
                    value={zipCode || ''}
                    onChange={handleInputChange}
                    className={classes.textField}
                    variant="outlined"
                    size="small"
                    maxLength={5}
                  />
                  {`${selectedCountry?.label?.trim().toLowerCase()}` !== `${UNITED_STATES.trim().toLowerCase()}` ? <>
                    <TextField
                      label="State"
                      name="stateAddress"
                      value={stateAddress || ''}
                      onChange={handleInputChange}
                      className={classes.textField}
                      variant='outlined'
                      size='small'
                    />
                    <TextField
                      label="City"
                      name="city"
                      value={city || ''}
                      onChange={handleInputChange}
                      className={classes.textField}
                      variant='outlined'
                      size='small'
                    />
                  </> : <>
                    <Select
                      options={stateNames.map((state)=>{
                        return {
                          label: state.name,
                          value: state.id,
                        }
                      }) || []}   
                      className={classes.selectBox}
                      isLoading={cityStateLoading}
                      inputProps={{
                        placeholder:"Select State",
                        onChange:(e)=>{
                          selectChange('stateAddress', e)
                          setCityLookUpTrigger({value: e.value})
                        },
                        value: selectedState.value !== '' ?  selectedState : ''
                      }}
                    />
                    <Select
                       options={cityNames.map((city)=>{
                        return {
                          label: city.name,
                          value: city.id,
                        }
                      }) || []
                    }
                      inputProps={{
                        value: selectedCity.value !== ''? selectedCity: '',
                        placeholder:"Select City",
                        onChange:(e)=>{
                          selectChange('city', e)
                        }
                      }}
                      className={classes.selectBox}
                      isLoading={cityStateLoading || cityLoading}
                     /> 
                    </>
                  }
                <Button
                  disabled={cityStateLoading || cityLoading || !isAddressValid()}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={updateAddress}
                >
                  Save
                </Button>
              </Box>
          </Paper>
        </Modal>
      </Grid>      
  )
}

export default NewSearch;