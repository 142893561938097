import { all, put, takeEvery, select } from 'redux-saga/effects';

import action              from './action';
import notificationAction  from '../notification/action';
import { calendarRequest } from '../../service/requests';
import { HTTP_STATUS } from '../../utils/constants';
import { handleHttpError } from '../../utils/helper';

/////////////////////////
// reqCalendarGetEvents()
/////////////////////////
function* reqCalendarGetEvents({ payload }) {
  const { connection_user_id, date_start, date_end } = payload;
  const getCalendar = state => state.Calendar;
  const Calendar    = yield select(getCalendar);
  
  try {
    console.log('reqCalendarGetEvents()');

    const getUserSession = state => state.Session;
    const User           = yield select(getUserSession);
    
    const res = yield calendarRequest
      .getCalendarEvents(
        {
          connection_user_id: connection_user_id,
          date_start: date_start,
          date_end: date_end,
        },
        User.user.user.userId
      ).catch(error => {
        handleHttpError({
          error,
          request: 'calendarRequest.getCalendarEvents::src/redux/connection'
        })
      });

    if (res) {
      if (res?.data?.status?.code == HTTP_STATUS._200 && res?.data?.events.length != 0) {
        let unseenCalendarEventCount = 0;

        res.data.events.map((item) => {
          const { userId, isSeen } = item.eventReceiver;
            if ((userId === User.user.userId) && (!Boolean(isSeen))) {
              unseenCalendarEventCount = unseenCalendarEventCount + 1
            }
        });
        
        yield put({
          type    : notificationAction.NOTIFICATION_CALENDAR_UPDATE,
          payload : unseenCalendarEventCount
        });

        yield put({
          type    : action.CALENDAR_GET_EVENTS_SUCCESS,
          payload : res.data.events
        });
      } else {
        if (Calendar.eventList.length == 0) {
          yield put({
            type    : action.CALENDAR_GET_EVENTS_ERROR,
            payload : []
          });
        }
      }
    } else {
      if (Calendar.eventList.length == 0) {
        yield put({
          type    : action.CALENDAR_GET_EVENTS_ERROR,
          payload : []
        });
      }
    }
  } catch (error) {
    if (Calendar.eventList.length == 0) {
      yield put({
        type    : action.CALENDAR_GET_EVENTS_ERROR,
        payload : []
      });
    }
  }
}

export default function* rootSaga() {
  yield all([takeEvery(action.CALENDAR_GET_EVENTS,  reqCalendarGetEvents)]);
}
