import { makeStyles } from '@material-ui/core/styles';
import colors from '../../utils/colors';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100%',
  },

  gridContainer: {
    height: '100%',
  },

  // Modal
  paper: {
    left: 'calc(50% - 200px)',
    top: '50%',
    transform: 'translateY(-50%)',
    position: 'absolute',
    width: 400,
    backgroundColor: colors.BACKGROUND_COLOR,
    boxShadow: theme.shadows[5],
  },

  preference: {
    // alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',

    '& > .MuiBox-root': {
      // border: 'solid 1px red',
    },

    '& > .MuiBox-root:last-of-type': {
      display: 'flex',
      justifyContent: 'space-around',
      padding: theme.spacing(5, 0),
      width: '100%',
      
      '& > .MuiBox-root': {
        alignSelf: 'center'
      }
    }
  },

  progressContainer: {
    margin: theme.spacing(4),

    '& > .MuiTypography-root': {
      marginTop: theme.spacing(1)
    },
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flex: 1,
    width: '100%',

    '& .MuiBox-root': {
      // border: 'solid 1px red',
    }
  },

  // STEPPER
  stepper: {
    display: 'flex',
    backgroundColor: 'unset',
  },

}));

export default useStyles;