import React, { useState, useEffect }   from 'react';
import { 
  Box, 
  Button, 
  Typography, 
  Grid, 
  CircularProgress,
  TextField 
   }                                    from '@material-ui/core';
import PinInput                         from 'react-pin-input';

import useStyles                        from './style';
import { userRequest }                  from '../../../service/requests';
import { ALERT_SEVERITY_SUCCESS, 
  ALERT_SEVERITY_ERROR, 
  UPD_MODE_USER_REFERRED_BY, 
  TOAST_REFERRAL_SUCCESS, 
  TOAST_REFERRAL_ERROR, 
  HTTP_STATUS}                from '../../../utils/constants';
import referral_code_icon               from '../../../assets/images/referral_code_icon.png';
import { handleHttpError } from '../../../utils/helper';


const ReferralCode = (props) => {
  const classes = useStyles();
  const { showAlert, next, user, updateUserField } = props;
  const [ code, setCode ] = useState('');

  useEffect(() => {
		const queryParams = new URLSearchParams(window.location.search);
    const referralCode = queryParams.get('referral_code');
    console.log('referralCode:',referralCode);
    setCode(referralCode);
	}, [])

  const verifyCode = () => {
    console.log('ReferralCode.verifyCode()');
    return userRequest.verifyReferredByCode({
      referral_code : code,
      user_id       : user.userId,
    }).catch(error => {
      handleHttpError({
        error,
        request: 'userRequest.verifyReferredByCode::src/views/registration/referral-code'
      })
    });
  }

  const updateReferredBy = () => {
    console.log('ReferralCode.updateReferredBy()');
    return userRequest.updateUser({
      user_id           : user.userId,
      upd_mode          : UPD_MODE_USER_REFERRED_BY,
      who_updated       : user.userId,
      conf_code         : code
    }).then(updateUserResponse => {
        if(updateUserResponse?.data?.status?.code != HTTP_STATUS._200){
          let { status } = updateUserResponse?.data;
          throw new Error(`${status?.code} ${status?.description}`);
        }
        updateUserField(updateUserResponse?.data?.userDetail?.userField);
        showAlert(ALERT_SEVERITY_SUCCESS, TOAST_REFERRAL_SUCCESS);
        handleNext();
      }).catch(error => {
        handleHttpError({
          error,
          request: 'userRequest.updateUser::src/views/registration/referral-code'
        })
      });
  }

  const submit = async () => {
    console.log('ReferralCode.submit()');
    const verifyResponse = await verifyCode();
    if(verifyResponse?.data?.code == HTTP_STATUS._200) {
      await updateReferredBy();
    }else{
      showAlert(ALERT_SEVERITY_ERROR, TOAST_REFERRAL_ERROR);
     }
  }

  const handleNext = () => {
    next();
  }

  return (
    <Grid item md={7} className={classes.verifyReferral}>
      <Box className={classes.container}>
        <Typography 
          variant     = "h6" 
          color       = "primary" 
          className   = {[ `text-bold text-center mb-20`, classes.margin ]}>
          Did a friend refer you?
        </Typography>
        <img src={referral_code_icon} className={classes.margin}/>
        <TextField
          inputProps  = { { className : classes.eventDetailsInput, maxLength: 6 } } 
          placeholder = 'Enter their referral code'
          onChange    = { e => { setCode(e.target.value.toUpperCase()) } }
          className   = { classes.textField }
          variant     = "outlined"
          size        = "small"
          value       = { code }
        />
      </Box>
      <Button 
        type          = "submit" 
        variant       = "contained" 
        className     = { `btn-min-width` } 
        color         = "primary" 
        onClick       = { code ? submit : handleNext }>
        NEXT
      </Button>
    </Grid>
  )

  
}

export default ReferralCode;