import React, { useState, useEffect } from 'react';
import { Box, Button, CircularProgress, Grid, List, ListItem, ListItemIcon, ListItemText, Modal, TextField, Typography } from '@material-ui/core';

import useStyles from './style';
import Title from '../../../components/title';

import LinkIcon from '@material-ui/icons/Link';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';

import { ALERT_SEVERITY_SUCCESS, TOAST_REFERRAL_LINK_COPIED_SUCCESS, REFER_MESSAGE } from '../../../utils/constants';
import { userRequest } from '../../../service/requests';
import { handleHttpError } from '../../../utils/helper';

const ReferralLinkModal = (props) => {
	const { isOpen, copyReferralLink, closeCopyReferralModal, isReferralCodeFetching, referralLink } = props;
	const classes = useStyles();

	return (
		<Modal
			aria-labelledby='copy-referral-link'
			aria-describedby='copy-referral-link-description'
			open={isOpen}
			onClose={closeCopyReferralModal}
		>
			<div className={classes.modalPaper}>
				<Grid container direction='column' spacing={3}>
					<Grid item>
						<Typography variant='subtitle2' color='textPrimary' className={`text-bold`}>
              Here's your referral link
            </Typography>
					</Grid>
					{
						isReferralCodeFetching ?
						<Grid item className={classes.loading}>
							<CircularProgress color="primary" size={24}/>
						</Grid>
						:
						<Grid item>
							<TextField 
								id='referralLink'
								name='referralLink'
								fullWidth 
								value={referralLink}
								disabled
							/>
						</Grid>
					}
				</Grid>
				<Grid container alignItems='flex-end' justify='flex-end' spacing={3}>
					<Grid item>
						<Button color='primary' variant='contained' disabled={isReferralCodeFetching} onClick={copyReferralLink}>Copy</Button>
					</Grid>
				</Grid>
			</div>
		</Modal>
	)
}

const ReferAFriend = (props) => {
	const classes = useStyles();
	const { user, showAlert } = props;
	
	const [isCopyReferralModalOpen, setCopyReferralModalOpen] = useState(false);
	const [isReferralCodeFetching, setReferralCodeFetching] = useState(true);
	const [referralLinkElement, setReferralLinkElement] = useState(undefined);
	const [referralLink, setReferralLink] = useState(`${window.location.origin}/refer?code=`);
	
	const copyReferralLink = () => {
		let storeContentEditable = referralLinkElement.contentEditable;
		let storeReadOnly = referralLinkElement.readOnly;
		const selected =
				document.getSelection().rangeCount > 0 
						? document.getSelection().getRangeAt(0)
						: false;

		document.execCommand('copy');
		document.body.removeChild(referralLinkElement);
		
		if (selected) {
			document.getSelection().removeAllRanges(); 
			document.getSelection().addRange(selected);
		}

		referralLinkElement.contentEditable = storeContentEditable;
		referralLinkElement.readOnly = storeReadOnly;

		setCopyReferralModalOpen(false);
		showAlert(ALERT_SEVERITY_SUCCESS, TOAST_REFERRAL_LINK_COPIED_SUCCESS);
	}

	const openReferralLinkModal = () => {
		setReferralLinkElement(document.createElement('textarea'));
		setCopyReferralModalOpen(true);
	}

	useEffect(() => {
		if (!isReferralCodeFetching) {
			if (referralLinkElement) {
				referralLinkElement.value = referralLink;
				referralLinkElement.contentEditable = true;
				referralLinkElement.readOnly = false;
				referralLinkElement.setAttribute('readonly', false);
				referralLinkElement.setAttribute('contenteditable', true);
				referralLinkElement.style.position = 'absolute';
				referralLinkElement.style.left = '-9999px'; 
				document.body.appendChild(referralLinkElement);
				referralLinkElement.select();
				referralLinkElement.setSelectionRange(0, 999999);
			}
		}
	}, [isReferralCodeFetching, referralLinkElement])

	window.fbAsyncInit = () => {
		window.FB.init({
				appId   : process.env.REACT_APP_FACEBOOK_APP_ID,
				status  : true,
				xfbml   : true,
				version : 'v2.9'
		});
	}

	const shareToFacebook = () => {
		window.FB.ui({
			method: 'share',
			// TODO: replace href with actual URL
			href: 'https://local-ogp.firebaseapp.com/site/BtkVFpYWVXlORLAgP273'
		}, (response) => {
			console.log('response : ', response);
		});
	}

	useEffect(() => {
		userRequest.getReferralCode({
			user_id : user.userId
		}).then(response => {
			console.log('response : ', response);

			setReferralLink(referralLink + response.data.referral.confCode);
			setReferralCodeFetching(false);
		}).catch(error => {
			handleHttpError({
				error,
				request: 'userRequest.getReferralCode::src/views/settings/refer-a-friend'
			})
		});
	}, []);

  return (
    <Box className={classes.content}>
      <Title title='Refer a Friend' />
			<Grid container direction='column' alignItems='center' justify='center' spacing={3}>
				<Grid item>
					<Typography variant='h5' color='textPrimary' align='center' className={classes.bold}>
						S.O.Search is more fun with friends
					</Typography>
				</Grid>
				<Grid item>
					<Typography variant='h6' color='textPrimary' align='center'>
						Send invites to your friends
					</Typography>
				</Grid>
				<Grid item className={classes.buttonGrid}>
					<List aria-label='main referral buttons'>
						<ListItem button className={classes.copyLinkButton} onClick={openReferralLinkModal}>
							<ListItemIcon>
								<LinkIcon className={classes.shareIcon} />
							</ListItemIcon>
							<ListItemText primary='Copy Referral Link' className={classes.buttonText} />
						</ListItem>
						<ListItem button className={classes.facebookButton} onClick={shareToFacebook}>
							<ListItemIcon>
								<FacebookIcon className={classes.shareIcon} />
							</ListItemIcon>
							<ListItemText primary='Share Referral Link to Facebook' className={classes.buttonText} />
						</ListItem>					
						<ListItem button className={classes.linkedInButton}>
							<ListItemIcon>
								<LinkedInIcon className={classes.shareIcon} />
							</ListItemIcon>
							<ListItemText primary='Share Referral Link to LinkedIn' className={classes.buttonText} />
						</ListItem>
						<ListItem button component='a' className={classes.twitterButton} href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(REFER_MESSAGE(referralLink))}`} target='_blank'>
							<ListItemIcon>
								<TwitterIcon className={classes.shareIcon} />
							</ListItemIcon>
							<ListItemText primary='Tweet Referral Link to Twitter' className={classes.buttonText} />
						</ListItem>				
					</List>
				</Grid>
			</Grid>
			<ReferralLinkModal 
				isOpen={isCopyReferralModalOpen} 
				closeCopyReferralModal={() => setCopyReferralModalOpen(false)} 
				copyReferralLink={copyReferralLink} 
				referralLink={referralLink} 
				isReferralCodeFetching={isReferralCodeFetching} 
			/>
    </Box>
  )
}

export default ReferAFriend;