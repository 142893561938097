import React, { useState, useEffect } from 'react';

import moment from 'moment';

import CalendarView, { EventDetails } from '../../calendar';

import { ADD_EVENT } from '../../../utils/constants';
import { Box } from '@material-ui/core';

const Calendar = (props) => {
	const { eventList, getCalendarEvents, userConnection, userId, userName } = props;
	const [markedDates, setMarkedDates] = useState([]);
	const [selectedEvent, setSelectedEvent] = useState({});
	const [isEventDetailsModalOpen, setEventDetailsModalOpen] = useState(false);
	const initialEventDetails = {
    userId,
    cUserName : userName,
    user : userConnection.user,
    username : userConnection.user.userName,
    eventName : '',
    dateTime : new Date,
    location : '',
    reminder : 0,
  };
  const [eventDetails, setEventDetails] = useState(initialEventDetails);

	const handleChangeEventDetails = (eventInfo, value) => {
		setEventDetails(prevEventDetails => ({
			...prevEventDetails,
			[eventInfo] : value
		}))
  }
	
	const handleSelectEvent = (event) => {
    setSelectedEvent(event);
	}
	
	const handleSelectSlot = ({ start }) => {
		handleChangeEventDetails('dateTime', start, setEventDetails);
		setEventDetailsModalOpen(true);
  }
	
	return (
		<Box p={5} boxShadow={2} style={{borderRadius: 10, backgroundColor: '#ffffff'}}>
			<CalendarView 
				{...props}
				markedDates={[]}
				selectedEvent={{}}
				handleSelectSlot={handleSelectSlot}
				handleSelectEvent={handleSelectEvent}
				setMarkedDates={setMarkedDates}
			/>	
			{/* <EventDetails
				{...props} 
				initialEventDetails={initialEventDetails}
				isEventDetailsModalOpen={isEventDetailsModalOpen} 
				setEventDetailsModalOpen={setEventDetailsModalOpen} 
				eventDetails={eventDetails} 
				setEventDetails={setEventDetails}
				handleChangeEventDetails={handleChangeEventDetails}
				mode={ADD_EVENT}
			/> */}
		</Box>
		
	)
}
export default Calendar;