import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { AppBar, Box, Button, CircularProgress, Container, Hidden, Grid, Paper, Modal, Step, StepLabel, Stepper, Toolbar, Typography, LinearProgress } from '@material-ui/core';

import useStyles from './style';
import PrivacyPolicy from './privacy-policy';
import BasicInfo from './basic-info';
import VerifyAccount from './verify-account';
import AccountInfo from './account-info';
import ReferralCode from './referral-code';
import Address from './address';
import Preference from './preference';
import Height from './height';
import Upload from './upload';
import Note from './note';
import Subscription from './subscription'
import LeftLayout from '../../components/left-layout';
import logo from '../../assets/images/logo.png';
import userAction from '../../redux/user/action';
import lookupAction from '../../redux/lookup/action';
import Gender from './gender';

const { updateToken, updateUser, updateUserField, signInUser, updateUserSubscription } = userAction;
const { getLookUpFields, getLookUpCountry, getLookUpState, getLookUpCity }  = lookupAction;

const Registration = (props) => {
  const classes = useStyles();
  const ref = useRef();

  const [component, setComponent] = useState(0);
  const [isPreference, setIsPreference] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [completed, setCompleted] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [cityLookUpTrigger, setCityLookUpTrigger] = useState({});


  const steps = ['Relationship', 'Looking For?', 'Address', 'Language', 'Height', 'Body Build', 'Hair Color', 'Eye Color', 'Ethnicity', 'Smoking Tolerance', 'Exercise Regimen', 'Alcohol Tolerance', 'Position On Kids', 'Education', 'Religion', 'Profession', 'Pet Policy', 'Hobbies', 'Political Belief', 'Upload Photo', 'Yourself', 'Subscription'];

  useEffect(() => {
    props.updateUserSubscription({})
    document.getElementById('root').style.height = '100%';
  }, []);

  useEffect(()=>{
    props.getLookUpCountry('/')
    props.getLookUpState('233')
  },[])
  
  useEffect(()=>{
      const noCity =  Object.keys(cityLookUpTrigger).length === 0 && cityLookUpTrigger.constructor === Object;
      if(!noCity){
        props.getLookUpCity(cityLookUpTrigger.value)
        setCityLookUpTrigger({})
      }
  },[cityLookUpTrigger])

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return component === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };


  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : component + 1;

    if(component === 3 && !isPreference) {
      setComponent(0);
      setIsPreference(true);
    } else {
      setComponent(newActiveStep);
    }
  };

  const handleBack = () => {
    setComponent(prevComponent => prevComponent - 1);
  };

  const getIsSaving = (boolean) => {
    console.log('getIsSaving : ', boolean);
    setIsSaving(boolean);
  }

  const nextStep = () => {
    console.log('nextStep');
    ref.current.triggerNext();
  }

  const registration = [
    <BasicInfo      {...props} next={handleNext} key={0}/>,
    <VerifyAccount  {...props} next={handleNext} key={1}/>,
    <AccountInfo    {...props} next={handleNext} key={2}/>,
    <ReferralCode   {...props} next={handleNext} key={3}/>,
  ]

  const preference = [
    <Preference     {...props} ref={ref} next={handleNext} key={4}   callback={getIsSaving} index={0} required={true}/>,
    <Gender         {...props} ref={ref} next={handleNext} key={5}   callback={getIsSaving}/>,
    <Address        {...props} ref={ref} next={handleNext} key={6}   callback={getIsSaving} setCityLookUpTrigger={setCityLookUpTrigger} />,
    <Preference     {...props} ref={ref} next={handleNext} key={7}   callback={getIsSaving} index={1} multiple={true}/>,
    <Height         {...props} ref={ref} next={handleNext} key={8}   callback={getIsSaving} />,
    <Preference     {...props} ref={ref} next={handleNext} key={9}   callback={getIsSaving} index={2} required={true}/>,
    <Preference     {...props} ref={ref} next={handleNext} key={10}  callback={getIsSaving} index={3} required={true}/>,
    <Preference     {...props} ref={ref} next={handleNext} key={11}  callback={getIsSaving} index={4} required={true}/>,
    <Preference     {...props} ref={ref} next={handleNext} key={12}  callback={getIsSaving} index={5} required={true}/>,
    <Preference     {...props} ref={ref} next={handleNext} key={13}  callback={getIsSaving} index={6} required={true}/>,
    <Preference     {...props} ref={ref} next={handleNext} key={14}  callback={getIsSaving} index={7}/>,
    <Preference     {...props} ref={ref} next={handleNext} key={15}  callback={getIsSaving} index={8}/>,
    <Preference     {...props} ref={ref} next={handleNext} key={16}  callback={getIsSaving} index={9}/>,
    <Preference     {...props} ref={ref} next={handleNext} key={17}  callback={getIsSaving} index={10}/>,
    <Preference     {...props} ref={ref} next={handleNext} key={18}  callback={getIsSaving} index={11}/>,
    <Preference     {...props} ref={ref} next={handleNext} key={19}  callback={getIsSaving} index={12}/>,
    <Preference     {...props} ref={ref} next={handleNext} key={20}  callback={getIsSaving} index={13}/>,
    <Preference     {...props} ref={ref} next={handleNext} key={21}  callback={getIsSaving} index={14}/>,
    <Preference     {...props} ref={ref} next={handleNext} key={22}  callback={getIsSaving} index={15} multiple={true}/>,
    <Upload         {...props} ref={ref} next={handleNext} key={23}  callback={getIsSaving}/>,
    <Note           {...props} ref={ref} next={handleNext} key={24}  callback={getIsSaving}/>,
    <Subscription   {...props} ref={ref} next={handleNext} key={25}  callback={getIsSaving}/>
  ]

  if (isPreference) return (
    <Grid container className={classes.container}>
      <Hidden smDown>
        <Grid item md={5} className={classes.gridItem}>
          <LeftLayout></LeftLayout>
        </Grid>
      </Hidden>
      <Grid item xs={12} md={7}>
        <Box className={classes.preference}>
          <Box className={classes.progressContainer}>
            <LinearProgress variant="determinate" value={component / steps.length * 100} />
            <Typography variant="subtitle1" color="primary" align="center">{`${Math.floor(component / steps.length * 100)}% Complete`}</Typography>
          </Box>
          {/* <Stepper activeStep={component} className={classes.stepper} alternativeLabel>
            {
              steps.map((value, index) => (
                <Step key={index}>
                  <StepLabel>{value}</StepLabel>
                </Step>
              ))
            }
          </Stepper> */}
          <Box className={classes.content}>
            {
              preference.filter((value, index) => index === component)
            }
          </Box>
          <Box>
            <Button
              variant="outlined"
              color="primary"
              disabled={component === 0}
              onClick={handleBack}
            >
              Back
            </Button>
            <Button variant="contained" color="primary" onClick={nextStep} disabled={isSaving}>
              {
                isSaving ?
                  <CircularProgress color="white" size={24}/>
                : component === steps.length - 1 ? 
                  'Finish' 
                : 
                  'Next'
              }
            </Button>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )



  return (
    <Container maxWidth={component > 0 ? 'xl' : 'md'} disableGutters={component > 0 ? true : false} className={classes.container}>
      <Grid container className={classes.gridContainer}>
        <Hidden smDown>
          {
            component > 0 &&
              <Grid item md={5} className={classes.gridItem}>
                <LeftLayout></LeftLayout>
              </Grid>
          }
        </Hidden>
        {
          registration.filter((value, index) => index === component)
        }
      </Grid>
      <Modal
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Paper className={classes.paper}>
          <PrivacyPolicy  {...props} next={() => setIsModalOpen(false)} key={0}/>
        </Paper>
      </Modal>
    </Container>
  );
}

/////////////////////////////
// mapStateToProps()
/////////////////////////////
const mapStateToProps = (state) => {
  console.log('registration/index.js:mapStateToProps(state)');
  console.log(state);
  return {
    user             : state?.User?.user,
    token            : state?.User?.token,
    lookUpGender     : state?.Lookup?.lookUpGender,
    lookUpHeight     : state?.Lookup?.lookUpHeight,
    isLoading        : state?.Lookup?.isLoading,
    Lookup           : state?.Lookup,
    userSubscription : state?.User?.userSubscription,
  };
}

export default connect(mapStateToProps, { getLookUpFields, updateToken, updateUser, updateUserField, updateUserSubscription, signInUser, getLookUpCountry, getLookUpState, getLookUpCity })(
  Registration
);