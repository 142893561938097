import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
  InputAdornment,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { useHistory } from "react-router-dom";

import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import DeleteIcon from "@material-ui/icons/DeleteOutlined";
import PhoneIcon from "@material-ui/icons/Phone";
import VideocamIcon from "@material-ui/icons/Videocam";

import "./style.css";
import useStyles from "./style";
import ChatLayout from "../../chat-layout";

import {
  CHANNEL,
  STATUS_ACTIVE,
  STATUS_SENT,
  STATUS_RECEIVED,
  CONNECTION_ACTION_ACCEPT,
  CONNECTION_ACTION_IGNORE,
  CONNECTION_ACTION_REMOVE,
  CONNECTION_ACTION_SEND,
  CONNECTION_ACTION_UNLIKE,
  CONNECTION_TYPE_MESSAGE,
  CONNECTION_TYPE_PHONE_CALL,
  CONNECTION_TYPE_VIDEO_CALL,
  TOAST_AXIOS_REQUEST_ERROR,
  TOAST_CHAT_SENT,
  TOAST_CHAT_ACCEPTED,
  TOAST_CHAT_REMOVED,
  TOAST_CHAT_DECLINED,
  TOAST_CHAT_DISABLED,
  TOAST_PHONE_SENT,
  TOAST_PHONE_ACCEPTED,
  TOAST_PHONE_REMOVED,
  TOAST_PHONE_DECLINED,
  TOAST_PHONE_DISABLED,
  TOAST_VIDEO_SENT,
  TOAST_VIDEO_ACCEPTED,
  TOAST_VIDEO_REMOVED,
  TOAST_VIDEO_DECLINED,
  TOAST_VIDEO_DISABLED,
  ALERT_SEVERITY_ERROR,
  ALERT_SEVERITY_INFO,
  ALERT_SEVERITY_SUCCESS,
  ALERT_SEVERITY_WARINIG,
  STATUS_INACTIVE,
  PRELODAER_MESSAGE,
  PHONE_CALL_REQUEST_SENT,
  VIDEO_CALL_REQUEST_SENT,
  MESSAGE_REQUEST_SENT,
  PHONE_CALL_REQUEST_ACCEPTED,
  VIDEO_CALL_REQUEST_ACCEPTED,
  MESSAGE_REQUEST_ACCEPTED,
  PHONE_CALL_DISABLED,
  VIDEO_CALL_DISABLED,
  MESSAGE_DISABLED,
  CALL_TYPE_AUDIO,
  CALL_TYPE_VIDEO,
  PHONE_CALL_CALLER,
  VIDEO_CALL_CALLER,
  PRELOADER_MESSAGE_CARD,
  HTTP_STATUS,
} from '../../../utils/constants';

import { connectionRequest, notificationRequest, callRequest, twilioRequest } from '../../../service/requests';
import { notificationContent } from '../../../utils/notificationcontent';
import Preloader from '../../preloader';
import { handleHttpError } from '../../../utils/helper';

const CommunicateButton = (props) => {
  const classes = useStyles();
  const {
    value,
    type,
    user,
    userConnection,
    title,
    icon,
    initiateAction,
    setUserConnection,
    showAlert,
    updateMessageUserConnection,
    disable,
  } = props;

  const [state, setState] = useState({
    affirmativeButton: "SEND",
    modalDesc: `You need to send a request to ${title} with ${userConnection.user.userName}. Send Request?`,
    action: CONNECTION_ACTION_SEND,
    leftShade: classes.leftUnshaded,
    rightShade: classes.rightUnshaded,
    hasNegativeButton: true,
    toast: "",
  });
  const [isOpen, setIsOpen] = useState(false);

  const {
    affirmativeButton,
    hasNegativeButton,
    modalDesc,
    action,
    leftShade,
    rightShade,
    toast,
  } = state;

  useEffect(() => {
    setState({
      ...state,
      leftShade: getLeftShadeClass(value),
      rightShade: getRightShadeClass(value),
    });
  }, [value]);

  const getToast = () => {
    if (type === CONNECTION_TYPE_MESSAGE) {
      return {
        sent: TOAST_CHAT_SENT(userConnection.user.userName),
        accepted: TOAST_CHAT_ACCEPTED(userConnection.user.userName),
        removed: TOAST_CHAT_REMOVED(userConnection.user.userName),
        declined: TOAST_CHAT_DECLINED(userConnection.user.userName),
        disabled: TOAST_CHAT_DISABLED(userConnection.user.userName),
      };
    } else if (type === CONNECTION_TYPE_PHONE_CALL) {
      return {
        sent: TOAST_PHONE_SENT(userConnection.user.userName),
        accepted: TOAST_PHONE_ACCEPTED(userConnection.user.userName),
        removed: TOAST_PHONE_REMOVED(userConnection.user.userName),
        declined: TOAST_PHONE_DECLINED(userConnection.user.userName),
        disabled: TOAST_PHONE_DISABLED(userConnection.user.userName),
      };
    } else if (type === CONNECTION_TYPE_VIDEO_CALL) {
      return {
        sent: TOAST_VIDEO_SENT(userConnection.user.userName),
        accepted: TOAST_VIDEO_ACCEPTED(userConnection.user.userName),
        removed: TOAST_VIDEO_REMOVED(userConnection.user.userName),
        declined: TOAST_VIDEO_DECLINED(userConnection.user.userName),
        disabled: TOAST_VIDEO_DISABLED(userConnection.user.userName),
      };
    }
  };

  const getLeftShadeClass = (value) => {
    let leftShade = classes.leftUnshaded;

    if (value === STATUS_SENT) {
      leftShade = classes.leftShaded;
    } else if (value === STATUS_RECEIVED) {
      leftShade = classes.leftUnshaded;
    } else if (value === STATUS_ACTIVE) {
      leftShade = classes.leftShaded;
    }

    return leftShade;
  };

  const getRightShadeClass = (value) => {
    let rightShade = classes.rightUnshaded;

    if (value === STATUS_SENT) {
      rightShade = classes.rightUnshaded;
    } else if (value === STATUS_RECEIVED) {
      rightShade = classes.rightShaded;
    } else if (value === STATUS_ACTIVE) {
      rightShade = classes.rightShaded;
    } else {
      rightShade = classes.rightUnshaded;
    }

    return rightShade;
  };

  const toggleModal = () => {
    setIsOpen((prevIsOpen) => !prevIsOpen);
  };

  const handleRequest = () => {
    let communicateRequestNotif = {};

    connectionRequest
      .connectUser(
        {
          user_id: user.userId,
          who_added: user.userId,
        },
        type + "/" + action + "/" + userConnection.user.userId
      )
      .then((response) => {
        console.log("response : ", response);

      if (response?.data?.status?.code == HTTP_STATUS._200) {
        toggleModal();
        showAlert(ALERT_SEVERITY_SUCCESS, toast);
        setUserConnection(response.data.connection);
        updateMessageUserConnection(response.data.connection)

          if (type === CONNECTION_TYPE_PHONE_CALL && action === STATUS_SENT) {
            communicateRequestNotif = notificationContent(user.userName)[
              PHONE_CALL_REQUEST_SENT
            ];
          } else if (
            type === CONNECTION_TYPE_VIDEO_CALL &&
            action === STATUS_SENT
          ) {
            communicateRequestNotif = notificationContent(user.userName)[
              VIDEO_CALL_REQUEST_SENT
            ];
          } else if (
            type === CONNECTION_TYPE_MESSAGE &&
            action === STATUS_SENT
          ) {
            communicateRequestNotif = notificationContent(user.userName)[
              MESSAGE_REQUEST_SENT
            ];
          } else if (
            type === CONNECTION_TYPE_PHONE_CALL &&
            action === CONNECTION_ACTION_ACCEPT
          ) {
            communicateRequestNotif = notificationContent(user.userName)[
              PHONE_CALL_REQUEST_ACCEPTED
            ];
          } else if (
            type === CONNECTION_TYPE_VIDEO_CALL &&
            action === CONNECTION_ACTION_ACCEPT
          ) {
            communicateRequestNotif = notificationContent(user.userName)[
              VIDEO_CALL_REQUEST_ACCEPTED
            ];
          } else if (
            type === CONNECTION_TYPE_MESSAGE &&
            action === CONNECTION_ACTION_ACCEPT
          ) {
            communicateRequestNotif = notificationContent(user.userName)[
              MESSAGE_REQUEST_ACCEPTED
            ];
          } else if (
            type === CONNECTION_TYPE_MESSAGE &&
            action === CONNECTION_ACTION_IGNORE
          ) {
            communicateRequestNotif = notificationContent(user.userName)[
              MESSAGE_DISABLED
            ];
          } else if (
            type === CONNECTION_TYPE_PHONE_CALL &&
            action === CONNECTION_ACTION_IGNORE
          ) {
            communicateRequestNotif = notificationContent(user.userName)[
              PHONE_CALL_DISABLED
            ];
          } else if (
            type === CONNECTION_TYPE_VIDEO_CALL &&
            action === CONNECTION_ACTION_IGNORE
          ) {
            communicateRequestNotif = notificationContent(user.userName)[
              VIDEO_CALL_DISABLED
            ];
          }

          const payloadKeys = Object.keys(communicateRequestNotif);
          if (payloadKeys.length != 0) {
            let payload = {
              item_id: response.data.connection.connectionId,
              sender_id: user.userId,
              receiver_id: userConnection.user.userId,
              notif_type: communicateRequestNotif.notifType,
              title: communicateRequestNotif.notifTitle,
              body: communicateRequestNotif.notifBody,
            };
            if (payloadKeys.includes("isHidden"))
              payload.is_hidden = communicateRequestNotif.isHidden;

            notificationRequest
              .sendNotification(payload)
              .then((response) => {
                console.log("response : ", response);
              })
              .catch((error) => {
                console.log("error : ", error);
              });
          }
        }

        const payloadKeys = Object.keys(communicateRequestNotif);
        if (payloadKeys.length != 0) {

          let payload = {
            item_id     : response.data.connection.connectionId,
            sender_id   : user.userId,
            receiver_id : userConnection.user.userId,
            notif_type  : communicateRequestNotif.notifType,
            title       : communicateRequestNotif.notifTitle,
            body        : communicateRequestNotif.notifBody
          };
          if (payloadKeys.includes('isHidden')) payload.is_hidden = communicateRequestNotif.isHidden;

          notificationRequest.sendNotification(payload).then(response => {
            console.log('response : ', response);
      
          }).catch(error => {
            handleHttpError({
              error,
              request: 'notificationRequest.sendNotification::src/components/userprofile/communicate'
            })
          });
        }
    }).catch(error => {
      handleHttpError({
        error,
        request: 'connectionRequest.connectUser::src/components/userprofile/communicate'
      })
    });
  }

  const handleSend = () => {
    handleRequest();
  };

  const enableCommunication = async () => {
    console.log("enableCommunication()");

    if (value === STATUS_ACTIVE) {
      if (
        type === CONNECTION_TYPE_VIDEO_CALL ||
        type === CONNECTION_TYPE_PHONE_CALL
      ) {
        let stream = null;

        try {
          stream = await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: true,
          });
          console.log("stream: ", stream);

          if (stream) {
            initiateAction();
            const tracks = stream.getTracks();
            if (tracks) {
              tracks.map((track) => {
                track.stop();
              });
            }
          }
        } catch (err) {
          console.log("err: ", err.message);

          setState({
            ...state,
            hasNegativeButton: false,
            affirmativeButton: "OK",
            modalDesc:
              "Camera and microphone permission is blocked. Please enable them in your browser.",
            action:
              value === STATUS_RECEIVED
                ? CONNECTION_ACTION_ACCEPT
                : CONNECTION_ACTION_SEND,
          });

          toggleModal();
        }
      } else {
        initiateAction();
      }
    } else {
      setState({
        ...state,
        affirmativeButton: value === STATUS_RECEIVED ? "ACCEPT" : "SEND",
        modalDesc:
          value === STATUS_RECEIVED
            ? `You need to accept the request to ${title} with ${userConnection.user.userName}. Accept Request?`
            : `You need to send a request to ${title} with ${userConnection.user.userName}. Send Request?`,
        action:
          value === STATUS_RECEIVED
            ? CONNECTION_ACTION_ACCEPT
            : CONNECTION_ACTION_SEND,
        toast:
          value === STATUS_RECEIVED ? getToast().accepted : getToast().sent,
      });
      toggleModal();
    }
  };

  const disableCommunication = () => {
    console.log("disableCommunication()");

    const disableMethodTitle = () => {
      let methodTitle = title;
      const { phoneCall, videoCall } = userConnection;
      switch (title) {
        case "Chat":
          if (phoneCall !== STATUS_INACTIVE && videoCall !== STATUS_INACTIVE) {
            methodTitle = "chat, phone call and video call";
          } else if (
            phoneCall !== STATUS_INACTIVE &&
            videoCall === STATUS_INACTIVE
          ) {
            methodTitle = "chat and phone call";
          }
          break;

        case "Phone Call":
          if (videoCall !== STATUS_INACTIVE) {
            methodTitle = "phone call and video call";
          }
          break;

        default:
          break;
      }
      return methodTitle.toLowerCase();
    };

    setState({
      ...state,
      affirmativeButton:
        value === STATUS_ACTIVE
          ? "DEACTIVATE"
          : value === STATUS_SENT
          ? "REMOVE"
          : "DECLINE",
      modalDesc:
        value === STATUS_ACTIVE
          ? `Do you want to deactivate ${disableMethodTitle()}?`
          : value === STATUS_SENT
          ? `You need to send another ${title} request to ${userConnection.user.userName} once removed. Remove ${title} request?`
          : `You need to send another ${title} request to ${userConnection.user.userName} once declined. Decline ${title} request?`,
      action: CONNECTION_ACTION_IGNORE,
      toast:
        value === STATUS_ACTIVE
          ? getToast().disabled
          : value === STATUS_SENT
          ? getToast().removed
          : getToast().declined,
    });

    toggleModal();
  };

  return (
    <Box className={classes.actionCard}>
      <Dialog
        open={isOpen}
        onClose={toggleModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableEnforceFocus
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {modalDesc}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {hasNegativeButton && (
            <Button
              className={classes.button}
              onClick={toggleModal}
              color="primary"
              variant="outlined"
              autoFocus
              focusRipple
            >
              No
            </Button>
          )}
          <Button
            className={classes.button}
            onClick={hasNegativeButton ? handleSend : toggleModal}
            color="primary"
            variant="contained"
          >
            {affirmativeButton}
          </Button>
        </DialogActions>
      </Dialog>
      {/* <Button className={`${classes.communicateSmallActionButton} ${(value !== STATUS_INACTIVE) && classes.communicateSmallActionButtonEnable}`} onClick={disableCommunication} disabled={value === STATUS_INACTIVE || !value}>
        <DeleteIcon style={{fill: 'white'}}/>
      </Button> */}
      <Button
        component={Card}
        className={classes.communicateActionButton}
        onClick={enableCommunication}
        disabled={value === STATUS_SENT || disable}
      >
        <Box
          className={`${classes.communicateActionButton} ${classes.defaultShade} ${leftShade}`}
        ></Box>
        <Box
          className={`${classes.communicateActionButton} ${classes.defaultShade} ${rightShade}`}
        ></Box>
        {icon}
      </Button>
      <Typography variant="h5" color="primary">
        {title}
      </Typography>
    </Box>
  );
};

const Communicate = (props) => {
  console.log("Communicate()");

  const history = useHistory();
  const classes = useStyles();
  const {
    addConversation,
    showAlert,
    user,
    userConnection,
    messages,
    twilio,
    userId,
  } = props;
  const { message, phoneCall, videoCall } = userConnection;

  const [selectedMessageThread, setSelectedMessageThread] = useState({});
  const [isChatBoxShown, setIsChatBoxShown] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  // useEffect(() => {
  //   console.log('Communicate.useEffect();');
  //   const updatedMessageThread = messages.find(message => message.userConnection.user.userId === userConnection.user.userId);
  //   console.log('updatedMessageThread : ', updatedMessageThread);
  //   updatedMessageThread && setSelectedMessageThread(updatedMessageThread);
  // }, [messages]);

  const initiateChat = () => {
    console.log("Communicate.initiateChat();");

    const connectionUserId = userConnection.connectionUserId;
    const uniqueName =
      user.userId > connectionUserId
        ? CHANNEL + connectionUserId + "_" + user.userId
        : CHANNEL + user.userId + "_" + connectionUserId;

    if (twilio.client) {
      twilio.client
        .getConversationByUniqueName(uniqueName)
        .then(async (conversation) => {
          console.log("conversation : ", conversation);

          let messages = await conversation.getMessages();

          const messagesLength = messages?.items?.length;
          const participants = await conversation?.getParticipants();
          const lastConsumedMessageIndex =
            conversation?.channelState?.lastMessage?.index;
          const latestMessage = messages?.items[messagesLength - 1];
          const participant = participants.find(
            (participantItem) =>
              parseInt(participantItem?.state?.identity) !== userId
          );
          const participantId = participant?.state?.identity;
          const participantInfo = await participant?.getUser();
          const latestMessageContent =
            latestMessage?.state?.author != userId
              ? latestMessage?.state?.body
              : `You: ${latestMessage?.state?.body}`;
          const updatedMessageThread = props?.messages.find(
            (message) =>
              message?.userConnection?.user?.userId ===
              userConnection?.user?.userId
          );
          const lastReadMessageIndex =
            conversation?.channelState?.lastReadMessageIndex;

        if(updatedMessageThread){
          setSelectedMessageThread(updatedMessageThread);
        }
        else{
          await addConversation({
            conversationName            : conversation.channelState.uniqueName,
            profilePicture              : userConnection.user.mediaProfile, 
            userName                    : userConnection.user.userName, 
            time                        : latestMessage.state.timestamp,
            isOnline                    : participant ? (participantInfo.state.online ? (participantInfo.state.attributes.is_reachable === false ? false : true) : false) : false,
            isUnseen                    : lastConsumedMessageIndex != lastReadMessageIndex,
            membershipViewabilityStatus : userConnection.userSubscription.viewabilityStatus,
            messages                    : messages.items,
            hasNextPage                 : messages.hasNextPage,
            hasPrevPage                 : messages.hasPrevPage,
            nextPage                    : messages.nextPage,
            prevPage                    : messages.prevPage,
            conversation,
            participantInfo,
            userConnection,
            latestMessageContent
          });

          setSelectedMessageThread({
            conversationName            : conversation.channelState.uniqueName,
            profilePicture              : userConnection.user.mediaProfile, 
            userName                    : userConnection.user.userName, 
            time                        : latestMessage.state.timestamp,
            isOnline                    : participant ? (participantInfo.state.online ? (participantInfo.state.attributes.is_reachable === false ? false : true) : false) : false,
            isUnseen                    : lastConsumedMessageIndex != lastReadMessageIndex,
            membershipViewabilityStatus : userConnection.userSubscription.viewabilityStatus,
            messages                    : messages.items,
            hasNextPage:                  messages.hasNextPage,
            hasPrevPage                 : messages.hasPrevPage,
            nextPage                    : messages.nextPage,
            prevPage                    : messages.prevPage,
            conversation,
            participantInfo,
            userConnection,
            latestMessageContent
          });
        }
       

        setIsChatBoxShown(true);
      }).catch(error => {
        console.log('twilio.client.getConversationByUniqueName error : ', error);

        if (error.message === 'Not Found') {
          twilio.client.createConversation({
            uniqueName, 
            friendlyName : uniqueName
          }).then(async conversation => {
            console.log('conversation : ', conversation);
            conversation.join();

            twilioRequest.addConversationParticipants({
              sid: conversation.sid,
              identity: connectionUserId
            })
            .then(response => console.log('response', response))
            .catch(error   => {
              handleHttpError({
                error,
                request: 'twilioRequest.addConversationParticipants::src/components/userprofile/communicate'
              })
            })

            let messages = await conversation.getMessages();

            const messagesLength = messages.items.length;
            const participants = await conversation.getParticipants();
            const lastConsumedMessageIndex = conversation.channelState.lastMessage.index;
            const latestMessage = messages.items[messagesLength - 1];
            const participant = participants.find(participantItem => parseInt(participantItem.state.identity) !== userId);
            const participantId = participant.state.identity;
            const participantInfo = await participant.getUser();
            const latestMessageContent     = latestMessage.state.author != userId ? latestMessage.state.body : `You: ${latestMessage.state.body}`
            const lastReadMessageIndex      = conversation.channelState.lastReadMessageIndex;

            addConversation({
              conversationName            : conversation.channelState.uniqueName,
              profilePicture              : userConnection.user.mediaProfile, 
              userName                    : userConnection.user.userName, 
              time                        : latestMessage.state.timestamp,
              isOnline                    : participant ? (participantInfo.state.online ? (participantInfo.state.attributes.is_reachable === false ? false : true) : false) : false,
              isUnseen                    : lastConsumedMessageIndex != lastReadMessageIndex,
              membershipViewabilityStatus : userConnection.userSubscription.viewabilityStatus,
              messages                    : messages.items,
              hasNextPage:                  messages.hasNextPage,
              hasPrevPage                 : messages.hasPrevPage,
              nextPage                    : messages.nextPage,
              prevPage                    : messages.prevPage,
              conversation,
              participantInfo,
              userConnection,
              latestMessageContent,
            });

            setSelectedMessageThread({
              conversationName: conversation?.channelState?.uniqueName,
              profilePicture: userConnection?.user?.mediaProfile,
              userName: userConnection?.user?.userName,
              time: latestMessage?.state?.timestamp,
              isOnline: participant
                ? participantInfo?.state?.online
                  ? participantInfo?.state?.attributes?.is_reachable === false
                    ? false
                    : true
                  : false
                : false,
              isUnseen: lastConsumedMessageIndex != lastReadMessageIndex,
              membershipViewabilityStatus:
                userConnection?.userSubscription?.viewabilityStatus,
              messages: messages?.items,
              hasNextPage: messages?.hasNextPage,
              hasPrevPage: messages?.hasPrevPage,
              nextPage: messages?.nextPage,
              prevPage: messages?.prevPage,
              conversation,
              participantInfo,
              userConnection,
              latestMessageContent,
            });
          }).catch((error) => {
            console.log(
              "twilio.client.getConversationByUniqueName error : ",
              error
            )});

          setIsChatBoxShown(true);
        }

          if (error.message === "Not Found") {
            twilio.client
              .createConversation({
                uniqueName,
                friendlyName: uniqueName,
              })
              .then(async (conversation) => {
                console.log("conversation : ", conversation);
                conversation.join();

                twilioRequest
                  .addConversationParticipants({
                    sid: conversation.sid,
                    identity: connectionUserId,
                  })
                  .then((response) => console.log("response", response))
                  .catch((error) => console.log("error", error));

                let messages = await conversation.getMessages();

                const messagesLength = messages?.items?.length;
                const participants = await conversation?.getParticipants();
                const lastConsumedMessageIndex =
                  conversation?.channelState?.lastMessage?.index;
                const latestMessage = messages?.items[messagesLength - 1];
                const participant = participants.find(
                  (participantItem) =>
                    parseInt(participantItem?.state?.identity) !== userId
                );
                const participantId = participant?.state?.identity;
                const participantInfo = await participant?.getUser();
                const latestMessageContent =
                  latestMessage?.state?.author != userId
                    ? latestMessage?.state?.body
                    : `You: ${latestMessage?.state?.body}`;
                const lastReadMessageIndex =
                  conversation?.channelState?.lastReadMessageIndex;

                addConversation({
                  conversationName: conversation?.channelState?.uniqueName,
                  profilePicture: userConnection?.user?.mediaProfile,
                  userName: userConnection?.user?.userName,
                  time: latestMessage?.state?.timestamp,
                  isOnline: participant
                    ? participantInfo?.state?.online
                      ? participantInfo?.state?.attributes?.is_reachable ===
                        false
                        ? false
                        : true
                      : false
                    : false,
                  isUnseen: lastConsumedMessageIndex != lastReadMessageIndex,
                  membershipViewabilityStatus:
                    userConnection?.userSubscription?.viewabilityStatus,
                  messages: messages?.items,
                  hasNextPage: messages?.hasNextPage,
                  hasPrevPage: messages?.hasPrevPage,
                  nextPage: messages?.nextPage,
                  prevPage: messages?.prevPage,
                  conversation,
                  participantInfo,
                  userConnection,
                  latestMessageContent,
                });
              })
              .catch((error) => {
                console.log("twilio.client.createConversation error : ", error);
                showAlert(ALERT_SEVERITY_ERROR, TOAST_AXIOS_REQUEST_ERROR);
              });
          } else {
            showAlert(ALERT_SEVERITY_ERROR, TOAST_AXIOS_REQUEST_ERROR);
          }
        });
    }
  };

  const initiateCall = () => {
    console.log("Communicate.initiateCall();");

    callRequest
      .startCall(
        {
          from_user_id: user.userId,
          to_user_id: userConnection.user.userId,
        },
        CALL_TYPE_AUDIO
      )
      .then((response) => {
        console.log("response : ", response);

      const { callThreadId } = response.data.call;
      history.push(`/audiocall?operator=${PHONE_CALL_CALLER}&threadId=${callThreadId}`);
      // window.open(`${window.location.host}/videocall`, 'Video Call');
    }).catch(error => {
      handleHttpError({
        error,
        request: 'callRequest.startCall::src/components/userprofile/communicate'
      })
    });
  }

  const initiateVideoCall = () => {
    console.log("Communicate.initiateVideoCall();");

    callRequest.startCall({
      from_user_id : user.userId,
      to_user_id   : userConnection.user.userId
    }, CALL_TYPE_VIDEO).then(response => {
      console.log('response : ', response);
      const { callThreadId } = response.data.call;
      history.push(`/videocall?operator=${VIDEO_CALL_CALLER}&threadId=${callThreadId}`);
      // window.open(`${window.location.host}/videocall`, 'Video Call');
    }).catch(error => {
      handleHttpError({
        error,
        request: 'callRequest.startCall::src/components/userprofile/communicate'
      })
    });
  }
  const handleScroll = (e) => {
    if (e.target.scrollTop == 0) {
      setIsLoadingMore(true);
      if (selectedMessageThread.hasPrevPage) {
        selectedMessageThread.prevPage().then((messagesPage) => {
          setSelectedMessageThread({
            ...selectedMessageThread,
            hasNextPage: messagesPage.hasNextPage,
            hasPrevPage: messagesPage.hasPrevPage,
            messages: [
              ...selectedMessageThread.messages.reverse(),
              ...messagesPage.items,
            ].reverse(),
            nextPage: messagesPage.nextPage,
            prevPage: messagesPage.prevPage,
          });
          setIsLoadingMore(false);
        });
        e.target.scrollTo({
          top: 1000,
          behavior: "smooth",
        });
      } else {
        setIsLoadingMore(false);
      }
    }
  };

  return (
    <Box
      p={5}
      boxShadow={2}
      style={{ backgroundColor: "#ffffff", borderRadius: 10 }}
    >
      {isChatBoxShown ? (
        <ChatLayout
          {...props}
          isLoadingMore={isLoadingMore}
          selectedMessageThread={selectedMessageThread}
          handleScroll={handleScroll}
        />
      ) : (
        <Grid
          container
          direction="column"
          alignItems="center"
          justify="center"
          spacing={3}
          className={classes.communicateContainer}
        >
          <Grid item xs={12} md={12} lg={12} className={classes.actionGrid}>
            <CommunicateButton
              {...props}
              value={message}
              type={CONNECTION_TYPE_MESSAGE}
              title="Chat"
              icon={<ChatBubbleIcon className={classes.actionButton} />}
              initiateAction={initiateChat}
            />
            <CommunicateButton
              {...props}
              color="transparent"
              value={phoneCall}
              type={CONNECTION_TYPE_PHONE_CALL}
              title="Phone"
              icon={<PhoneIcon className={classes.actionButton} />}
              disable={message !== STATUS_ACTIVE}
              initiateAction={initiateCall}
            />
            <CommunicateButton
              {...props}
              value={videoCall}
              type={CONNECTION_TYPE_VIDEO_CALL}
              title="Video"
              icon={<VideocamIcon className={classes.actionButton} />}
              disable={phoneCall !== STATUS_ACTIVE}
              initiateAction={initiateVideoCall}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default Communicate;
