import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Sound from 'react-sound';
import { Avatar, Box, Button, Checkbox, Container, FormControlLabel, Grid, Hidden, IconButton, Link, TextField, Typography, CircularProgress } from '@material-ui/core';
import PhoneOutlinedIcon from '@material-ui/icons/CallOutlined';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import CallEndOutlinedIcon from '@material-ui/icons/CallEndOutlined';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import MicOffIcon from '@material-ui/icons/MicOff';
import MicIcon from '@material-ui/icons/Mic';
import TwilioVideo from 'twilio-video';
import useStyles from './style';
import ring  from '../../assets/audios/ring_ring.mp3'
import { ALERT_SEVERITY_ERROR, TOAST_AXIOS_REQUEST_ERROR, ALERT_SEVERITY_SUCCESS, CALL_STATE_CALLING, VIDEO_CALL_REJECTED, VIDEO_CALL_ENDED, ALERT_SEVERITY_INFO, CALL_STATE_DECLINE, VIDEO_CALL_MISSED, PHONE_CALL_RECEIVER, AUDIO_CALL_DIALLED, VIDEO_CALL_DIALLED, CALL_STATE_CONNECTING, CALL_STATE_DISCONNECTED, CALL_STATE_MISSED, CALL_ACTION_MISSED, ROOM, VIDEO_CALL_ANSWERED, CALL_ACTION_ACCEPT, CALL_ACTION_DECLINE, CALL_STATE_CONNECTED, CALL_ACTION_COMPLETED, CALL_TYPE_AUDIO, PHONE_CALL_CALLER } from '../../utils/constants';
import { callRequest, multipleRequest, userRequest, notificationRequest } from '../../service/requests';
import userAction         from '../../redux/user/action';
import calendarAction     from '../../redux/calendar/action';
import connectionAction   from '../../redux/connection/action';
import searchAction       from '../../redux/search/action';
import colors from '../../utils/colors';
import { notificationContent } from '../../utils/notificationcontent';
import { handleHttpError, isEmpty } from '../../utils/helper';

const { updateTwilioVideoToken } = userAction;
const { setUserConnection } = connectionAction;

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const AudioCall = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const query = useQuery().toString();
  const operator = query.split('&')[0].split('=')[1];

  const { setUserConnection, showAlert, user, twilioVideoToken, updateTwilioVideoToken, userConnection, iViewed, likedMe, viewedMe } = props;

  const [threadId, setThreadId] = useState(query.split('&')[1].split('=')[1]);
  const [senderId] = useState(operator === PHONE_CALL_RECEIVER ? query.split('&')[2].split('=')[1] : null);

  const [audioCall, setAudioCall] = useState({});
  const [status, setStatus] = useState(CALL_STATE_CALLING);
  const [isAudioEnabled, toggleIsAudioEnabled] = useState(false);
  const [localVideoTrack, setLocalVideoTrack] = useState({});
  const [playStatus, setPlayStatus] = useState(Sound.status.STOPPED);
  const [interval, setInterval] = useState(null);

  useEffect(()=>{
    return ()=>{
      updateTwilioVideoToken(null)
    }
  },[])


  useEffect(() => {
    document.getElementById('root').style.height = '100%';
    if (operator === PHONE_CALL_CALLER) {
      const audioCallNotifContent = notificationContent(user.userName)[AUDIO_CALL_DIALLED];
      
      notificationRequest.sendNotification({
        item_id                 : threadId,
        sender_id               : user.userId,
        receiver_id             : userConnection.user.userId,
        notif_type              : audioCallNotifContent.notifType,
        is_notification_showed  : audioCallNotifContent.isNotificationShowed,
        is_voip_notification    : true,
        title                   : audioCallNotifContent.notifTitle,
        body                    : audioCallNotifContent.notifBody,
      }).then(response => {
        console.log('response : ', response);
        
        startAudioCall();
      }).catch(error => {
        handleHttpError({
          error,
          request: 'notificationRequest.sendNotification::src/views/audio-call'
        })        
      });
    } else {
      const caller = [...iViewed, ...likedMe.map(user => user.userConnection), ...viewedMe].find(connection => connection.user.userId == senderId);
      setPlayStatus(Sound.status.PLAYING);
      setUserConnection(caller);
    }

    return () => {
      console.log('will unmount');
      setPlayStatus(Sound.status.STOPPED);
      clearTimeout(interval);
    }
  }, [threadId]);

  useEffect(() => {
    if (twilioVideoToken != null && twilioVideoToken !== VIDEO_CALL_REJECTED && twilioVideoToken !== VIDEO_CALL_ENDED && twilioVideoToken !== VIDEO_CALL_MISSED) {
      setPlayStatus(Sound.status.STOPPED);
      setStatus(CALL_STATE_CONNECTING);
      clearTimeout(interval);
      initiateAudioCall();
    } else if (twilioVideoToken === VIDEO_CALL_REJECTED && operator === PHONE_CALL_CALLER) {
      setPlayStatus(Sound.status.STOPPED);
      clearTimeout(interval);
      updateTwilioVideoToken(null);
      setStatus(CALL_STATE_DECLINE);
      showAlert(ALERT_SEVERITY_ERROR, 'Video call rejected.');
    } else if ((twilioVideoToken === VIDEO_CALL_ENDED) && operator === PHONE_CALL_RECEIVER) {
      !isEmpty(audioCall) && audioCall.room.disconnect();
      setPlayStatus(Sound.status.STOPPED);
      clearTimeout(interval);
      showAlert(ALERT_SEVERITY_ERROR, 'Video call ended.');
      updateTwilioVideoToken(null);
      goBack();
    } else if ((twilioVideoToken === VIDEO_CALL_REJECTED) && operator === PHONE_CALL_RECEIVER) {
      !isEmpty(audioCall) && audioCall.room.disconnect();
      setPlayStatus(Sound.status.STOPPED);
      clearTimeout(interval);
      showAlert(ALERT_SEVERITY_ERROR, 'Video call rejected.');
      updateTwilioVideoToken(null);
      goBack();
    } else if ((twilioVideoToken === VIDEO_CALL_MISSED) && operator === PHONE_CALL_RECEIVER) {
      setPlayStatus(Sound.status.STOPPED);
      clearTimeout(interval);
      showAlert(ALERT_SEVERITY_ERROR, 'Video call missed.');
      goBack();
    }

  }, [twilioVideoToken, twilioVideoToken && twilioVideoToken.tokenTwilio]);

  const initiateAudioCall = () => {
    console.log('initiateAudioCall');
    console.log(twilioVideoToken)
    TwilioVideo.connect(twilioVideoToken.token).then(room => {
      console.log('TwilioVideo connect');
      console.log(`Successfully joined a Room: ${room}`);
      setStatus(CALL_STATE_CONNECTED);
      setAudioCall({room});
      toggleIsAudioEnabled(true);

      room.localParticipant.videoTracks.forEach((publication) => {
        publication.track.stop();
        publication.track.disable();
      });

      // CALLER
      // Log Participant Connected
      room.on('participantConnected', participant => {
        console.log(`A remote Participant connected: ${participant}`);
        console.log('participant : ', participant);

        participant.tracks.forEach(publication => {
          if (publication.isSubscribed) {
            if (publication.kind === 'audio') {
              const track = publication.track;
              document.getElementById('remote-media').appendChild(track.attach());
            }
          }
        });        

        participant.on('trackSubscribed', track => {
          if(track.kind === 'audio') {
            document.getElementById('remote-media').appendChild(track.attach());
          }

        });
        
      });

      // RECEIVER
      // Log any Participants already connected to the Room
      room.participants.forEach(participant => {
        console.log(`Participant "${participant.identity}" is connected to the Room`);
        console.log('participant : ', participant);

        // comment for now this code causes mobile phone both speaker to work
        // participant.tracks.forEach(publication => {
        //   if (publication.isSubscribed) {
        //     if (publication.kind === 'audio') {
        //       const track = publication.track;

        //       document.getElementById('remote-media').appendChild(track.attach());
        //     }
        //   }
        // });        

        participant.on('trackSubscribed', track => {
          console.log('trackSubscribed : ', track);
          if (track.kind === 'audio') {
            document.getElementById('remote-media').appendChild(track.attach());
          }
        });

      });

      // Log Participants as they disconnect from the Room
      room.once('participantDisconnected', participant => {
        console.log(`Participant "${participant.identity}" has disconnected from the Room`);
        room.disconnect();
      });

      // Participant Disconnected
      room.on('participantDisconnected', participant => {
        console.log(`Participant disconnected: ${participant.identity}`);
        room.disconnect();
      });

      // Disconnected
      room.on('disconnected', room => {
        console.log('Disconnected');
        updateTwilioVideoToken(null);
        setStatus(CALL_STATE_DISCONNECTED);
        room.localParticipant.tracks.forEach(publication => {
          const attachedElements = publication.track.detach();
          attachedElements.forEach(element => element.remove());
        });

        try {
          document.getElementById('remote-media').innerHTML = '';
        }catch(e){
          console.log('error: ', e)
        }
      });
      
    }, error => {
      console.error(`Unable to connect to Room: ${error.message}`);
      showAlert(ALERT_SEVERITY_ERROR, error.message);
    });
  }

  const startAudioCall = () => {
    console.log('startAudioCall');

    setPlayStatus(Sound.status.PLAYING);

    if (operator === PHONE_CALL_CALLER) {
      // 30 seconds time out
      const newInterval = setTimeout(() => {
        setStatus(CALL_STATE_MISSED);
        
        const missAudioCallNotification = notificationContent(user.userName)[VIDEO_CALL_MISSED];

        multipleRequest([
          notificationRequest.sendNotification({
            item_id                 : threadId,
            sender_id               : user.userId,
            receiver_id             : userConnection.user.userId,
            notif_type              : missAudioCallNotification.notifType,
            is_notification_showed  : missAudioCallNotification.isNotificationShowed,
            is_voip_notification    : true,
            title                   : missAudioCallNotification.notifTitle,
            body                    : missAudioCallNotification.notifBody
          }),
          callRequest.respondToCall({
            user_id       : user.userId
          }, CALL_ACTION_MISSED, threadId)
          ]).then(response => {
          console.log('response : ', response);
        }).catch(error => {
          handleHttpError({
            error,
            request: 'notificationRequest.sendNotification&callRequest.respondToCall::src/views/audio-call'
          })        
  
        });

        setPlayStatus(Sound.status.STOPPED);
      }, 30000);

      setInterval(newInterval);
    }
  }

  const acceptAudioCall = () => {
    console.log('acceptAudioCall');

    const roomName = (user.userId > senderId) ? `${ROOM+ senderId}_${user.userId}` : `${ROOM+ user.userId}_${senderId}`;
    const audioCallNotifContent = notificationContent(null)[VIDEO_CALL_ANSWERED];
    
    setPlayStatus(Sound.status.STOPPED);
    clearTimeout(interval);
    setStatus(CALL_STATE_CONNECTING);

    multipleRequest([
      notificationRequest.sendNotification({
        item_id                 : threadId,
        sender_id               : user.userId,
        receiver_id             : senderId,
        notif_type              : audioCallNotifContent.notifType,
        is_notification_showed  : audioCallNotifContent.isNotificationShowed,
        title                   : audioCallNotifContent.notifTitle,
        body                    : audioCallNotifContent.notifBody
        
      }),
      userRequest.getTwilioVideoToken({
        room_name : roomName,
        user_name : user.userName
      }),
      callRequest.respondToCall({
        user_id   : user.userId
      }, CALL_ACTION_ACCEPT, threadId)
    ]).then(([notifResponse, twilioVideoResponse, callRequestResponse]) => {
      console.log('notifResponse        : ', notifResponse);
      console.log('twilioVideoResponse  : ', twilioVideoResponse);
      console.log('callRequestResponse  : ', callRequestResponse);

      updateTwilioVideoToken(twilioVideoResponse.data.tokenTwilio);

      // if (!isVideoOn) {
      //   this.toggleVideo();
      // }
    }).catch(error => {
      handleHttpError({
        error,
        request: 'notificationRequest.sendNotification&userRequest.getTwilioVideoToken::src/views/audiocall'

      })
    });

  }

  const declineAudioCall = () => {
    console.log('declineAudioCall');

    const declineAudioCallNotification = notificationContent(null)[VIDEO_CALL_REJECTED];

    setPlayStatus(Sound.status.STOPPED);
    clearTimeout(interval);

    multipleRequest([
      notificationRequest.sendNotification({
        item_id                 : threadId,
        sender_id               : user.userId,
        receiver_id             : senderId,
        notif_type              : declineAudioCallNotification.notifType,
        is_notification_showed  : declineAudioCallNotification.isNotificationShowed,
        title                   : declineAudioCallNotification.notifTitle,
        body                    : declineAudioCallNotification.notifBody
      }),
      callRequest.respondToCall({
        user_id      : user.userId
      }, CALL_ACTION_DECLINE, threadId)
    ]).then(response => {
      console.log('response : ', response);
      if (operator === PHONE_CALL_RECEIVER)
        history.goBack();
    }).catch(error => {
      handleHttpError({
        error,
        request: 'notificationRequest.sendNotificationcallRequest.respondToCall::src/views/audiocall'
        
      })
    });
  }

  const endAudioCall = () => {
    console.log('endAudioCall');

    setPlayStatus(Sound.status.STOPPED);
    clearTimeout(interval);
    !isEmpty(audioCall) && audioCall.room.disconnect();
    
    if (operator === PHONE_CALL_CALLER && status !== CALL_STATE_CONNECTED) {

      const audioCallNotification = notificationContent(null)[VIDEO_CALL_ENDED];

      multipleRequest([
        notificationRequest.sendNotification({
          item_id                 : threadId,
          sender_id               : user.userId,
          receiver_id             : (operator === PHONE_CALL_RECEIVER) ? senderId : userConnection.user.userId,
          notif_type              : audioCallNotification.notifType,
          is_notification_showed  : audioCallNotification.isNotificationShowed,
          is_voip_notification    : true,
          title                   : audioCallNotification.notifTitle,
          body                    : audioCallNotification.notifBody
        }),
        callRequest.respondToCall({
          user_id   : user.userId
        }, CALL_ACTION_COMPLETED, threadId)
      ]).then(response => {
        console.log('response : ', response);

        setStatus(CALL_STATE_DISCONNECTED);
      }).catch(error => {
        handleHttpError({
          error,
          request: 'notificationRequest.sendNotification::src/views/audiocall'
        })
      });
    }
  }

  const goBack = () => {
    console.log('goBack');
    history.goBack();
  }

  const redial = () => {
    console.log('redial');

    callRequest.startCall({
      from_user_id : user.userId,
      to_user_id   : (operator === PHONE_CALL_RECEIVER) ? senderId : userConnection.user.userId,
    }, CALL_TYPE_AUDIO).then(response => {
      console.log('response : ', response);

      const { callThreadId } = response.data.call;
      setThreadId(callThreadId);
      setStatus(CALL_STATE_CALLING);
      // history.push(`/videocall?operator=${PHONE_CALL_CALLER}&threadId=${callThreadId}`);
      // window.open(`${window.location.host}/videocall`, 'Video Call');
    }).catch(error => {
      handleHttpError({
        error,
        request: 'callRequest.startCall::src/views/audiocall'
      })
    });
  }

  const toggleAudio = () => {
    console.log('toggleAudio');

    if (isAudioEnabled) {
      audioCall.room.localParticipant.audioTracks.forEach(publication => {
        publication.track.disable();
      });
    } else {
      audioCall.room.localParticipant.audioTracks.forEach(publication => {
        publication.track.enable();
      });
    }

    toggleIsAudioEnabled(prevSound => !prevSound);
  }

  return (

      <Grid container className={classes.container}>
        <Grid item xs={12} className={classes.grid}>
          <Sound
            url={ring}
            playStatus={playStatus}
            playFromPosition={0 /* in milliseconds */}
            loop={true}
          />
          <Box className={classes.content}>
            <Avatar
              alt={'profile'}
              className={classes.image}
              src={userConnection && userConnection.user && userConnection.user.mediaProfile}
            />
            <Typography variant="h6" color="TextPrimary" align="center">{userConnection && userConnection.user && userConnection.user.userName}</Typography>
            <Typography variant="subtitle2" color="TextPrimary" align="center">
              {
                status === CALL_STATE_CALLING && operator === PHONE_CALL_RECEIVER ? 
                  'Calling'
                : status === CALL_STATE_CALLING ?
                  'Contacting'
                : status === CALL_STATE_CONNECTING ?
                  'Connecting'
                : status === CALL_STATE_DISCONNECTED ?
                  'Call Ended'
                : status === CALL_STATE_DECLINE ?
                  'Call Rejected'
                : status === CALL_STATE_MISSED ?
                  'No Answer'
                : 
                  'Connected'
              }
            </Typography>
          </Box>
          <Box className={classes.controls}>
            {
              (status === CALL_STATE_CONNECTED || status === CALL_STATE_CONNECTING) ? 
                <Box>
                  <IconButton className={`${isAudioEnabled ? classes.iconButtonOutlined : classes.iconButtonContained}`} onClick={toggleAudio}>
                    {
                      isAudioEnabled ?
                        <MicIcon style={{fill : colors.WHITE}}/>
                      :
                        <MicOffIcon style={{fill : colors.WHITE}}/>
                    }
                  </IconButton>
                  <IconButton className={classes.iconButtonRed} onClick={endAudioCall}>
                    <CallEndOutlinedIcon style={{fill : colors.WHITE}}/>
                  </IconButton>
                </Box>
              : ((operator === PHONE_CALL_CALLER && status === CALL_STATE_DECLINE) || status === CALL_STATE_DISCONNECTED || status === CALL_STATE_MISSED) ?
                <Box>
                  <IconButton className={classes.iconButtonGray} onClick={goBack}>
                    <ArrowBackOutlinedIcon style={{fill : colors.WHITE}}/>
                  </IconButton>
                  <IconButton className={classes.iconButtonGreen} onClick={redial}>
                    <PhoneOutlinedIcon style={{fill : colors.WHITE}}/>
                  </IconButton>
                </Box>
              :  
                (operator === PHONE_CALL_CALLER) ? 
                  <Box>
                    <IconButton className={classes.iconButtonRed} onClick={endAudioCall}>
                      <CallEndOutlinedIcon style={{fill : colors.WHITE}}/>
                    </IconButton>
                  </Box>
                :
                  <Box>
                    <IconButton className={classes.iconButtonGreen} onClick={acceptAudioCall}>
                      <CheckOutlinedIcon style={{fill : colors.WHITE}}/>
                    </IconButton>
                    <IconButton className={classes.iconButtonRed} onClick={declineAudioCall}>
                      <CloseOutlinedIcon style={{fill : colors.WHITE}}/>
                    </IconButton>
                  </Box>
            }
          </Box>
          <Box id="remote-media" className={classes.remoteAudio}></Box>
        </Grid>
      </Grid>
  );
}

/////////////////////////////
// mapStateToProps()
/////////////////////////////
const mapStateToProps = (state) => {
  console.log('shortlist/index.js:mapStateToProps(state)');
  console.log(state);
  return {
    user              : state.Session.user.user,
    twilioVideoToken  : state.User.twilioVideoToken,
    userConnection    : state.Connection.userConnection,
    likedMe       : state.Connection.recentViewsList.likedMe,
    iViewed       : state.Connection.recentViewsList.iViewed,
    viewedMe      : state.Connection.recentViewsList.viewedMe 
  };
}

export default connect(mapStateToProps, { setUserConnection, updateTwilioVideoToken })(
  AudioCall
);