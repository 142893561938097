import React, { useContext, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, useHistory, Redirect } from 'react-router-dom';
import { FirebaseContext } from '../classes/firebase';
import { notificationRequest } from '../service/requests';
import userAction from '../redux/user/action';
import notificationAction from '../redux/notification/action';
import connectionAction from '../redux/connection/action';

// Import custom components
// import MainLayout from '../components/common/layout/MainLayout';
import NotFound from '../components/notfound';

// Views
import Auth from '../views/auth';
import Welcome from '../views/welcome';
import ForgotPassword from '../views/forgot-password';
import PrivacyPolicy from '../views/privacy-policy';
import AccountDeletion from '../views/help-page/account-deletion';
import AboutUs from '../views/about-us';
import Registration from '../views/registration';
import Home from '../views/home';
import RecentViews from '../views/recent-views';
import MessageCenter from '../views/message-center';
import Shortlist from '../views/shortlist';
import Search from '../views/search';
import Calendar, { CalendarParticipantList, CalendarTab } from '../views/calendar';
import Profile from '../views/profile';
import Settings from '../views/settings';
import AudioCall from '../views/audio-call';
import VideoCall from '../views/video-call';
import Subscription from '../views/subscription';
import Referral from '../views/referral';

import AuthRoute from './AuthRoute';
import NeutralRoute from './NeutralRoute';
import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';
import { Main, None } from '../components/toplayout';
// import Minimal from '../components/common/Minimal';
import { handleHttpError, isEmpty } from '../utils/helper';
import { STATUS_ACTIVE } from '../utils/constants'
const { updateTwilioVideoToken } = userAction;
const { saveNotification } = notificationAction;

const { 
  setUserConnection, 
  updateChatRequest,
  updatePhoneRequest,
  updateVideoRequest,
  setDisabledConnection
}  = connectionAction;

const Routes = (props) => {
  const firebase = useContext(FirebaseContext);
  const history = useHistory();
  const {
    isSignedIn,
    authenticated,
    checked,
    saveNotification,
    setUserConnection,
    user,
    updateTwilioVideoToken,
    likedMe,
    iViewed,
    viewedMe,
    settings,
    updateChatRequest,
    updatePhoneRequest,
    updateVideoRequest,
    setDisabledConnection,
    email,
    userSubscription,
    userDetails
  } = props;
  const hasSubscription = Object.keys(userSubscription).length > 0 ? userSubscription.status : undefined
  const isSubscribed = hasSubscription ? userSubscription.status === STATUS_ACTIVE : false
  // const [isSubscriptionGetReady, setIsSubscriptionGetReady] = useState(false)
  
  useEffect(()=>{

  },[isSubscribed])


  useEffect(() => {

    if( Object.keys(userDetails.user).length == 0 || !checked || !authenticated || !userDetails || !settings) return () => {}
    
    if (firebase.isSupported()) {
      firebase.requestNotificationPermission();
      firebase.getFirebaseToken().then(token => {
        console.log('token : ', token);
        notificationRequest.bindUserNotification({
          user_name     : user.user.userName,
          channel_type  : 'voip_android',
          device_token  : token
        }).catch(error => {
          handleHttpError({
            error,
            request: 'notificationRequest.bindUserNotification::src/routers'
          })
        })
        notificationRequest.bindUserNotification({
          user_name     : user.user.userName,
          channel_type  : 'fcm',
          device_token  : token
        }).then((response) => {
          firebase.handleOnReceiveMessage({
            history,
            iViewed,
            likedMe,
            viewedMe,
            saveNotification,
            setUserConnection,
            user : user.user,
            settings,
            updateTwilioVideoToken,
            updateChatRequest,
            updatePhoneRequest,
            updateVideoRequest,
            setDisabledConnection
          });
        }).catch(error => {
            handleHttpError({
              error,
              request: 'notificationRequest.bindUserNotification::src/routers1'
            })
        });
      }).catch(error => {
        console.log('error',error);
      });
    }
    
  }, [checked, authenticated, userDetails]);

  const reload = () => window.location.reload();

  return (
    checked && 
      <Switch>
        {
          authenticated ?
            isSignedIn ?
              <Switch>
                <Route exact path="/" render={(props) => {
                  return (<Redirect to={{ pathname: '/home', state: { from: props.location } }} />)
                }} />
                <Route exact path="/sub" render={(props) => {
                  return (<Redirect to={{ pathname: '/subscription', state: { from: props.location } }} />)
                }} />
                <PrivateRoute layout={Main} path="/home" component={Home} authenticated={authenticated} isUserEmpty={isEmpty(user)} isSignedIn={isSignedIn} isSubscribed={isSubscribed}/>
                <PrivateRoute layout={Main} path="/message-center/:tabname" component={MessageCenter} authenticated={authenticated} isUserEmpty={isEmpty(user)} isSignedIn={isSignedIn} isSubscribed={isSubscribed}/>
                <PrivateRoute layout={Main} path="/recent-views/:tabname" component={RecentViews} authenticated={authenticated} isUserEmpty={isEmpty(user)} isSignedIn={isSignedIn} isSubscribed={isSubscribed}/>
                <PrivateRoute layout={Main} path="/shortlist" component={Shortlist} authenticated={authenticated} isUserEmpty={isEmpty(user)} isSignedIn={isSignedIn} isSubscribed={isSubscribed}/>
                <PrivateRoute layout={Main} path="/search/:tabname" component={Search} authenticated={authenticated} isUserEmpty={isEmpty(user)} isSignedIn={isSignedIn} isSubscribed={isSubscribed}/>
                <PrivateRoute layout={Main} path="/calendar/:tabname" component={Calendar} authenticated={authenticated} isUserEmpty={isEmpty(user)} isSignedIn={isSignedIn} isSubscribed={isSubscribed}  />
                <PrivateRoute layout={Main} exact path="/profile/:username" component={Profile} authenticated={authenticated} isUserEmpty={isEmpty(user)} isSignedIn={isSignedIn} isSubscribed={isSubscribed}/>
                <PrivateRoute layout={Main} path="/settings/:tabname" component={Settings} authenticated={authenticated} isUserEmpty={isEmpty(user)} isSignedIn={isSignedIn} isSubscribed={isSubscribed}/>
                <PrivateRoute layout={None} path="/audiocall" component={AudioCall} authenticated={authenticated} isUserEmpty={isEmpty(user)} isSignedIn={isSignedIn} isSubscribed={isSubscribed}/>
                <PrivateRoute layout={None} path="/videocall" component={VideoCall} authenticated={authenticated} isUserEmpty={isEmpty(user)} isSignedIn={isSignedIn} isSubscribed={isSubscribed}/>
                <PublicRoute layout={None} path="/registration" component={Registration} authenticated={authenticated} isSignedIn={isSignedIn} user={user}/>
                <PrivateRoute layout={None} path="/subscription" component={Subscription} authenticated={authenticated} isUserEmpty={isEmpty(user)} isSignedIn={isSignedIn} isSubscribed={isSubscribed}/>
                <PrivateRoute layout={None} path="/forgotpassword" component={ForgotPassword} authenticated={authenticated} isUserEmpty={isEmpty(user)} isSignedIn={isSignedIn} isSubscribed={isSubscribed} />
                <PrivateRoute layout={None} path="/help/accountdeletion" component={AccountDeletion} authenticated={authenticated} isUserEmpty={isEmpty(user)} isSignedIn={isSignedIn} isSubscribed={isSubscribed}/>

                {/* set as default /help to change */}
                <PrivateRoute layout={None} path="/help" component={AccountDeletion} authenticated={authenticated} isUserEmpty={isEmpty(user)} isSignedIn={isSignedIn} isSubscribed={isSubscribed}/> 

                <Route component={NotFound} />
              </Switch>
            :
              <Switch>
                <PublicRoute layout={None} path="/referral" component={Referral} authenticated={authenticated} isSignedIn={isSignedIn}/>
                <PublicRoute layout={None} exact path="/" component={Welcome} authenticated={authenticated} isSignedIn={isSignedIn}/>
                <Route exact path="/apple-app-site-association" onEnter={reload} />
                { email && <PublicRoute layout={None} path="/forgotpassword" component={ForgotPassword} authenticated={authenticated} isSignedIn={isSignedIn}/>}
                <PublicRoute layout={None} exact refer path="/refer" authenticated={authenticated} isSignedIn={isSignedIn}/>
                <PublicRoute layout={None} path="/registration" component={Registration} authenticated={authenticated} isSignedIn={isSignedIn} user={user}/>
                <NeutralRoute layout={None} path="/privacypolicy" component={PrivacyPolicy} />
                <NeutralRoute layout={None} path="/help/accountdeletion" component={AccountDeletion} />

                {/* set as default /help to change */}
                <NeutralRoute layout={None} path="/help" component={AccountDeletion} /> 
                
                <NeutralRoute layout={None} path="/aboutus" component={AboutUs} />
                { isSignedIn !== undefined && <Route component={NotFound} /> }
              </Switch>   
          :
            <Switch>
              <NeutralRoute layout={None} path="/privacypolicy" component={PrivacyPolicy}/>
              <NeutralRoute layout={None} path="/help/accountdeletion" component={AccountDeletion} />
              <NeutralRoute layout={None} path="/help" component={AccountDeletion} />
              <NeutralRoute layout={None} path="/aboutus" component={AboutUs}/>
              <AuthRoute layout={None} path="/" component={Auth} authenticated={authenticated} />
            </Switch>
        }
        <PublicRoute layout={None} path="/registration" component={Registration} authenticated={authenticated} isSignedIn={isSignedIn} user={user}/>        
        <AuthRoute layout={None} component={Auth} authenticated={authenticated} />
      </Switch>
  )
};

/////////////////////////////
// mapStateToProps()
/////////////////////////////
const mapStateToProps = (state) => {
  console.log('routers/index.js:mapStateToProps(state)');
  console.log(state);
  return {
		isSignedIn            : state.Session.user.isSignedIn,
    settings              : state.Session.user.settings,
		authenticated         : state.Session.authenticated,
		checked               : state.Session.checked,
		user                  : state.Session.user,
    userDetails           : state.User,
		email                 : state.User.user.email,
    userSubscription      : state.User.userSubscription
  };
}

export default connect(mapStateToProps, { saveNotification, setUserConnection, updateTwilioVideoToken, updateChatRequest, updatePhoneRequest, updateVideoRequest, setDisabledConnection })(
  Routes
);
