import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Card, CardContent, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, FormControl, Grid, IconButton, Input, InputBase, List, ListItem, ListItemText, MenuItem, Modal, Select, TextField, Typography, ListItemAvatar, Avatar, ListSubheader, Checkbox } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';

import moment from 'moment';
import DateFnsUtils from '@date-io/date-fns';

import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import CalendarTodayOutlinedIcon from '@material-ui/icons/CalendarTodayOutlined';
import CancelIcon from '@material-ui/icons/CancelOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import EventNoteOutlinedIcon from '@material-ui/icons/EventNoteOutlined';
import HelpIcon from '@material-ui/icons/HelpOutlineOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import NotificationsOutlinedIcon from '@material-ui/icons/NotificationsOutlined';
import ScheduleOutlinedIcon from '@material-ui/icons/Schedule';

import useStyles from './style';

import { calendarRequest, notificationRequest, searchRequest } from '../../../service/requests';
import {
	ADD_EVENT,
	ALERT_SEVERITY_ERROR, 
	ALERT_SEVERITY_SUCCESS, 
  CALENDAR_INVITE_SENT, 
	CALENDAR_RESPONSE_GOING,
	CALENDAR_RESPONSE_NOT_GOING,
  CALENDAR_RESPONSE_PENDING,
	HTTP_STATUS,
	STATUS_ACTIVE, 
	STATUS_INACTIVE,
	TOAST_AXIOS_REQUEST_ERROR, 
	TOAST_EVENT_ADDED, 
	TOAST_EVENT_DELETED,
	TOAST_EVENT_UPDATED,
	TOAST_EVENT_UPDATE_FAILED
} from '../../../utils/constants';
import { notificationContent } from '../../../utils/notificationcontent';
import { Https } from '@material-ui/icons';
import { handleHttpError } from '../../../utils/helper';
let typingTimeout = undefined;

const getModalStyle = () => {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const EventDetails = (props) => {
	const classes = useStyles();
	const history = useHistory();
	const { 
		addCalendarEvent, 
		deleteMeetup,
		eventDetails,
		getRecentViews, 
		getShortlist,
		handleChangeEventDetails,
		initialEventDetails,
		isEventDetailsModalOpen,
		iViewed,
		mode,
		setEventDetails,
		setEventDetailsModalOpen,
		shortlist,
		showAlert, 
		updateMeetup,
		userId
	} = props;

	const [isSuggestedUserListHidden, setSuggestedUserListHidden] = useState(true);
	const [modalStyle] = useState(getModalStyle);
	const initialValidationValues = {
		isUsernameError  : false,
		isEventNameError : false,
		isLocationError  : false
	}
  	const [eventFormValidation, setEventFormValidation] = useState(initialValidationValues)
	const [recentlyViewed, setRecentlyViewed] = useState([]);
	const [searchResults, setSearchResults] = useState([]);
	const [eventResponse, setEventResponse] = useState(eventDetails.response ? eventDetails.response : CALENDAR_RESPONSE_PENDING);
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	const [userConnectionIssue, setUserConnectionIssue] = useState(false);
	const [isChecked, setChecked] = useState(false);
  
  	const reminderOptionsList = [
    {
      option 	: 'No Reminder',
      value	  :	0
    },
    {
      option 	: 'At Event',
      value	  :	1
    },
    {
      option 	: '15 minutes before',
      value	  :	15
    },
    {
      option 	: '30 minutes before',
      value	  :	30
    },
    {
      option 	: '1 hour before',
      value	  :	60
    },
    {
      option 	: '3 hours before',
      value	  :	180
    },
    {
      option 	: '6 hours before',
      value	  :	360
    },
    {
      option 	: '12 hours before',
      value	  :	720
    }
	];

	let recentlyViewedUsers = [];

	const getUniqueUsersInteractedToMe = () => {
		const userMap   = new Map();
		let uniqueUsers = [];
		
	
		for (const item of iViewed) {
			
			if (!userMap.has(item.user.userId)){
				userMap.set(item.user.userId, true);
				uniqueUsers.push(item);
			}
		}
	
		return uniqueUsers;
	}

	useEffect(() => {
		(shortlist.length === 0) && getShortlist();
		if (iViewed.length === 0) {
			getRecentViews();
		} else {
			recentlyViewedUsers = getUniqueUsersInteractedToMe();
			setRecentlyViewed(recentlyViewedUsers)
		}
	}, [])

	useEffect(() => {
		recentlyViewedUsers = getUniqueUsersInteractedToMe();
		setRecentlyViewed(recentlyViewedUsers)
	}, [iViewed])
	
	const handleSelectUser = (item) => {
		item?.message == STATUS_ACTIVE ? setUserConnectionIssue(false) : setUserConnectionIssue(true);
		setSuggestedUserListHidden(true);
		handleChangeEventDetails('username', item.user.userName);
		handleChangeEventDetails('user', item.user);
	}

	const handleChange = event => {
		setChecked(event.target.checked);
	};

	const handleSelectResponse = (event) => {
		setEventResponse(event.target.value);
    };
	
	const searchUsername = (userId, event, setEventDetails, setSearchResults) => {
		const { name, value } = event.target;
	
		typingTimeout && clearTimeout(typingTimeout);
	
		handleChangeEventDetails(name, value, setEventDetails);
	
		if (value == '') {
			setSearchResults([]);
			return
		}
	
		typingTimeout = setTimeout(() => {
			searchRequest.searchByUsername({
				user_id : userId,
				keyword : value
			}).then(response => {
				if (response?.data?.code == HTTP_STATUS._471) {
					setSearchResults([]);
				} else {
					setSearchResults(response.data.userConnection);
				}
			}).catch(error => {
				handleHttpError({
					error,
					request: "searchRequest.searchByUsername::src/component/calendar/eventdetails"
				})
			});
		}, 1000);
	}

	const closeEventDetailsModal = () => {
		setEventDetails(initialEventDetails);
		setEventFormValidation(initialValidationValues);
		setEventDetailsModalOpen(false);
		setUserConnectionIssue(false);
	};

	const saveEvent = () => {
		console.log('saveEvent()');
		console.log('eventDetails : ', eventDetails);

		if (mode === ADD_EVENT) {
	
			if ((eventDetails.username === '') || (eventDetails.eventName === '') || (eventDetails.location === '' && !isChecked)) {
				setEventFormValidation({
					isUsernameError : eventDetails.username === '',
					isEventNameError : eventDetails.eventName === '',
					isLocationError : eventDetails.location === ''
				})
			} else {
				const dateTimeUnix = moment(eventDetails.dateTime).unix()
				setEventDetailsModalOpen(false);
			
				calendarRequest.addCalendarEvent({
					user_id                 : eventDetails.userId,
					connection_user_id      : eventDetails.user.userId,
					meetup_name             : eventDetails.eventName,
					reminder                : eventDetails.reminder,
					event_place             : isChecked ? "No location" : eventDetails.location,
					street                  : '',
					city                    : '',
					state                   : '',
					zip_code                : '',
					date_start              : dateTimeUnix,
					date_end                : dateTimeUnix
				}).then(response => {
					console.log('response : ', response);
		
					if (response?.data?.status?.code == HTTP_STATUS._200) {
						addCalendarEvent(response.data.event);
			
						const sendInviteNotification = notificationContent(eventDetails.cUserName)[CALENDAR_INVITE_SENT];
						setChecked(false);
						
						notificationRequest.sendNotification({
							item_id         : response.data.event.meetup.meetupId,
							sender_id       : eventDetails.userId,
							receiver_id 	: eventDetails.user.userId,
							notif_type  	: sendInviteNotification.notifType,
							title           : sendInviteNotification.notifTitle,
							body            : sendInviteNotification.notifBody
						}).then(response => {
							console.log('response : ', response);
		
						}).catch(error => {
							console.log('error : ', error);
						});
				
						setEventDetails(initialEventDetails);
						showAlert(ALERT_SEVERITY_SUCCESS, TOAST_EVENT_ADDED);
					} else {
						showAlert(ALERT_SEVERITY_ERROR, TOAST_AXIOS_REQUEST_ERROR)
					}
				}).catch(error => {
					handleHttpError({
						error,
						request:"calendarRequest.addCalendarEvent::src/component/calendar/eventdetails"
					})
				});
			}
		} else {
			const updatedDateTime = moment(eventDetails.dateTime).unix();

			calendarRequest.updateMeetup({
				meetup_id   			 : eventDetails.meetupId,
				user_id 					 : eventDetails.userId,
				meetup_name				 : eventDetails.eventName,
				reminder					 : eventDetails.reminder,
				details						 : '',
				event_place				 : eventDetails.location,
				street					 	 : '',
				city					 		 : '',
				state					 		 : '',
				zip_code					 : '',
				date_start				 : updatedDateTime,
				date_end					 : updatedDateTime,
			}).then(response => {
				console.log('response : ', response);
				
				updateMeetup(response.data.meetup);
				
				showAlert(ALERT_SEVERITY_SUCCESS, TOAST_EVENT_UPDATED);

			}).catch(error => {
				handleHttpError({
					error,
					request: "calendarRequest.updateMeetup::src/component/calendar/eventdetails"
				})
			});
			closeEventDetailsModal();
		}
	}

	const deleteEvent = () => {
		console.log('deleteEvent()');

		const eventDateTime = moment(eventDetails.dateTime).unix();

		calendarRequest.updateMeetup({
			meetup_id   			 : eventDetails.meetupId,
			user_id 					 : eventDetails.userId,
			meetup_name				 : eventDetails.eventName,
			reminder					 : eventDetails.reminder,
			event_place				 : eventDetails.location,
			status					 	 : STATUS_INACTIVE,
			date_start				 : eventDateTime,
			date_end					 : eventDateTime
		}).then(response => {
			console.log('response : ', response);

			deleteMeetup(response.data.meetup.meetupId);
			
			showAlert(ALERT_SEVERITY_SUCCESS, TOAST_EVENT_DELETED);

		}).catch(error => {
			handleHttpError({
				error,
				request: "calendarRequest.updateMeetup::src/component/calendar/eventdetails"
			})
		});
		toggleModal();
		closeEventDetailsModal();
	}

	const toggleModal = () => {
		setIsDialogOpen(prevIsDialogOpen => !prevIsDialogOpen);
	};

	const viewProfile = () => {
		history.push(`/profile/${eventDetails.username}?tab=profile`);
	};
	
	const disabledButton = userConnectionIssue || eventDetails.username === '' || eventDetails.eventName === '' || eventDetails.dateTime === '' || (eventDetails.location === '' && !isChecked) || eventDetails.reminder === '';
  
	return (
		<>
			<Modal
				aria-labelledby='create-event-modal'
				aria-describedby='create-event-modal-description'
				open={isEventDetailsModalOpen}
				onClose={closeEventDetailsModal}
				BackdropProps={{ className : classes.backdrop }}
			>
				<div style={modalStyle} className={classes.modalPaper}>
					<div className={classes.closeButtonContainer}>
						<IconButton aria-label='close' onClick={closeEventDetailsModal}>
							<CloseOutlinedIcon />
						</IconButton>
					</div>
					<div className={classes.modalPaperContents}>
						<div className={classes.eventInfoRow}>
							<Grid container spacing={3} alignItems='flex-start'>
								<Grid xs={1} item>
									<AccountCircleOutlinedIcon color='primary' />
								</Grid>
								<Grid item xs={1}>
									<TextField 
										id='username'
										name='username'
										inputProps={{ className : classes.eventDetailsInput }} 
										fullWidth 
										placeholder='Participant Name' 
										value={eventDetails.username}
										onChange={(event) => searchUsername(userId, event, setEventDetails, setSearchResults)}
										autoComplete='off'
										onFocus={() => setSuggestedUserListHidden(false)}
										required
										error={eventFormValidation.isUsernameError}
										// helperText={eventFormValidation.isUsernameError ? 'Required.' : ''}
									/>
									<Card hidden={isSuggestedUserListHidden}>
										<CardContent className={classes.cardContent}>
											{
												(shortlist.length > 0) &&
												<List>
													<ListSubheader disableSticky>Shortlist</ListSubheader>
													{
														shortlist.filter((user)=> user.isReachabilityStatusOn === 1).map((item, index) => {
															if (item.user.userName.includes(eventDetails.username)) {
																return (
																	<ListItem key={index} button onClick={() => handleSelectUser(item)}>
																		<ListItemAvatar>
																			<Avatar alt={item.user.userName} src={item.user.mediaProfile} />
																		</ListItemAvatar>
																		<ListItemText>{item.user.userName}</ListItemText>
																	</ListItem>
																)
															}
														})
													}
												</List>
											}
											{
												(recentlyViewed.length > 0) &&
												<List>
													<ListSubheader disableSticky>Recently Viewed</ListSubheader>
													{
														recentlyViewed.map((item, index) => {
															if (item.user.userName.includes(eventDetails.username)) {
																return (
																	<ListItem key={index} button onClick={() => handleSelectUser(item)}>
																		<ListItemAvatar>
																			<Avatar alt={item.user.userName} src={item.user.mediaProfile} />
																		</ListItemAvatar>
																		<ListItemText>{item.user.userName}</ListItemText>
																	</ListItem>
																)
															}
														})
													}
												</List>
											}
											{
												(searchResults.length > 0) && 
												<List>
													<ListSubheader disableSticky>Search Results</ListSubheader>
													{
														searchResults.map((item, index) => {
															return (
																<ListItem key={index} button onClick={() => handleSelectUser(item)}>
																	<ListItemAvatar>
																		<Avatar alt={item.user.userName} src={item.user.mediaProfile} />
																	</ListItemAvatar>
																	<ListItemText>{item.user.userName}</ListItemText>
																</ListItem>
															)
														})
													}
												</List>
											}
										</CardContent>
									</Card>
								</Grid>
							</Grid>
						</div>
						<div className={classes.eventInfoRow}>
							<Grid container spacing={3} alignItems='flex-start'>
								<Grid xs={1} item>
									<EventNoteOutlinedIcon color='primary' />
								</Grid>
								<Grid xs item>
									<TextField 
										id='eventName'
										name='eventName'
										inputProps={{ className : classes.eventDetailsInput }} 
										fullWidth 
										placeholder='Event Name' 
										value={eventDetails.eventName}
										autoComplete='off'
										onFocus={() => setSuggestedUserListHidden(true)}
										onChange={(event) => {
											const { name, value } = event.target;

											handleChangeEventDetails(name, value)
										}}
										required
										error={eventFormValidation.isEventNameError}
										// helperText={eventFormValidation.isEventNameError ? 'Required.' : ''}
									/>
								</Grid>
							</Grid>
						</div>
						<div className={classes.eventInfoRow}>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<Grid container alignItems='center' spacing={3} justify='space-around'>
								<Grid xs={1} item></Grid>
								<Grid xs item>
									<KeyboardDatePicker
										margin='normal'
										id='date-picker-dialog'
										label='Date'
										format='MM/dd/yyyy'
										value={moment(eventDetails.dateTime).format('YYYY-MM-DD').toString()}
										onChange={(date) => handleChangeEventDetails('dateTime', date)}
										onOpen={() => setSuggestedUserListHidden(true)}
										keyboardIcon={<CalendarTodayOutlinedIcon color='primary' />}
										KeyboardButtonProps={{
											'aria-label': 'change date',
											onClick : () => setSuggestedUserListHidden(true)
										}}
										disablePast={mode === ADD_EVENT}
									/>
								</Grid>
								<Grid xs item>
									<KeyboardTimePicker
										margin='normal'
										id='time-picker'
										label='Time'
										value={eventDetails.dateTime}
										onChange={(date) => handleChangeEventDetails('dateTime', date)}
										onOpen={() => setSuggestedUserListHidden(true)}
										onAccept={(date)=>{
											let today = new Date();

											if(date.getTime() < today.getTime()){
                        						handleChangeEventDetails('dateTime', today);
												showAlert(ALERT_SEVERITY_ERROR, `This date won't work`);
											}
										}}
										KeyboardButtonProps={{
											'aria-label': 'change time',
										}}
										keyboardIcon={<ScheduleOutlinedIcon color='primary' />}
									/>
								</Grid>
							</Grid>
						</MuiPickersUtilsProvider>
						</div>
						<div className={classes.eventInfoRow}>
							<Grid container spacing={3} alignItems='flex-start'>
								<Grid xs={1} item>
									<LocationOnOutlinedIcon color='primary' />
								</Grid>
								<Grid xs item>
									<Input 
										id='location'
										name='location'
										inputProps={{ className : classes.eventDetailsInput }} 
										fullWidth 
										placeholder='Location' 
										value={eventDetails.location}
										disabled={isChecked}
										autoComplete='off'
										onFocus={() => setSuggestedUserListHidden(true)}
										onChange={(event) => {
											const { name, value } = event.target;

											handleChangeEventDetails(name, value);
										}}
										required
										error={eventFormValidation.isLocationError}
										// helperText={eventFormValidation.isLocationError ? 'Required.' : ''}
									/>
									<Box style={{display: 'flex', flexDirection: 'row'}}>
										<Checkbox
											checked={isChecked}
											onChange={handleChange}
											color="primary"
										/>
										<Typography variant="subtitle2" color="textPrimary" className={classes.noLocText}>
											No location
										</Typography>
									</Box>
								</Grid>

							</Grid>
						</div>
						<div className={classes.eventInfoRow}>
							<Grid container spacing={3} alignItems='flex-start'>
								<Grid xs={1} item>
									<NotificationsOutlinedIcon color='primary' />
								</Grid>
								<Grid xs item>
									<FormControl>
										<Select
											id='reminder'
											name='reminder'
											value={eventDetails.reminder}
											onFocus={() => setSuggestedUserListHidden(true)}
											onChange={(event) => {
												const { name, value } = event.target;

												handleChangeEventDetails(name, value);
											}}
										>
											{
												reminderOptionsList.map((item, index) => {
													return (
														<MenuItem key={index} value={item.value}>{item.option}</MenuItem>
													)
												})
											}
										</Select>
									</FormControl>
								</Grid>
							</Grid>
						</div>
						{	
							userConnectionIssue &&
								<Grid className={classes.userConnectionIssue}>
									<Typography variant='subtitle1' className={classes.errorMessage}>
										You and {eventDetails.username} must be connected via chat first to enable calendar invite.
									</Typography>
									<Button color='primary' variant='contained' className={classes.connectBtn} onClick={viewProfile}>Connect</Button>
								</Grid>
						}
						{
							(mode !== ADD_EVENT) &&
							<div className={classes.eventInfoRow}>
								<Grid container xs={12} spacing={3} alignItems='flex-start'>
									<Grid xs={1} item></Grid>
									<Grid xs item>
										<Box display='flex' flexDirection='row' alignItems='center'>
											<Typography variant='subtitle1' color='textPrimary' className={classes.center}>
												Response:
											</Typography>
											<Select
												labelId="demo-customized-select-label"
												id="demo-customized-select"
												value={eventResponse}
												onChange={handleSelectResponse}
												input={<InputBase className={classes.responseInput} />}
											>
												<MenuItem value={CALENDAR_RESPONSE_PENDING}>
													<div className={classes.responseOptions}>
														<HelpIcon color='primary' />
														<Typography variant='subtitle1' color='textPrimary' className={classes.responseText}>
															Pending
														</Typography>
													</div>
												</MenuItem>
												<MenuItem value={CALENDAR_RESPONSE_GOING}>
													<div className={classes.responseOptions}>
														<CheckCircleIcon className={classes.acceptedResponseIcon} />
														<Typography variant='subtitle1' color='textPrimary' className={classes.responseText}>
															Accept
														</Typography>
													</div>
												</MenuItem>
												<MenuItem value={CALENDAR_RESPONSE_NOT_GOING}>
													<div className={classes.responseOptions}>
														<CancelIcon className={classes.declinedResponseIcon} />
														<Typography variant='subtitle1' color='textPrimary' className={classes.responseText}>
															Decline
														</Typography>
													</div>
												</MenuItem>
											</Select>
										</Box>
									</Grid>
								</Grid>
							</div>
						}
						<Box py={2}>
						{
							(mode === ADD_EVENT)
							? <Grid container alignItems='center' justify='center' className={classes.saveButtonContainer}>
									<Grid item className={classes.saveButtonGridItem}>
										<Button color='primary' variant='contained' onClick={saveEvent} disabled={disabledButton}>Save</Button>
									</Grid>
								</Grid>
							: <Grid container justify={eventDetails.isEventCreator ? 'space-between' : 'flex-end'}>
									{
										eventDetails.isEventCreator &&
										<Grid item>
											<Button variant="outlined" color='primary' onClick={toggleModal}>Delete</Button>
										</Grid>
									}
									<Grid item>
										<Button color='primary' variant='contained' onClick={saveEvent}>Save</Button>
									</Grid>
								</Grid>
						}
						</Box>
					</div>
				</div>
			</Modal>
			<Dialog
				open={isDialogOpen}
				onClose={toggleModal}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">Delete Event</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Are you sure that you want to delete this event?
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={toggleModal} color="primary" variant="contained" autoFocus>
						No
					</Button>
					<Button onClick={deleteEvent} color="primary" variant="outlined">
						Yes
					</Button>
				</DialogActions>
			</Dialog>
		</>
  )
}

export default EventDetails;