import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../utils/colors';

const useStyles = makeStyles(theme => ({

  // Basic Info
  '& span .MuiFormControlLabel-label': {
    color: colors.TEXT_GRAY
  },

  gridHeader: {
    marginTop: theme.spacing(4),
    display: 'flex',
    justifyContent: 'space-between'
  },

  sos: {
    height: 64,
    [theme.breakpoints.down('sm')]: {
      height: 32
    },
  },

  gridItem: {
    width: '100%',
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
    },
  },

  content: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      textAlign: 'center',
    },

    '& .MuiFormControl-root': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },

    '& .MuiTypography-root': {
      marginTop: theme.spacing(1),
    },
  },

  textField: {
    width: 270,

    '&  .MuiInputLabel-outlined': {
      transform: 'translate(14px, 12px) scale(1)'
    },

    '&  .MuiInputLabel-outlined.MuiInputLabel-shrink':{
      transform: 'translate(14px, -6px) scale(0.75)'
    },

    '& .MuiFormGroup-root .MuiFormControlLabel-root .MuiTypography-root': {
      color: colors.TEXT_GRAY
    },
    
    '& > .MuiFormHelperText-root': {
      color: 'red'
    },

    '& > .MuiInputBase-root': {
      height : 40
    }
  },

  genderFieldError: {
    color : 'red'
  },

  mobileNumber: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },

    '& > .MuiBox-root > .MuiFormControl-root': {
      borderBottom: `1px solid ${colors.LIGHT_GRAY}`,
    },

    '& > .MuiFormHelperText-root': {
      color: 'red',
    },

    '& > .MuiBox-root > .MuiFormControl-root:last-of-type': {
      width : 160,
    },

    '& > .MuiBox-root > .MuiFormControl-root > .MuiInputBase-root': {
      height : 40
    }
  },

  gender: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },

    '& > .MuiBox-root > .MuiFormControl-root': {
      borderBottom: `1px solid ${colors.LIGHT_GRAY}`,
    },

    '& > .MuiFormHelperText-root': {
      color: 'red',
    }
  },

  radioGroup: {
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },

    '& .MuiTypography-root': {
      color: colors.PRIMARY_TEXT
    },
  },

}));

export default useStyles;