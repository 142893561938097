import { makeStyles } from '@material-ui/core/styles';
import colors from '../../utils/colors';

const useStyles = makeStyles(theme => ({

  // GENERAL
  info: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    padding: 2
  },

  column: {
    alignItems: 'flex-start',
    justifyContent: 'center',
    flexDirection: 'column',
  },

  icon: {
    backgroundColor: colors.PRELOADER_SHADOW,
    borderRadius: 24,
    height: 24,
    marginLeft: 5,
    marginRight: 5,
    width: 24
  },

  text: {
    backgroundColor: colors.PRELOADER_SHADOW,
    height: 18,
    marginBottom: 3, 
    marginTop: 3,
  },

  title: {
    borderRadius: 4,
    backgroundColor: colors.PRELOADER_SHADOW,
    height: 24,
    marginBottom: 3, 
    marginTop: 3,
  },

  // PRELOADER CARD USER
  userCard: {
    backgroundColor: colors.PRELOADER_BACKGROUND,
    width: '100%'
  },

  userImage: {
    backgroundColor: colors.PRELOADER_SHADOW,
    width: 'inherit',
    [theme.breakpoints.up('xs')]: {
      height: 150,
    },
    [theme.breakpoints.up('sm')]: {
      height: 150,
    },
    [theme.breakpoints.up('md')]: {
      height: 200,
    },
    [theme.breakpoints.up('lg')]: {
      height: 200,
    },
  },

  userContent: {
    padding: 5,
  },

  connectionContainer: {
    padding: 2,
    display: 'flex',
    justifyContent: 'space-between'
  },

  // PRELOADER CARD SHORTLIST
  shortlistCard: {
    width: "100%",
    backgroundColor: colors.PRELOADER_BACKGROUND,
  },

  shortlistImage: {
    borderRadius: 4,
    backgroundColor: colors.PRELOADER_SHADOW,
    width: 'inherit',
    [theme.breakpoints.up('xs')]: {
      height: 150,
    },
    [theme.breakpoints.up('sm')]: {
      height: 150,
    },
    [theme.breakpoints.up('md')]: {
      height: 200,
    },
    [theme.breakpoints.up('lg')]: {
      height: 200,
    },
  },

  shortlistContent: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10, 
    height: 70,
    maxHeight: 70,
    alignItems: 'center',
  },

  // PRELOADER CARD VIEW
  viewCard: {
    backgroundColor: colors.PRELOADER_BACKGROUND,
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
  },

  viewImage: {
    height: 0,
    borderRadius: 4,
    paddingTop: '100%', // 1:1

    // height: 'inherit',
    // minWidth: 100,
    // width: 100,
    backgroundColor: colors.PRELOADER_SHADOW
  },

  viewContent: {
    flex: '1',
    padding: theme.spacing(1)
  },

  // PRELOADER CARD NEAR ME
  nearMeCard: {
    backgroundColor: colors.PRELOADER_BACKGROUND,
    display: 'flex',
    padding: 5,
    position: 'relative'
  },

  nearMeImage: {
    backgroundColor: colors.PRELOADER_SHADOW,
    borderRadius: 4,
    height: 'inherit',
    minWidth: 150,
    width: 150,
  },

  nearMeConnectionContainer: {
    marginTop: 6,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    alignItems: 'center',
  },

  heart: {
    marginLeft: 5,
    borderRadius: 4,
    backgroundColor: colors.PRELOADER_SHADOW,
    width: 100,
    height: 24,
  },

  // PRELOADER MESSAGE CENTER CARDS
  messageCenterCard: {
    alignItems: 'center',
    backgroundColor: colors.PRELOADER_BACKGROUND,
    display: 'flex',
    padding: 5,
    position: 'relative'
  },

  messageCenterImage: {
    backgroundColor: colors.PRELOADER_SHADOW,
    margin: theme.spacing(2),
    [theme.breakpoints.up('xs')]: {
      height: 50,
      width: 50,
      borderRadius: 50
    },
    [theme.breakpoints.up('sm')]: {
      height: 70,
      width: 70,
      borderRadius: 70
    },
    [theme.breakpoints.up('md')]: {
      height: 70,
      width: 70,
      borderRadius: 70
    }
  },

  contentView: {
    flex: '1',
    alignSelf: 'center',
  },

  participantUsername: {
    marginBottom: 5
  },

  // PRELOADER USER PROFILE
  userProfileContainer: {
    borderRadius: 4,
    padding: theme.spacing(5),
    backgroundColor: colors.PRELOADER_BACKGROUND
  },

  box: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: theme.spacing(2),
  },

  userProfileImage: {
    borderRadius: theme.spacing(1),
    height: 250,
    width: 250,
    backgroundColor: colors.PRELOADER_SHADOW
  },

  userProfileInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(3),
  },

  userProfileUsername: {
    borderRadius: 4,
    fontWeight: 'bold',
    color: colors.PRELOADER_SHADOW,
    backgroundColor: colors.PRELOADER_SHADOW,
  },

  userProfileAddress: {
    marginTop: theme.spacing(1),
    borderRadius: 4,
    color: colors.PRELOADER_SHADOW,
    backgroundColor: colors.PRELOADER_SHADOW,
  },

  connectionContainer: {
    display: 'flex',
  },

  userProfileRating: {
    marginTop: theme.spacing(1),
    borderRadius: 4,
    backgroundColor: colors.PRELOADER_SHADOW,
    width: 136,
    height: 36,
  },

  userProfileHeart: {
    borderRadius: '50%',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1),
    backgroundColor: colors.PRELOADER_SHADOW,
    width: 36,
    height: 36,
  },

  userProfileContent: {
    height: 500,
    borderRadius: 4,
    backgroundColor: colors.PRELOADER_SHADOW,
  },

  // PRELOADER SAVED SEARCH
  savedSearchContainer: {
    backgroundColor: colors.PRELOADER_BACKGROUND,
    borderRadius: theme.spacing(1),
    display: 'flex',
    padding: theme.spacing(2),
    justifyContent: 'space-between',
  },

  // PRELOADER MESSAGE
  messageContainer: {
    borderRadius: 5,
    minHeight: 24,
    height: 24,
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    margin: theme.spacing(0.25),
  },

  received: {
    marginRight: 'auto'
  },

  sent: {
    marginLeft: 'auto'
  },

  // PRELOADER HISTORY
  historyContainer: {
    backgroundColor: colors.PRELOADER_BACKGROUND,
    display: 'flex',
    marginBottom: theme.spacing(1),
    justifyContent: 'space-between',

    '& > .MuiBox-root': {
      display: 'flex',

      '& > div:nth-child(2)': {
        alignSelf: 'center',
        marginLeft: theme.spacing(2),
      },

    },

    '& > .MuiBox-root:last-of-type': {
      alignSelf: 'center',
      marginRight: theme.spacing(2)
    }
  },

  historyIcon: {
    backgroundColor: colors.PRELOADER_SHADOW,
    height: 40,
    width: 40,
  },

  // PRELOADER CALENDAR EVENTS
  eventStatus: {
    alignItems : 'center',
    backgroundColor: colors.PRELOADER_SHADOW,
    borderRadius : 20,
    justifyContent : 'space-around',
    height : '100%',
    padding: theme.spacing(1, 2),
    width : 150
  },

  date: {
    backgroundColor: colors.PRELOADER_SHADOW,
    borderRadius: 4,
    height: 24,
    marginBottom: 10
  },

  eventText: {
    backgroundColor: colors.PRELOADER_SHADOW,
    borderRadius: 4,
    height: 24,
    marginLeft : 10
  },

  // PRELOADER SEARCHLIST HOME
  searchlistHome: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: 140
    }
  },

  // PRELOADER SHORTLIST HOME
  shortlistHome: {
    display: 'inline-block !important',
    margin: 10
  },

  shortlistHomeText: {
    margin: 5,
  },

  shortlistImageHome: {
    borderRadius: 10,
    backgroundColor: colors.PRELOADER_SHADOW,
    width: 120,
    height: 120
  },

  shortlistImageHomeWrapper: {
    width: '-webkit-fill-available !important'
  },

  // Unanswered Messages
  unansweredMessageCard: {
    alignItems: 'center',
    backgroundColor: colors.PRELOADER_BACKGROUND,
    display: 'flex',
    borderRadius: '10px',
    boxShadow: 'none',
    position: 'relative',
    margin: '1em'
  },

  unansweredMessageImage: {
    backgroundColor: colors.PRELOADER_SHADOW,
    margin: theme.spacing(2),
    height: 60,
    width: 60,
    borderRadius: 60/2,
  },

  // Upcoming Events
  upcomingEventCard: {
    display: 'flex',
    border: 'none',
    boxShadow: 'none',
    borderRadius: '10px',
    margin: '1em'
  },

  upcomingEventDate: {
    display: 'inline-flex',
    borderRadius: 10,
    width: 70,
    height: 100,
  },

  upcomingEventInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: '0.5em',
    minWidth: 0,
  },

  upcomingEventText: {
    marginLeft: 10
  },

  referralCode: {
    height: 41,
    display: 'block',
    marginBottom: 30,
    [theme.breakpoints.down("sm")]: {
      height: 22
    }
  },

  blockedUserCard: {
    alignItems: 'center',
    backgroundColor: colors.PRELOADER_BACKGROUND,
    display: 'flex',
    borderRadius: 10,
    boxShadow: 'none',
    position: 'relative',
    margin: 8,
    width: '100%'
  },

  blockedUserImage: {
    backgroundColor: colors.PRELOADER_SHADOW,
    margin: 8,
    height: 50,
    width: 50,
    borderRadius: 50/2,
  },

  blockedUsername: {
    marginLeft: '10%'
  },

  //PRELOADER_COUPON
  couponCard: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    overflow: 'visible',
    width: 600,
    minHeight: 100,
    borderColor: colors.PRIMARY,
    backgroundColor: colors.PRELOADER_SHADOW,
    borderWidth: 2,
    borderStyle: 'solid',
    position: 'relative',
  },

  left: {
    width: 15,
    height: 30,
    borderTopRightRadius: 30,
    borderBottomRightRadius: 30,
    borderColor: colors.PRIMARY,
    backgroundColor: colors.BACKGROUND_COLOR,
    borderWidth: 2,
    borderStyle: 'solid',
    overflow: 'visible',
    position: 'absolute',
    borderWidth: 2,
    borderLeftWidth: 0,
    left: -2,
    zIndex: 100
  },

  right: {
    width: 15,
    height: 30,
    borderTopLeftRadius: 30,
    borderBottomLeftRadius: 30,
    borderColor: colors.PRIMARY,
    backgroundColor: colors.BACKGROUND_COLOR,
    borderWidth: 2,
    borderStyle: 'solid',
    position: 'absolute',
    borderWidth: 2,
    borderRightWidth: 0,
    right: -2,
    zIndex: 100
  },
}));

export default useStyles;