import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, TextField, Card, FormControl, Select, MenuItem, FormHelperText, CircularProgress } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import useStyles from './style';
import countryByDialInfo from '../../../../assets/json/country-by-dial-info.json';
import { userRequest } from '../../../../service/requests';
import { formatPhoneNumber, handleHttpError } from '../../../../utils/helper';
import { ALERT_SEVERITY_SUCCESS, ALERT_SEVERITY_ERROR, TOAST_AXIOS_REQUEST_ERROR, UPD_MODE_USER_PHONE_NO, HTTP_STATUS} from '../../../../utils/constants';

const ChangePhoneNumber = (props) => {
  const {  showAlert, user, updateUser, toggleModal  } = props;

  const classes = useStyles();

  const [isSaving, setIsSaving] = useState();
  const [state, setState] = useState({
    mobileNumber          : '',
    countryCode           : '+1',
    isMobileNumberError   : false,
    mobileNumberErrorDesc : '',
  });

  const { mobileNumber, countryCode, isMobileNumberError, mobileNumberErrorDesc } = state;

  const handleCodeChange = (event) => {
    setState({
      ...state,
      countryCode : event.target.value
    });
  }

  const handleInputChange = (event) => {
    let value = event.target.value;
    value = formatPhoneNumber(value);
    setState({
      ...state,
      mobileNumber : value
    });
  }

  const changeMobileNumber = () => {
    const mobileNumberStripped = mobileNumber.replace(/[^\d]/g, "");
    setState({
      ...state,
      isMobileNumberError   : mobileNumberStripped === '' || mobileNumberStripped.length != 10 ? true : false,
      mobileNumberErrorDesc : mobileNumberStripped === '' ? 'Required' : mobileNumberStripped.length != 10 ? 'Enter a valid mobile number' : '',
    });

    if (mobileNumberStripped === '' || mobileNumberStripped.length != 10) return
    
    setIsSaving(prevIsSaving => !prevIsSaving);

    userRequest.updateUser({
      user_id     : user.userId,
      upd_mode    : UPD_MODE_USER_PHONE_NO,
      who_updated	: user.userId,
      phone_no    : countryCode + mobileNumberStripped,
    }).then(response => {
      if (response?.data?.status?.code != HTTP_STATUS._200) {
        let { status } = response?.data;
        throw new Error(`${status?.code} ${status?.description}`);
      }
      updateUser(response?.data?.userDetail?.user);
      showAlert(ALERT_SEVERITY_SUCCESS, 'We sent a code to your mobile phone number.');
      toggleModal();
    }).catch(error => {
      handleHttpError({
        error,
        request: 'userRequest.updateUser::src/views/registration/verify-account/change-phone-number'
      })
    }).finally(() => {
      setIsSaving(prevIsSaving => !prevIsSaving);
    })
  }

  return (
    <Box className={classes.modalWrapper} width={1/4} height={1/2}>
      <Card className={classes.modalContent}>
        <Button className={classes.closeButton} onClick={toggleModal}> <CloseIcon fontSize="small"/> </Button>
        <Typography className={'text-bold'} variant="h6" color="textPrimary">Enter Your Mobile Number</Typography>
        <Box display="flex">
          <FormControl className={classes.formControl} error={isMobileNumberError}>
            <Select
              variant="outlined"
              value={countryCode}
              onChange={handleCodeChange}
            >
              {
                countryByDialInfo.filter((countryItem) => countryItem.dial_code != null).map((countryItem, index) => 
                  <MenuItem key={index} value={countryItem.dial_code}>{`${countryItem.flag} ${countryItem.code} ${countryItem.dial_code}`}</MenuItem>    
                )
              }
            </Select>
          </FormControl>
          <TextField
            placeholder='(XXX) XXX-XXXX'
            name='mobileNumber'
            value={mobileNumber}
            onChange={handleInputChange}
            required
            error={isMobileNumberError}
            variant="outlined"
            size="small"
          />
        </Box>
        {
          isMobileNumberError && <FormHelperText>{mobileNumberErrorDesc}</FormHelperText>
        }
        <Typography variant="p" color="textPrimary">S.O Search will send a verification code in this number.</Typography>
        <Box marginTop={5}>
          <Button
            disabled={(mobileNumber.replace(/[^\d]/g, "").length != 10) || isSaving} 
            color="primary" variant="contained" className={`btn-min-width`}
            onClick={changeMobileNumber}
          >
            { !isSaving ? 'Next' : <CircularProgress size={24} /> }
          </Button>
        </Box>
      </Card>
    </Box>
  )
}

export default ChangePhoneNumber;