import React, { useState, useEffect, useRef } from 'react';
import { Container, Grid, Card, CardContent, Link, Modal, Box, CircularProgress, Button, Fade, Backdrop, Typography, TextField, IconButton } from '@material-ui/core';
import { connect } from 'react-redux';
import { useStyles } from './style';
import { stripeRequest } from '../../../service/requests';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';

const ConfirmModal = (props) => {

  const { setStatus, confirmUrl, setConfirmUrl } = props
  const iframeDivRef = useRef()
  const classes = useStyles()


  const on3DSComplete = (event) => {
    if (event.data.type === 'stripe-3ds-fallback' && event.origin.includes('stripe')) {
      setStatus('validating')
      setConfirmUrl('')
    }
  }

  useEffect(() => {
    window.addEventListener('message', on3DSComplete);

    return () => {
      window.removeEventListener('message', on3DSComplete)
    }

  }, [])

  return (
    <React.Fragment>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={confirmUrl !== ''}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={confirmUrl !== ''}>
            <iframe ref={iframeDivRef} src={confirmUrl} className={classes.paymentCard} frameBorder="0" />
        </Fade>
      </Modal>
    </React.Fragment>
  )


}

/////////////////////////////
// mapStateToProps()
/////////////////////////////
function mapStateToProps(state) {
  return {

  };
}

export default connect(mapStateToProps, {})(
  ConfirmModal
);