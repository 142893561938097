import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Box, Typography, Card, Tabs, Tab, AppBar, Button, Modal } from '@material-ui/core';
import useStyles from './style';

import { ALERT_SEVERITY_SUCCESS, TOAST_SUBSCRIPTION_PLAN_CHANGED, USER_SUBSCRIPTION_TRIAL_ID, USER_SUBSCRIPTION_REGULAR_ID, STATUS_ACTIVE, LOOK_UP_SUBSCRIPTION_PLAN } from '../../../utils/constants';

import CardPlan from '../../settings/my-account/subscription-plan/CardPlan';
import ConfirmModal from '../../../views/subscription/confirmModal';
import PaymentModal from '../../../views/subscription/paymentModal'
import SnackBar from '../../../components/snackbar';

import DiamondIcon from '../../../assets/images/diamond.png'
import SunGlasses from '../../../assets/images/sun-glasses.png'
import confetti from '../../../assets/images/confirmation-confetti.png';

import moment from 'moment';

////////////////////////
///// allyProps()
///////////////////////
const allyProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    key: index
  };
}

////////////////////////
///// TapPanel()
///////////////////////
const TabPanel = (props) => {
  const { children, value, index } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

////////////////////////
// appendSubscriptionDetails()
///////////////////////
const appendSubscriptionDetails = (subscriptionDetail, item) =>{
  if(subscriptionDetail.code === item.membershipLevelCode){
    return {
      ...subscriptionDetail,
      membership: [
        ...subscriptionDetail.membership,
        item
      ]
    }      
  }
  return subscriptionDetail
}


const Subscription = forwardRef((props, ref) => {
  const { Lookup, getLookUpFields, next, user, userSubscription, token, signInUser } = props;

  const classes = useStyles();

  const [value, setValue] = useState(0);
  const [selectedSubscription, setSelectedSubscription] = useState({})
  const [changeSubscriptionConfirm, setChangeSubscriptionConfirm] = useState(false);
  const [paymentModalDetails, setPaymentModalDetails] = useState({})
  const [openToast, setOpenToast] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [status, setStatus] = useState('none')
  const [confirmUrl, setConfirmUrl] = useState('')
  const isPaymentModalOpen = Object.keys(paymentModalDetails).length > 0 && paymentModalDetails.constructor === Object

  useEffect(() => {
    getLookUpFields(LOOK_UP_SUBSCRIPTION_PLAN)
  }, []);

  useImperativeHandle(ref, () => ({
    triggerNext() {
      toggleModal();
    }
  }));

  const toggleModal = () => {
    setIsModalOpen(prevIsModalOpen => !prevIsModalOpen);
  };

  const handleClick = () => {
    next();
    signInUser({
      user : user,
      token : token
    });
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSubscriptionClick = (stripeProductId, subscriptionPlanId, membershipDetail) => {
    setStatus('none')
    setSelectedSubscription({
      stripeProductId, subscriptionPlanId, membershipDetail
    })

    if (userSubscription.status === STATUS_ACTIVE) {
      setChangeSubscriptionConfirm(true)
    } else {
      getSubscriptionDetails(stripeProductId, subscriptionPlanId, membershipDetail)
    }
  }

  const handleConfirm = async () => {
    getSubscriptionDetails(selectedSubscription.stripeProductId, selectedSubscription.subscriptionPlanId, selectedSubscription.membershipDetail)
  }

  const getSubscriptionDetails = async (stripeProductId, subscriptionPlanId, membershipDetail) => {
    const priceId = stripeProductId;
    const userId = user.userId ? user.userId : '';
    const email = user.email ? user.email : '';
    membershipDetail.subscriptionPlanId === USER_SUBSCRIPTION_REGULAR_ID && setStatus('validating')
    try {
      setPaymentModalDetails({
        userId,
        email,
        priceId,
        subscriptionPlanId,
        membershipDetail
      })
      setChangeSubscriptionConfirm(false)
    } catch (e) {
      console.log('billing error: ', e)
    }
  }

  let subscriptionDetails = [
    {
      code: 'REG',
      subscription: 'Regular',
      description: 'Build your S.O. Search profile, define who you are looking for and let the world’s leading Personal Relationship Management (PRM) tool assist you with finding that “Significant Other” friend or mate you are searching for.',
      membership: []
    },
    {
      code: 'PRV',
      subscription: 'Privacy',
      description: 'Your S.O. Search profile is visible to all users, but your pictures remain hidden until you initiate a Chat conversation with another user. Only then are your pictures revealed to that user. Privacy level users can switch between Regular and Privacy levels at any time.',
      membership: []
    },
    {
      code: 'ELT',
      subscription: 'Elite',
      description: 'Your S.O Search profile is hidden to all but Elite Membership users, including while using Near Me. Elite Members can see your profile, but your pictures remain hidden until you initiate a Chat conversation with another Elite Member. Elite Member users are identified with a Diamond next to their UserName. Elite Members can also view and communicate with Regular and Privacy Members users. Elite members can change their viewable status by switching between Regular, Privacy and Elite levels at anytime.',
      membership: []
    }
  ];

  //Filtering the 2 month free trial for now
  let filteredSubscriptionPlan =  Lookup.lookUpSubscriptionPlan.filter((subscriptionPlan) => subscriptionPlan.subscriptionPlanId !== USER_SUBSCRIPTION_TRIAL_ID )

  filteredSubscriptionPlan.map(item => {
    let updatedSubscriptionDetails = subscriptionDetails.map(subscriptionDetail => {
        return appendSubscriptionDetails(subscriptionDetail, item)
    });
    subscriptionDetails = updatedSubscriptionDetails;
  });
  const currentSubscription = subscriptionDetails.filter((item) => item.code === userSubscription.membershipLevelCode)[0]

  const renderSubscriptionPlanList = () => {
    return subscriptionDetails.map((plan, index) => {
      return (
        <TabPanel value={value} index={index} key={index}>
          {plan.code === "PRV" && (
            <img src={SunGlasses} className={`${classes.planIcon} ${classes.quickFade}`} />
          )}
          {plan.code === "ELT" && (
            <img src={DiamondIcon} className={`${classes.planIcon} ${classes.quickFade}`} />
          )}
          <Typography color="textPrimary" className={classes.subscriptionDescription}>{plan.description}</Typography>
          <Box className={classes.cardPlanContainer}>
            {plan.membership.map((membership, subIndex) => {
              return (
                <CardPlan
                  membershipDetail={membership}
                  key={subIndex}
                  isSignedIn={false}
                  userSubscription={userSubscription}
                  handleSubscriptionClick={handleSubscriptionClick}
                />
              );
            })}
          </Box>
        </TabPanel>
      );
    });
  };

  return (
    <Box ref={ref} className={classes.container}>
      {
        isPaymentModalOpen && (
          <PaymentModal
            isPaymentModalOpen={isPaymentModalOpen}
            paymentModalDetails={paymentModalDetails}
            setPaymentModalDetails={setPaymentModalDetails}
            confirmUrl={confirmUrl}
            setConfirmUrl={setConfirmUrl}
            status={status}
            setStatus={setStatus}
            isFromRegistration={true}
            newUser={user}
          />
        )
      }
      {
        confirmUrl !== '' && (
          <ConfirmModal
            confirmUrl={confirmUrl}
            setConfirmUrl={setConfirmUrl}
            status={status}
            setStatus={setStatus}
          />
        )
      }
      <Box>
        <Typography variant="h5">Select Subscription Plan</Typography>
      </Box>
      
      <Typography color="textPrimary">Select a plan that's right for you</Typography>
      <Box>
      <AppBar position="static" className={classes.subPlanTabs}>
        <Tabs centered value={value} onChange={handleChange}>
            <Tab label="Regular" {...allyProps(0)} />
            <Tab label="Privacy" {...allyProps(1)} />
            <Tab label="Elite" {...allyProps(2)} />
        </Tabs>
      </AppBar>
      </Box>
      {
        renderSubscriptionPlanList()
      }
      { currentSubscription && 
        <Modal
          open={changeSubscriptionConfirm}
          onClose={() => { setChangeSubscriptionConfirm(false) }}
          className={classes.unsubscribeModal}
        >
          <Card className={classes.modalCard}>
            <Typography variant="h5" className={`text-bold text-center`}> Change My Subscription </Typography>
            <Typography variant="subtitle2" className={`text-center`} color="textPrimary">
            You have an active {currentSubscription.subscription} Subscription will end on {moment.unix(currentSubscription.subscriptionPlanEnd).format('MMMM D, YYYY')}. Clicking Yes will cancel your current plan. Are you sure you want to continue?
            </Typography>

            <Box className={classes.modalBtnContainer}>
              <Button
                type="submit"
                variant="outlined"
                color="primary"
                className={classes.submit}
                onClick={() => { setChangeSubscriptionConfirm(false) }}
              >
                No
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={() => { handleConfirm() }}
              >
                Yes
              </Button>
            </Box>
          </Card>
        </Modal>}
      <SnackBar
      severity={ALERT_SEVERITY_SUCCESS}
      message={TOAST_SUBSCRIPTION_PLAN_CHANGED}
      open={openToast}
      setOpen={setOpenToast}/>

      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={isModalOpen}
        onClose={toggleModal}
      >
        <div className={classes.modal}>
          <div className={classes.paper}>
            <img src={confetti} className={classes.image} alt="confetti"/>
            
            <Typography variant="h5" color="textPrimary" className={`text-bold text-center`}>
              Congratulations!
            </Typography>

            <Typography variant="subtitle2" className={`text-center`} color="textPrimary">
              You're on your way.
              Now, tell us where we're going 
              (who you're looking for)
            </Typography>

            <Typography variant="subtitle2" className={`text-center`} color="textPrimary">
              Let's setup your Ideal Search.
            </Typography>

            <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit} onClick={handleClick}>
              {
                `Continue`
              }
            </Button>
          </div>
        </div>
      </Modal>
    
    </Box>
  )
});

export default Subscription;