import action from "./action";
import { TUTORIAL_USER_PROFILE, TUTORIAL_SHORTLIST } from "../../utils/constants";

const initState = {
  tutorial: {
    [TUTORIAL_USER_PROFILE] : {
      imageGallery : {
        name     : 'imageGallery',
        title    : null,
        text     : 'Swipe left or right to see more images.',
        order    : 3,
        isViewed : false
      },
      profileOptions : {
        name     : 'profileOptions',
        title    : null,
        text     : 'Tap here to see profile options.',
        order    : 1,
        isViewed : false
      },
      receivedLike : {
        name     : 'receivedLike',
        title    : ' likes you.',
        text     : 'Tap here to see profile options and select "Show Your Affection" from the options to like her back.',
        order    : 2,
        isViewed : false
      },
      sendChatRequest: {
        name     : 'sendChatRequest',
        title    : null,
        text     : 'To start chatting this user, you need to send chat request first. Tap the chat icon to send chat request.',
        isViewed : false
      },
      receiveChatRequest: {
        name     : 'receiveChatRequest',
        title    : ' sent you a chat request.',
        text     : 'Tap the chat icon to accept or ignore the chat request.',
        isViewed : false
      },
      sendAudioCallRequest: {
        name     : 'sendAudioCallRequest',
        title    : null,
        text     : 'To start calling this user, you need to send call request first. Tap the call icon to send call request.',
        isViewed : false
      },
      receiveAudioCallRequest: {
        name     : 'receiveAudioCallRequest',
        title    : ' sent you a call request.',
        text     : 'Tap the call icon to accept or ignore the call request.',
        isViewed : false
      },
      sendVideoCallRequest: {
        name     : 'sendVideoCallRequest',
        title    : null,
        text     : 'To start a video call with this user, you need to send video call request first. Tap the video call icon to send video call request.',
        isViewed : false
      },
      receiveVideoCallRequest: {
        name     : 'receiveVideoCallRequest',
        title    : ' sent you a video call request.',
        text     : 'Tap the video call icon to accept or ignore the video call request.',
        isViewed : false
      }
    },
    [TUTORIAL_SHORTLIST] : {
      deleteShortlistProfile: {
        name     : 'deleteShortlistProfile',
        title    : null,
        text     : 'Long press to delete a shortlisted profile.',
        isViewed : false
      }
    }
  }
};

export default function userReducer(state = initState, act) {

  switch (act.type) {

    case action.USER_TUTORIAL_VIEW:
      console.log("redux/tutorial/reducer.js:USER_TUTORIAL_VIEW");

      let tutorialItem = state.tutorial[act.payload.tutorialType][act.payload.name];
      
      tutorialItem['isViewed'] = true;

      return {
        ...state,
        tutorial : {
          ...state.tutorial,
          [act.payload.tutorialType] : {
            ...state.tutorial[act.payload.tutorialType],
            [act.payload.name] : tutorialItem
          }
          
        }
      }
    default:
      return state
  }
}
