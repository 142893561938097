import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { Box, Card, CardMedia, Grid, IconButton, Typography, Tooltip } from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import profile from '../../assets/images/muser.png'

import moment from 'moment';

import useStyles from './style';
import Rating from '../rating'
import Heart from '../heart'
import connectionAction from '../../redux/connection/action';
import Icon              from '@mdi/react';
import { mdiSunglasses } from '@mdi/js';
import DiamondIcon       from '../../assets/images/diamond.png';
import {
  CALENDAR_RESPONSE_GOING,
  CALENDAR_RESPONSE_NOT_GOING,
  CALENDAR_RESPONSE_PENDING,
  CARD_CALENDAR_EVENTS,
  CARD_SHORTLIST,
  CARD_USER,
  CARD_VIEW,
  STATUS_ACTIVE,
  USER_SUBSCRIPTION_ELITE,
  USER_SUBSCRIPTION_PRIVACY
} from '../../utils/constants';

import { formatDateTime, getAddress, getEventLocation, getImageCache, getEventReminder, handleHttpError } from '../../utils/helper';
import { connectionRequest } from '../../service/requests';

const { setUserConnection } = connectionAction;

const UserCard = (props) => {
  const { eventDetails, handleEdit, handleEventResponse, type, userConnection, userId, visibilityStatus } = props;
  const { status } = eventDetails ? eventDetails.eventReceiver : CALENDAR_RESPONSE_PENDING;
  const classes = useStyles();
  const history = useHistory();

  const [profileImage, setProfileImage] = useState(profile);
  const [response, setResponse] = useState(status);

  const viewProfile = () => {
    setUserConnection(userConnection);
    history.push(`/profile/${userConnection.user.userName}?tab=profile`);
  };

  const handleSelectResponse = (event) => {
    setResponse(event.target.value);
    handleEventResponse(event.target.value, eventDetails);
  };

  if (type === CARD_USER) {
    const { isViewed, message, user, userField, userSubscription } = userConnection;

    getImageCache(user.mediaProfile).then((cachedImage) => {
      setProfileImage(cachedImage);
    });

    const arePhotosPrivate = (userSubscription.viewabilityStatus == USER_SUBSCRIPTION_PRIVACY || userSubscription.viewabilityStatus == USER_SUBSCRIPTION_ELITE) && message != STATUS_ACTIVE;

    return (
      <Grid item xs={6} sm={4} md={3} lg={2}>
        <Card className={`${classes.card}`} onClick={viewProfile} >
          <CardMedia
            className={`${classes.userImage} ${arePhotosPrivate && `image-blur`}`}
            image={profileImage}
          />
          <div className={classes.content} style={{paddingLeft: 15, paddingRight: 15}}>
            <Typography variant='subtitle1' color='textPrimary' noWrap={true} className={`${classes.boldText}f`}>
              {`${user.userName}`} 
              {( userConnection?.userSubscription?.viewabilityStatus == 'PRV' ) ? <Icon path={mdiSunglasses} size={1} color="black" style={{marginLeft : 2}} /> 
        	    :( userConnection?.userSubscription?.viewabilityStatus == 'ELT' ) ? <img src={DiamondIcon} className={`${classes.planIcon} ${classes.quickFade}`} /> :''}
            </Typography>
            <Typography variant='caption' color='textPrimary' display='block' noWrap={true}>
              {`${user.age}, ${userField.address.city}`}
            </Typography>
            <div className={classes.connectionContainer}>
              <Rating value={userConnection.rating} />
              <Heart value={userConnection.heart}/>
            </div>
          </div>
        </Card>
      </Grid>
    )
  } else if (type === CARD_SHORTLIST) {

    const { message, user, userField, userSubscription } = userConnection;

    getImageCache(user.mediaProfile).then((cachedImage) => {
      setProfileImage(cachedImage);
    });

    const arePhotosPrivate = (userSubscription.viewabilityStatus == USER_SUBSCRIPTION_PRIVACY || userSubscription.viewabilityStatus == USER_SUBSCRIPTION_ELITE) && message != STATUS_ACTIVE;

    return (
      <Grid item xs={8} sm={4} md={3} lg={2} >
        <Card className={classes.cardShortlist} onClick={viewProfile} >
          <CardMedia
            className={`${classes.shortistImage} ${arePhotosPrivate && `image-blur`}`}
            image={profileImage}
          />
          <div className={classes.overlay}></div>
          <div className={classes.contentShortlist}>
            <Typography variant='subtitle1' color='secondary' align='center' className={classes.boldText}>
              {`${user.userName}`}
              {( userConnection?.userSubscription?.viewabilityStatus == 'PRV' ) ? <Icon path={mdiSunglasses} size={1} color="black" style={{marginLeft : 2}} /> 
        	    :( userConnection?.userSubscription?.viewabilityStatus == 'ELT' ) ? <img src={DiamondIcon} className={`${classes.planIcon} ${classes.quickFade}`} /> :''}
            </Typography>
            <Typography variant='subtitle2' color='secondary' align='center'>
            {`${user.age}, ${userField.address.city}`}
            </Typography>       
          </div>
        </Card>
      </Grid>
    )
  } else if (type === CARD_VIEW) {

    const { auConnection, isSeen } = props;
    const { message, user, userField, userSubscription, profileView } = userConnection;
    const arePhotosPrivate = (userSubscription.viewabilityStatus == USER_SUBSCRIPTION_PRIVACY || userSubscription.viewabilityStatus == USER_SUBSCRIPTION_ELITE) && message != STATUS_ACTIVE;
    const date = (auConnection) ? formatDateTime(auConnection.whenChanged) : formatDateTime(userConnection.profileView.whenViewed) ;

    const markAsSeen = async () => {
      console.log('UserCard.markAsSeen()');

      // const { seenRecentViewsProfile } = this.props;

      viewProfile();
      // seenRecentViewsProfile();

      if (isSeen) return
      !profileView.isSeen && await connectionRequest.markAsSeen({
        user_id : userId,
        profile_view_id : profileView.profileViewId
      }).catch(error => {
        handleHttpError({
          error,
          request: 'connectionRequest.markAsSeen::src/components/usercard'
        })
      });    
    }

    getImageCache(user.mediaProfile).then((cachedImage) => {
      setProfileImage(cachedImage);
    });

    return (
      <Grid item xs={6} sm={4} md={3} lg={2}>
        <Card className={`${classes.card}`} onClick={markAsSeen}>  
          <div className={classes.content}>
          <CardMedia
            className={`${classes.userImage} ${arePhotosPrivate && `image-blur`}`}
            image={profileImage}
          />
          <div className={classes.userCardInfo}>
            <Typography variant='subtitle1' color='textPrimary' align='left' noWrap={true} className={`${classes.boldText} ${userConnection?.userSubscription?.viewabilityStatus == 'REG' && classes.regCard}`}>
              {`${user.userName}`}
              {( userConnection?.userSubscription?.viewabilityStatus == 'PRV' ) ? <Icon path={mdiSunglasses} size={0.9} color="black" style={{marginLeft : 2}} /> 
        	    :( userConnection?.userSubscription?.viewabilityStatus == 'ELT' ) ? <img src={DiamondIcon} className={`${classes.planIcon} ${classes.quickFade}`} /> :''}
              {`, ${user.age}`} 
            </Typography>
            <Typography variant='caption' display='block' color='textPrimary' noWrap={true} align='left'>
              {getAddress(userField.address)}
            </Typography>
            <Typography variant='caption' display='block' color='textPrimary' align='left'>
              {date}
            </Typography>
            </div>
          </div>
        </Card>
      </Grid>
    )
  } else if (type === CARD_CALENDAR_EVENTS) {

    const { mediaProfile, userName } = eventDetails.userConnection.user;
    const { eventPlace, street, city, state, zipCode, dateStart, meetupName } = eventDetails.meetup;
    const { reminder } = eventDetails.eventSender;

    let dateSection = [classes.eventStatus];
    const currentTime = Date.now() / 1000

    if(mediaProfile !== ''){
      getImageCache(mediaProfile).then((cachedImage) => setProfileImage(cachedImage));
    }

    let eventStatus = '';

    if (status === CALENDAR_RESPONSE_PENDING) {
      if (currentTime < dateStart){
        eventStatus = 'PENDING';
        dateSection.push(classes.pendingStatus);
      } else {
        eventStatus = 'EXPIRED';
        dateSection.push(classes.pendingStatus);
      }

    } else if (status === CALENDAR_RESPONSE_GOING) {
      eventStatus = 'ACCEPTED';
      dateSection.push(classes.acceptedStatus);
    } else if (status === CALENDAR_RESPONSE_NOT_GOING) {
      eventStatus = 'DECLINED';
      dateSection.push(classes.declinedStatus);
    }

    return (
      <Grid item className={classes.calendarEventGridItem} xs={12} sm={6} md={4} lg={4}>
        <Box component={Card} display='flex' flexDirection='row' borderRadius={20} className={eventStatus !== 'EXPIRED' ? classes.cardCalendarEvent : classes.expiredEvent}>
          <IconButton aria-label="edit" id='edit-button' className={classes.editButton} disabled={eventStatus === 'EXPIRED'} onClick={handleEdit}>
            <EditIcon color='primary' />
          </IconButton>
          <Box className={dateSection} width='15%'>
            <Grid container className={classes.dateSectionInfo} justify="space-around" alignItems="center">
              <Grid item xs={12}>
                <Typography variant='subtitle1' color='textPrimary' align='center' className={classes.date}>
                  {moment.unix(dateStart).format('dddd')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h4' color='textPrimary' align='center' className={classes.date}>
                  {moment.unix(dateStart).format('DD')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6' color='textPrimary' align='center' className={classes.date}>
                  {moment.unix(dateStart).format('MMMM')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='subtitle2' color='textPrimary' align='center' className={[classes.eventStatusContainer, classes.date]}>
                  {eventStatus}
                </Typography>
              </Grid>
            </Grid>
          </Box>
          <Box width='85%' height='auto' display='flex' flexDirection='column' justifyContent='space-around'>
            <Box 
                ml={2} 
                fontSize='subtitle1.fontSize' 
                height='33.33%' 
                textOverflow='ellipsis' 
                display='flex' 
                color='textPrimary'
                alignItems='center'
              >
                {userName.length >= 27 ? userName.substring(0,27) + "..." : userName}
                { visibilityStatus === 'PRV'  ? <Icon path={mdiSunglasses} size={1} color="black" style={{marginLeft : 2}} /> 
        	          : visibilityStatus === 'ELT' ? <img src={DiamondIcon} className={`${classes.planIcon} ${classes.quickFade}`} /> : ''}

              </Box>
          <Tooltip title = { eventStatus !== 'EXPIRED' ? meetupName : '' }>
              <Box 
                ml={2} 
                fontSize='subtitle1.fontSize' 
                height='33.33%' 
                textOverflow='ellipsis' 
                display='flex' 
                color='textPrimary'
                alignItems='center'
              >
                {meetupName.length >= 24 ? meetupName.substring(0,23) + "..." : meetupName}

              </Box>
            </Tooltip>
            
            <Tooltip title = { eventStatus !== 'EXPIRED' ? getEventLocation(eventPlace, street, city, state, zipCode) : '' } >
              <Box 
                ml={2}
                fontSize='subtitle1.fontSize' 
                height='33.33%' 
                textOverflow='ellipsis' 
                display='flex' 
                color='textPrimary'
                alignItems='center'
              >
                {`${moment(moment.unix(dateStart)).format('hh:mm A')} | ${getEventLocation(eventPlace, street, city, state, zipCode).length === 0 ? "No location" : getEventLocation(eventPlace, street, city, state, zipCode).substring(0,14) + '...'}`}
              </Box>
            </Tooltip>
            
            <Box 
              ml={2} 
              fontSize='subtitle1.fontSize' 
              height='33.33%' 
              textOverflow='ellipsis' 
              display='flex' 
              color='textPrimary'
              alignItems='center'
            >
              {getEventReminder(reminder)}
            </Box>
          </Box>
        </Box>
      </Grid>
    )
  }
}

/////////////////////////////
// mapStateToProps()
/////////////////////////////
const mapStateToProps = (state) => {
  // console.log('usercard/index.js:mapStateToProps(state)');
  // console.log(state);
  return {
    userId          : state.Session.user.user.userId
  };
}

export default connect(mapStateToProps, { setUserConnection } )(
  UserCard
);