import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../utils/colors';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({

  collapsibleContainer: {
    marginBottom : theme.spacing(2),
    marginTop : theme.spacing(2),
  },

  box: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  modifySubscriptionBox:{
    paddingBottom: 10,
  },

  link: {
    cursor: 'pointer'
  },

  changePassword: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: theme.spacing(2),
    paddingLeft: theme.spacing(4),

    '& > .MuiFormControl-root': {
      width: 295,
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
      '& > .MuiOutlinedInput-root': {
        borderRadius: 10
      }
    },

    '& > .MuiButtonBase-root': {
      width: 120,
      borderRadius: 40,
      alignSelf: 'flex-end',
      margin: theme.spacing(5, 1)
    },

    '& > .pincode-input-container > .pincode-input-text': {
      border: `1px solid ${colors.GRAY} !important`,
      borderRadius: 10,
      color: colors.PRIMARY_TEXT
    }
  },

  passwordStrength: {
    display: 'flex',
    flexDirection: 'column',
    '& > p, .MuiListItemText-root': {
      color: colors.TEXT_GRAY
    },
    '& > ul > li': {
      padding: 0
    },
    '& > .MuiButtonBase-root': {
      width: 120,
      borderRadius: 40,
      alignSelf: 'flex-end',
      margin: theme.spacing(5, 1)
    },
  },

  checkIconGreen: {
    color: colors.GREEN
  },

  content: {
    flex: 1,
    paddingTop: theme.spacing(5),
    padding: theme.spacing(4),
    '& > .MuiTypography-root:first-child': {
      color: colors.PRIMARY
    },
  },

  mobileNumber: {

    '& > .MuiBox-root > .MuiFormControl-root:last-of-type': {
      width : 175,
    },

    '& > .MuiFormHelperText-root': {
      color: 'red',
    },

    '& > .MuiBox-root > .MuiFormControl-root > .MuiOutlinedInput-root': {
      borderRadius: 10
    }
  },

  currentInfo: {
    paddingBottom: theme.spacing(2),
    color: colors.PRIMARY_TEXT,
  },

  subscriptionContainer: {
    '& h6': {
      color: colors.PRIMARY_TEXT,
      padding: 20
    },
    '& > .MuiBox-root': {
      paddingLeft: 25,
      paddingTop: 20,
      '& > .MuiBox-root': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        '& p': {
          color: colors.PRIMARY_TEXT
        }
      },
      '& > hr': {
        marginBottom: theme.spacing(4)
      },
    },
    '& a': {
      cursor: 'pointer'
    }
  },

  formControl: {
    height : 40,
    minWidth: 120,

    '& > .MuiInputBase-root': {
      height : 40,
    }
  },

  subscriptionPlanCard: {
    borderRadius: 10,
    position: 'absolute',
    left: '50%',
    top: '2%',
    outline: 'none',
    transform: 'translateX(-50%)',
    [theme.breakpoints.down("sm")]: {
      minWidth: 500
    },
    [theme.breakpoints.down("xs")]: {
      top: '5%',
      minWidth: 300
    },
  },

  unsubscribeModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  paymentScreenModal: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  paymentScreenCard:{
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(4),
    minHeight: '50vh',
    height: '620px',
    minWidth: '45vw',
    width: '500px'
  },
  modalClose:{
    //  float: 'right',
    display: 'block',
    marginRight: 0,
    marginLeft: 'auto',
     color: colors.GRAY,
     '&:hover': {
      color: colors.PRIMARY,
      backgroundColor: 'rgba(0, 0, 0, 0.1)'
    }
  },

  modalCard: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(4),
    '& > h5': {
      color: colors.PRIMARY
    },
    '& > h6': {
      width: '70%'
    },
    '& h5, h6': {
      margin: theme.spacing(2, 0)
    }
  },

  modalBtnContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    transform: 'translate(10px, 0)',
    '& button': {
      marginRight: 10,
      width: 200
    }
  },

  disabledLink: {
    cursor: 'not-allowed !important',
    color: colors.PRIMARY_TEXT
  },

  codeVerification: {
    '& > .MuiFormControl-root': {
      width: 295,
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
      '& > .MuiOutlinedInput-root': {
        borderRadius: 10
      }
    },

    '& > p': {
      margin: 5,
      maxWidth: 300,
      '& > a': {
        cursor: 'pointer'
      }
    },

    '& > .pincode-input-container > .pincode-input-text': {
      border: `1px solid ${colors.GRAY} !important`,
      borderRadius: 10,
      color: colors.PRIMARY,
      fontSize: 20,
      fontWeight: 'bold'
    },

    '& > .buttonGroup': {
      display: 'flex',
      justifyContent: 'flex-end',
      padding: 20,
      '& > .MuiButtonBase-root': {
        width: 120,
        borderRadius: 40,
        alignSelf: 'flex-end',
        margin: theme.spacing(5, 1)
      },
    }
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    margin: theme.spacing(2, 0, 2, 0),

    '& .MuiFormControl-root': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },

    '& .MuiTypography-root': {
      marginBottom: theme.spacing(0.5),
      marginTop: theme.spacing(0.5),
    },

    '& > .pincode-input-container': {
      margin: theme.spacing(6, 0, 2, 0),
      '& > .pincode-input-text': {
        border: `1px solid ${colors.GRAY} !important`,
        borderRadius: 10,
        color: colors.PRIMARY,
        fontSize: 20,
        fontWeight: 'bold'
      }
    },
  },

  contentItem: {
    cursor: "pointer",
  },

  itemList: {
    "&:hover": {
      background: "none",
    },
  },

  forgotpassword: {
    marginLeft: 230,
  },

  linkForgetPassword: {
    marginLeft: 0,
    textDecoration: 'underline',

    '&:hover': {
      cursor: 'pointer'
    }
  },

  // Modal
  paper: {
    left: 'calc(50% - 200px)',
    top: '50%',
    transform: 'translateY(-50%)',
    position: 'absolute',
    width: 400,
    backgroundColor: colors.BACKGROUND_COLOR,
    boxShadow: theme.shadows[5],
  },

  boxForgetPassword: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(6),
  },

  gradientLogo: {
    height: 60,
    marginBottom: theme.spacing(2)
  },

  formForgetPassword: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',

    '& .MuiFormControl-root': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },

    '& .MuiTypography-root': {
      marginBottom: theme.spacing(0.5),
      marginTop: theme.spacing(0.5),
    },
  },

  textField: {
    '& .MuiFormGroup-root .MuiFormControlLabel-root .MuiTypography-root': {
      color: colors.TEXT_GRAY
    },
    '& > .MuiFormHelperText-root': {
      color: 'red'
    }
  },  


}));

export default useStyles;