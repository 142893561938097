import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../utils/colors';

const useStyles = makeStyles(theme => ({

  // Verify Account
  gridItem: {
    width: '100%',
  },

  verifyAccount: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-around',

    '& > .MuiBox-root:last-of-type': {
      display: 'flex',
      justifyContent: 'space-around',
      width: '100%'
    }
  },

  content: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    flexDirection: 'column',

    '& .MuiTypography-root': {
      marginBottom: theme.spacing(2),
    },

    '& .MuiLink-root': {
      '&:hover': {
        cursor: 'pointer',
      }
    },

    '& > .pincode-input-container': {
      margin: theme.spacing(6, 0, 2, 0),
      '& > .pincode-input-text': {
        border: `1px solid ${colors.GRAY} !important`,
        borderRadius: 10,
        color: colors.PRIMARY,
        fontSize: 20,
        fontWeight: 'bold'
      }
    },
  },

  textField: {
    marginBottom: theme.spacing(4),
    width: 270,
    '& .MuiFormGroup-root .MuiFormControlLabel-root .MuiTypography-root': {
      color: colors.TEXT_GRAY
    },
    '& > .MuiFormHelperText-root': {
      color: 'red'
    }
  },

}));

export default useStyles;