import React from 'react';
import { CardActionArea, Card, CardContent, CircularProgress, Link, SvgIcon, ButtonBase } from '@material-ui/core';
import { VisibilityOutlined } from '@material-ui/icons';

import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

import useStyles from './style';

import { ReactComponent as HeartCircleOutline } from '../../assets/images/heart-circle-outline.svg';
import { STATUS_VIEWED_ME, STATUS_CALENDAR_INVITES, STATUS_HEARTED_ME, STATUS_PROFILE_COMPLETION, STATUS_PROFILE_IDEAL } from '../../utils/constants'

const DashboardStatusCard = (props) => {
	const classes = useStyles();
	const { title, count, description, type, isLoading, gotoPage } = props;

	const isProgress = (type === STATUS_PROFILE_COMPLETION || type === STATUS_PROFILE_IDEAL) ? true : false;
	const onClickProps = gotoPage ? {
		onClick : gotoPage
	} : {};

	const statusCardType = (type) => {
		let icon = null;
		const progressProps = {
			size: 100,
			thickness: 5,
			variant: (isLoading && !count) ? 'indeterminate' : 'static',
			value: count
		}

		switch (type) {
			case STATUS_VIEWED_ME:
				icon = <VisibilityOutlined className={classes.viewedMeIcon} fontSize='inherit' />;
				break;
			case STATUS_CALENDAR_INVITES:
				icon = <CalendarTodayIcon className={classes.calendarInvitesIcon} fontSize='inherit' />;
				break;
			case STATUS_HEARTED_ME:
				icon = <SvgIcon className={classes.heartedMeIcon} fontSize='inherit'><HeartCircleOutline /></SvgIcon>
				break;
			default:
				icon = circularProgressWithOffsetFill(progressProps);
				break;
		}
		return icon;
	}

	const circularProgressWithOffsetFill = (progressProps) => {
		
		const offsetConfig = {
			size: 100,
			thickness: 5,
			variant: 'static',
			value: 100
		};

		return (
			<>
				<CircularProgress {...offsetConfig} className={classes.progressOffset} />
				<CircularProgress
					{...progressProps}
					className={
						type === STATUS_PROFILE_COMPLETION
						? classes.completeProfileProgress
						: classes.completeProfileIdeal
					}
				/>
			</>
		)
	}

	const preloader = () => {
		return <div className='ctrPreloader'><CircularProgress /></div>
	}

	return (
		<Card {...onClickProps} className={`${classes.card} ${gotoPage && classes.clickableCard}`}>
			<CardContent className={classes.cardContent}>
				{
					gotoPage
					? <Link
							{...onClickProps}
							component='button'
							variant='body2'
						>
							{title}
						</Link>
					: <p>{title}</p>
				}
				
				<div className={classes.iconWrapper}>
					{statusCardType(type)}
					{
						(isLoading && !count)
							? !isProgress ? preloader() : null
							: <span>{`${count || 0}${isProgress ? '%' : ''}`}</span>
					}
				</div>
				<small className={classes.textFit}>{description || ''}</small>

			</CardContent>
		</Card>
	)
}

export default DashboardStatusCard;