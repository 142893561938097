import React from 'react';
import { Button, Tab, Tabs } from '@material-ui/core';
import { connect } from 'react-redux';

import AddCircleIcon from '@material-ui/icons/AddCircle';

import useStyles from './style';
import calendarAction from '../../../redux/calendar/action';

const { setActiveTabIndex } = calendarAction;

const a11yProps = (index) => {
  return {
    id: `calendar-tab-${index}`,
    'aria-controls': `calendar-tab-${index}`,
  };
}

const CalendarTab = (props) => {
  const { activeTabIndex, setActiveTabIndex } = props;
  const classes = useStyles();

  
  const handleTabChange = (event, value) => {
    console.log('handleTabChange()');
    console.log('CalendarTab props: ', props);

    setActiveTabIndex(value);
  };

    return (
      <>
        <Tabs
          value={activeTabIndex}
          onChange={handleTabChange}
          indicatorColor='secondary'
          textColor='secondary'
          variant='scrollable'
          scrollButtons='on'
          aria-label='calendar tabs'
          className={classes.container}
        >
            <Tab label='Invitation Tab' {...a11yProps(0)} className={classes.tab} />
            <Tab label='Calendar Tab' {...a11yProps(1)} className={classes.tab} />
        </Tabs>
        <Button color='primary' variant='contained' startIcon={<AddCircleIcon />}>Add Event</Button>
      </>
    );
};

/////////////////////////////
// mapStateToProps()
/////////////////////////////
const mapStateToProps = (state) => {
  console.log('components/calendar/tabs/index.js:mapStateToProps(state)');
  console.log(state);
  return {
    authenticated  : state.Session.authenticated,
    activeTabIndex : state.Calendar.activeTabIndex
  };
}

export default connect(mapStateToProps, { setActiveTabIndex })(
  CalendarTab
);
