import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({

  calendar: {
		flex : 1, 
		height : 700
	},

	backdrop: {
    backgroundColor : 'rgba(0,0,0,0)'
  },

  cardContent: {
    maxHeight : 250, 
    overflow : 'scroll',
    padding : 0
  },

  createEventContainer: {
    alignItems: 'flex-end',
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(2, 0, 2, 0),
  },

  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius : 10,
    boxShadow: theme.shadows[5],
    outline : 0,
    padding: theme.spacing(1, 1, 1),
    position: 'absolute',
  },

  popupPaper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius : 10,
    boxShadow: theme.shadows[5],
    maxWidth : 500,
    outline : 0
  },

  modalPaperContents: {
    padding: theme.spacing(1, 3, 2),
  },

  eventInfoRow: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    padding: 2
  },

  eventDetails: {
    marginLeft : 20
  },

  eventDetailsInput: {
    backgroundColor : 'rgba(0,0,0,0)',
    paddingTop : 4
  },

  closeButtonContainer: {
    textAlign : 'right'
  },

  popupEventName: {
    fontWeight : 'bold',
    padding : '20px 0px 0px 15px',
    overflowWrap : 'anywhere'
  }
}));

export default useStyles;