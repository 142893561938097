const action = {

  SIGN_OUT  : "SIGN_OUT",
  CLEAR_USER_DATA:  "CLEAR_USER_DATA",

  signOutUser: () => ({
    type : action.SIGN_OUT
  }),
  
  clearUserData: payload => ({
    type : action.CLEAR_USER_DATA,
    payload
  }),

};

export default action;