import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../../utils/colors';

const useStyles = makeStyles(theme => ({

  buttonSave: {
    width: 120,
    borderRadius: 40,
    alignSelf: 'flex-end',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },


  // ABOUT US
  noteField: {
    width: '100%',
    marginBottom: theme.spacing(2),
    '& > .MuiOutlinedInput-root': {
      borderRadius: 10
    }
  },


}));

export default useStyles;
