import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Avatar, Card, CardMedia, Container, Grid, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import useStyles from './style';
import UserCard from '../../components/usercard';
import Preloader from '../../components/preloader';
import Title from '../../components/title';
import {
  CARD_SHORTLIST,
  PRELOADER_CARD_SHORTLIST,
} from '../../utils/constants';

import heart from '../../assets/images/heart-sharp.png';
import heartplus from '../../assets/images/heartplus.png';

import connectionAction from '../../redux/connection/action';

const { getShortlist }  = connectionAction;

const DefaultCard = (props) => {
  const { key } = props;
  const classes = useStyles();
  const history = useHistory();

  const goToSearch = () => {
    history.push('/search');
  }

  return (
    <Grid item xs={8} sm={4} md={3} lg={2} >
      <Card className={classes.preloaderCard} key={key} onClick={goToSearch}>
        <CardMedia
          className={classes.image}
          image={heartplus}
        />
        <div className={classes.overlay}></div>
        <div className={classes.preloaderContent}  onClick={goToSearch}>
          <Typography variant='subtitle1' color='primary' align='center' className={classes.username}>
            Add New
          </Typography>
        </div>
      </Card>
    </Grid>
  );
}

const ShortistPreloader = () => {
  const classes = useStyles();

  return (
    Array.from({length: 3}, () => 1).map((index, item) => {
      return (
        <Grid 
          container 
          justify='center'
          alignItems='center'
          spacing={2} 
          className={classes.shortlistGrid}
        >
          {
            Array.from({length: 3}, () => 1).map((value, index) => {
              return (
                <Preloader type={PRELOADER_CARD_SHORTLIST} key={index}/>
              )
            })
          }
        </Grid>
      )
    })
  )
}

const Shortlists = (props) => {
  const { shortlist } = props;
  const classes = useStyles();

  let shortlistItems = [];
  let shortlistRow = [];
  let start = 0;
  let limit = 3;

  for (let y=0; y<3; y++) {
    if (shortlist.length > 0) {
      for (let x=start; x<limit; x++) {

        if (shortlist[x]) {
          shortlistItems.push(<UserCard type={CARD_SHORTLIST} userConnection={shortlist[x]} key={x} />);
        } else {
          shortlistItems.push(<DefaultCard key={x} />); 
        } 
      }
    } else {
      for (let x=start; x<limit; x++) {
        shortlistItems.push(<DefaultCard key={x} />);
      }
    }
    
    shortlistRow.push(
      <Grid 
        container 
        justify='center'
        alignItems='center'
        spacing={2} 
        className={classes.shortlistGrid}
      >
        {shortlistItems}
      </Grid>
    )
    start = limit;
    limit = limit + 3;
    shortlistItems = [];
  }

  return shortlistRow;
}

const Shortlist = (props) => {
  const { isShortlistLoading, shortlist, getShortlist } = props;
  const classes = useStyles();

  useEffect(() => {
    getShortlist();

  }, []);

  return (
    <Container className={classes.root}>
      <Grid 
        container 
        justify='center' 
        alignItems='center'
        spacing={2} 
      >
      {
        (isShortlistLoading && shortlist.length === 0) ?
          <ShortistPreloader/>
        : 
          <Shortlists shortlist={shortlist}/>
      }
      </Grid>
    </Container>
  );
}

/////////////////////////////
// mapStateToProps()
/////////////////////////////
const mapStateToProps = (state) => {
  console.log('shortlist/index.js:mapStateToProps(state)');
  console.log(state);
  return {
    User                : state.Session.user,
    shortlist           : state.Connection.shortlist,
    isShortlistLoading  : state.Connection.isShortlistLoading
  };
}

export default connect(mapStateToProps, { getShortlist })(Shortlist);