import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../utils/colors';

const useStyles = makeStyles(theme => ({
  search: {
    alignItems: 'center',
    borderRadius: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(2),

    '&:hover': {
      backgroundColor: colors.HOVER_DEFAULT,
      cursor: 'pointer'
    },

    '& > .MuiBox-root': {
      alignItems: 'center', 
      display: 'flex',
    },

    '& > .MuiBox-root:first-of-type': {
      flex: 0.8,
      overflow: 'hidden',
    }
  },

  searchName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },

  addSearch: {
    background: `linear-gradient(${colors.GRADIENT_YELLOW}, ${colors.GRADIENT_ORANGE})`,
    justifyContent: 'center'
  },

  disableAddSearch: {
    backgroundColor: colors.GRAY,
    background: 'none',
    '&:hover': {
      backgroundColor: colors.GRAY,
      cursor: 'not-allowed'
    },
  },

  disableButton: {
    '&:hover': {
      cursor: 'not-allowed'
    },
  },

  actionButtonEmpty: {
    height: 24,
    minHeight: 24,
    width: 24,
    minWidth: 24,
    margin: theme.spacing(0, 0, 0, 1),
  },

  actionButton: {
    backgroundColor: 'transparent',
    padding: 0,
    margin: theme.spacing(0, 0, 0, 1),
    zIndex: 2,

    '&:hover': {
      backgroundColor: colors.HOVER_DEFAULT
    },
  }
}));

export default useStyles;