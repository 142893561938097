import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../utils/colors';

export const useStyles = makeStyles(theme => ({

  box: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddintTop: theme.spacing(2),
    paddintBottom: theme.spacing(2),
    gap: '20px',
    // border: '1px solid blue'
  },

  textField: {
    width: 270,
    '& .MuiFormGroup-root .MuiFormControlLabel-root .MuiTypography-root': {
      color: colors.TEXT_GRAY
    },
    '& > .MuiFormHelperText-root': {
      color: 'red'
    }
  },

  validationMessage: {
    color: 'red',
    fontSize: '12px',
    alignSelf: 'flex-start',
    marginTop: '-14px',
    marginBottom: '-5px',
    marginLeft: '14px',
  },

  selectBox: {
    width: '101%',
    maxWidth: '270px',
  },


}));
