const action = {

  NOTIFICATION_GET                       : "NOTIFICATION_GET",
  NOTIFICATION_GET_SUCCESS               : "NOTIFICATION_GET_SUCCESS",
  NOTIFICATION_GET_ERROR                 : "NOTIFICATION_GET_ERROR",

  NOTIFICATION_GET_CALL_LOG              : "NOTIFICATION_GET_CALL_LOG",
  NOTIFICATION_GET_CALL_LOG_SUCCESS      : "NOTIFICATION_GET_CALL_LOG_SUCCESS",
  NOTIFICATION_GET_CALL_LOG_ERROR        : "NOTIFICATION_GET_CALL_LOG_ERROR",
  NOTIFICATION_SET_CALL_LOG_STATUS        : "NOTIFICATION_SET_CALL_LOG_STATUS",
  NOTIFICATION_RESET            : "NOTIFICATION_RESET",

  NOTIFICATION_SAVE                      : "NOTIFICATION_SAVE",

  NOTIFICATION_SEEN                      : "NOTIFICATION_SEEN",
  NOTIFICATION_SEEN_SUCCESS              : "NOTIFICATION_SEEN_SUCCESS",

  NOTIFICATION_SEARCH_UPDATE             : "NOTIFICATION_SEARCH_UPDATE",
  NOTIFICATION_SEARCH_SEEN               : "NOTIFICATION_SEARCH_SEEN",
  NOTIFICATION_SEARCH_SEEN_SUCCESS       : "NOTIFICATION_SEARCH_SEEN_SUCCESS",

  NOTIFICATION_RECENT_VIEWS_UPDATE       : "NOTIFICATION_RECENT_VIEWS_UPDATE",
  NOTIFICATION_RECENT_VIEWS_SEEN         : "NOTIFICATION_RECENT_VIEWS_SEEN",
  NOTIFICATION_RECENT_VIEWS_SEEN_SUCCESS : "NOTIFICATION_RECENT_VIEWS_SEEN_SUCCESS",

  NOTIFICATION_CALENDAR_UPDATE           : "NOTIFICATION_CALENDAR_UPDATE",
  NOTIFICATION_CALENDAR_SEEN             : "NOTIFICATION_CALENDAR_SEEN",
  NOTIFICATION_CALENDAR_SEEN_SUCCESS     : "NOTIFICATION_CALENDAR_SEEN_SUCCESS",

  NOTIFICATION_CHAT_UPDATE               : "NOTIFICATION_CHAT_UPDATE",
  NOTIFICATION_CHAT_SEEN                 : "NOTIFICATION_CHAT_SEEN",
  NOTIFICATION_CHAT_SEEN_SUCCESS         : "NOTIFICATION_CHAT_SEEN_SUCCESS",

  NOTIFICATION_MARK_ALL_READ             : "NOTIFICATION_MARK_ALL_READ",
  NOTIFICATION_MARK_ALL_SUCCESS          : "NOTIFICATION_MARK_ALL_SUCCESS",

  getNotifications: page => ({
    type    : action.NOTIFICATION_GET,
    payload : page
  }),

  saveNotification: notification => ({
    type    : action.NOTIFICATION_SAVE,
    payload : notification
  }),


  seenNotification: notifId => ({
    type    : action.NOTIFICATION_SEEN,
    payload : notifId
  }),

  seenSearchResultProfile: () => ({
    type    : action.NOTIFICATION_SEARCH_SEEN
  }),

  seenRecentViewsProfile: () => ({
    type    : action.NOTIFICATION_RECENT_VIEWS_SEEN
  }),

  seenCalendarEvent: calendar => ({
    type    : action.NOTIFICATION_CALENDAR_SEEN,
    payload : calendar
  }),

  updateChatCount: unseenChatCount => ({
    type    : action.NOTIFICATION_CHAT_UPDATE,
    payload : unseenChatCount
  }),

  seenChat: chat => ({
    type    : action.NOTIFICATION_CHAT_SEEN,
    payload : chat
  }),

  markAllRead: user => ({
    type    : action.NOTIFICATION_MARK_ALL_READ,
    payload : user
  }),

  getNotificationCallLogs: payload => ({
    type    : action.NOTIFICATION_GET_CALL_LOG,
    payload : payload
  }),

  resetNotification: () => ({
    type    : action.NOTIFICATION_RESET,
  })

};

export default action;
