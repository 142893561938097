import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../utils/colors';

const useStyles = makeStyles(theme => ({

  privacyPolicy: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    padding: theme.spacing(6),
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    '& .MuiTypography-root': {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },

    '& > .MuiBox-root': {
      marginBottom: theme.spacing(2),
    }
  },

  image: {
    marginBottom: theme.spacing(2),
    height: 64,
    width: 64,
  },

  checkBox: {
    alignSelf : 'center',
  },



}));

export default useStyles;