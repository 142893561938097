import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { parseString } from 'xml2js';
import countryByAbbreviation from 'country-json/src/country-by-abbreviation.json';
import { Box, Typography, TextField, ButtonBase } from '@material-ui/core';
import GENDER_FEMALE from '../../../assets/images/gender_female.png';
import GENDER_FEMALE_SELECTED from '../../../assets/images/gender_female_selected.png';
import GENDER_MALE from '../../../assets/images/gender_male.png';
import GENDER_MALE_SELECTED from '../../../assets/images/gender_male_selected.png';
import {
  UNITED_STATES,
  TOAST_AXIOS_REQUEST_ERROR,
  UPD_MODE_USER_LOOKING_FOR_GENDER,
  USER_GENDER_MALE,
  USER_GENDER_FEMALE,
  HTTP_STATUS
} from '../../../utils/constants'

import useStyles from './style';
import { userRequest, thirdPartyRequest } from '../../../service/requests';
import { handleHttpError } from '../../../utils/helper';

const Gender = forwardRef((props, ref) => {
  const classes = useStyles();

  const { callback, next, updateUserField, user } = props;

  const [isSaving, setIsSaving] = useState(false);
  const [state, setState] = useState({
    gender           : '',
    isGenderError    : false,
    genderErrorDesc  : ''
  });

  const { 
    gender, isGenderError, genderErrorDesc
  } = state;

  useEffect(() => {
    callback(isSaving);
  }, [isSaving])


  const selectGender = (gender) => {
    setState({
      ...state,
      gender
    });
  }

  const handleNext = () => {
    
    if (gender === '' ) {

      setState({
        ...state,
        isGenderError    : gender === '' ? true : false,
        genderErrorDesc  : gender === '' ? 'Required' : ''
      })

      return
    }

    setIsSaving(prevIsSaving => !prevIsSaving);
    userRequest.updateUser({
      user_id             : user.userId,
      upd_mode            : UPD_MODE_USER_LOOKING_FOR_GENDER,
      who_updated         : user.userId,
      looking_for_gender  : gender
    }).then(response => {
      console.log('response : ', response);

      if (response?.data?.status?.code != HTTP_STATUS._200) {
        let { status } = response?.data;
        throw new Error(`${status?.code} ${status?.description}`);
      }
      const userField = response?.data?.userDetail?.userField;
      updateUserField(userField);
      setIsSaving(prevIsSaving => !prevIsSaving);
      next();
    }).catch(error => {
      setIsSaving(prevIsSaving => !prevIsSaving);
      handleHttpError({
        error,
        request: 'userRequest.UpdateUser::src/views/registration/gender'
      })
    });
  }  

  useImperativeHandle(ref, () => ({
    triggerNext() {
      handleNext()
    }
  }));

  return (
    <Box ref={ref} className={classes.box}>

      <Typography variant="h5" color="primary" className={`text-bold text-center mb-20`}>
        About You
      </Typography>

      <Typography variant="h6" className={`text-bold text-center`} color="textPrimary">
        Looking For?
      </Typography>

      <Box className={classes.genderContainer}>
        <Box className={classes.gender}>
          {
            gender === USER_GENDER_MALE ? 
              <img src={GENDER_MALE_SELECTED} className={classes.selectedImage} alt="logo"/>
            :
            <ButtonBase
              onClick={() => selectGender(USER_GENDER_MALE)}
            >
              <img src={GENDER_MALE} className={classes.image} alt="logo"/>
            </ButtonBase>
          }
          <Typography variant="subtitle" color={gender === USER_GENDER_MALE ? 'primary' : 'textPrimary'} className={`text-bold`}>Man</Typography>
        </Box>
        <div>
          <div className={classes.vertical}></div>
        </div>
        <Box className={classes.gender}>
          {
            gender === USER_GENDER_FEMALE ?
              <img src={GENDER_FEMALE_SELECTED} className={classes.selectedImage} alt="logo"/> 
            :
              <ButtonBase
                onClick={() => selectGender(USER_GENDER_FEMALE)}
              >
                <img src={GENDER_FEMALE} className={classes.image} alt="logo"/>
              </ButtonBase>
          }
          <Typography variant="subtitle" color={gender === USER_GENDER_FEMALE ? 'primary' : 'textPrimary'} className={`text-bold`}>Woman</Typography>
        </Box>
      </Box>
      <Typography variant="caption" color="error">{genderErrorDesc}</Typography>

    </Box>
  )
});

export default Gender;