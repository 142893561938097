import { makeStyles } from '@material-ui/core/styles';
import colors from '../../utils/colors';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: 'rgba(250, 127, 61, 0.6)',
  },

  sos: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: 100,
    margin: theme.spacing(5),

    [theme.breakpoints.down('sm')]: {
      height: 40,
    },    
  },

  signInButton: {
    position: 'absolute',
    top: 0,
    right: 0,
    margin: theme.spacing(5),
  },

  image: {
    minHeight: '100%',
    minWidth: 1024,

    height: 'auto',
    width: '100%',
    position: 'fixed',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },

  text: {
    color: colors.WHITE,
    fontWeight: 'bold',
    textAlign: 'center',
    marginBottom: theme.spacing(2),

    '& > img':{
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1),
    },
  },

  buttonContainer: {
    textAlign: 'center',
  },

  logo: {
    cursor: 'pointer',
    height: 52,
  },

  overlay: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    backgroundImage: `linear-gradient(180deg, ${colors.GRADIENT_YELLOW} 30%, ${colors.GRADIENT_ORANGE} 90%)`,
    opacity: 0.5,
    zIndex: 2,
  },

  welcome: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    position: 'fixed',
    justifyContent: 'center',
    // top: '30%',
    zIndex: 3,
  },

  button: {
    margin: theme.spacing(3),
    textAlign: 'center',
    backgroundColor: `linear-gradient(180deg, ${colors.GRADIENT_YELLOW} 30%, ${colors.GRADIENT_ORANGE} 90%)`,
  },

  appBar: {
    width: '100%',
    position: 'fixed',
    top: 'auto',
    bottom: 10,
    
    '& > .MuiToolbar-root': {
      justifyContent: 'center'
    }
  },

  link: {
    marginLeft: 0,
    textDecoration: 'underline',

    '&:hover': {
      cursor: 'pointer'
    }
  },

  // Modal
  paper: {
    left: 'calc(50% - 200px)',
    top: '50%',
    transform: 'translateY(-50%)',
    position: 'absolute',
    width: 400,
    backgroundColor: colors.BACKGROUND_COLOR,
    boxShadow: theme.shadows[5],
  },

  box: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(6),
  },

  gradientLogo: {
    height: 60,
    marginBottom: theme.spacing(2)
  },

  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '100%',

    '& .MuiFormControl-root': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },

    '& .MuiTypography-root': {
      marginBottom: theme.spacing(0.5),
      marginTop: theme.spacing(0.5),
    },
  },

  textField: {
    '& .MuiFormGroup-root .MuiFormControlLabel-root .MuiTypography-root': {
      color: colors.TEXT_GRAY
    },
    '& > .MuiFormHelperText-root': {
      color: 'red'
    }
  },  

  submit: {
    margin: theme.spacing(3, 0, 2),
  },

  description: {
    textAlign: 'center',
    margin: '0 !important',
  },
  forgotpassword: {
    marginLeft: 200,
  },

  footer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },

  stores: {
    paddingTop: theme.spacing(2),
    flexDirection: 'row',
    justifyContent: 'space-between'
  },

  store: {
    height: 40,
  },

}));

export default useStyles;