import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Divider, List, ListItem, ListItemIcon, ListItemText, Typography } from '@material-ui/core';

import AccessibilityIcon from '@material-ui/icons/Accessibility';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import CakeIcon from '@material-ui/icons/Cake';
import ChildCareIcon from '@material-ui/icons/ChildCare';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import FaceIcon from '@material-ui/icons/Face';
import FireplaceIcon from '@material-ui/icons/Fireplace';
import FlagIcon from '@material-ui/icons/Flag';
import HeightIcon from '@material-ui/icons/Height';
import LanguageIcon from '@material-ui/icons/Language';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import LocalBarIcon from '@material-ui/icons/LocalBar';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PersonIcon from '@material-ui/icons/Person';
import PetsIcon from '@material-ui/icons/Pets';
import PoolIcon from '@material-ui/icons/Pool';
import PublicIcon from '@material-ui/icons/Public';
import SchoolIcon from '@material-ui/icons/School';
import SearchIcon from '@material-ui/icons/Search';
import SmokingRoomsIcon from '@material-ui/icons/SmokingRooms';
import VisibilityIcon from '@material-ui/icons/Visibility';
import WcIcon from '@material-ui/icons/Wc';
import WorkIcon from '@material-ui/icons/Work';
import Search from '@material-ui/icons/Search';
import { PersonSearch, FaceRetouchingNatural, EscalatorWarning, FitnessCenter, People, School, AttachMoney, FmdGood, Public, Female, Male, Person } from '@material-ui/icons';
import { mdiChurch } from '@mdi/js';

import useStyles from './style';

import { 
  LOOK_UP_ALCOHOL,
  LOOK_UP_BODY_TYPE,
  LOOK_UP_EDUC_LEVEL,
  LOOK_UP_ETHNICITY,
  LOOK_UP_EXER_REGIMEN, 
  LOOK_UP_EYE_COLOR,
  LOOK_UP_GENDER,
  LOOK_UP_HAIR_COLOR,
  LOOK_UP_HEIGHT,
  LOOK_UP_HOBBY, 
  LOOK_UP_INCOME_LEVEL,
  LOOK_UP_KID_SITUATION,
  LOOK_UP_LANGUAGE,
  LOOK_UP_PET_POLICY,
  LOOK_UP_POS_POLITICS,
  LOOK_UP_PREF_SMOKING,
  LOOK_UP_PROFESSION,
  LOOK_UP_RELATIONSHIP_STATUS,
  LOOK_UP_RELATIONSHIP_TYPE,
  LOOK_UP_RELIG_BELIEF,
  USER_FIELD_NOT_STATED,
} from '../../../utils/constants';

import ringIcon                 from '../../../assets/images/ring.svg';
import { getFormattedDateTime } from '../../../utils/helper';

const UserProfileItem = (props) => {

  const { icon, key, label, value, lookup, lookupField, lookupProperty } = props;
  const [profileInfo, setProfileInfo] = useState(value);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (lookupField !== undefined && lookupProperty !== undefined) {
      if (lookupProperty.length > 0) {
        if (value === null || value === undefined || value.length === 0) {
          setProfileInfo(USER_FIELD_NOT_STATED);
        } else {
          const updatedValue = getLookupDescription(value, lookupField);
          if (updatedValue){
            setProfileInfo(updatedValue.description);
          }
        }
        setIsLoading(false);
      }
    } else {
      if (lookupField === LOOK_UP_LANGUAGE || lookupField === LOOK_UP_HOBBY) {
        if (value.length === 0) {
          setProfileInfo(USER_FIELD_NOT_STATED);
        } else {
          setProfileInfo(value.join(', '));
        }
      }
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (lookupProperty !== undefined && lookupProperty.length > 0) {
      if (value === null || value === undefined || value.length === 0) {
        setProfileInfo(USER_FIELD_NOT_STATED);
      } else {
        const updatedValue = getLookupDescription(value, lookupField, lookupProperty);
        if (updatedValue){
          setProfileInfo(updatedValue.description);
        }
      }
      setIsLoading(false);
    } else {
      if (lookupField === LOOK_UP_LANGUAGE || lookupField === LOOK_UP_HOBBY) {
        if (value.length === 0) {
          setProfileInfo(USER_FIELD_NOT_STATED);
        } else {
          setProfileInfo(value.join(', '));
        }
      }
      setIsLoading(false);
    }
  }, [lookup[lookupProperty]])
  
  const getLookupDescription = (lookupCode, lookupType, lookupProperty) => {
    console.log('getLookupDescription');
    console.log(lookupCode);
    console.log(lookupType);

    const { getLookUpFields } = props;

    if (lookupProperty !== undefined && lookupType !== undefined) {
      const lookupValues = lookup[lookupProperty];

      if (lookupValues.length > 0) {
        const lookupItem = lookupValues.find(item => item.code == lookupCode);
  
        return lookupItem;
      } else {
        getLookUpFields(lookupType);
      }
    }
  }

  return (
    <React.Fragment key={key}>
      <ListItem style={{ marginBottom: -20}}>
        <ListItemIcon>
          {icon}
        </ListItemIcon>
        <ListItemText
          primary={label}
          primaryTypographyProps={{
            color: 'primary',
            style: {
              fontWeight : 'bold'
            }
          }}
          secondary={
            isLoading
            ? <CircularProgress color='textPrimary' size={24} />
            : <Typography variant='subtitle1' color='textPrimary'>
                {profileInfo}
              </Typography>
          }
        />
      </ListItem>
     </React.Fragment>
  )
}

const Profile = (props) => {
  console.log('Profile()');

  const classes = useStyles();
  const { user, userField } = props;

  // about me section
  const aboutMe = [
    
    {
      value     : userField.about ? userField.about : null,
      icon      : <LibraryBooksIcon color='primary' />,
      isLoading : false
    },
  ]

  // hobbies section
  const hobbies = [
    
    {
      lookupField : LOOK_UP_HOBBY,
      label     : 'Hobbies',
      value     : userField.userPreference.hobby? userField.userPreference.hobby : null ,
      icon      : <PoolIcon color='primary' />,
      isLoading : true
    }
  ]

  // I am section
  const iAm = [
    {
      value     : user.userName,
      icon      : <Person color='primary' />,
      isLoading : false
    },
    {
      lookupProperty  : 'lookUpGender',
      lookupField : LOOK_UP_GENDER,
      value     : user.gender,
      icon      : user.gender == 'MAL' ? <Male color='primary' /> : <Female color='primary' />,
      isLoading : true
    },
    {
      lookupProperty  : 'lookUpRelStatus',
      lookupField : LOOK_UP_RELATIONSHIP_STATUS,
      value     : userField.userPreference.relationshipStatus == 'OTH' ? userField.userPreference.relationshipStatusDesc : userField.userPreference.relationshipStatus,
      icon      : <img src={ringIcon} className={classes.ringIcon}/>,
      isLoading : true
    },
    {
      lookupProperty  : 'lookUpGender',
      lookupField : LOOK_UP_GENDER,
      value     : userField.userPreference.lookingForGender,
      icon      : <PersonSearch color='primary' />,
      isLoading : true
    },
    {
      lookupProperty  : 'lookUpHeight',
      lookupField : LOOK_UP_HEIGHT,
      value     : userField.userPreference.height,
      icon      : <HeightIcon color='primary' />,
      isLoading : true
    },
    {
      lookupProperty  : 'lookUpBodyType',
      lookupField : LOOK_UP_BODY_TYPE,
      value     : userField.userPreference.bodyType,
      icon      : <AccessibilityIcon color='primary' />,
      isLoading : true
    },
    {
      lookupProperty  : 'lookUpHairColor',
      lookupField : LOOK_UP_HAIR_COLOR,
      value     : userField.userPreference.hairColor,
      icon      : <FaceRetouchingNatural color='primary' />,
      isLoading : true
    },
    {
      lookupProperty  : 'lookUpEyeColor',
      lookupField : LOOK_UP_EYE_COLOR,
      value     : userField.userPreference.eyeColor,
      icon      : <VisibilityIcon color='primary' />,
      isLoading : true
    },
    {
      lookupProperty  : 'lookUpRelType',
      lookupField : LOOK_UP_RELATIONSHIP_TYPE,
      value     : userField.userPreference.relationshipType,
      icon      : userField.userPreference.relationshipType == 'BRO' ? <Search color='primary' /> :<WcIcon color='primary' />,
      isLoading : true
    },
  ]

  // At the Surface Section
  const atSurface = [ 
    {
      lookupProperty  : 'lookUpKidSituation',
      lookupField : LOOK_UP_KID_SITUATION,
      value     : userField.userPreference.kidSituation,
      icon      : <EscalatorWarning color='primary' />,
      isLoading : true
    },
  {
    lookupProperty  : 'lookUpPetPolicy',
    lookupField : LOOK_UP_PET_POLICY,
    value     : userField.userPreference.petPolicy,
    icon      : <PetsIcon color='primary' />,
    isLoading : true
  },
  {
    lookupProperty  : 'lookUpExerRegimen',
    lookupField : LOOK_UP_EXER_REGIMEN,
    value     : userField.userPreference.exerRegimen,
    icon      : <FitnessCenter color='primary' />,
    isLoading : true
  },
  {
    lookupProperty  : 'lookUpPrefAlcohol',
    lookupField : LOOK_UP_ALCOHOL,
    value     : userField.userPreference.prefAlcohol,
    icon      : <LocalBarIcon color='primary' />,
    isLoading : true
  },
  {
    lookupProperty  : 'lookUpPrefSmoking',
    lookupField : LOOK_UP_PREF_SMOKING,
    value     : userField.userPreference.prefSmoking,
    icon      : <SmokingRoomsIcon color='primary' />,
    isLoading : true
  },
]

  // Deeper Level Section
 const aDeeperLevel = [  {
  lookupProperty  : 'lookUpEthnicity',
  lookupField : LOOK_UP_ETHNICITY,
  value     : userField.userPreference.ethnicity ? userField.userPreference.ethnicity : null,
  icon      : <People color='primary' />,
  isLoading : true
},
{
  lookupProperty  : 'lookUpEducLevel',
  lookupField : LOOK_UP_EDUC_LEVEL,
  value     : userField.userPreference.educLevel,
  icon      : <School color='primary' />,
  isLoading : true
},
{
  lookupProperty  : 'lookUpProfession',
  lookupField : LOOK_UP_PROFESSION,
  value     : userField.userPreference.profession,
  icon      : <WorkIcon color='primary' />,
  isLoading : true
},
{
  lookupProperty  : 'lookUpIncomeLevel',
  lookupField : LOOK_UP_INCOME_LEVEL,
  value     : userField.userPreference.incomeLevel,
  icon      : <AttachMoney color='primary' />,
  isLoading : true
},
{
  lookupProperty  : 'lookUpReligBelief',
  lookupField : LOOK_UP_RELIG_BELIEF,
  value     : userField.userPreference.religBelief == 'OTH' ? userField.userPreference.religBeliefDesc : userField.userPreference.religBelief,
  icon      : <FireplaceIcon color='primary' />,
  isLoading : true
},
{
  lookupProperty  : 'lookUpPosPolitics',
  lookupField : LOOK_UP_POS_POLITICS,
  value     : userField.userPreference.posPolitics,
  icon      : <FlagIcon color='primary' />,
  isLoading : true
},
{
  value     : getFormattedDateTime(user.birthday, null, 'MMM DD, YYYY'),
  icon      : <FmdGood color='primary' />,
  isLoading : false
},
{
  lookupField : LOOK_UP_LANGUAGE,
  value     : userField.userPreference.language,
  icon      : <Public color='primary' />,
  isLoading : true
},]


  return (
    
    <Box pr={0} pl={0} boxShadow={2} style={{ borderRadius: 10, backgroundColor: '#ffffff', paddingBottom: 10}}>

      {
         iAm[0].value?
         
        <List mb={10} style={{marginLeft: 40, marginTop: 10, marginBottom: 10}}>
        <Typography variant='h6' color='textPrimary'>I am:</Typography>
          {
            iAm.filter(item => item.value != null).map((item, index) => (
             <UserProfileItem {...props} {...item} key={index} />
            ))
          }
        </List> : null
      }

      {
        aboutMe[0].value?

        <List mb={10} style={{marginLeft: 40, marginTop: 10, marginBottom: 10}}>
        <Typography variant='h6' color='textPrimary'>My Story:</Typography>
          {
            aboutMe.filter(item => item.value != null).map((item, index) => (
              <UserProfileItem {...props} {...item} key={index} />
            ))
          }
         </List> : null
      } 
      {
        atSurface[0].value?

        <List style={{marginLeft: 40, marginTop: 10, marginBottom: 10}}>
        <Typography variant='h6' color='textPrimary'>At the Surface:</Typography>
          {
            atSurface.filter(item => item.value != null ).map((item, index) => (
              <UserProfileItem {...props} {...item} key={index} />
            ))
          }
        
         </List> : null
      }
     
      {
        aDeeperLevel[0].value?

        <List style={{marginLeft: 40, marginTop: 10, marginBottom: 10}}>
        <Typography variant='h6' color='textPrimary'>A Deeper Level:</Typography>
          {
            aDeeperLevel.filter(item => item.value != null  ).map((item, index) => (
              <UserProfileItem  {...props} {...item} key={index} />
            
            ))
          
          }
        </List> : null
      }
      
      {
        hobbies[0].value.length > 0 ? 

        <List style={{marginLeft: 40, marginTop: 10, marginBottom: 10}}>
        <Typography variant='h6' color='textPrimary'>For Fun:</Typography>
          {
            hobbies.filter(item => item.value != null).map((item, index) => (
              <UserProfileItem  {...props} {...item} key={index} />
            
            ))
          }
        </List> : null
      }
    </Box>
  )
}

export default Profile;