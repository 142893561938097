import { makeStyles } from "@material-ui/core/styles";
import colors from "../../utils/colors";

const useStyles = makeStyles((theme) => ({

  homeContainer: {
    padding: "1em",
    [theme.breakpoints.up("md")]: {
      padding: "4em",
      paddingLeft: "5em",
      paddingRight: "5em",
    },
    backgroundColor: colors.BACKGROUND_COLOR,
    position: 'absolute',
    marginTop: '120px',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
  },

  messageContainer: {
    background: 'linear-gradient(#FDC830, #F37335)',
    borderRadius: '10px',
    border: 'none',
  },

  linkToMessages: {
    textAlign: 'end',
    fontSize: '12px',
    position: 'absolute',
    bottom: 0,
    right: 0,
    marginRight: '1em',
    '& a': {
      color: colors.WHITE,
      borderBottom: 'solid 1px',
      cursor: 'pointer',
      fontSize: 12
    }
  },

  cardContentMessage: {
    position: 'relative',
    minHeight: '500px',
    [theme.breakpoints.down('xs')]: {
      padding: 0
    },
    '& > button' : {
      textDecoration : 'none! important'
    }
  },

  cardContentMessageWrapper: {
    margin: '1em'
  },

  cardMessageHeader: {
    color: colors.WHITE,
    marginLeft: '1em'
  },

  boxDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    gap: '20px',
    color: colors.PRIMARY
  },

  loading:{
    color: colors.PRIMARY_TEXT
  },

  failHead: {
    marginTop: "15%",
  },

  link: {
    color: colors.PRIMARY_TEXT
  },

  applySubContainer: {
    outline: 'none',
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		borderRadius: 10,
		width: 400,
		height: 350,
		backgroundColor: 'white',
		p: 3,
	},

  paymentModalContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    outline: 'none',
    position: 'absolute',
    minWidth: 350,
    maxWidth: 350,
    height: 450,
    color: colors.PRIMARY,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: colors.WHITE,
    borderRadius: 15,
    padding: theme.spacing(5, 5, 5, 5),
    '& > .MuiTypography-root': {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },

    '& > .MuiButtonBase-root': {
      marginTop: theme.spacing(3),
    },

    '& > .MuiRating-root': {
      color : colors.YELLOW
    }
  },

  confettiContainer: {
    marginTop: 50,
    width: 180,
    height: 180,
    overflow: 'hidden',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 30,
    '& > img':{
      width: "100%",
      height: "100%",
    },
  },

  imageContainer: {
    marginTop: 20,
    width: 250,
    height: 250,
    overflow: 'hidden',
    marginLeft: 'auto',
    marginRight: 'auto',
    '& > img':{
      width: "100%",
      height: "100%",
    },
    
  },

  paymentDescriptionContainer:{
    marginBottom: 'auto'
  }
  
}));

export default useStyles;
