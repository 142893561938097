import { makeStyles } from '@material-ui/styles';
import colors from '../../../utils/colors';

const useStyles = makeStyles(theme => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
    },
    li: {
      listStyle: 'none',
    },
  },

  gridListContainer: {
    padding : theme.spacing(0, 5),
    display: 'flex',
    overflowX: 'scroll'
  },

  gridListItem: {
    borderTopLeftRadius : 20,
    borderTopRightRadius : 20,
    marginTop : 15,
    padding: theme.spacing(1),

    '& > .MuiAvatar-rounded': {
      borderRadius : 20
    }
  },

  activeGridListItem: {
    backgroundColor: colors.WHITE,
    borderTopLeftRadius : 20,
    borderTopRightRadius : 20
  },

  gridListImage: {
    height : 70,
    width : 70
  }
}));

export default useStyles;