import React, { useEffect, useState } from 'react';
import { Avatar, Card, Grid, Typography } from '@material-ui/core';

import moment from 'moment';

import CallMadeIcon from '@material-ui/icons/CallMade';
import CallMissedIcon from '@material-ui/icons/CallMissed';
import CallReceivedIcon from '@material-ui/icons/CallReceived';
import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import EventIcon from '@material-ui/icons/Event';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PhoneIcon from '@material-ui/icons/Phone';
import VideocamIcon from '@material-ui/icons/Videocam';

import useStyles from './style';

import {
  CALENDAR_INVITE_ACCEPTED,
  CALENDAR_INVITE_SENT,
  CALL_CARD,
  CALL_STATUS_INBOUND_AUDIO,
  CALL_STATUS_INBOUND_VIDEO,
  CALL_STATUS_MISSED_AUDIO,
  CALL_STATUS_MISSED_VIDEO,
  CALL_STATUS_OUTBOUND_AUDIO,
  CALL_STATUS_OUTBOUND_VIDEO,
  HEART_ACCEPTED,
  HEART_SENT,
  IMAGE_INVALID_LINK,
  MESSAGE_REQUEST_ACCEPTED,
  MESSAGE_REQUEST_SENT,
  NOTIFICATION_CARD,
  PHONE_CALL_REQUEST_ACCEPTED,
  PHONE_CALL_REQUEST_SENT,
  STATUS_ACTIVE,
  USER_SUBSCRIPTION_ELITE,
  USER_SUBSCRIPTION_PRIVACY,
  VIDEO_CALL_REQUEST_ACCEPTED,
  VIDEO_CALL_REQUEST_SENT
} from '../../utils/constants';

import { searchRequest } from '../../service/requests';
import { getImageCache, handleHttpError } from '../../utils/helper';
import { notificationContent } from '../../utils/notificationcontent';
import NoImageLink from '../../assets/images/user-profile.png'

const MessageCenterCard = (props) => {
  const classes = useStyles();
  const { userId, type } = props;
  const [profileImage, setProfileImage] = useState('');
  const { callLog, notification, handleOnClick } = props;
  const { message, user, userSubscription } = (type === NOTIFICATION_CARD) ? notification.notificationSender : callLog.notificationSender;

  useEffect(() => {
    if (user.mediaProfile) return;
    searchRequest.searchByUsername({
      user_id : userId,
      keyword : user.userName
    }).then(response => {
      if(!response.data.userConnection[0].user.mediaProfile.includes(IMAGE_INVALID_LINK)){
        setProfileImage(response.data.userConnection[0].user.mediaProfile);
      }
      else {
        setProfileImage(NoImageLink);
      }
    }).catch(error=>{
      setProfileImage(NoImageLink);
      handleHttpError({
        error,
        request: "searchRequest.searchByUsername::src/components/message-center-card"
      })
    })
  }, [user.mediaProfile])

  if ((type === NOTIFICATION_CARD) || (type === CALL_CARD)) {
    const time = moment.unix((type === NOTIFICATION_CARD) ? notification.whenAdded : callLog.whenAdded).fromNow();;
    const details = (type === NOTIFICATION_CARD) ? notificationContent()[notification.notifType].notifBody : callLog.details;
    const isUnseen = (type === NOTIFICATION_CARD) && (notification.isSeen === 0);
    const arePhotosPrivate = userSubscription && (userSubscription.viewabilityStatus == USER_SUBSCRIPTION_PRIVACY || userSubscription.viewabilityStatus == USER_SUBSCRIPTION_ELITE) && message != STATUS_ACTIVE;
    
    let notificationIcon = <></>;

    if (type === NOTIFICATION_CARD) {
      
      
     !user.mediaProfile.includes(IMAGE_INVALID_LINK) && getImageCache(user.mediaProfile).then((cachedImage) => {
        setProfileImage(cachedImage);
      }).catch((e)=>{
        setProfileImage(NoImageLink)
      });
      
      if ((notification.notifType === CALENDAR_INVITE_ACCEPTED) || (notification.notifType === CALENDAR_INVITE_SENT)) {
        notificationIcon  = <EventIcon className={`${isUnseen && classes.unseenText}`}/>;
  
      } else if ((notification.notifType === PHONE_CALL_REQUEST_ACCEPTED) || (notification.notifType === PHONE_CALL_REQUEST_SENT)) {
        notificationIcon  = <PhoneIcon className={`${isUnseen && classes.unseenText}`}/>;
  
      } else if ((notification.notifType === MESSAGE_REQUEST_ACCEPTED) || (notification.notifType === MESSAGE_REQUEST_SENT)) {
        notificationIcon  = <ChatBubbleIcon className={`${isUnseen && classes.unseenText}`}/>;
  
      } else if ((notification.notifType === VIDEO_CALL_REQUEST_ACCEPTED) || (notification.notifType === VIDEO_CALL_REQUEST_SENT)) {
        notificationIcon  = <VideocamIcon className={`${isUnseen && classes.unseenText}`}/>;
  
      } else if ((notification.notifType === HEART_SENT) || (notification.notifType === HEART_ACCEPTED)) {
        notificationIcon  = <FavoriteIcon className={`${isUnseen && classes.unseenText}`}/>;
  
      }
    } else {

      if (user.mediaProfile) {
        !user.mediaProfile.includes(IMAGE_INVALID_LINK) && getImageCache(user.mediaProfile).then((cachedImage) => {
          setProfileImage(cachedImage);
        }).catch((e)=>{
          setProfileImage(NoImageLink)
        });
      } 

      if (callLog.details == CALL_STATUS_OUTBOUND_AUDIO || callLog.details == CALL_STATUS_OUTBOUND_VIDEO) {
        notificationIcon  = <CallMadeIcon className={classes.outboundCall}/>;
      } else if (callLog.details == CALL_STATUS_INBOUND_AUDIO || callLog.details == CALL_STATUS_INBOUND_VIDEO) {
        notificationIcon  = <CallReceivedIcon className={classes.inboundCall}/>;
      } else if (callLog.details == CALL_STATUS_MISSED_AUDIO || callLog.details == CALL_STATUS_MISSED_VIDEO) {
        notificationIcon  = <CallMissedIcon className={classes.missedCall}/>;
      }
    }

    return (
      <Grid item xs={12}>
        <Card className={`${classes.notifCard} ${isUnseen && classes.unseenCard}`} onClick={handleOnClick} >
          <Grid container>
            <Grid item xs={3} md={2} lg={2}>
              <Avatar
                alt={user.userName}
                className={`${classes.image} ${arePhotosPrivate && classes.blurImage}`}
                src={profileImage}
              />
            </Grid>
            <Grid item xs={5} md={8} lg={8} className={classes.info}>
              {
                (type === NOTIFICATION_CARD)
                ? <Typography variant="subtitle1" className={`${classes.bold} ${isUnseen && classes.unseenText}`}>
                    {`${user.userName}`}
                    <Typography component="span" color="textPrimary" className={`${isUnseen && classes.unseenText}`}>
                      {details}
                    </Typography>
                  </Typography>
                : <div className={classes.column}>
                    <Typography variant="subtitle1" align='left' className={classes.bold}>
                      {`${user.userName}`}
                    </Typography>
                    <Typography variant="subtitle1" color="textPrimary">
                      {details}
                    </Typography>
                  </div>
              }
            </Grid>
            <Grid item xs={4} md={2} lg={2} className={classes.thirdRow}>
              {notificationIcon}
              <Typography variant="subtitle1" color="textPrimary" className={`${isUnseen && classes.unseenText}`}>
                {time}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    )
  }
}
export default MessageCenterCard;