import { makeStyles } from '@material-ui/core/styles';
import colors from '../../utils/colors'

export const useStyles = makeStyles(theme => ({

  selectBox: {
    position: "absolute",
    height: "100%"
  },
  boxStyle: {
    position: "relative",
    height:"30px"
  }


}));

