import React, { useState, useEffect } from 'react';
import { Container, Box, CardMedia, Typography, Button } from '@material-ui/core';
import useStyles from './style';
import colors from '../../utils/colors';
import Title from '../../components/title';
import UserProfile from '../../components/userprofile';
import { connect } from 'react-redux';
import { searchRequest } from '../../service/requests';

import connectionAction from '../../redux/connection/action';
import { handleHttpError, isEmpty } from '../../utils/helper';
import Preloader from '../../components/preloader';
import NotFound from '../../components/notfound';
import { HTTP_STATUS, PRELOADER_USER_PROFILE, USER_GENDER_ALL } from '../../utils/constants';
const { setUserConnection } = connectionAction;

const Profile = (props) => {
  console.log('Profile()');

  const classes = useStyles();
  const { match, userId, setUserConnection, showAlert, userConnection } = props;
  const userName = match.params.username;

  const [isUserConnectionsLoading, setUserConnectionLoading] = useState(false);

  useEffect(() => {
    console.log('Profile.useEffect()');

    if (isEmpty(userConnection)) {
      setUserConnectionLoading(true)
      searchRequest.searchByUsername({
        user_id : userId,
        keyword : userName,
        gender  : USER_GENDER_ALL
      }).then(response => {
        console.log('response : ', response.data);

        setUserConnectionLoading(false)
        if (response?.data?.code == HTTP_STATUS._471) {
          console.log('NOT FOUND')
        } else {
          const responseUserName = response.data.userConnection[0].user.userName;

          (userName === responseUserName ) && setUserConnection(response.data.userConnection[0]);
        }
      }).catch(error => {
        handleHttpError({
          error,
          request: 'searchRequest.searchByUsername::src/views/profile'
        })
      });
    }

  }, [])

  return (
    <Container
     maxWidth='xl' 
     className={classes.root}
     >
      {
        isUserConnectionsLoading ?
          <Preloader type={PRELOADER_USER_PROFILE} />
        : !isEmpty(userConnection) ?
          <UserProfile showAlert={showAlert} />
        : <NotFound/>
      }
    </Container>
  );
}


/////////////////////////////
// mapStateToProps()
/////////////////////////////
const mapStateToProps = (state) => {
  console.log('search/result/index.js:mapStateToProps(state)');
  console.log(state);
  return {
    userConnection      : state.Connection.userConnection,
    userConnectionList  : state.Connection.userConnectionList,
    // isLoading           : state.Connection.isLoading,
    // searchResult        : state.Search.searchResult,
    // searches          : state.Search.searches,
    userId              : state.Session.user.user.userId
  };
}

export default connect(mapStateToProps, { setUserConnection })(
  Profile
);