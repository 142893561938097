import { makeStyles } from '@material-ui/core/styles';
import colors from '../../utils/colors';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'absolute',
    marginTop: '120px',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
  },

  profileContainer: {
    // padding: theme.spacing(2, 0, 0, 0),
    padding: theme.spacing(5),
    backgroundColor: colors.WHITE
  },

}));

export default useStyles;