import React, { useState, useEffect, useRef } from 'react';
import { Box, Grid, CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import Empty from '../../../components/empty';
import Preloader from '../../../components/preloader';

import useStyles from './style';
import NO_NOTIFICATION_IMG from '../../../assets/images/no_notifications.png';
import NotificationCard from '../../../components/notification-card'
import notificationAction from '../../../redux/notification/action';


import {
  NO_NOTIFICATION_DESC,
  PRELOADER_NOTIFICATION_CARD,
  NO_NOTIFICATION_HISTORY_TITLE,
} from '../../../utils/constants'

const { getNotifications } = notificationAction;


const Notification = (props) => {

  //from mapstate
  const {
    notificationList,
    notificationListPage,
    isNotificationLoading,
    isNotificationLoaded,
    isAllNotificationFetched,
    getNotifications
  } = props

  //from componentprops
  const {
    handleClickNotification,
    value,
    isOnBottom,
    setIsOnBottom
  } = props

  const classes = useStyles()
  const ref = useRef()

  useEffect(() => {
    if (!isNotificationLoaded && value === 0) {
      getNotifications({ notificationListPage: notificationListPage });
    }

  }, [value]);

  useEffect(() => {
    if (isOnBottom) {
      if (value === 0 && !isNotificationLoading && isNotificationLoaded && !isAllNotificationFetched) {
        getNotifications({ notificationListPage: notificationListPage + 1 });
        if (ref.current) {
          setTimeout(() => {
            ref.current.scrollIntoView({ behavior: "smooth" })
          }, 1)
        }
      }
    }
  }, [isOnBottom])

  useEffect(() => {
    if (!isNotificationLoading) {
      setIsOnBottom(false)
    }
  }, [isNotificationLoading])

  //return if no notification
  if (isNotificationLoaded && (notificationList.length === 0 || !notificationList)) {
    return <Empty title={NO_NOTIFICATION_HISTORY_TITLE} description={NO_NOTIFICATION_DESC} image={NO_NOTIFICATION_IMG} />
  }

  return (
    <Box
      justifyContent={(!isNotificationLoading && notificationList.length) ? 'flex-start' : 'center'}
      alignItems={(!isNotificationLoading && notificationList.length) ? 'flex-start' : 'center'}
      display='flex'
    >
      {
        (!isNotificationLoaded) ? (
          <Grid
            container
            justify='flex-start'
            alignItems='center'
            spacing={2}
          >
            {
              Array.from({ length: 5 }, () => 1).map((value, index) => {
                return (
                  <Preloader type={PRELOADER_NOTIFICATION_CARD} key={index} />
                )
              })
            }
          </Grid>
        ) : (
          <Grid container spacing={2} alignItems="stretch">
            {
              notificationList.map((notification, index) => {
                return (
                  <NotificationCard {...props} key={index} notification={notification} handleOnClick={() => handleClickNotification(notification)} />
                )
              })
            }
            {
              isNotificationLoaded && (
                <Box className={!isNotificationLoading ? classes.hidden : classes.loadingBar}>
                  <CircularProgress ref={ref} />
                </Box>
              )
            }
          </Grid>
        )

      }

    </Box>
  )
}


/////////////////////////////
// mapStateToProps()
/////////////////////////////
const mapStateToProps = (state) => {
  console.log('message-center/index.js:mapStateToProps(state)');
  console.log(state);
  return {
    userId                : state?.User?.user?.userId,
    notificationList      : state?.Notification?.notificationList,
    notificationListPage  : state?.Notification?.notificationListPage,
    isNotificationLoading : state?.Notification?.isNotificationLoading,
    isNotificationLoaded  : state?.Notification?.isNotificationLoaded,
  };
}

export default connect(mapStateToProps, { getNotifications })(
  Notification
);