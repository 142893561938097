import React from 'react';
import { makeStyles } from '@material-ui/styles';

import TopBar from '../topbar';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  }
}));

export const Main = (props) => {
  const { children } = props;
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <TopBar {...props} />
      {
        children
      }
    </div>
  );
};

export const None = (props) => {
  const { children } = props;

  const classes = useStyles();

  return (
    <div className={classes.root} >
      {
        children
      }
    </div>
  );
};
