import React from 'react';
import { Box, Container } from '@material-ui/core';

import useStyles from './style';
import Content from '../../settings/account-deletion';

function AccountDeletion() {
  const classes = useStyles();

  return (
    <Container component={Box} maxWidth="lg" className={classes.container}>
      <Content showTitle={true}/>
    </Container>
  );
}

export default AccountDeletion;