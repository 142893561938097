import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../utils/colors';

const useStyles = makeStyles(theme => ({
  // New Search
  container: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',

    '& > .MuiButton-root:last-of-type': {
      visibility: 'hidden',
    }
  },

  searchNameHeader: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  
  searchName: {
    fontWeight: 'bold',
  },

  editSearchName: {
    marginLeft: theme.spacing(1),
    alignSelf: 'center',
    cursor: 'pointer',

    '&:hover': {
      color: colors.TEXT_GREEN
    },
  },

  form: {
    display: 'flex',
    flexDirection: 'column',

    '& > .MuiBox-root': {
      display: 'flex',
      justifyContent: 'space-between'
    },

    '& > .MuiBox-root:nth-of-type(3)': {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'flex-start',
      marginBottom: theme.spacing(2),
    },

    '& > .MuiSlider-root': {
      marginBottom: 36
    }
  },

  radioGroup: {
    margin: theme.spacing(1, 0, 1, 0),
    alignItems: 'center',

    '& .MuiTypography-root:first-of-type': {
      marginRight: theme.spacing(3)
    },

    '& .MuiTypography-root': {
      color: colors.PRIMARY_TEXT
    },
  },

  // Modal
  paper: {
    left: 'calc(50% - 200px)',
    top: '50%',
    transform: 'translateY(-50%)',
    position: 'absolute',
    width: 400,
    backgroundColor: colors.BACKGROUND_COLOR,
    boxShadow: theme.shadows[5],
  },

  box: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(6),
    gap: '20px',
    '& .MuiTyphography-root': {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
    '& .MuiButton-root': {
      maxWidth: 300,
    }
  },
  
  textField: {
    width: 300,
    '& .MuiFormGroup-root .MuiFormControlLabel-root .MuiTypography-root': {
      color: colors.TEXT_GRAY
    },
    '& > .MuiFormHelperText-root': {
      color: 'red'
    }
  },
  selectBox: {
    width: '100%',
    maxWidth: 300,
  },

}));

export default useStyles;