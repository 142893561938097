import React, { useEffect, useState } from 'react';
import { Avatar, Card, Grid, Typography } from '@material-ui/core';

import moment from 'moment';

import ChatBubbleIcon from '@material-ui/icons/ChatBubble';
import EventIcon from '@material-ui/icons/Event';
import FavoriteIcon from '@material-ui/icons/Favorite';
import PhoneIcon from '@material-ui/icons/Phone';
import VideocamIcon from '@material-ui/icons/Videocam';

import useStyles from './style';

import {
  CALENDAR_INVITE_ACCEPTED,
  CALENDAR_INVITE_SENT,
  HEART_ACCEPTED,
  HEART_SENT,
  IMAGE_INVALID_LINK,
  MESSAGE_REQUEST_ACCEPTED,
  MESSAGE_REQUEST_SENT,
  PHONE_CALL_REQUEST_ACCEPTED,
  PHONE_CALL_REQUEST_SENT,
  STATUS_ACTIVE,
  USER_SUBSCRIPTION_ELITE,
  USER_SUBSCRIPTION_PRIVACY,
  VIDEO_CALL_REQUEST_ACCEPTED,
  VIDEO_CALL_REQUEST_SENT,
  HTTP_STATUS
} from '../../utils/constants';

import { searchRequest } from '../../service/requests';
import { getImageCache, handleHttpError } from '../../utils/helper';
import { notificationContent } from '../../utils/notificationcontent';
import NoImageLink from '../../assets/images/user-profile.png'

const NotificationCard = (props) => {
  const classes = useStyles();
  const [profileImage, setProfileImage] = useState('');
  const { notification, handleOnClick,  userId } = props;
  const { message, user, userSubscription } = notification.notificationSender;

  useEffect(() => {
    if (user?.mediaProfile === "") return;
	console.log("searchRequest/notification-card : ", userId, user)
    searchRequest.searchByUsername({
      user_id : userId,
      keyword : user.userName
    }).then(response => {
      console.log('userRequest.searchByUsername response : ', response);
	  if(response.data === HTTP_STATUS._200){
		setProfileImage(response.data.userConnection[0].user.mediaProfile);
	  }
    }).catch(error => {
      handleHttpError({
		error,
		request: "searchRequest.searchByUsername::src/components/notification-card"
	  })
    });

    return () =>{
      setProfileImage('')
    }

  }, [user.mediaProfile])

	const time              = moment.unix(notification.whenAdded).fromNow();
	const details           = notificationContent()[notification.notifType].notifBody;
	const isUnseen          = notification.isSeen === 0;
	const arePhotosPrivate  = userSubscription && (userSubscription.viewabilityStatus == USER_SUBSCRIPTION_PRIVACY || userSubscription.viewabilityStatus == USER_SUBSCRIPTION_ELITE) && message != STATUS_ACTIVE;

	let notificationIcon = <></>;


	!user.mediaProfile.includes(IMAGE_INVALID_LINK) && getImageCache(user.mediaProfile).then((cachedImage) => {
    setProfileImage(cachedImage);
  }).catch((e)=>{
    setProfileImage(NoImageLink)
  });
		
	if ((notification.notifType === CALENDAR_INVITE_ACCEPTED) || (notification.notifType === CALENDAR_INVITE_SENT)) {
		notificationIcon  = <EventIcon/>;

	} else if ((notification.notifType === PHONE_CALL_REQUEST_ACCEPTED) || (notification.notifType === PHONE_CALL_REQUEST_SENT)) {
		notificationIcon  = <PhoneIcon/>;

	} else if ((notification.notifType === MESSAGE_REQUEST_ACCEPTED) || (notification.notifType === MESSAGE_REQUEST_SENT)) {
		notificationIcon  = <ChatBubbleIcon/>;

	} else if ((notification.notifType === VIDEO_CALL_REQUEST_ACCEPTED) || (notification.notifType === VIDEO_CALL_REQUEST_SENT)) {
		notificationIcon  = <VideocamIcon/>;

	} else if ((notification.notifType === HEART_SENT) || (notification.notifType === HEART_ACCEPTED)) {
		notificationIcon  = <FavoriteIcon/>;

	}

	return (
		<Grid item xs={12} hidden={details === null || details === ''} >
			<Card className={`${classes.notifCard}`} onClick={handleOnClick }  >
				<Grid container >
					<Grid item xs={3} md={2} lg={2} >
						<Avatar
							alt={user.userName}
							className={`${classes.image} ${arePhotosPrivate && classes.blurImage}`}
							src={profileImage}
						/>
					</Grid>
					<Grid item xs={5} md={8} lg={8} className={classes.info} >
						<Typography variant="subtitle1" className={classes.bold}>
							{`${user.userName}`}
							<Typography component="span" color="textPrimary">
								{details}
							</Typography>
						</Typography>
					</Grid>
					<Grid item xs={4} md={2} lg={2} className={classes.thirdRow}>
						{notificationIcon}
						<Typography variant="subtitle1" color="textPrimary">
							{time}
						</Typography>
					</Grid>
				</Grid>
			</Card>
		</Grid>
	)
}
export default NotificationCard;