import { Client } from '@twilio/conversations';
import messageAction     from '../redux/message/action';
import store from '../store'

/////////////////////////
// createChatClient()
/////////////////////////
export const createChatClient = async (tokenTwilio) => {
  console.log('util/twilio/createChatClient');
  console.log('tokenTwilio : ', tokenTwilio);

  return await Client.create(tokenTwilio);
}

/////////////////////////
// createChannel()
/////////////////////////
export const createChannel = async (client, uniqueName, friendlyName) => {
  console.log('util/twilio/createChannel');
  console.log('client       : ', client);
  console.log('uniqueName   : ', uniqueName);
  console.log('friendlyName : ', friendlyName);

  return await client.createConversation({
    uniqueName,
    friendlyName
  });
}

/////////////////////////
// getPublicChannels()
/////////////////////////
export const getPublicChannels = async (client) => {
  console.log('util/twilio/getPublicChannels');
  console.log('client : ', client);

  const paginator = await client.getPublicChannelDescriptors();

  return await paginator.items;
}

/////////////////////////
// getUserChannels()
/////////////////////////
export const getUserChannels = async (client) => {
  console.log('util/twilio/getConversations');
  console.log('client : ', client);

  return await client.conversations.conversations;
}

/////////////////////////
// listenForInvite()
/////////////////////////
export const listenForInvite = (client) => {
  console.log('util/twilio/listenForInvite');
  console.log('client : ', client);

  client.on('channelInvited', (channel) => {
    if(channel.state.status !== "joined") channel.join();
  });
}

/////////////////////////
// getChannel()
/////////////////////////
export const getChannel = async (channelDescriptor) => {
  console.log('util/twilio/getChannel');
  console.log('channelDescriptor : ', channelDescriptor);

  return await channelDescriptor.getChannel()
}

/////////////////////////
// inviteMember()
/////////////////////////
export const inviteMember = async (channel, userId) => {
  console.log('util/twilio/inviteMember');
  console.log('channel : ', channel);

  return await channel.invite(userId);
}

/////////////////////////
// joinChannel()
/////////////////////////
export const joinChannel = (conversation) => {
  console.log('util/twilio/joinChannel');
  console.log('conversation : ', conversation);
  
  if (conversation.channelState.status !== "joined") conversation.join();
}

/////////////////////////
// listenToChannel()
/////////////////////////
export const listenToChannel = (channel) => {
  console.log('util/twilio/listenToChannel');
  console.log('channel : ', channel);
  channel.removeListener('messageAdded', handleMessageAdded);
  channel.on('messageAdded', handleMessageAdded)
}

const handleMessageAdded = (message) => {
  try{
    console.log('messageAdded', message)
    store.dispatch({
      type    : messageAction.MESSAGE_RECEIVED,
      payload : message
    })
  }catch(err){
    console.log('error', err)
  }
}

/////////////////////////
// subscribeToChannelMember()
/////////////////////////
export const subscribeToChannelMember = (channel) => {
  console.log('util/twilio/subscribeToChannelMember');
  console.log('channel : ', channel);
  
  const getChannelMembers = async () => {
    
    const channelMember = await channel.getMembers();
    
    return channelMember
  }

  getChannelMembers().then(members => {

    members.forEach(member => {
      const getUser = async () => {
        const channelUser = await member.getUser();
        
        return channelUser
      }

      getUser().then((user) => {
        const { friendlyName, identity } = user.state;
        const { userId, userName } = store.getState().User.user;
  
        if (userId === identity) {
          if (friendlyName !== userName) {
            user.updateFriendlyName(userName).catch(error => {
              console.log('error : ', error);
            });
          }
        }
      });
    }); 
  });
}

/////////////////////////
// getMessages()
/////////////////////////
export const getMessages = async (channel) => {
  console.log('util/twilio/getMessages');
  console.log('channel : ', channel);

  return await channel.getMessages();
}
