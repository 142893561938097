import React, { memo } from 'react';
import { Box, Container, Card, Grid, Typography, CardMedia } from '@material-ui/core';

import useStyles from './style';

import './style.css';

import {
  PRELOADER_CARD_VIEW,
  PRELOADER_CARD_SHORTLIST,
  PRELOADER_CARD_SHORTLIST_HOME,
  PRELOADER_CARD_SEARCH_HOME,
  PRELOADER_CARD_NEAR_ME,
  PRELOADER_CARD_USER,
  PRELOADER_CARD_CALENDAR,
  PRELOADER_CALL_CARD,
  PRELOADER_MESSAGE_CARD,
  PRELOADER_UNANSWERED_MESSAGE_CARD,
  PRELOADER_UPCOMING_EVENT_CARD,
  PRELOADER_NOTIFICATION_CARD,
  PRELOADER_USER_PROFILE,
  PRELOADER_SAVED_SEARCH,
  PRELODAER_MESSAGE,
  PRELOADER_USER_HISTORY,
  PRELOADER_REFERRAL_CODE,
  PRELOADER_BLOCKED_PROFILES,
  PRELOADER_PHOTO,
  PRELOADER_COUPON
} from '../../utils/constants';
import { getRandomNumber } from '../../utils/helper';

const Preloader = (props) => {
  const { type } = props;
  const classes = useStyles();

  if (type === PRELOADER_CARD_USER) {
    return (
      <Grid item xs={6} sm={4} md={3} lg={2}>
        <Card className={`${classes.userCard} preloader`}>
          <div className={classes.userImage} />
          <div className={classes.userContent}>
            <div className={classes.info}>
              <div className={classes.text} style={{width: getRandomNumber(100, 130)}} />
            </div>
            <div className={classes.info}>
              <div className={classes.text} style={{width: getRandomNumber(30, 130)}} />
            </div>
            <div className={classes.connectionContainer}>
              <div className={classes.heart} />
              <div className={classes.icon} />
            </div>
          </div>
        </Card>
      </Grid>
    )
  } else if (type === PRELOADER_CARD_SHORTLIST) {
    return (
      <Grid item xs={4} sm={4} md={3} lg={2} >
        <Card className={`${classes.shortlistCard} preloader`}>
          <div className={classes.shortlistImage} />
          <div className={classes.shortlistContent}>
            <div className={classes.title} style={{width: getRandomNumber(100, 130)}} />
            <div className={classes.text} style={{width: getRandomNumber(100, 130)}} />
            <div className={classes.text} style={{width: getRandomNumber(100, 130)}} />
          </div>
        </Card>
      </Grid>
    )
  } else if (type === PRELOADER_CARD_VIEW) {
    return (
      <Grid item xs={12} sm={6} md={4} lg={2}>
        <Card className={`${classes.viewCard} preloader`}>
          <CardMedia
            className={classes.viewImage}
          />
          <div className={classes.viewContent}>
            <div className={classes.title} style={{width: getRandomNumber(100, 150)}} />
            <div className={classes.text} style={{width: getRandomNumber(100, 150)}} />
            <div className={classes.text} style={{width: getRandomNumber(50, 150)}} />
          </div>
        </Card>
      </Grid>
    )
  } else if ((type === PRELOADER_NOTIFICATION_CARD) || (type === PRELOADER_CALL_CARD)) {
    return (
      <Grid item xs={12}>
        <Card className={`${classes.messageCenterCard} preloader`} >
          <Grid container>
            <Grid item xs={3} md={2} lg={2}>
              <div className={classes.messageCenterImage} />
            </Grid>
            <Grid item xs={5} md={8} lg={8} className={`${classes.info} ${(type === PRELOADER_CALL_CARD) && classes.column}`}>
              <div className={`${classes.text} ${(type === PRELOADER_CALL_CARD) && classes.participantUsername}`} style={{ width: getRandomNumber(100, 130) }} />
              <div className={classes.text} style={{ width: getRandomNumber(100, 130) }} />
            </Grid>
            <Grid item xs={4} md={2} lg={2} className={classes.info}>
              <div className={classes.icon} />
              <div className={classes.text} style={{ width: getRandomNumber(40, 130)}} />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    )
  } else if (type === PRELOADER_MESSAGE_CARD) {
    return (
      <Grid item xs={12}>
        <Card className={`${classes.messageCenterCard} preloader`} >
          <Grid container>
            <Grid item xs={3} md={2} lg={2}>
              <div className={classes.messageCenterImage} />
            </Grid>
            <Grid item xs={5} md={8} lg={8} className={`${classes.info} ${(type === PRELOADER_CALL_CARD) && classes.column}`}>
              <div className={`${classes.text} ${(type === PRELOADER_CALL_CARD) && classes.participantUsername}`} style={{ width: Math.random() * (130 - 100) + 100 }} />
              <div className={classes.text} style={{ width: Math.random() * (130 - 100) + 100 }} />
            </Grid>
            <Grid item xs={4} md={2} lg={2} className={classes.info}>
              <div className={classes.icon} />
              <div className={classes.text} style={{ width: Math.random() * (130 - 40) + 40 }} />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    )
  } else if (type === PRELOADER_USER_PROFILE) {
    return (
      <Container component={Box} maxWidth='lg' className={`${classes.userProfileContainer} preloader`}>
        <Box className={classes.box}>
          <div className={classes.userProfileImage} />
          <div className={classes.userProfileInfoContainer}>
            <Typography variant='h5' className={classes.userProfileUsername}>username, 00</Typography>
            <Typography variant='subtitle1' className={classes.userProfileAddress}>0000 Street Baranggay, City, Province, State, Country</Typography>
            <div className={classes.connectionContainer}>
              <div className={classes.userProfileRating} />
              <div className={classes.userProfileHeart} />
              <div className={classes.userProfileHeart} />
            </div>
          </div>
        </Box>
        <div className={classes.userProfileContent} />
      </Container>
    ) 
  } else if (type === PRELOADER_SAVED_SEARCH) {
    return (
      <Grid item xs={12} sm={12} md={6} lg={4}>
        <Card className={`${classes.savedSearchContainer} preloader`}>
          <div className={classes.info}>
            <div className={classes.text} style={{width: getRandomNumber(100, 200)}} />
          </div>
        </Card>
      </Grid>
    )
  } else if (type === PRELOADER_CARD_CALENDAR) {
    return (
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <Box component={Card} display='flex' flexDirection='row' borderRadius={20}>
          <Box className={classes.eventStatus} width='15%'>
            <Grid container justify='space-around' alignItems='center'>
              <Grid item xs={12}>
                <div className={classes.date} style={{ width: getRandomNumber(130, 130)}} />
              </Grid>
              <Grid item xs={12}>
                <div className={classes.date} style={{ width: getRandomNumber(130, 130)}} />
              </Grid>
              <Grid item xs={12}>
                <div className={classes.date} style={{ width: getRandomNumber(130, 130)}} />
              </Grid>
              <Grid item xs={12}>
                <div className={classes.date} style={{ width: getRandomNumber(130, 130)}} />
              </Grid>
            </Grid>
          </Box>
          <Box width='85%' height='auto' display='flex' flexDirection='column' justifyContent='space-around'>
            <Box 
              ml={2} 
              fontSize='subtitle1.fontSize' 
              height='33.33%' 
              textOverflow='ellipsis' 
              display='flex' 
              color='textPrimary'
              alignItems='center'
            >
              <div className={classes.eventText} style={{ width: getRandomNumber(130, 130)}} />
            </Box>
            <Box 
              ml={2}
              fontSize='subtitle1.fontSize' 
              height='33.33%' 
              textOverflow='ellipsis' 
              display='flex' 
              color='textPrimary'
              alignItems='center'
            >
              <div className={classes.eventText} style={{ width: getRandomNumber(130, 130)}} />
            </Box>
            <Box 
              ml={2} 
              fontSize='subtitle1.fontSize' 
              height='33.33%' 
              textOverflow='ellipsis' 
              display='flex' 
              color='textPrimary'
              alignItems='center'
            >
              <div className={classes.eventText} style={{ width: getRandomNumber(130, 130)}} />
            </Box>
          </Box>
        </Box>
      </Grid>
    )
  } else if (type === PRELODAER_MESSAGE) {
    const { isReceived } = props

    return (
      <Box className={`${classes.messageContainer} ${isReceived ? classes.received : classes.sent} preloader`} style={{ width: getRandomNumber(100, 130) }}>

      </Box>
    )
  } else if (type === PRELOADER_USER_HISTORY) {
    return (
      <Box className={`${classes.historyContainer} preloader`} >
        <Box>
          <Box className={classes.historyIcon}>
          </Box>
          <div className={classes.text} style={{ width: getRandomNumber(100, 250) }} />
        </Box>
        <Box>
          <div className={classes.text} style={{ width: getRandomNumber(80, 130) }} />
        </Box>
      </Box>
    )
  } else if (type === PRELOADER_CARD_SEARCH_HOME) {
    return (
      <Card className={`${classes.shortlistCard} ${classes.searchlistHome} preloader`}>
        <div className={classes.shortlistImage} />
        <div className={classes.shortlistContent}>
          <div className={classes.info}>
            <div className={classes.text} style={{width: 150}} />
          </div>
          <div className={classes.info}>
            <div className={classes.text} style={{width: getRandomNumber(100, 150)}} />
          </div>
        </div>
      </Card>
    )
  } else if (type === PRELOADER_CARD_SHORTLIST_HOME) {
    return (
      <div className={classes.shortlistHome}>
        <div className={`${classes.shortlistImageHomeWrapper} preloader`}>
          <Card>
            <div className={classes.shortlistImageHome} />
          </Card>
        </div>
        <div className={`${classes.shortlistHomeText} preloader`}>
          <div className={classes.text} style={{width: 110}} />
        </div>
      </div>
    )
  } else if (type === PRELOADER_UNANSWERED_MESSAGE_CARD) {
    return (
      <Card className={`${classes.unansweredMessageCard} preloader`} >
        <div className={classes.unansweredMessageImage} />
        <div className={classes.contentView}>
          <div className={classes.info}>
            <div className={classes.text} style={{width: getRandomNumber(100, 130)}} />
          </div>
          <div className={classes.info}>
            <div className={classes.text} style={{width: getRandomNumber(70, 130)}} />
          </div>
        </div>
      </Card>
    )
  } else if (type === PRELOADER_UPCOMING_EVENT_CARD) {
    return (
      <Card className={classes.upcomingEventCard}>
        <div className={`${classes.upcomingEventDate} preloader`}></div> 
        <div className={classes.upcomingEventInfo}>
          <div className={`${classes.text} ${classes.upcomingEventText} preloader`} style={{width: getRandomNumber(120, 150)}} />
          <div className={`${classes.text} ${classes.upcomingEventText} preloader`} style={{width: getRandomNumber(100, 120)}} />
        </div>
        
      </Card>
    )
  } else if (type === PRELOADER_REFERRAL_CODE) {
    return (<div className={`${classes.referralCode} preloader`} style={{width: 130}} />);
  } else if (type === PRELOADER_BLOCKED_PROFILES) {
    return (
      <Card className={`${classes.blockedUserCard} preloader`} >
        <div className={classes.blockedUserImage} />
        <div className={classes.contentView}>
          <div className={`${classes.info} ${classes.blockedUsername}`}>
            <div className={classes.text} style={{width: getRandomNumber(100, 130)}} />
          </div>
        </div>
      </Card>
    )
  } else if (type === PRELOADER_PHOTO) {
    return (
      <Grid >
        <Card className={`${classes.userCard} preloader`}>
          <div className={classes.userImage} />
        </Card>
      </Grid>
    )
  } else if (type === PRELOADER_COUPON){
    return(
      <Grid className={`${classes.couponCard} preloader`}>
        <Grid className={classes.left}/>
        <Grid className={classes.right}/>
      </Grid>
    )
  }
}

export default memo(Preloader);