import React, { useState, useEffect }  from 'react';
import { userRequest } from '../../../service/requests';
import { FormControl, Select, MenuItem, TextField, FormHelperText, Box, Button, CircularProgress } from '@material-ui/core';
import SnackBar from '../../../components/snackbar';
import { LOOK_UP_ISSUE_CATEG, ALERT_SEVERITY_SUCCESS, ALERT_SEVERITY_ERROR, TOAST_AXIOS_REQUEST_ERROR, HTTP_STATUS } from '../../../utils/constants';
import useStyles from './style';
import { handleHttpError } from '../../../utils/helper';

const ReportIssues = (props) => {
	const classes = useStyles();

  const { getLookUpFields, lookUpIssueCateg, userId } = props;

  const [lookUpIssueCategUpdate, setLookUpIssueCategUpdate] = useState([lookUpIssueCateg]);
  const [code, setCode] = React.useState('TEC');
  const [isCodeError, setIsCodeError] = useState(false);
  const [codeErrorDesc, setCodeErrorDesc] = useState('');
  
  const [details, setDetails] = React.useState('');
  const [isDetailsError, setIsDetailsError] = useState(false);
  const [detailsErrorDesc, setDetailsErrorDesc] = useState('');

  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState(false);

  const [isSending, setIsSending] = useState(false);


  useEffect(() => {
    //Remove Cancel Membership from the choice
    setLookUpIssueCategUpdate(lookUpIssueCategUpdate => (lookUpIssueCategUpdate = lookUpIssueCateg.filter(issueCategory => (issueCategory.code !== 'CAN'))))
    if (lookUpIssueCateg.length == 0) {
      getLookUpFields(LOOK_UP_ISSUE_CATEG);
    }

  }, []);

  const handleCodeChange = (event) => {
    setCode(event.target.value);

    if (!event.target.value) {
      setIsCodeError(true);
      setCodeErrorDesc('Required.');
    } else {
      setIsCodeError(false);
      setCodeErrorDesc('');
    }
  };

  const handleInputChange = (event) => {
    let value = event.target.value; 
    if (!value.trim('') && value.length) {
      value = '';
      return;
    }
    setDetails(value);

    if (value === '') {
      setIsDetailsError(true);
      setDetailsErrorDesc('Required.');
    } else {
      setIsDetailsError(false);
      setDetailsErrorDesc('');
    }
  };

  const reportIssue = async () => {
    if (!code || details === '') {
      setIsCodeError(!code ? true : false);
      setCodeErrorDesc(!code ? 'Required.' : '');
      setIsDetailsError(details === '' ? true : false);
      setDetailsErrorDesc(details === '' ? 'Required' : '');
    } else {
      try {
        setIsSending(true);
        const response = await userRequest.reportIssue({
          user_id     : userId,
          categ_code  : code,
          details     : details,
        }).catch(error => {
          handleHttpError({
            error,
            request: 'userRequest.reportIssue::src/views/settings/report-issues'
          })
        })

        if (response?.data?.code == HTTP_STATUS._200) {
          setCode('TEC');
          setDetails('');
          setIsCodeError(false);
          setCodeErrorDesc('');
          setIsDetailsError(false);
          setDetailsErrorDesc('');

          setSnackbarMessage('Successfully sent an issue.');
          setSnackbarSeverity(ALERT_SEVERITY_SUCCESS);
          setIsSnackbarOpen(true);
        }
      } catch (error) {
        setSnackbarSeverity(ALERT_SEVERITY_ERROR);
        setSnackbarMessage(TOAST_AXIOS_REQUEST_ERROR);
        setIsSnackbarOpen(true);
        console.log('error : ', error);
      } finally {
        setIsSending(false);
      }
    }
  }
  const disabledButton = code == '' || details === '';
  return (
    <Box className={classes.reportIssue}>
      <FormControl variant="outlined" error={isCodeError} fullWidth>
        <Select
          value={code}
          onChange={handleCodeChange}
          MenuProps={{
            disableScrollLock: true, 
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left"
            },
            getContentAnchorEl: null
          }}
        >
          {
            lookUpIssueCateg.length > 0 && lookUpIssueCateg.map(value => 
              <MenuItem classes={{selected: classes.reportIssueSelectItem}} key={value.code} value={value.code}>
                {value.description}
              </MenuItem>
            )
          }
        </Select>
        <FormHelperText>{codeErrorDesc}</FormHelperText>
      </FormControl>
      
      <br/>
      <TextField
        required
        id="notes"
        multiline
        rows="10"
        placeholder="Please describe the issues"
        value={details}
        variant="outlined"
        onChange={handleInputChange}
        error={isDetailsError}
        helperText={detailsErrorDesc}
      />
      <br/>
      <Box>
        <Button disabled={isSending || disabledButton} className={classes.reportIssueButton} color="primary" variant="contained" onClick={reportIssue}>
          {
            isSending ?
              <CircularProgress size={24} />
            :
              'Send'
          }
        </Button>
      </Box>
      <SnackBar 
        severity={snackbarSeverity}
        message={snackbarMessage}
        open={isSnackbarOpen}
        setOpen={setIsSnackbarOpen}
      />
    </Box>
  )
};

export default ReportIssues;
