const action = {

  CONVERSATION_ADD               : "CONVERSATION_ADD",
  CONVERSATION_UPDATE               : "CONVERSATION_UPDATE",
  
  MESSAGE_RECEIVED               : "MESSAGE_RECEIVED",
  MESSAGE_PARTICIPANT_UPDATE     : "MESSAGE_PARTICIPANT_UPDATE",
  MESSAGE_USER_CONNECTION_UPDATE : "MESSAGE_USER_CONNECTION_UPDATE",
  MESSAGE_SAVE                   : "MESSAGE_SAVE",
  MESSAGE_UPDATE_SEEN            : "MESSAGE_UPDATE_SEEN",
  MESSAGE_LOADED_TOGGLE          : "MESSAGE_LOADED_TOGGLE",
  MESSAGE_GET                    : "MESSAGE_GET",
  MESSAGE_LIST_UPDATE            : "MESSAGE_LIST_UPDATE",
  
  TWILIO_UPDATE                  : "TWILIO_UPDATE",
  TWILIO_UPDATE_SUCCESS          : "TWILIO_UPDATE_SUCCESS",
  

  addConversation: conversation => ({
    type    : action.CONVERSATION_ADD,
    payload : conversation
  }),

  toggleMessageLoaded: () => ({
    type    : action.MESSAGE_LOADED_TOGGLE,
  }),

  updateTwilio: userId => ({
    type    : action.TWILIO_UPDATE,
    payload : userId
  }),

  updateTwilioClient: twilio => ({
    type    : action.TWILIO_UPDATE_SUCCESS,
    payload : twilio
  }),

  saveMessages: messages => ({
    type    : action.MESSAGE_SAVE,
    payload : messages
  }),

  updateMessages: messageReceived => ({
    type    : action.MESSAGE_RECEIVED,
    payload : messageReceived
  }),

  seenMessage: userId => ({
    type    : action.MESSAGE_UPDATE_SEEN,
    payload : userId
  }),

  updateMessageParticipant: participant => ({
    type    : action.MESSAGE_PARTICIPANT_UPDATE,
    payload : participant
  }),

  updateMessageUserConnection: userConnection => ({
    type    : action.MESSAGE_USER_CONNECTION_UPDATE,
    payload : userConnection
  }),

  getAllMessages: client => ({
    type    : action.MESSAGE_GET,
    payload : client
  })

};

export default action;
