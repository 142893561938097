import React, { useState, useEffect } from 'react';
import { Box, Typography, Card, Divider, Tabs, Tab, AppBar, Button, List, ListItem, ListItemText, Link, Modal, Container } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import SnackBar from '../../components/snackbar';
import useStyles from './style';
import { UPD_MODE_USER_MEMBERSHIP, ALERT_SEVERITY_SUCCESS, TOAST_SUBSCRIPTION_PLAN_CHANGED, USER_SUBSCRIPTION_TRIAL_END, USER_SUBSCRIPTION_TRIAL_ID, USER_SUBSCRIPTION_REGULAR_ID, STATUS_ACTIVE, USER_SUBSCRIPTION_TRIAL_PRICE, STATUS_INACTIVE, HTTP_STATUS } from '../../utils/constants';
import moment from 'moment';
import { userRequest, billingRequest } from '../../service/requests';
import { useHistory } from 'react-router-dom';
import userAction from '../../redux/user/action'
import { connect } from 'react-redux';

import ConfirmModal from './confirmModal';
import PaymentModal from './paymentModal'
import { handleHttpError } from '../../utils/helper';

import DiamondIcon from '../../assets/images/diamond.png'
import SunGlasses from '../../assets/images/sun-glasses.png'
import CardPlan from '../settings/my-account/subscription-plan/CardPlan';



const { updateUserSubscription } = userAction

const appendSubscriptionDetails = (subscriptionDetail, item) =>{
  if(subscriptionDetail.code === item.membershipLevelCode){
    return {
      ...subscriptionDetail,
      membership: [
        ...subscriptionDetail.membership,
        item
      ]
    }      
  }
  return subscriptionDetail
}

const TabPanel = (props) => {
  const { children, value, index } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    key: index
  };
}

const Subscription = (props) => {
  const { userSubscription, updateUserSubscription, lookUpSubscriptionPlan, userId } = props;

  let subscriptionDetails = [
    {
      code: 'REG',
      subscription: 'Regular',
      description: 'Build your S.O. Search profile, define who you are looking for and let the world’s leading Personal Relationship Management (PRM) tool assist you with finding that “Significant Other” friend or mate you are searching for.',
      membership: []
    },
    {
      code: 'PRV',
      subscription: 'Privacy',
      description: 'Your S.O. Search profile is visible to all users, but your pictures remain hidden until you initiate a Chat conversation with another user. Only then are your pictures revealed to that user. Privacy level users can switch between Regular and Privacy levels at any time.',
      membership: []
    },
    {
      code: 'ELT',
      subscription: 'Elite',
      description: 'Your S.O Search profile is hidden to all but Elite Membership users, including while using Near Me. Elite Members can see your profile, but your pictures remain hidden until you initiate a Chat conversation with another Elite Member. Elite Member users are identified with a Diamond next to their UserName. Elite Members can also view and communicate with Regular and Privacy Members users. Elite members can change their viewable status by switching between Regular, Privacy and Elite levels at anytime.',
      membership: []
    }
  ];

  const [value, setValue] = useState(0);
  const [planValue, setPlanValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [openToast, setOpenToast] = useState(false);
  const [changeSubscriptionConfirm, setChangeSubscriptionConfirm] = useState(false);
  const classes = useStyles();
  const todayDate = moment().format('MMMM D, YYYY');
  const trialEndSubscription = moment.unix(USER_SUBSCRIPTION_TRIAL_END).format('MMMM D, YYYY');
  const endDate = moment.unix(USER_SUBSCRIPTION_TRIAL_END);
  const [selectedSubscription, setSelectedSubscription] = useState({})
  const [confirmLoading, setConfirmLoading] = useState(false)
  const history = useHistory();
  const [isFreeTrialAvailable, setIsFreeTrialAvailable] = useState(false)
  const [applyAnimation, setApplyAnimation] = useState(true)

  const [paymentModalDetails, setPaymentModalDetails] = useState({})
  const isPaymentModalOpen = Object.keys(paymentModalDetails).length > 0 && paymentModalDetails.constructor === Object
  const [confirmUrl, setConfirmUrl] = useState('')
  const [status, setStatus] = useState('none')

  let freeTrialData = {}

  const currentSubscription = subscriptionDetails.filter((item) => item.code === userSubscription.membershipLevelCode)[0]
  // Temporarily filtered the 2 months free trial
  let filteredSubscriptionPlan =  lookUpSubscriptionPlan.filter((subscriptionPlan) => subscriptionPlan.subscriptionPlanId !== USER_SUBSCRIPTION_TRIAL_ID )
  filteredSubscriptionPlan.map(item => {
    let updatedSubscriptionDetails = subscriptionDetails.map(subscriptionDetail => {
    if(item.subscriptionPlanId === USER_SUBSCRIPTION_TRIAL_ID){
        freeTrialData = item
        return subscriptionDetail
      }
      return appendSubscriptionDetails(subscriptionDetail, item)
    });
    subscriptionDetails = updatedSubscriptionDetails;
  });

  useEffect(() => {
    userRequest.getDetail({
      user_id: userId
    }).then(response => {
      updateUserSubscription(response.data.userDetail.userSubscription)
      if (response.data.userDetail.userSubscription.status === STATUS_ACTIVE) {
        history.push({ pathname: '/home' })
      }
    }).catch(error => {
      handleHttpError({
        error,
        request: 'userRequest.getDetail::src/views/subscription'
      })
    });

    userRequest
      .checkFreeTrial(userId)
      .then(({ data }) => {
        if(data?.code === '200'){
          setIsFreeTrialAvailable(true)
        } else {
          setIsFreeTrialAvailable(false)
        }
        setTimeout(()=>{ setApplyAnimation(false) }, 1000)
      })
      .catch((e) => {
        console.log(e, " :error");
        setApplyAnimation(false)
        setIsFreeTrialAvailable(false)
      });
  }, [])

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };



  const handleSubscriptionClick = (stripeProductId, subscriptionPlanId, membershipDetail) => {
    setStatus('none')
    setSelectedSubscription({
      stripeProductId, subscriptionPlanId, membershipDetail
    })

    if (userSubscription.status === STATUS_ACTIVE) {
      setChangeSubscriptionConfirm(true)
    } else {
      getSubscriptionDetails(stripeProductId, subscriptionPlanId, membershipDetail)
    }

  }

  const handleConfirm = async () => {
    getSubscriptionDetails(selectedSubscription.stripeProductId, selectedSubscription.subscriptionPlanId, selectedSubscription.membershipDetail)
  }

  const getSubscriptionDetails = async (stripeProductId, subscriptionPlanId, membershipDetail) => {

    const priceId = stripeProductId;
    const userId = props.userId ? props.userId : '';
    const email = props.email ? props.email : '';
    membershipDetail.subscriptionPlanId === USER_SUBSCRIPTION_REGULAR_ID && setStatus('validating')
    try {
      // temporarily removed free trial
      // if (subscriptionPlanId == USER_SUBSCRIPTION_TRIAL_ID) {
      //   const claimFreeTrialResponse = await userRequest.claimFreeTrial(userId).catch(error => { 
      //     console.log('error: ', error)
      //   })
      //   const subscriptionResponse = await userRequest.updateSubscription({
      //     subscription_plan_id: subscriptionPlanId,
      //     subscription_plan_end: USER_SUBSCRIPTION_TRIAL_END,
      //     viewability_status: USER_SUBSCRIPTION_REGULAR,
      //     status: STATUS_ACTIVE
      //   },
      //     userId
      //   ).catch(error => {
      //     handleHttpError({
      //       error,
      //       request: 'userRequest.updateSubscription::src/views/subscription'
      //     })
      //   });
      //   if (subscriptionResponse?.data?.code ==  HTTP_STATUS._200) {
      //     const userDetailsResponse = await userRequest.getDetail({
      //       user_id: userId
      //     }).catch(error => {
      //       handleHttpError({
      //         error,
      //         request: 'userRequest.getDetail::src/views/subscription'
      //       })
      //     });
      //     const subscriptionDetails = userDetailsResponse?.data?.userDetail?.userSubscription;
      //     await updateUserSubscription(subscriptionDetails)

      //     history.push({
      //       pathname: '/home',
      //       search: '?success=true&subscribedToMembership=trial'
      //     })

      //   }
      // } else {
      // setPaymentModalDetails({
      //   userId,
      //   email,
      //   priceId,
      //   subscriptionPlanId,
      //   membershipDetail
      // })
      // setChangeSubscriptionConfirm(false)
      // }

      setPaymentModalDetails({
        userId,
        email,
        priceId,
        subscriptionPlanId,
        membershipDetail
      })
      setChangeSubscriptionConfirm(false)
    } catch (e) {
      console.log('billing error: ', e)
    }

  }

  const renderFreeTrialCard = () => {
    if (userSubscription.status === STATUS_INACTIVE && userSubscription.subscriptionPlanId !== USER_SUBSCRIPTION_TRIAL_ID){
      return <React.Fragment />
    }
    if (!isFreeTrialAvailable) {
      return <React.Fragment />
    }
    return (<Box
      className={`${classes.cardPlanContainer} ${
        applyAnimation && classes.fade
      }`}
    >
      <CardPlan
        membershipDetail={freeTrialData}
        userSubscription={userSubscription}
        handleSubscriptionClick={handleSubscriptionClick}
        freeTrial
      />
    </Box>)
  };


  const renderSubscriptionPlanList = () => {
    return subscriptionDetails.map((plan, index) => {
      return (
        <TabPanel value={value} index={index} key={index}>
          {plan.code === "PRV" && (
            <img src={SunGlasses} className={`${classes.planIcon} ${classes.quickFade}`} />
          )}
          {plan.code === "ELT" && (
            <img src={DiamondIcon} className={`${classes.planIcon} ${classes.quickFade}`} />
          )}
          {value === 0 && (
            renderFreeTrialCard()
          )}
          <Typography color="textPrimary">{plan.description}</Typography>
          <Box className={classes.cardPlanContainer}>
            {plan.membership.map((membership, subIndex) => {
              return (
                <CardPlan
                  membershipDetail={membership}
                  key={subIndex}
                  userSubscription={userSubscription}
                  handleSubscriptionClick={handleSubscriptionClick}
                />
              );
            })}
          </Box>
        </TabPanel>
      );
    });
  };

  return (
    <Container className={classes.mainContainer}>
      {
        isPaymentModalOpen && (
          <PaymentModal
            isPaymentModalOpen={isPaymentModalOpen}
            paymentModalDetails={paymentModalDetails}
            setPaymentModalDetails={setPaymentModalDetails}
            confirmUrl={confirmUrl}
            setConfirmUrl={setConfirmUrl}
            status={status}
            setStatus={setStatus}
          />
        )
      }
      {
        confirmUrl !== '' && (
          <ConfirmModal
            confirmUrl={confirmUrl}
            setConfirmUrl={setConfirmUrl}
            status={status}
            setStatus={setStatus}
          />
        )
      }
    <Box className={classes.container}>
      <Box>
        <Typography variant="h5">Select Subscription Plan</Typography>
        <Typography>   </Typography>
      </Box>
      <Typography color="textPrimary">Select a plan that's right for you</Typography>
      <Box>
        <AppBar position="static" className={classes.subPlanTabs}>
          <Tabs centered value={value} onChange={handleChange}>
            <Tab label="Regular" {...a11yProps(0)} />
            <Tab label="Privacy" {...a11yProps(1)} />
            <Tab label="Elite" {...a11yProps(2)} />
          </Tabs>
        </AppBar>
        {
          renderSubscriptionPlanList()
        }
      </Box>
      {
          status === 'done' && <Box>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={`${classes.submit} ${classes.gap}`}
              onClick={() => { history.push({ pathname: '/home' }) }}
            >

              Go to Home

            </Button>
          </Box>
        }
      {currentSubscription && <Modal
        open={changeSubscriptionConfirm}
        onClose={() => { setChangeSubscriptionConfirm(false) }}
        className={classes.unsubscribeModal}
      >
        <Card className={classes.modalCard}>
          <Typography variant="h5" className={`text-bold text-center`}>
            Change My Subscription
          </Typography>

          <Typography variant="subtitle2" className={`text-center`} color="textPrimary">
            You have an active {currentSubscription.subscription} Subscription will end on {moment.unix(currentSubscription.subscriptionPlanEnd).format('MMMM D, YYYY')}. Clicking Yes will cancel your current plan. Are you sure you want to continue?
          </Typography>

          <Box className={classes.modalBtnContainer}>
            <Button
              type="submit"
              variant="outlined"
              color="primary"
              className={classes.submit}
              onClick={() => { setChangeSubscriptionConfirm(false) }}

            >
              No
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => { handleConfirm() }}

            >
              Yes
            </Button>
          </Box>
        </Card>
      </Modal>}
      <SnackBar
        severity={ALERT_SEVERITY_SUCCESS}
        message={TOAST_SUBSCRIPTION_PLAN_CHANGED}
        open={openToast}
        setOpen={setOpenToast}
      />
    </Box>
    </Container>
  )
} 

/////////////////////////////
// mapStateToProps()
/////////////////////////////
const mapStateToProps = (state) => {
  console.log('routers/index.js:mapStateToProps(state)');
  console.log(state);
  return {
    userId: state.Session.user.user.userId,
    userName: state.Session.user.user.userName,
    email: state.Session.user.user.email,
    userSubscription: state.User.userSubscription,
    lookUpSubscriptionPlan: state.Lookup.lookUpSubscriptionPlan,
  };
}
export default connect(mapStateToProps, {
  updateUserSubscription
})(Subscription);
