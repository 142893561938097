import { makeStyles } from '@material-ui/core/styles';
import colors from '../../utils/colors';

const useStyles = makeStyles(theme => ({

	card: {
		borderRadius: '10px',
		minWidth: '100px'
	},

	clickableCard: {
		cursor : 'pointer'
	},

	cardContent: {
		display: 'flex',
		position: 'relative',
		flexDirection: 'column',
		alignItems: 'center',
		textAlign: 'center',
		padding: 0,
		marginTop: '1em',
		'& p': {
			margin: 0,
			color: colors.PRIMARY,
		},
		'& small': {
			fontSize: '12px',
			minHeight: '30px'
		},
		'& span, .ctrPreloader': {
			position: 'absolute',
			fontSize: '28px',
			fontWeight: 'bold',
			left: '50%',
			transform: 'translate(-50%, 0)',
			bottom: '35%'
		}
	},

	iconWrapper: {
		position: 'relative',
		margin: '1em'
	},

	viewedMeIcon: {
		fontSize: '6em',
		display: 'flex',
		color: colors.DASHBOARD_VIEWED_ME,
	},

	calendarInvitesIcon: {
		fontSize: '6em',
		display: 'flex',
		color: colors.DASHBOARD_CALENDAR_INVITES,
	},

	heartedMeIcon: {
		fontSize: '6em',
		display: 'flex',
		color: colors.DASHBOARD_HEARTED_ME
	},

	completeProfileProgress: {
		color: colors.DASHBOARD_PROFILE_PROGRESS
	},

	completeProfileIdeal: {
		color: colors.DASHBOARD_PROFILE_IDEAL
	},

	progressOffset: {
		color: colors.PRIMARY_TEXT,
		position: 'absolute',
		left: 0
	},

	textFit: {
		width: '60%'
	},

	marginLR: {
		margin: '0 55px'
	}
}));

export default useStyles;
