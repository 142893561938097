import React, { useState, useEffect,
                useRef }                 from 'react';
import { useHistory, useLocation }       from 'react-router-dom';
import { Box, Button, Card, CardMedia, 
         ClickAwayListener, Container,
         Grow, MenuItem, MenuList, 
         Modal, Paper, Popper, 
         Tab, Tabs, TextField, 
         Typography, Avatar,
          IconButton, Grid, Link, 
          CircularProgress }              from '@material-ui/core';
import { connect }                        from 'react-redux';

import Rating                             from '@material-ui/lab/Rating';
import StarBorderIcon                     from '@material-ui/icons/StarBorder';
import MoreHorizIcon                      from '@material-ui/icons/MoreHoriz';
import StarIcon                           from '@material-ui/icons/Star';
import ChevronLeftIcon                    from '@material-ui/icons/ChevronLeft';
import { PersonSearch }                   from '@material-ui/icons';
import CloseIcon                          from '@material-ui/icons/Close';
import LockIcon                           from '@material-ui/icons/Lock';

import useStyles                          from './style';
import Calendar                           from './calendar';
import Communicate                        from './communicate';
import History                            from './history';
import Notes                              from './notes';
import Photos                             from './photos';
import Profile                            from './profile';

import Rate                               from '../../components/rating';
import Heart                              from '../../components/heart';
import Shortlist                          from '../../components/shortlist';
import colors                             from '../../utils/colors';

import calendarAction                     from '../../redux/calendar/action';
import connectionAction                   from '../../redux/connection/action';
import messageAction                      from '../../redux/message/action';
import lookupAction                       from '../../redux/lookup/action';

import Icon                               from '@mdi/react';
import { mdiSunglasses }                  from '@mdi/js';
import DiamondIcon                        from '../../assets/images/diamond.png';
import {
  ALERT_SEVERITY_ERROR,
  ALERT_SEVERITY_INFO,
  ALERT_SEVERITY_SUCCESS,
  ALERT_SEVERITY_WARINIG,
  CONNECTION_ACTION_ACCEPT,
  CONNECTION_ACTION_IGNORE,
  CONNECTION_ACTION_REMOVE,
  CONNECTION_ACTION_SEND,
  CONNECTION_ACTION_UNLIKE,
  CONNECTION_TYPE_MESSAGE,
  CONNECTION_TYPE_PHONE_CALL,
  CONNECTION_TYPE_VIDEO_CALL,
  HEART_ACCEPTED,
  HEART_SENT,
  HTTP_STATUS,
  LOOK_UP_BODY_TYPE,
  LOOK_UP_HAIR_COLOR,
  LOOK_UP_HEIGHT,
  LOOK_UP_KID_SITUATION,
  LOOK_UP_RELATIONSHIP_STATUS,
  LOOK_UP_RELATIONSHIP_TYPE,
  SHORT_LIST_YES,
  STATUS_ACTIVE,
  STATUS_BLOCKED,
  STATUS_INACTIVE,
  STATUS_RECEIVED,
  STATUS_SENT,
  TOAST_AXIOS_REQUEST_ERROR, 
  TOAST_SHORTLIST_ADDED,
  TOAST_SHORTLIST_ADDING,
  TOAST_SHORTLIST_REMOVED,
  TOAST_SHORTLIST_REMOVING,
  TOAST_USER_BLOCKED, 
  TOAST_USER_LIKED, 
  TOAST_USER_NOTE_ADDED,
  TOAST_USER_RATED,
  TOAST_USER_UNLIKED,
  USER_SUBSCRIPTION_ELITE,
  USER_SUBSCRIPTION_PRIVACY
} from '../../utils/constants'

import { connectionRequest, notificationRequest } from '../../service/requests';
import { notificationContent } from '../../utils/notificationcontent';
import { getAddress, handleHttpError, urlParamToJSON } from '../../utils/helper';

const { addCalendarEvent, deleteMeetup, getCalendarEvents, updateMeetup } = calendarAction;
const { addConversation, getAllMessages, updateMessageUserConnection, updateTwilio, updateTwilioClient, seenMessage, updateMessageParticipant } = messageAction;
const { getHistory, removeUserConnection, setUserConnection, viewProfile } = connectionAction;
const { getLookUpFields } = lookupAction;

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
}

const urlTabs = [
  'profile',
  'photos',
  'connect',
  'history',
  'notes',
  'calendar',
]

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

const CenteredTabs = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const query = useQuery().toString();
  console.log('query : ', query);

  const { tabs } = props;

  const initialValue = urlTabs.findIndex(tab => `tab=${tab}` == query);

  console.log('initialValue : ', initialValue);
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    if (query === '' || !urlTabs.find(tab => `tab=${tab}` == query)) {
      setValue(0);
      history.push(`${window.location.pathname}?tab=profile`);
    }
  }, []);

  useEffect(() => {
    const paramObj = urlParamToJSON(query);
    console.log(paramObj, 'paramObj');
    const paramKeys = Object.keys(paramObj);
    if (!paramKeys.includes('tab')) return;

    const paramIndex = urlTabs.indexOf(paramObj.tab);
    if (value !== paramIndex) {
      setValue(paramIndex);
    }
  }, [history.location.search])

  const handleChange = (event, newValue) => {
  
    let tab = 'profile';

    switch (newValue) {
      case 0:
        tab = 'profile';
        break;
      case 1:
        tab = 'photos';
        break;
      case 2:
        tab = 'connect';
        break;
      case 3:
        tab = 'history';
        break;
      case 4:
        tab = 'notes';
        break;
      case 5:
        tab = 'calendar';
        break;
    }

    history.push(`${window.location.pathname}?tab=${tab}`);
    setValue(newValue);
  };

  return (
    <Box>
      <Tabs
        value={value}
        onChange={handleChange}
        // indicatorColor='primary'
        textColor='primary'
        variant='scrollable'
        scrollButtons='auto'
        aria-label='scrollable auto tabs example'
      >
        {
          tabs.map((tab, index) => (
            <Tab 
              key={index} 
              label={
                <Typography variant='h6'>
                  {tab.label}
                </Typography>
              }
              style={{ backgroundColor : (value === index) ? colors.WHITE : colors.PRIMARY }} 
              className={classes.tab}
              classes={{
                textColorPrimary : classes.inactiveTabColor,
                wrapper: classes.tabWrapper
              }}
            />
          ))
        }
      </Tabs>
      <Box bgcolor='white'>
        {
          tabs.map((tab, index) => (
            <TabPanel key={index} value={value} index={tab.id}>
              {tab.component}
            </TabPanel>
          ))
        }
      </Box>
    </Box>
  );
}

const UserProfile = (props) => {
  console.log('UserProfile()');
  
  const classes = useStyles();
  const anchorRef = useRef(null);
  const history = useHistory();
  
  const { getLookUpFields, removeUserConnection, setUserConnection, showAlert, user, userConnection, viewProfile, userId } = props;
  
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const [state, setState] = useState({
    rate: userConnection.rating,
    isRateLoading: false,
    isLikeLoading: false,
    isModalOpen : false,
  });
  const [isShortlistToggleLoading, setIsShortlistToggleLoading] = useState(false);
  const { isModalOpen, rate } = state;
  const isUserBlocked = userConnection.status === STATUS_BLOCKED;
  const arePhotosPrivate = (userConnection.userSubscription.viewabilityStatus == USER_SUBSCRIPTION_PRIVACY || userConnection.userSubscription.viewabilityStatus == USER_SUBSCRIPTION_ELITE) && userConnection.message != STATUS_ACTIVE;

  useEffect(() => {
    console.log('UserProfile.useEffect()');

    viewProfile({
      userId,
      connectionUserId : userConnection.user.userId
    });
    
    if (userConnection.userField.userPreference.relationshipStatus) {
      getLookUpFields(LOOK_UP_RELATIONSHIP_STATUS);
    }

    if (userConnection.userField.userPreference.relationshipType) {
      getLookUpFields(LOOK_UP_RELATIONSHIP_TYPE);
    }
    
    if (userConnection.userField.userPreference.hairColor) {
      getLookUpFields(LOOK_UP_HAIR_COLOR);
    }

    if (userConnection.userField.userPreference.bodyType) {
      getLookUpFields(LOOK_UP_BODY_TYPE);
    }

    if (userConnection.userField.userPreference.kidSituation) {
      getLookUpFields(LOOK_UP_KID_SITUATION);
    }
     

  }, []);

  const toggleRating = () => {
    console.log(toggleRating);
    setState({
      ...state,
      isModalOpen : !state.isModalOpen
    })
  }

  const rateUser = () => {
    setState({
      ...state,
      isRateLoading: true
    });
    connectionRequest.rateUser(
      {
        user_id   : userId,
        who_added : userId,
        rating    : rate
      },
      userConnection.user.userId
    ).then(response => {
      console.log('response : ', response);

      if (response?.data?.status?.code == HTTP_STATUS._200) {
        toggleRating();
        showAlert(ALERT_SEVERITY_SUCCESS, TOAST_USER_RATED(userConnection.user.userName));
        setUserConnection(response.data.connection);

        // setState(prevState => ({
        //   isSaving	: !prevState.isSaving
        // }));
      }
    }).catch(error => {
      handleHttpError({
        error,
        request: "connectionRequest.rateUser::src/components/userprofile"
      })
    }).finally(() => {
      setState({
        ...state,
        isRateLoading: false
      });
      toggleRating();
    })
  }

  const likeUser = () => {
    console.log('UserProfile.likeUser()');
    setState({
      ...state,
      isLikeLoading: true
    });
    let likeNotification = {};
    let connectionAction;
    let toastMessage;

    if (userConnection.heart == STATUS_ACTIVE) {
      connectionAction   = CONNECTION_ACTION_UNLIKE;
      toastMessage       = TOAST_USER_UNLIKED(userConnection.user.userName);
    } else if (userConnection.heart == STATUS_SENT) {
      connectionAction   = CONNECTION_ACTION_REMOVE;
      toastMessage       = TOAST_USER_UNLIKED(userConnection.user.userName);
    } else if (userConnection.heart == STATUS_RECEIVED) {
      connectionAction   = CONNECTION_ACTION_ACCEPT;
      toastMessage       = TOAST_USER_LIKED(userConnection.user.userName);
      likeNotification   = notificationContent(user.userName)[HEART_ACCEPTED];
    } else {
      connectionAction   = CONNECTION_ACTION_SEND;
      toastMessage       = TOAST_USER_LIKED(userConnection.user.userName);
      likeNotification   = notificationContent(user.userName)[HEART_SENT];
    }

    const apiPath = connectionAction+ '/' + userConnection.user.userId;

    connectionRequest.likeUser({
      user_id   : user.userId,
      who_added : user.userId,
    }, apiPath).then(response => {
      console.log('response : ', response);

      if (response?.data?.status?.code == HTTP_STATUS._200) {

        setUserConnection(response.data.connection);

        if (connectionAction == CONNECTION_ACTION_ACCEPT || connectionAction == CONNECTION_ACTION_SEND) {
          notificationRequest.sendNotification({
            item_id     : response.data.connection.connectionId,
            sender_id		: user.userId,
            receiver_id : userConnection.user.userId,
            notif_type  : likeNotification.notifType,
            title				: likeNotification.notifTitle,
            body   			: likeNotification.notifBody
          }).then(response => {
            console.log('response : ', response);
          }).catch(error => {
            handleHttpError({
              error,
              request: "notificationRequest.sendNotification::src/components/userprofile"
            })
          });
        }

        showAlert(ALERT_SEVERITY_SUCCESS, toastMessage);
      }
    }).catch(error => {
      handleHttpError({
        error,
        request: "connectionRequest.likeUser::src/components/userprofile"
      })
    }).finally(() => {
      setState({
        ...state,
        isLikeLoading: false
      });
    })
  }

  const toggleShortlist = () => {
    console.log('UserProfile.toggleShortlist()');

    if (userConnection.shortList === SHORT_LIST_YES) {

      showAlert(ALERT_SEVERITY_INFO, TOAST_SHORTLIST_REMOVING);
      setIsShortlistToggleLoading(isPrevLoading => !isPrevLoading);
      connectionRequest.removeShortlist({
        user_id   : userId,
        who_added : userId
      }, 
      userConnection.user.userId
      ).then(response => {
        console.log('response : ', response);
  
        if (response?.data?.status?.code == HTTP_STATUS._200) {
          setUserConnection(response.data.connection);
          showAlert(ALERT_SEVERITY_SUCCESS, TOAST_SHORTLIST_REMOVED(userConnection.user.userName));
        }
      }).catch(error => {
        handleHttpError({
          error,
          request: "connectionRequest.removeShortlist::src/component/useprofile"
        })
      }).finally(() => {
        setIsShortlistToggleLoading(isPrevLoading => !isPrevLoading);
      });
    } else {

      showAlert(ALERT_SEVERITY_INFO, TOAST_SHORTLIST_ADDING);
      setIsShortlistToggleLoading(isPrevLoading => !isPrevLoading);
      connectionRequest.addToShortlist(
        {
          user_id   : userId,
          who_added : userId
        },
        userConnection.user.userId
      ).then(response => {
        console.log('response : ', response);
  
        if (response?.data?.status?.code == HTTP_STATUS._200) {
          setUserConnection(response.data.connection);
          showAlert(ALERT_SEVERITY_SUCCESS, TOAST_SHORTLIST_ADDED(userConnection.user.userName));  
        } else if (response?.data?.status?.code == HTTP_STATUS._502) {
          showAlert(ALERT_SEVERITY_ERROR, response?.data?.status?.description);
        }
      }).catch(error => {
        handleHttpError({
          error,
          request: "connectionRequest.addToShortlist::src/component/useprofile"
        })
      }).finally(() => {
        setIsShortlistToggleLoading(isPrevLoading => !isPrevLoading);
      });
    }

  }

  const handleToggle = () => {
    setIsPopperOpen(prevOpen => !prevOpen);
  }

  const blockUser = () => {
		connectionRequest.blockUser(
      {
        user_id   : userId,
        who_added : userId
      },
      userConnection.user.userId
    ).then(response => {
      console.log('response : ', response);

      if (response?.data?.status?.code == HTTP_STATUS._200) {
        removeUserConnection(userConnection.user.userId);
        setUserConnection(response.data.connection);
        showAlert(ALERT_SEVERITY_SUCCESS, TOAST_USER_BLOCKED(userConnection.user.userName));
        history.push('/home');
        window.location.reload();
       
      }
    }).catch(error => {
      handleHttpError({
        error,
        request: 'connectionRequest.blockUser::src/components/userprofile'
      })
    });
  }

  const goBack = () => {
    history.goBack();
  } 
  const preventDragHandler = (e) => {
    e.preventDefault();
  }
  return (
    <>
 
    <Container maxWidth='xl' className={classes.profileContainer}> 
    <Button  onClick={goBack} variant='contained' color='primary' className={classes.backLinkButton}> <ChevronLeftIcon /> Back</Button>

    <Grid container spacing={2}>
      <Grid item md={12} lg={8} xl={5}>

        <Box mb={2} pd={12} boxShadow={2} className={`${classes.box} ${isUserBlocked && classes.blockedBox}`}>
            <Box className={classes.profileSection}>
						{/* <Typography color='black'>{`\nPhotos will be available if you and ${user.userName} are connected via chat.`}</Typography> */}
              <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row'}}>
                {
                  (userConnection?.mediaGallery?.length === 0 && arePhotosPrivate) && 
                  <Box position='absolute' width='37%' zIndex={1} display='flex' alignItems='center' justifyContent='center' flexDirection='column' py={5}>
                  <LockIcon color='black' className={classes.lockIcon} />
                    <Typography  align='center' color='black'>{`${userConnection.user.userName} prefers Privacy`} 
                    {( userConnection?.userSubscription?.viewabilityStatus == 'PRV' ) ? <Icon path={mdiSunglasses} size={1} color="black" style={{marginLeft : 2}} /> 
                    :( userConnection?.userSubscription?.viewabilityStatus == 'ELT' ) ? <img src={DiamondIcon} className={`${classes.planIcon} ${classes.quickFade}`} /> :''}.</Typography>
                    <Typography  align='center' color='black'>{`Photos will be available if you and ${userConnection.user.userName} are connected via chat.`}</Typography>
                  </Box>
                }

                <Avatar
                  onDragStart = { preventDragHandler }
                  className={`${classes.image} ${arePhotosPrivate && 'image-blur'}`}
                  onContextMenu={(e)=> e.preventDefault()}
                  src={userConnection.user.mediaProfile}
                />
              </Box>
              <Box display='flex' flexDirection='column' p={3} justifyContent={isUserBlocked ? 'flex-start' : 'flex-end'}>
                <Typography color='secondary' className={classes.title}>{`${userConnection.user.userName}`} 
                {( userConnection?.userSubscription?.viewabilityStatus == 'PRV' ) ? <Icon path={mdiSunglasses} size={1} color="black" style={{marginLeft : 2}} /> 
                :( userConnection?.userSubscription?.viewabilityStatus == 'ELT' ) ? <img src={DiamondIcon} className={`${classes.planIcon} ${classes.quickFade}`} /> :''} 
                {`, ${userConnection.user.age}`}</Typography>
                <Typography variant='subtitle1' color='secondary' className={classes.address}>{`${getAddress(userConnection.userField.address)}`}</Typography>
                {
                  !isUserBlocked &&
                  <>
                  <Button 
                    variant   = 'contained' className = {classes.addToShortlist} 
                    color     = 'secondary' onClick   = {toggleShortlist} 
                    disabled  = {isShortlistToggleLoading}
                  >
                    {userConnection.shortList === SHORT_LIST_YES ? 'Remove from' : 'Add to'} Shortlist
                  </Button>
                  <Box mt={2}>
                    <Grid container md={12} className={classes.ContainerIconButton}>
                      <Grid item lg={12} md={12} xs={12} component={IconButton} bgcolor={colors.WHITE} onClick={!state.isLikeLoading && likeUser} className={classes.heartButton}>
                          {
                            state.isLikeLoading ?
                              <CircularProgress color='textPrimary' size={24} />
                            : 
                              <Heart value={userConnection.heart} />
                          }
                        </Grid>
                      <Grid item lg={12} md={12} xs={12} component={IconButton} bgcolor={colors.WHITE} onClick={toggleRating} className={classes.iconButton}>
                        {/* <Typography variant='subtitle1' color='primary'>{userConnection.rating}</Typography> */}
                        <Rating
                          name='simple-controlled'
                          precision={1}
                          value={rate}
                          size='large'
                          emptyIcon={<StarBorderIcon fontSize='inherit' />}
                          readOnly={true}
                          />
                      </Grid>
                    </Grid>
                  </Box>
                  </>
                }
              </Box>
            </Box>
            <Box style={{ overflow: 'hidden', display: 'flex', justifyContent: 'flex-end', marginLeft:'auto'}} >
            {
              !isUserBlocked &&
              <Grid item xs={1} md={1} lg={1} className={classes.ellipsisContainer}>
                <IconButton
                  ref={anchorRef}
                  aria-controls={isPopperOpen ? 'menu-list-grow' : undefined}
                  aria-haspopup='true'
                  onClick={handleToggle}
                >
                  <MoreHorizIcon color='secondary' style={{width: 40, height: 40}}/>
                </IconButton>

                <Popper open={isPopperOpen} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                    >
                      <Paper className={classes.blockMenu}>
                        <ClickAwayListener onClickAway={handleToggle}>
                          <MenuItem onClick={blockUser}>Block user</MenuItem>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Grid>
            }
            </Box>
        </Box>
 
        <Box mb={2}>
          <Communicate {...props} userConnection={userConnection} user={user} />
        </Box>

        <Box mb={2}>
          <Profile {...props} user={userConnection.user} userField={userConnection.userField} />
        </Box>
      </Grid>

      {/* for 2nd column */}
      <Grid item xs={12} md={12} lg={4} xl={7}> 
        <Box mb={2}>
          <Photos {...props} arePhotosPrivate={arePhotosPrivate} />
        </Box>
        
        <Box mb={2}>
          <Notes {...props} showAlert={showAlert} userId={userId} note={userConnection.note} connectionUserId={userConnection.user.userId} userName={userConnection.user.userName} />
        </Box>

        <Box mb={2}>
          <History {...props} />   
        </Box>

        <Box mb={2}>
          <Calendar {...props} />       
        </Box>
      </Grid>

        
    
      
      {/* {
        !isUserBlocked
          ? <CenteredTabs
            tabs={[
              {
                id: 0,
                label: 'Profile',
                component: <Profile {...props} user={userConnection.user} userField={userConnection.userField} />
              },
              {
                id: 1,
                label: 'Photos',
                component: <Photos {...props} arePhotosPrivate={arePhotosPrivate} />
              },
              {
                id: 2,
                label: 'Connect',
                component: <Communicate {...props} userConnection={userConnection} user={user} />
              },
              {
                id: 3,
                label: 'History',
                component: <History {...props} />
              },
              {
                id: 4,
                label: 'Notes',
                component: <Notes {...props} showAlert={showAlert} userId={userId} note={userConnection.note} connectionUserId={userConnection.user.userId} userName={userConnection.user.userName} />
              },
              {
                id: 5,
                label: 'Calendar',
                component: <Calendar {...props} />
              }
            ]}
          />
        : <Box p={5}>
            <Typography variant='h5' color='textPrimary' align='center' className={classes.blockedUserLabel}>
              {`You've blocked ${userConnection.user.userName}.`}
              <Typography component='span' color='textPrimary' align='center' display='block'>
                You are no longer connected to this user.
              </Typography>
            </Typography>
          </Box>
      } */}
      { <Modal
      
        aria-labelledby='simple-modal-title'
        aria-describedby='simple-modal-description'
        open={isModalOpen}
        onClose={toggleRating}
      >
        <div className={classes.modal}>
          <div className={classes.paper}>
          <div className={classes.closeButtonContainer}>
					  	<IconButton className={classes.closeButtonContainer} aria-label='close' onClick={toggleRating}>
							  <CloseIcon />
					  	</IconButton>
					</div>
            <Typography variant='h5' color='textPrimary' className={`text-bold text-center`}>
              Cast Stars
            </Typography>

            <Box className={classes.ratingContainer}>
              <Rating
                name='simple-controlled'
                precision={1}
                value={rate}
                size='large'
                emptyIcon={<StarBorderIcon fontSize='inherit' />}
                onChange={(event, newValue) => {
                  setState({
                    ...state,
                    rate : newValue
                  });
                }}
              />
            </Box>
            <Box>
              <Button disabled={state.isRateLoading} fullWidth variant='contained' color='primary'className={classes.submit} onClick={rateUser}>
                {
                  state.isRateLoading ?
                    <CircularProgress color='textPrimary' size={24} />
                  : 
                    'Cast'
                }
              </Button>
            </Box>
          </div>
        </div>
      </Modal> }

    </Grid>
    </Container>
    </>
  )
}

/////////////////////////////
// mapStateToProps()
/////////////////////////////
const mapStateToProps = (state) => {
  console.log('userProfile/index.js:mapStateToProps(state)');
  console.log(state);
  return {
    user              : state.Session.user.user,
    userId            : state.Session.user.user.userId,
    userName          : state.Session.user.user.userName,
    userConnection    : state.Connection.userConnection,
    connectionHistory : state.Connection.userConnection.connectionHistory,
    eventList         : state.Calendar.eventList,
    isHistoryLoading  : state.Connection.isHistoryLoading,
    lookup            : state.Lookup,
    twilio            : state.Message.twilio,
    messages          : state.Message.messages,
    shortlist         : state.Connection.shortlist,
    likedMe           : state.Connection.recentViewsList.likedMe,
    iViewed           : state.Connection.recentViewsList.iViewed,
    viewedMe          : state.Connection.recentViewsList.viewedMe,
  };
}

export default connect(mapStateToProps, { addCalendarEvent, addConversation, deleteMeetup, getCalendarEvents, updateMeetup, getHistory, getLookUpFields, removeUserConnection, setUserConnection, getAllMessages, updateMessageUserConnection, updateTwilio, updateTwilioClient, viewProfile, seenMessage, updateMessageParticipant })(
  UserProfile
)