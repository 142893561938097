import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../utils/colors';

const useStyles = makeStyles(theme => ({
  loadingBar:{
    width:"100%",
   
  },

  hidden:{
    display:"none"
  },


}));

export default useStyles;