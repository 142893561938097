import axios  from 'axios';
import { sessionService } from 'redux-react-session';
import api    from './api';
import store from '../store'


import { 
  CSRF_TOKEN,
  XML_HTTP_REQUEST 
} from '../utils/constants';

const axiosInstance = axios.create({
  baseURL : window.REACT_APP_SOS_API,
  headers : {
    'X-Requested-With': XML_HTTP_REQUEST,
    'Csrf-token'      : CSRF_TOKEN
  }
});

axiosInstance.interceptors.request.use(
  async (config) => {
    let token;
    try {
      token = await sessionService.loadSession();  
    } catch (error) {
      token = false;
    }
  
    const User = store.getState().User
    if (!token && User.token) {
      token = User
    }

    if (token) config.headers.Authorization = api.BEARER+ token.token.jwt;

    return config;
  },
  (error) => {
    console.log('error : ', error);

    return Promise.reject (error);
  }
);

const localInstance = axios.create({
  baseURL : 'http://localhost:9000/v1/',
  headers : {
    'X-Requested-With': XML_HTTP_REQUEST,
    'Csrf-token'      : CSRF_TOKEN
  }
});

localInstance.interceptors.request.use(
  async (config) => {
    let token;
    try {
      token = await sessionService.loadSession();  
    } catch (error) {
      token = false;
    }
  
    const User = store.getState().User
    if (!token && User.token) {
      token = User
    }

    if (token) config.headers.Authorization = api.BEARER+ token.token.jwt;

    return config;
  },
  (error) => {
    console.log('error : ', error);

    return Promise.reject (error);
  }
);

const stripeInstance = axios.create({
  baseURL : window.STRIPE_API,
  headers : {
    // 'Content-Type'    : 'application/x-www-form-urlencoded',
    'X-Requested-With': XML_HTTP_REQUEST,
    'Csrf-token'      : CSRF_TOKEN,
    'Authorization'   : 'Bearer sk_test_51KkwTrJ4lpxNpT7NzXXvbxxR5nEGDJ0eOAkvNGp4SDaUWMXjO124OKkCb8CVYsySudKuajZ11XVazvz7WCd5mrwB00mmnENdLh'
  }
});

export  {axiosInstance, localInstance, stripeInstance};