import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  
  historyDetail: {
    alignItems : 'center', 
    fontWeight : 'bold'
  }


}));

export default useStyles;