import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../utils/colors';

const useStyles = makeStyles(theme => ({
  box: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddintTop: theme.spacing(2),
    paddintBottom: theme.spacing(2),
  },

  box2: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },

  imageUploadContainer: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: theme.spacing(3),

    '& > .MuiBox-root': {
      alignItems: 'center',
      border: `2px dashed ${colors.TEXT_GRAY}`,
      display: 'flex',
      height: 256,
      justifyContent: 'center',
      width: 256,
    },

    '& > .MuiButton-root': {
      margin: theme.spacing(1),
    }
  },

  image: {
    alignItems: 'center',
    border: `2px solid ${colors.LIGHT_GRAY}`,
    display: 'flex',
    height: 'auto',
    justifyContent: 'center',
    maxWidth: 256,
    width: 256,
  },

  inputFile: {
    width: 0.1,
    height: 0.1,
    opacity: 0,
    overflow: 'hidden',
    position: 'absolute',
    zIndex: -1
  },

  // MODAL
  modal: {
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    width: '100%',
  },

  viewImageBox: {
    alignItems: 'center',
    display: 'flex',
    height: '50%',
    justifyContent: 'center',
    position: 'relative',
    width: '50%',

    '& > .MuiLink-root': {
      bottom: 2,
      color: colors.WHITE,
      cursor: 'pointer',
      fontSize: 12,
      textAlign: 'center',
      position: 'absolute',
    }
  },

  closeIcon: {
    color: colors.GRAY,
    position: 'absolute',
    right: 10,
    top: 10, 
    cursor : 'pointer',

    '&:hover': {
      color: colors.WHITE,
    }
  },

  uploadImageBox: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    height: 'auto',
    width: 250,

    [theme.breakpoints.up('md')]: {
      height: 'auto',
      width: 400,
    },
  },

  image: {
    backgroundSize: 'contain',
    height: '100%',
    width: '100%',
  },

  controls: {
    display: 'flex',
    flexDirection: 'row',

    '& .Mui-disabled': {
      borderColor: colors.TEXT_GRAY,
      color: colors.TEXT_GRAY,
    },
  },

  button: {
    borderColor: colors.WHITE,
    color: colors.WHITE,
    margin: theme.spacing(1),
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.1)'
    },
  },
  buttonSave: {
    backgroundColor: colors.GRADIENT_ORANGE,
    color: colors.WHITE,
    margin: theme.spacing(1),
    '&:hover': {
      backgroundColor: colors.PRIMARY,
      color: colors.WHITE,
      borderColor: colors.WHITE,
    },
  },

}));

export default useStyles;