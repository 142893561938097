import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../../utils/colors';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    '& > .MuiBox-root:first-child': {
      position: 'relative',
      textAlign: 'center',
      '& a': {
        position: 'absolute',
        left: 0,
        cursor: 'pointer'
      },
      '& > h5': {
        color: colors.PRIMARY,
      },
    },
    '& > .MuiBox-root:not(:first-child)': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },

  subPlanTabs: {
    background: colors.WHITE,
    color: colors.PRIMARY,
    borderRadius: 10,
    width: 300,
    margin: 20,
    marginBottom: 0,
    '& > .MuiTabs-root': {
      minHeight: 0
    },
    '& button': {
      minWidth: 100,
      minHeight: 0,
      '&:first-of-type': {
        borderRadius: theme.spacing(1, 0, 0, 1)
      },
      '&:last-of-type': {
        borderRadius: theme.spacing(0, 1, 1, 0)
      },
      '&.Mui-selected': {
        background: colors.GRADIENT_ORANGE,
        color: colors.WHITE,
      }
    },
    '& .MuiTabs-indicator': {
      display: 'none'
    },
    '& .MuiTab-textColorInherit': {
      opacity: '0.8'
    }
  },

  buttonSelect: {
    background: colors.PRIMARY,
    color: colors.WHITE,
    borderRadius: 40,
    alignSelf: 'center',
    minWidth: 200,
    '&:hover': {
      backgroundColor: colors.PRIMARY
    },
    '&.Mui-disabled': {
      background: colors.GRAY,
      color: colors.WHITE
    },
    marginBottom: 5
  },

  cardPlanContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },

  cardPlan: {
    borderRadius: 10,
    border: '1px solid',
    borderColor: colors.PRIMARY,
    margin: theme.spacing(2, 4),
    '& .MuiBox-root': {
      padding: theme.spacing(1, 4)
    },
    '& ul': {
      padding: theme.spacing(0, 3, 3, 3)
    },
    '& li': {
      display: 'list-item',
      listStyle: 'disc inside',
      color: colors.PRIMARY
    },
    marginTop: '30px',
  },

  trialCardPlan: {
    borderRadius: 10,
    border: '1px solid',
    borderColor: colors.PRIMARY,
    margin: theme.spacing(2, 4),
    '& .MuiBox-root': {
      padding: theme.spacing(1, 4)
    },
    '& ul': {
      padding: theme.spacing(0, 3, 3, 3)
    },
    '& li': {
      display: 'list-item',
      listStyle: 'disc inside',
      color: colors.PRIMARY
    },
    maxWidth: '422px',
    marginBottom: '30px'
  },
  
  fade: {
    animation: '$fadeInAnimation 1s forwards',
  },

  quickFade: {
    animation: '$fadeInAnimation 0.5s forwards',
  },

  cardDescription:{
    display       : 'flex',
    flexDirection : 'row',
    gap           : '5px',
    textAlign     : 'left',
    color: colors.PRIMARY,
  },
  cardDiv:{
    display       : 'flex',
    flexDirection : 'column',
    gap           : '5px',
    alignItems    : 'flex-start',
    justifyContent: 'flex-start',
    marginTop     : '10px',
    marginBottom  : '20px',
    padding       : '8px 32px',
 
  },
  paymentDetails: {
    '& .textNumerator': {
      color: colors.PRIMARY,
      fontSize: 30
    },
    '& .dollarSign': {
      fontSize: 20
    },
    '& .textSlash': {
      color: colors.PRIMARY_TEXT
    },
    '& .textMonths': {
      color: colors.PRIMARY_TEXT,
      fontSize: 20
    },
    '& hr': {
      backgroundColor: colors.PRIMARY_TEXT,
      height: 2,
    },
    '& .endDate': {
      marginTop: 10,
      marginBottom: 10
    }
  },
  unsubscribeModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  modalCard: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(4),
    '& > h5': {
      color: colors.PRIMARY
    },
    '& > h6': {
      width: '70%'
    },
    '& h5, h6': {
      margin: theme.spacing(2, 0)
    }
  },

  modalBtnContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    transform: 'translate(10px, 0)',
    '& button': {
      marginRight: 10,
      width: 200
    }
  },
  
  planIcon: {
    width: '80px',
    height: '80px',
    margin: 10
  },

  activeSubscription: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '14px'
  },

  buttonActiveSubscription: {
    background: colors.GRADIENT_ORANGE,
    color: colors.WHITE,
    borderRadius: 4,
    alignSelf: 'center',
    minWidth: 200,
    '&:hover': {
      backgroundColor: colors.PRIMARY
    },
    '&.Mui-disabled': {
      background: colors.GRAY,
      color: colors.WHITE
    },
    marginBottom: 5,
    boxShadow: theme.shadows[2],
    pointerEvents: 'none'
  },


  '@keyframes fadeInAnimation': {
    '0%': {
        opacity: '0',
    },
    '100%': {
        opacity: '1',
     }
  }


}));

export default useStyles;
