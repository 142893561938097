import { makeStyles } from '@material-ui/core/styles';
import colors from '../../utils/colors';

const useStyles = makeStyles(theme => ({
  container: {
    height: '100vh',
    
    // border: '1px solid red'
  },

  paper: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    justifyContent: 'center',

    '& > .MuiTypography-root': {
      marginBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
  },

  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  
  form: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    position: 'relative',
    margin: theme.spacing(2, 0, 2, 0),

    '& .MuiFormControl-root': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },

    '& .MuiTypography-root': {
      marginBottom: theme.spacing(0.5),
      marginTop: theme.spacing(0.5),
    },

    '& > .pincode-input-container': {
      margin: theme.spacing(6, 0, 2, 0),
      '& > .pincode-input-text': {
        border: `1px solid ${colors.GRAY} !important`,
        borderRadius: 10,
        color: colors.PRIMARY,
        fontSize: 20,
        fontWeight: 'bold'
      }
    },
  },

  textField: {
    width: 270,
    '& .MuiFormGroup-root .MuiFormControlLabel-root .MuiTypography-root': {
      color: colors.TEXT_GRAY
    },
    '& > .MuiFormHelperText-root': {
      color: 'red'
    }
  },  

  submit: {
    margin: theme.spacing(3, 0, 2),
  },

  textRegister: {
    textAlign: 'center',
    margin: '0 !important',

    '& > a': {
      color: colors.TEAL
    }
  },

  textForgot: {
    textAlign: 'center',
    margin: '0 !important',
  },

  passwordStrengthContainer: {
    position: 'absolute',
    right: 50
  },

  passwordStrength: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    '& > p, .MuiListItemText-root': {
      color: colors.TEXT_GRAY
    },
    '& > ul > li': {
      padding: 0
    },
    '& > .MuiButtonBase-root': {
      width: 120,
      borderRadius: 40,
      alignSelf: 'flex-end',
      margin: theme.spacing(5, 1)
    },
  },
  
  checkIconGreen: {
    color: colors.GREEN
  },
}));

export default useStyles;