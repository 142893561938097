import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../../utils/colors';

const useStyles = makeStyles(theme => ({

  buttonSave: {
    width: 120,
    borderRadius: 40,
    alignSelf: 'flex-end',
    marginBottom: theme.spacing(2),
  },

  // ADDRESS
  addressContainer: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(4),
    gap: '20px',
  },
  loading: {
    display: 'flex',
    alignSelf: 'center',
  },

  helperText: {
    color: 'red',
    fontSize: 12,
    marginTop: -14,
    marginLeft: 14,
    marginBottom: -5
  },

  textField: {
    width: 270,
    '& .MuiFormGroup-root .MuiFormControlLabel-root .MuiTypography-root': {
      color: colors.TEXT_GRAY
    },
    '& > .MuiFormHelperText-root': {
      color: 'red'
    }
  },

  selectBox: {
    width: '101%',
    maxWidth: '270px',
  },

}));

export default useStyles;
