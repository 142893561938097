import { makeStyles } from "@material-ui/core/styles";
import colors from "../../utils/colors";

const useStyles = makeStyles((theme) => ({
  // ChatLayout
  chatLayoutContainer: {
    backgroundColor: colors.WHITE,
    height: 600,
  },

  chatBox: {
    overflowY: "auto",
  },

  chatsLabel: {
    fontWeight: "bold",
    margin: 10,
  },

  chatItemUsername: {
    fontWeight: "bold",
    color: colors.PRIMARY_TEXT,
  },

  unseenChatItemRow: {
    color: colors.PRIMARY,
    fontWeight: "bold",
  },

  fullHeight: {
    height: "100%",
  },

  verticallyCenter: {
    display: "flex",
    justifyContent: "center",
  },

  verticallyRight: {
    display: "flex",
    justifyContent: "flex-end",
  },

  scrollContainer: {
    overflow: "hidden",
  },

  chatThread: {
    height: "100%",
    overflowY: "scroll",
    paddingLeft: 10,
    paddingRight: 10,
    padding: theme.spacing(0, 1),
  },

  messageInputContainer: {
    padding: theme.spacing(1),
  },

  messageInput: {
    alignSelf: "center",
    backgroundColor: "#F1F3F4",
    borderRadius: 100,
    display: "flex",
    maxHeight: 40,
    paddingBottom: 3,
    paddingLeft: 20,
    paddingTop: 3,
  },

  emptyStateMessage: {
    paddingTop: "20%",
  },

  tapButtonContainer: {
    padding: theme.spacing(3, 0),
    display: "flex",
    justifyContent: "center",
  },

  tapButton: {
    // "& > .MuiButton-root": {
    textTransform: "none",
    borderRadius: 20,
    width: 200,
    //},
  },
}));

export default useStyles;
