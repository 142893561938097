import React, { useEffect, useState } from 'react';
import { Box, Container, Grid, Typography } from '@material-ui/core';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import moment from 'moment';

import useStyles from './style';
import CalendarView, { EventDetails } from '../../../components/calendar';
import Empty from '../../../components/empty';
import Preloader from '../../../components/preloader';
import UserCard from '../../../components/usercard';

import calendarAction from '../../../redux/calendar/action';
import connectionAction from '../../../redux/connection/action';

import { calendarRequest } from '../../../service/requests';
import { 
  ADD_EVENT,
  ALERT_SEVERITY_ERROR, 
  ALERT_SEVERITY_SUCCESS, 
  CALENDAR_RESPONSE_GOING,
  CALENDAR_RESPONSE_NOT_GOING,
  CALENDAR_RESPONSE_PENDING,
  CARD_CALENDAR_EVENTS, 
  MSG_NO_INVITATIONS,
  NO_INVITATION_DESC,
  PRELOADER_CARD_CALENDAR, 
  TOAST_AXIOS_REQUEST_ERROR,
  TOAST_EVENT_ACCEPTED,
  TOAST_EVENT_DECLINED,
  UPDATE_EVENT,
  UPD_MODE_CALENDAR_STATUS
} from '../../../utils/constants';

import { getEventLocation, handleHttpError } from '../../../utils/helper';

import NO_INVITATIONS from '../../../assets/images/no_invitations.png';

const { addCalendarEvent, deleteMeetup, getCalendarEvents, setEventDetailsModalOpen, setEventOperation, updateMeetup, updateReceiverCalendar, updateSenderCalendar } = calendarAction;
const { getRecentViews, getShortlist }  = connectionAction;

const Calendar = (props) => {
  const { 
    eventList, 
    eventOperation,
    getCalendarEvents, 
    isEventDetailsModalOpen,
    isEventLoading, 
    selectedEventList,
    setEventDetailsModalOpen,
    setEventOperation,
    showAlert, 
    updateReceiverCalendar, 
    updateSenderCalendar,
    userId,
    userName
  } = props;
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const [markedDates, setMarkedDates] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState({});
  const [subTabIndex, setSubTabIndex] = useState(0);
  const initialEventDetails = {
    userId,
    cUserName : userName,
    user : {},
    username : '',
    eventName : '',
    dateTime : new Date,
    location : '',
    reminder : 0,
  };
  const [invitationCardYearLabels, setInvitationCardYearLabels] = useState([]);
  const [eventDetails, setEventDetails] = useState(initialEventDetails);

  useEffect(() => {
    
    if (eventList.length === 0) {
      getCalendarEvents({
        connection_user_id  : 0,
        date_start          : 0,
        date_end            : 0
      });
    }

    console.log('eventList : ', eventList);

    let events = [];
    let invitationCardYearLabels = [];

    if (eventList.length > 0) {
      eventList.forEach(eventListItem => {
        const { meetupId, meetupName, dateStart } = eventListItem.meetup;

        const eventYear = moment.unix(dateStart).format('YYYY');
            
        if ((invitationCardYearLabels.length === 0) || invitationCardYearLabels.find(invitationCardYearLabel => invitationCardYearLabel.eventYear === eventYear) === undefined) {
          invitationCardYearLabels.push({
            meetupId,
            eventYear
          });
        }
    
        (eventListItem.eventReceiver.status === CALENDAR_RESPONSE_GOING || eventListItem.eventReceiver.status === CALENDAR_RESPONSE_PENDING) && 
        events.push({
          title     : meetupName,
          start     : moment(moment.unix(dateStart)).toDate(),
          end       : moment(moment.unix(dateStart)).toDate(),
          allDay    : false,
          resource  : null,
          event     : eventListItem
        });
      })
    } 

    console.log('Events : ', events);

    setMarkedDates(events);
    setInvitationCardYearLabels(invitationCardYearLabels);

  }, [eventList, eventDetails.dateTime]);
  
  useEffect(() => {
      const pathname = location.pathname.split('/');
    
      if(pathname[2] == 'calendar-view'){
        setSubTabIndex(1);
      }else if(pathname[2] == 'invitation-view'){
        setSubTabIndex(0);
      }else{
        history.push('/calendar/invitation-view');
        setSubTabIndex(0);
      }
    }, [location.pathname])

  const handleChangeEventDetails = (eventInfo, value) => {
		setEventDetails(prevEventDetails => ({
			...prevEventDetails,
			[eventInfo] : value
		}))
  }

  const addEvent = () => {
    (eventOperation !== ADD_EVENT) && setEventOperation(ADD_EVENT);
    setEventDetailsModalOpen(true);
  }

  const handleSelectSlot = ({ start }) => {
    handleChangeEventDetails('dateTime', start, setEventDetails);
    addEvent();
  }

  const handleSelectEvent = (event) => {
    setSelectedEvent(event);
  }

  const handleEditEvent = (event) => {
    console.log('handleEditEvent() ', event);

    const eventDateTime = new Date(event.meetup.dateStart * 1000);
    const selectedEvent = {
      userId,
      cUserName : userName,
      user : event.userConnection,
      username : event.userConnection.user.userName,
      eventName : event.meetup.meetupName,
      dateTime : eventDateTime,
      location : getEventLocation(event.meetup.eventPlace, event.meetup.street, event.meetup.city, event.meetup.state, event.meetup.zipCode),
      reminder : event.eventSender.reminder,
      meetupId : event.meetup.meetupId,
      response : event.eventReceiver.status,
      isEventCreator : event.eventSender.userId === userId
    };
    
    setEventOperation(UPDATE_EVENT);
    setEventDetails(selectedEvent);
    setEventDetailsModalOpen(true);
  }

  const handleEventResponse = (eventResponse, event) => {
    const calendarSenUserId	= event.eventSender.user.userId;
    const calendarId = (userId === calendarSenUserId) ? event.eventSender.calendar.calendarId : event.eventReceiver.calendar.calendarId;

    calendarRequest.updateCalendarEvent({
      calendar_id   : calendarId,
      user_id 			: userId,
      upd_mode			: UPD_MODE_CALENDAR_STATUS,
      update_value	: eventResponse
    }).then(response => {
      console.log('response : ', response);

      if (eventResponse === CALENDAR_RESPONSE_GOING) {
        showAlert(ALERT_SEVERITY_SUCCESS, TOAST_EVENT_ACCEPTED);
      } else if (eventResponse === CALENDAR_RESPONSE_NOT_GOING) {
        showAlert(ALERT_SEVERITY_SUCCESS, TOAST_EVENT_DECLINED);
      }
      
      (userId === calendarSenUserId) ? updateSenderCalendar(response.data.calendar) : updateReceiverCalendar(response.data.calendar);
    }).catch(error => {
      handleHttpError({
        error,
        request: 'calendarRequest.updateCalendarEvent::src/views/calendar/main'
      })
    });
  }

  return (
    <Container className={`${classes.root} ${subTabIndex === 0 && classes.invitationView}`}>
      {
        subTabIndex === 0
        ? <Box 
            height={800} 
            alignItems='flex-start'
            justifyContent='flex-start'
            display='flex'
          >
            {
              (isEventLoading) ?
              <Grid 
                container 
                justify='flex-start' 
                alignItems='center' 
                spacing={3}
              >
                {
                  Array.from({ length: 9 }, () => 1).map((value, index) => {
                    return (
                      <Preloader type={PRELOADER_CARD_CALENDAR} key={index} />
                    )
                  })
                }
              </Grid>
              : (eventList.length > 0) 
                ? <Grid container spacing={3} alignItems="stretch">
                    {
                      eventList.map((event, index) => {
                        const eventYearObj = invitationCardYearLabels.find(invitationCardYearLabel => invitationCardYearLabel.meetupId === event.meetup.meetupId);
                        
                        return (
                          <>
                            {
                              eventYearObj && 
                              <Box py={3} px={1.5} width='100%'>
                                <Typography variant='h6' className='text-bold' color='primary'>{eventYearObj.eventYear}</Typography>
                              </Box>
                            }
                            <UserCard
                              key={index}
                              type={CARD_CALENDAR_EVENTS}
                              eventDetails={event}
                              visibilityStatus={event.userConnection.userSubscription.viewabilityStatus}
                              handleEdit={() => handleEditEvent(event)}
                              handleEventResponse={handleEventResponse}
                            />
                          </>
                        )
                      })
                    }
                  </Grid>
                : <Empty title={MSG_NO_INVITATIONS} description={NO_INVITATION_DESC}image={NO_INVITATIONS}/>
            }
          </Box>
        : <Box p={5}>
            <CalendarView 
              {...props}
              markedDates={markedDates}
              selectedEvent={selectedEvent || {}}
              handleSelectSlot={handleSelectSlot}
              handleSelectEvent={handleSelectEvent}
              setMarkedDates={setMarkedDates}
            />  
          </Box>
      }
      <EventDetails 
        {...props} 
        initialEventDetails={initialEventDetails}
        isEventDetailsModalOpen={isEventDetailsModalOpen} 
        setEventDetailsModalOpen={setEventDetailsModalOpen} 
        eventDetails={eventDetails} 
        setEventDetails={setEventDetails}
        handleChangeEventDetails={handleChangeEventDetails}
        mode={eventOperation}
      />
    </Container>
  );
}

/////////////////////////////
// mapStateToProps()
/////////////////////////////
const mapStateToProps = (state) => {
  console.log('calendar/index.js:mapStateToProps(state)');
  console.log(state);
  return {
    userId                  : state.Session.user.user.userId,
    userName                : state.Session.user.user.userName,
    user                    : state.Session.user.user,
    isEventDetailsModalOpen : state.Calendar.isEventDetailsModalOpen,
    eventOperation          : state.Calendar.eventOperation,
    selectedEventList       : state.Calendar.selectedEventList,
    eventList               : state.Calendar.eventList,
    isEventAdded            : state.Calendar.isEventAdded,
    isEventLoading          : state.Calendar.isEventLoading,
    userConnection          : state.Connection.userConnection,
    shortlist               : state.Connection.shortlist,
    calendarUnseenCount     : state.Notification.badgeCount.calendar,
    isShortlistLoading      : state.Connection.isShortlistLoading,
    likedMe                 : state.Connection.recentViewsList.likedMe,
    iViewed                 : state.Connection.recentViewsList.iViewed,
    viewedMe                : state.Connection.recentViewsList.viewedMe,
  };
}

export default connect(mapStateToProps, { addCalendarEvent, deleteMeetup, getCalendarEvents, getRecentViews, getShortlist, setEventDetailsModalOpen, setEventOperation, updateMeetup, updateReceiverCalendar, updateSenderCalendar })(Calendar);