import React, { useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import SnackBar from '../components/snackbar';

const PublicRoute = (props) => {
	const { authenticated, exact, component : Component, layout : Layout, path, refer, isSignedIn, user } = props;
	const [open, setAlertOpen] = useState(false);
  const [message, setAlertMessage] = useState('');
  const [severity, setAlertSeverity] = useState('success');

  const showAlert = (severity, message) => {
    setAlertOpen(true);
    setAlertMessage(message);
    setAlertSeverity(severity)
  }

	return (
		<Route
			exact={exact}
			path={path}
			render={(props) =>
				isSignedIn ? (
					<Redirect
						to={
							user && !user.lastLogin ?
								{
									// pathname: `/search`,
									// search  : `?tab=advancedsearch&searchname=My+Ideal`,
									pathname: `/search/advanced-search&searchname=My+Ideal`,
									state   : {
										subTabIndex: 1
									}
								}
							:
								{
									pathname: '/home',
									state: { from: props.location },
								}
						}
					/>
				) : refer ?
				<Redirect
					to={{
						pathname: '/registration',
						state: { from: props.location },
					}}
				/>
				: (
					<Layout>
						<Component {...props} showAlert={showAlert} />
						<SnackBar open={open} setOpen={setAlertOpen} message={message} severity={severity}/>
					</Layout>
				)
			}
		/>
	)
};

export default PublicRoute;