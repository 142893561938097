import { makeStyles } from '@material-ui/core/styles';
import colors from '../../utils/colors';

const useStyles = makeStyles(theme => ({
  
  container: {
    display: 'flex',
    height: '100%'
  },

  grid: {
    display: 'flex',
    justifyContent: 'center'
  },

  localVideo: {
    backgroundColor : colors.BLACK,
    bottom: 0,
    position: 'absolute',
    right: 0,
    '& > video': {
      height: 'auto',
      width: 300,
    },
  },

  remoteVideo: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    '& > video': {
      display: 'flex',
      height: '100%',
      width: 'auto',
    },
  },

  turnedOffRemoteVideo: {
    backgroundColor : colors.BLACK,
    width: '100%',
    '& > video': {
      display : 'none'
    },
  },

  username: {
    color : colors.WHITE
  },
  
  content: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  },

  image: {
    height: 96,
    marginBottom: theme.spacing(1),
    width: 96
  },
  
  localViewProfile: {
    height: 45,
    position: 'absolute',
    width: 45
  },

  controls: {
    bottom: 0,
    marginBottom: theme.spacing(5),
    position: 'absolute',
    zIndex: 1,
    '& > .MuiBox-root': {
      '& > .MuiIconButton-root': { 
        margin: theme.spacing(2),
      }
    },
  },

  icon: {
    fill: colors.WHITE
  },

  primaryIcon: {
    fill: colors.PRIMARY
  },

  iconButton: {
    backgroundColor: colors.PRIMARY,
  },

  iconButtonOutlined: {
    backgroundColor: colors.DARK_GRAY,
    border: `1px solid ${colors.DARK_GRAY}`
  },

  iconButtonContained: {
    backgroundColor: colors.PRIMARY,
    border: `1px solid ${colors.PRIMARY}`
  },

  iconButtonGreen: {
    backgroundColor: colors.GREEN,
    border: `1px solid ${colors.GREEN}`
  },

  iconButtonRed: {
    backgroundColor: colors.ERROR,
    border: `1px solid ${colors.ERROR}`
  },

  iconButtonGray: {
    backgroundColor: colors.TEXT_GRAY,
    border: `1px solid ${colors.TEXT_GRAY}`
  }
}));

export default useStyles;