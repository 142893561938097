const action = {

  AUTHENTICATE                    : "AUTHENTICATE",
  AUTHENTICATE_SUCCESS            : "AUTHENTICATE_SUCCESS",

  USER_SIGNIN                     : "USER_SIGNIN",

  USER_TOKEN_UPDATE               : "USER_TOKEN_UPDATE",
  TWILIO_VIDEO_TOKEN_SET          : "USER_TWILIO_VIDEO_TOKEN_UPDATE",

  USER_GET                        : "USER_GET",
  USER_UPDATE                     : "USER_UPDATE",
  USER_DELETE                     : "USER_DELETE",
  USER_FIELD_UPDATE               : "USER_FIELD_UPDATE",
  USER_FIELD_GET_ERROR            : "USER_FIELD_GET_ERROR",
  USER_CREDIT_CARD_UPDATE         : "USER_CREDIT_CARD_UPDATE",
  USER_SETTING_UPDATE             : "USER_SETTING_UPDATE",
  USER_SUBSCRIPTION_UPDATE        : "USER_SUBSCRIPTION_UPDATE",
  USER_SUBSCRIPTION_START         : "USER_SUBSCRIPTION_START",
  USER_IS_SUBSCRIBED_UPDATE       : "USER_IS_SUBSCRIBED_UPDATE",
  USER_NOTIF_SETTING_SET          : "USER_NOTIF_SETTING_SET",
  USER_BILLING_UPDATE             : 'USER_BILLING_UPDATE',
  USER_PROFILE_UPDATE             : 'USER_PROFILE_UPDATE',
  // SESSION
  UPDATE_USER_SESSION             : "UPDATE_USER_SESSION",

  ADD_USER_SETTINGS_OBJECT        : "ADD_USER_SETTINGS_OBJECT",

  authenticateUser: userToken => ({
    type    : action.AUTHENTICATE,
    payload : userToken
  }),

  signInUser: userToken => ({
    type    : action.USER_SIGNIN,
    payload : userToken
  }),

  updateSession: user => ({
    type    : action.UPDATE_USER_SESSION,
    payload : user
  }),

  updateToken: token => ({
    type    : action.USER_TOKEN_UPDATE,
    payload : token
  }),

  updateTwilioVideoToken: token => ({
    type    : action.TWILIO_VIDEO_TOKEN_SET,
    payload : token
  }),

  updateUser: user => ({
    type    : action.USER_UPDATE,
    payload : user
  }),

  updateUserField: userField => ({
    type    : action.USER_FIELD_UPDATE,
    payload : userField
  }),

  updateUserSubscription: subscription => ({
    type    : action.USER_SUBSCRIPTION_UPDATE,
    payload : subscription
  }),

  updateUserSettings: userSettings => ({
    type    : action.USER_SETTING_UPDATE,
    payload : userSettings
  }),

  startSubscription: subscription => ({
    type    : action.USER_SUBSCRIPTION_START,
    payload : subscription
  }),

  updateBillingFields: subscription => ({
    type    : action.USER_BILLING_UPDATE,
    payload : subscription
  }),

  updateUserCreditCard: userCreditCard => ({
    type    : action.USER_CREDIT_CARD_UPDATE,
    payload : userCreditCard
  }),

  updateUserSetting: userSetting => ({
    type    : action.USER_SETTING_UPDATE,
    payload : userSetting
  }),

  updateIsSubscribed: payload => ({
    type    : action.USER_IS_SUBSCRIBED_UPDATE,
    payload
  }),

  setNotifSetting: notificationSetting => ({
    type    : action.USER_NOTIF_SETTING_SET,
    payload : notificationSetting
  }),

  updateProfilePicture: payload => ({
    type    : action.USER_PROFILE_UPDATE,
    payload : payload
  }),
};

export default action;
