import { all, takeEvery }          from 'redux-saga/effects';
import { sessionService } from 'redux-react-session';
import calendarSaga     from './calendar/saga';
import connectionSaga   from './connection/saga';
import lookupSaga       from './lookup/saga';
import messageSaga      from './message/saga';
import notificationSaga from './notification/saga';
import searchSaga       from './search/saga';
import userSaga         from './user/saga';
import action           from './action';

/////////////////////////
// reqUserSignOut()
/////////////////////////
function* reqUserSignOut() {

  try {
    console.log('reqUserSignOut()');

    sessionService.deleteSession();
    sessionService.deleteUser();
  } catch (error) {
    console.log('error : ', error);
  }
}


function* reqUserClearData({ payload }) {

  try {
    console.log('reqUserClearData()');
   if( payload === 4){
    sessionService.deleteSession();
    sessionService.deleteUser();
   }
   
  } catch (error) {
    console.log('error : ', error);
  }
}

export default function* rootSaga() {
  yield all([
    calendarSaga(),
    connectionSaga(),
    lookupSaga(),
    messageSaga(),
    notificationSaga(),
    searchSaga(),
    userSaga(),
    takeEvery(action.SIGN_OUT,reqUserSignOut),
    takeEvery(action.CLEAR_USER_DATA,reqUserClearData)
  ]);
}
