import React, { useEffect } from 'react';

import useStyle from './style';
import { useHistory } from 'react-router-dom';
import logo from '../../../assets/images/logo-text-white.png'

import { 
  AppBar, Box, Button, Tab, Tabs, Toolbar, Typography, Popper, Grow, Paper, MenuList, MenuItem, 
  ClickAwayListener, Avatar, IconButton, useMediaQuery, useTheme, InputAdornment, TextField, 
  Divider, ListItemIcon, ListItemText, Collapse, ListItemSecondaryAction, Switch, Checkbox
} from '@material-ui/core';

const AccountDeletion = (props) => {
	const classes = useStyle();

  const history = useHistory();

  useEffect(() => {
    document.getElementById('root').style.height = '100%';
  }, []);

  const goToHompage = () => {
    history.push('');
  };


  return (
    <div>
      <AppBar component={Paper} elevation={0} className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <div className={classes.toolbarTitle}>
            <img src={logo} className={classes.logo} alt='S.O.Search'/>
          </div>
        </Toolbar>
      </AppBar>
      <div className={classes.textInfo}>
          <h2 className={classes.title}>
            Permanently Delete Your Account
          </h2>
          <p>
            Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. These Terms
            apply to all visitors, users and others who access or use the Service.
          </p>
          <p>
            By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms then you may not access
            the Service.
          </p>
          <h3 className={classes.title}>
            What happens if I permanently delete my account?
          </h3>
          <ul className={classes.bulletList}>
            <li>You won't be able to reactivate your account</li>
            <li>Your profile details, photos and everything else you've added will be permanently deleted. You won't be able to retrieve anything you'be added</li>
            <li>Some information, like messages you sent to other users, is soted in their device's memory cache and may still be visible to them after you delete your account.</li>
          </ul>
          <h3 className={classes.title}>
           Learn how to permanently delete your account.
          </h3>
          <p>To permanently delete your account:</p>
          <ol className={classes.numberList}>
            <li>Click your profile picture in the top right of SOS App.</li>
            <li>Select Settings & Privacy, then click Settings.</li>
            <li>If Accounts Center is at the top left of your Settings menu, you can delete your account through Accounts Center. If Accounts Center is at the bottom left of your Settings menu, you can delete your account through your Settings.</li>
          </ol>

      </div>
    </div>

  );
} 
export default AccountDeletion;