import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../../utils/colors';

const useStyles = makeStyles(theme => ({

  collapseBoxWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },

  valueText: {
    color: colors.TEXT_GRAY,
  },

  buttonSave: {
    width: 120,
    borderRadius: 40,
    alignSelf: 'flex-end',
    marginBottom: theme.spacing(2),
  },

  // PREFERENCE
  preferenceContainer: {
    marginLeft: theme.spacing(4),
    marginBottom: theme.spacing(1),

    '& .MuiTypography-root': {
      color: colors.TEXT_GRAY,
      fontSize: 14,
    }
  },

  formControl: {
    display: 'flex',
    flexDirection: 'row',

    '& > .MuiBox-root': {
      display: 'flex',
      flexDirection: 'column'
    }
  },

  radio: {
    padding: theme.spacing(0.5),
  },
}));

export default useStyles;
