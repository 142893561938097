import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { Box, CircularProgress, Button, TextField, Typography} from '@material-ui/core';

import useStyles from './style';
import { 
  UPD_MODE_USER_ABOUT, ALERT_SEVERITY_SUCCESS, TOAST_AXIOS_REQUEST_ERROR, MAX_NOTE_CHARACTERS, HTTP_STATUS} from '../../../../utils/constants';
import { userRequest} from '../../../../service/requests';
import { handleHttpError } from '../../../../utils/helper';

const AboutMe = forwardRef((props, ref) => {
    const classes = useStyles();
  
    const { isSaving, setIsSaving, setOpenedIndex, updateSession, updateUserField, user, userField, showAlert } = props;
  
    const [note, setNote] = useState(userField.about ? userField.about : '');
  
    const handleInputChange = event => {
      //event.target.value ? setIsFalse(true) : setIsFalse(true);
      setNote(event.target.value);
      
    };
  
    const saveNote = async () => {
      console.log('Note.saveNote()');
  
      setIsSaving(prevIsSaving => !prevIsSaving);
      await userRequest.updateUser({
        user_id       : user.userId,
        upd_mode      : UPD_MODE_USER_ABOUT,
        who_updated   : user.userId,
        about         : note
      }).then(response => {
        console.log('response : ', response);
  
        if (response?.data?.status?.code != HTTP_STATUS._200) {
          let { status } = response?.data;
          throw new Error(`${status?.code} ${status?.description}`);
        }
        updateUserField(response?.data?.userDetail?.userField)
        updateSession(response?.data?.userDetail);
        showAlert(ALERT_SEVERITY_SUCCESS, 'About Me has successfully updated.')
      }).catch(error => {
        handleHttpError({
          error,
          request: 'userRequest.updateUser::src/views/settings/my-profile/about-me'
        })
      }).finally(() => {
        setIsSaving(prevIsSaving => !prevIsSaving);
        setOpenedIndex(prevOpenedIndex => !prevOpenedIndex);
      })
    }
  
    useImperativeHandle(ref, () => ({
      async triggerSave() {
        await saveNote();
      }
    }));
  
    return (
      <Box textAlign="end" marginBottom={1}>
        <TextField
          multiline
          rows="10"
          placeholder="Tell your future S.O something about yourself and who you are looking for."
          value={note}
          variant="outlined"
          size="small"
          onChange={handleInputChange}
          className={classes.noteField}
          inputProps={{ maxLength: MAX_NOTE_CHARACTERS }}
        />
        <Typography variant="caption" color="primary">
          { `${note.length} / ${MAX_NOTE_CHARACTERS}` }
        </Typography>
        <br/>
        <Button disabled={isSaving} className={classes.buttonSave} onClick={saveNote} color="primary" variant="contained">
          {
            isSaving ?
              <CircularProgress size={24}/>
            : 
            'Save'
            }
        </Button>
      </Box>
    )
  })

export default AboutMe