import React, { useState, useEffect }   from 'react';
import { 
  Box, 
  Button, 
  Typography,  
   }                                    from '@material-ui/core';
import  {withRouter }                   from 'react-router-dom';
import useStyles                        from './style';
import logo_text_white                  from '../../assets/images/logo-text-white.png';
import apple_icon                       from '../../assets/images/apple-icon.png';
import playstore_icon                   from '../../assets/images/playstore-icon.png';
import landing_page_image               from '../../assets/images/landing-page-image.png';

const Referral = (props) => {
  const classes = useStyles();
  const queryParams = new URLSearchParams(window.location.search);
  const code = queryParams.get('referral_code');
 
  console.log('referral_code:',code);
 

  const nextPath = (path) => {
  props.history.push(path)
  }

  return (
    
    <div className={ classes.mainContainer }>
        <img src={ logo_text_white } className={ classes.sosearchLogo }/>
        <div className={ classes.boxContainer }>
          <img src  = { landing_page_image } className={ classes.landingPageImage }/>
          <div className={ classes.whiteBox } >
            <Typography 
              variant     = "h5" 
              className   = { [ `text-bold text-center mt-20`, classes.textLabel ] }>
              Congratulations!
            </Typography>
            <Typography 
              variant     = "h5" 
              className   = { [ `text-bold text-center`, classes.text ] }>
              A friend has referred you to S.O.Search!
            </Typography>
            <Button 
              className   = { classes.registerBtn }
              onClick     = { () => nextPath('/registration?referral_code=' + code) }>
              Register
            </Button>
            <Typography 
              variant     = "h5" 
              className   = { [ `text-bold text-center`, classes.textLabel ] }>
              OR GET THE APP NOW!
            </Typography>
            <div> 
              <Button 
                className = { classes.downloadBtn }
                onClick   = { () =>  window.location.href = 'https://www.apple.com/ph/app-store/' }>
                <img src  = {apple_icon}/>
              </Button>
              <Button 
                className = { classes.downloadBtn }
                onClick   = { () => window.location.href = "https://play.google.com/store/apps/details?id=com.sosearch" }>
                <img src  = { playstore_icon }/>
              </Button>
            </div>
         </div>
        </div>
      </div>
  )

  
}

export default withRouter(Referral);