import React, { useState } from 'react';
import { Avatar, Box, Typography } from '@material-ui/core';
import './style.css';
import useStyles from './style';
import { getImageCache } from '../../utils/helper';
import NoImageLink from '../../assets/images/user-profile.png';
import { IMAGE_INVALID_LINK } from '../../utils/constants';

const MessageBubble = ({ isMessageReceived, isTyping, message, imageSrc }) => {
	const classes = useStyles();
	const [profileImage, setProfileImage] = useState('');


	!imageSrc.includes(IMAGE_INVALID_LINK) && getImageCache(imageSrc).then((cachedImage) => {
		setProfileImage(cachedImage);
	  }).catch((e)=>{
		setProfileImage(NoImageLink)
	  });
	  
	return (
		<Box
			alignItems='center'
			display='flex'
			flexDirection='row'
			justifyContent={isMessageReceived ? 'flex-start' : 'flex-end'}
			mt={1}
		>
			{ isMessageReceived && <Avatar className={classes.avatar} src={profileImage} /> }
			{
				isTyping
					? <div className={`${classes.baseBubble} ${classes.received} tiblock`}>
							<div className={`tidot`}></div>
							<div className={`tidot`}></div>
							<div className={`tidot`}></div>
						</div>
					: <Typography
							className={`${classes.baseBubble} ${isMessageReceived ? classes.received : classes.sent}`}
							align='left'
						>
							{message}
						</Typography>
					
			}
		</Box>
	)
}

export default MessageBubble;