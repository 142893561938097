const api = {

  TIMEOUT_LIMIT               : 1000,
  BEARER                      : 'Bearer ',
  
  // Public
  USER_SIGNIN                 : 'public/signin',
  USER_ADD                    : 'public/add',
  USER_SEARCH_LU              : 'lookup/search/',
  USER_VERIFY_PHONE           : 'public/verification',
  USER_VERIFY_EMAIL           : 'public/verifyresetpasswordemail',
  USER_VERIFY_CODE            : 'public/verifypassresetcode',
  USER_PASS_RESET             : 'public/resetpassword',
  USER_CHECK_EMAIL            : 'public/email/availability',
  USER_CHECK_USERNAME         : 'public/username/availability',
  USER_RESEND_CODE            : 'public/resendcode',
  USER_SENT_OTP               : 'public/send/otp',

  // User
  USER_GET                    : 'user/',
  USER_GET_DETAIL             : 'user/detail',
  USER_DELETE_MEDIA           : 'user/media/delete',
  USER_UPDATE                 : 'user/',
  USER_REPORT_ISSUE           : 'user/reportissue',
  USER_GET_DETAIL             : 'user/detail',
  USER_GET_PRESIGNED_URL      : 'user/media/upload',
  USER_ADD_PHOTO              : 'user/media/photo',
  USER_GET_GALLERY            : 'user/media/gallery',
  USER_GET_TWILIO_CHAT_TOKEN  : 'user/token/twiliochat',
  USER_GET_TWILIO_VOICE_TOKEN : 'user/token/twiliovoice',
  USER_GET_TWILIO_VIDEO_TOKEN : 'user/token/twiliovideo',
  USER_CHANGE_PASS            : 'user/changepassword',
  USER_GET_REFERRAL_CODE      : 'user/referral/getCode',
  USER_REFERRAL_AVAILABILITY  : 'user/referralCode/availability',
  USER_CLAIM_REFERRAL_CREDIT  : 'user/referral/claim',
  USER_SUBSCRIPTION           : '/subscription',
  USER_ADD_CHAT_PARTICIPANT   : 'user/chat/participant',
  USER_GET_COUPONS            : (userId) => `user/${userId}/coupons`,
  USER_CLAIM_COUPON           : (userId, couponId) => `user/${userId}/coupons/${couponId}/claim`,
  USER_FREE_TRIAL_CLAIM       : (userId) => `user/${userId}/free_trial/claim`,
  USER_FREE_TRIAL_CHECK       : (userId) => `user/${userId}/free_trial/check`,
  USER_CHECK_REFERRAL_CODE    : 'user/referralCode/availability',
  REFERRAL_COUNTER            : (userId) => `user/${userId}/referral/counter`,

  // Search
  SEARCH_GET                  : 'search/',
  SEARCH_GET_DETAIL           : 'search/detail/',
  SEARCH_USER_BY_CRITERIA     : 'search/exec/',
  SEARCH_USER_BY_USERNAME     : 'search/username',
  SEARCH_ADD                  : 'search/',
  SEARCH_UPDATE               : 'search/',
  SEARCH_DELETE               : 'search/',
  SEARCH_SET_CRITERIA         : 'search/criteria/',

  // Connection
  CONNECTION_GET_SHORTLIST    : 'connection/shortlist',
  CONNECTION_GET_BLOCKED      : 'connection/block',
  CONNECTION_GET_NEAR_ME      : 'connection/nearme',
  CONNECTION_GET_WHO_VIEW_ME  : 'connection/whoviewme',
  CONNECTION_GET_WHO_I_VIEW   : 'connection/whoiview',
  CONNECTION_GET_WHO_LIKE_ME  : 'connection/wholikeme',
  CONNECTION_GET_CONNECTION   : 'connection/',
  CONNECTION_SET_CONNECTION   : 'connection/',
  CONNECTION_ADD_WHO_VIEW_ME  : 'connection/whoviewme/',
  CONNECTION_USERS_PROFILE    : 'connection/usersprofile/',
  CONNECTION_MARK_AS_SEEN     : 'connection/view/',

  // History
  HISTORY_GET_ITEMS           : 'history',

  // Look-up
  PROFILE_LU                  : 'lookup/profile/',
  SUBSCRIPTION_PLAN_LU        : 'lookup/subscription/',
  CREDENTIAL_LU               : 'lookup/credential/',

  // Call
  CALL_GET_LOGS               : 'call/getcall',
  CALL_START                  : 'call/startCall',
  CALL_RESPOND                : 'call',

  // Calendar  
  CALENDAR_GET_ALL_EVENTS     : 'calendar/events',
  CALENDAR_ADD_EVENT          : 'calendar/event/add',
  CALENDAR_UPDATE_MEETUP      : 'calendar/meetup/update',
  CALENDAR_UPDATE_EVENT       : 'calendar/update',

  // Near Me
  NEAR_ME_ADD                 : 'nearme/',
  NEAR_ME_CHECK_USER          : 'nearme/check',
  NEAR_ME_GET_HISTORY         : 'nearme/',
  NEAR_ME_UPDATE              : 'nearme/',
  
  // Notification
  NOTIFICATION_BIND           : 'notification/bind',
  NOTIFICATION_GET            : 'notification/',
  NOTIFICATION_SEEN           : 'notification/seen',
  NOTIFICATION_SEND           : 'notification/send',
  NOTIFICATION_MARK_ALL_READ  : 'notification/markAllRead',

  //Geocode
  GEOCODE_ADDRESS             : 'https://maps.googleapis.com/maps/api/geocode/json',

  COUNTRIES_LU                : 'lookup/countries',
  COUNTRIES_CODE_LU           : 'lookup/countries/',
  STATES_LU                   : 'lookup/states/',
  CITIES_LU                   : 'lookup/cities/',

  // USPS Zip Code Lookup
  ZIPCODE                     : 'https://production.shippingapis.com/ShippingAPITest.dll',

  //BILLING 
  BILLING_GET_LINK            : 'billing/service/paymentlink',
  BILLING_UNSUBSCRIBE         : 'billing/service/unsubscribe',
  BILLING_CLAIM_COUPON        : 'billing/service/coupon/claim', 
  BILLING_SUBSCRIBE           : 'billing/service/subscribe',     
  BILLING_PAYMENT_INTENT      : 'billing/service/paymentIntent/',
  PAYMENT_METHOD              : 'payment_methods',
  PAYMENT_INTENT              : 'payment_intents/',
  CONFIRM                     : '/confirm'

};

export default api;
