import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, CircularProgress, Modal, Typography, TextField } from '@material-ui/core';
import useStyles from './style';
import confetti from '../../../assets/images/confirmation-confetti.png';
import {
  UPD_MODE_USER_ABOUT,
  MAX_NOTE_CHARACTERS,
  ALERT_SEVERITY_WARINIG,
  TOAST_INPUT_LIMIT_REACHED,
  HTTP_STATUS
} from '../../../utils/constants'
import { userRequest } from '../../../service/requests';
import { handleHttpError } from '../../../utils/helper';

const Note = forwardRef((props, ref) => {
  const classes = useStyles();
  const { callback, updateUserField, user, next, signInUser, token, showAlert } = props;

  const [note, setNote] = useState('');
  const [isSaving, setIsSaving] = useState(false);


  useEffect(() => {
    callback(isSaving);
  }, [isSaving]);

  const handleInputChange = event => {
    console.log('onNoteChanged', event);
    setNote(event.target.value);
  };

  const saveNote = () => {
    console.log('Note.saveNote()');
    
    setIsSaving(prevIsSaving => !prevIsSaving);
    userRequest.updateUser({
      user_id       : user.userId,
      upd_mode      : UPD_MODE_USER_ABOUT,
      who_updated   : user.userId,
      about         : note
    }).then(response => {
      console.log('response : ', response);

      if (response?.data?.status?.code != HTTP_STATUS._200) {
        let { status } = response?.data;
        throw new Error(`${status?.code} ${status?.description}`);        
      }
      const userField = response?.data?.userDetail?.userField;
      setIsSaving(prevIsSaving => !prevIsSaving);
      updateUserField(userField);
      next();
    }).catch(error => {
      setIsSaving(prevIsSaving => !prevIsSaving);
      handleHttpError({
        error,
        request: 'userRequest.updateUser::src/views/registration/note'
      })
    });
  }

  useImperativeHandle(ref, () => ({
    triggerNext() {
      saveNote();
    }
  }));

  return (
    <Box className={classes.box}>
      <Box className={classes.box2}>

        <Typography variant="h5" color="primary" className={`text-bold text-center mb-20`}>
          About You
        </Typography>

        <Typography variant="h6" className={`text-bold text-center mb-20`} color="textPrimary">
          Please share something about yourself.
        </Typography>

        {/* <Typography variant="subtitle2" className={`text-center`} color="textPrimary">
          For example: Let your next significant other know a bit more about you - who you are, what you like to do; the type of person you are looking for.
        </Typography> */}

        <TextField
          multiline
          rows="10"
          placeholder="Tell your future S.O. something about yourself and who you are looking for."
          value={note}
          variant="outlined"
          onChange={handleInputChange}
          className={classes.noteField}
          inputProps={{ maxLength: MAX_NOTE_CHARACTERS }}
        />

        <Typography variant="caption" color="primary" className={classes.characterCount}>
          { `${note.length} / ${MAX_NOTE_CHARACTERS}` }
        </Typography>

      </Box>
    </Box>
  )
});

export default Note;