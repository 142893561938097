import React, { useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import SnackBar from '../components/snackbar';

const NeutralRoute = (props) => {
  const { component: Component, exact, layout: Layout, path } = props;

  const [open, setAlertOpen] = useState(false);
  const [message, setAlertMessage] = useState('');
  const [severity, setAlertSeverity] = useState('success');

  const showAlert = (severity, message) => {
    setAlertOpen(true);
    setAlertMessage(message);
    setAlertSeverity(severity)
  }
  
  return (
    <Route
      exact={exact}
      path={path}
      render={(props) => 
        <Layout>
          <Component {...props} showAlert={showAlert} />
          <SnackBar open={open} setOpen={setAlertOpen} message={message} severity={severity}/>
        </Layout>
      }
    />
  );
};

export default NeutralRoute;