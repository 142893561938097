import React, { useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Popover, Typography, Box } from '@material-ui/core';
import { Calendar, momentLocalizer, Views } from 'react-big-calendar';

import moment from 'moment';

import AccountCircleOutlinedIcon from '@material-ui/icons/AccountCircleOutlined';
import CloseOutlinedIcon from '@material-ui/icons/CloseOutlined';
import LocationOnOutlinedIcon from '@material-ui/icons/LocationOnOutlined';
import NotificationsActiveOutlinedIcon from '@material-ui/icons/NotificationsActiveOutlined';
import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';

import useStyles from './style';

import 'react-big-calendar/lib/css/react-big-calendar.css';

import { calendarRequest } from '../../../service/requests';
import { getEventLocation, getEventReminder, handleHttpError } from '../../../utils/helper';
import { ALERT_SEVERITY_ERROR, ALERT_SEVERITY_SUCCESS, CALENDAR_RESPONSE_GOING, HTTP_STATUS, STATUS_INACTIVE, TOAST_AXIOS_REQUEST_ERROR, TOAST_EVENT_DELETED } from '../../../utils/constants';
import colors from '../../../utils/colors';

const localizer = momentLocalizer(moment);

const getFormattedInvitationDateTime = (invitationDate) => {
  const dateFormat  = 'dddd MMMM D, YYYY'
  const timeFormat  = 'h:mm A'
  const rawDate   	= moment.unix(invitationDate);
  let dateTime 			= [];

  dateTime[0] 			= moment(rawDate).format(dateFormat);
  dateTime[1] 			= moment(rawDate).format(timeFormat);

  return dateTime.join(' ')
}

const CalendarView = (props) => {
	const classes = useStyles();
	const { deleteMeetup, markedDates, handleSelectSlot, handleSelectEvent, selectedEvent, setMarkedDates, showAlert, userId } = props;
	const [anchorEl, setAnchorEl] = useState(null);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const isPopupOpen = Boolean(anchorEl);
  const id = isPopupOpen ? 'event-details-popover' : undefined;

	const handleCloseEventDetailsPopup = () => {
    setAnchorEl(null);
	};
	
	const deleteEvent = () => {
		console.log('deleteEvent()');
		
		handleCloseEventDetailsPopup();
		setMarkedDates(markedDates.filter(markedDate => markedDate.event.meetup.meetupId !== selectedEvent.meetup.meetupId))
		
		calendarRequest.updateMeetup({
			meetup_id   			 : selectedEvent.meetup.meetupId,
			user_id 					 : userId,
			meetup_name				 : selectedEvent.meetup.meetupName,
			reminder					 : selectedEvent.meetup.reminder,
			details						 : selectedEvent.meetup.details,
			location					 : selectedEvent.meetup.location,
			status					 	 : STATUS_INACTIVE,
			date_start				 : selectedEvent.meetup.dateStart,
			date_end					 : selectedEvent.meetup.dateStart,
		}).then(response => {
			if (response?.data?.status?.code == HTTP_STATUS._200) {
				console.log(response);
	
				deleteMeetup(response.data.meetup);
				
				showAlert(ALERT_SEVERITY_SUCCESS, TOAST_EVENT_DELETED);
			}
			
		}).catch(error => {
			handleHttpError({
        error,
        request: "calendarRequest.updateMeetup::src/component/calendar/calendarview"
      })
		})
	}

  const closeDeleteDialog = () => {
    setDeleteDialogOpen(false);
  };

  const handleDeleteEvent = () => {
    setDeleteDialogOpen(false);
    deleteEvent();
	}
	
	const selectEvent = (event, e) => {
    setAnchorEl(e.currentTarget);
    handleSelectEvent(event.event);
  }
  
  const ColoredDateCellWrapper = (props) => {
    return React.cloneElement(React.Children.only(props.children), {
      style: {
        backgroundColor: props.event.event.eventReceiver.status === CALENDAR_RESPONSE_GOING ? colors.CALENDAR_RESPONSE_ACCEPTED : colors.CALENDAR_RESPONSE_PENDING,
      }
    })
  }

	return (
		<>
			<Calendar
        selectable
        components={{
          eventWrapper : ColoredDateCellWrapper
        }}
				localizer={localizer}
        events={markedDates}
				views={{
          month: true,
          week: true,
          day : true,
          agenda : true
        }}
				startAccessor='start'
				endAccessor='end'
				className={classes.calendar}
				onSelectSlot={handleSelectSlot}
				onSelectEvent={selectEvent}
				popup={true}
			/>
			<Popover
        id={id}
        open={isPopupOpen}
        anchorEl={anchorEl}
        onClose={handleCloseEventDetailsPopup}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{ className : classes.popupPaper }}
      >
        <Box style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '0px 0px 0px 10px'}}>
          <div className={classes.eventInfoRow}>
            <Typography variant='h5' color='textPrimary' className={classes.popupEventName}>
              {selectedEvent.meetup && selectedEvent.meetup.meetupName}
            </Typography>
          </div>
          <div className={classes.closeButtonContainer}>
            <IconButton onClick={handleCloseEventDetailsPopup}>
              <CloseOutlinedIcon />
            </IconButton>
          </div>
        </Box>
        <div className={classes.modalPaperContents}>
          <div className={classes.eventInfoRow}>
            <AccountCircleOutlinedIcon color='primary' />
            <Typography variant='subtitle1' color='textPrimary' className={classes.eventDetails}>
              {selectedEvent.userConnection && selectedEvent.userConnection.user.userName}
            </Typography>
          </div>
          <div className={classes.eventInfoRow}>
            <ScheduleOutlinedIcon color='primary' />
            <Typography variant='subtitle1' color='textPrimary' className={classes.eventDetails}>
              {selectedEvent.meetup && getFormattedInvitationDateTime(selectedEvent.meetup.dateStart)}
            </Typography>
          </div>
          <div className={classes.eventInfoRow}>
            <LocationOnOutlinedIcon color='primary' />
            <Typography variant='subtitle1' color='textPrimary' className={classes.eventDetails}>
              {selectedEvent.meetup && getEventLocation(selectedEvent.meetup.eventPlace, selectedEvent.meetup.street, selectedEvent.meetup.city, selectedEvent.meetup.state, selectedEvent.meetup.zipCode)}
            </Typography>
          </div>
          <div className={classes.eventInfoRow}>
            <NotificationsActiveOutlinedIcon color='primary' />
            <Typography variant='subtitle1' color='textPrimary' className={classes.eventDetails}>
              {selectedEvent.eventSender && getEventReminder(selectedEvent.eventSender.reminder)}
            </Typography>
          </div>
        </div>
      </Popover>
      <Dialog
        open={isDeleteDialogOpen}
        onClose={closeDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Delete Event</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this event?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteEvent} autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
		</>
	)
}

export default CalendarView;