const action = {

    USER_TUTORIAL_VIEW            : "USER_TUTORIAL_VIEW",
  
    setTutorialViewed: tutorial => ({
      type    : action.USER_TUTORIAL_VIEW,
      payload : tutorial
    })
  
  };
  
  export default action;
  