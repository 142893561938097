import { orange } from '@material-ui/core/colors';
import color from '@material-ui/core/colors/amber';
import { makeStyles } from '@material-ui/core/styles';
import colors from '../../utils/colors';

const useStyles = makeStyles(theme => ({

  // USER PROFILE
  profileContainer: {
    paddingLeft : 130,
    paddingRight : 130,
  }, 


  backLinkButton: {
    display: 'inline-flex',
    alignItems: 'center',
    margin: theme.spacing(5, 0),
    cursor: 'pointer',
  },

  tab: {
    borderTopLeftRadius : 20, 
    borderTopRightRadius : 20, 
    boxShadow: `rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px`
  },

  inactiveTabColor: {
    color : colors.WHITE
  },

  tabWrapper: {
    fontWeight : 'bold',
    textTransform: 'capitalize'
  },

  box: {
    backgroundImage: `linear-gradient(180deg, ${colors.GRADIENT_YELLOW} 30%, ${colors.GRADIENT_ORANGE} 90%)`,
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'row',
    position: 'relative',
    justifyContent: 'space-between'
    // [theme.breakpoints.down('sm')]:{
    //   height: 1000
    // }
  },

  blockedBox: {
    backgroundImage: `linear-gradient(180deg, ${colors.USER_PROFILE_BLOCKED} 30%, ${colors.USER_PROFILE_BLOCKED} 90%)`
  },
 

  image: {
    borderRadius: theme.spacing(1),
    margin: 20,
    height: 330,
    width: 310,
  },

  title: {
    fontWeight: 'bold'
  },

  starRatingIcon: {
    color: colors.YELLOW,
    width: 40,
    height: 40
  },
  blockMenu: {
    '&:hover': {
      color: colors.WHITE,
      backgroundColor: 'rgba(0, 0, 0, 0.7)'
    }
  },

  connectionContainer: {
    display: 'flex',
  },

  profileSection: {
    display: 'flex',
    flexDirection: 'row',
  },

  ContainerIconButton: {
    backgroundColor: 'white',
    borderRadius: 10,
    height: 110
  
    },
  
  rating: {
    backgroundColor: colors.WHITE,
  },

  heart: {
    backgroundColor: colors.WHITE,
    width : 35,
    height : 35,
    borderRadius : 35
  },

  heartButton: {
    marginBottom: -20
  },

  addToShortlist: {
      marginTop : 10,
    '& .MuiButton-label': {
      color: colors.PRIMARY
    },
    '&:hover': {
      backgroundColor: 'rgba(255,255,255, 0.9)'
    }
  },

  ellipsisContainer: {
    alignItems: 'flex-start',
    display: 'flex',
    justifyContent : 'flex-end',
    marginLeft: 60
   },

  // MODAL

  paper: {
    position: 'absolute',
    minWidth: 350,
    maxWidth: 350,
    top: '30%',
    left: 'calc(50% - 215px)',
    backgroundColor: colors.WHITE,
    borderRadius: 15,
    padding: theme.spacing(5, 5, 5),

    '& > .MuiTypography-root': {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },

    '& > .MuiButtonBase-root': {
      marginTop: theme.spacing(3),
    },

    '& > .MuiRating-root': {
      color : colors.YELLOW
    }
  },

  ratingContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },

  blockedUserLabel: {
    fontWeight: 'bold'
  },
  
  closeButtonContainer: {
    textAlign : 'right',
    marginBottom: -15,
    marginTop: -25,
    marginRight: -18 
  },

  planIcon: {
    width: '20px',
    height: '20px'
  },
  
  quickFade: {
    animation: '$fadeInAnimation 0.5s forwards',
  },

}));

export default useStyles;