import action from "./action";

const initState = {
  user      : {},
  userField : {
    position : {
      isLocationOn : 0
    }
  },
  userSubscription : {},
  userSettings              : {
    isReachabilityStatusOn    : 1,
    isChatAlertOn             : 1,
    isChatSoundOn             : 1,
    isVoiceCallAlertOn        : 1,
    isVoiceCallSoundOn        : 1,
    isVideoCallAlertOn        : 1,
    isVideoCallSoundOn        : 1,
    isNotifAlertOn            : 1,
    isNotifSoundOn            : 1,
    chatSoundName             : 'iphone.mp3',
    videoCallSoundName        : 'iphone.mp3',
    voiceCallSoundName        : 'iphone.mp3',
    notifSoundName            : 'iphone.mp3',
    whoAdded                  : 0,
    whenAdded                 : 0,
    whoUpdated                : 0,
    whenUpdated               : 0
  },
  applyingSubscriptionLoading : false,
  applyingSubscriptionStatus : 'none'
};

export default function userReducer(state = initState, act) {

  switch (act.type) {

    case action.ADD_USER_SETTINGS_OBJECT: {
      console.log("redux/user/reducer.js:ADD_USER_SETTINGS_OBJECT");
      console.log("state: ", state);
      let userSettings        = initState.userSettings;
      userSettings.whoAdded   = (state?.user?.userId) ? state?.user?.userId : 0;
      userSettings.whoUpdated = (state?.user?.userId) ? state?.user?.userId : 0;
      return {
        ...state,
        userSettings
      }
    }

    case action.USER_SIGNIN:
      console.log("redux/user/reducer.js:USER_SIGNIN");
      return {
        ...state,
        user  : act.payload.user,
        token : act.payload.token,
      }
    
    case action.USER_TOKEN_UPDATE:
      console.log("redux/user/reducer.js:USER_TOKEN_UPDATE");
      return {
        ...state,
        token : act.payload,
      } 

    case action.TWILIO_VIDEO_TOKEN_SET:
      console.log("redux/user/reducer.js:TWILIO_VIDEO_TOKEN_SET");
      return {
        ...state,
        twilioVideoToken : act.payload,
      } 

    case action.USER_UPDATE:
      console.log("redux/user/reducer.js:USER_UPDATE");
      return {
        ...state,
        user : act.payload,
      }

    case action.USER_FIELD_UPDATE:
      console.log("redux/user/reducer.js:USER_FIELD_UPDATE");
      return {
        ...state,
        userField : act.payload,
      }

    case action.USER_CREDIT_CARD_UPDATE:
      console.log("redux/user/reducer.js:USER_CREDIT_CARD_UPDATE");
      return {
        ...state,
        userCreditCard : act.payload,
      }

    case action.USER_SETTING_UPDATE:
      console.log("redux/user/reducer.js:USER_SETTING_UPDATE");
      return {
        ...state,
        userSetting : act.payload,
      }

    case action.USER_SUBSCRIPTION_UPDATE:
      console.log("redux/user/reducer.js:USER_SUBSCRIPTION_UPDATE");
      return {
        ...state,
        userSubscription : act.payload,
      }

    case action.USER_SETTING_UPDATE:
      console.log("redux/user/reducer.js:USER_SETTING_UPDATE");
      return {
        ...state,
        userSettings                  : act.payload,
      }
    
    case action.USER_IS_SUBSCRIBED_UPDATE:
      console.log("redux/user/reducer.js:USER_HAS_SUBSCRIPTION_UPDATE");
      return {
        ...state,
        isSubscribed : act.payload,
      }

    case action.USER_SUBSCRIPTION_START:
      console.log("redux/user/reducer.js:USER_SUBSCRIPTION_START");
      return{
        ...state,
        applyingSubscriptionLoading: true
      }
    
    case action.USER_BILLING_UPDATE:
      console.log("redux/user/reducer.js:USER_BILLING_UPDATE");
      return{
        ...state,
        ...act.payload
      }
    case action.USER_PROFILE_UPDATE:
      console.log("redux/user/reducer.js:USER_PROFILE_UPDATE");
      console.log({
        user: {
          ...state.user,
          mediaProfile: act.payload.media
        }
      })
      return{
        ...state,
        user: {
          ...state.user,
          mediaProfile: act.payload.media
        }
      }
      default:
      return state
  }
}
