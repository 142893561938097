import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../utils/colors';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    textAlign: 'center',
    '& > .MuiBox-root:first-child': {
      position: 'relative',
      textAlign: 'center',
      '& a': {
        position: 'absolute',
        left: 0,
        cursor: 'pointer'
      },
      '& > h5': {
        color: colors.PRIMARY,
      },
    },
    '& > .MuiBox-root:not(:first-child)': {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center'
    }
  },

  subPlanTabs: {
    background: colors.WHITE,
    color: colors.PRIMARY,
    borderRadius: 10,
    width: 300,
    margin: 20,
    marginBottom: 0,
    '& > .MuiTabs-root': {
      minHeight: 0
    },
    '& button': {
      minWidth: 100,
      minHeight: 0,
      '&:first-of-type': {
        borderRadius: theme.spacing(1, 0, 0, 1)
      },
      '&:last-of-type': {
        borderRadius: theme.spacing(0, 1, 1, 0)
      },
      '&.Mui-selected': {
        background: colors.GRADIENT_ORANGE,
        color: colors.WHITE,
      }
    },
    '& .MuiTabs-indicator': {
      display: 'none'
    }
  },

  cardPlanContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: '10px'
  },

  unsubscribeModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  modalCard: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(4),
    '& > h5': {
      color: colors.PRIMARY
    },
    '& > h6': {
      width: '70%'
    },
    '& h5, h6': {
      margin: theme.spacing(2, 0)
    }
  },

  modalBtnContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    transform: 'translate(10px, 0)',
    '& button': {
      marginRight: 10,
      width: 200
    }
  },

  planIcon: {
    width: '80px',
    height: '80px',
    margin: 10
  },

  buttonActiveSubscription: {
    background: colors.GRADIENT_ORANGE,
    color: colors.WHITE,
    borderRadius: 4,
    alignSelf: 'center',
    minWidth: 200,
    '&:hover': {
      backgroundColor: colors.PRIMARY
    },
    '&.Mui-disabled': {
      background: colors.GRAY,
      color: colors.WHITE
    },
    marginBottom: 5,
    boxShadow: theme.shadows[2],
    pointerEvents: 'none'
  },

  trialCardPlan: {
    borderRadius: 10,
    border: '1px solid',
    borderColor: colors.PRIMARY,
    margin: theme.spacing(2, 4),
    '& .MuiBox-root': {
      padding: theme.spacing(1, 4)
    },
    '& ul': {
      padding: theme.spacing(0, 3, 3, 3)
    },
    '& li': {
      display: 'list-item',
      listStyle: 'disc inside',
      color: colors.PRIMARY
    },
    maxWidth: '422px',
    marginBottom: '30px'
  },
  
  trialCardPlan: {
    animation: '$fadeInAnimation 1s forwards',
  },

  quickFade: {
    animation: '$fadeInAnimation 0.5s forwards',
  },

  '@keyframes fadeInAnimation': {
    '0%': {
        opacity: '0',
    },
    '100%': {
        opacity: '1',
     }
  },
  subscriptionDescription : {
    textAlign: 'center',
    margin: '0 15%'
  },

  // MODAL
  modal: {
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent'
  },

  paper: {
    backgroundColor: colors.WHITE,
    borderRadius: 15,
    position: 'absolute',
    minWidth: 350,
    left: 'calc(50% - 215px)',
    maxWidth: 350,
    padding: theme.spacing(5, 5, 5),
    textAlign: 'center',
    top: '20%',

    '& > .MuiTypography-root': {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },

    '& > .MuiButtonBase-root': {
      marginTop: theme.spacing(3),
    }
  },

  image: {
    marginBottom: theme.spacing(2),
    height: 64,
    width: 64,
  },

}));

export default useStyles;
