import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../utils/colors';

const useStyles = makeStyles(theme => ({

  profileBox: {
    display: 'flex',
    flexWrap: 'wrap'
  },

  emptyBox: {
    height: 100,
    padding: '25% 75% 25% '
  },

  blockedCard: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors.WHITE,
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: 10,
    width: '100%',
    boxShadow: 'none',
    cursor: 'pointer'
  },

  blockedImage: {
    height: 50,
    width: 50,
    borderRadius: 50 / 2,
    marginRight: theme.spacing(1),
  },

  blockedUsername: {
    flex: 1,
    marginLeft: 20,
  },

  blockedDate: {
    marginRight: 20
  },

  popoverUnblockContainer: {
    '& p': {
      padding: theme.spacing(1, 3),
      cursor: 'pointer'
    }
  },

  unblockbtn: {
    cursor: 'pointer',
    fontWeight: 'Bold',
    color: '#FA803D',
    marginRight: 20,

  },


  ScreenUnblock: {
    backgroundColor: 'black'
  },

  margin: {
    // margin: theme.spacing(1),
  },

  // MODAL
  modal: {
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent'
  },

  paper: {
    position: 'absolute',
    minWidth: 350,
    maxWidth: 350,
    top: '30%',
    left: 'calc(50% - 215px)',
    backgroundColor: colors.WHITE,
    borderRadius: 15,
    padding: theme.spacing(5, 5, 5),

    '& > .MuiTypography-root': {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },

    '& > .MuiButtonBase-root': {
      marginTop: theme.spacing(3),
    }

  },

  modalBtnContainer: {
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'row'
    
  },

  unblockScreen: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  unblockedCard: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: colors.GRAY,
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    borderRadius: 10,
    width: 1000,
    height: 250,
    boxShadow: 'none',
  },

  unblockedImage: {
    height: 250,
    width: 250,
    borderRadius: 50 / 2,
    marginRight: theme.spacing(1),
  },

  unblockedName: {
    flex: 1,
    marginLeft: 20,
    marginTop: -80,
    fontWeight: '900',
    color: '#fff'
  },

  unblockedDate: {
    marginLeft: 20,
    color: '#fff'

  },

  Backbtn: {
    color: '#FA803D',
  },

  unscreenbtn: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    fontWeight: 'Bold',
    fontSize: 100,
    color: '#FA803D',
    marginLeft: 300
  },
  submit:{
    marginRight: 15
  }



}));

export default useStyles;