import React, { memo, useState } from 'react';
import { Card, CardContent, Avatar, Link } from '@material-ui/core';

import useStyles from './style';
import Empty from '../empty';
import Preloader from '../preloader';
import NO_USERS_FOUND_IMAGE from "../../assets/images/no_users_found.png";

import Icon              from '@mdi/react';
import { mdiSunglasses } from '@mdi/js';
import DiamondIcon       from '../../assets/images/diamond.png';
import {
	PRELOADER_CARD_SEARCH_HOME,
	STATUS_ACTIVE,
	USER_NO_PROFILES,
	USER_SUBSCRIPTION_ELITE,
  USER_SUBSCRIPTION_PRIVACY
} from '../../utils/constants';

import { getFormattedDateTime, getImageCache } from '../../utils/helper';

const SearchResultItem = (props) => {
	const classes = useStyles();
	const { clickCallback, userConnection, key } = props;
	const [profileImage, setProfileImage] = useState('');
	const user = userConnection.user;
	const arePhotosPrivate = (userConnection.userSubscription.viewabilityStatus == USER_SUBSCRIPTION_PRIVACY || userConnection.userSubscription.viewabilityStatus == USER_SUBSCRIPTION_ELITE) && userConnection.message != STATUS_ACTIVE;

	const userAddedTimestamp = user.whenAdded;
	const formattedDate = {
		date: getFormattedDateTime(userAddedTimestamp, "unix", "dddd, MMMM DD YYYY"),
		time: getFormattedDateTime(userAddedTimestamp, "unix", "hh:mm a")
	};

	const callbackFunction = () => {
		clickCallback && clickCallback(userConnection)
	}

	getImageCache(user.mediaProfile).then((cachedImage) => {
		setProfileImage(cachedImage)
	})

	return (
		<div className={classes.itemSearch} key={key}>
			<Card onClick={callbackFunction.bind(this, userConnection)}>
				<Avatar
					className={`${classes.itemSearchImg} ${arePhotosPrivate && 'image-blur'}`}
					src={profileImage}
					variant='square'
				/>
				<div className={classes.profileDetails}>
					<p>{`${user.userName}`}
					{( userConnection?.userSubscription?.viewabilityStatus == 'PRV' ) ? <Icon path={mdiSunglasses} size={1} color="black" style={{marginLeft : 2}} /> 
        	:( userConnection?.userSubscription?.viewabilityStatus == 'ELT' ) ? <img src={DiamondIcon} className={`${classes.planIcon} ${classes.quickFade}`} /> :''}
					{`, ${user.age}`}</p>
					<p>{`${userConnection.userField.address.city}, ${userConnection.userField.address.country}`}</p>
					<p>{formattedDate.date}</p>
					<p>{formattedDate.time}</p>
				</div>
			</Card>
		</div>
	)
}

const DashboardSearchlist = (props) => {
	const classes = useStyles();
	const { title, searches, gotoPage, isLoading, clickCallback } = props;

	const populateProfiles = () => {

		if (!searches) return <Empty image={NO_USERS_FOUND_IMAGE} title="Search" description={USER_NO_PROFILES} />
		if (!searches.length) return <Empty image={NO_USERS_FOUND_IMAGE} title="Search" description={USER_NO_PROFILES}/>

		const idealSearchList = searches.filter(
			(item) => item.searchName === "My Ideal"
		);
		if (!idealSearchList.length) return <Empty image={NO_USERS_FOUND_IMAGE} title="Search" description={USER_NO_PROFILES} />;

		const searchlistResult = idealSearchList[0].users;

		if (!searchlistResult.length) return <Empty image={NO_USERS_FOUND_IMAGE} title="Search" description={USER_NO_PROFILES} />;

		return searchlistResult.slice(0,4).map((profile, index) => {
			return <SearchResultItem clickCallback={clickCallback} key={index} userConnection={profile} />;
		})
	};

	const preloader = () => {
		return [1,2,3,4].map((value) => (
			<div className={classes.itemSearch} key={value}>
				<Preloader type={PRELOADER_CARD_SEARCH_HOME} />
			</div> 
		))
	}

	return (
		<Card className={classes.card}>
			<div className={classes.cardHeader}>
				<Link component='button' onClick={gotoPage}>
					<h3>{title}</h3>
				</Link>
			</div>
			<CardContent className={`${classes.cardContent} ${classes.multipleRows}`}>
				{
					isLoading && !searches.length
					? preloader()
					: populateProfiles()
				}
			</CardContent>
		</Card>
	)
}

export default memo(DashboardSearchlist);
