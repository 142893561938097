import React, { useState, useCallback, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import { Button, IconButton, Box, Checkbox, CircularProgress, Collapse, Divider, FormControl, FormControlLabel, FormGroup, FormHelperText, Link, MenuItem, Modal, Radio, RadioGroup, Select, Slider, TextField, Typography, Avatar, Container, SvgIcon } from '@material-ui/core';

import Address                         from './address';
import AboutMe                         from './about-me';
import Height                          from './height';
import Preference                      from './preference';
import ReactCrop                       from 'react-image-crop';

import Icon                            from '@mdi/react';
import CloseOutlinedIcon               from '@material-ui/icons/CloseOutlined';
import CropOutlinedIcon                from '@material-ui/icons/CropOutlined';
import CloudUploadOutlinedIcon         from '@material-ui/icons/CloudUploadOutlined';
import RotateRightOutlinedIcon         from '@material-ui/icons/RotateRightOutlined';
import DeleteOutlinedIcon              from '@material-ui/icons/DeleteOutlined';
import LocationOnIcon                  from '@material-ui/icons/LocationOn';
import MenuBookIcon                    from '@material-ui/icons/MenuBook';
import WcIcon                          from '@material-ui/icons/Wc';
import AccessibilityIcon               from '@material-ui/icons/Accessibility';
import Cake                            from '@material-ui/icons/Cake';
import VisibilityIcon                  from '@material-ui/icons/Visibility';
import SchoolIcon                      from '@material-ui/icons/School';
import SmokingRoomsIcon                from '@material-ui/icons/SmokingRooms';
import FitnessCenterIcon               from '@material-ui/icons/FitnessCenter';
import SupervisorAccountIcon           from '@material-ui/icons/SupervisorAccount';
import PublicIcon                      from '@material-ui/icons/Public';
import WorkIcon                        from '@material-ui/icons/Work';
import PetsIcon                        from '@material-ui/icons/Pets';
import AttachMoneyIcon                 from '@material-ui/icons/AttachMoney';
import CancelPresentationIcon          from '@material-ui/icons/CancelPresentation';
import AddAPhotoIcon                   from '@material-ui/icons/AddAPhoto';
import { 
  mdiGenderMaleFemale, 
  mdiHumanMaleHeight, 
  mdiFaceProfileWoman, 
  mdiGlassMugVariant, 
  mdiChurch,
  mdiMicrophoneVariant,
  mdiSquareEditOutline,
  mdiAccountSearch,
  mdiBaby,
  mdiSwim,
  mdiSunglasses
}                                      from '@mdi/js';
import DiamondIcon                     from '../../../assets/images/diamond.png';
import ringIcon                        from '../../../assets/images/ring.svg';
import useStyles from './style';
import Title from '../../../components/title';
import { 
  LOOK_UP_GEOCODING_API_KEY, LOOK_UP_BODY_TYPE, LOOK_UP_ETHNICITY, LOOK_UP_EDUC_LEVEL, LOOK_UP_EYE_COLOR, LOOK_UP_HEIGHT, LOOK_UP_HAIR_COLOR, LOOK_UP_HOBBY_CATEG, LOOK_UP_HOBBY, LOOK_UP_INCOME_LEVEL, LOOK_UP_KID_SITUATION, LOOK_UP_LANGUAGE, LOOK_UP_PET_POLICY, LOOK_UP_POS_POLITICS, LOOK_UP_PREF_SMOKING, LOOK_UP_ALCOHOL, LOOK_UP_PROFESSION, LOOK_UP_RELATIONSHIP_STATUS, LOOK_UP_RELATIONSHIP_TYPE, LOOK_UP_RELIG_BELIEF, 
  UPD_MODE_USER_ADDRESS, UPD_MODE_USER_BODY_TYPE, UPD_MODE_USER_EDUC_LEVEL, UPD_MODE_USER_EMAIL_ADDRESS, UPD_MODE_USER_ETHNICITY, UPD_MODE_USER_EYE_COLOR, UPD_MODE_USER_ABOUT, UPD_MODE_USER_HAIR_COLOR, UPD_MODE_USER_INCOME_LEVEL, UPD_MODE_USER_KID_SITUATION, UPD_MODE_USER_LANGUAGE, UPD_MODE_USER_POS_POLITICS, UPD_MODE_USER_PREF_ALCOHOL, UPD_MODE_USER_PREF_SMOKING, UPD_MODE_USER_RELATIONSHIP_TYPE, UPD_MODE_USER_RELIGION, UPD_MODE_USER_HEIGHT,
  ALERT_SEVERITY_SUCCESS, ALERT_SEVERITY_ERROR, TOAST_AXIOS_REQUEST_ERROR, MAX_NOTE_CHARACTERS, UNITED_STATES, USER_GENDER_MALE, UPD_MODE_USER_PROFILE_PHOTO, UPD_MODE_USER_PET_POLICY, USER_GENDER_FEMALE, UPD_MODE_USER_GENDER, UPD_MODE_USER_PROFESSION, STATUS_ACTIVE, STATUS_INACTIVE, UPD_MODE_USER_STATUS, LOOK_UP_CODE_NOP, UPD_MODE_USER_HOBBY, LOOK_UP_GENDER, UPD_MODE_USER_LOOKING_FOR_GENDER, USER_GENDER_UNKNOWN, LOOK_UP_EXER_REGIMEN, UPD_MODE_USER_EXER_REGIMEN, ALERT_SEVERITY_INFO, HTTP_STATUS } from '../../../utils/constants';
import { getAddress, getLookUpDesc, handleHttpError } from '../../../utils/helper';
import { userRequest, thirdPartyRequest, lookupRequest, geoCodeRequest } from '../../../service/requests';
import lookupAction from '../../../redux/lookup/action';
import { connect } from 'react-redux';

const arrGender = [
  {
    code: USER_GENDER_MALE,
    description : 'Male'
  }, 
  {
    code: USER_GENDER_FEMALE,
    description : 'Female'
  },
  {
    code: USER_GENDER_UNKNOWN,
    description : 'Unknown'
  },

]
const { getLookUpFields, getLookUpCountry, getLookUpState, getLookUpCity }  = lookupAction;

const CollapsibleContainer = (props) => {
  const classes = useStyles();
  const { isSaving, getIsSaving, component, icon, label, value, disable, index, openedIndex, setOpenedIndex } = props;
 // const [isFalse, setIsFalse] = useState(false)
  const toggleContent = () => {
    setOpenedIndex(prevOpenedIndex => (prevOpenedIndex != index ? index : null));
    console.log('openedIndex, index', openedIndex, index);
  }

  return (
    <Box className={classes.collapsibleContainer}>
      <Box className={classes.collapsibleContent}>
        {icon}
        <Box>
          <Typography variant="caption" color="textPrimary">
            {label}
          </Typography>
          <Typography variant="caption" color="textPrimary" className={`${!disable && classes.valueText} ${openedIndex === index ? classes.hidden : classes.visible}`}>
            {value}
          </Typography>
        </Box>
        {
          !disable && <Link className={classes.link} onClick={toggleContent}>{openedIndex === index ? 'Close' : 'Edit'} <Icon path={mdiSquareEditOutline} /></Link>
        }
      </Box>
      <Collapse in={openedIndex === index}>
        <Box className={classes.collapseBoxWrapper}>
          {
            component
          }
        </Box>
      </Collapse>
      <Divider/>
    </Box>
  )
}

const Gender = (props) => {
  const classes = useStyles();

  const { required, showAlert, updateSession, user } = props
  
  const [isSaving, setIsSaving] = useState(false);
  const [gender, setGender] = useState(user.gender);

  const selectGender = event => {
    
    setGender(event.target.value);
  };

  const saveGender = () => {
    
    if (gender === '' && required) {
      showAlert(ALERT_SEVERITY_ERROR, 'Required.');

      return
    }

    setIsSaving(prevIsSaving => !prevIsSaving);
    userRequest.updateUser({
      user_id     : user.userId,
      upd_mode    : UPD_MODE_USER_GENDER,
      who_updated : user.userId,
      gender      : gender
    }).then(response => {
      console.log('response : ', response);

      if (response?.data?.status?.code != HTTP_STATUS._200) {
        let { status } = response?.data;
        throw new Error(`${status?.code} ${status?.description}`);
      }
      updateSession(response?.data?.userDetail);
      setIsSaving(prevIsSaving => !prevIsSaving);  
    }).catch(error => {
      setIsSaving(prevIsSaving => !prevIsSaving); 
      handleHttpError({
        error,
        request: 'userRequest.updateUser::src/views/settings/my-profile'
      })

    });
  }

  return (
    <Box className={classes.preferenceContainer}>
      <RadioGroup aria-label="position" className={classes.radioGroup} name="gender" value={gender} onChange={selectGender} >
        {
          arrGender.map((value, index) => 
            <FormControlLabel
              key={index}
              value={value.code}
              control={<Radio color="primary" size="small" className={classes.radio}/>}
              label={value.description}
              labelPlacement="end"
            />
          )
        }
      </RadioGroup>
    </Box>
  )
}


const MyProfile = (props) => {
  const classes = useStyles();
  const ref = useRef();



  const { 
    userId, getLookUpFields, lookUpBodyType, lookUpEducLevel, lookUpEthnicity, lookUpEyeColor, lookUpHairColor, lookUpHeight, lookUpHobbyCode, lookUpIncomeLevel, lookUpKidSituation, lookUpLanguage, lookUpPetPolicy, lookUpPosPolitics, lookUpPrefAlcohol, lookUpPrefSmoking, lookUpExerRegimen, lookUpProfession, lookUpRelStatus, lookUpRelType, lookUpReligBelief, 
    showAlert, updateSession, updateUser, user, userField, userSubscription 
  } = props;

  const [isError, setIsError] = useState(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [openedIndex, setOpenedIndex] = useState(null);
  const [isSaving, setIsSaving] = useState(false);
  const [cityLookUpTrigger, setCityLookUpTrigger] = useState({});
  const [initializedLookupState, setInitializedLookupState] = useState(true);
  const [initializedLookupCity, setInitializedLookupCity] = useState(true);
  const [initializedLookupCountry, setInitializedLookupCountry] = useState(true);
  const LookupState = props?.Lookup?.lookUpState ? props.Lookup.lookUpState : []; 
  const LookupCity = props?.Lookup?.lookUpCity ? props.Lookup.lookUpCity :  [];
  const LookupCountry = props?.Lookup?.lookUpCountry ? props.Lookup.lookUpCountry : [];
  const [isRotate, setIsRotate]                               = useState(false);
  const [angle, setAngle]                                     = useState(0);
  const [initialStateAddress, setInitialStateAddress] = useState({
    id : "",
    name : "",
    code : ""
  })
  const [initialCityAddress, setInitialCityAddress] = useState({
    id : "",
    name : ""
  })
  const [initialCountryAddress, setInitialCountryAddress] = useState({
    id : "",
    name : "",
    code : ""
  })
  useEffect(()=>{
    props.getLookUpCountry('/')
    props.getLookUpState('233')
    console.log("address",userField.address)
  },[])
  
  useEffect(()=>{
      const noCity =  Object.keys(cityLookUpTrigger).length === 0 && cityLookUpTrigger.constructor === Object;
      if(!noCity){
        props.getLookUpCity(cityLookUpTrigger.value)
        setCityLookUpTrigger({})
      }
  },[cityLookUpTrigger])

  useEffect(()=>{
    if(LookupState.length > 0 && initializedLookupState && userField.address.country == UNITED_STATES){
      const initialState = LookupState.find((state)=>state.name==userField.address.state || state.code==userField.address.state)
      props.getLookUpCity(initialState.id)
      setInitializedLookupState(false);
      setInitialStateAddress(initialState);
    }
  },[LookupState])
  
  useEffect(()=>{
    if(LookupCity.length > 0 && initializedLookupCity && userField.address.country == UNITED_STATES){
      const initialCity = LookupCity.find((city)=> { 
        return city.name.toLowerCase()==userField.address.city.toLowerCase()})
        setInitializedLookupCity(false);
        setInitialCityAddress(initialCity);
    }
  },[LookupCity])

  useEffect(()=>{
    if(LookupCountry.length > 0 && initializedLookupCountry){
      const initialCountry = LookupCountry.find((country)=> {
        return country?.name?.toLowerCase() == userField.address?.country?.toLowerCase()
      })
      setInitialCountryAddress(initialCountry)
      setInitializedLookupCountry(false)
    }
  },[LookupCountry])

  const getIsSaving = (boolean) => {
    console.log('getIsSaving : ', boolean);

    setIsError(isError);
  }

  const fields = [
    {
      label     : 'MY LOCATION',
      value     : getAddress(userField.address),
      component : <Address {...props} ref={ref} callback={getIsSaving} initialCityAddress={initialCityAddress} initialCountryAddress={initialCountryAddress} initialStateAddress={initialStateAddress} setCityLookUpTrigger={setCityLookUpTrigger} isError={isError} setIsSaving={setIsSaving}  isSaving={isSaving} setOpenedIndex={setOpenedIndex}/>,
      icon      : <LocationOnIcon />
    },
    {
      label     : 'ABOUT ME',
      value     : userField.about,
      component : <AboutMe {...props} ref={ref} setIsSaving={setIsSaving}  isSaving={isSaving} setOpenedIndex={setOpenedIndex}/>,
      icon      : <MenuBookIcon />
    },
    {
      label     : 'MY GENDER',
      value     : user.gender === USER_GENDER_MALE ? 'Male' : 'Female',
      disable   : true,
      icon      : <Icon path={mdiGenderMaleFemale} />
    },
    {
      label     : 'MY STATUS',
      value     : userField.userPreference.relationshipStatusDesc || getLookUpDesc(lookUpRelStatus, userField.userPreference.relationshipStatus),
      component : <Preference {...props} label="My status" index={0} ref={ref} setIsSaving={setIsSaving} isSaving={isSaving} setOpenedIndex={setOpenedIndex}/>,
      icon      : <img src={ringIcon} className={classes.ringIcon} />
    },
    {
      label     : 'LOOKING FOR WHAT?',
      value     : getLookUpDesc(lookUpRelType, userField.userPreference.relationshipType),
      component : <Preference {...props} label="Looking for what" index={1} ref={ref} setIsSaving={setIsSaving} isSaving={isSaving} setOpenedIndex={setOpenedIndex}/>,
      icon      : <Icon path={mdiAccountSearch} />
    },
    {
      label     : 'LOOKING FOR WHO?',
      value     : arrGender.filter(value => userField.userPreference?.lookingForGender === value.code)[0]?.description,
      component : <Preference {...props} label="Looking for who" index={2} ref={ref} setIsSaving={setIsSaving} isSaving={isSaving} setOpenedIndex={setOpenedIndex}/>,
      icon      : <WcIcon />
    },
    {
      label     : 'MY BIRTHDAY',
      value     : user.birthday,
      disable   : true,
      icon      : <Cake />
    },
    {
      label     : 'MY HEIGHT',
      value     : getLookUpDesc(lookUpHeight, userField.userPreference.height),
      component : <Height {...props} ref={ref} setIsSaving={setIsSaving}  isSaving={isSaving} setOpenedIndex={setOpenedIndex}/>,
      icon      : <Icon path={mdiHumanMaleHeight} />
    },
    {
      label     : 'MY LANGUAGES',
      value     : getLookUpDesc(lookUpLanguage, userField.userPreference.languageCode),
      component : <Preference {...props} label="Languages" index={3} ref={ref} multiple={true} setIsSaving={setIsSaving} isSaving={isSaving} setOpenedIndex={setOpenedIndex}/>,
      icon      : <PublicIcon />
    },
    {
      label     : 'MY BODY TYPE',
      value     : getLookUpDesc(lookUpBodyType, userField.userPreference.bodyType),
      component : <Preference {...props} label="Body type" index={4} ref={ref} setIsSaving={setIsSaving} isSaving={isSaving} setOpenedIndex={setOpenedIndex}/>,
      icon      : <AccessibilityIcon />
    },
    {
      label     : 'MY HAIR COLOR',
      value     : getLookUpDesc(lookUpHairColor, userField.userPreference.hairColor),
      component : <Preference {...props} label="Hair color" index={5} ref={ref} setIsSaving={setIsSaving} isSaving={isSaving} setOpenedIndex={setOpenedIndex}/>,
      icon      : <Icon path={mdiFaceProfileWoman} />
    },
    {
      label     : 'MY EYE COLOR',
      value     : getLookUpDesc(lookUpEyeColor, userField.userPreference.eyeColor),
      component : <Preference {...props} label="Eye color" index={6} ref={ref} setIsSaving={setIsSaving} isSaving={isSaving} setOpenedIndex={setOpenedIndex}/>,
      icon      : <VisibilityIcon />
    },
    {
      label     : 'MY KIDS SITUATION',
      value     : getLookUpDesc(lookUpKidSituation, userField.userPreference.kidSituation),
      component : <Preference {...props} label="Kids situation" index={7} ref={ref} setIsSaving={setIsSaving} isSaving={isSaving} setOpenedIndex={setOpenedIndex}/>,
      icon      : <Icon path={mdiBaby} />
    },
    {
      label     : 'MY EDUCATION LEVEL',
      value     : getLookUpDesc(lookUpEducLevel, userField.userPreference.educLevel),
      component : <Preference {...props} label="Education level" index={8} ref={ref} setIsSaving={setIsSaving} isSaving={isSaving} setOpenedIndex={setOpenedIndex}/>,
      icon      : <SchoolIcon />
    },
    {
      label     : 'MY ALCOHOL INTAKE',
      value     : getLookUpDesc(lookUpPrefAlcohol, userField.userPreference.prefAlcohol),
      component : <Preference {...props} label="Alcohol intake" index={9} ref={ref} setIsSaving={setIsSaving} isSaving={isSaving} setOpenedIndex={setOpenedIndex}/>,
      icon      : <Icon path={mdiGlassMugVariant} />
    },
    {
      label     : 'MY SMOKING FREQUENCY',
      value     : getLookUpDesc(lookUpPrefSmoking, userField.userPreference.prefSmoking),
      component : <Preference {...props} label="Smoking frequency" index={10} ref={ref} setIsSaving={setIsSaving} isSaving={isSaving} setOpenedIndex={setOpenedIndex}/>,
      icon      : <SmokingRoomsIcon />
    },
    {
      label     : 'MY EXERCISE REGIMEN',
      value     : getLookUpDesc(lookUpExerRegimen, userField.userPreference.exerRegimen),
      component : <Preference {...props} label="Exercise regimen" index={11} ref={ref} setIsSaving={setIsSaving} isSaving={isSaving} setOpenedIndex={setOpenedIndex}/>,
      icon      : <FitnessCenterIcon />
    },
    {
      label     : 'MY ETHNICITY',
      value     : getLookUpDesc(lookUpEthnicity, userField.userPreference.ethnicity),
      component : <Preference {...props} label="Ethnicity" index={12} ref={ref} setIsSaving={setIsSaving} isSaving={isSaving} setOpenedIndex={setOpenedIndex}/>,
      icon      : <SupervisorAccountIcon />
    },
    {
      label     : 'MY FAITH',
      value     : userField.userPreference.religBeliefDesc || getLookUpDesc(lookUpReligBelief, userField.userPreference.religBelief),
      component : <Preference {...props} label="Faith" index={13} ref={ref} setIsSaving={setIsSaving} isSaving={isSaving} setOpenedIndex={setOpenedIndex}/>,
      icon      : <Icon path={mdiChurch} />
    },
    {
      label     : 'MY PROFESSION',
      value     : getLookUpDesc(lookUpProfession, userField.userPreference.profession),
      component : <Preference {...props} label="Profession" index={14} ref={ref} setIsSaving={setIsSaving} isSaving={isSaving} setOpenedIndex={setOpenedIndex}/>,
      icon      : <WorkIcon />
    },
    {
      label     : 'MY PET POLICY',
      value     : getLookUpDesc(lookUpPetPolicy, userField.userPreference.petPolicy),
      component : <Preference {...props} label="Pet policy" index={15} ref={ref} setIsSaving={setIsSaving} isSaving={isSaving} setOpenedIndex={setOpenedIndex}/>,
      icon      : <PetsIcon />
    },
    // {
    //   label     : 'INCOME LEVEL (ANNUAL)',
    //   value     : getLookUpDesc(lookUpIncomeLevel, userField.userPreference.incomeLevel),
    //   component : <Preference {...props} label="Income level" index={15} ref={ref} setIsSaving={setIsSaving} isSaving={isSaving} setOpenedIndex={setOpenedIndex}/>,
    //   icon      : <AttachMoneyIcon />
    // },
    {
      label     : 'MY POLITICS',
      value     : getLookUpDesc(lookUpPosPolitics, userField.userPreference.posPolitics),
      component : <Preference {...props} label="Politics" index={16} ref={ref} setIsSaving={setIsSaving} isSaving={isSaving} setOpenedIndex={setOpenedIndex}/>,
      icon      : <Icon path={mdiMicrophoneVariant} />
    },
    {
      label     : 'MY HOBBIES',
      value     : getLookUpDesc(lookUpHobbyCode, userField.userPreference.hobbyCode),
      component : <Preference {...props} label="Hobbies" index={17} ref={ref} multiple={true} setIsSaving={setIsSaving} isSaving={isSaving} setOpenedIndex={setOpenedIndex}/>,
      icon      : <Icon path={mdiSwim} />
    },
  ]

  useEffect(() => {
    getLU();
  }, []);

  const getLU = () => {

    if (lookUpRelStatus.length === 0) {
      getLookUpFields(LOOK_UP_RELATIONSHIP_STATUS);
    }

    if (lookUpRelType.length === 0) {
      getLookUpFields(LOOK_UP_RELATIONSHIP_TYPE);
    }

    if (lookUpRelType.length === 0) {
      getLookUpFields(LOOK_UP_GENDER);
    }

    if (lookUpLanguage.length === 0) {
      getLookUpFields(LOOK_UP_LANGUAGE);
    }

    if (lookUpBodyType.length === 0) {
      getLookUpFields(LOOK_UP_BODY_TYPE);
    }

    if (lookUpHairColor.length === 0) {
      getLookUpFields(LOOK_UP_HAIR_COLOR);
    }

    if (lookUpEyeColor.length === 0) {
      getLookUpFields(LOOK_UP_EYE_COLOR);
    }

    if (lookUpKidSituation.length === 0) {
      getLookUpFields(LOOK_UP_KID_SITUATION);
    }
    
    if (lookUpEducLevel.length === 0) {
      getLookUpFields(LOOK_UP_EDUC_LEVEL);
    }

    if (lookUpPrefAlcohol.length === 0) {
      getLookUpFields(LOOK_UP_ALCOHOL);
    }

    if (lookUpPrefSmoking.length === 0) {
      getLookUpFields(LOOK_UP_PREF_SMOKING);
    }

    if (lookUpEthnicity.length === 0) {
      getLookUpFields(LOOK_UP_EXER_REGIMEN);
    }

    if (lookUpEthnicity.length === 0) {
      getLookUpFields(LOOK_UP_ETHNICITY);
    }

    if (lookUpReligBelief.length === 0) {
      getLookUpFields(LOOK_UP_RELIG_BELIEF);
    }

    if (lookUpProfession.length === 0) {
      getLookUpFields(LOOK_UP_PROFESSION);
    }

    if (lookUpPetPolicy.length === 0) {
      getLookUpFields(LOOK_UP_PET_POLICY);
    }

    if (lookUpIncomeLevel.length === 0) {
      getLookUpFields(LOOK_UP_INCOME_LEVEL);
    }

    if (lookUpPosPolitics.length === 0) {
      getLookUpFields(LOOK_UP_POS_POLITICS);
    }

    if (lookUpHeight.length === 0) {
      getLookUpFields(LOOK_UP_HEIGHT);
    }

    if (lookUpHobbyCode.length === 0) {
      getLookUpFields(LOOK_UP_HOBBY);
    }
  }

  const toggleStatusModal = () => {
    setIsStatusModalOpen(prevIsStatusModalOpen => !prevIsStatusModalOpen);
  }

  const updateStatus = () => {

    const status = (user.status === STATUS_ACTIVE) ? STATUS_ACTIVE : STATUS_INACTIVE;
    toggleStatusModal();
    userRequest.updateUser({
      user_id       : user.userId,
      upd_mode      : UPD_MODE_USER_STATUS,
      who_updated   : user.userId,
      status        : status
    }).then(response => {
      console.log('response : ', response.data.userDetail.user);

      if (response?.data?.status?.code != HTTP_STATUS._200) {
        let { status } = response?.data;
        throw new Error(`${status?.code} ${status?.description}`);
      }
      updateSession(response?.data?.userDetail);
      updateUser(response?.data?.userDetail);
      showAlert(ALERT_SEVERITY_SUCCESS, (user.status === STATUS_ACTIVE) ? 'You deactivated your account successfully.' : 'You activated your account successfully.');
    }).catch(error => {
      handleHttpError({
        error,
        request: 'userRequest.updateUser::src/views/settings/my-profile'
      })
    });
  }
  

  //Update Profile Picture
  const [imageRef, setImageRef] = useState(null);
  const [selectedImage, setSelectedImage] = useState({});
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [selectedImagePath, setSelectedImagePath] = useState();
  const [file, setFile] = useState(null);
  const [medias, setMedia] = useState([]);
  const [isSavingProfilePicture, setIsSavingProfilePicture] = useState(false)
  const [selectedImageValue, setSelectedImageValue] = useState({})

  const [crop, setCrop] = useState({ 
    unit:   '%', 
    x:      0,
    y:      0,
    width:  100,
    height: 100,
    aspect: null
  });

  const toggleUploadModal = () => {
    setIsUploadModalOpen(prevIsModalOpen => !prevIsModalOpen);
  };

  const onChange = (e) => {
    
    if (e.target.files.length === 0) {
      return
    }

    const objectUrl = URL.createObjectURL(e.target.files[0]);
    toggleUploadModal();
    setIsRotate(false);
    setAngle(0);
    setCrop({
      unit:   '%', 
      x:      0,
      y:      0,
      width:  100,
      height: 100,
      aspect: 0
    });
    setSelectedImage({
      s3_path : objectUrl
    });
  }

  const updateCropRatio = (ratioWidth,ratioHeight) => {
    console.log(crop.aspect, 'crop aspect')
  
    const ratio              = ratioWidth / ratioHeight;
    const origWidth          = imageRef.width;
    const origHeight         = imageRef.height;
    const newWidth           = ratioWidth * 100;
    const computedRatio      = (origHeight / origWidth) * newWidth;
    const rationInPercentage = ((computedRatio / origWidth) * 100) > 100 ? 100: ((computedRatio / origWidth) * 100);
   
    setCrop({
      ...crop,
      unit:  '%', 
      x:      0,
      y:     0,
      width:    ratioWidth == 1 && ratioHeight == 1 ? ((origHeight / origWidth) * 100) >= 100 ? 100 : ((origHeight / origWidth) * 100) : rationInPercentage,
      height:   ratioWidth == 1 && ratioHeight == 1 ? ((origWidth / origHeight) * 100) >= 100 ? 100 : ((origWidth / origHeight) * 100) : newWidth >= 100 ? 100: newWidth,
      aspect: ratio
    });
  }


  const setProfilePicture = (path) => {
    setIsSavingProfilePicture(prevIsSaving => !prevIsSaving);
    userRequest.updateUser({
      user_id        : userId,
      upd_mode       : UPD_MODE_USER_PROFILE_PHOTO,
      who_updated    : userId,
      profile_photos : path
    }).then(response => {
      console.log('response : ', response);

      if (response?.data?.status?.code != HTTP_STATUS._200) {
        let { status } = response?.data;
        throw new Error(`${status?.code} ${status?.description}`);
      }
      updateUser(response?.data?.userDetail?.user);
      updateSession(response?.data?.userDetail);
      showAlert(ALERT_SEVERITY_SUCCESS, 'You have successfully changed your profile picture.');
      setIsSavingProfilePicture(prevIsSaving => !prevIsSaving);
    }).catch(error => {
      setIsSavingProfilePicture(prevIsSaving => !prevIsSaving);
      handleHttpError({
        error,
        request: 'userRequest.updateUser::src/views/settings/my-profile'
      })
    });
  }

  const uploadPhoto = () => {
    
    showAlert(ALERT_SEVERITY_INFO, 'Changing your profile picture.');
    setIsSaving(prevIsSaving => !prevIsSaving);

    const data = new FormData();
    data.append('user_id', userId);
    data.append('image', file);
    data.append('is_primary', 'false')

    userRequest.uploadPhoto(
      data
    ).then(response => {
      console.log('response : ', response);
      setProfilePicture(response.data.media[0].path)
      toggleUploadModal();
      setIsSaving(prevIsSaving => !prevIsSaving);

      if (medias.length === 0) {
        setMedia(response.data.media);
        return
      }
      let newMedias = medias;
      response.data.media.forEach(element => {
        if (!medias.some(media => media.media_id === element.media_id)) {
          newMedias = [element, ...newMedias];
        } else {
          newMedias = response.data.media;
        }
      });
      setMedia(newMedias);
    }).catch(error => {
      setIsSaving(prevIsSaving => !prevIsSaving);
      handleHttpError({
        error,
        request: 'userRequest.uploadPhoto::src/views/settings/my-profile'
      })
    }); 
  }

  useEffect(()=>{
    if(imageRef != null){
      createCroppedImage(imageRef, crop,angle,isRotate);
    }
  },[imageRef,angle,isRotate]);

  const onLoad = useCallback(img => {
    setImageRef(img);
  }, []);

  const cropImage = async (crop) => {
    console.log('crop : ', crop);

    if (imageRef && crop.width && crop.height) {
      createCroppedImage(imageRef, crop, angle, isRotate); 
    }
  };

  const createCroppedImage = async (image, crop, angle, isRotate) => {
    const canvas  = document.createElement('canvas');
    const scaleX  = image.naturalWidth / image.width;
    const scaleY  = image.naturalHeight / image.height;

    canvas.width  = crop.width;
    canvas.height = crop.height;
    
    const ctx     = canvas.getContext('2d');

    ctx.save();
    if(isRotate == true){
      ctx.translate(canvas.width/2,canvas.height/2);
      ctx.rotate(isRotate? angle * Math.PI/180 : 0);
    }

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      isRotate? -crop.width / 2 : 0, isRotate? -crop.height / 2 : 0,
      crop.width,
      crop.height
    );

    ctx.restore();
      
    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          reject(new Error('Canvas is empty'));
          return;
        }
        
        blob.name = 'newFile.jpeg';
        setFile(blob);
      }, 'image/jpeg');
    });
  }

  const rotateImage = () => {
    if(angle != 270){
      setAngle(angle + 90);
      setIsRotate(true);
    }else{
      setAngle(0);
      setIsRotate(false);
    }

    console.log('rotateImage()', isRotate);
    console.log('angle: ', angle);
  }
  
  const uploadImageRef = useRef (null)
 
  return (
    <Box className={classes.content}>
      <Title title="My Profile" />
      <Box className={classes.basicInfoContainer}>
      <input ref={uploadImageRef} name="image" id="file" type="file" accept="image/*" className={classes.inputFile} onChange={(onChange)}/> 
        <IconButton onClick={()=> uploadImageRef.current.click()} >
          <Avatar
            className= {classes.images}
            alt={user.userName}
            src={user.mediaProfile}
          />   
          <AddAPhotoIcon 
            className={classes.photoIcon}
          />
        </IconButton>
        <Box>
          <Typography variant="h6">{`${user.firstName} ${user.lastName} `} 
            {( userSubscription?.viewabilityStatus == 'PRV' ) ? <Icon path={mdiSunglasses} size={1} color="black" style={{marginLeft : 2}} /> 
            :( userSubscription?.viewabilityStatus == 'ELT' ) ? <img src={DiamondIcon} className={`${classes.planIcon} ${classes.quickFade}`} /> :''}
          </Typography>
          <Typography variant="subtitle2">{`${user.age} years old`}</Typography>
        </Box>
      </Box>
      {
        fields.map((value, index) => 
          <CollapsibleContainer
            component={value.component}
            icon={value.icon}
            key={index}
            label={value.label}
            value={value.value}
            disable={value.disable}
            getIsSaving={getIsSaving}
            isSaving={isSaving}
            index={index}
            openedIndex={openedIndex}
            setOpenedIndex={setOpenedIndex}
          />
        )
      }
      <Button variant="outlined" color="primary" className={classes.button} onClick={toggleStatusModal}>{user.status === STATUS_ACTIVE ? 'Deactivate Account' : 'Activate Account'}</Button>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={isStatusModalOpen}
        onClose={toggleStatusModal}
      >
        <div className={classes.modal}>
          <div className={classes.paper}>
          
            <Typography variant="h5" color="textPrimary" className={`text-bold text-center`}>
              {
                user.status === STATUS_ACTIVE ?
                  `Deactivate Account`
                :
                  `Activate Account`
              }
            </Typography>

            <Typography variant="subtitle2" className={`text-center`} color="textPrimary">
              {
                user.status === STATUS_ACTIVE ?
                  `Do you really want to deactivate your account?`
                :
                  `Do you really want to activate your account?`
              }
            </Typography>

            <Box className={classes.modalBtnContainer}>
              <Button
                type="submit"
                fullWidth
                variant="outlined"
                color="primary"
                className={classes.submit}
                onClick={toggleStatusModal}
              >
                No
              </Button>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                onClick={updateStatus}
              >
                {
                  user.status === STATUS_ACTIVE ?
                    `Deactivate`
                  :
                    `Activate`
                }
              </Button>
            </Box>
          </div>
        </div>
      </Modal>  
      <Modal
              open={isUploadModalOpen}
              onClose={toggleUploadModal}
            >
              <Box className={classes.imageBoxModal}>
                <CloseOutlinedIcon className={classes.closeIcon} onClick={toggleUploadModal}/>
                <Box className={classes.uploadImageBox}>
                  <ReactCrop
                    src={selectedImage && selectedImage.s3_path}
                    onImageLoaded={onLoad}
                    crop={crop}
                    style={{ transform: `rotate(${angle}deg)` }}
                    onChange={c => setCrop(c)}
                    onComplete={cropImage}
                  />
                  <Box className={classes.controls}>
                    <Button disabled={isSaving} variant="outlined" className={classes.button} onClick={updateCropRatio.bind(this, 0,0)}>
                      Freeform
                    </Button>
                    <Button disabled={isSaving} variant="outlined" className={classes.button}onClick={rotateImage} startIcon={<RotateRightOutlinedIcon className={classes.cropIcon}/>}>
                      Rotate
                    </Button>
                    <Button disabled={isSaving} variant="outlined" className={classes.button} onClick={updateCropRatio.bind(this, 1,1)}>
                      Square
                    </Button>
                    <Button disabled={isSaving} variant="outlined" className={classes.button} onClick={updateCropRatio.bind(this, 5,7)}>
                      5:7
                    </Button>
                    <Button disabled={isSaving} variant="outlined" className={classes.button} onClick={updateCropRatio.bind(this, 3,4)}>
                      3:4
                    </Button>
                    <Button disabled={isSaving} variant="outlined" className={classes.button} onClick={updateCropRatio.bind(this, 3,5)}>
                      3:5
                    </Button>
                    <Button disabled={isSaving} variant="outlined" className={classes.button} onClick={updateCropRatio.bind(this, 2,3)}>
                      2:3
                    </Button>
                  </Box>
                  <Box className={classes.controls}>
                    <Button disabled={isSaving} variant="outlined" className={classes.button} onClick={toggleUploadModal} startIcon={<CancelPresentationIcon className={classes.cropIcon}/>}>
                      Cancel
                    </Button>
                    <Button disabled={isSaving} variant="outlined" className={classes.buttonUpload} onClick={uploadPhoto} startIcon={!isSaving ? <CloudUploadOutlinedIcon className={classes.cropIcon}/> : <></>}>
                      {
                        isSaving ?
                          <CircularProgress color="white" size={24}/>
                        : 
                          'Upload'
                      }
                    </Button>
                  </Box>
                  
                </Box>
              </Box>
      </Modal>
    </Box>
  )
}

const mapStateToProps = state => {return {
  Lookup: state.Lookup,
  userSubscription  : state.User.userSubscription
}} 

export default connect(mapStateToProps, { getLookUpFields, getLookUpCountry, getLookUpState, getLookUpCity })(
  MyProfile
);
