import { makeStyles } from '@material-ui/core/styles';
import colors from '../../utils/colors';

const useStyles = makeStyles(theme => ({

  // SEARCH
  root: {
    position: 'absolute',
    marginTop: '120px',
    marginLeft: 'auto',
    marginRight: 'auto',
    left: 0,
    right: 0,
    [theme.breakpoints.down("sm")]: {
      marginTop: '165px'
    },

    '& > .MuiGrid-root': {
      marginTop: theme.spacing(4),
    },
  },

  search: {
    alignItems: 'center',
    borderRadius: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(2),

    '&:hover': {
      backgroundColor: colors.HOVER_DEFAULT,
      cursor: 'pointer'
    },

    '& > .MuiBox-root': {
      alignItems: 'center', 
      display: 'flex',
    },

    '& > .MuiBox-root:first-of-type': {
      flex: 0.8,
      overflow: 'hidden',
    }
  },

  searchName: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap'
  },

  addSearch: {
    background: `linear-gradient(${colors.GRADIENT_YELLOW}, ${colors.GRADIENT_ORANGE})`,
    justifyContent: 'center'
  },

  disableAddSearch: {
    backgroundColor: colors.GRAY,
    background: 'none',
    '&:hover': {
      backgroundColor: colors.GRAY,
      cursor: 'not-allowed'
    },
  },

  flexEnd: {
    alignItems: 'flex-end',
    display: 'flex',
    justifyContent: 'flex-end',
  },

  header: {
    borderBottom: `1px solid ${colors.TEXT_GRAY}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: theme.spacing(3, 0, 1, 0),
  },

  headerLeft: {
    flex: 1,
    fontWeight: 'bold',
    padding: theme.spacing(0, 0, 0, 4),
  },

  headerRight: {
    flex: 1,
    display: 'flex',
    fontWeight: 'bold',
    padding: theme.spacing(0, 4, 0, 0),
    justifyContent: 'flex-end'
  },

  paper: {
    backgroundColor: colors.BACKGROUND_COLOR,
    marginTop: theme.spacing(4),
  },

  searchContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column'
  },

  searchFieldContainer: {
    alignItems: 'flex-end',
    display: 'flex',
    justifyContent: 'flex-end',
    margin: theme.spacing(2, 0, 2, 0),
  },

  notFound: {
    width: '100%',
    
  },

  searchField: {
    backgroundColor: colors.WHITE,
  },

  defaultSearch: {
    fontWeight: 'bold',
  },

  searchCount: {
    display: 'inline-flex'
  },

  action: {
    flex: 1,
    minWidth: 80,
    display: 'flex',
    justifyContent: 'flex-end'
  },

  actionButtonEmpty: {
    height: 24,
    minHeight: 24,
    width: 24,
    minWidth: 24,
    margin: theme.spacing(0, 0, 0, 1),
  },

  actionButton: {
    backgroundColor: 'transparent',
    // borderRadius: 32/2,
    // height: 32,
    // minHeight: 32,
    // width: 32,
    // minWidth: 32,
    padding: 0,
    margin: theme.spacing(0, 0, 0, 1),
    zIndex: 2,

    '&:hover': {
      backgroundColor: colors.HOVER_DEFAULT
    },
  },

  // MESSAGE CENTER CARD

  notifCard: {
    alignItems: 'center',
    backgroundColor: colors.WHITE,
    display: 'flex',
    padding: 5,
    position: 'relative'
  },

  image: {
    
    margin: theme.spacing(2),
    [theme.breakpoints.up('xs')]: {
      height: 50,
      width: 50,
      borderRadius: 50/2,
    },
    [theme.breakpoints.up('sm')]: {
      height: 75,
      width: 75,
      borderRadius: 75/2,
    },
    [theme.breakpoints.up('md')]: {
      height: 75,
      width: 75,
      borderRadius: 75/2,
    },
  },

  contentView: {
    flex: '1',
    alignSelf: 'center',
  },

  info: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  

  // Search Result
  searchResult: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },

}));

export default useStyles;