import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, Grid, IconButton, CircularProgress, Paper, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/EditOutlined';
import DeleteIcon from '@material-ui/icons/DeleteOutlined';
import StarIcon from '@material-ui/icons/StarOutlined';
import useStyles from './style';
import Preloader from '../../../components/preloader';
import {
  PRELOADER_SAVED_SEARCH,
  MY_IDEAL,
  USER_GENDER_UNKNOWN,
  ALERT_SEVERITY_SUCCESS,
  ALERT_SEVERITY_ERROR,
  TOAST_AXIOS_REQUEST_ERROR,
  TOAST_SEARCH_ADDED,
  TOAST_SEARCH_UPDATED,
  TOAST_SEARCH_DELETED,
} from '../../../utils/constants';
import { getRandomNumber, urlParamToJSON } from '../../../utils/helper';

const SavedSearchList = (props) => {
  const [isLoading, setIsLoading] = useState(false)
  const [isDeleting, setIsDeleting] = useState(false)
  const classes = useStyles();
  const location = useLocation();
  const idealParameter = useLocation();
  const {
    search,
    searches,
    setSearch,
    addSearch, isSearchAdding, isSearchAdded, isSearchAddError,
    getSearches, isSearchLoading, isSearchLoaded,
    deleteSearch, isSearchDeleted, isSearchDeleteError,
    user, userId, userAddress,
    onEditSearch, onAddSearch, onExecuteSearch, showAlert,
  } = props;

  const urlParams = location.search;
  const urlParamsObject = urlParams ? urlParamToJSON(urlParams.substring(1)) : {};

  const [iconButtonFocus, setIconButtonFocus] = useState(false);
  const [searchName, setSearchName] = useState('');
  const [viewAllOpen, setViewAllOpen] = useState(false);
  const navMyIdeal= useRef(null);

  useEffect(() => {
    if (!isSearchLoaded)
      getSearches();

    if (!urlParamsObject.searchname || !isSearchLoaded) return;
    const searchNameParam = urlParamsObject.searchname.replace('+', ' ');
    editSearchItem(searchNameParam);
  }, [isSearchLoaded]);

  useEffect(()=>{
    if(typeof idealParameter?.state?.detail !== undefined && idealParameter?.state?.detail == true){
      setViewAllOpen(true)
    }
  },[idealParameter])

  // useEffect(() => {
  //   console.log('isSearchAdded : ', isSearchAdded);

  //   if (isSearchAdded) {
  //     onAddSearch(); 
  //     showAlert(ALERT_SEVERITY_SUCCESS, TOAST_SEARCH_ADDED(search.searchName));
  //   }

  //   // if (isSearchAddError)
  //   // showAlert(ALERT_SEVERITY_ERROR, TOAST_AXIOS_REQUEST_ERROR);

  // }, [isSearchAdded]);

  // useEffect(() => {
  //   if (isSearchDeleted)
  //     showAlert(ALERT_SEVERITY_SUCCESS, TOAST_SEARCH_DELETED(searchName));

  //   if (isSearchDeleteError)
  //     showAlert(ALERT_SEVERITY_ERROR, TOAST_AXIOS_REQUEST_ERROR);

  // }, [isSearchDeleted, isSearchDeleteError]);

  const enterIconButton = () => {
    setIconButtonFocus(true);
  }

  const leaveIconButton = () => {
    setIconButtonFocus(false)
  }

  const handleSearchAdd = () => {
    console.log('SavedSearchList.handleSearchAdd()');

    const myIdealSearch = searches.find(search => search.searchName.toUpperCase() === MY_IDEAL);
    const myIdealGender = myIdealSearch ? myIdealSearch.gender : USER_GENDER_UNKNOWN;

    setIsLoading(true)
    

    addSearch({
      user_id: userId,
      gender: myIdealGender,
      age_from: (user.age <= 23) ? 18 : user.age,
      age_to: user.age + 10,
      travel_tolerance: 50,
      country: userAddress.country,
      state: userAddress.state,
      city: userAddress.city,
      zip_code: userAddress.zipCode,
    });

    const timeout = () => {
      setIsLoading(false);
      onEditSearch();
    }
    setTimeout(timeout, 3000)
    
 
  };

  const editSearch = (search, event) => {
    console.log('SavedSearchList.editSearch()');

    if (event) event.stopPropagation();
    setSearch(search);
    onEditSearch();
  }

  const editSearchItem = (searchNameParam) => {
    const searchItemObj = searches.find(search => search.searchName.toUpperCase() === searchNameParam.toUpperCase());
    if (searchItemObj) {
      setSearchName(searchNameParam);
      editSearch(searchItemObj, null);
    }
  }

  const handleSearchDelete = (search, event) => {
    console.log('SavedSearchList.deleteSearch()');
    setIsDeleting(true);
    const { searchId } = search;

    event.stopPropagation();
    setSearch(search);
    deleteSearch({
      searchId,
      search: {
        who_updated: userId
      }
    });
    const timeout = () => {
      setIsDeleting(false)
    }
    setTimeout(timeout, 2000)
  }

  const executeSearch = (search, event) => {
    console.log('SavedSearchList.executeSearch()');

    event.stopPropagation();
    setSearch(search);
    onExecuteSearch();
  }

  return (
    <Grid
      container
      spacing={2}
    >
      {
        isSearchLoading ?
          Array.from({ length: getRandomNumber(2, 9) }).map((value, index) => {
            return (
              <Preloader type={PRELOADER_SAVED_SEARCH} key={index} />
            )
          })
          :
          <>

            <Grid item xs={12} sm={12} md={6} lg={4}>
            {!isLoading && <Paper className={`${classes.search} ${classes.addSearch} ${searches.length >= 8 && classes.disableAddSearch}`} onClick={searches.length < 8 ? handleSearchAdd : null}>
                <AddIcon color='secondary' />
                <Typography variant='subtitle2' color='secondary' className={`text-bold`}>Add Search</Typography>
              </Paper>}
            {isLoading && <Paper className={`${classes.search} ${classes.addSearch} ${isLoading && classes.disableAddSearch}`}>
                <CircularProgress size={24} color={'secondary'}/>
              </Paper>}
            </Grid>
            {
              searches.map((search, index) => {
                return (
                  <Grid item xs={12} sm={12} md={6} lg={4} key={index}>
                    <Paper className={`${classes.search} ${iconButtonFocus && classes.searchButtonUnhover} ${isDeleting && classes.disableButton}`} ref={index == 0 ? navMyIdeal : null} onClick={viewAllOpen && index == 0 ? navMyIdeal.current.click() : null || executeSearch.bind(this, search)}>
                      <> 
                      <Box>
                        {
                          index === 0 ?
                            <StarIcon color='primary' />
                            :
                            <SearchIcon color='primary' />
                        }
                        <Typography variant='subtitle2' color='primary' className={`text-bold ${classes.searchName}`} >{search.searchName}</Typography>
                      </Box>
                      <Typography variant='subtitle2' color='primary' className={`text-bold`}>{`${search.users.filter(user => user.isViewed === false).length}/${search.users.length}`}</Typography>
                      <Box>
                        {
                          index === 0 &&
                          <Box className={classes.actionButtonEmpty}>
                          </Box>
                        }
                        <IconButton color="primary" className={classes.actionButton} onClick={editSearch.bind(this, search)} onMouseEnter={enterIconButton} onMouseLeave={leaveIconButton}>
                          <EditIcon style={{ fill: 'green' }} />
                        </IconButton>
                        {
                          index !== 0 &&
                          <IconButton color="primary" className={classes.actionButton} onClick={handleSearchDelete.bind(this, search)} onMouseEnter={enterIconButton} onMouseLeave={leaveIconButton}>
                            <DeleteIcon style={{ fill: 'red' }} />
                          </IconButton>
                        }
                      </Box>
                      </>
                    </Paper>
                  </Grid>
                )
              })
            }
          </>
      }
    </Grid>
  )
}

export default SavedSearchList;