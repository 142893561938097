import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../../utils/colors';

const useStyles = makeStyles(theme => ({
  paymentCard: {
    outline: 'none',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    boxShadow: 24,
    padding: 50,
    borderRadius: 10,
    width: '400px',
    height: '400px',
  },

  closeButton: {
    position: 'absolute',
    top: -40,
    right: -40
  },

  mainContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    position: 'relative',
    width: '100%',
    height: '100%',
    '-webkit-touch-callout': 'none',
    '-webkit-user-select': 'none',
    '-khtml-user-select': 'none',
    '-moz-user-select': 'none',
    '-ms-user-select': 'none',
    'user-select': 'none',
    fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'sans-serif'"
  },

  imageContainer: {
    marginTop: 20,
    width: 100,
    height: 100,
    overflow: 'hidden',
    marginLeft: 'auto',
    marginRight: 'auto',
    '& > img': {
      width: '100%',
      height: '100%',
    },
  },

  cardInformation: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    color: colors.PRIMARY_TEXT,
    fontSize: 14,
    '& > input': {
      marginTop: 5,
      width: '100%',
      height: '35px',
      borderTopLeftRadius: 7,
      borderTopRightRadius: 7,
      boxSizing: 'border-box',
      paddingLeft: 10,
      border: '1px solid gray',
      outlineColor: colors.PRIMARY_TEXT
    }
  },

  cardAdditional: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',


    '& input': {
      width: '50%',
      height: '35px',
      boxSizing: 'border-box',
      border: '1px solid gray',
      borderTop: 0,
      outlineColor: colors.PRIMARY_TEXT
    },
    '& span:nth-of-type(1) > input': {
      borderBottomLeftRadius: 7,
      borderRight: 0,
      paddingLeft: 10,
      width: '100%'
    },
    '& span:nth-of-type(2) > input': {
      borderBottomRightRadius: 7,
      paddingLeft: 10,
      width: '100%'
    },
    '& span': {
      position: 'relative',
      width: '100%'
    }
  },

  dateError: {
    position: 'absolute',
    color: 'red',
    zIndex: 10,
    right: 3,
    top: '5px'
  },

  headerInformation: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    alignItems: 'center',
    width: '100%',
  },

  title: {
    color: colors.GRADIENT_ORANGE,
    fontWeight: 'bold',
    fontSize: 20,
  },

  subTitle: {
    display: 'flex',
    flexDirection: 'row',
    display: 'inline',
    color: colors.PRIMARY_TEXT,
    '& span:nth-of-type(1)': {
      fontWeight: 'bold',
      fontSize: 18,
      marginRight: 5,

    },
    '& span:nth-of-type(2)': {
      marginTop: 'auto',
    }

  },

  subInformation: {
    alignSelf: 'center',
    marginTop: '10px'
  },

  submitButton: {
    marginTop: 'auto',
    color: 'white',
    backgroundColor: colors.GRADIENT_ORANGE,
    height: 35
  },

  checkmark__circle: {
    strokeDasharray: '166',
    strokeDashoffset: '166',
    strokeWidth: '2',
    strokeMiterlimit: '10',
    stroke: colors.GRADIENT_ORANGE,
    fill: 'none',
    animation: '$stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards',
  },

  checkmark: {
    width: '56px',
    height: '56px',
    borderRadius: '50%',
    display: 'block',
    strokeWidth: '2',
    stroke: '#fff',
    strokeMiterlimit: '10',
    margin: 'auto',
    marginTop: '6%',
    boxShadow: `inset 0px 0px 0px ${colors.GRADIENT_ORANGE}`,
    animation: '$fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both',
  },

  checkmark__check: {
    transformOrigin: '50% 50%',
    strokeDasharray: '48',
    strokeDashoffset: '48',
    animation: '$stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards',
  },

  '@keyframes stroke': {
    '100%': {
      strokeDashoffset: '0',
    }
  },

  '@keyframes scale': {
    '0%, 100%': {
      transform: 'none',
    },

    '50%': {
      transform: 'scale3d(1.1, 1.1, 1)',
    }
  },

  '@keyframes fill': {
    '100%': {
      boxShadow: `inset 0px 0px 0px 30px ${colors.GRADIENT_ORANGE}`,
    }
  },








}
))



export { useStyles }