import { all, takeEvery, put, select, call, takeLatest } from 'redux-saga/effects';
import action from './action';
import { userRequest, connectionRequest, twilioRequest } from '../../service/requests';
import actionConnection from '../connection/action';
import {
  createChatClient,
  getChannel,
  getMessages,
  getUserChannels,
  listenToChannel,
  subscribeToChannelMember,
} from '../../utils/twilio';
import { HTTP_STATUS } from '../../utils/constants';
import { handleHttpError } from '../../utils/helper';

/////////////////////////
// reqInitTwilioChat()
/////////////////////////
function* reqInitTwilioChat({ payload }) {
  try {
    console.log('reqInitTwilioChat()');
    console.log('payload : ', payload);

    const userId = payload;
    const getUser = state => state.User;
    const user = yield select(getUser);
    const notificationInfo = user.notificationInfo;

    console.log('notificationInfo : ', notificationInfo);

    const res = yield userRequest.getTwilioChatToken({
      user_id      : userId,
      channel_type : 'fcm',
      user_name    : user.user.userName,
    }).catch(error =>{
      handleHttpError({
        error,
        request: 'userRequest.getTwilioChatToken::src/redux/message'
      })
    });


    console.log('getTwilioChatToken res');
    console.log(res);

    if (res) {
      const accessToken = res.data.tokenTwilio.token;
      const resChatClient = yield createChatClient(accessToken);
      const client = resChatClient;

      let conversationsObjs = [];

      const twilio = {
        client,
        conversations : conversationsObjs,
      }        

      yield put({
        type    : action.TWILIO_UPDATE_SUCCESS,
        payload : twilio
      });
    }
  } catch (error) {
    console.log('exception()');
    console.log(error);
  }
}

// /////////////////////////
// // reqMessageReceived()
// /////////////////////////
// function* reqMessageReceived({ payload }) {
//   try {
//     console.log(payload,'pipiload')
//     // const { channel, state } = payload
//     // console.log('reqMessageReceived()');
//     // console.log('payload : ', payload);
//     // console.log('state : ', state);
//     // console.log('channel : ', channel);
//     // const getUserId = state => state.Session.user.user.userId
//     // const userId = yield select(getUserId)
//     // // const messageObj = {
//     // //   _id       : state.index, // message id
//     // //   text      : state.body,
//     // //   createdAt : state.timestamp,
//     // //   user      : {
//     // //     _id     : parseInt(state.author),
//     // //   }
//     // // };
//     yield put({
//       type    : action.MESSAGE_LIST_UPDATE,
//       payload
//     });
//     // if (state.author != userId) {
//     // }
//   } catch (error) {
//     console.log('exception()');
//     console.log(error);
//   }
// }

function* getAllMessages ({payload}) {
  
  try {

    console.log('getAllMessages()');
    const client = payload ;
    let conversationsArr = [];
    const conversationResponse = yield client.conversations.getConversations()
    const conversations = conversationResponse.items
    const getUserId = state => state.Session.user.user.userId
    const userId = yield select(getUserId);
  
    const conversationParticipantIds = []
    
    for (let x = 0; x < conversations.length; x++) {
      try{
        const conversation = conversations[x]
        if (conversation.channelState.status === 'notParticipating') continue;
        const connectionParticipant = yield conversation.getParticipants()
        const regex = new RegExp(`CHANNEL|_|${userId}`, 'g')
        const connectionUserId = conversation.channelState.uniqueName.replaceAll(regex, '')
        conversationParticipantIds.push(connectionUserId)
  
        if (connectionParticipant.length === 1) {
          try {
            yield twilioRequest.addConversationParticipants({
              sid: conversation.sid,
              identity: connectionUserId
            })
          } catch (e) {
            console.log(e, 'error')
          }
        }
      }
        catch(e){
      console.log(e)
      }
    }
    
    const connectionUsers = {}
    try{
      const userIds = conversationParticipantIds.join(',')
      const connectionResponse = yield connectionRequest.usersProfile({
        connection_user_ids: userIds,
        user_id: userId
      })
      if(connectionResponse?.data?.userConnection.length > 0){
        for(let x=0; x < connectionResponse.data.userConnection.length ;x++){
          connectionUsers[connectionResponse.data.userConnection[x].user.userId] = connectionResponse.data.userConnection[x]
        }
      }

    }catch(e){
      handleHttpError({
        error: e,
        request: 'connectionRequest.usersProfile::src/redux/message'
      })
    }

    for (let x = 0; x< conversations.length; x++) {
      const conversation = conversations[x]
      if (conversation.channelState.status === 'notParticipating') continue;
      const messages       = yield conversation.getMessages();
      const messagesLength = messages.items.length;
      if (messagesLength === 0) continue;
      const conversationUniqueNameArr = conversation.channelState.uniqueName.split('_');
      const participants              = yield conversation.getParticipants();
      const lastConsumedMessageIndex  = conversation.channelState.lastMessage.index;
      const lastReadMessageIndex      = conversation.channelState.lastReadMessageIndex;
      const latestMessage             = messages.items[messagesLength - 1];
      const participantId             = (userId > parseInt(conversationUniqueNameArr[1])) ? parseInt(conversationUniqueNameArr[1]) : parseInt(conversationUniqueNameArr[2]);
      const participant               = participants.find(participantItem => parseInt(participantItem.state.identity) !== userId);
      let   participantInfo           = {};

      const latestMessageContent     = latestMessage.state.author != userId ? latestMessage.state.body : `You: ${latestMessage.state.body}`
      let userConnection             = connectionUsers[`${participantId}`]
      if (participant) {
        participantInfo = yield participant.getUser();
      }
      try {
        const conversationItem = {
          conversationName            : conversation.channelState.uniqueName,
          profilePicture              : userConnection.user.mediaProfile, 
          userName                    : userConnection.user.userName, 
          time                        : latestMessage.state.timestamp,
          isOnline                    : participant ? (participantInfo.state.online ? (participantInfo.state.attributes.is_reachable === false ? false : true) : false) : false,
          isUnseen                    : lastConsumedMessageIndex != lastReadMessageIndex,
          membershipViewabilityStatus : userConnection.userSubscription.viewabilityStatus,
          messages                    : messages.items,
          hasNextPage                 : messages.hasNextPage,
          hasPrevPage                 : messages.hasPrevPage,
          nextPage                    : messages.nextPage,
          prevPage                    : messages.prevPage,
          conversation,
          participantInfo,
          userConnection,
          latestMessageContent
        };
        conversationsArr.push(conversationItem);
      } catch(e){
        console.log(e, 'error')
      }
    }
    conversationsArr.sort((a, b) => {
      return b.time - a.time;
    });
    const twilio = {
      client,
      conversations : conversationsArr,
    };
  
    yield put({
      type    : action.CONVERSATION_UPDATE,
      payload : twilio,
      
    });

  } catch(e){
    console.log("error: ", e)
  }

};

// const getContact = (value , iViewedList, likedMeList, viewedMeList) =>  {
//   let contact = [
//     ...iViewedList,
//     ...likedMeList.map((user) => user.userConnection),
//     ...viewedMeList,
//   ].find((connection) => connection.user.userId == value);
//   return contact;
// };



export default function* rootSaga() {
  yield all([takeEvery(action.TWILIO_UPDATE,    reqInitTwilioChat)]);
  // yield all([takeEvery(action.MESSAGE_RECEIVED, reqMessageReceived)]);
  yield all([takeLatest(action.MESSAGE_GET, getAllMessages)]);
}
