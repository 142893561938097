import action from "./action";

const initState = {
  isMessageLoaded: false,
  isLoading   : true,
  twilio      : {
                  client    : null,
                  conversations  : []
                },
  messages    : [],
  messageList : [],
};

export default function messageReducer(state = initState, act) {

  switch (act.type) {

    case action.MESSAGE_GET:{
      return {
        ...state,
        isMessageLoaded     : false
      }
    }

    case action.TWILIO_UPDATE:{
      return {
        ...state,
        isLoading     : true,
      }
    }

    case action.TWILIO_UPDATE_SUCCESS:
      console.log("redux/message/reducer.js:TWILIO_UPDATE_SUCCESS");
      console.log("-- act.payload --");
      console.log(act.payload);

      return {
        ...state,
        isLoading     : false,
        twilio        : act.payload
      }
    case action.CONVERSATION_ADD:
      console.log("redux/message/reducer.js:CONVERSATION_ADD");
      
      let updatedMessageList = state.messages;

      updatedMessageList.push(act.payload);
      updatedMessageList.sort((a, b) => {
        return b.messages[b.messages.length - 1].state.timestamp - a.messages[a.messages.length - 1].state.timestamp;
      });
      
      return {
        ...state,
        isLoading  : false,
        messages   : updatedMessageList,
        twilio     : {
          ...state.twilio,
          conversations: updatedMessageList
        }
      }

    case action.MESSAGE_SAVE:
      console.log("redux/message/reducer.js:MESSAGE_SAVE");
      return {
        ...state,
        isLoading  : false,
        messages   : act.payload
      }

    case action.MESSAGE_RECEIVED:
      console.log("redux/message/reducer.js:MESSAGE_RECEIVED");
      console.log("act.payload : ", act.payload);

      // Message List Item
      let messageItem = state.messages.find(message => message.conversation.sid == act.payload.conversation.sid)
      
      messageItem.isUnseen = messageItem.participantInfo.state.identity === act.payload.state.author;
      messageItem.messages.push(act.payload);
      
      // Messages List
      let newMessages = state.messages.filter(message => message.participantInfo.state.identity !== messageItem.participantInfo.state.identity)
      
      newMessages.push(messageItem);
      newMessages.sort((a,b) => {
        return b.messages[b.messages.length - 1].state.timestamp - a.messages[a.messages.length - 1].state.timestamp;
      });

      console.log(newMessages,'newmessage')

      return {
        ...state,
        messages : newMessages
      }

    case action.MESSAGE_UPDATE_SEEN:
      console.log("redux/message/reducer.js:MESSAGE_UPDATE_SEEN");
      console.log("act.payload : ", act.payload);

      return {
        ...state,
        messages : state.messages.map(messageItem => {
          let updatedMessageItem = messageItem;
          
          if (messageItem.participantInfo.state.identity === act.payload) {
            updatedMessageItem['isUnseen'] = false;
          }

          return updatedMessageItem;
        })
      }

    case action.MESSAGE_PARTICIPANT_UPDATE:
      console.log("redux/message/reducer.js:MESSAGE_PARTICIPANT_UPDATE");
      console.log("act.payload : ", act.payload);

      return {
        ...state,
        messages : state.messages.map(messageItem => {
          let updatedMessageListItem = messageItem;
          
          if (messageItem.participantInfo.state.identity === act.payload.state.identity) {
            updatedMessageListItem['participantInfo'] = act.payload;
          }

          return updatedMessageListItem;
        })
      }

    case action.MESSAGE_USER_CONNECTION_UPDATE:
      console.log("redux/message/reducer.js:MESSAGE_USER_CONNECTION_UPDATE");
      console.log("act.payload : ", act.payload);

      return {
        ...state,
        messages : state.messages.map(messageItem => {
          let updatedMessageListItem = messageItem;
          
          if (messageItem.userConnection.user.userId === act.payload.user.userId) {
            updatedMessageListItem['userConnection'] = act.payload;
          }

          return updatedMessageListItem;
        })
      }
    
    case action.MESSAGE_LOADED_TOGGLE:
      return {
        ...state,
        isMessageLoaded: !state.isMessageLoaded
      }

    case action.CONVERSATION_UPDATE:
      return {
        ...state,
        twilio:{
          client: act.payload.client,
          conversations: act.payload.conversations
        },
        messages: act.payload.conversations,
        isMessageLoaded: true
      }


    default:
      return state
  }
}
