import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Button, Box, CircularProgress, TextField, Typography} from '@material-ui/core';
import Select from '../../../../components/Select';

import { parseString } from 'xml2js';
import countryByAbbreviation from 'country-json/src/country-by-abbreviation.json';
import useStyles from './style';
import { 
  LOOK_UP_GEOCODING_API_KEY, UPD_MODE_USER_ADDRESS, ALERT_SEVERITY_SUCCESS, TOAST_AXIOS_REQUEST_ERROR, UNITED_STATES, HTTP_STATUS} from '../../../../utils/constants';
import { userRequest, thirdPartyRequest, lookupRequest, geoCodeRequest } from '../../../../service/requests';
import lookupAction from '../../../../redux/lookup/action';
import { handleHttpError } from '../../../../utils/helper';


const Address = forwardRef((props, ref) => {
    const classes = useStyles();
    const { isSaving, setIsSaving, Lookup, setOpenedIndex, initialStateAddress, initialCityAddress, initialCountryAddress, callback, updateSession, updateUserField, user, userField, setCityLookUpTrigger, showAlert } = props;

    // const [isSaving, setIsSaving] = useState(false);
    const countryNames = Lookup.lookUpCountry ? Lookup.lookUpCountry : [];
    const stateNames = Lookup.lookUpState ? Lookup.lookUpState : [];
    const cityNames = Lookup.lookUpCity ? Lookup.lookUpCity : [];
    const [matchedCountries, setMatchedCountries] = useState([]);
    const [requiredFields, setRequiredFields] = useState(false);

    const [state, setState] = useState({
      zipCode           : userField.address.zipCode,
      isZipCodeError    : false,
      zipCodeErrorDesc  : '',
      city              : '',
      isCityError       : false,
      cityErrorDesc     : '',
      stateAddress      : '',
      isStateError      : false,
      stateErrorDesc    : '',
      country           : '',
      isCountryError    : false,
      countryErrorDesc  : '',
      geocodeApiKey     : '',
      selectedCountry   : {
                        label:'',
                        value:'',
                        code:''
      },
      selectedState     : {
                        label:'',
                        value:'',
                        code:''
      },
      selectedCity     : {
                        label:'',
                        value:'',
                        code:'',
      searchCity       : false,
      },
      isLoading        : true,
      gettingCityandState : false
    });

    const { 
      zipCode, isZipCodeError, zipCodeErrorDesc,
      city, isCityError, cityErrorDesc, 
      stateAddress, isStateError, stateErrorDesc,
      country, isCountryError, countryErrorDesc, geocodeApiKey
    } = state;

    useEffect(()=> {
      userField.address.country == UNITED_STATES ?
      setState({
        ...state,
        selectedState : {
          value: initialStateAddress.id,
          label: initialStateAddress.name, 
          code:  initialStateAddress.code
        },
        selectedCity : {
          value: initialCityAddress.id,
          label: initialCityAddress.name,
          code: initialCityAddress.code
        },
        selectedCountry : {
          value: initialCountryAddress.id,
          label: initialCountryAddress.name,
          code: initialCountryAddress.code,
        },
        stateAddress : initialStateAddress.name,
        city : initialCityAddress.name,
        country : initialCountryAddress.name, 
      })
      :
      setState({
        ...state,
        selectedCountry : {
          value: initialCountryAddress?.id,
          label: initialCountryAddress?.name,
          code: initialCountryAddress?.code,
        },
        selectedState     : {
                  label:'',
                  value:'',
                  code:''
        },
        selectedCity     : {
                  label:'',
                  value:'',
                  code:'',
        },
        stateAddress : userField.address.state,
        city :  userField.address.city ,
        country : userField.address.country, 
      })
    },[initialStateAddress, initialCityAddress, initialCountryAddress])
    useEffect(()=>{
      //  if(city != '' && country !='' && stateAddress !=''){
          setState({
            ...state,
            isLoading: false,
          })
      //  }
    },[city, country, stateAddress])

    useEffect(() => {
      console.log('Address.useEffect()');
      
      if (zipCode?.length === 5 && country == UNITED_STATES) {  
        setState({
          ...state,
          gettingCityandState: true,
        })
        getCityAndState();
      }
  
      let matchedCountries = []
  
      countryByAbbreviation.map(countryItem => {
        if (countryItem.country.toLowerCase().match(country.toLowerCase())) {
          matchedCountries.push(countryItem);
        }
      });
  
      setMatchedCountries(matchedCountries);
  
      // this.setState(prevState => ({ 
      //   hasCountryError    : prevState.hasCountryError ? !prevState.hasCountryError : prevState.hasCountryError,
      //   hasCountrySelected : false, 
      // }));
  
    }, [zipCode]);
  
    useEffect(() => {
      callback(zipCode === '' || zipCode?.length < 5 || city === '' || stateAddress === '' || country === '');
    }, [isSaving])
  
  
    useEffect(() => {
      (async function(){
        const result = await lookupRequest.getCredentialLookup(LOOK_UP_GEOCODING_API_KEY).catch(error => {
          handleHttpError({
            error,
            request: 'lookupRequest.getCredentialLookup::src/views/settings/my-profile/address'
          })
        })
      setState({...state, geocodeApiKey : result.data.credential.value})
      
      })();
    },[]);
  
  
    const getCityAndState = () => {
      const zipCodeXML = `<CityStateLookupRequest USERID="699MPHIN4829"><ZipCode ID="0"><Zip5>${zipCode}</Zip5></ZipCode></CityStateLookupRequest>`;
  
      thirdPartyRequest.getCityState({
        XML : zipCodeXML
      }).then(response => {
        console.log('response : ', response);
  
        parseString(response.data, (error, result) => {
          console.log('result : ', result);
          console.log('error  : ', error);
  
          if (result.CityStateLookupResponse.ZipCode[0].Error == undefined) {
            
            const selectedState = stateNames.find(state=>state.code.trim().toLowerCase() === result.CityStateLookupResponse.ZipCode[0].State[0].trim().toLowerCase())
            props.setCityLookUpTrigger({value:selectedState.id})
            console.log("response",selectedState)
            setState({
              ...state,
              city  : result?.CityStateLookupResponse?.ZipCode[0]?.City[0].toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
              stateAddress : result?.CityStateLookupResponse?.ZipCode[0]?.State[0],
              selectedState : {
                value: selectedState.id,
                label: selectedState.name,
                code: selectedState.code
              },
              searchCity: true,
              gettingCityandState : false,
            });
            // this.setState(prevState => ({
            //   hasZipCodeError       : prevState.hasZipCodeError ? !prevState.hasZipCodeError : prevState.hasZipCodeError,
            //   zipCodeLookupResponse : result.CityStateLookupResponse.ZipCode[0]
            // }));
          } else {
            setState({
              ...state,
              city  : '',
              stateAddress : '',
              gettingCityandState : false,
            });
            // this.setState({
            //   zipCodeLookupResponse : result.CityStateLookupResponse.ZipCode[0]
            // });
          }
        });
  
      }).catch(error => { 
        handleHttpError({
          error,
          request: 'thirdPartyRequest.getCityState::src/views/settings/my-profile/address'
        })
      });
    }

    useEffect(()=>{
      if(state.searchCity && cityNames.length > 0 ){
        const selectedCity = cityNames.find(city=>state.city.trim().toLowerCase() === city.name.trim().toLowerCase())
        setState({
          ...state,
          searchCity: false,
          selectedCity:{
            label:selectedCity.name,
            value:selectedCity.id
          }
        });
      }
    },[cityNames])
  
    const handleInputChange = event => {
  
      let value = event.target.value;
      value = value.replace(/[^0-9]/g, '');
      if (event.target.name == 'zipCode') {
       

        if (value.length > 5)
          return
          setState({
            ...state,
            [event.target.name]: value,
            selectedState     : {
              label:'',
              value:'',
              code:''
            },
            selectedCity     : {
              label:'',
              value:'',
              code:'',
            },
            stateAddress: '',
            city: '',
          });
          return
      }
  
      setState({
        ...state,
        [event.target.name]: event.target.value
      });
    };

    const getCoordinates = async (address) => { 
      console.log('getCoordinates()')
      const response = await geoCodeRequest.getGeoCodeAddress({
        address,
        key            : geocodeApiKey
      }).catch(error => {
        handleHttpError({
          error,
          request: 'geoCodeRequest.getGeoCodeAddress::src/views/settings/my-profile/address'
        })
      })
      const { lat, lng } = await response?.data?.results[0]?.geometry?.location;
  
      return {lat, lng}
    }
  
    const validateAddress = () => {
      if (zipCode === '' || city === '' || stateAddress === '' || country === '' ) {
        setState({
          ...state,
          isZipCodeError    : zipCode === '' ? true : false,
          zipCodeErrorDesc  : zipCode === '' ? 'Required Postal Code' : '',
          isCityError       : city === '' ? true : false,
          cityErrorDesc     : city === '' ? 'Required City Address' : '',
          isStateError      : stateAddress === '' ? true : false,
          stateErrorDesc    : stateAddress === '' ? 'Required State Address' : '',
          isCountryError    : country === '' ? true : false,
          countryErrorDesc  : country === '' ? 'Required Country' : '',
        });
        setRequiredFields(true)
        // throw new Error("Validation failed");
        return true
      } else {
        setState({
          ...state,
          isZipCodeError : false,
          isCityError    : false,
          isStateError   : false,
          isCountryError : false,
        })
        return false
      }
    }
  
  const saveAddress = async () => {
      console.log("saveAddress");
      
      if(!validateAddress()){
        setIsSaving(true);
        const {lat, lng} = await getCoordinates({zipCode : zipCode, city : city, stateAddress : stateAddress, country : country})
        userRequest.updateUser({
          user_id       : user.userId,
          upd_mode      : UPD_MODE_USER_ADDRESS,
          who_updated   : user.userId,
          zip_code      : zipCode,
          city          : city,
          state         : stateAddress,
          country       : country,
          latitude      : lat,
          longitude     : lng
        }).then(response => {
          console.log('response : ', response);
          if (response?.data?.status?.code != HTTP_STATUS._200) {
            let { status } = response?.data;
            throw new Error(`${status?.code} ${status?.description}`);
          }
          updateSession(response?.data?.userDetail);
          updateUserField(response?.data?.userDetail?.userField)
          showAlert(ALERT_SEVERITY_SUCCESS, 'Location has successfully updated.');
        }).catch(error => {
          handleHttpError({
            error,
            request: 'userRequest.updateUser::src/views/settings/my-profile/address'
          })
        }).finally(() => {
          setState({
            ...state,
            // isZipCodeError : false,
            // isCityError    : false,
            // isStateError   : false,
            // isCountryError : false,
            isZipCodeError    : zipCode === '' ? true : false,
            zipCodeErrorDesc  : zipCode === '' ? 'Required Postal Code' : '',
            isCityError       : city === '' ? true : false,
            cityErrorDesc     : city === '' ? 'Required City Address' : '',
            isStateError      : stateAddress === '' ? true : false,
            stateErrorDesc    : stateAddress === '' ? 'Required State Address' : '',
            isCountryError    : country === '' ? true : false,
            countryErrorDesc  : country === '' ? 'Required Country' : '',
          })
          setIsSaving(prevIsSaving => !prevIsSaving);
          setOpenedIndex(prevOpenedIndex => !prevOpenedIndex);
        }) 
    }  
  }
    
    useImperativeHandle(ref, () => ({
      async triggerSave() {
        await saveAddress();
      },
    }));

    const selectChange = (field, e)=>{
   
      if(field === 'country'){
        setState({
          ...state,
          zipCode: '',
          city: '',
          stateAddress: '',
          selectedCountry: e,
          selectedState:{
            label:'',
            value:'',
            code:''
          },
          selectedCity:{
            label:'',
            value:'',
          },
          [field]: e.label
        })
        setRequiredFields(false)
      }
  
      if(field === 'stateAddress') {
        setState({
          ...state,
          city: '',
          selectedState: e,
          selectedCity:{
            label:'',
            value:'',
          },
          [field]: e.label
        })
      }
  
      if(field === 'city') {
        setState({
          ...state,
          selectedCity:e,
          [field]: e.label,
        })
      }
    }
  
    return (

      <Box className={classes.addressContainer}>
        {
        state.isLoading ? 
          <CircularProgress className={classes.loading}/>
        :
          <>
        <Select
          options={countryNames.map((country)=>{
            return {
              label: country.name,
              value: country.id,
              code: country.code,
            }
          })}
          className={classes.selectBox} 
          inputProps={{
            placeholder:"Select Country",
            onChange:(e)=>{
              selectChange('country', e)
            },
            value:state.selectedCountry
          }}
        />
        <TextField
          label="Postal Code"
          name="zipCode"
          value={zipCode}
          onChange={handleInputChange}
          className={classes.textField}
          required
          error={isZipCodeError}
          helperText={zipCodeErrorDesc}
          variant="outlined"
          size="small"
          maxLength={5}
        />
        {state.country !== UNITED_STATES && 
        <>
          <TextField
          label="City"
          name="city"
          value={city}
          onChange={handleInputChange}
          className={classes.textField}
          required
          error={isCityError}
          helperText={cityErrorDesc}
          variant='outlined'
          size='small'
        />
         <TextField
          label="State"
          name="stateAddress"
          value={stateAddress}
          onChange={handleInputChange}
          className={classes.textField}
          required
          error={isStateError}
          helperText={stateErrorDesc}
          variant='outlined'
          size='small'
        />
        </>
        }
       {state.country?.trim().toLowerCase() == UNITED_STATES.trim().toLowerCase() && 
       <>

        <Select
          options={stateNames.map((state)=>{
            return {
              label: state.name,
              value: state.id,
              code: state.code,
            }
          })}
          className={classes.selectBox}
          isLoading={state.gettingCityandState}
          inputProps={{      
            placeholder:"Select State*",
            onChange:(e)=>{
              console.log(stateAddress)
              selectChange('stateAddress', e)
               props.setCityLookUpTrigger({value: e.value})
            },
            value: state.selectedState?.value !== '' ? state.selectedState : null
          }}
        />
        { isStateError ? <Typography className={classes.helperText}>Required State Address</Typography>: null}
        {cityNames.length > 0 ? 
        <>
        <Select
          options={cityNames.map((city)=>{
            return {
              label: city.name,
              value: city.id,
            }
          })}
          inputProps={{
            value:state.selectedCity.value !== '' ? state.selectedCity : null,
            placeholder:"Select City*",
            onChange:(e)=>{
              selectChange('city', e)
            }
          }}
          className={classes.selectBox}
          isLoading={state.gettingCityandState}
         /> 
         {isCityError ? <Typography className={classes.helperText}>Required City Address</Typography>: null}
        </> 
        : 
          <TextField
          label="City"
          name="city"
          value={city}
          onChange={handleInputChange}
          className={classes.textField}
          required
          error={isCityError}
          helperText={cityErrorDesc}
          variant='outlined'
          size='small'
        />
        }
        </>
       }
          </>
        }
        <Button disabled={requiredFields  || isSaving || state.gettingCityandState} className={classes.buttonSave} onClick={saveAddress} color="primary" variant="contained">
          {
            isSaving ?
              <CircularProgress size={24}/>
            : 
            'Save'
            }
        </Button>
      </Box>
    )
  })

export default Address;
