import { all, takeEvery, put, call, select } from 'redux-saga/effects';

import action             from './action';
import connectionAction   from '../connection/action';
import notificationAction from '../notification/action';
import { searchRequest }  from '../../service/requests'

import { 
  LOOK_UP_INCOME_LEVEL,
  UPD_MODE_SEARCH_INCOME_LEVEL,
} from '../../utils/constants';
import { handleHttpError } from '../../utils/helper';

/////////////////////////
// reqSearchGet()
/////////////////////////
function* reqSearchGet() {
  try {
    console.log('reqSearchGet()');

    const getUserId = state => state.Session.user.user.userId;
    const userId = yield select(getUserId);
    const response = yield searchRequest.getSearch({
      user_id: userId
    }).catch(error => {
      handleHttpError({
        error,
        request: 'searchRequest.getSearch::src/redux/search'
      })
    });
    console.log('response : ', response);

    if (response) {
      const searches      = response.data.searches;
      const unviewedCount = searches.flatMap(search => search.users).filter(user => !user.isViewed).length
      
      yield put({
        type    : notificationAction.NOTIFICATION_SEARCH_UPDATE,
        payload : unviewedCount
      });
      
      yield put({
        type    : action.SEARCH_GET_SUCCESS,
        payload : searches
      });

    }
  } catch (error) {
    console.log('error : ', error);
    yield put({
      type    : action.SEARCH_GET_ERROR
    });
  }
}

/////////////////////////
// reqSearchAdd()
/////////////////////////
function* reqSearchAdd({ payload }) {
  try {
    console.log('reqSearchAdd()');
    console.log('payload : ', payload);

    const response = yield searchRequest.addSearch(payload).catch(error => {
      handleHttpError({
        error,
        request: 'searchRequest.addSearch::src/redux/search'
      })
    });
    console.log('response : ', response);

    if (response) {
      yield put({
        type    : action.SEARCH_ADD_SUCCESS,
        payload : response.data.search
      });
    }
  } catch (error) {
    console.log('error :', error);
    yield put({
      type    : action.SEARCH_ADD_ERROR,
      payload : error
    });
  }
}

/////////////////////////
// reqSearchUpdate()
/////////////////////////
function* reqSearchUpdate({ payload }) {
  try {
    console.log('reqSearchUpdate()');
    console.log('payload : ', payload);

    const { search, searchId } = payload;

    const response = yield searchRequest.updateSearch(search, searchId).catch(error=>{
      handleHttpError({
        error,
        request: 'searchRequest.updateSearch::src/redux/search'
      })
    });
    console.log('response : ', response);

    if (response) {
      yield put({
        type    : action.SEARCH_UPDATE_SUCCESS,
        payload : response.data.search
      });
    }
  } catch (error) {
    console.log('error : ', error)
    yield put({
      type    : action.SEARCH_UPDATE_ERROR,
      payload : error
    });
  }
}

/////////////////////////
// reqSearchUpdateCriteria()
/////////////////////////
function* reqSearchUpdateCriteria({ payload }) {
  try {
    console.log('reqSearchUpdateCriteria()');
    console.log('payload : '+ payload);

    const { searchId, criteriaType, code } = payload;

    if(criteriaType == LOOK_UP_INCOME_LEVEL) {
      return false
    }

    const getUser = state => state.Session.user
    const User = yield select(getUser)

    

    const response =  yield searchRequest.updateSearchCriteria({
        user_id : User.user.userId,
        code    : code
      },
        searchId,
        criteriaType
      ).catch(error => {
        handleHttpError({
          error,
          request: 'searchRequest.updateSearchCriteria::src/redux/search'
        })
      });

    if (response) {
      yield put({
        type    : action.SEARCH_UPDATE_SUCCESS,
        payload : response.data.search
      });
    }
  } catch (error) {
    console.log('error : ', error);
    yield put({
      type    : action.SEARCH_UPDATE_ERROR
    });
  }
}

/////////////////////////
// reqSearchDelete()
/////////////////////////
function* reqSearchDelete({ payload }) {
  try {
    console.log('reqSearchDelete()');
    console.log('payload : ', payload);

    const { search, searchId } = payload;

    const response = yield searchRequest.deleteSearch(search, searchId).catch(error=> {
      handleHttpError({
        error,
        request: 'searchRequest.deleteSearch::src/redux/search'
      })
    });
    console.log('response : ', response);

    if (response) {
      yield put({
        type    : action.SEARCH_DELETE_SUCCESS,
        payload : searchId
      });
    }
  } catch (error) {
    console.log('error : ', error);
    yield put({
      type    : action.SEARCH_DELETE_ERROR,
      payload : error
    });
  }
}

export default function* rootSaga() {
  yield all([takeEvery(action.SEARCH_GET_REQUEST, reqSearchGet)]);
  yield all([takeEvery(action.SEARCH_ADD_REQUEST, reqSearchAdd)]);
  yield all([takeEvery(action.SEARCH_UPDATE_REQUEST, reqSearchUpdate)]);
  yield all([takeEvery(action.SEARCH_UPDATE_CRITERIA, reqSearchUpdateCriteria)]);
  yield all([takeEvery(action.SEARCH_DELETE_REQUEST, reqSearchDelete)]);
}
