import { makeStyles } from '@material-ui/styles';
import colors from '../../../utils/colors';

const useStyles = makeStyles(theme => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
    },
    li: {
      listStyle: 'none',
    },
  },

  // TABS 
  container: {
    width: 'fit-content'
  },

  tab: {
    color: colors.WHITE,
    fontWeight: 'bold',
    textTransform: 'capitalize'
  }
}));

export default useStyles;