import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import PinInput from 'react-pin-input';
import { Avatar, Box, Button, Checkbox, Container, FormControlLabel, Grid, Hidden, Link, TextField, List, ListItem, ListItemIcon, ListItemText, Typography, CircularProgress } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import LiveHelpOutlinedIcon from '@material-ui/icons/LiveHelpOutlined';
import LeftLayout from '../../components/left-layout';
import useStyles from './style';
import { userRequest } from '../../service/requests';
import colors from '../../utils/colors';
import { ALERT_SEVERITY_ERROR, TOAST_AXIOS_REQUEST_ERROR, TOAST_VERIFICATION_CODE_SENT, ALERT_SEVERITY_SUCCESS, TOAST_PASSWORD_CHANGED, ALERT_SEVERITY_INFO, HTTP_STATUS, TOAST_INVALID_PASSWORD } from '../../utils/constants';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';
import { handleHttpError } from '../../utils/helper';

const Verification = (props) => {
  const classes = useStyles();
  const { email, showAlert, next } = props;

  const [isVerifying, setIsVerifying] = useState(false);
  const [isResending, setIsResending] = useState(false);
  const [state, setState] = useState({
    code          : '',
    isCodeError   : false,
    codeErrorDesc : '',
    isPasswordVisible: false,
    isConfirmPasswordVisible: false,
  });

  const { code, isCodeError, codeErrorDesc } = state;

  useEffect(() => {
    code.length === 6 && verifyCode();
  }, [code])

  const resendCode = () => {
    console.log('VerifyCode.resendCode()');

    setState({
      ...state,
      code          : '',
      isCodeError   : false,
      codeErrorDesc : ''
    });

    setIsResending(prevIsResending => !prevIsResending);
    userRequest.verifyResetPasswordEmail({
      email : email
    }).then(response => {
      console.log('response : ', response);

      if (response?.data?.status?.code == HTTP_STATUS._200) {
        setIsResending(prevIsResending => !prevIsResending);
        showAlert(ALERT_SEVERITY_SUCCESS, 'We sent a new code to your phone number to verify your account.');
      }
    }).catch(error => {
      setIsResending(prevIsResending => !prevIsResending);
      handleHttpError({
        error,
        request: 'userRequest.verifyResetPasswordEmail::src/views/forgot-password'
      })
    });
  }

  const verifyCode = () => {
    console.log('VerifyCode.verifyCode()');

    setIsVerifying(prevIsVerifying => !prevIsVerifying);
    if (code != '') {
      showAlert(ALERT_SEVERITY_INFO, 'Verifying code please wait.');
      userRequest.verifyCode({
        conf_code : code,
      }).then(response => {
        console.log('response : ', response);

        setIsVerifying(prevIsVerifying => !prevIsVerifying);
        if (response?.data?.status?.code == HTTP_STATUS._200) {
          setState({
            ...state,
            isCodeError   : false,
            codeErrorDesc : response.data.description
          });
          next(code);
          showAlert(ALERT_SEVERITY_SUCCESS, 'Verification code successfully verified.');
        } else {
          setState({
            ...state,
            isCodeError   : true,
            codeErrorDesc : response.data.description
          });
        }
      }).catch(error => {
        setIsVerifying(prevIsVerifying => !prevIsVerifying);
        handleHttpError({
          error,
          request: 'userRequest.verifyCode::src/views/forgot-password'
        })
      }); 
    }
  }

  const handleInputChange = (value) => {
    const updatedValue = value.replace(/[^0-9]/g, '');
    if (updatedValue.length > 6) return;

    setState({
      ...state,
      code : updatedValue,
    });
  };

  return (
    <>
      <VerifiedUserOutlinedIcon style={{color: colors.TEXT_GRAY}} fontSize="large"/>
      <Typography variant="h6" color="textPrimary" className={`text-bold`}>
        Verify Account
      </Typography>
      <Typography variant="subtitle2" className={`text-center`} color="textPrimary">
        We sent a code to your mobile phone number
      </Typography>
      <Typography variant="subtitle2" className={`text-center`} color="textPrimary">
        Enter your code here
      </Typography>
      <Box className={classes.form}>
        <PinInput 
          length={6}
          initialValue={code}
          onChange={handleInputChange}
          type="numeric" 
          inputMode="number"
          autoSelect={true}
        />
        { isCodeError && <Typography color={'error'}>{codeErrorDesc}</Typography>}
        <Button
          variant="outlined"
          color="primary"
          className={classes.submit}
          onClick={resendCode}
          fullWidth
        >
          {
            isResending ?
              <CircularProgress color="white" size={24}/>
            : 
              'Resend Code'
          }
        </Button>
      </Box>
    </>
  )
}

const NewPassword = (props) => {
  const classes = useStyles();
  const history = useHistory();  
  const { showAlert, user, code } = props;

  const [isSaving, setIsSaving] = useState(false);
  const [state, setState] = useState({
    password                 : '',
    isPasswordError          : false,
    passwordErrorDesc        : '',
    confirmPassword          : '',
    isConfirmPasswordError   : false,
    confirmPasswordErrorDesc : '',
    isPasswordPassed         : false, 
    passwordCriteria         : {
      has8Characters       : false,
      has1Lowercase        : false,
      has1Uppercase        : false,
      has1Number           : false,
      has1SpecialCharacter : false
    }
  });

  const { 
    password, confirmPassword, 
    isPasswordError, passwordErrorDesc, 
    isConfirmPasswordError, confirmPasswordErrorDesc, 
    passwordCriteria, isPasswordPassed
  } = state;

  useEffect(() => {
    if (password !== confirmPassword  && password !== ''  && confirmPassword !== '') {
      setState({
        ...state,
        isConfirmPasswordError: true,
        confirmPasswordErrorDesc: 'Password mismatched.'
      });
    } else {
      setState({
        ...state,
        isConfirmPasswordError: false,
        confirmPasswordErrorDesc: ''
      });
    }
  }, [ password,confirmPassword ])

  const handleInputChange = (event) => {

    const inputValue = event.target.value;
    
    let newState = {
      ...state,
      [event.target.name]: inputValue,
    }

    if (event.target.name === 'password') {
      const has8Characters       = inputValue.length >= 8;
      const has1Lowercase        = /[a-z]/.test(inputValue);
      const has1Uppercase        = /[A-Z]/.test(inputValue);
      const has1Number           = /[0-9]/.test(inputValue);
      const has1SpecialCharacter = /[~`!@#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/.test(inputValue);

      newState = {
        ...newState,
        isPasswordPassed : has8Characters && has1Lowercase && has1Uppercase && has1Number && has1SpecialCharacter,
        passwordCriteria : {
          has8Characters,
          has1Lowercase,
          has1Uppercase,
          has1Number,
          has1SpecialCharacter
        }
      }
    }

    setState({
      ...newState
    });
  }

  const savePassword = async () => {
    console.log('NewPassword.savePassword()');

    setState({
      ...state,
      isPasswordError           : password === '' ? true : false,
      passwordErrorDesc         : password === '' ? 'Required.' : '',
      isConfirmPasswordError    : confirmPassword === '' || password !== confirmPassword ? true : false,
      confirmPasswordErrorDesc  : confirmPassword === '' ? 'Required.' : password !== confirmPassword ? 'Password mismatched.' : '',
    });

    if (password === '' || confirmPassword === '') {
      return
    } else if (password !== confirmPassword) {
      showAlert(ALERT_SEVERITY_ERROR, 'Password mismatched.');
      return
    } else if (!isPasswordPassed) {
      showAlert(ALERT_SEVERITY_ERROR, 'Password is weak. Create a stronger password.');
      return
    }

    setIsSaving(prevIsSaving => !prevIsSaving);
    const signInResponse = await userRequest.signIn({ email: user.email, password: password })
    .catch( error => handleHttpError({ error, request: 'userRequest.signIn::src/views/forgot-password' }));
    console.log('signInResponse:',signInResponse);

    if (signInResponse?.data?.status?.code == HTTP_STATUS._200) {
      showAlert(ALERT_SEVERITY_ERROR, TOAST_INVALID_PASSWORD);
      setIsSaving(prevIsSaving => !prevIsSaving);
    } else if(signInResponse?.data?.status?.code == HTTP_STATUS._401){
      const resetPasswordResponse = await userRequest.resetPassword({
        user_id     : user.userId,
        password    : password,
        who_updated : user.userId,
        phone_no    : user.phoneNo,
        conf_code   : code
      }).catch(error => {
        setIsSaving(prevIsSaving => !prevIsSaving);
        handleHttpError({ error, request: 'userRequest.resetPassword::src/views/forgot-password' })
      });
      console.log('resetPasswordResponse : ', resetPasswordResponse);
      if (resetPasswordResponse?.data?.code == HTTP_STATUS._200) {
        setIsSaving(prevIsSaving => !prevIsSaving);
        showAlert(ALERT_SEVERITY_SUCCESS, TOAST_PASSWORD_CHANGED);
        history.push('/?signin=true');
      } 
    }
  }

 
  const handleClickShowPassword = () => {
    setState({ ...state, isPasswordVisible: !state.isPasswordVisible });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClickShowConfirmPassword = () => {
    setState({ ...state, isConfirmPasswordVisible: !state.isConfirmPasswordVisible });
  }

  return (
    <>
      <LockOutlinedIcon style={{color: colors.TEXT_GRAY}} fontSize="large"/>
      <Typography variant="h5" color="textPrimary">
        New Password
      </Typography>
      <Typography variant="subtitle2" color="textPrimary">
        Enter your new password.
      </Typography>
      <Grid container justify="center">
        <Grid item lg={5}>
          <Box className={classes.form} >
            <TextField
              required
              label={'Password'}
              name={'password'}
              type={state.isPasswordVisible ? 'text' : 'password'}
              value={password}
              onChange={handleInputChange}
              className={classes.textField}
              size="small"
              error={isPasswordError}
              helperText={passwordErrorDesc}
              variant='outlined'

              InputProps={{              
                endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                  >
                    {state.isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
                )
              }}

            />
            <TextField
              required
              label={'Confirm Password'}
              name={'confirmPassword'}
              type={state.isConfirmPasswordVisible ? 'text' : 'password'}
              value={confirmPassword}
              onChange={handleInputChange}
              className={classes.textField}
              size="small"
              error={isConfirmPasswordError}
              helperText={confirmPasswordErrorDesc}
              variant='outlined'

              InputProps={{              
                endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowConfirmPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge='end'
                  >
                    {state.isConfirmPasswordVisible ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
                )
              }}

            />
          </Box>
        </Grid>
        <Grid item lg={5} className={classes.passwordStrengthContainer}>
          <Box className={classes.passwordStrength}>
            <Typography>Password must contain: </Typography>
            <List>
              <ListItem dense>
                <ListItemIcon>
                  {
                      passwordCriteria.has8Characters ?
                      <CheckIcon fontSize="small" className={classes.checkIconGreen} />
                    : 
                      <CloseIcon fontSize="small" color="error"/>
                  }
                </ListItemIcon>
                <ListItemText primary="8 characters" />
              </ListItem>
              <ListItem dense>
                  <ListItemIcon>
                  {
                      passwordCriteria.has1Lowercase ?
                      <CheckIcon fontSize="small" className={classes.checkIconGreen} />
                    : 
                      <CloseIcon fontSize="small" color="error"/>
                  }
                </ListItemIcon>
                <ListItemText primary="1 lowercase" />
              </ListItem>
              <ListItem dense>
                <ListItemIcon>
                  {
                      passwordCriteria.has1Uppercase ?
                      <CheckIcon fontSize="small" className={classes.checkIconGreen} />
                    : 
                      <CloseIcon fontSize="small" color="error"/>
                  }
                </ListItemIcon>
                <ListItemText primary="1 uppercase" />
              </ListItem>
              <ListItem dense>
                  <ListItemIcon>
                  {
                      passwordCriteria.has1Number ?
                      <CheckIcon fontSize="small" className={classes.checkIconGreen} />
                    : 
                      <CloseIcon fontSize="small" color="error"/>
                  }
                  </ListItemIcon>
                <ListItemText primary="1 number" />
              </ListItem>
              <ListItem dense>
                <ListItemIcon>
                  {
                      passwordCriteria.has1SpecialCharacter ?
                      <CheckIcon fontSize="small" className={classes.checkIconGreen} />
                    : 
                      <CloseIcon fontSize="small" color="error"/>
                  }
                </ListItemIcon>
                <ListItemText primary="1 special character" />
              </ListItem>
            </List>
          </Box>
        </Grid>
      </Grid>
      <Box className={classes.form}>
        <Button 
          variant="contained"
          color="primary" 
          onClick={savePassword} 
          className={classes.submit}
          fullWidth
        >
          {
            isSaving ?
              <CircularProgress color="white" size={24}/>
            : 
              'Save'
          }
        </Button>
      </Box>
    </>
  )
}

const ForgotPassword = (props) => {
  const classes = useStyles();
  
  const [component, setComponent] = useState(0);
  const [code, setCode]           = useState('');
  const handleNext = (code) => {
    setCode(code);
    setComponent(component + 1);
  }

  const forgotPasswordComponent = [
    <Verification {...props} next={handleNext} key={0}/>,
    <NewPassword  {...props} key={1} code={code}/>
  ]

  return (
    <Grid container className={classes.container}>
      <Hidden smDown>
        <Grid item xs={12} md={6} >
          <LeftLayout/>
        </Grid>
      </Hidden>
      <Grid item xs={12} md={6} >
        <Box className={classes.paper}>
          {
            forgotPasswordComponent.filter((value, index) => index === component)
          }
        </Box>
      </Grid>
    </Grid>
  );
}

/////////////////////////////
// mapStateToProps()
/////////////////////////////
const mapStateToProps = (state) => {
  console.log('registration/index.js:mapStateToProps(state)');
  console.log(state);
  return {
    user  : state.Session.user.user,
    email : state.User.user.email
  };
}

export default connect(mapStateToProps, {  })(
  ForgotPassword
);