import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../utils/colors';

const useStyles = makeStyles(theme => ({

  gridItem: {
    width             : '100%',
  },

  verifyReferral: {
    alignItems        : 'center',
    display           : 'flex',
    flexDirection     : 'column',
    height            : '100%',
    justifyContent    : 'space-around',
  },

  container: {
    alignItems        : 'center',
    display           : 'flex',
    flexDirection     : 'column'
  },

  margin: {
    margin            : 20
  },

  eventDetailsInput: {
    textAlign         : 'center',
    backgroundColor   : 'rgba(0,0,0,0)',
  },

  textField: {
    width             : 270,
    '& .MuiFormGroup-root .MuiFormControlLabel-root .MuiTypography-root': {
      color           : colors.TEXT_GRAY
    },
    '& > .MuiFormHelperText-root': {
      color           : 'red'
    }
  },

}));

export default useStyles;