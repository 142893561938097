import React from 'react';
import { Typography } from '@material-ui/core/'
import useStyles from './style';

const Title = ({title}) => {
  const classes = useStyles();

  return (
    <Typography variant="h5" color="textPrimary" noWrap className={classes.title}>
      {title}
    </Typography>
  )
}

export default Title;