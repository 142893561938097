import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Box, Container, Grid, Typography, CircularProgress } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';

import useStyles from './style';
import ChatLayout from '../../../components/chat-layout';
import Empty from '../../../components/empty';
import MessageCenterCard from '../../../components/message-center-card';
import Preloader from '../../../components/preloader';

import connectionAction from '../../../redux/connection/action';
import messageAction from '../../../redux/message/action';
import NO_NOTIFICATION_IMG from '../../../assets/images/no_notifications.png';
import NO_CALL_HISTORY_IMG from '../../../assets/images/no_calls.png'
import NO_CHAT_IMG from '../../../assets/images/no_chats.png'
import NotificationCard from '../../../components/notification-card'
import { useBottomScrollListener } from 'react-bottom-scroll-listener';

import {
  CALENDAR_INVITE_ACCEPTED,
  CALENDAR_INVITE_SENT,
  CALL_ACTION_COMPLETED,
  CALL_ACTION_DECLINE,
  CALL_ACTION_MISSED,
  CALL_CARD,
  CALL_STATUS_INBOUND_AUDIO,
  CALL_STATUS_INBOUND_VIDEO,
  CALL_STATUS_MISSED_AUDIO,
  CALL_STATUS_MISSED_VIDEO,
  CALL_STATUS_OUTBOUND_AUDIO,
  CALL_STATUS_OUTBOUND_VIDEO,
  CALL_TYPE_AUDIO,
  CALL_TYPE_VIDEO,
  COMMUNICATION_TYPE_VIDEO,
  EMPTY_CHAT,
  NO_NOTIFICATION,
  NO_NOTIFICATION_DESC,
  NOTIFICATION_CARD,
  PRELOADER_CALL_CARD,
  PRELOADER_MESSAGE_CARD,
  PRELOADER_NOTIFICATION_CARD,
  NO_NOTIFICATION_HISTORY_TITLE,
  NO_CALL_HISTORY_TITLE,
  NO_CALL_HISTORY_DESC,
  NO_CHAT_HISTORY_TITLE,
  NO_CHAT_HISTORY_DESC,
  COMMUNICATION_TYPE_AUDIO
} from '../../../utils/constants'
import { callRequest, multipleRequest } from '../../../service/requests';
import notificationAction from '../../../redux/notification/action'
const { getNotificationCallLogs } = notificationAction
const CallLogs = (props) => {

  const {
    user,
    userId,
    isOnBottom,
    setIsOnBottom,
    location,
    isCallLoading,
    callLogs,
    handleClickNotification,
    value
  } = props;

  //mapstateprops
  const {
    notificationCallLogList,
    isNotificationLogsLoading,
    isNotificationLogsLoaded,
    callLogStatus,
    getNotificationCallLogs,
    notificationCallLogPage
  } = props


  const classes = useStyles()
  const ref = useRef()

  useEffect(() => {
    if (!location.state) return;
    if (!isNotificationLogsLoaded) {
      getNotificationCallLogs({ userId, pageCount: notificationCallLogPage, user })
    }
  }, []);

  useEffect(() => {
    if ((!isNotificationLogsLoading && isNotificationLogsLoaded) && isOnBottom && value === 2) {
      getNotificationCallLogs({ userId, pageCount: notificationCallLogPage, user });
      if (ref.current) {
        setTimeout(() => {
          ref.current.scrollIntoView({ behavior: "smooth" })
        }, 1)
      }
    }
    setIsOnBottom(false)
  }, [isOnBottom]);


  // const getPhoneCallLog = () => {
  //   setCallLoading(true);
  //   multipleRequest([
  //     callRequest.getCall({
  //       user_id: userId,
  //       page: pageCount
  //     }, CALL_TYPE_AUDIO),
  //     callRequest.getCall({
  //       user_id: userId,
  //       page: pageCount
  //     }, CALL_TYPE_VIDEO)
  //   ]).then(responses => {

  //     //isNone?
  //     if (responses[0].data.status.code == "402" && responses[1].data.status.code == "402") {
  //       setStatus(true);
  //     }

  //     let phoneCallLogs = [];

  //     if (responses[0].data.call_thread.length > 1) {

  //       responses[0].data.call_thread.forEach(call => {

  //         let phoneCall = {
  //           notificationSender: {}
  //         };

  //         phoneCall.notificationSender['user'] = call.userConnection.user;
  //         phoneCall.notificationSender['userSubscription'] = call.userConnection.userSubscription;
  //         phoneCall.notificationSender['userField'] = call.userConnection.userField;
  //         phoneCall.notificationSender['message'] = call.userConnection.message;
  //         phoneCall['notifType'] = COMMUNICATION_TYPE_AUDIO;

  //         if ((call.whoAdded == user.userId) && (call.status == CALL_ACTION_COMPLETED)) {
  //           phoneCall['details'] = CALL_STATUS_OUTBOUND_AUDIO;
  //         } else if ((call.whoAdded != user.userId) && (call.status == CALL_ACTION_COMPLETED)) {
  //           phoneCall['details'] = CALL_STATUS_INBOUND_AUDIO;
  //         } else if ((call.status == CALL_ACTION_MISSED) || (call.status == CALL_ACTION_DECLINE)) {
  //           phoneCall['details'] = CALL_STATUS_MISSED_AUDIO;
  //         } else {
  //         }

  //         phoneCall['whenAdded'] = call.whenAdded;

  //         phoneCallLogs.push(phoneCall);
  //       });
  //     }

  //     if (responses[1].data.call_thread.length > 1) {

  //       responses[1].data.call_thread.forEach(call => {
  //         let videoCall = {
  //           notificationSender: {}
  //         };

  //         videoCall.notificationSender['user'] = call.userConnection.user;
  //         videoCall.notificationSender['userSubscription'] = call.userConnection.userSubscription;
  //         videoCall.notificationSender['userField'] = call.userConnection.userField;
  //         videoCall.notificationSender['message'] = call.userConnection.message;
  //         videoCall['notifType'] = COMMUNICATION_TYPE_VIDEO;

  //         if ((call.whoAdded == user.userId) && (call.status == CALL_ACTION_COMPLETED)) {
  //           videoCall['details'] = CALL_STATUS_OUTBOUND_VIDEO;
  //         } else if ((call.whoAdded != user.userId) && (call.status == CALL_ACTION_COMPLETED)) {
  //           videoCall['details'] = CALL_STATUS_INBOUND_VIDEO;
  //         } else if ((call.status == CALL_ACTION_MISSED) || (call.status == CALL_ACTION_DECLINE)) {
  //           videoCall['details'] = CALL_STATUS_MISSED_VIDEO;
  //         } else {
  //         }

  //         videoCall['whenAdded'] = call.whenAdded;

  //         phoneCallLogs.push(videoCall);
  //       });
  //     }

  //     phoneCallLogs.sort((a, b) => {
  //       return new Date(b.eventTime) - new Date(a.eventTime);
  //     });

  //     //Merge Pagination
  //     setGetCallLog([...getCallLog, ...phoneCallLogs]);

  //   }).catch(error => {
  //     console.log('error : ', error);
  //   }).finally(() => {
  //     setCallLoading(false);
  //     setIsCallLogsLoaded(true);
  //   })
  // }

  // useEffect(() => {
  //   //Total Item Pagination
  //   setCallLog(getCallLog);
  //   console.log("Total Call/Video History: ", getCallLog)
  // }, [getCallLog])


  return (
    <Box
      alignItems={(!isCallLoading && callLogs.length) ? 'flex-start' : 'center'}
      justifyContent={(!isCallLoading && callLogs.length) ? 'flex-start' : 'center'}
      display='flex'
      classes={classes.callLogsBox}
    >


      <Grid
        container
        justify='flex-start'
        alignItems='center'
        spacing={2}
      >
        {
          (!isNotificationLogsLoaded) ?
            Array.from({ length: 20 }, () => 1).map((value, index) => {
              return (
                <Preloader type={PRELOADER_CALL_CARD} key={index} />
              )
            })
            : (notificationCallLogList.length > 0)
              ? notificationCallLogList.map((callLog, index) => {
                return (
                  <MessageCenterCard {...props} type={CALL_CARD} key={index} callLog={callLog} handleOnClick={() => handleClickNotification(callLog)} />
                )
              })
              : <Empty title={NO_CALL_HISTORY_TITLE} description={NO_CALL_HISTORY_DESC} image={NO_CALL_HISTORY_IMG} />
        }
        {
          isNotificationLogsLoaded && (
            <Box className={!isNotificationLogsLoading ? classes.hidden : classes.loadingBar}>
              <CircularProgress ref={ref} />
            </Box>
          )
        }
      </Grid>

    </Box>
  )
}

/////////////////////////////
// mapStateToProps()
/////////////////////////////
const mapStateToProps = (state) => {
  console.log('message-center/index.js:mapStateToProps(state)');
  console.log(state);
  return {
    notificationCallLogList: state.Notification.notificationCallLogList,
    isNotificationLogsLoading: state.Notification.isNotificationLogsLoading,
    isNotificationLogsLoaded: state.Notification.isNotificationLogsLoaded,
    callLogStatus: state.Notification.callLogStatus,
    notificationCallLogPage: state.Notification.notificationCallLogPage
  };
}

export default connect(mapStateToProps, { getNotificationCallLogs })(
  CallLogs
);