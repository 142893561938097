import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, TextField, Grid, Link, Modal, CircularProgress } from '@material-ui/core';
import PinInput from 'react-pin-input';

import useStyles from './style';
import { userRequest } from '../../../service/requests';
import { TOAST_VERIFICATION_CODE_SENT, TOAST_AXIOS_REQUEST_ERROR, ALERT_SEVERITY_SUCCESS, ALERT_SEVERITY_ERROR, TOAST_CODE_SEND, HTTP_STATUS } from '../../../utils/constants';
import ChangePhoneNumber from './change-phone-number';
import { handleHttpError } from '../../../utils/helper';

const VerifyAccount = (props) => {
  const classes = useStyles();
  const { showAlert, next, user } = props;
  const [isVerifying, setIsVerifying] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [state, setState] = useState({
    code          : '',
    isCodeError   : false,
    codeErrorDesc : '',
  });

  const {code, isCodeError, codeErrorDesc } = state;

  useEffect(() => {
    code.length === 6 && verifyCode();
  }, [code])

  const handleInputChange = event => {
    let value = event.target.value.replace(/[^0-9]/g, '');
    
    setState({
      ...state,
      code : value,
    });
  };

  const changeMobileNumber = () => {
    toggleChangePhoneNumberModal();
  }
  
  const resendCode = () => {
    console.log('VerifyAccount.resendCode()');

    showAlert(ALERT_SEVERITY_SUCCESS, TOAST_CODE_SEND);
    setState({
      ...state,
      code          : '',
      isCodeError   : false,
      codeErrorDesc : ''
    });
    userRequest.resendCode({
      user_id   : user.userId,
      phone_no  : user.phoneNo
    }).then(response => {
      console.log('response : ', response);

      if (response?.data?.code == HTTP_STATUS._200) {
        showAlert(ALERT_SEVERITY_SUCCESS, TOAST_VERIFICATION_CODE_SENT);
      }
    }).catch(error => {
      showAlert(ALERT_SEVERITY_ERROR, TOAST_AXIOS_REQUEST_ERROR);
      handleHttpError({
        error,
        request: 'userRequest.resendCode::src/views/registration/verify-account'
      })
    });
  }

  const toggleChangePhoneNumberModal = () => {
    setIsModalOpen(prevIsModalOpen => !prevIsModalOpen);
  }

  const verifyCode = () => {
    console.log('VerifyAccount.verifyCode()');
    
    setIsVerifying(prevIsVerifying => !prevIsVerifying);
    if (code != '') {
      userRequest.verifyPhone({
        user_id   : user.userId,
        phone_no  : user.phoneNo,
        conf_code : code,
      }).then(response => {
        console.log('response : ', response);

        setIsVerifying(prevIsVerifying => !prevIsVerifying);

        if (response?.data?.code == HTTP_STATUS._200) {

          setState({
            ...state,
            isCodeError   : false,
            codeErrorDesc : response.data.description
          });
          showAlert(ALERT_SEVERITY_SUCCESS, 'Your account has been successfully verified.');
          next();
        } else {
          setState({
            ...state,
            isCodeError   : true,
            codeErrorDesc : response.data.description
          });
        }
      }).catch(error => {
        showAlert(ALERT_SEVERITY_ERROR, TOAST_AXIOS_REQUEST_ERROR);
        handleHttpError({
          error,
          request: 'userRequest.verifyPhone::src/views/registration/verify-account'
        })
      }); 
    }
  }

  return (
    <Grid item md={7} className={classes.gridItem}>
      <Box className={classes.verifyAccount}>
        <Typography variant="h6" color="textPrimary" className={`text-bold`}>
          Verify Account
        </Typography>
        <Box className={classes.content}>
          <Typography variant="subtitle2" className={`text-center`} color="textPrimary">
            {`Enter the code that we sent to ${user.phoneNo}`}
          </Typography>
          {/* <TextField
            label={'Code'}
            value={code}
            onChange={handleInputChange}
            className={classes.textField}
            error={isCodeError}
            helperText={codeErrorDesc}
          /> */}
          <PinInput 
            length={6}
            initialValue={code}
            onChange={(value) => handleInputChange({target: {value, name: 'code'}})}
            type="numeric" 
            inputMode="number"
            autoSelect={true}
          />
          { isCodeError && <Typography color={'error'}>{codeErrorDesc}</Typography>}
          <Link onClick={changeMobileNumber} variant="caption" color='primary'>
            Change your mobile number
          </Link>
          <Link onClick={resendCode} variant="caption" color='primary'>
            Resend Code
          </Link>
        </Box>
        {/* <Box>
          <Button disabled={isVerifying || code.length < 6} color="primary" variant="contained" onClick={verifyCode} className={`btn-min-width`}>
            {
              isVerifying ?
                <CircularProgress color="white" size={24}/>
              : 
                'Submit'
            }
          </Button>
        </Box> */}
      </Box>
      <Modal
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        open={isModalOpen}
        onClose={toggleChangePhoneNumberModal}
      >
        <ChangePhoneNumber {...props} toggleModal={toggleChangePhoneNumberModal} />
      </Modal>
    </Grid>
  )
}

export default VerifyAccount;