import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Button, Box, Slider, CircularProgress, Typography } from '@material-ui/core';

import useStyles from './style';
import { UPD_MODE_USER_HEIGHT, ALERT_SEVERITY_SUCCESS, TOAST_AXIOS_REQUEST_ERROR, HTTP_STATUS } from '../../../../utils/constants';
import { userRequest } from '../../../../service/requests';
import { handleHttpError } from '../../../../utils/helper';

const Height = forwardRef((props, ref) => {
    const classes = useStyles();
    const { setIsSaving, isSaving, setOpenedIndex, getLookUpFields, lookUpHeight, updateSession, updateUserField, user, userField, showAlert } = props;
    const marks = [
        {
          value: 360,
          label: `7'11`,
        },
        {
          value: 349,
          label: `7'0`,
        },
        {
          value: 337,
          label: `6'0`,
        },
        {
          value: 325,
          label: `5'0`,
        },
        {
          value: 313,
          label: `4'0`,
        },
        {
          value: 300,
          label: `3'0`,
        },
      ];
      
  
    // const [isSaving, setIsSaving] = useState(false);
    const [state, setState] = useState({
      heightCode: 506,
      heightDescription : `5\'6"`,
      height: 325
    });
  
    const { height, heightCode, heightDescription } = state;
    
    useEffect(() => {
      const minSliderValue    = 301;
      const heightIndex       = lookUpHeight.findIndex(height => height.code == userField.userPreference.height);
      const heightObj         = lookUpHeight.find(height => height.code == userField.userPreference.height);
      const heightDescription = heightObj && heightObj.description;
      const heightCode        = heightObj && heightObj.code;
  
      setState({
        ...state,
        height            : minSliderValue + heightIndex,
        heightCode        : heightCode,
        heightDescription : heightDescription,
      });
    }, [lookUpHeight.length]);
  
    const getHeightValue = (value) => {
      
      let newHeightValue;
      const minSliderValue= 300;
  
      if (value > minSliderValue) {
        newHeightValue = value - 1;
      } else if (value === minSliderValue) {
        newHeightValue = 300
      }
  
      if (newHeightValue > 299 && newHeightValue < 312) {
        newHeightValue = newHeightValue - 300
  
        if (newHeightValue === 0) {
          newHeightValue = 300;
        } else {
          newHeightValue += 300;
        }
      } else if (newHeightValue > 311 && newHeightValue < 324) {
        newHeightValue = newHeightValue - 312
  
        if (newHeightValue === 0) {
          newHeightValue = 400;
        } else {
          newHeightValue += 400;
        }
      } else if (newHeightValue > 323 && newHeightValue < 336) {
        newHeightValue = newHeightValue - 324
  
        if (newHeightValue === 0) {
          newHeightValue = 500;
        } else {
          newHeightValue += 500;
        }
      } else if (newHeightValue > 335 && newHeightValue < 348) {
        newHeightValue = newHeightValue - 336
        
        if (newHeightValue === 0) {
          newHeightValue = 600;
        } else {
          newHeightValue += 600;
        }
      } else if (newHeightValue > 347 && newHeightValue < 360) {
        newHeightValue = newHeightValue - 348
  
        if (newHeightValue === 0) {
          newHeightValue = 700;
        } else {
          newHeightValue += 700;
        }
      }
  
      return newHeightValue
    }
  
    const handleChange = (event, value) => {
      console.log('value : ', value);
  
      const { lookUpHeight } = props;
    
      const newHeightValue    = getHeightValue(value);
      const heightObj         = lookUpHeight.find(height => height.code == newHeightValue);
      const heightDescription = heightObj && heightObj.description;
      const heightCode        = heightObj && heightObj.code;
  
      setState({
        ...state,
        heightCode,
        heightDescription,
        height  : value
      });
    }
  
    const saveHeight = async () => {
  
      try {
        setIsSaving(prevIsSaving => !prevIsSaving);
        const response = await userRequest.updateUser({
          user_id     : user.userId,
          upd_mode    : UPD_MODE_USER_HEIGHT,
          who_updated : user.userId,
          height      : heightCode
        }).catch(error => {
          handleHttpError({
            error,
            request: 'userRequest.updateUser::src/views/settings/my-profile/height'
          })
        })
        console.log('response : ', response);
  
        if (response?.data?.status?.code != HTTP_STATUS._200) {
          let { status } = response?.data;
          throw new Error(`${status?.code} ${status?.description}`);
        } 
        updateUserField(response?.data?.userDetail?.userField)
        updateSession(response?.data?.userDetail);
        showAlert(ALERT_SEVERITY_SUCCESS, 'Height has successfully updated.');
      } catch (error) {
        console.log('error : ', error);
        showAlert(ALERT_SEVERITY_SUCCESS, TOAST_AXIOS_REQUEST_ERROR);
      } finally {
        setIsSaving(prevIsSaving => !prevIsSaving);
        setOpenedIndex(prevOpenedIndex => !prevOpenedIndex);
      }
    }
  
    useImperativeHandle(ref, () => ({
      async triggerSave() {
        await saveHeight()
      }
    }));
  
    return (
    <Box>
        <Box className={classes.heightContainer}>
        <Box>
          <Typography variant="h3" className={`text-center`} color="textPrimary">
            I
          </Typography>
          <Typography variant="h3" className={`text-center`} color="textPrimary">
            am
          </Typography>
          <Typography variant="h3" className={`text-center`} color="textPrimary">
            {heightDescription}
          </Typography>
        </Box>
        <Box>
          <Slider
            orientation="vertical"
            getAriaValueText={(value) => `${value}`}
            defaultValue={height}
            value={height}
            aria-labelledby="vertical-slider"
            marks={marks}
            min={300}
            max={360}
            onChange={handleChange}
          /> 
        </Box>
        </Box>
        <Box className={classes.collapseBoxWrapper}>
            <Button disabled={isSaving} className={classes.buttonSave} onClick={saveHeight} color="primary" variant="contained">
            {
                isSaving ?
                    <CircularProgress size={24}/>
                : 
                    'Save'
            }
            </Button>
        </Box>
    </Box>
    )
  })
  

export default Height;