import React, { useState, useEffect, useRef } from "react";
import {
  Avatar,
  Badge,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Grid,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Link,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";

import moment from "moment";

import CallIcon from "@material-ui/icons/Call";
import VideocamIcon from "@material-ui/icons/Videocam";
import EmojiEmotionsIcon from "@material-ui/icons/EmojiEmotions";

import useStyles from "./style";
import MessageBubble from "../message-bubble";
import {
  STATUS_ACTIVE,
  STATUS_INACTIVE,
  STATUS_SENT,
  IMAGE_INVALID_LINK,
  STATUS_BLOCKED,
  STATUS_RECEIVED_BLOCK
} from "../../utils/constants";
import { joinChannel, listenToChannel } from "../../utils/twilio";
import NoImageLink from "../../assets/images/user-profile.png";
import { getImageCache } from "../../utils/helper";
import { BottomScrollListener } from "react-bottom-scroll-listener";
import CircularProgress from "@material-ui/core/CircularProgress";

//
import Empty from "../empty";
import NO_CHATS_IMAGE from "../../assets/images/no_chats.png";

import { MSG_NO_CONVERSATION } from "../../utils/constants";
import colors from "../../utils/colors";

const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: "#44b700",
    color: "#44b700",
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}))(Badge);

const ChatLayout = (props) => {
  const {
    hasChatList,
    messages = [],
    seenMessage,
    selectedMessageThread,
    setSelectedMessageThread,
    twilio,
    updateMessageParticipant,
    userId,
    handleScroll,
    isLoadingMore,
  } = props;
  const classes = useStyles();
  const chatThread = useRef();
  const chatInputField = useRef();
  const history = useHistory();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [chatTextInputValue, setChatTextInputValue] = useState("");
  const [isUserTyping, setIsUserTyping] = useState(false);
  const [dialogContent, setDialogContent] = useState({
    title: "Voice Call",
    content: "You need to send phone call request to call ",
  });

  useEffect(() => {
    listenToTwilioClientEvents();
  }, []);

  useEffect(() => {
    if (selectedMessageThread) {
      const conversationChannel = selectedMessageThread.conversation;
      joinChannel(conversationChannel);
      listenToChannel(conversationChannel);

      // conversationChannel.removeListener('typingStarted', handleTypingStarted);
      // conversationChannel.removeListener('typingEnded', handleTyepingEnded);
      // console.log('typingStarted')
      // conversationChannel.on('typingStarted', handleTypingStarted);
      // conversationChannel.on('typingEnded', handleTyepingEnded);

      conversationChannel.setAllMessagesRead().then((response) => {
        selectedMessageThread.isUnseen &&
          seenMessage(selectedMessageThread.participantInfo.state.identity);
      });
    }
  }, [selectedMessageThread]);

  const handleTypingStarted = () => {
    setIsUserTyping(true);
  };

  const handleTyepingEnded = () => {
    setIsUserTyping(false);
  };

  useEffect(() => {
    if (chatThread.current != null) {
      chatThread.current.scrollTop = chatThread.current.scrollHeight;
    }
  }, [isUserTyping, messages]);

  const listenToTwilioClientEvents = () => {
    console.log("listenToTwilioClientEvents()");
    console.log("listenToTwilioClientEvents()");

    if (twilio.client !== null) {
      twilio.client.on("userUpdated", (event) => {
        if (
          event.updateReasons.includes("reachabilityOnline") ||
          event.updateReasons.includes("attributes")
        ) {
          if (messages.length > 0) {
            updateMessageParticipant(event.user);
          }
        }
      });
    }
  };

  const handleOnSelectedMessageThread = (messageThread) => {
    chatThread.current.scrollTop = chatThread.current.scrollHeight;
    setSelectedMessageThread(messageThread);
  };

  const handleInputChange = (event) => {
    const message = event.target.value;
    selectedMessageThread.conversation.typing();
    setChatTextInputValue(message);
  };

  const sendMessage = (event) => {
    if (event.key === "Enter") {
      if (chatInputField.current.value.trim().length > 0) {
        selectedMessageThread.conversation
          .sendMessage(chatInputField.current.value)
          .then(() => {
            chatInputField.current.value = "";
          })
          .catch((error) => {
            console.log("error: ", error);
          });
      }
    }
  };

  const toggleModal = () => {
    setIsDialogOpen((prevIsDialogOpen) => !prevIsDialogOpen);
  };

  const performVoiceCall = () => {
    console.log("performVoiceCall()");

    const { phoneCall, user } = selectedMessageThread.userConnection;
    if (phoneCall !== STATUS_ACTIVE) {
      let content = "";

      if (phoneCall === null || phoneCall === STATUS_INACTIVE) {
        content = `You need to send phone call request to call ${user.userName}.`;
      } else if (phoneCall === null || phoneCall === STATUS_INACTIVE) {
        content = `${user.userName} has not accepted yet your phone call request.`;
      } else {
        content = `You have not accepted the phone call request of ${user.userName}.`;
      }

      setDialogContent({
        title: "Voice Call is Inactive",
        content,
      });
      toggleModal();
    } else if (phoneCall === STATUS_ACTIVE) {
      // executePhoneCall();
    }
  };

  const performVideoCall = () => {
    console.log("performVideoCall()");

    const { videoCall, user } = selectedMessageThread.userConnection;
    if (videoCall !== STATUS_ACTIVE) {
      let content = "";

      if (videoCall === null || videoCall === STATUS_INACTIVE) {
        content = `You need to send video call request to call ${user.userName}.`;
      } else if (videoCall === null || videoCall === STATUS_INACTIVE) {
        content = `${user.userName} has not accepted yet your video call request.`;
      } else {
        content = `You have not accepted the video call request of ${user.userName}.`;
      }

      setDialogContent({
        title: "Video Call is Inactive",
        content,
      });
      toggleModal();
    } else if (videoCall === STATUS_ACTIVE) {
      // executeVideoCall();
    }
  };

  const preventDefault = (event) => {
    event.preventDefault();
    history.push(
      `/profile/${selectedMessageThread.userConnection.user.userName}?tab=connect`
    );
  };

  const chatInfoMessage =
    selectedMessageThread.userConnection.message === STATUS_SENT ? (
      `${selectedMessageThread.userConnection.user.userName} hasn't accepted yet your chat request.`
    ) : (
      <>
        To reply, please{" "}
        <Typography
          display="inline"
          color="primary"
          variant="subtitle1"
          component={Link}
          onClick={preventDefault}
        >
          activate
        </Typography>{" "}
        the chat.
      </>
    );

  let connectionProfile = NoImageLink;

  if (selectedMessageThread) {
    !selectedMessageThread.userConnection.user.mediaProfile.includes(
      IMAGE_INVALID_LINK
    ) &&
      getImageCache(selectedMessageThread.userConnection.user.mediaProfile)
        .then((cachedImage) => {
          connectionProfile = cachedImage;
        })
        .catch((e) => {
          connectionProfile = NoImageLink;
        });
  }
  const taptoSayHello = () => {
    selectedMessageThread.conversation
      .sendMessage("Hello")
      .then(() => {})
      .catch((error) => {
        console.log("error: ", error);
      });
  };

  return (
    <>
      <Grid container direction="row" className={classes.chatLayoutContainer}>
        {hasChatList && (
          <Grid item xs={4}>
            <Typography
              variant="h4"
              color="primary"
              className={classes.chatsLabel}
              align="left"
            >
              Chats
            </Typography>
            <List className={classes.chatBox}>
              {messages.map((item, index) => {
                const { participantInfo, isUnseen, messages, userConnection } =
                  item;
                const messageLength = messages.length - 1;
                const latestMessage = messages[messageLength];

                const participantIsInvisible = participantInfo
                  ? !participantInfo.state.online
                  : true;
                let mediaProfile = NoImageLink;
                if (
                  !userConnection.user.mediaProfile.includes(
                    IMAGE_INVALID_LINK
                  ) ||
                  userConnection.user.mediaProfile === "" ||
                  !userConnection.user.mediaProfile
                ) {
                  mediaProfile = userConnection.user.mediaProfile;
                }
                return (
                  <>
                    <ListItem
                      alignItems="flex-start"
                      button
                      selected={
                        selectedMessageThread.userConnection.user.userId ===
                        userConnection.user.userId
                      }
                      key={index}
                      onClick={() => handleOnSelectedMessageThread(item)}
                    >
                      <ListItemAvatar>
                        <StyledBadge
                          overlap="circle"
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                          }}
                          variant="dot"
                          invisible={participantIsInvisible}
                        >
                          <Avatar src={mediaProfile} />
                        </StyledBadge>
                      </ListItemAvatar>
                      <ListItemText
                        primary={userConnection.user.userName}
                        primaryTypographyProps={{
                          className: `${classes.chatItemUsername} ${
                            isUnseen && classes.unseenChatItemRow
                          }`,
                        }}
                        secondary={
                          <React.Fragment>
                            <Typography
                              component="span"
                              variant="body2"
                              className={`${
                                isUnseen && classes.unseenChatItemRow
                              }`}
                              color="textPrimary"
                            >
                              {latestMessage.state.author != userId
                                ? latestMessage.state.body
                                : `You: ${latestMessage.state.body}`}
                            </Typography>
                            <Typography
                              component="span"
                              variant="body2"
                              className={`${
                                isUnseen && classes.unseenChatItemRow
                              }`}
                              color="textPrimary"
                            >
                              {` · ${moment
                                .unix(
                                  moment(
                                    new Date(
                                      latestMessage.state.timestamp.toLocaleString()
                                    )
                                  ).unix()
                                )
                                .fromNow()}`}
                            </Typography>
                          </React.Fragment>
                        }
                      />
                    </ListItem>
                  </>
                );
              })}
            </List>
          </Grid>
        )}
        <Grid item xs={hasChatList ? 8 : 12}>
          {hasChatList && (
            <Box height="10%" borderLeft={0.5} borderColor="primary.border">
              {selectedMessageThread.messages && (
                <Grid
                  container
                  className={classes.fullHeight}
                  alignItems="center"
                >
                  <Grid item xs={1} className={classes.verticallyCenter}>
                    <Avatar
                      src={
                        selectedMessageThread.userConnection.user.mediaProfile
                      }
                    />
                  </Grid>
                  <Grid item xs={9}>
                    <Typography variant="h6" color="primary" align="left">
                      {selectedMessageThread.userConnection.user.userName}
                    </Typography>
                  </Grid>
                  <Grid item xs={2} className={classes.verticallyRight}>
                    <IconButton
                      aria-label="audio call"
                      onClick={performVoiceCall}
                    >
                      <CallIcon
                        color={
                          selectedMessageThread.userConnection.phoneCall ===
                          STATUS_ACTIVE
                            ? "primary"
                            : "text.primary"
                        }
                      />
                    </IconButton>
                    <IconButton
                      aria-label="video call"
                      onClick={performVideoCall}
                    >
                      <VideocamIcon
                        color={
                          selectedMessageThread.userConnection.videoCall ===
                          STATUS_ACTIVE
                            ? "primary"
                            : "text.primary"
                        }
                      />
                    </IconButton>
                  </Grid>
                </Grid>
              )}
            </Box>
          )}
          <Box
            height={hasChatList ? "90%" : "100%"}
            borderLeft={0.5}
            bgcolor={"white"}
            borderColor="primary.border"
          >
            <Grid
              container
              direction="column"
              justify="flex-end"
              className={classes.fullHeight}
            >
              {isLoadingMore && (
                <CircularProgress style={{ WebkitAlignSelf: "center" }} />
              )}
              <Grid
                item
                xs
                className={classes.scrollContainer}
                onScroll={handleScroll}
              >
                <Box
                  className={classes.chatThread}
                  ref={chatThread}
                  borderTop={0.5}
                  borderColor="primary.border"
                >
                  {selectedMessageThread.messages &&
                    selectedMessageThread.messages.map(({ state }, index) => (
                      <MessageBubble
                        imageSrc={
                          selectedMessageThread.userConnection.user.mediaProfile
                        }
                        isMessageReceived={parseInt(state.author) !== userId}
                        message={state.body}
                        key={index}
                      />
                    ))}
                  {isUserTyping && (
                    <MessageBubble
                      imageSrc={
                        selectedMessageThread.userConnection.user.mediaProfile
                      }
                      isMessageReceived
                      isTyping
                    />
                  )}
                  <Box className={classes.emptyStateMessage}>
                    {selectedMessageThread.messages.length === 0 && (
                      <>
                        <Empty
                          image={NO_CHATS_IMAGE}
                          description={MSG_NO_CONVERSATION}
                        />
                        <Box className={classes.tapButtonContainer}>
                          <Button
                            className={classes.tapButton}
                            color="primary"
                            variant="contained"
                            onClick={taptoSayHello}
                          >
                            Tap to say 'Hello'
                          </Button>
                        </Box>
                      </>
                    )}
                  </Box>
                </Box>
              </Grid>

              <Grid item className={classes.messageInputContainer}>
                {selectedMessageThread?.userConnection?.message == STATUS_ACTIVE 
                && selectedMessageThread?.userConnection?.status == STATUS_ACTIVE || selectedMessageThread?.userConnection?.status == null 
                ? 
                  (
                    <InputBase
                      inputRef={chatInputField}
                      className={classes.messageInput}
                      placeholder={"Type a message"}
                      // value={chatTextInputValue}
                      onKeyDown={sendMessage}
                      // onChange={handleInputChange}
                      endAdornment={
                        <IconButton>
                          <EmojiEmotionsIcon />
                        </IconButton>
                      }
                      fullWidth
                    />
                  )
                : 
                  selectedMessageThread?.userConnection?.status == STATUS_BLOCKED || selectedMessageThread?.userConnection?.status == STATUS_RECEIVED_BLOCK 
                    ?
                      (
                        <Typography
                          align="center"
                          color="textPrimary"
                          variant="subtitle1"
                          style={{ padding: 20, backgroundColor : colors.USER_PROFILE_BLOCKED }}
                        >
                          {selectedMessageThread?.userConnection?.status == STATUS_RECEIVED_BLOCK ? "You can't reply to this conversation. This user blocked you." : "You can't reply to this conversation. You blocked this user."}
                        </Typography>
                      )
                    : (
                        <Typography
                          align="center"
                          color="textPrimary"
                          variant="subtitle1"
                          style={{ padding: 20 }}
                        >
                          You cannot reply to this conversation. {chatInfoMessage}{" "}
                        </Typography>
                      )
                }
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <Dialog
        open={isDialogOpen}
        onClose={toggleModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{dialogContent.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {dialogContent.content}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={toggleModal}
            color="primary"
            variant="contained"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ChatLayout;
