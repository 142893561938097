import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../utils/colors';

const useStyles = makeStyles(theme => ({
  // PROFILE
 
  
  infoGrid: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },

  detail: {
    fontWeight: 'bold',
    lineHeight: '32px'
  },

  value: {
    lineHeight: '32px',
  },

  deeperLvl: {
    marginBottom: 0
  },

  ringIcon: {
    width: '24px',
    height: '24px'
  }

}));

export default useStyles;