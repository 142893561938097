import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../utils/colors';

const useStyles = makeStyles(theme => ({

  content: {
    flex: 1,
    paddingTop: theme.spacing(5),
    padding: theme.spacing(4),
  },

  bold : {
    fontWeight : 'bold'
  },

  buttonGrid: {
    width : '50%'
  },

  shareIcon: {
    color : colors.WHITE
  },

  copyLinkButton: {
    backgroundColor : '#707070', 
    borderRadius : 10, 
    marginBottom : 10, 
  },

  facebookButton: { 
    backgroundColor : '#3b5998', 
    borderRadius : 10, 
    marginBottom : 10 
  },

  twitterButton: {
    backgroundColor : '#00acee', 
    borderRadius : 10, 
    marginBottom : 10
  },

  linkedInButton: {
    backgroundColor : '#0e76a8', 
    borderRadius : 10, 
    marginBottom : 10
  },

  buttonText: {
    color : 'white',
    textAlign : 'center'
  },

  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius : 10,
    outline : 0,
    padding: theme.spacing(2, 2, 2, 2),
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: `translate(-50%, -50%)`,
    width : 400
  },

  loading: {
    alignSelf : 'center'
  }
}));

export default useStyles;