import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../utils/colors';

const useStyles = makeStyles(theme => ({
  container: {
    '& .sectionTitle': {
      color: colors.ABOUT_US_SECTION_TITLE,
    }
  },
}));

export default useStyles;
