import React, { memo, useState } from 'react';
import { Avatar, Card, CardContent, Link } from '@material-ui/core';

import useStyles from './style';
import Empty from '../empty';
import Preloader from '../preloader';
import NO_USERS_IMAGE from "../../assets/images/no_users_found.png";

import { 
	PRELOADER_CARD_SHORTLIST_HOME, 
	STATUS_ACTIVE,
	USER_NO_PROFILES,
  USER_SUBSCRIPTION_ELITE,
  USER_SUBSCRIPTION_PRIVACY
} from '../../utils/constants';
import Icon              from '@mdi/react';
import { mdiSunglasses } from '@mdi/js';
import DiamondIcon       from '../../assets/images/diamond.png';
import { getImageCache } from '../../utils/helper';

const ShortlistItem = (props) => {
	const classes = useStyles();
	const { clickCallback, userConnection, key } = props;
	const [profileImage, setProfileImage] = useState('');
	const user = userConnection.user;
	const arePhotosPrivate = (userConnection.userSubscription.viewabilityStatus == USER_SUBSCRIPTION_PRIVACY || userConnection.userSubscription.viewabilityStatus == USER_SUBSCRIPTION_ELITE) && userConnection.message != STATUS_ACTIVE;

	const callbackFunction = () => {
		clickCallback && clickCallback(userConnection)
	};

	getImageCache(user.mediaProfile).then((cachedImage) => {
		setProfileImage(cachedImage)
	});

	return (
		<div className={classes.item} key={key}>
			<Card onClick={callbackFunction.bind(this, userConnection)}>
				<Avatar
					className={`${classes.itemImg} ${arePhotosPrivate && 'image-blur'}`}
					src={profileImage}
					variant='square'
				/>
			</Card>
			<p>
				{`${user.userName}`} 
				{( userConnection?.userSubscription?.viewabilityStatus == 'PRV' ) ? <Icon path={mdiSunglasses} size={1} color="black" style={{marginLeft : 2}} /> 
				:( userConnection?.userSubscription?.viewabilityStatus == 'ELT' ) ? <img src={DiamondIcon} className={`${classes.planIcon} ${classes.quickFade}`} /> :''}
				{`, ${user.age}`}
			</p>
		</div>
	)
}

const DashboardShortlist = (props) => {
	const classes = useStyles();
	const { title, shortlist, gotoPage, isLoading } = props;

	const populateProfiles = () => {

		if (!shortlist) return <Empty image={NO_USERS_IMAGE} title="Shortlist" description={USER_NO_PROFILES} />
		if (!shortlist.length) return <Empty image={NO_USERS_IMAGE} title="Shortlist" description={USER_NO_PROFILES} />

		if (shortlist.length > 4)
		shortlist.splice(4, shortlist.length - 1);

		return shortlist.map((shortlistItem, index) => {
			
			return <ShortlistItem {...props} key={index} userConnection={shortlistItem} />
		})
	};

	const preloader = () => {
		return [1,2,3,4].map(
			(value) => 
				<div className={classes.item} key={value}> 
					<Preloader type={PRELOADER_CARD_SHORTLIST_HOME}/> 
				</div> 
		)
	}

	return (
		<Card className={classes.card}>
			<div className={classes.cardHeader}>
				<Link component='button' onClick={gotoPage}>
					<h3>Shortlist</h3>
				</Link>
			</div>
			<CardContent className={classes.cardContent}>
				{
					isLoading && !shortlist.length
					? preloader()
					: populateProfiles()
				}
			</CardContent>
		</Card>
	)
}

export default memo(DashboardShortlist);
