import { initializeApp, getApps } from "firebase/app";
import { isSupported, getMessaging, getToken, onMessage } from "firebase/messaging";
import { AUDIO_CALL_DIALLED, VIDEO_CALL_DIALLED, VIDEO_CALL_ANSWERED, VIDEO_CALL_REJECTED, VIDEO_CALL_ENDED, VIDEO_CALL_MISSED, PHONE_CALL_RECEIVER, VIDEO_CALL_RECEIVER, ROOM, MESSAGE_REQUEST_SENT, MESSAGE_REQUEST_ACCEPTED, PHONE_CALL_REQUEST_SENT, PHONE_CALL_REQUEST_ACCEPTED, VIDEO_CALL_REQUEST_SENT, VIDEO_CALL_REQUEST_ACCEPTED, MESSAGE_DISABLED, PHONE_CALL_DISABLED, VIDEO_CALL_DISABLED } from "../../utils/constants";
import { userRequest } from "../../service/requests";
import { handleHttpError } from "../../utils/helper";

var firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	databaseURL: process.env.REACT_APP_FIREBASE_DB_URL,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APP_ID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

class Firebase {
	constructor() {
			this.app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];
			this.messaging = getMessaging(this.app);
	}

	isSupported = () => {
		return isSupported()
	}

	requestNotificationPermission = async () => {

		return await Notification.requestPermission();
	}

	getFirebaseToken = async () => {
		return getToken(this.messaging, { vapidKey: 'BO0xFfPdlA6V8gG6nOKKyswsM3kZx0FrMmeZlzjLmdJge2JWEsSC3h6eM34ahTSroqz_UXQvoHOJ8QAswSmxjE0' });
	}

	
	handleOnReceiveMessage = (props) => {
		const { settings } = props;
		const isNotificationEnabled = settings?.notification?.isEnabled;
		const hiddenNotifTypes = [MESSAGE_DISABLED, PHONE_CALL_DISABLED, VIDEO_CALL_DISABLED];
		
		onMessage(this.messaging, (payload) => {
			console.log('Message received. ', payload);

			const notificationTitle = 'S.O.Search';
			const notificationOptions = {
				body: payload.data.twi_body
			};

			if (payload.data.notifType === VIDEO_CALL_DIALLED) {
				const { history, saveNotification } = props;

				saveNotification(payload.data);

				history.push(`/videocall?operator=${VIDEO_CALL_RECEIVER}&threadId=${payload.data.itemId}&id=${payload.data.senderId}`);
				// window.open(`${window.location.host}/videocall`, 'Video Call');
			} else if (payload.data.notifType === AUDIO_CALL_DIALLED) {
				const { history, saveNotification } = props;

				saveNotification(payload.data);

				history.push(`/audiocall?operator=${PHONE_CALL_RECEIVER}&threadId=${payload.data.itemId}&id=${payload.data.senderId}`);
				// window.open(`${window.location.host}/videocall`, 'Video Call');
			} else if (payload.data.notifType === VIDEO_CALL_ANSWERED) {
				const { user, updateTwilioVideoToken } = props;
				const roomName = (user.userId > payload.data.senderId) ? ROOM + payload.data.senderId + '_' + user.userId : ROOM + user.userId + '_' + payload.data.senderId;

				userRequest.getTwilioVideoToken({
					user_name: user.userName,
					room_name: roomName
				}).then(response => {
					console.log('response : ', response);

					updateTwilioVideoToken(response.data.tokenTwilio);
				}).catch(error => {
					handleHttpError({
						error,
						request: "userRequest.getTwilioVideoToken::src/classes/firebase/firebase.js"
					})
				});
			} else if (payload.data.notifType === VIDEO_CALL_REJECTED) {
				const { updateTwilioVideoToken, history } = props;
				if(history.location.pathname === '/audiocall' || history.location.pathname === '/videocall' ){
					updateTwilioVideoToken(VIDEO_CALL_REJECTED);
				} else {
					updateTwilioVideoToken(null);
				}
			} else if (payload.data.notifType === VIDEO_CALL_ENDED) {
				const { updateTwilioVideoToken } = props;

				updateTwilioVideoToken(VIDEO_CALL_ENDED);
			} else if (payload.data.notifType === VIDEO_CALL_MISSED) {
				const { updateTwilioVideoToken } = props;

				updateTwilioVideoToken(VIDEO_CALL_MISSED);
			} else if (payload.data.notifType === MESSAGE_REQUEST_SENT || payload.data.notifType === MESSAGE_REQUEST_ACCEPTED) {
				const { updateChatRequest } = props;

				updateChatRequest(payload.data);
			} else if (payload.data.notifType === PHONE_CALL_REQUEST_SENT || payload.data.notifType === PHONE_CALL_REQUEST_ACCEPTED) {
				const { updatePhoneRequest } = props;

				updatePhoneRequest(payload.data);
			} else if (payload.data.notifType === VIDEO_CALL_REQUEST_SENT || payload.data.notifType === VIDEO_CALL_REQUEST_ACCEPTED) {
				const { updateVideoRequest } = props;

				updateVideoRequest(payload.data);
			} else if (hiddenNotifTypes.includes(payload.data.notifType)) {
				const { setDisabledConnection } = props;

				setDisabledConnection(payload.data);
			}

			if (hiddenNotifTypes.includes(payload.data.notifType))
				return;

			if (!payload.data.twi_body)
				return

			if (!payload.data.isNotificationShowed)
				return

			if (!isNotificationEnabled)
				return

			const notification = new Notification(notificationTitle, notificationOptions)
		})
	}
}

export default Firebase;