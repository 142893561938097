import React, { useState, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import SnackBar from '../components/snackbar';
import { userRequest } from '../service/requests';
import userAction from '../redux/user/action'
import { connect } from 'react-redux';
import { TOAST_AXIOS_REQUEST_ERROR, ALERT_SEVERITY_ERROR, STATUS_ACTIVE } from '../utils/constants'


const PrivateRoute = (props) => {
	const { authenticated, exact, component: Component, isUserEmpty, layout: Layout, path, isSignedIn, isSubscribed } = props;
	
	
	const [open, setAlertOpen] = useState(false);
	const [message, setAlertMessage] = useState('');
	const [severity, setAlertSeverity] = useState('success');
	

 
	const showAlert = (severity, message) => {
		setAlertOpen(true);
		setAlertMessage(message);
		setAlertSeverity(severity)
	}

	const privateRouteProps = props;

	return (
		<Route
			exact={exact}
			path={path}
			render={(props) => {
				if(!isSubscribed && props.location.pathname !== '/subscription' && props.location.pathname !== '/search' && props.location.pathname !== '/home' && props.location.pathname !== '/settings' ){
					return <Redirect
						to={{
							pathname: '/sub',
							state: { from: props.location },
						}}
					/>
				}
				if(authenticated  && isSignedIn) {
					if(!isUserEmpty){
						return <Layout {...privateRouteProps}>
						<Component {...props} showAlert={showAlert} />
						<SnackBar open={open} setOpen={setAlertOpen} message={message} severity={severity} />
								</Layout>
					} 
				}
				else {
					return <Redirect
						to={{
						pathname: '/',
						state: { from: props.location },
						}}
					/>
				}
      		}}
		/>
	)
};


export default PrivateRoute
	
  