import React, { useState, useEffect, useRef } from 'react';
import { Box, Container, Grid, Paper, Typography, CircularProgress } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';

import useStyles from './style';
import UserCard from '../../components/usercard';
import Preloader from '../../components/preloader';
import Empty from '../../components/empty';
import { BottomScrollListener }  from 'react-bottom-scroll-listener';
import connectionAction	from '../../redux/connection/action';

import {
  CARD_VIEW,
  PRELOADER_CARD_VIEW,
  NO_USERS_LIKED_ME_TITLE,
  NO_USERS_LIKED_ME_DESC,
  NO_USERS_VIEWED_ME_TITLE,
  NO_USERS_VIEWED_ME_DESC,
  NO_USERS_I_VIEWED_TITLE,
  NO_USERS_I_VIEWED_DESC,
} from '../../utils/constants'

import NO_USERS_LIKED_ME from "../../assets/images/no_user_liked_me.png";
import NO_USERS_VIEWD_ME from "../../assets/images/no_user_viewed_me.png";

const { getRecentViews } = connectionAction;

// const useQuery = () => {
//   return new URLSearchParams(useLocation().search);
// }

const urlTabs = [
  'viewed-me',
  'liked-me',
  'ive-viewed'
]

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

const CenteredTabs = (props) => {

  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const { tabs, tabname } = props;
  
  // const query = useQuery().toString();
  const initialValue = urlTabs.findIndex(tab => `${tab}` == `${tabname}`);
  
  const [value, setValue] = useState(initialValue);
  useEffect(() => {
    if (`${tabname}` === '' || !urlTabs.find(tab => `${tab}` == `${tabname}`)) {
      setValue(0);
      history.push(`/recent-views/viewed-me`);
    } else {
      let tab = 'viewed-me';

      if (initialValue === 0) {
        tab = 'viewed-me';
      } else if (initialValue === 1) {
        tab = 'liked-me';
      } else if (initialValue === 2) {
        tab = 'ive-viewed';
      }

      history.push(`/recent-views/${tab}`);
      setValue(initialValue);
    }
  }, [initialValue]);

  useEffect(() => {
    if (!location.state) return;
    setValue(location.state.subTabIndex);
  }, [location]);

  return (
    <Paper className={classes.paper}>
      {
        tabs.map((tab, index) => {
          return (
            <TabPanel key={index} value={value} index={tab.id}>
              {tab.component}
            </TabPanel>
          )
        })
      }
    </Paper>
  );
}

const RecentViews = (props) => {
  console.log('RecentViews()');

  const [isOnBottom, setIsOnBottom] = useState(false);
  const classes = useStyles();
  const { getRecentViews, isRecentViewsLoading, likedMeList, iViewedList, viewedMeList, page, isLikedMe, isIViewed, isViewedMe, match } = props;
  // const query = useQuery().toString();
  const tabname = match.params.tabname;
  const initialValue = urlTabs.findIndex(tab => `${tab}` == `${tabname}`);
  const tabsName = [ `Viewed Me`, `Liked Me`, `I've Viewed` ];
  
  // const ref = useRef();
  console.log('tabs: ',initialValue);

  useEffect(() => {
    window.scrollTo({
      top      : 0,
      behavior : 'smooth'});
  }, [initialValue]);

  useEffect(() => {
    console.log(initialValue, 'initialValue')
    getRecentViews();
  }, []);

  // useEffect(() => {
  //   if (ref.current) {
  //     setTimeout(() => {
  //       ref.current.scrollIntoView({ behavior: "smooth" })
  //       console.log(ref.current,'sheesh');
  //     }, 1)
  //   }
  //   setIsOnBottom(false);
  // }, [isOnBottom]);

//   ////////////////////////////////
//  ///Reached bottom Pagination////
// ////////////////////////////////
//   const handleReachedBottom = (tab) => {

//     if (!isRecentViewsLoading) {
//       switch(tab){
//         case 'Liked Me':
//           setIsOnBottom(true);
//           isLikedMe && getRecentViews({
//             page: {
//                    likedMePage:  page.likedMePage+1, 
//                    iViewedPage:  page.iViewedPage, 
//                    viewedMePage: page.viewedMePage 
//                   },
//             tab: tab});
//         break;
//         case `I've Viewed`:
//           setIsOnBottom(true);
//           isIViewed && getRecentViews({
//             page: {
//               likedMePage:  page.likedMePage, 
//               iViewedPage:  page.iViewedPage+1  , 
//               viewedMePage: page.viewedMePage 
//                   },
//             tab: tab});
//         break;
//         case 'Viewed Me':
//           setIsOnBottom(true);
//           isViewedMe && getRecentViews({
//             page: {
//                 likedMePage:  page.likedMePage, 
//                 iViewedPage:  page.iViewedPage, 
//                 viewedMePage: page.viewedMePage+1  
//                   },
//             tab: tab});
//         break;
//       }
//       }
//    }

  return (
    <Container className={classes.root} >
      <CenteredTabs tabname={tabname} tabs={[
        {
          id : 0,
          label : 'Viewed Me',
          component:  <Grid 
                        container 
                        justify='flex-start' 
                        alignItems='center'
                        spacing={2} 
                      >
                        
                        {/* <BottomScrollListener onBottom={ ()=> handleReachedBottom(tabsName[0]) }/> */}
                        {
                          (isRecentViewsLoading && viewedMeList.length === 0) ?
                            Array.from({length: Math.random() * (8) + 1 }, () => 1).map((value, index) => {
                              return (
                                <Preloader type={PRELOADER_CARD_VIEW} key={index}/>
                            )
                            })
                          :
                            viewedMeList.length === 0 ? 
                              <Empty title={NO_USERS_VIEWED_ME_TITLE} description={NO_USERS_VIEWED_ME_DESC} image={NO_USERS_VIEWD_ME}/>
                            :
                              viewedMeList.map((userConnection, index) => {
                                return (
                                  <UserCard type={CARD_VIEW} userConnection={userConnection} isSeen={userConnection.profileView.isSeen} key={index} />
                                )
                              })
                        }
                       
                      </Grid>
        },
        {
          id : 1,
          label : 'Liked Me',
          component:  <Grid 
                        container 
                        justify='flex-start' 
                        alignItems='center'
                        spacing={2} 
                      >
                         {/* <BottomScrollListener onBottom={ ()=> handleReachedBottom(tabsName[1]) }/> */}
                        {
                          (isRecentViewsLoading && likedMeList.length === 0) ?
                            Array.from({length: Math.random() * (8) + 1 }, () => 1).map((value, index) => {
                              return (
                                <Preloader type={PRELOADER_CARD_VIEW} key={index}/>
                            )
                            })
                          :
                            likedMeList.length === 0 ?
                              <Empty title={NO_USERS_LIKED_ME_TITLE} description={NO_USERS_LIKED_ME_DESC} image={NO_USERS_LIKED_ME}/>
                            :
                              likedMeList.map((likedMe, index) => {
                                return (
                                  <UserCard type={CARD_VIEW} userConnection={likedMe.userConnection} auConnection={likedMe.auConnection} isSeen={(iViewedList.find(iViewed => iViewed.user.userId == likedMe.userConnection.user.userId)) ? true : false} key={index} />
                                )
                              })
                        }
                      </Grid>
        },
        {
          id : 2,
          label : `I've Viewed`,
          component:  <Grid 
                        container 
                        justify='flex-start' 
                        alignItems='center'
                        spacing={2} 
                      >
                         {/* <BottomScrollListener onBottom={ ()=> handleReachedBottom(tabsName[2]) }/> */}
                        {
                          (isRecentViewsLoading && iViewedList.length === 0) ?
                            Array.from({length: Math.random() * (8) + 1 }, () => 1).map((value, index) => {
                              return (
                                <Preloader type={PRELOADER_CARD_VIEW} key={index}/>
                            )
                            })
                          :
                            iViewedList.length === 0 ? 
                              <Empty title={NO_USERS_I_VIEWED_TITLE} description={NO_USERS_I_VIEWED_DESC} image={NO_USERS_VIEWD_ME}/>
                            :
                              iViewedList.map((userConnection, index) => {
                                return (
                                  <UserCard type={CARD_VIEW} userConnection={userConnection} isSeen={true} key={index} />
                                )
                              })
                        }
                      </Grid>
        },
        
      ]}/>
     {/* <Box className={!isRecentViewsLoading ? classes.hidden : classes.loadingBar}>
         <CircularProgress   style={{margin:15}} ref={ref} />
      </Box> */}
    </Container>
  );
}


/////////////////////////////
// mapStateToProps()
/////////////////////////////
const mapStateToProps = (state) => {
  console.log('shortlist/index.js:mapStateToProps(state)');
  console.log('shortlist/index.js:mapStateToProps(state)', state.Connection.recentViewsList.page.iViewedPage);
  return {
    
    isRecentViewsLoading  : state.Connection.isRecentViewsLoading,
    likedMeList           : state.Connection.recentViewsList.likedMe,
    iViewedList           : state.Connection.recentViewsList.iViewed,
    viewedMeList          : state.Connection.recentViewsList.viewedMe,
    page                  :  state.Connection.recentViewsList.page,
    isLikedMe             :  state.Connection.recentViewsList.isLikedMe,
    isIViewed             :  state.Connection.recentViewsList.isIViewed,
    isViewedMe            :  state.Connection.recentViewsList.isViewedMe

  };
}

export default connect(mapStateToProps, { getRecentViews })(RecentViews);