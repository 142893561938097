import { makeStyles } from '@material-ui/core/styles';
import colors from '../../utils/colors';

const useStyles = makeStyles(theme => ({

  // GENERAL style
  card: {
    border: `4px solid ${colors.GRAY}`,
    borderRadius: 7,
    cursor: 'pointer',
    width: "100%",
    height: "100",
    '&:hover': {
      backgroundColor: colors.HOVER_DEFAULT
    },
  },

  regularCard: {
    paddingBottom : '6px'
  },

  userCardInfo: {
    padding: 10,
    paddingTop: 15,
    paddingBottom: 15

  },

  activeCard: {
    border: `1px`,
    backgroundImage: `linear-gradient(180deg, ${colors.GRADIENT_YELLOW} 30%, ${colors.GRADIENT_ORANGE} 90%)`,
    padding : `4px`,
  },

  userImage: {
    backgroundColor: colors.PRELOADER_BACKGROUND,
    borderRadius: 5,
    paddingTop: '100%', // 1:1
  },

  content: {
    height:'100%',
    backgroundColor: colors.WHITE,
    borderRadius: 5,
    fontWeight: 'bold',
    display: 'absolute',
  },

  connectionContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: "100%"
  },

  boldText: {
    fontWeight: 'bold',
    alignItems: 'center',
  },

  activeText: {
    color: colors.PRIMARY,
    fontWeight: 'bold',
    justifyContent: 'center',
    alignItems: 'center',
  },

  // style for CARD_SHORTLIST
  cardShortlist: {
    backgroundColor: colors.WHITE,
    borderRadius: 10,
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colors.HOVER_DEFAULT
    },
    position: 'relative',
  },

  shortistImage: {
    backgroundColor: colors.PRELOADER_BACKGROUND,
    height: 0,
    borderRadius: 4,
    paddingTop: '150%', // 4:5
  },

  contentShortlist: {
    bottom: 0,
    position: 'absolute',
    padding: 10, 
    height: 50,
    maxHeight: 50,
    alignItems: 'center',
    width: '90%',
    left: 2
  },

  overlay: {
    bottom: 0,
    position: 'absolute',
    backgroundColor: 'black',
    opacity: 0.4,
    padding: 10, 
    height: 70,
    maxHeight: 70,
    width: '100%',
    // filter:'blur(4px)',
    backdropFilter: 'blur(1px)',
    borderRadius: 8
  },

  // style for CARD_CALENDAR_EVENTS

  calendarEventGridItem: {
    padding: 10
  },

  cardCalendarEvent: {
    display   : 'flex',
    '&:hover #edit-button': {
      opacity : 1,
      zIndex  : 2
    },
  },

  expiredEvent: {
    opacity   : 0.5,
    zIndex    : 1
  },

  editButton: {
    backgroundColor   : 'rgba(255, 255, 255, 1)', 
    boxShadow         : theme.shadows[5],
    opacity           : 0,
    position          : 'absolute',
    marginLeft        : 380,
    marginTop         : -20,
    '&:hover': {
      backgroundColor : 'rgba(255, 255, 255, 1)'
    },
  },
  dateSectionInfo: {
    height : 140
  },

  eventStatus: {
    alignItems : 'center',
    borderRadius : 20,
    justifyContent : 'space-around',
    height : '100%',
    padding: theme.spacing(1, 2),
    width : 150
  },

  acceptedStatus: {
    backgroundImage: `linear-gradient(180deg, ${colors.GRADIENT_YELLOW} 30%, ${colors.GRADIENT_ORANGE} 90%)`,
  },

  declinedStatus: {
    backgroundColor : colors.ERROR
  },

  pendingStatus: {
    backgroundColor : colors.CALENDAR_RESPONSE_PENDING
  },

  eventStatusContainer: {
    borderColor : colors.WHITE, 
    borderStyle : 'solid',
    borderWidth : 2, 
    padding: theme.spacing(1, 0),
    borderRadius : 10
  },

  eventUserInfo: {
    alignItems : 'center',
    display : 'flex'
  },

  eventUsername: {
    fontWeight : 'bold', 
    marginLeft : 10
  },

  meetupName: {
    fontWeight : 'bold',
    overflow: 'hidden',
    textOverflow : 'ellipsis',
    whiteSpace: 'noWrap',
  },

  eventDetailsContainer: {
    padding: 25, 
    paddingBottom : 0,
    height : '100%'
  },

  calendarEventProfile: {
    width: theme.spacing(7),
    height: theme.spacing(7)
  },

  calendarInfo: {
    display : 'flex',
    marginBottom : 5 
  },

  

  responseContainer: {
    alignItems : 'center',
    display : 'flex'
  },

  responseInput: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)'
    },
    '&:hover #edit-button': {
      opacity : 0
    },
  },

  date: {
    color : colors.WHITE,
    lineHeight : 1,
    fontWeight : 600
  },

  calendarInfoText: {
    marginLeft : 10
  },

  center: {
    alignItems : 'center'
  },

  responseOptions: {
    alignItems : 'center',
    display : 'flex', 
    marginLeft : 10
  },

  acceptedResponseIcon: {
    color : colors.LIGHT_GREEN
  },

  declinedResponseIcon: {
    color : colors.ERROR
  },

  responseText: {
    alignItems : 'center', 
    marginLeft : 5
  },

  planIcon: {
    width: '20px',
    height: '20px'
  },

  quickFade: {
    animation: '$fadeInAnimation 0.5s forwards',
  },


}));

export default useStyles;