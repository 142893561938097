import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../../utils/colors';

const useStyles = makeStyles(theme => ({
  container: {
    display         : 'flex',
    flexDirection   : 'column',
    justifyContent  : 'center',
    textAlign       : 'center',
    height          : 'auto',
  },
  box:{
    display         : 'flex',
    flexDirection   : 'row',
    justifyContent  : 'center',
    alignContent    :'center',
    width           :'100%',
    height          : '50vh',
  },
  bottomBoxButton: {
    display         : 'flex',
    flexDirection   : 'row',
    justifyContent  : 'center',
    alignContent    :'center',
    width           : '100%',
  },
  textContentBox: {
    display         : 'flex',
    flexDirection   : 'column',
    justifyContent  : 'space-evenly',
    width           : '60vh',
    height          : '100%',
    '& a': {
        position  : 'absolute',
        left      : 0,
        cursor    : 'pointer'
      },
      '& > h5': {
        color: colors.PRIMARY,
      },
  },
  confirmButton:{
    backgroundColor : colors.PRIMARY,
    color           : colors.WHITE,
    '&:hover': {
        backgroundColor : colors.GRADIENT_ORANGE,
        color           : colors.WHITE,
      },
  },
  cancelButton:{
    backgroundColor : colors.GRAY,
    color           : colors.WHITE,
    '&:hover': {
        backgroundColor : colors.GRAY,
        color           : colors.WHITE,
      },
  },
  button:{
    margin        : 25,
    borderRadius  : 50,
    paddingLeft   : 20,
    paddingRight  : 20
  },

  form: {
    display         : 'flex',
    flexDirection   : 'column',
    justifyContent  : 'center',
    position        : 'relative',
    margin          : theme.spacing(2, 0, 2, 0),

    '& .MuiFormControl-root': {
      marginTop     : theme.spacing(1),
      marginBottom  : theme.spacing(1),
      
    },

    '& .MuiTypography-root': {
      marginBottom  : theme.spacing(0.5),
      marginTop     : theme.spacing(0.5),
      
    },

    '& > .pincode-input-container': {
      margin: theme.spacing(2, 0, 2, 0),
      
      '& > .pincode-input-text': {
        border        : `2px solid ${colors.PRIMARY} !important`,
        borderRadius  : '8px',
        color         : colors.DARK_GRAY,
        fontSize      : 20,
        fontWeight    : 'bold',
        
      }
    },
  },

  buttonSelect: {
    background    : colors.PRIMARY,
    color         : colors.WHITE,
    borderRadius  : 40,
    alignSelf     : 'center',
    minWidth      : 200,
    '&:hover': {
      backgroundColor: colors.PRIMARY
    },
    '&.Mui-disabled': {
      background  : colors.GRAY,
      color       : colors.WHITE
    },
    marginBottom  : 5
  },
  
  fade: {
    animation: '$fadeInAnimation 1s forwards',
  },

  quickFade: {
    animation: '$fadeInAnimation 0.5s forwards',
  },
  
  planIcon: {
    width           : '70px',
    height          : '120px',
    margin          : 10,
    alignSelf       :'center'
  },
  sadIcon: {
    width           : '120px',
    height          : '120px',
    margin          : 10,
    alignSelf       :'center'
  },

  '@keyframes fadeInAnimation': {
    '0%': {
        opacity: '0',
    },
    '100%': {
        opacity: '1',
     }
  }


}));

export default useStyles;
