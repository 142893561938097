import React from 'react';
import Icon from '@mdi/react';
import { mdiHeartHalfFull, mdiHeartOutline, mdiHeart } from '@mdi/js';
import {
  STATUS_ACTIVE,
  STATUS_RECEIVED,
  STATUS_SENT
} from '../../utils/constants'

const Heart = (props) => {
  const { value } = props;

  let icon =  <Icon path={mdiHeartOutline} size={1} color="red" />
  
  if (value === STATUS_SENT) {
    icon = <Icon path={mdiHeartHalfFull} size={1} color="red" />
  } else if (value === STATUS_RECEIVED) {
    icon = <Icon path={mdiHeartHalfFull} size={1} horizontal color="red" />
  } else if (value === STATUS_ACTIVE) {
    icon = <Icon path={mdiHeart} size={1} color="red" />
  }

  return icon
}

export default Heart;