import { makeStyles } from '@material-ui/styles';
import colors from '../../../utils/colors'

const useStyles = makeStyles(theme => ({

  container: {
    padding: 30,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& h5, p.mb-20': {
      marginBottom: 20
    },
    '& h4': {
      marginBottom: 30
    },
    [theme.breakpoints.down("xs")]: {
      padding: 10,
      '& h4, h5': {
        fontSize: 18,
        marginBottom: 10
      },
      '& span, p.mb-20': {
        fontSize: 12,
        marginBottom: 5
      }
    },
    gap: '20px'
  },

  paper: {
    left: 'calc(50% - 200px)',
    top: '50%',
    transform: 'translateY(-50%)',
    position: 'absolute',
    width: 400,
    backgroundColor: colors.BACKGROUND_COLOR,
    boxShadow: theme.shadows[5],
  },

  box: {
    alignItems: 'left',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'left',
    padding: theme.spacing(3),
  },

  btnBox: {
    alignItems: 'right',
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'right',
  },

  modalButton: {
    marginRight: 10,
  },

  wrapper: {
    padding: theme.spacing(3),
    '& > h5': {
      color: colors.PRIMARY,
      alignSelf: 'flex-start'
    }
  },

  buttonGrid: {
    width : '80%'
  },

  shareIcon: {
    color : colors.WHITE
  },

  copyLinkButton: {
    backgroundColor : colors.PRIMARY_TEXT, 
    borderRadius : 10, 
    marginBottom : 10, 
    cursor       : 'pointer'
  },

  facebookButton: { 
    backgroundColor : colors.FACEBOOK, 
    borderRadius : 10, 
    marginBottom : 10 
  },

  twitterButton: {
    backgroundColor : colors.TWITTER, 
    borderRadius : 10, 
    marginBottom : 10
  },

  linkedInButton: {
    backgroundColor : colors.LINKED_IN, 
    borderRadius : 10, 
    marginBottom : 10
  },

  buttonText: {
    color : colors.WHITE,
    textAlign : 'center'
  },

  submitBtn: {
    marginBottom: 10
  },

  rewardForm: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px'
  }


}));

export default useStyles;
