import { makeStyles } from '@material-ui/styles';
import colors from '../../../utils/colors'

const useStyles = makeStyles(theme => ({

  appBar: {
    margin: 0,
    backgroundImage: `linear-gradient(180deg, ${colors.GRADIENT_YELLOW} 30%, ${colors.GRADIENT_ORANGE} 90%)`,
    boxShadow: 'none',
    
  },
  
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    marginTop: 4,
    marginBottom:4
  },

  logo: {
    height: 40,
   
  },

  toolbarTitle: {

  },

  textInfo: {
    marginTop: theme.spacing(15), // Add top margin to create space
    // Your styles for the text info container
  },

  gradientCard: {
    borderTopLeftRadius: theme.spacing(0.5),
    borderTopRightRadius: theme.spacing(0.5),
    padding: theme.spacing(1, 0, 0, 0),
    backgroundImage: `linear-gradient(180deg, ${colors.GRADIENT_YELLOW} 30%, ${colors.GRADIENT_ORANGE} 90%)`,
  },

  noHover: {
    color: colors.WHITE,
    '&:hover': {
      backgroundColor: colors.TRANSPARENT,
      cursor: 'default'
    }
  },

  bulletList: {
    overflow: 'hidden',
    listStyleType: 'disc',
    '& li': {
      display: 'list-item',
      listStylePosition: 'block', 
    },
  },

  numberList: {
    overflow: 'hidden',
    listStyleType: 'decimal',
    '& li': {
      display: 'list-item',
      listStylePosition: 'block', 
      marginBottom: theme.spacing(0.5),
    },
  },
 
}));

export default useStyles;