import { makeStyles } from '@material-ui/styles';
import colors from '../../utils/colors'

const useStyles = makeStyles(theme => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
    },
    li: {
      listStyle: 'none',
    },
  },

  appBar: {
    margin: 0,
    backgroundImage: `linear-gradient(180deg, ${colors.GRADIENT_YELLOW} 30%, ${colors.GRADIENT_ORANGE} 90%)`,
    boxShadow: 'none',
    borderBottomLeftRadius: 20,
    borderBottomRightRadius: 20,
    position: 'fixed'
  },

  subTabWithSibling: {
    paddingRight: 20
  },

  toolbar: {
    flexWrap: 'wrap',
    position: 'relative',
    margin: theme.spacing(1, 3, 1, 3),
    '& h3': {
      position: 'absolute',
      left: '44%',
      // flex: 1,
      [theme.breakpoints.down("sm")]: {
        display: 'none'
      }
    }
  },

  logo: {
    height: 40,
    [theme.breakpoints.down("xs")]: {
      height: 28
    }
  },

  toolbarTitle: {
    display: 'flex',
    flexGrow: 1,
  },

  searchbar: {
    borderRadius: '50px',
    background: '#ffffff',
    padding: '0 10px',
    '& svg': {
      transform: 'rotate(90deg)'
    },
    [theme.breakpoints.down("sm")]: {
      display: 'none'
    }
  },

  menuButton: {
    display: 'flex',
    flexDirection: 'row',
    // flex: 1,
    paddingLeft: '20px',
    justifyContent: 'flex-end',

    '& > .MuiTypography-root': {
      alignSelf: 'center',
      marginRight: theme.spacing(2),
    },

    [theme.breakpoints.down("xs")]: {
      '& h6': {
        display: 'none'
      }
    }
  },

  button: {
    marginRight: theme.spacing(1),
  },

  // TABS 
  container: {
    width: 'fit-content',
    paddingLeft: 20
  },

  tab: {
    color: colors.WHITE,
    fontWeight: 'bold',
    textTransform: 'capitalize',
    minWidth: '50px',
  },

  tabsContainer: {
    display: 'flex', 
    flex: 1, 
    flexDirection: 'row', 
    justifyContent: 'space-between',
    '& .MuiTab-wrapper':{
      flexDirection: 'row',
      justifyContent: 'space-around'
      },
    '& .MuiTabs-root:nth-child(1)': {
      flex: 0.9
    },
    '& .subTabs .MuiTabs-flexContainer': {
      paddingRight: 20
    },
    [theme.breakpoints.down("sm")]: {
      display: 'block',
      '& .subTabs .MuiTabs-flexContainer': {
        display: 'flex',
        justifyContent: 'center',
      },
      
    }
  },

  subTabContainer: {
    '& > .MuiButton-root': {
      textTransform: 'none',
      borderRadius: 20,
      fontSize: 13,
      height: 'max-content'
    },

    '& > .MuiButton-root > .MuiButton-label': {
      color : colors.PRIMARY
    }    
  },

  subSettingIcon: {
    color: colors.PRIMARY,
    justifyContent: 'flex-end'
  },


  settingsContainer: {
    width: 250,
  },

  gradientCard: {
    borderTopLeftRadius: theme.spacing(0.5),
    borderTopRightRadius: theme.spacing(0.5),
    padding: theme.spacing(1, 0, 0, 0),
    backgroundImage: `linear-gradient(180deg, ${colors.GRADIENT_YELLOW} 30%, ${colors.GRADIENT_ORANGE} 90%)`,
  },

  noHover: {
    color: colors.WHITE,
    '&:hover': {
      backgroundColor: colors.TRANSPARENT,
      cursor: 'default'
    }
  },

  customMenuItem: {
    padding: theme.spacing(1, 4, 1, 4),

    '& .MuiListItemText-secondary': {
      color: colors.WHITE,
      fontSize: 12,
      whiteSpace: 'normal'
    },
  },

  divider: {
    backgroundColor: colors.WHITE,
    margin: theme.spacing(0, 2, 0, 2),
  },

  menuItemMemberType: {
    color: colors.WHITE,
    '& .MuiListItemIcon-root.beforeEnter': {
      justifyContent: 'flex-end',
      color: 'inherit'
    },
    '& .MuiListItemIcon-root.afterEnter': {
      color: 'inherit'
    }
  },
}));

export default useStyles;