import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../utils/colors';

const useStyles = makeStyles(theme => ({

  // Account Info
  gridItem: {
    width: '100%',
  },

  accountInfo: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'space-around',
  },

  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80%',

    '& > .MuiTypography-root:first-of-type': {
      marginBottom: theme.spacing(6),
      // marginTop: theme.spacing(1),
    },

    '& > .MuiGrid-root:first-of-type': {
      position: 'relative'
    }
  },

  inputContainer: {
    display:"grid",
    justifyContent:"center",
    right: 100,
    '& > .MuiFormControl-root': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(2),
    },

    '& > .MuiTypography-root': {
      marginBottom: theme.spacing(5),
      // marginTop: theme.spacing(1),
    },

    '& > h6': {
      // marginTop: theme.spacing(4),
    }
  },

  textField: {
    width: 270,
    '& .MuiFormGroup-root .MuiFormControlLabel-root .MuiTypography-root': {
      color: colors.TEXT_GRAY
    },
    '& > .MuiFormHelperText-root': {
      color: 'red'
    }
  },

  // MODAL
  modal: {
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
  },

  paper: {
    backgroundColor: colors.WHITE,
    borderRadius: 15,
    position: 'absolute',
    minWidth: 350,
    left: 'calc(50% - 215px)',
    maxWidth: 350,
    padding: theme.spacing(5, 5, 5),
    textAlign: 'center',
    top: '20%',

    '& > .MuiTypography-root': {
      marginBottom: theme.spacing(2),
      marginTop: theme.spacing(2),
    },

    '& > .MuiButtonBase-root': {
      marginTop: theme.spacing(3),
    }
  },

  image: {
    marginBottom: theme.spacing(2),
    height: 64,
    width: 64,
  },

  passwordStrengthContainer: {
    display: 'flex',
    justifyContent: 'center',
  },

  passwordStrength: {
    paddingTop:20,
    padding:5,
    width:270,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    '& > p, .MuiListItemText-root': {
      color: colors.TEXT_GRAY
    },
    '& > ul > li': {
      padding: 0
    },
    '& > .MuiButtonBase-root': {
      width: 120,
      borderRadius: 40,
      alignSelf: 'flex-end',
      margin: theme.spacing(5, 1)
    },
  },

  header:{
    marginTop:'10vh',
  
  },

  policy:{
     marginTop:'auto'
  },

  checkIconGreen: {
    color: colors.GREEN
  },

  middleSection:{
    display:"grid"
  }


}));

export default useStyles;