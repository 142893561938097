
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga'
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer, createTransform } from 'redux-persist';
import {stringify , parse} from 'flatted';

import rootSaga from './redux/sagas';
import rootReducer from './redux/reducers';
import { composeWithDevTools } from 'redux-devtools-extension';


const selectivelyPersistMessageReducer = createTransform(
  (inboundState, key) => {
      return inboundState;
  }
);

const transformCircularObject = createTransform(
  (inboundState, key) => {
      return inboundState;
  },
  (outboundState, key) => {
      return outboundState;
  }
)

const persistConfig = {
    key: 'root',
    storage,
    whitelist : ['User', 'Tutorial',  'Search', 'Calendar', 'Lookup', 'app'],
    blacklist: ['Message', 'Notification','Connection'],
    transforms: [selectivelyPersistMessageReducer, transformCircularObject]  
  }


const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = composeWithDevTools({
  trace: true
});


const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(sagaMiddleware))
);

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);

export default store;