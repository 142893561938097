import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Box, Checkbox, CircularProgress, FormControl, FormGroup, RadioGroup, FormControlLabel, Radio, Typography, TextField } from '@material-ui/core';

import useStyles from './style';
import {
  LOOK_UP_ALCOHOL,
  LOOK_UP_BODY_TYPE,
  LOOK_UP_EDUC_LEVEL,
  LOOK_UP_ETHNICITY,
  LOOK_UP_EYE_COLOR,
  LOOK_UP_HAIR_COLOR,
  LOOK_UP_INCOME_LEVEL,
  LOOK_UP_KID_SITUATION,
  LOOK_UP_LANGUAGE,
  LOOK_UP_POS_POLITICS,
  LOOK_UP_PREF_SMOKING,
  LOOK_UP_RELATIONSHIP_TYPE,
  LOOK_UP_HOBBY,
  LOOK_UP_RELIG_BELIEF,
  LOOK_UP_CODE_NOP,
  LOOK_UP_PROFESSION,
  LOOK_UP_PET_POLICY,
  LOOK_UP_EXER_REGIMEN,
  TOAST_AXIOS_REQUEST_ERROR,
  UPD_MODE_USER_BODY_TYPE,
  UPD_MODE_USER_EDUC_LEVEL,
  UPD_MODE_USER_ETHNICITY,
  UPD_MODE_USER_EYE_COLOR,
  UPD_MODE_USER_HAIR_COLOR,
  UPD_MODE_USER_INCOME_LEVEL,
  UPD_MODE_USER_KID_SITUATION,
  UPD_MODE_USER_LANGUAGE,
  UPD_MODE_USER_POS_POLITICS,
  UPD_MODE_USER_PREF_ALCOHOL,
  UPD_MODE_USER_PREF_SMOKING,
  UPD_MODE_USER_RELATIONSHIP_TYPE,
  UPD_MODE_USER_HOBBY,
  UPD_MODE_USER_RELIGION,
  UPD_MODE_USER_PROFESSION,
  UPD_MODE_USER_PET_POLICY,
  UPD_MODE_USER_EXER_REGIMEN,
  ALERT_SEVERITY_ERROR,
  HTTP_STATUS,
} from '../../../utils/constants'
import { userRequest } from '../../../service/requests';
import { handleHttpError } from '../../../utils/helper';

const Preference = forwardRef((props, ref) => {
  
  const classes = useStyles();
  const { getLookUpFields, isLoading, index, Lookup, multiple, required, showAlert, updateUserField, user, next, callback } = props;
  const arrLookUp = [
    {
      title           : 'What type of relationship are you looking for?',
      lookUp          : LOOK_UP_RELATIONSHIP_TYPE,
      lookUpPreference: 'relationship_type',
      updMode         : UPD_MODE_USER_RELATIONSHIP_TYPE,
      lookUpState     : 'lookUpRelType',
      // userField       : 'relationshipType'
    },
    {
      title           : 'What is your primary language?',
      lookUp          : LOOK_UP_LANGUAGE,
      lookUpPreference: 'language_code',
      updMode         : UPD_MODE_USER_LANGUAGE,
      lookUpState     : 'lookUpLanguage',
      // userField       : 'relationshipType'
    },
    {
      title           : 'What is your body build?',
      lookUp          : LOOK_UP_BODY_TYPE,
      lookUpPreference: 'body_type',
      updMode         : UPD_MODE_USER_BODY_TYPE,
      lookUpState     : 'lookUpBodyType',
      // userField       : 'bodyType'
    },
    {
      title           : 'What is your hair color?',
      lookUp          : LOOK_UP_HAIR_COLOR,
      lookUpPreference: 'hair_color',
      updMode         : UPD_MODE_USER_HAIR_COLOR,
      lookUpState     : 'lookUpHairColor',
      // userField       : 'hairColor'
    },
    {
      title           : 'What is your eye color?',
      lookUp          : LOOK_UP_EYE_COLOR,
      lookUpPreference: 'eye_color',
      updMode         : UPD_MODE_USER_EYE_COLOR,
      lookUpState     : 'lookUpEyeColor',
      // userField       : 'eyeColor'
    },
    {
      title           : 'What is your exercise regimen?',
      lookUp          : LOOK_UP_EXER_REGIMEN,
      lookUpPreference: 'exer_regimen',
      updMode         : UPD_MODE_USER_EXER_REGIMEN,
      lookUpState     : 'lookUpExerRegimen',
    },
    {
      title           : 'What is your ethnicity?',
      lookUp          : LOOK_UP_ETHNICITY,
      lookUpPreference: 'ethnicity',
      updMode         : UPD_MODE_USER_ETHNICITY,
      lookUpState     : 'lookUpEthnicity',
      // userField       : 'ethnicity'
    },
    {
      title           : 'What is your smoking tolerance?',
      lookUp          : LOOK_UP_PREF_SMOKING,
      lookUpPreference: 'pref_smoking',
      updMode         : UPD_MODE_USER_PREF_SMOKING,
      lookUpState     : 'lookUpPrefSmoking',
      // userField       : 'prefSmoking'
    },
    {
      title           : 'What is your alcohol tolerance?',
      lookUp          : LOOK_UP_ALCOHOL,
      lookUpPreference: 'pref_alcohol',
      updMode         : UPD_MODE_USER_PREF_ALCOHOL,
      lookUpState     : 'lookUpPrefAlcohol',
      // userField       : 'prefAlcohol'
    },
    {
      title           : 'What is your position on kids?',
      lookUp          : LOOK_UP_KID_SITUATION,
      lookUpPreference: 'kid_situation',
      updMode         : UPD_MODE_USER_KID_SITUATION,
      lookUpState     : 'lookUpKidSituation',
      // userField       : 'kidSituation'
    },
    {
      title           : 'What is your highest education level attained?',
      lookUp          : LOOK_UP_EDUC_LEVEL,
      lookUpPreference: 'educ_level',
      updMode         : UPD_MODE_USER_EDUC_LEVEL,
      lookUpState     : 'lookUpEducLevel',
      // userField       : 'educLevel'
    },
    {
      title           : 'What is your profession?',
      lookUp          : LOOK_UP_PROFESSION,
      lookUpPreference: 'profession',
      updMode         : UPD_MODE_USER_PROFESSION,
      lookUpState     : 'lookUpProfession',
    },
    {
      title           : 'What is your pet policy?',
      lookUp          : LOOK_UP_PET_POLICY,
      lookUpPreference: 'pet_policy',
      updMode         : UPD_MODE_USER_PET_POLICY,
      lookUpState     : 'lookUpPetPolicy',
    },
    // {
    //   title           : 'What is your income level (Annual)?',
    //   lookUp          : LOOK_UP_INCOME_LEVEL,
    //   lookUpPreference: 'income_level',
    //   updMode         : UPD_MODE_USER_INCOME_LEVEL,
    //   isNoPreference  : true,
    //   lookUpState     : 'lookUpIncomeLevel',
    //   // userField       : 'incomeLevel'
    // },
    {
      title           : 'What is your religion?',
      lookUp          : LOOK_UP_RELIG_BELIEF,
      lookUpPreference: 'relig_belief',
      updMode         : UPD_MODE_USER_RELIGION,
      lookUpState     : 'lookUpReligBelief',
      // userField       : 'relationshipType'
    },
    {
      title           : 'What is your political belief?',
      lookUp          : LOOK_UP_POS_POLITICS,
      lookUpPreference: 'pos_politics',
      updMode         : UPD_MODE_USER_POS_POLITICS,
      lookUpState     : 'lookUpPosPolitics',
      // userField       : 'posPolitics'
    },
    {
      title           : 'What are your hobbies?',
      lookUp          : LOOK_UP_HOBBY,
      lookUpPreference: 'hobby_code',
      updMode         : UPD_MODE_USER_HOBBY,
      lookUpState     : 'lookUpHobbyCode',
    },
  ]
  const [isSaving, setIsSaving] = useState(false);
  const [preference, setPreference] = useState(multiple ? [] : '');
  const [religion, setReligion] = useState('');

  useEffect(() => {
    console.log('Preference.useEffect()');

    performLookupRequest();
    callback(isSaving);

  }, [isSaving]);

  const performLookupRequest = () => {
    console.log('performLookupRequest()');

    if (Lookup[arrLookUp[index].lookUpState].length == 0) {
      const lookUp = arrLookUp[index].lookUp;

      getLookUpFields(lookUp);
    }
  }
  
  const selectPreference = event => {
    console.log('Preference.selectPreference()');
    console.log('event : ', event.target.value);
    
    
    if (multiple) {
      if(preference.includes(event.target.value)) {
        setPreference(preference.filter(value => value !== event.target.value));
      } else {
        setPreference([...preference, event.target.value]);
      }
    } else {
      setPreference(event.target.value);
    }

  };

  const handleNext = () => {
    console.log('preference : ', preference);
    
    if (preference === '' && required) {
      const message = multiple ? 'Required. Select at least one.' : 'Required. Select one.';
      showAlert(ALERT_SEVERITY_ERROR, message);

      return
    }

    const updMode                    = arrLookUp[index].updMode
    const lookUpPreference           = arrLookUp[index].lookUpPreference

    setIsSaving(prevIsSaving => !prevIsSaving);

    let payload = {
      user_id             : user.userId,
      upd_mode            : updMode,
      who_updated         : user.userId,
      [lookUpPreference]  : preference.toString()
    }

    if (religion.trim().length && preference === 'OTH') {
      payload.relig_belief_desc = religion;
    }

    userRequest.updateUser(payload).then(response => {
      console.log('response : ', response);

      if (response?.data?.status?.code != HTTP_STATUS._200) {
        let { status } = response?.data;
        throw new Error(`${status?.code} ${status?.description}`);
      } 
      if (index === 0) {

        setIsSaving(prevIsSaving => !prevIsSaving);          
        next();
      } else if (arrLookUp.length > index + 1) {

        updateUserField(response?.data?.userDetail?.userField);
        setIsSaving(prevIsSaving => !prevIsSaving); 
        setPreference('');
        next();
      } else {
        setIsSaving(prevIsSaving => !prevIsSaving); 
        next();
      }
    }).catch(error => {
      setIsSaving(prevIsSaving => !prevIsSaving); 
      handleHttpError({
        error,
        request: 'userRequest.updateUser::src/views/registration/preference'
      })
    });
  }
  const handleReligBeliefInputChange = (event) => {
    const value = event.target.value;
    setReligion(value);
  }

  const isInColContentRange = (colNum, innerIndex) => {
    let condition;
    const preferenceLength = Lookup[arrLookUp[index].lookUpState].length;
    const colContentCount = Math.ceil(preferenceLength / 3);
    switch (colNum) {
      case 1:
        condition = (innerIndex >= 0 && innerIndex < colContentCount);
        break;
      case 2:
        condition = (innerIndex >= colContentCount && innerIndex < (colContentCount * colNum));
        break;
      case 3:
        condition = (innerIndex >= (colContentCount * 2))
        break;
      default:
        break;
    }
    return condition;
  }

  useImperativeHandle(ref, () => ({
    triggerNext() {
      handleNext()
    }
  }));

  return (
    <Box className={classes.box}>
      <Typography variant="h5" color="primary" className={`text-bold text-center mb-20`}>
        About You
      </Typography>

      <Typography variant="h6" className={`text-bold text-center mb-20`} color="textPrimary">
        {arrLookUp[index].title}
      </Typography>

      {
        isLoading ?
          <CircularProgress />

        : multiple ? 
          <FormControl component="fieldset">
            <FormGroup className={classes.formControl}>
              {
                [1, 2, 3].map(containerNumber => {
                  return (
                    <Box>
                      {
                        Lookup[arrLookUp[index].lookUpState].map((value, index) => 
                          {
                            if (isInColContentRange(containerNumber, index)) {
                              return (
                                <FormControlLabel
                                  key={index}
                                  value={value.code}
                                  control={<Checkbox checked={preference.includes(value.code)} color="primary"/>}
                                  onChange={selectPreference}
                                  label={value.description}
                                  labelPlacement="end"
                                />
                              )
                            }
                          }
                        )
                      }
                    </Box>
                  )
                })
              }
            </FormGroup>
          </FormControl>
        : 
          <Box>
            <RadioGroup aria-label="position" className={classes.radioGroup} name="preference" value={preference} onChange={selectPreference} >
              {
                Lookup[arrLookUp[index].lookUpState].filter((value) => value.code != LOOK_UP_CODE_NOP).map((value, index) => 
                  <>
                    <FormControlLabel
                      key={index}
                      value={value.code}
                      control={<Radio color="primary" />}
                      label={value.description}
                      labelPlacement="end"
                    />
                    {
                      (value.code === 'OTH' && preference === 'OTH') && 
                      <TextField name="religion" className={classes.textField} 
                        size="small" variant="outlined" placeholder="Please specify" 
                        value={religion} onChange={handleReligBeliefInputChange}
                      />
                    }
                  </>
                )
              }
              </RadioGroup>
            </Box>
      }
    </Box>
  )

});

export default Preference;