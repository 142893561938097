import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { parseString } from 'xml2js';
import countryByAbbreviation from 'country-json/src/country-by-abbreviation.json';
import { Box, Typography, TextField } from '@material-ui/core';
import {
  UNITED_STATES,
  TOAST_AXIOS_REQUEST_ERROR,
  UPD_MODE_USER_ADDRESS,
  LOOK_UP_GEOCODING_API_KEY,
  HTTP_STATUS
} from '../../../utils/constants'

import { userRequest, thirdPartyRequest, lookupRequest, geoCodeRequest } from '../../../service/requests';

import {useStyles}      from './style';
import Select from '../../../components/Select';
import { handleHttpError } from '../../../utils/helper';

const Address = forwardRef((props, ref) => {
  const classes = useStyles();

  const { callback, next, updateUserField, user, Lookup } = props;
  const countryNames = Lookup.lookUpCountry ? Lookup.lookUpCountry : [];
  const stateNames = Lookup.lookUpState ? Lookup.lookUpState : [];
  const cityNames = Lookup.lookUpCity ? Lookup.lookUpCity : [];
  const [isSaving, setIsSaving] = useState(false);
  const [matchedCountries, setMatchedCountries] = useState([]);
  
  const [state, setState] = useState({
    zipCode           : '',
    isZipCodeError    : false,
    zipCodeErrorDesc  : '',
    city              : '',
    isCityError       : false,
    cityErrorDesc     : '',
    stateAddress       : '',
    isStateError      : false,
    stateErrorDesc    : '',
    country           : UNITED_STATES,
    isCountryError    : false,
    countryErrorDesc  : '',
    geocodeApiKey     : '',
    selectedCountry   : {
                      label:UNITED_STATES,
                      value:'233',
                      code:'US'
    },
    selectedState     : {
                      label:'',
                      value:'',
                      code:''
    },
    selectedCity     : {
                      label:'',
                      value:'',
                      code:'',
    searchCity       : false
    },
    gettingStateAndCity: false,
  });

  const { 
    zipCode, isZipCodeError, zipCodeErrorDesc,
    city, isCityError, cityErrorDesc, 
    stateAddress, isStateError, stateErrorDesc,
    country, isCountryError, countryErrorDesc, geocodeApiKey,
    
  } = state;

  useEffect(() => {
    console.log('Address.useEffect()');
    
    if (zipCode.length === 5 && country == UNITED_STATES) {
      setState({
        ...state,
        gettingStateAndCity: true
      })
      getCityAndState();
    }

    let matchedCountries = []

    countryByAbbreviation.map(countryItem => {
      if (countryItem.country.toLowerCase().match(country.toLowerCase())) {
        matchedCountries.push(countryItem);
      }
    });

    setMatchedCountries(matchedCountries);

    // this.setState(prevState => ({ 
    //   hasCountryError    : prevState.hasCountryError ? !prevState.hasCountryError : prevState.hasCountryError,
    //   hasCountrySelected : false, 
    // }));

  }, [zipCode]);

  useEffect(() => {
    callback(isSaving);
  }, [isSaving])

  useEffect(() => {
    (async function(){
      const result = await lookupRequest.getCredentialLookup(LOOK_UP_GEOCODING_API_KEY).catch(error => {
        handleHttpError({
          error,
          request: 'lookupRequest.getCredentialLookup::src/views/registration/address'
        })
      })
      setState({...state, geocodeApiKey : result.data.credential.value})
    })();
  },[]);


  const getCityAndState = () => {
    const zipCodeXML = `<CityStateLookupRequest USERID="699MPHIN4829"><ZipCode ID="0"><Zip5>${zipCode}</Zip5></ZipCode></CityStateLookupRequest>`;

    thirdPartyRequest.getCityState({
      XML : zipCodeXML
    }).then(response => {
      console.log('response : ', response);

      parseString(response.data, (error, result) => {
        console.log('result : ', result);
        console.log('error  : ', error);

        if (result.CityStateLookupResponse.ZipCode[0].Error == undefined) {
          
          const selectedState = stateNames.find(state=>state.code.trim().toLowerCase() === result.CityStateLookupResponse.ZipCode[0].State[0].trim().toLowerCase())
          props.setCityLookUpTrigger({value:selectedState.id})
          setState({
            ...state,
            city  : result.CityStateLookupResponse.ZipCode[0].City[0].toLowerCase().split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
            stateAddress : result.CityStateLookupResponse.ZipCode[0].State[0],
            selectedState : {
              value: selectedState.id,
              label: selectedState.name,
              code: selectedState.code
            },
            searchCity: true,
            gettingStateAndCity: false,
          });

          // this.setState(prevState => ({
          //   hasZipCodeError       : prevState.hasZipCodeError ? !prevState.hasZipCodeError : prevState.hasZipCodeError,
          //   zipCodeLookupResponse : result.CityStateLookupResponse.ZipCode[0]
          // }));
        } else {
          setState({
            ...state,
            city  : '',
            stateAddress : '',
            gettingStateAndCity: false,
          });
          // this.setState({
          //   zipCodeLookupResponse : result.CityStateLookupResponse.ZipCode[0]
          // });
        }
      });

    }).catch(error => { 
      handleHttpError({
        error,
        request: 'thirdPartyRequest.getCityState::src/views/registration/address'
      })
    });
  }

  useEffect(()=>{
        if(state.searchCity && cityNames.length > 0 ){
          const selectedCity = cityNames.find(city=>state.city.trim().toLowerCase() === city.name.trim().toLowerCase())

          if(selectedCity){
            setState({
              ...state,
              searchCity: false,
              selectedCity:{
                label:selectedCity.name,
                value:selectedCity.id
              }
            });
          }
        }
  },[cityNames])


  const handleInputChange = event => {

    let value = event.target.value;

    if (event.target.name == 'zipCode') {
      value = value.replace(/[^0-9]/g, '');

      if (value.length > 5)
        return
        setState({
          ...state,
          [event.target.name]: value,
          selectedState     : {
            label:'',
            value:'',
            code:''
          },
          selectedCity     : {
            label:'',
            value:'',
            code:'',
          },
          city: '',
          stateAddress: ''
        });
        return
    }

    setState({
      ...state,
      [event.target.name]: event.target.value
    });
  };

  
  const getCoordinates = async (address) => { 
    console.log('getCoordinates()')
    const response = await geoCodeRequest.getGeoCodeAddress({
      address,
      key            : geocodeApiKey
    }).catch(error => {
      handleHttpError({
        error,
        request: 'geoCodeRequest.getGeoCodeAddress::src/views/registration/address'
      })
    })
    const { lat, lng } = await response?.data?.results[0]?.geometry?.location;

    return {lat, lng}
  }

  const handleNext = async () => {
    if (zipCode === '' || city === '' || stateAddress === '' || country === '' ) {

      setState({
        ...state,
        isZipCodeError    : zipCode === '' ? true : false,
        zipCodeErrorDesc  : zipCode === '' ? 'Required Postal Code' : '',
        isCityError       : city === '' ? true : false,
        cityErrorDesc     : city === '' ? 'Required City Address' : '',
        isStateError      : stateAddress === '' ? true : false,
        stateErrorDesc    : stateAddress === '' ? 'Required State Address' : '',
        isCountryError    : country === '' ? true : false,
        countryErrorDesc  : country === '' ? 'Required Country' : '',
      })

      return
    }
    setIsSaving(prevIsSaving => !prevIsSaving);
    const {lat, lng} = await getCoordinates({zipCode : zipCode, city : city, stateAddress : stateAddress, country : country})
    userRequest.updateUser({
      user_id       : user.userId,
      upd_mode      : UPD_MODE_USER_ADDRESS,
      who_updated   : user.userId,
      zip_code      : zipCode,
      city          : city,
      state         : stateAddress,
      country       : country,
      latitude      : lat,
      longitude     : lng

    }).then(response => {
      console.log('response : ', response);

      if (response?.data?.status?.code != HTTP_STATUS._200) {
        let { status } = response?.data;
        throw new Error(`${status?.code} ${status?.description}`);
      }
      const userField = response?.data?.userDetail?.userField;
      updateUserField(userField);
      setIsSaving(prevIsSaving => !prevIsSaving);
      next();
    }).catch(error => {
      setIsSaving(prevIsSaving => !prevIsSaving);
      handleHttpError({
        error,
        request: 'userRequest.updateUser::src/views/registration/address'
      })
    });
  }  

  useImperativeHandle(ref, () => ({
    triggerNext() {
      handleNext()
    }
  }));
  
  const selectChange = (field, e)=>{
   
    if(field === 'country'){
      setState({
        ...state,
        zipCode: '',
        city: '',
        stateAddress: '',
        selectedCountry: e,
        selectedState:{
          label:'',
          value:'',
          code:''
        },
        selectedCity:{
          label:'',
          value:'',
        },
        [field]: e.label
      })
    }

    if(field === 'stateAddress') {
      setState({
        ...state,
        city: '',
        selectedState: e,
        selectedCity:{
          label:'',
          value:'',
        },
        [field]: e.label
      })
    }

    if(field === 'city') {
      setState({
        ...state,
        selectedCity:e,
        [field]: e.label
      })
    }

   
  }


  return (
    <Box ref={ref} className={`${classes.box} ${classes.address}`}>

      <Typography variant="h5" color="primary" className={`text-bold text-center mb-20`}>
        About You
      </Typography>

      <Typography variant="h6" className={`text-bold text-center mb-20`} color="textPrimary">
        Your home location?
      </Typography>

      <Select
        options={countryNames.map((country)=>{
          return {
            label: country.name,
            value: country.id,
            code: country.code,
          }
        })}
        className={classes.selectBox}
        inputProps={{
          placeholder:"Select Country",
          onChange:(e)=>{
            selectChange('country', e)
          },
          value:state.selectedCountry
        }}
      />


      <TextField
        label="Postal Code"
        name="zipCode"
        value={zipCode}
        onChange={handleInputChange}
        className={classes.textField}
        required
        error={isZipCodeError}
        helperText={zipCodeErrorDesc}
        variant="outlined"
        size="small"
        maxLength={5}
      />

      {state.country !== UNITED_STATES && <>
        <TextField
        label="City"
        name="city"
        value={city}
        onChange={handleInputChange}
        className={classes.textField}
        required
        error={isCityError}
        helperText={cityErrorDesc}
        variant='outlined'
        size='small'
      />
       <TextField
        label="State"
        name="stateAddress"
        value={stateAddress}
        onChange={handleInputChange}
        className={classes.textField}
        required
        error={isStateError}
        helperText={stateErrorDesc}
        variant='outlined'
        size='small'
      />
      </>
      }

     {state.country.trim().toLowerCase() == UNITED_STATES.trim().toLowerCase() && 
        <>
          <Select
          options={stateNames.map((state)=>{
          return {
            label: state.name,
            value: state.id,
            code: state.code,
              }
            })}
            isLoading={state.gettingStateAndCity}
            className={classes.selectBox}
            inputProps={{
              placeholder:"Select State",
              onChange:(e)=>{
                selectChange('stateAddress', e)
                props.setCityLookUpTrigger({value: e.value})
              },
              value:state.selectedState.value !== ''? state.selectedState: null
            }
          
          }
          />
          {isStateError ? <Typography className={classes.validationMessage}>Required State Address</Typography> : null}
      {cityNames.length > 0 ? 
        <>
        <Select
          options={cityNames.map((city)=>{
          return {
            label: city.name,
            value: city.id,
          }
        })}
        inputProps={{
          value:state.selectedCity.value !== ''? state.selectedCity: null,
          placeholder:"Select City",
          onChange:(e)=>{
            selectChange('city', e)
          }
        }}
        isLoading={state.gettingStateAndCity}
          className={classes.selectBox}
        />
        {state.isCityError ? <Typography className={classes.validationMessage}>Required City Address</Typography> : null}
        </> : 
        <TextField
        label="City"
        name="city"
        value={city}
        onChange={handleInputChange}
        className={classes.textField}
        required
        error={isCityError}
        helperText={cityErrorDesc}
        variant='outlined'
        size='small'
      />
      }
      </>
     }

      

    </Box>
  )
});

export default Address;