import React from 'react';
import StarIcon from '@material-ui/icons/Star';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import colors from '../../utils/colors';


const Rating = (props) => {
  const { value } = props;

  let icon = []

  for (let i = 0; i < 5; i++) {
    let starCount = i + 1;
    if (value === (starCount - 0.5)) {
      icon.push(
        <StarHalfIcon style={{fill: colors.YELLOW}} key={i} />
      );
    } else if (value < starCount) {
      icon.push(
        <StarBorderIcon style={{fill: colors.YELLOW}} key={i} />
      );
    } else if (value >= starCount) {
      icon.push(
        <StarIcon style={{fill: colors.YELLOW}} key={i} />
      )
    }
  }

  return (
    <div>
      {icon}
    </div>
  )
}

export default Rating;