import React, { useState } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import SnackBar from '../components/snackbar';

const AuthRoute = (props) => {
	const { authenticated, exact, component : Component, layout : Layout, path } = props;
	const location = useLocation();

	const [open, setAlertOpen] = useState(false);
	const [message, setAlertMessage] = useState('');
	const [severity, setAlertSeverity] = useState('success');

	const showAlert = (severity, message) => {
		setAlertOpen(true);
		setAlertMessage(message);
		setAlertSeverity(severity)
	}

	return (
		<Route
			exact={exact}
			path={path}
			render={(props) =>
				authenticated ? (
					<Redirect
						to={{
							pathname: '/',
							state: { from: props.location },
						}}
					/>
				) : (location.pathname === '/refer') ?
						<Redirect
							to={{
								pathname: '/registration',
								state: { from: props.location },
							}}
						/> :
						<Layout>
							<Component {...props} showAlert={showAlert} />
							<SnackBar open={open} setOpen={setAlertOpen} message={message} severity={severity} />
						</Layout>
			}
		/>
	)
};

export default AuthRoute;