import action from "./action";

const initState = {

  notificationList                    : [],
  notificationListPage                : 1,
  isNotificationLoading               : false,
  isNotificationLoaded                : false,
  isAllNotificationFetched            : false,
  
  notificationCallLogList             : [],
  notificationCallLogPage             : 1,
  isNotificationLogsLoading           : false,
  isNotificationLogsLoaded            : false,

  callLogStatus                       : undefined,
  isMarkingNotificationReadInProgress : false,
  badgeCount                          : {
    calendar                 : 0,
    chat                     : 0, 
    nearMe                   : 0,
    notification             : 0,
    recentViews              : 0,
    search                   : 0
  },
};

export default function notificationReducer(state = initState, act) {

  switch (act.type) {

    case action.NOTIFICATION_GET:
      console.log("redux/notification/reducer.js:NOTIFICATION_GET");
      return {
        ...state,
        isNotificationLoading : true
      }

    case action.NOTIFICATION_GET_SUCCESS:
      console.log("redux/notification/reducer.js:NOTIFICATION_GET_SUCCESS", act.payload);
      let unseenNotificationCount = 0;

      act.payload.notificationList.forEach(notification => {
        unseenNotificationCount = (notification.isSeen == 0) ? unseenNotificationCount + 1 : unseenNotificationCount;
      });
       
      if(act.payload.notificationList.length != 0){
        console.log('here')
        return {
          ...state,
          notificationList      :  [...state.notificationList, ...act.payload.notificationList ],
          badgeCount            : { ...state.badgeCount, notification : unseenNotificationCount },
          isNotificationLoading : false,
          isNotificationLoaded  : true,
          notificationListPage  : act.payload.notificationListPage
        }
      }else{
        return {
          ...state,
          notificationList         :  [...state.notificationList, ...act.payload.notificationList ],
          badgeCount               : { ...state.badgeCount, notification : unseenNotificationCount },
          isAllNotificationFetched : true,
          isNotificationLoading    : false,
          isNotificationLoaded     : true,
          notificationListPage     : act.payload.notificationListPage
        }
      }

    case action.NOTIFICATION_GET_ERROR:
      console.log("redux/notification/reducer.js:NOTIFICATION_GET_ERROR");
      return {
        ...state,
        isNotificationLoading : false
      }

    case action.NOTIFICATION_SEEN_SUCCESS:
      console.log("redux/notification/reducer.js:NOTIFICATION_SEEN_SUCCESS");
      return {
        ...state,
        notificationList      : state.notificationList.map((notificationListItem) => {
          if (notificationListItem.notifId != act.payload.notifId) {
            return notificationListItem
          } else {
            return act.payload
          }
        }),
        badgeCount             : { ...state.badgeCount, notification : state.badgeCount.notification - 1 }
      }

    case action.NOTIFICATION_SEARCH_UPDATE:
      console.log("redux/notification/reducer.js:NOTIFICATION_SEARCH_UPDATE");
      return {
        ...state,
        badgeCount             : { ...state.badgeCount, search : act.payload }
      }

    case action.NOTIFICATION_SEARCH_SEEN:
      console.log("redux/notification/reducer.js:NOTIFICATION_SEARCH_SEEN");
      return {
        ...state,
        badgeCount             : { ...state.badgeCount, search : state.badgeCount.search - 1 }
      }

      case action.NOTIFICATION_RECENT_VIEWS_UPDATE:
      console.log("redux/notification/reducer.js:NOTIFICATION_RECENT_VIEWS_UPDATE");
      return {
        ...state,
        badgeCount             : { ...state.badgeCount, recentViews : act.payload }
      }

    case action.NOTIFICATION_RECENT_VIEWS_SEEN:
      console.log("redux/notification/reducer.js:NOTIFICATION_RECENT_VIEWS_SEEN");
      return {
        ...state,
        badgeCount             : { ...state.badgeCount, recentViews : state.badgeCount.recentViews - 1 }
      }

      case action.NOTIFICATION_CALENDAR_UPDATE:
      console.log("redux/notification/reducer.js:NOTIFICATION_CALENDAR_UPDATE");
      return {
        ...state,
        badgeCount             : { ...state.badgeCount, calendar : act.payload }
      }

    case action.NOTIFICATION_CALENDAR_SEEN_SUCCESS:
      console.log("redux/notification/reducer.js:NOTIFICATION_CALENDAR_SEEN_SUCCESS");
      return {
        ...state,
        badgeCount             : { ...state.badgeCount, calendar : state.badgeCount.calendar - 1 }
      }

    case action.NOTIFICATION_CHAT_UPDATE:
      console.log("redux/notification/reducer.js:NOTIFICATION_CHAT_UPDATE");
      return {
        ...state,
        badgeCount             : { ...state.badgeCount, chat : act.payload }
      }

    case action.NOTIFICATION_CHAT_SEEN_SUCCESS:
      console.log("redux/notification/reducer.js:NOTIFICATION_CHAT_SEEN_SUCCESS");
      return {
        ...state,
        badgeCount             : { ...state.badgeCount, chat : state.badgeCount.chat - 1 }
      }

    case action.NOTIFICATION_MARK_ALL_READ:
      console.log("redux/notification/reducer.js:NOTIFICATION_MARK_ALL_READ");
      return {
        ...state,
        isMarkingNotificationReadInProgress : true
      }

    case action.NOTIFICATION_MARK_ALL_SUCCESS:
      console.log("redux/notification/reducer.js:NOTIFICATION_MARK_ALL_SUCCESS");
      return {
        ...state,
        isMarkingNotificationReadInProgress : false
      }

    case action.NOTIFICATION_SAVE:
      console.log("redux/notification/reducer.js:NOTIFICATION_SAVE");
      return {
        ...state,
        notification                        : act.payload
      }
    
    case action.NOTIFICATION_GET_CALL_LOG:
      console.log("redux/notification/reducer.js:NOTIFICATION_GET_CALL_LOG");
      return {
        ...state,
        isNotificationLogsLoading           : true
      }

    case action.NOTIFICATION_GET_CALL_LOG_SUCCESS:
      console.log("redux/notification/reducer.js:NOTIFICATION_GET_CALL_LOG");
      const newData = [
        ...state.notificationCallLogList,
        ...act.payload
      ]
      return {
        ...state,
        notificationCallLogList             : newData,
        isNotificationLogsLoading           : false,
        isNotificationLogsLoaded            : true,
        notificationCallLogPage             : state.notificationCallLogPage + 1
      }
    
    case action.NOTIFICATION_SET_CALL_LOG_STATUS:
      console.log("redux/notification/reducer.js:NOTIFICATION_GET_CALL_LOG");
      return {
        ...state,
        callLogStatus                       : act.payload,
      }

    case action.NOTIFICATION_RESET:
      console.log("redux/notification/reducer.js:NOTIFICATION_GET_CALL_LOG");
      return {
        ...state,
        notificationCallLogList             : [],
        isNotificationLogsLoading           : false,
        isNotificationLogsLoaded            : false,
        notificationCallLogPage             : 1,
        notificationList                    : [],
        notificationListPage                : 1,
        isNotificationLoading               : false,
        isNotificationLoaded                : false,
      }
  

    default:
      return state
  }
}
