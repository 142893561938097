import { makeStyles }       from '@material-ui/core/styles';
import colors               from '../../utils/colors';

const useStyles = makeStyles(theme => ({
 

  mainContainer: {
    display               : 'flex',
    flexDirection         : 'column',
    alignItems            : 'center',
    width                 : '100vw',
    height                : '100vh',
    overflow              : 'hidden',
    background            : 'linear-gradient(#FDC830, #F37335)',
  },

  subcontainer:{
    marginTop             : 'auto',
    marginBottom          : 'auto',
  },

  sosearchLogo: {
    width                 : '404.5px',
    height                : '91.09px',
    marginTop             : '50px',
    marginLeft            : '127px',
    marginRight           : '1388.5px',
    marginBottom          : '50px',
    [theme.breakpoints.only('lg')]: {
      width               : '300px',
      height              : 'auto',
      marginTop           : '45px',
      marginLeft          : '127px',
      marginRight         : '1030px',
      marginBottom        : '45px',
      },
    [theme.breakpoints.only('md')]: {
      width               : '250px',
      height              : 'auto',
      marginTop           : '25px',
      marginLeft          : '0px',
      marginRight         : '0px',
      marginBottom        : '45px',
    },
    [theme.breakpoints.down('sm')]: {
      width               : '250px',
      height              : 'auto',
      marginLeft          : '0px',
      marginRight         : '0px',
      marginBottom        : '0px',
      }
    
  
  },

  boxContainer: {
    display               : 'flex',
    flexDirection         : 'row',
    alignItems            : 'center',
    justifyContent        : 'center',
    padding               : '10px',
    marginTop             : 'auto',
    marginBottom          : 'auto',
    [theme.breakpoints.between('md','xl')]: {
      marginTop           : '0px',
      marginBottom        : '0px',  
    },
    [theme.breakpoints.down('sm')]: {
      display             : 'flex',
      flexDirection       : 'column',
      alignItems          : 'center',
    }
  },

  landingPageImage: {
    marginRight           : '127px',
    width                 : '777px',
    height                : '520px',
    [theme.breakpoints.only('lg')]: {
      width               : '600px',
      height              : 'auto',
      marginRight         : '50px',
    },
    [theme.breakpoints.only('md')]: {
      width               : '500px',
      height              : 'auto',
      marginRight         : '50px',
    },
    [theme.breakpoints.only('sm')]: {
    width                 : '500px',
    height                : 'auto',
    marginRight           : 0,
    },
    [theme.breakpoints.only('xs')]: {
      width               : '350px',
      height              : 'auto',
      marginRight         : 0,
    }
  },

  whiteBox: {
    display               : 'flex',
    flexDirection         : 'column',
    alignItems            : 'center',
    width                 : '777px',
    height                : '520px',
    background            : colors.WHITE,
    borderRadius          : 20,

    [theme.breakpoints.only('lg')]: {
      width               : '600px',
      height              : '368.078px',
      
      },
      [theme.breakpoints.only('md')]: {
        width             : 'auto',
        height            : '325px',
    },
    [theme.breakpoints.only('sm')]: {
    width                 : '500px',
    height                : 'auto',
    background            : 'none',
    },
    [theme.breakpoints.only('xs')]: {
      width               : '350px',
      height              : 'auto',
      background          : 'none',
    }
   
  },

  textLabel: {
    [theme.breakpoints.only('xl')]: {
      marginTop           : 60,
      color               : colors.PRIMARY_TEXT,
    },
    [theme.breakpoints.only('lg')]: {
      marginTop           : 25,
      color               : colors.PRIMARY_TEXT,
    },
    [theme.breakpoints.only('md')]: {
      marginTop           : 15,
      color               : colors.PRIMARY_TEXT,
    },
    [theme.breakpoints.only('sm')]: {
      marginTop           : 10,
      color               : colors.WHITE,
    },
    [theme.breakpoints.only('xs')]: {
      marginTop           : 10,
      color               : colors.WHITE,
    }
  },

  text: {
    color                : colors.PRIMARY_TEXT,
    paddingRight         : 10,
    paddingLeft          : 10,

    [theme.breakpoints.down('sm')]: {
      color              : colors.WHITE,
    },
   
  },

  registerBtn: {
    color               : colors.WHITE,
    background          : colors.PRIMARY,
    fontWeight          : 'lighter',
    textTransform       : 'capitalize',
    borderRadius        : 40,
    width               : 150,
    height              : 50,
    marginTop           : 50,

    [theme.breakpoints.only('md')]: {
      marginTop         : 30,
      },
    [theme.breakpoints.only('sm')]: {
      marginTop         : 10,
      color             : colors.PRIMARY,
      background        : colors.WHITE
    },
    [theme.breakpoints.only('xs')]: {
      marginTop         : 10,
      color             : colors.PRIMARY, 
      background        : colors.WHITE,     
    }
  },

  downloadBtn: {
    border              : '0.5px solid',
    borderColor         : colors.PRIMARY_TEXT,
    background          : colors.WHITE,
    borderRadius        : 40,
    width               : 150,
    height              : 50,
    marginTop           : 50,
    margin              : 10,
    marginBottom        : 60,

    [theme.breakpoints.only('md')]: {
      marginTop         : 25,
    },
    [theme.breakpoints.down('sm')]: {
      marginTop         : 10,
    }
  }



}));

export default useStyles;