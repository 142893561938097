import { makeStyles } from '@material-ui/core/styles';
import colors from '../../../../utils/colors';

const useStyles = makeStyles(theme => ({

  collapseBoxWrapper: {
    display: 'flex',
    flexDirection: 'column'
  },

  valueText: {
    color: colors.TEXT_GRAY,
  },

  buttonSave: {
    width: 120,
    borderRadius: 40,
    alignSelf: 'flex-end',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },

  // HEIGHT
  heightContainer: {
    display: 'flex',
    flexDirection: 'row',
    height: 400,
    justifyContent: 'center',
    marginBottom: theme.spacing(1),
    marginLeft: theme.spacing(4),
    marginTop: theme.spacing(1),

    '& > .MuiBox-root': {
      marginLeft: theme.spacing(3),
    },

    '& > .MuiBox-root:first-of-type': {
      display: 'flex',
      flexDirection: 'column',
      alignSelf: 'center',
      width: theme.spacing(20),
      marginRight: theme.spacing(3),
    }
  },
}));

export default useStyles;
