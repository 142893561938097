import React, { useCallback, useState, 
  useEffect, forwardRef, 
  useImperativeHandle }            from 'react';
import ReactCrop                                 from 'react-image-crop';
import { Box, Button, Modal, Typography }        from '@material-ui/core';
import AddAPhotoOutlinedIcon                     from '@material-ui/icons/AddAPhotoOutlined';
import DeleteOutlinedIcon                        from '@material-ui/icons/DeleteOutlined';
import CropOutlinedIcon                          from '@material-ui/icons/CropOutlined';
import CloseOutlinedIcon                         from '@material-ui/icons/CloseOutlined';
import CloudUploadOutlinedIcon                   from '@material-ui/icons/CloudUploadOutlined';
import RotateRightOutlinedIcon                   from '@material-ui/icons/RotateRightOutlined';
import CancelPresentationIcon                    from '@material-ui/icons/CancelPresentation';
import useStyles                                 from './style';
import colors                                    from '../../../utils/colors';
import { multipleRequest, userRequest }          from '../../../service/requests';
import { createRandomCharacters, handleHttpError }                from '../../../utils/helper';
import { TOAST_AXIOS_REQUEST_ERROR, 
ALERT_SEVERITY_INFO, 
ALERT_SEVERITY_ERROR, 
ALERT_SEVERITY_SUCCESS }                from '../../../utils/constants';import 'react-image-crop/dist/ReactCrop.css';
const Upload = forwardRef((props, ref) => {
  const classes = useStyles();
  const { callback, next, showAlert, user, } = props;
  
  const [isSaving, setIsSaving] = useState(false);

  const [imageRef, setImageRef] = useState(null);
  const [file, setFile] = useState(null);
  const [objectUrl, setObjectUrl] = useState(null);
  const [croppedObjectUrl, setCroppedObjectUrl] = useState(null);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [angle, setAngle] = useState(0);
  const [isRotate, setIsRotate] = useState(false);

  const [crop, setCrop] = useState({ 
    unit: '%', 
    x: 0,
    y: 0,
    width: 100,
    height: 100,
    aspect: null
  });

  useEffect(() => {
    callback(isSaving);
  }, [isSaving]);
  
  useEffect(() => {
    if (imageRef) {
      cropImage(crop);
    }
  }, [imageRef]);

  useEffect(()=>{
    if(imageRef != null){
      createCroppedImage(imageRef, crop,angle,isRotate);
    }
  },[imageRef,angle,isRotate]);

  const toggleUploadModal = () => {
    setIsUploadModalOpen(prevIsModalOpen => !prevIsModalOpen);
  };


  const onChange = (e) => {
    console.log('e : ', e.target.files);

    if (e.target.files.length !== 0) {
      const objectUrl = URL.createObjectURL(e.target.files[0]);

      setObjectUrl(objectUrl);
      toggleUploadModal(); 
      e.target.value = null;
    }
  }
  
  const updateCropRatio = (ratioWidth,ratioHeight) => {
    console.log(crop.aspect, 'crop aspect')
  
    const ratio              = ratioWidth / ratioHeight;
    const origWidth          = imageRef.width;
    const origHeight         = imageRef.height;
    const newWidth           = ratioWidth * 100;
    const computedRatio      = (origHeight / origWidth) * newWidth;
    const rationInPercentage = ((computedRatio / origWidth) * 100) > 100? 100: ((computedRatio / origWidth) * 100);
   
    setCrop({
      ...crop,
      unit: '%', 
      x: 0,
      y: 0,
      width: ratioWidth == 1 && ratioHeight == 1 ?((origHeight / origWidth) * 100) >= 100? 100 : ((origHeight / origWidth) * 100): rationInPercentage,
      height: ratioWidth == 1 && ratioHeight == 1 ?((origWidth / origHeight) * 100) >= 100? 100 : ((origWidth / origHeight) * 100) : newWidth >= 100? 100: newWidth,
      aspect: ratio
    });
  }

  const onLoad = useCallback(img => {
    setImageRef(img);
  }, []);

  const cropImage = async (crop) => {
    console.log('crop : ', crop);

    if (imageRef && crop.width && crop.height) {
      createCroppedImage(imageRef, crop); 
    }
  };

  const createCroppedImage = async (image, crop) => {
    const canvas  = document.createElement('canvas');
    const scaleX  = image.naturalWidth / image.width;
    const scaleY  = image.naturalHeight / image.height;

    canvas.width  = crop.width;
    canvas.height = crop.height;
    
    const ctx     = canvas.getContext('2d');

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          reject(new Error('Canvas is empty'));
          return;
        }
        
        blob.name = 'newFile.jpeg';
        setFile(blob);
      }, 'image/jpeg');
    });
  }

  const createCroppedFile = () => {

    toggleUploadModal();
    const newObjectUrl = URL.createObjectURL(file);
    URL.revokeObjectURL(objectUrl);
    setObjectUrl(null);
    setCroppedObjectUrl(newObjectUrl);
  }

  const rotateImage = () => {
    if(angle != 270){
      setAngle(angle + 90);
      setIsRotate(true);
    } else{
      setAngle(0);
      setIsRotate(false);
    }
  }

  const uploadPhoto = () => {

    if(file == null){
      setIsSaving(prevIsSaving => !prevIsSaving);
      next();
      } else {
        showAlert(ALERT_SEVERITY_INFO, 'Uploading please wait.');
        const data = new FormData();
        data.append('user_id', user.userId);
        data.append('image', file);
        data.append('is_primary', 'true')
    
        showAlert(ALERT_SEVERITY_INFO, 'Uploading please wait.');
        userRequest.uploadPhoto(
          data
        ).then(response => {
          console.log('response : ', response);
    
          showAlert(ALERT_SEVERITY_SUCCESS, 'You successfully uploaded the profile picture.');
          setIsSaving(prevIsSaving => !prevIsSaving);
          next();
        }).catch(error => {
          setIsSaving(prevIsSaving => !prevIsSaving);
          handleHttpError({
            error,
            request: 'userRequest.uploadPhoto::src/views/registration/upload'
          })
        });
    }
  }

  const handleNext = () => {
    console.log('Upload.uploadPhoto()');
    
    setIsSaving(prevIsSaving => !prevIsSaving);
    uploadPhoto();
  }

  useImperativeHandle(ref, () => ({
    triggerNext() {
      handleNext()
    }
  }));

  return (
    <Box className={classes.box}>
      <Box className={classes.box2}>

        <Typography variant="h5" color="primary" className={`text-bold text-center mb-20`}>
          About You
        </Typography>

        <Typography variant="h6" className={`text-bold text-center mb-20`} color="textPrimary">
          Click to Upload Your Profile Photos
        </Typography>

        <Box className={classes.imageUploadContainer}>
          {
            croppedObjectUrl === null ? 
              <Box>
                <AddAPhotoOutlinedIcon style={{ fill : colors.TEXT_GRAY, fontSize : 64 }}/>
              </Box>
            :
              <img src={croppedObjectUrl} className={classes.image} alt="profile"/>
          }
          <Button for="file" color="primary" variant="outlined" size="small">
            <label for="file">Choose File</label>
          </Button>          
          <input name="image" id="file" type="file" accept="image/*" className={classes.inputFile} onChange={onChange}/>
        </Box>

        <Modal
          open={isUploadModalOpen}
          onClose={toggleUploadModal}
        >
          <Box className={classes.modal}>
            <CloseOutlinedIcon className={classes.closeIcon} onClick={toggleUploadModal}/>
            <Box className={classes.uploadImageBox}>
              <ReactCrop
                src={objectUrl && objectUrl}
                onImageLoaded={onLoad}
                crop={crop}
                style={{ transform: `rotate(${angle}deg)` }}
                onChange={c => setCrop(c)}
                onComplete={cropImage}
              />
              <Box className={classes.controls}>
                <Button disabled={isSaving} variant="outlined" className={classes.button} onClick={updateCropRatio.bind(this, 0,0)}>
                  Freeform
                </Button>
                <Button disabled={isSaving} variant="outlined" className={classes.button}onClick={rotateImage} startIcon={<RotateRightOutlinedIcon className={classes.cropIcon}/>}>
                  Rotate
                </Button>
                <Button disabled={isSaving} variant="outlined" className={classes.button} onClick={updateCropRatio.bind(this, 1,1)}>
                  Square
                </Button>
                <Button disabled={isSaving} variant="outlined" className={classes.button} onClick={updateCropRatio.bind(this, 5,7)}>
                  5:7
                </Button>
                <Button disabled={isSaving} variant="outlined" className={classes.button} onClick={updateCropRatio.bind(this, 3,4)}>
                  3:4
                </Button>
                <Button disabled={isSaving} variant="outlined" className={classes.button} onClick={updateCropRatio.bind(this, 3,5)}>
                  3:5
                </Button>
                <Button disabled={isSaving} variant="outlined" className={classes.button} onClick={updateCropRatio.bind(this, 2,3)}>
                  2:3
                </Button>
              </Box>
              <Box className={classes.controls}>
                <Button disabled={isSaving} variant="outlined" className={classes.button} onClick={toggleUploadModal} startIcon={<CancelPresentationIcon className={classes.cropIcon}/>}>
                  Cancel
                </Button>
                <Button disabled={isSaving} variant="outlined" className={classes.buttonSave} onClick={createCroppedFile} startIcon={!isSaving ? <CloudUploadOutlinedIcon className={classes.cropIcon}/> : <></>}>
                  Upload
                </Button>
              </Box>
              
            </Box>
          </Box>
        </Modal>

      </Box>
    </Box>
  )
});

export default Upload;