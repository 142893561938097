import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Button, Container, Grid, Paper, Typography } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import {
  CARD_USER,
  PRELOADER_CARD_USER,
  NO_USERS_FOUND_TITLE,
  NO_USERS_FOUND_DESC,
  TOAST_SEARCH_ADDED,
  ALERT_SEVERITY_SUCCESS,
  TOAST_SEARCH_DELETED,
  TOAST_AXIOS_REQUEST_ERROR,
  ALERT_SEVERITY_ERROR,
  TOAST_SEARCH_UPDATED,
  HTTP_STATUS
} from '../../utils/constants';
import NO_USERS_FOUND from "../../assets/images/no_users_found.png";
import { searchRequest } from '../../service/requests';
import searchAction from '../../redux/search/action';
import connectionAction from '../../redux/connection/action';
import useStyles from './style';
import NewSearch from './new-search';
import SearchList from './search-list';
import Empty from '../../components/empty';
import Preloader from '../../components/preloader';
import UserCard from '../../components/usercard';
import lookupAction from '../../redux/lookup/action';
import { handleHttpError } from '../../utils/helper';

const { updateSearch, addSearch, deleteSearch, getSearches, setSearch } = searchAction;
const { setUserConnectionList } = connectionAction;
const { getLookUpFields, getLookUpCountry, getLookUpState, getLookUpCity }  = lookupAction;

function useQuery() {
  return new URLSearchParams(window.location.search);
}
const query = useQuery().get('q');
const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

const CenteredTabs = (props) => {

  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const { tabs } = props;

  const query = useQuery().get('q');
  const [value, setValue] = useState(1);

  useEffect(() => {
    const pathname = location.pathname.split('/');
    if (pathname[2] == 'simple-search' || pathname[2] == `simple-search?q=${query}`) {
      setValue(0);
    } else if (pathname[2] == 'advanced-search') {
      setValue(1);
    }else {
        history.push('/search/advanced-search');
        setValue(1);
    }
  }, [location.pathname])

  console.log('value : ', value);

  return (
    <Paper className={classes.paper}>
      {
        tabs.map((tab, index) => {
          return (
            <TabPanel key={index} value={value} index={tab.id}>
              {tab.component}
            </TabPanel>
          )
        })
      }
    </Paper>
  );
}

const SavedSearchResult = (props) => {
  console.log('SavedSearchResult()');

  const classes = useStyles();
  const { onBack, search } = props;
  const { searchName, users } = search;

  return (
    <Grid 
      container 
      justify="flex-start" 
      alignItems="center"
      spacing={2} 
    >
      <Grid item xs={12}> 
        <Box className={classes.searchResult}>
          <Button color="primary" variant="contained" onClick={onBack}><ChevronLeftIcon />Back</Button>
          <Typography variant='h6' color='textPrimary' className={`text-bold`}>{searchName}</Typography>
          {/* <Button color="primary" variant="contained" onClick={onBack}><BackIcon/>Back</Button> */}
          <Box></Box>
        </Box>
      </Grid>

      {
        (users.length > 0) ?
          users.map((userConnection, index) => {
            return (
              <UserCard type={CARD_USER} userConnection={userConnection} key={index} />
            )
          })
        : 
          <Empty title={NO_USERS_FOUND_TITLE} description={NO_USERS_FOUND_DESC} image={NO_USERS_FOUND}/>
      }
    </Grid>
  )
}

const AdvancedSearch = (props) => {
  const [isNewSearch, setIsNewSearch] = useState(false);
  const [isExecuteSearch, setIsExecuteSearch] = useState(false);

  const history = useHistory();

  const toggleNewSearch = () => {
    if (isNewSearch) {
      history.push(`/search/advanced-search`);
    }
    setIsNewSearch(prevIsNewSearch => !prevIsNewSearch);
  }

  const toggleSavedSearchUserList = () => {
    setIsExecuteSearch(prevIsExecuteSearch => !prevIsExecuteSearch);
  }

  return (
    <>
      {
        isNewSearch ? 
          <NewSearch
            {...props}
            onBack={toggleNewSearch} 
          />
        : isExecuteSearch ? 
          <SavedSearchResult 
            {...props} 
            onBack={toggleSavedSearchUserList} 
          />
        :
          <SearchList 
            {...props} 
            onAddSearch={toggleNewSearch}
            onEditSearch={toggleNewSearch} 
            onExecuteSearch={toggleSavedSearchUserList} 
          />
      }
    </>
  )
}

const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const Search = (props) => {
  console.log('Search()');

  const classes = useStyles();
  const location = useLocation();
  const { search, isSearchAdded, isSearchAddError, isSearchUpdated, isSearchUpdateError, isSearchDeleted, isSearchDeleteError, showAlert, userId } = props;

  const prevProps = usePrevious({isSearchAdded, isSearchUpdated, isSearchUpdateError, isSearchDeleted, isSearchAddError, isSearchUpdateError, isSearchDeleteError});

  const [isUserConnectionsLoading, setUserConnectionLoading] = useState(false);
  const [userConnections, setUserConnections] = useState(null);
  const [username, setUsername] = useState('');

  const [isTyping, setIsTyping] = useState(false);
  const [typingTimeout, setTypingTimeout] = useState(0);
  
  const [isNewSearch, setIsNewSearch] = useState(false);

  const toggleNewSearch = () => {
    setIsNewSearch(prevIsNewSearch => !prevIsNewSearch);
  }

  useEffect(() => {
    if (prevProps && prevProps.isSearchAdded !== isSearchAdded && isSearchAdded) {
      toggleNewSearch(); 
      showAlert(ALERT_SEVERITY_SUCCESS, TOAST_SEARCH_ADDED(search.searchName));
    }
    
    if (prevProps && prevProps.isSearchUpdated !== isSearchUpdated && isSearchUpdated)
      showAlert(ALERT_SEVERITY_SUCCESS, TOAST_SEARCH_UPDATED(search.searchName));

    if (isSearchUpdateError) 
      showAlert(ALERT_SEVERITY_ERROR, TOAST_AXIOS_REQUEST_ERROR); 

    if (prevProps && prevProps.isSearchDeleted !== isSearchDeleted && isSearchDeleted)
      showAlert(ALERT_SEVERITY_SUCCESS, TOAST_SEARCH_DELETED(search.searchName));

    if (isSearchAddError || isSearchUpdateError || isSearchDeleteError)
      showAlert(ALERT_SEVERITY_ERROR, TOAST_AXIOS_REQUEST_ERROR);

  }, [isSearchAdded, isSearchAddError, isSearchUpdated, isSearchUpdateError, isSearchDeleted, isSearchDeleteError]);

  useEffect(() => {
    console.log('Search.useEffect()');

    const hasSearchValue = location.state ? location.state && location.state.searchValue : false

    if (!isTyping && hasSearchValue) {
      searchUsernameService();
    } 
    else if (query){
      searchUsernameService(query);
    }   
  }, [isTyping, location]);

  const searchUsernameService = (query) => {
    setUserConnectionLoading(true);
    searchRequest.searchByUsername({
      user_id : userId,
      keyword : query ? query : location.state.searchValue
    }).then(response => {
      console.log('response : ', response.data);

      setUserConnectionLoading(false);
      if (response?.data?.code == HTTP_STATUS._204) {
        setUserConnections([]);
      } else {
        setUserConnections(response.data.userConnection)
      }
    }).catch(error => {
      handleHttpError({
        error,
        request: 'searchRequest.searchByUsername::src/views/search'
      })
    });
  }

  const onUsernameSearch = (event) => {
    console.log('Search.onUsernameSearch()');

    setUsername(event.target.value);
    setUserConnectionLoading(true);
    
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setIsTyping(true);
    setTypingTimeout(setTimeout(() => {
      setIsTyping(false);
    }, 3000));
  }

  return (
    <Container className={classes.root}>
      <CenteredTabs tabs={[
        {
          id : 0,
          label : 'Simple Search',
          component : <Box>
                        <Grid 
                          container 
                          justify="flex-start" 
                          alignItems="flex-start"
                          spacing={2} 
                        >
                          {
                            (isUserConnectionsLoading) ?
                              Array.from({length: Math.random() * (8) + 1 }, () => 1).map((value, index) => {
                                return (
                                  <Preloader type={PRELOADER_CARD_USER} key={index} />
                                )
                              })
                            : userConnections !== null && userConnections?.length > 0 ?
                              userConnections.map((userConnection, index) => {
                                return (
                                  <UserCard type={CARD_USER} userConnection={userConnection} key={index} />
                                )
                              })
                            : userConnections === null ?
                              <Box className={classes.notFound}>
                                <Empty title={NO_USERS_FOUND_TITLE} description={NO_USERS_FOUND_DESC} image={NO_USERS_FOUND}/>
                              </Box>
                            :
                              <Box className={classes.notFound}>
                                <Empty title={NO_USERS_FOUND_TITLE} description={NO_USERS_FOUND_DESC} image={NO_USERS_FOUND}/>
                              </Box>
                          }
                        </Grid>
                      </Box>
        },
        {
          id : 1,
          label : 'Advanced Search',
          component : <AdvancedSearch {...props} />
        },
      ]}/>
    </Container>
  );
}

/////////////////////////////
// mapStateToProps()
/////////////////////////////
const mapStateToProps = (state) => {
  console.log('search/result/index.js:mapStateToProps(state)');
  console.log(state);
  return {
    search              : state.Search.search,
    searches            : state.Search.searches,
    isSearchLoading     : state.Search.isSearchLoading,
    isSearchLoaded      : state.Search.isSearchLoaded,
    isSearchAdding      : state.Search.isSearchAdding,
    isSearchAdded       : state.Search.isSearchAdded,
    isSearchAddError    : state.Search.isSearchAddError,
    isSearchUpdating    : state.Search.isSearchUpdating,
    isSearchUpdated     : state.Search.isSearchUpdated,
    isSearchUpdateError : state.Search.isSearchUpdateError,
    isSearchDeleted     : state.Search.isSearchDeleted,
    isSearchDeleteError : state.Search.isSearchDeleteError,
    user                : state.Session.user.user,
    userId              : state.Session.user.user.userId,
    userAddress         : state.Session.user.userField.address,
    Lookup              : state.Lookup
  };
}

export default connect(mapStateToProps, { addSearch, updateSearch, deleteSearch, getSearches, setSearch, setUserConnectionList, getLookUpCountry, getLookUpState, getLookUpCity })(
  Search
)