import React, { useState, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import {
  CALL_ACTION_COMPLETED,
  CALL_ACTION_DECLINE,
  CALL_ACTION_MISSED,
  CALL_STATUS_INBOUND_AUDIO,
  CALL_STATUS_INBOUND_VIDEO,
  CALL_STATUS_MISSED_AUDIO,
  CALL_STATUS_MISSED_VIDEO,
  CALL_STATUS_OUTBOUND_AUDIO,
  CALL_STATUS_OUTBOUND_VIDEO,
  CALL_TYPE_AUDIO,
  CALL_TYPE_VIDEO,
  COMMUNICATION_TYPE_VIDEO,
  COMMUNICATION_TYPE_AUDIO
} from '../../../utils/constants'
import { callRequest, multipleRequest } from '../../../service/requests';
import TabPanel from '../tab-panel';



const CenteredTabs = (props) => {

  const { tabs, value } = props;


  return (<React.Fragment>
    {
      value === 0 && (
        <TabPanel key={0} value={value} index={tabs[0].id}>
          {tabs[0].component}
        </TabPanel>
      )
    }
    {
      value === 1 && (
        <TabPanel key={1} value={value} index={tabs[1].id}>
          {tabs[1].component}
        </TabPanel>
      )
    }
    {
      value === 2 && (
        <TabPanel key={2} value={value} index={tabs[2].id}>
          {tabs[2].component}
        </TabPanel>
      )
    }
  </React.Fragment>
  );
}

export default CenteredTabs